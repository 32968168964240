<template>
  <div class="newportal">
    <header class="header bg-white">
      <div class="container justify-between">
        <div class="flex flex-wrap">
          <figure class="w-full md:w-1/3 mb-6 md:mb-0 text-center md:text-left">
            <a href="/" class=""
              ><img src="../assets/img/hand-logotipo.svg" class="md:block inline-block"
            /></a>
          </figure>
          <div class="w-full md:w-1/3 mb-6 md:mb-0 md:-mt-8 text-center z-10">
            <img src="../assets/img/hero.png" class="inline-block" />
          </div>
          <div class="w-full md:w-1/3 md:mb-0">
            <div class="header-right-text text-center md:text-right">
              Hand Paraguay
            </div>
          </div>
        </div>
      </div>
    </header>

    <main>
      <section class="py-5 md:py-16 bg-pattern-2 mb-2">
        <div class="container justify-between text-center container-lg container-xl">
          <div class="w-full lg:w-1/2 mx-auto">
            <h2 class="banner-heading mb-5">
              ¡Te damos la mano que buscabas!
            </h2>
            <p class="banner-subheading mb-5">
              Muchas veces necesitamos ayuda en la que podamos confiar, por eso creamos HAND, para
              resolver los problemas en el hogar con mayor facilidad, confianza y seguridad,
              conectando familias con los mejores profesionales en oficios.
            </p>
          </div>
        </div>
        <div class="container justify-between text-center container-lg container-xl">
          <div class="w-full lg:w-2/3 mx-auto">
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/2 md:pr-5">
                <router-link
                  :to="{ name: 'Help' }"
                  class="btn btn--help banner-btn mb-5 md:mb-0 w-full"
                  >Soy usuario<br />
                  BUSCO AYUDA</router-link
                >
              </div>
              <div class="w-full md:w-1/2 md:pl-5">
                <router-link
                  :to="{ name: 'ProfessionalRegister' }"
                  class="btn btn--job banner-btn mb-5 md:mb-0 w-full"
                >
                  Soy profesional<br />
                  BUSCO TRABAJO</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="footer">
      <div class="container px-0 container-lg">
        <div class="flex flex-wrap">
          <div class="w-full px-2 mb-2 text-center">
            <p class="mt-2">
              (c) Hand Paraguay - Hand y la tipografía con una mano en la H son marcas registradas
              de Hand Paraguay. Todos los derechos reservados.
            </p>
            <img src="../assets/img/hand-logotipo-grey.svg" class="inline-block" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters(["error"])
  },
  components: {}
};
</script>
<style scoped>
@import "../assets/css/newportal.css";
</style>
