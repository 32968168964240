<template>
  <div>
    <v-facebook-login
      :app-id="facebook.appId"
      class="btn btn--primary w-full icon-text-aligner text-left leading-tight text-lg p-3 cursor-pointer"
      v-model="facebook.model"
      @login="fbLogin"
      @sdk-init="fbSdkInit"
      text-class="justify-between line-height-1"
    >
      <template v-slot:login>
        <template v-if="title">{{ title }}</template>
        <template v-if="!title"> Continúa con Facebook </template>
      </template>
      <template v-slot:logo>
        <img src="../assets/img/icon-facebook.svg" class="w-8 icon--align-right ml-auto fb-icon" />
      </template>
      icon-facebook
    </v-facebook-login>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VFacebookLogin from "vue-facebook-login-component";
export default {
  name: "Facebook",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    facebook: {
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      FB: {},
      model: {},
      scope: {},
    },
    fbUser: {},
    fbAuthResponse: {}
  }),
  methods: {
    doLogin() {
      document.querySelector(".v-facebook-login").click();
    },
    fbLogin(loginResponse) {
      this.fbAuthResponse = loginResponse ? loginResponse.authResponse : {}
      this.getFbUserData();
      var self = this;
      setTimeout(function () {
        self.facebookLogin();
      }, 2000);
    },
    fbLogout() {
      if (this.facebook.scope.logout && this.facebook.model.connected) {
        this.facebook.scope.logout();
      }
    },
    fbSdkInit({ FB, scope }) {
      this.facebook.scope = scope;
      this.facebook.FB = FB;
    },
    getFbUserData() {
      this.facebook.FB.api(
        "/me",
        { fields: "id, name,email, picture,first_name,last_name" },
        (user) => {
          this.fbUser = user;
        }
      );
    },
    facebookLogin() {
      if (!this.isAuthenticated && this.idle && this.connected && this.fbUser) {
        this.$store.dispatch("hideMessageLink");
        this.$store
          .dispatch("socialLoginFacebook", {
            access_token: this.fbAuthResponse.accessToken,
          })
          .then(() => {
            this.processingLogin = true;
            let loginMessage = `Datos correctos! redireccionando...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", loginMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function () {
              self.$store.dispatch("setShowAlert", false);
              let isRedirectProfileId = null;
              let isProfessionId = null;
              if (self.ClientTempData && self.ClientTempData != null) {
                isRedirectProfileId = self.ClientTempData.professionalId
                  ? self.ClientTempData.professionalId
                  : null;
                isProfessionId = self.ClientTempData.profession_id
                  ? self.ClientTempData.profession_id
                  : null;
              }
              if (self.redirectpath) {
                self.$router.push(self.redirectpath);
              } else if (isRedirectProfileId != null && isProfessionId != null) {
                self.$router.push({
                  name: "ProfessionalsProfile",
                  params: { proid: isProfessionId, pid: isRedirectProfileId },
                });
              } else {
                self.$router.push({ name: "Dashboard" });
              }
            }, 2000);
          })
          .catch(() => {
            let errorMessage = this.error.response.data.msg;
            if (this.error.response.data.provider) {
              this.fbLogout();
              if (this.error.response.data.provider == "mail") {
                this.$store.dispatch("setIsShowMailLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado manualmente, por favor inicie sesión ";
              } else if (this.error.response.data.provider == "google") {
                this.$store.dispatch("setIsShowGoogleLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado por google, por favor inicie sesión ";
              }
            }
            this.processingLogin = false;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingLogin = false;
      }
    },
  },
  components: {
    VFacebookLogin,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "error", "ClientTempData"]),
    idle() {
      return this.facebook.model.idle;
    },
    connected() {
      return this.facebook.model.connected;
    },
    disconnected() {
      return !this.connected;
    },
    avatarUrl() {
      if (this.fbUser && this.fbUser.picture && this.fbUser.picture.data.url) {
        return this.fbUser.picture.data.url;
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="css">
.fb-icon {
  order: 2;
}
.line-height-1 {
  line-height: 1.125;
}
</style>
