export default function() {
  if (this.locations && this.locations.length > 0) {
    if (this.location.id) {
      for (var i = 0; i < this.locations.length; i++) {
        if (this.locations[i].id == this.location.id) {
          this.location.name = this.locations[i].description;
          this.searchlocation = this.location.name;
          this.BreadcrumbItems.push({
            name: this.location.name,
            link:
              "/professionals/search?profession=" +
              this.profession +
              "&location=" +
              this.location.id,
            islast: true
          });
        }
      }
    } else {
      this.location.id = this.locations[0].id;
      this.location.name = this.locations[0].description;
    }
  }
}
