export default function() {
  this.processingBudgetRequests = true;
  this.$store
    .dispatch("GetUserBudgetRequest", {
      userId: this.user.id,
      requestStatus: this.requestStatus
    })
    .then(response => {
      this.budgetRequests = response;
      this.processingBudgetRequests = false;
    })
    .catch(() => {
      this.processingBudgetRequests = false;
      let errorMessage = this.error.response.data.msg;
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", errorMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
