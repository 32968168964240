<template>
  <section class="px-2 lg:w-8/12">
    <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
      <h4 class="mb-3 lg:mt-0 lg:mb-0">
        <svg class="-mt-2 icon">
          <use
            href="../../assets/img/icons.svg#icon-todo"
            xlink:href="../../assets/img/icons.svg#icon-todo"
          />
        </svg>
        Activar Email
      </h4>
    </div>
    <div>
      <div class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded">
        <div class="flex flex-col justify-between pt-4 lg:flex-row">
          <hr class="my-3 -mx-5 border-gray-200 lg:hidden" />
          <form
            @submit.prevent="onSubmit"
            method="post"
            class="p-5 md:p-10 rounded border border-gray-400 bg-white"
          >
            <h3 class="mb-8">Activar email de cliente</h3>
            <label>
              <span>Email:</span>
              <input
                class="form-input mt-1 block w-full"
                v-bind:class="{ 'bg-red-100 border-red-700': submited && !validateEmail() }"
                type="text"
                placeholder="Ingresa el correo a activar"
                v-model="email"
              />
              <span class="error" v-if="submited && !email"
                >Por favor ingrese su correo electrónico</span
              >
              <span class="error" v-if="submited && !validateEmail() && email"
                >Ingrese un email válido</span
              >
            </label>
            <button
              type="submit"
              class="btn btn--md btn--secondary w-full"
              :disabled="processingActivateEmail"
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AdminProfileSidebar from "@/components/AdminProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import validateEmail from "@/utils/methods/validateEmail";
export default {
  data() {
    return {
      colorMessage: "#66A7E5",
      submited: false,
      email: "",
      processingActivateEmail: false,
      forgoterrormessage: "",
    };
  },
  mounted() {},
  methods: {
    validateEmail,
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingActivateEmail = true;
      if (this.validateForm()) {
        this.$store
          .dispatch("activateEmail", {
            email: this.email,
          })
          .then(() => {
            this.processingActivateEmail = false;
            let resetpasswordMessage = `Correo activado correctamente`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", resetpasswordMessage);
            this.$store.dispatch("setShowAlert", true);
          })
          .catch(() => {
            this.processingActivateEmail = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingActivateEmail = false;
      }
    },
    validateForm() {
      return this.email != "" && this.validateEmail() == true;
    },
  },
  computed: {
    ...mapGetters(["error", "user"]),
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    AdminProfileSidebar,
  },
};
</script>
<style lang="css" scoped>
@import "../../assets/css/dashboard.css";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.success {
  color: #1bb01b;
}
</style>
