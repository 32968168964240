<template>
      <modal v-if="show" @close="show=false" ref="confirmationModalChild" :disable_close="disableClose">
          <div class="w-full flex flex-col items-center max-sm:px-[0px] px-[80px] py-[10px]">
            <span class="text-[25px] leading-[29px] text-black font-bold mt-[30px]">{{title}}</span>
            <span class="leading-[24px] text-center mt-[9px] text-dark">{{subtitle}}</span>
            <slot name="modal-content"></slot>
            <div class="mt-[38px]">
              <rounded-button :onClick="confirmed" :size="'large'">
                <span class="text-[16px] leading-[19px] font-medium text-white font-bold">{{buttonText}}</span>
              </rounded-button>
            </div>
            <div class="py-[17px] cursor-pointer" v-if="showBack" @click="close">
                <span class="text-[16px] hover:underline leading-[19px] font-medium text-dark font-bold">Volver atrás</span>
            </div>
          </div>
      </modal>
  </template>
  <script>

  import Modal from "@/components/v2/Modal.vue";
  import {RoundedButton} from "@/components/v2/buttons";
  export default {
    components: {
                  Modal,
                  RoundedButton,
                },
    props:{
      show:{
        type:Boolean,
        default:false
      },
      title:{
        type: String,
        default: "¿Desea confirmar la acción?"
      },
      subtitle:{
        type: String,
        default: "Por favor, confirma tu decisión"
      },
      buttonText:{
        type: String,
        default: "Confirmar"
      },
      showBack:{
        type: Boolean,
        default: true
      },
      disableClose:{
        type: Boolean,
        default: true
      }
    },
    data()
    {
      return {
        showModal:false,
      }
    },
    methods:{
        confirmed()
        {
            this.$emit('afterConfirmation')
            this.$refs.confirmationModalChild.closeModal()
        },
        close()
        {
          this.$emit('afterClose')
          this.$refs.confirmationModalChild.closeModal()
        }
    }
  }
  </script>
  