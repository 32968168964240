<template>
  <button
    type="button"
    class="btn btn--primary w-full icon-text-aligner text-left leading-tight text-lg p-3"
    @click="onClickTrigger"
  >
    <span class="justify-between" style="line-height:1;">
      Registrate con Google
    </span>
    <img
      src="../assets/img/icon-google.svg"
      class="w-8 icon--align-right ml-auto bg-white p-2 rounded"
    />
  </button>
</template>

<script>
export default {
  data() {
    return {
      Google: {},
      googleUser: {},
      isAuthorized:false,
      googleAuthResponse: {}
    }
  },
  methods:{
    onClickTrigger()
    {
      !this.isAuthorized ? this.login() : this.logout()
    },
    login() {
      this.$gAuth.signIn().then(GoogleUser => {
        this.googleAuthResponse = GoogleUser.getAuthResponse();
        this.isAuthorized = this.$gAuth.isAuthorized
        if(this.isAuthorized)
        {
          const user = GoogleUser.getBasicProfile();
          const data = {
            oauthUid: user.getId(),
            email: user.getEmail(),
            firstName: user.getGivenName(),
            lastName: user.getFamilyName(),
            pictureProfileUrl: user.getImageUrl(),
            oauthProvider: "google",
            userType:"professional",
            accessToken: this.googleAuthResponse.access_token,
          }
          this.Google = GoogleUser
          this.googleUser = user
          this.$emit('updateData',data, this.logout)
          //this.$gAuth.signOut();
        }
      })
        .catch((error)=>{
          console.log("error")
          console.log(error)
        });
    },
    logout() {
      this.$gAuth.signOut();
      this.isAuthorized = false;
    },
  },
};
</script>
<style lang="css">
</style>
