<template>
    <div>
    <section class="flex py-10 md:my-1 bg-white">
      <div class="container flex flex-col container-xl md:flex-row py-10">
        <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0 mx-auto">
          <Messages />
          <form
            class="p-6"
            @submit.prevent="ActivateUser"
            method="post"
          >
            <fieldset class="p-4 mb-3">
              <h4 class="">Validación de teléfono</h4>
              <p class="mb-6">Por favor inserte el código de validación que le llegará al whatsapp asociado a su teléfono.</p>
              <div class="grid grid-cols-1">
                <div>
                  <label>
                    <input
                      v-model="token"
                      class="block w-full mt-1 form-input"
                      type="text"
                      placeholder=""
                    />
                  </label>
                </div>
              </div>
            </fieldset>
            <div class="w-full flex justify-center">
              <div class="w-[40%] flex flex-col">
                <rounded-button :onClick="ActivateUser" :disabled="processingActivate">
                  Validar código
                </rounded-button>
                <div class="w-full flex mt-[21px] justify-center" @click="ResendActivationToken">
                  <custom-link v-if="showResend" >Reenviar código de activación</custom-link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <Banner/>
  </div>
</template>

<script>
import Banner from "@/components/v2/Banner.vue";
import {RoundedButton} from "@/components/v2/buttons";
import Footer from "@/components/Footer.vue";
import CustomLink from "@/components/v2/Link.vue"
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import Tooltip from "@/components/v2/Tooltip.vue";
export default {
  data() {
    return {
      token: "",
      showResend: false,
      processingActivate:false
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.$store.dispatch("setShowHeaderBackButton", false);
    this.$store.dispatch("setShowHeaderCancelButton", true);
    console.log(this.RegisterProfessionalCurrentStep)
  },
  beforeDestroy() {
    this.$store.dispatch("setShowHeaderCancelButton", false);
    this.$store.dispatch("setIsErrorAlert", false);
    this.$store.dispatch("setShowAlert", false);
  },
  methods: {
    ResendActivationToken(){
      this.processingActivate = true;
      console.log("procesando reenvio");
        this.$store
          .dispatch("resendActivatePhone", {
            ...this.RegisterClientTempData,
            ...this.RegisterProfessionalTempData
          })
          .then(() => {
            this.processingActivate = false;
            let ResendEmailMessage = `Código de activación enviado exitósamente. Por favor inserte el código que le llegará al whatsapp...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", ResendEmailMessage);
            this.$store.dispatch("setShowAlert", true);
            this.$store.dispatch("setIsResendEmail", "");
          })
          .catch(() => {
            this.showResend = true;
            this.processingActivate = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },
    ActivateUser() {
      this.processingActivate = true;
      if (this.token) {
        this.$store
          .dispatch("ActivateAccount", {
            token: this.token
          })
          .then(() => {
            this.processingActivate = true;
            let activateMessage = `Código validado exitósamente. Redirigiendo...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", activateMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function() {
              self.$store.dispatch("setShowAlert", false);
              if(self.RegisterProfessionalCurrentStep == 3){
                self.$store.dispatch("saveRegisterProfessionalCurrentStep", 4);
                self.$router.push({ name: 'RegisterProfessionalCompleteStep' })
              }else{
                self.$router.push({ name: "LoginClient" });
              }
              self.$store.dispatch("removeRegisterClientTempData");
              self.$store.dispatch("removeRegisterClientCurrentStep");
              
            }, 2000);
          })
          .catch(() => {
            this.showResend = true;
            this.processingActivate = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingActivate = false;
        this.$router.push("/login");
      }
    },
  },
  computed: {
    ...mapGetters(["error", "RegisterClientTempData","RegisterProfessionalCurrentStep","RegisterProfessionalTempData"]),
  },
  components: {
    Footer,
    Messages,
    Breadcrumb,
    RoundedButton,
    Tooltip,
    Banner,
    CustomLink
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
.whatsapp-contact-icon {
  top: 2px;
}
</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>
