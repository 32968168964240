<template>
  <a :href="href" class="link">
    <slot></slot>
  </a>
</template>
<script>
export default {
  props:{
    href:{
      type:String,
      default:"#"
    }
  }
}
</script>
<style scoped>
  .link{
    color:#373A3C;
    font-size:14px;
    line-height: 24px;
    text-decoration: underline;
  }

  .link:visited{
    color: #373A3C;
  }
</style>
