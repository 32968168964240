var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"px-2 mb-4 lg:w-3/12"},[_c('nav',{staticClass:"lg:-mt-4"},[_c('ul',[_vm._l((_vm.links),function(link,i){return [_c('li',{key:i},[_c('router-link',{class:[
              _vm.$route.name == link.route.name || _vm.$route.path == link.route.path
                ? 'text-brand-secondary'
                : 'text-black',
              'flex items-center justify-between py-4',
            ],attrs:{"to":link.route}},[_c('span',[_vm._v(_vm._s(link.title))]),_c('chevron-right-icon',{attrs:{"color":_vm.$route.name == link.route.name || _vm.$route.path == link.route.path
                  ? '#ff783e'
                  : '#000'}})],1)],1)]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }