<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <SideNavigator :links="links" />
          <router-view></router-view>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Messages from "@/components/Messages.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideNavigator from "@/components/SideNavigator.vue";
export default {
  components: { SideNavigator, Messages, Breadcrumb, Header, Footer },
  data() {
    return {
      links: [
        {
          route: { name: "AdminServiceRequests" },
          title: "Servicios solicitados",
        },
        {
          route: { name: "AdminBudgetRequest" },
          title: "Presupuestos solicitados",
        },
        {
          route: { name: "AdminReviews" },
          title: "Reviews",
        },
        {
          route: { name: "AdminProfessionalsRequests" },
          title: "Solicitudes de profesionales",
        },
        {
          route: { name: "AdminTransferRequest" },
          title: "Transferencias bancarias a confirmar",
        },
        {
          route: { name: "AdminClients" },
          title: "Clientes",
        },
      ],
    };
  },
};
</script>
