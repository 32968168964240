<template>
  <div class="w-full flex flex-col">
    <div class="w-full flex justify-between mb-3">
      <span class="text-lg font-bold">Total</span>
      <span v-if="serviceRequest.status !== 'complete'" class="text-lg">₲ {{ serviceRequest.serviceFee | thousandSeparator }}</span>
      <span v-if="serviceRequest.status === 'complete'" class="text-lg">₲ {{ serviceRequest.service_finished_fee | thousandSeparator }}</span>
    </div>
    <div class="w-full flex flex-col">
      <div
        v-for="detail in details"
        :key="detail.id"
        class="flex justify-between mb-5 p-5 service-item"
      >
        <span class="text-lg font-bold">{{ detail.service.description }}</span>
        <span class="text-lg">x{{ detail.quantity }}</span>
        <span v-if="serviceRequest.status !== 'complete'" class="text-lg">
          ₲ {{ (detail.service_request.serviceFee * detail.quantity) | thousandSeparator }}
        </span>
        <span v-if="serviceRequest.status === 'complete'" class="text-lg">
          ₲ {{ (detail.service_request.service_finished_fee * detail.quantity) | thousandSeparator }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import thousandSeparator from "@/utils/filters/thousandSeparator";
export default {
  props: {
    serviceRequest: {
      type: Object,
      default: () => ({}),
    },
    details: {
      type: Array,
      default: () => [],
    },
    extra_services: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    thousandSeparator,
  },
  computed: {
    totalPrice() {
      const total_services = this.details.reduce((accumulator, detail) => {
        return accumulator + detail.quantity * detail.service.price;
      }, 0);
      const total_extra_services = this.extra_services.reduce((accumulator, service) => {
        return accumulator + service.price;
      }, 0);

      return total_extra_services + total_services;
    },
  },
};
</script>
<style lang="css" scoped>
.services-container {
  max-height: 285px;
  overflow: scroll;
}
.service-item {
  border-left: 3px solid #a3a3a3;
}
</style>
