<template>
  <svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M36 34.0132C36 35.1105 35.1103 36.0001 34.0131 36.0001H24.8392V22.0591H29.5187L30.2193 16.6261H24.8392V13.1571C24.8392 11.5841 25.2761 10.5121 27.5319 10.5121L30.4089 10.5109V5.65157C29.9111 5.58534 28.2033 5.4374 26.2166 5.4374C22.0684 5.4374 19.2286 7.96935 19.2286 12.6193V16.6261H14.5371V22.0591H19.2286V36.0001H1.98689C0.889313 36.0001 0 35.1105 0 34.0132V1.98689C0 0.889453 0.889313 0 1.98689 0H34.0131C35.1103 0 36 0.889453 36 1.98689V34.0132Z" fill="white"/>
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:36
    },
    height:{
      type:Number,
      default:36
    }
  }
}
</script>
