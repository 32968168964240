import store from "@/store";
import redirectBasedRole from "./redirect-base-role";
const isProfessional = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  if (store.getters.user.userType == "professional") {
    return redirectBasedRole(next);
  }
  return next();
};
export default isProfessional;
