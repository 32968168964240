<template>
  <div class="w-full flex flex-col items-center">
    <ProfilePicture class="mr-3 w-1/2" :url="client.pictureProfileUrl" />
    <span class="client-fullname my-3">{{ client.firstName }} {{ client.lastName }}</span>
    <template v-for="(key, i) in Object.keys(profile_data)">
      <div :key="i" class="w-full flex justify-between items-center">
        <span class="profile-label">{{ key }}:</span>
        <span class="profile-data ml-3">{{ profile_data[key] }}</span>
      </div>
    </template>
    <div class="w-full my-3">
      <div @click="$emit('show-service-requests')" class="btn btn--primary">
        Trabajos contratados
      </div>
    </div>
  </div>
</template>
<script>
import { ProfilePicture } from "@/components";

export default {
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    profile_data() {
      if (!this.client) return {};
      return {
        "C.I": this.client.identificationNumber || "Sin número de documento",
        "R.U.C": this.client.ruc || "Sin número de R.U.C",
        "Fecha de Nacimiento": this.client.birthDate || "Sin fecha de nacimiento",
        Email: this.client.email || "Sin email",
        "Teléfono 1": this.client.phoneNumber || "Sin número de teléfono",
        "Teléfono 2": this.client.phoneNumber2 || "Sin número de teléfono",
        "Dirección ": this.client.address || "Sin dirección",
        Ciudad: this.client.location.description || "Sin ciudad",
        Departamento: this.client.location.department.description || "Sin departamento",
      };
    },
  },
  components: {
    ProfilePicture,
  },
};
</script>
<style scoped>
.client-fullname {
  font-weight: 700 !important;
  font-size: 1.5rem !important;
}
.profile-label {
  font-weight: 700 !important;
}
</style>
