<template>
  <section class="px-2 lg:w-8/12">
    <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
      <h4 class="mb-3 lg:mt-0 lg:mb-0">
        <svg class="-mt-2 icon">
          <use
            href="../../assets/img/icons.svg#icon-todo"
            xlink:href="../../assets/img/icons.svg#icon-todo"
          />
        </svg>
        Pedido de Servicios
      </h4>
      <div>
        <label class="inline-flex items-center mb-0">
          <input
            type="radio"
            class="form-radio"
            name="solicitudEstado"
            value
            v-model="reviewStatus"
            @change="GetProfessionalReview()"
          />
          <span class="ml-2">All</span>
        </label>
        <label class="inline-flex items-center mb-0 ml-6">
          <input
            type="radio"
            class="form-radio"
            name="solicitudEstado"
            value="pending"
            v-model="reviewStatus"
            @change="GetProfessionalReview()"
          />
          <span class="ml-2">Pendientes</span>
        </label>
        <label class="inline-flex items-center mb-0 ml-6">
          <input
            type="radio"
            class="form-radio"
            name="solicitudEstado"
            value="rejected"
            v-model="reviewStatus"
            @change="GetProfessionalReview()"
          />
          <span class="ml-2">rechazada</span>
        </label>
      </div>
    </div>
    <div v-if="reviews.length > 0">
      <div
        class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
        v-for="(review, index) in reviews"
        :key="index"
      >
        <header
          class="flex items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white border-b border-gray-200"
        >
          <div class="flex flex-col lg:flex-row">
            <strong>Estado:</strong>
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
              v-if="review.status == 'accepted'"
              >Aceptado</span
            >
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary"
              v-if="review.status == 'pending'"
              >Pendiente</span
            >
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary error"
              v-if="review.status == 'rejected'"
              >Rechazado</span
            >
          </div>
        </header>

        <div class="flex flex-col justify-between pt-4 lg:flex-row">
          <figure class="flex items-center lg:mb-0 lg:w-2/12 lg:mr-4 lg:flex-col">
            <img
              :src="review.Professional.pictureProfileUrl"
              :alt="review.Professional.firstName + ' ' + review.Professional.lastName"
              class="object-cover shadow avatar avatar--xl"
            />
            <figcaption
              class="pl-2 text-2xl text-center text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
            >
              {{ review.Professional.firstName }} {{ review.Professional.lastName }}
            </figcaption>
          </figure>

          <hr class="my-3 -mx-5 border-gray-200 lg:hidden" />

          <div class="flex-1">
            <div class="flex flex-col -mx-2 lg:flex-row">
              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Servicio solicitado:</h6>
                <ul class="mb-3 ml-8 list-disc">
                  <li v-for="(Service, index) in review.Services" :key="index">
                    {{ Service.description }}
                  </li>
                </ul>
                <h6 class="mb-0">Rate:</h6>
                <p>
                  <star-rating
                    inactive-color="#dfe0e6"
                    :show-rating="false"
                    active-color="#ffd83e"
                    :increment="0.5"
                    :star-size="20"
                    :read-only="true"
                    :rating="review.rate | numberFormat"
                  ></star-rating>
                </p>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Review At:</h6>
                <p>{{ moment(review.createdAt).format("DD/MM/YYYY - HH:MM") }}hs</p>
                <h6 class="mb-0">Precio:</h6>
                <p>Gs.{{ review.Service.serviceFinishedFee | thousandSeparator }}</p>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-2">Submitted By:</h6>
                <figure class="flex lg:flex-col">
                  <img
                    :src="review.User.pictureProfileUrl"
                    :alt="review.User.firstName + ' ' + review.User.lastName"
                    class="object-cover shadow avatar avatar--xl"
                  />
                  <figcaption
                    class="pl-2 text-2xl text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
                  >
                    {{ review.User.firstName }} {{ review.User.lastName }}
                    <p v-if="review.User.email" class="text-sm">{{ review.User.email }}</p>
                    <p v-if="review.User.phoneNumber">{{ review.User.phoneNumber }}</p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <h6 class="mb-0">Review:</h6>
            <p>{{ review.description }}</p>
          </div>
        </div>

        <footer
          class="relative flex flex-col items-center px-5 py-2 mt-3 -mx-5 text-xs bg-gray-100 border-t lg:flex-row bordera-gray-200"
        >
          <div class="pb-12 lg:pb-0 lg:pr-24">
            <span class="pr-1 text-brand-secondary">(*)</span>Aceptado la solicitud, el prefesional
            se pondrá en contacto con usted, para coordinar el servicio, habilitando el botón de
            pago una vez que haya concluido el servicio solicitado
          </div>
          <div class="absolute bottom-0 flex-none w-full lg:relative lg:w-auto" v-if="reviewStatus">
            <button
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
              @click="updateReviewStatus(review.id, 'accepted')"
              v-if="reviewStatus == 'pending'"
              :disabled="processingUpdateReview"
            >
              Accept
            </button>
            <button
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--danger ml-6"
              @click="updateReviewStatus(review.id, 'rejected')"
              v-if="reviewStatus == 'pending'"
              :disabled="processingUpdateReview"
            >
              Reject
            </button>
            <button
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--secondary ml-6"
              @click="updateReviewStatus(review.id, 'pending')"
              v-if="reviewStatus == 'rejected'"
              :disabled="processingUpdateReview"
            >
              Revertir
            </button>
          </div>
        </footer>
      </div>
    </div>
    <div class="container msg-section" v-else>
      <section
        class="flex flex-col items-center md:flex-row alert alert--success shadow alert--info"
      >
        <div class="md:pr-6 mb-2">
          <svg class="text-6xl text-blue-800 icon md:text-5xl">
            <use
              href="../../assets/img/icons.svg#icon-alert-circle-i"
              xlink:href="../../assets/img/icons.svg#icon-alert-circle-i"
            />
          </svg>
        </div>
        <div class="text-center md:text-left">
          <p class="text-sm">No Review Found.</p>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AdminProfileSidebar from "@/components/AdminProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import numberFormat from "@/utils/filters/numberFormat";
import totalServiceCost from "@/utils/methods/totalServiceCost";
export default {
  data() {
    return {
      processingGetReviews: false,
      processingUpdateReview: false,
      reviewStatus: "pending",
      reviews: {},
      updateReviewData: {
        reviewId: null,
        requestData: {
          status: "",
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetProfessionalReview();
  },
  methods: {
    GetProfessionalReview() {
      this.processingGetReviews = true;
      this.$store
        .dispatch("GetProfessionalReview", {
          reviewStatus: this.reviewStatus,
        })
        .then((response) => {
          this.reviews = response.reviewsResult;
          this.processingGetReviews = false;
        })
        .catch(() => {
          this.processingGetReviews = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    updateReviewStatus(reviewId, status) {
      this.processingUpdateReview = true;
      this.updateReviewData.reviewId = reviewId;
      this.updateReviewData.requestData.status = status;
      this.$store
        .dispatch("updateReviewStatus", this.updateReviewData)
        .then(() => {
          this.processingUpdateReview = false;
          this.GetProfessionalReview();
          let registerMessage = `Review Status Updated Successfully.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", registerMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingUpdateReview = false;
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    totalServiceCost,
  },
  filters: {
    thousandSeparator,
    numberFormat,
  },
  computed: {
    ...mapGetters(["error", "user"]),
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    AdminProfileSidebar,
  },
};
</script>
<style lang="css" scoped>
@import "../../assets/css/dashboard.css";
.success {
  color: #1bb01b;
}
.error {
  color: #e54d42;
}
.btn--danger,
.btn--danger:hover {
  background-color: red;
}
</style>
