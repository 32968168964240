<template>
    <div
      class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
    >
      <div :style="professional.active ? 'opacity:1' : 'opacity:.5'">
        <header
          class="flex items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white border-b border-gray-200"
        >
          <div class="flex flex-col lg:flex-row">
            <strong>Estado:</strong>
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary info"
              v-if="!professional.active"
            >Inactivado</span
            >
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
              v-if="professional.status == 'accepted' && professional.active"
            >Aceptado</span
            >
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary"
              v-if="professional.status == 'pending' && professional.active"
            >Pendiente</span
            >
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary error"
              v-if="professional.status == 'rejected' && professional.active"
            >Rechazado</span
            >
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
              v-if="professional.status == 'complete' && professional.active"
            >Completado</span
            >
          </div>
          <div class="flex flex-col lg:flex-row">
            <router-link
              :to="{
                  name: 'AdminEditProfessionalProfile',
                  params: { id: professional.userId },
                }"
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
              v-if="professional.status == 'complete'"
            >
              Editar perfil
            </router-link>
            <router-link
              :to="{
                  name: 'AdminEditProfessionalPhoto',
                  params: { id: professional.userId },
                }"
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success ml-2"
              v-if="professional.status == 'complete'"
            >
              Editar fotos
            </router-link>
            <!--
            <button
              @click="($event) => deleteProfessional(professional.userId)"
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--danger ml-2"
            >
              Dar de baja
            </button>
            -->
          </div>
        </header>

        <div class="flex flex-col justify-between pt-4 lg:flex-row">
          <ProfilePicture
              :url="professional.pictureProfileUrl"
              class="avatar avatar--xl"
          />
          <hr class="my-3 -mx-5 border-gray-200 lg:hidden" />

          <div class="flex-1">
            <div class="flex flex-col -mx-2 lg:flex-row">
              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Email:</h6>
                <p>{{ professional.email }}</p>
                <div v-if="professional.status == 'accepted'">
                  <!--
                  <button
                    class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
                    @click="resendActivateEmail(professional.userId)"
                    :disabled="processingResendActivateEmail"
                  >
                    Reenviar email
                  </button>
                  -->
                </div>
                <h6 class="mb-0">Cellular:</h6>
                <p>{{ professional.phoneNumber }}</p>
                <h6 class="mb-0">RUC/C.I:</h6>
                <p>{{ professional.identificationNumber }}</p>
                <h6 class="mb-0">Servicio:</h6>
                <ul class="mb-3 ml-8 list-disc">
                  <li v-for="(Service, index) in professional.Services" :key="index">
                    {{ Service.description }}
                  </li>
                </ul>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Dirección:</h6>
                <p>{{ professional.address }}</p>
                <h6 class="mb-2">Ciudad:</h6>
                <p>{{ professional.Location }}</p>
                <h6 class="mb-0">Profesiones:</h6>
                <ul class="mb-3 ml-8 list-disc">
                  <li v-for="(item, index) in professional.Profession" :key="index">
                    {{ item.description }}
                  </li>
                </ul>
                <h6 class="mb-2">Otro oficio / categoría:</h6>
                <p>{{ professional.otherProfession }}</p>
                <h6 class="mb-3">Url de registro (2nda parte):</h6>
                <ul class="mb-3 ml-8 list-disc">
                  <li>
                    <a :href="professional.registrationUrl" target="_blank">{{
                        professional.registrationUrl
                      }}</a>
                  </li>
                </ul>
                <h6 class="mb-0">Ubicación de zona laboral</h6>
                <ul class="mb-3 ml-8 list-disc">
                  <li>
                    <a :href="professional.locationUrl" target="_blank">{{
                        professional.locationUrl
                      }}</a>
                  </li>
                </ul>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Referencia laboral 1:</h6>
                <p>
                  {{ professional.laboralReferenceName1 }} <br />
                  {{ professional.laboralReferencePhone1 }}
                </p>
                <h6 class="mb-0">Referencia laboral 2:</h6>
                <p>
                  {{ professional.laboralReferenceName2 }} <br />
                  {{ professional.laboralReferencePhone2 }}
                </p>
                <h6 class="mb-0">Fecha de Registro:</h6>
                <p>
                  {{ professional.createdAt }}
                </p>
              </div>
            </div>

            <h6 class="mb-0">Descripción del servicio:</h6>
            <p>{{ professional.coverLetter }}</p>
            <h6>Adjuntar archivos:</h6>
            <ul
              class="mb-3 ml-8 list-disc"
              v-if="professional.Files && professional.Files.length > 0"
            >
              <li v-for="(File, index) in professional.Files" :key="index">
                <a :href="File.fileUrl" target="_blank">{{ File.description }}</a>
              </li>
            </ul>
          </div>
        </div>

        <footer
          class="relative flex flex-col items-center px-5 py-2 mt-3 -mx-5 text-xs bg-gray-100 border-t lg:flex-row bordera-gray-200"
        >
          <div class="pb-12 lg:pb-0 lg:pr-24">
            <span class="pr-1 text-brand-secondary">(*)</span>Aceptado la solicitud, el
            prefesional se pondrá en contacto con usted, para coordinar el servicio, habilitando
            el botón de pago una vez que haya concluido el servicio solicitado
          </div>
          <!--<div
            class="absolute bottom-0 flex-none w-full lg:relative lg:w-auto"
            v-if="professionalStatus"
          >
            <button
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
              @click="updateProfessionalStatus(professional.userId, 'accepted')"
              v-if="professionalStatus == 'pending'"
              :disabled="processingUpdateProfessional"
            >
              Aceptar
            </button>
            <button
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--danger ml-6"
              @click="updateProfessionalStatus(professional.userId, 'rejected')"
              v-if="professionalStatus == 'pending'"
              :disabled="processingUpdateProfessional"
            >
              Rechazar
            </button>
            <button
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--secondary ml-6"
              @click="updateProfessionalStatus(professional.userId, 'pending')"
              v-if="professionalStatus == 'rejected'"
              :disabled="processingUpdateProfessional"
            >
              Revertir
            </button>
          </div>-->
        </footer>
      </div>
    </div>
</template>
<script>
import ProfilePicture from "@/components/ProfilePicture.vue";

export default {
  components: {ProfilePicture},
  props:{
    professional:{
      type:Object,
      default:()=>({})
    }
  }
}
</script>
