import Toast from "@/components/toast";
const handleError = error=>{
  switch(error.code) {
    case error.PERMISSION_DENIED:
      Toast("Rechazaste que podamos usar tu ubicación")
      break;
    case error.POSITION_UNAVAILABLE:
      Toast("Tu ubicación no está disponible")
      break;
    case error.TIMEOUT:
      Toast("No pudimos obtener tu ubicación")
      break;
    case error.UNKNOWN_ERROR:
      Toast("Tuvimos un error desconocido al tratar de obtener tu ubicación")
      break;
  }
}
export default (handlePosition=()=>{})=>{
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(handlePosition,handleError);
  } else {
    Toast("Este navegador no soporta geolocalización")
  }
}
