import axios from "axios";

import * as ls from "@/config/local-storage-help";
export const baseURL = process.env.VUE_APP_BASE_URL || "http://localhost:3000";
// eslint-disable-next-line
const HTTP = axios.create({
  baseURL,
  timeout: 20000
});
HTTP.interceptors.response.use(
  response => {
    /** TODO: Add any response interceptors */

    return response;
  },
  error => {
    /** TODO: Do something with response error */

    if (error.response.status == 401) {
      ls.removeLocalStorage();
      window.location.replace("/login/client");
    } else {
      return Promise.reject(error);
    }
  }
);
let token = ls.getToken();
if (token) {
  HTTP.defaults.headers.common["Authorization"] = "Bearer " + token;
}
export { HTTP };
