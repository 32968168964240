<template>
  <div class="w-full flex flex-col items-center">
    <img
      ref="profilePicture"
      :src="
        !!client.pictureProfileUrl
          ? client.pictureProfileUrl
          : '/img/default-user-icon.4aa4dc7e.jpg'
      "
      class="rounded-full mr-3 w-1/2"
      alt=""
    />
    <div class="w-full">
      <fieldset>
        <label>
          <span>Nombres</span>
          <input class="form-input mt-1 block w-full" type="text" v-model="names" />
        </label>
        <label>
          <span>Apellidos</span>
          <input class="form-input mt-1 block w-full" type="text" />
        </label>
        <label>
          <span>Cédula de identidad</span>
          <input class="form-input mt-1 block w-full" type="text" />
        </label>
        <label>
          <span>R.U.C</span>
          <input class="form-input mt-1 block w-full" type="text" />
        </label>
        <label>
          <span>Email</span>
          <input class="form-input mt-1 block w-full" type="text" />
        </label>
        <label>
          <span>Teléfono 1</span>

          <VuePhoneNumberInput
            color="#63b3ed"
            class="mt-1 bg-red-100 border-red-700 OnErrorScrollHere'"
            valid-color="#63b3ed"
            error-color="#63b3ed"
            default-country-code="PY"
            :translations="{
              countrySelectorLabel: 'Prefijo',
              countrySelectorError: 'Elija un prefijo',
              phoneNumberLabel: 'Ingresa tu número',
              example: 'Ejemplo :',
            }"
            :no-example="true"
            :show-code-on-list="true"
          />
        </label>
        <label>
          <span>Teléfono 2</span>

          <VuePhoneNumberInput
            color="#63b3ed"
            class="mt-1 bg-red-100 border-red-700 OnErrorScrollHere'"
            valid-color="#63b3ed"
            error-color="#63b3ed"
            default-country-code="PY"
            :translations="{
              countrySelectorLabel: 'Prefijo',
              countrySelectorError: 'Elija un prefijo',
              phoneNumberLabel: 'Ingresa tu número',
              example: 'Ejemplo :',
            }"
            :no-example="true"
            :show-code-on-list="true"
          />
        </label>
        <label>
          <span>Dirección</span>
          <textarea class="form-input mt-1 block w-full"></textarea>
        </label>
        <label>
          <span>Ciudad</span>
          <select class="block w-full mt-1 rounded form-select">
            <option disabled :value="null">Seleccionar ciudad</option>
            <option v-for="department in []" :key="department.id" :value="department.id">
              {{ department.description }}
            </option>
          </select>
        </label>
      </fieldset>
    </div>
  </div>
</template>
<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      names: '',
      lastNames: '',
      ci: '',
      ruc: '',
      email: '',
      phone1: '',
      phone2: '',
      address: '',
      city_id: '',
    };
  },
  mounted() {
    this.setProfileData();
    this.$refs['profilePicture'].setAttribute(
      'src',
      !!this.client.pictureProfileUrl
        ? this.client.pictureProfileUrl
        : '/img/default-user-icon.4aa4dc7e.jpg',
    );
  },
  methods: {
    setProfileData() {
      this.names = this.client.firstName;
    },
  },
  components: {
    VuePhoneNumberInput,
  },
};
</script>
<style scoped></style>
