<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="flex py-4 md:my-10">
      <div class="container container-xl flex flex-col md:flex-row md:items-center">
        <div class="w-full md:w-1/2">
          <form
            @submit.prevent="onSubmit"
            method="post"
            class="p-5 md:p-10 rounded border border-gray-400 bg-white"
          >
            <h3 class="mb-8">Recuperar contraseña</h3>
            <label>
              <span>Email/Teléfono:</span>
              <input
                class="form-input mt-1 block w-full"
                v-bind:class="{ 'bg-red-100 border-red-700': submited && !emailTel }"
                type="text"
                placeholder="Ingresa el correo o teléfono con el cual te registraste"
                v-model="emailTel"
              />
              <span class="help-text block mt-1">
                En caso de recuperar a través del teléfono ingresar con el código de país
              </span>
              <span class="error" v-if="submited && !emailTel"
                >Por favor ingrese su correo electrónico o número de teléfono</span
              >
            </label>
            <button
              type="submit"
              class="btn btn--md btn--secondary w-full"
              :disabled="processingForgot"
            >
              Enviar
            </button>
          </form>
        </div>

        <div class="w-full md:w-1/2">
          <section class="mb-5 text-center md:p-20 md:mb-0">
            <h4>¿Algún problema técnico para ingresar?</h4>
            <p>
              Si todavía no puedes ingresar, puedes ponerte en contacto con nosotros, que te
              responderemos en la brevedad posible
            </p>

            <router-link to="/register-client" class="underline">
              <svg class="mr-1 icon">
                <use
                  href="../assets/img/icons.svg#icon-email"
                  xlink:href="../assets/img/icons.svg#icon-email"
                />
              </svg>
              Envianos un correo
            </router-link>
          </section>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import validateEmail from "@/utils/methods/validateEmail";
export default {
  data() {
    return {
      colorMessage: "#66A7E5",
      submited: false,
      emailTel: "",
      processingForgot: false,
      forgoterrormessage: ""
    };
  },
  methods: {
    validateEmail,
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingForgot = true;
      if (this.validateForm()) {
        this.$store
          .dispatch("forgotPasswordEmail", {
            emailTel: this.emailTel
          })
          .then(() => {
            this.processingForgot = false;
            let resetpasswordMessage = `Mensaje de recuperación enviado correctamente`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", resetpasswordMessage);
            this.$store.dispatch("setShowAlert", true);
          })
          .catch(() => {
            this.processingForgot = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
          });
      } else {
        this.processingForgot = false;
      }
    },
    validateForm() {
      return this.emailTel != "";
    }
  },
  computed: {
    ...mapGetters(["error"])
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb
  }
};
</script>
<style scoped>
@import "../assets/css/ingresar-registro.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.help-text {
  font-weight: normal;
  color: #686b79;
  font-family: inherit;
  font-size: 13px;
}
</style>
