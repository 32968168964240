/**
 * Initial State
 */
const initialBase = {
  user: {
    firstName: "",
    lastName: "",
    token: "",
    userId: "",
    userName: "",
    userType: ""
  }
};
const setInitialState = () => {
  const init = JSON.stringify(initialBase);
  localStorage.setItem("user", init);
};

export const setLocalStorage = state => {
  localStorage.setItem("user", JSON.stringify(state));
  const user = localStorage.getItem("user");
  return JSON.parse(user);
};

export const getLocalStorage = () => {
  let user = {};
  let localStorageUser = localStorage.getItem("user");
  if (localStorageUser != null) {
    user = JSON.parse(localStorageUser);
    user = user.user;
  }
  return user;
};
export const getToken = () => {
  let token = "";
  let localStorageUser = localStorage.getItem("user");
  if (localStorageUser != null) {
    let user = JSON.parse(localStorageUser);
    token = user.token;
  }
  return token;
};

export const removeLocalStorage = () => {
  localStorage.removeItem("user");
  setInitialState();
};
export const setPaymentHash = state => {
  localStorage.setItem("paymentHash", JSON.stringify(state));
  const paymentHash = localStorage.getItem("paymentHash");
  return JSON.parse(paymentHash);
};

export const getPaymentHash = () => {
  let paymentHash = {};
  let localStoragePaymentHash = localStorage.getItem("paymentHash");
  if (localStoragePaymentHash != null) {
    paymentHash = JSON.parse(localStoragePaymentHash);
  }
  return paymentHash;
};
export const removePaymentHash = () => {
  localStorage.removeItem("paymentHash");
  
};
export const setPaymentStatus = state => {
  let paymentHash = {};
  let localStoragePaymentHash = localStorage.getItem("paymentHash");
  if (localStoragePaymentHash != null) {
    paymentHash = JSON.parse(localStoragePaymentHash);
  }
  let orderID=paymentHash.orderID;
  state.orderID=orderID;
  localStorage.setItem("paymentStatus", JSON.stringify(state));
  const paymentStatus = localStorage.getItem("paymentStatus");
  removePaymentHash();
  return JSON.parse(paymentStatus);
};

export const getPaymentStatus = () => {
  let paymentStatus = {};
  let localStoragePaymentStatus = localStorage.getItem("paymentStatus");
  if (localStoragePaymentStatus != null) {
    paymentStatus = JSON.parse(localStoragePaymentStatus);
  }
  return paymentStatus;
};
export const removePaymentStatus = () => {
  localStorage.removeItem("paymentStatus");
};
export const setClientTempData = state => {
  localStorage.setItem("ClientTempData", JSON.stringify(state));
  const ClientTempData = localStorage.getItem("ClientTempData");
  return JSON.parse(ClientTempData);
};

export const getClientTempData = () => {
  let ClientTempData = null;
  let localClientTempData = localStorage.getItem("ClientTempData");
  if (localClientTempData != null) {
    ClientTempData = JSON.parse(localClientTempData);
  }
  return ClientTempData;
}; 
export const removeClientTempData = () => {
  localStorage.removeItem("ClientTempData");
};
export const setStepTempData = state => {
  localStorage.setItem("StepTempData", JSON.stringify(state));
  const StepTempData = localStorage.getItem("StepTempData");
  return JSON.parse(StepTempData);
};

export const getStepTempData = () => {
  let StepTempData = null;
  let localStepTempData = localStorage.getItem("StepTempData");
  if (localStepTempData != null) {
    StepTempData = JSON.parse(localStepTempData);
  }
  return StepTempData;
}; 
export const removeStepTempData = () => {
  localStorage.removeItem("StepTempData");
};

// Pantalla completar perfil professional

export const getRubros = () => {
  let rubros = [{year:1,services:[]}];
  let localRubros = localStorage.getItem("rubros");

  if (localRubros != null) {
    rubros = JSON.parse(localRubros);
  } else {
    localStorage.setItem("rubros", JSON.stringify(rubros))
  }

  return rubros;
};

export const addRubro = rubro => {
  let rubros = getRubros()

  rubros.push(rubro)

  localStorage.setItem("rubros", JSON.stringify(rubros));
};

export const updateRubro = (rubro, rubroIndex) => {
  let rubros = getRubros()

  rubros[rubroIndex] = rubro

  localStorage.setItem("rubros", JSON.stringify(rubros));
};

export const deleteRubro = (rubroIndex) => {
  let rubros = getRubros()

  const newRubros = rubros.filter((rubro, index) => index !== rubroIndex)

  localStorage.setItem("rubros", JSON.stringify(newRubros));
};

export const removeRubros = () => {
  localStorage.removeItem("rubros");
};

export const getProfessions = () => {
  let professions = [];
  let localProfessions = localStorage.getItem("professions");

  if (localProfessions != null) {
    professions = JSON.parse(localProfessions);
  }

  return professions;
};

export const setProfessions = state => {
  localStorage.setItem("professions", JSON.stringify(state));
};

export const removeProfessions = state => {
  localStorage.removeItem("professions");
};

export const getDistanceKm = () => {
  let distanceKm = 1;

  if (localStorage.getItem("distanceKm") != null) {
    distanceKm = localStorage.getItem("distanceKm");
  } else {
    localStorage.setItem("distanceKm", JSON.stringify(distanceKm))
  }

  return distanceKm;
};

export const setDistanceKm = state => {
  localStorage.setItem("distanceKm", state);
};

export const removeDistanceKm = state => {
  localStorage.removeItem("distanceKm");
};

export const getAddress = () => {
  let address = "";

  if (localStorage.getItem("address") != null) {
    address = localStorage.getItem("address");
  } else {
    localStorage.setItem("address", JSON.stringify(address))
  }

  return address;
};

export const setAddress = state => {
  localStorage.setItem("address", state);
};

export const removeAddress = state => {
  localStorage.removeItem("address");
};

export const getLocation = () => {
  let location = null;

  if (localStorage.getItem("location") != null) {
    location = JSON.parse(localStorage.getItem("location"));
    location = [location.lat, location.lng]
  }

  return location;
};

export const setLocation = state => {
  const location = {
    lat: state[0],
    lng: state[1]
  }
  localStorage.setItem("location", JSON.stringify(location));
};

export const removeLocation = state => {
  localStorage.removeItem("location");
};

export const getLocationPermission = () => {
  let locationPermission = null;

  if (localStorage.getItem("locationPermission") != null) {
    locationPermission = JSON.parse(localStorage.getItem("locationPermission"));
  }

  return locationPermission;
};

export const setLocationPermission = state => {
  localStorage.setItem("locationPermission", state);
};

export const removeLocationPermission = state => {
  localStorage.removeItem("locationPermission");
};

// Datos temporales de registro de clientes

export const setRegisterClientTempData = state => {
  localStorage.setItem("RegisterClientTempData", JSON.stringify(state));
  const RegisterClientTempData = localStorage.getItem("RegisterClientTempData");
  return JSON.parse(RegisterClientTempData);
};

export const getRegisterClientTempData = () => {
  let RegisterClientTempData = null;
  let localRegisterClientTempData = localStorage.getItem("RegisterClientTempData");
  if (localRegisterClientTempData != null) {
    RegisterClientTempData = JSON.parse(localRegisterClientTempData);
  }
  return RegisterClientTempData;
};

export const removeRegisterClientTempData = () => {
  localStorage.removeItem("RegisterClientTempData");
};

export const setRegisterClientCurrentStep = state => {
  localStorage.setItem("RegisterClientCurrentStep", state);
  const RegisterClientCurrentStep = localStorage.getItem("RegisterClientCurrentStep");
  return JSON.parse(RegisterClientCurrentStep);
};

export const getRegisterClientCurrentStep = () => {
  let RegisterClientCurrentStep = null;
  let localRegisterClientCurrentStep = localStorage.getItem("RegisterClientCurrentStep");
  if (localRegisterClientCurrentStep != null) {
    RegisterClientCurrentStep = localRegisterClientCurrentStep;
  }
  return RegisterClientCurrentStep;
};

export const removeRegisterClientCurrentStep = () => {
  localStorage.removeItem("RegisterClientCurrentStep");
};

// Datos temporales de registro de professionales

export const setRegisterProfessionalTempData = state => {
  localStorage.setItem("RegisterProfessionalTempData", JSON.stringify(state));
  const RegisterProfessionalTempData = localStorage.getItem("RegisterProfessionalTempData");
  return JSON.parse(RegisterProfessionalTempData);
};

export const getRegisterProfessionalTempData = () => {
  let RegisterProfessionalTempData = null;
  let localRegisterProfessionalTempData = localStorage.getItem("RegisterProfessionalTempData");
  if (localRegisterProfessionalTempData != null) {
    RegisterProfessionalTempData = JSON.parse(localRegisterProfessionalTempData);
  }
  return RegisterProfessionalTempData;
};

export const removeRegisterProfessionalTempData = () => {
  localStorage.removeItem("RegisterProfessionalTempData");
};

export const setRegisterProfessionalCurrentStep = state => {
  localStorage.setItem("RegisterProfessionalCurrentStep", state);
  const RegisterProfessionalCurrentStep = localStorage.getItem("RegisterProfessionalCurrentStep");
  return JSON.parse(RegisterProfessionalCurrentStep);
};

export const getRegisterProfessionalCurrentStep = () => {
  let RegisterProfessionalCurrentStep = null;
  let localRegisterProfessionalCurrentStep = localStorage.getItem("RegisterProfessionalCurrentStep");
  if (localRegisterProfessionalCurrentStep != null) {
    RegisterProfessionalCurrentStep = localRegisterProfessionalCurrentStep;
  }
  return RegisterProfessionalCurrentStep;
};

export const removeRegisterProfessionalCurrentStep = () => {
  localStorage.removeItem("RegisterProfessionalCurrentStep");
};
