var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"flex bg-white"},[_c('div',{staticClass:"container flex flex-col container-xl md:flex-row mt-6 md:mt-24 p-10 md:p-0 mb-48"},[_c('div',{staticClass:"w-full md:w-1/2 md:pr-2 mb-4 md:mb-0 mx-auto"},[_c('Messages'),_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('fieldset',{staticClass:"mb-3"},[_c('h4',{},[_vm._v("Datos personales")]),_c('p',{staticClass:"mb-6"},[_vm._v("Texto legal")]),_c('div',{staticClass:"grid grid-cols-1 xl-grid-cols-2 grid-gap-4"},[_c('div',{staticClass:"mt-[15px] xl:mt-[0px]"},[_c('form-label',{attrs:{"title":'Departamento'}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.department),expression:"department"}],staticClass:"block w-full rounded form-select",class:{
                    'bg-red-100 border-red-700 OnErrorScrollHere': _vm.submited && !_vm.department
                  },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.department=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.GetDepartmentLocations()}]}},[(_vm.activeDepartments.length <= 0)?_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Departamento no encontrado")]):_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccionar Departamento")]),_vm._l((_vm.activeDepartments),function(department){return _c('option',{key:department.id,domProps:{"value":department.id}},[_vm._v(_vm._s(department.description))])})],2),(_vm.submited && !_vm.department)?_c('span',{staticClass:"error"},[_vm._v("Por favor Ingresa tu Departamento")]):_vm._e()])],1),_c('div',{staticClass:"mt-[15px] xl:mt-[0px]"},[_c('form-label',{attrs:{"title":'Ciudad'}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.location),expression:"location"}],staticClass:"block w-full rounded form-select",class:{
                    'bg-red-100 border-red-700 OnErrorScrollHere': _vm.submited && !_vm.location
                  },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.location=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.activeDepartmentLocations.length <= 0)?_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Ciudad no encontrada")]):_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione Ciudad")]),_vm._l((_vm.activeDepartmentLocations),function(departmentLocation){return _c('option',{key:departmentLocation.id,domProps:{"value":departmentLocation.id}},[_vm._v(_vm._s(departmentLocation.description))])})],2),(_vm.submited && !_vm.location)?_c('span',{staticClass:"error"},[_vm._v("Por favor Ingresa tu Ciudad")]):_vm._e()])],1)]),_c('div',{staticClass:"grid grid-cols-1 xl-grid-cols-2 grid-gap-4 mt-[15px] mb-[30px] xl:mt-[0px] xl:mb-[15px]"},[_c('div',{staticClass:"mt-4"},[_c('form-label',{attrs:{"title":'Email'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"block w-full form-input",class:{
                    'bg-red-100 border-red-700 OnErrorScrollHere': _vm.submited && !_vm.validateEmail()
                  },attrs:{"type":"text","placeholder":"Ingresa tu correo electrónico"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.submited && !_vm.email)?_c('span',{staticClass:"error"},[_vm._v("Por favor ingrese su correo")]):_vm._e(),(_vm.submited && !_vm.validateEmail() && _vm.email)?_c('span',{staticClass:"error"},[_vm._v("Ingrese un correo válido")]):_vm._e()])],1),_c('div',{staticClass:"mt-[15px] xl:mt-[16px]"},[_c('form-label',{attrs:{"title":'Número de identidad'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.identificationNumber),expression:"identificationNumber"}],staticClass:"block w-full form-input",class:{
                    'bg-red-100 border-red-700 OnErrorScrollHere': _vm.submited && !_vm.identificationNumber
                  },attrs:{"type":"text","placeholder":"Ingresa tu número de identidad"},domProps:{"value":(_vm.identificationNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.identificationNumber=$event.target.value}}}),(_vm.submited && !_vm.identificationNumber)?_c('span',{staticClass:"error"},[_vm._v("Por favor ingresa tu número de identidad")]):_vm._e()])],1)])]),_c('label',{staticClass:"flex items-center mt-5"},[_c('div',{staticClass:"mx-auto"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.acceptPolicy),expression:"acceptPolicy"}],staticClass:"form-checkbox",class:{
                'bg-red-100 border-red-700 OnErrorScrollHere': _vm.submited && _vm.acceptPolicy != true
              },attrs:{"type":"checkbox","value":"true"},domProps:{"checked":Array.isArray(_vm.acceptPolicy)?_vm._i(_vm.acceptPolicy,"true")>-1:(_vm.acceptPolicy)},on:{"change":function($event){var $$a=_vm.acceptPolicy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="true",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.acceptPolicy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.acceptPolicy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.acceptPolicy=$$c}}}}),_c('span',{staticClass:"ml-2 font-normal font-body"},[_vm._v("He leído y acepto la "),_c('router-link',{staticClass:"underline",attrs:{"to":{ name: 'TermsCondition' },"target":"_blank"}},[_vm._v("política de privacidad y condiciones")])],1)])]),_c('label',{staticClass:"flex items-center"},[(_vm.submited && _vm.acceptPolicy != true)?_c('span',{staticClass:"error"},[_vm._v("Debe aceptar las politicas de privacidad y condiciones para registrarse")]):_vm._e()]),_c('div',{staticClass:"w-full flex justify-center"},[_c('div',{staticClass:"w-[100%] xl:w-[40%] flex flex-col items-center"},[_c('rounded-button',{attrs:{"onClick":_vm.onSubmit,"disabled":_vm.processingRegister}},[_vm._v(" Crear perfil cliente ")]),_c('div',{staticClass:"w-max flex items-center max-xl:flex-col justify-between mt-[21px]"},[_c('span',[_vm._v("¿Necesitas ayuda?")]),_c('div',[_c('whatsapp-icon',{staticClass:"mx-[6px] inline-block"}),_c('custom-link',{attrs:{"href":'#'}},[_vm._v("0981888777")])],1)])],1)])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }