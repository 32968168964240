<template>
  <section class="relative py-4">
    <picture class="absolute top-0 bottom-0 left-0 right-0 bg-buscador">
      <source media="(max-width: 640px)" srcset="../assets/img/new-image-bg-375.png" />

      <source media="(max-width: 1024px)" srcset="../assets/img/new-image-bg.png" />

      <source media="(max-width: 1366px)" srcset="../assets/img/new-image-bg.png" />

      <source media="(max-width: 1920px)" srcset="../assets/img/new-image-bg.png" />

      <img
        src="../assets/img/new-image-bg.png"
        class="object-cover w-full h-full"
        alt="Imagen Muestra"
      />
    </picture>

    <div class="container relative z-10 px-6 md:px-0  flex items-center buscador-ppal">
      <div class="w-full xl:w-10/12">
        <h2 class="text-white md:w-4/5">
          Encuentra ahora mismo a los mejores profesionales cerca tuyo
        </h2>

        <div
          class="flex flex-col items-stretch mb-6 text-lg  md:flex-row md:h-16 auto-profession"
          id="searchForm"
        >
          <div
            class="h-16 text-gray-800 placeholder-gray-800  md:w-2/3 mb-4 md:mb-0 md:rounded-r-none md:h-auto "
            :key="componentKey"
          >
            <div class="relative">
              <autocomplete
                ref="profession"
                placeholder="Escribe tu búsqueda aquí, por ejemplo; plomería"
                :source="professions"
                v-model="profession"
                :initialValue="profession"
                :initialDisplay="profession"
                results-display="description"
                resultsValue="description"
                inputClass="h-16 md:h-12  text-gray-800 placeholder-gray-800  border-none px-4 profession-value"
                id="buscador_field"
                @selected="setProfession"
                ><template #noResults>
                  Profesión inexistente
                </template>
              </autocomplete>
            </div>
          </div>

          <select
            v-model="location"
            class="h-16 text-gray-800 rounded form-select md:w-1/3 mb-4 md:mb-0 md:border-l-0 md:h-auto mx-0 md:mx-2"
          >
            <option selected disabled>¿Dónde?</option>
            <option
              v-for="location in activeLocations"
              :key="location.id"
              :value="{ id: location.id, name: location.description }"
              >{{ location.description }}</option
            >
          </select>
          <button
            @click="SearchProfessionals()"
            class="justify-center text-xl h-16 font-semibold btn btn--secondary mb-4 md:mb-0 md:w-1/3 md:h-auto icon-text-aligner px-3"
          >
            Buscar profesional
            <svg class="text-white text-base icon icon--outline icon--stroke-2 icon--md ml-auto">
              <use
                href="../assets/img/icons.svg#icon-search"
                xlink:href="../assets/img/icons.svg#icon-search"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GetLocations from "@/utils/methods/GetLocations";
import setDefaultLocation from "@/utils/methods/setDefaultLocation";
import activeLocations from "@/utils/computed/activeLocations";
import Autocomplete from "vuejs-auto-complete";
import scrollToSection from "@/utils/methods/scrollToSection";
export default {
  name: "Searcher",
  props: {
    msg: String,
    professions: Array
  },
  data() {
    return {
      processingSearch: false,
      location: { id: null, name: "" },
      locations: [],
      profession: "",
      componentKey: 0,
      services: [],
      service: [],
      advanceProfession: "",
      isShowSearch: false,
      isMultiOpen: false
    };
  },
  mounted() {
    this.GetLocations();
    this.setDefaultLocation();
  },
  computed: {
    activeLocations
  },
  methods: {
    GetLocations,
    setDefaultLocation,
    scrollToSection,
    SearchProfessionals() {
      this.isShowSearch = false;
      if (this.service.length > 0) {
        let serviceIds = [];
        for (var t = 0; t < this.service.length; t++) {
          serviceIds[t] = this.service[t].id;
        }
        this.$router.push({
          name: "ProfessionalsSearch",
          query: {
            profession: this.profession,
            location: this.location.id,
            services: "[" + serviceIds.join(",") + "]"
          }
        });
      } else {
        this.$router.push({
          name: "ProfessionalsSearch",
          query: {
            profession: this.profession,
            location: this.location.id
          }
        });
      }
    },
    changeProfession(professionItem) {
      this.profession = professionItem.description;
      this.componentKey += 1;
      this.$router.push({
        name: "ProfessionalsSearch",
        query: {
          profession: this.profession,
          location: this.location.id
        }
      });
    },
    GetServiceByType() {
      this.service = [];
      this.profession = this.advanceProfession.description;
      this.componentKey += 1;
      this.$store
        .dispatch("getServiceByType", {
          type: this.advanceProfession.type
        })
        .then(response => {
          this.services = response.services;
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    toggleAdvanceSearch() {
      this.isShowSearch = !this.isShowSearch;
    },
    setProfession(profession) {
      this.advanceProfession = profession.selectedObject;
      this.GetServiceByType();
    }
  },
  components: {
    Autocomplete
  }
};
</script>

<style scoped>
@import "../assets/css/index.css";
</style>
<style>
.auto-profession .autocomplete__box {
  border: none !important;
  border-radius: 0px !important;
}
.card-profesion {
  z-index: 5;
}

#buscador_field {
  background-color: #fff;
  border-color: #c0c3ce;
  border-radius: 0.25rem;
  padding: 0rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
@media (min-width: 768px) {
  #buscador_field {
    padding: 0.5rem 0.75rem;
  }
}
</style>
