<template>
  <div class="help bg-white">
    <header class="header bg-pattern-3">
      <div class="container justify-between">
        <div class="flex flex-wrap px-6 md:px-0">
          <figure class="w-full md:w-1/3 mb-6 md:mb-0 text-center md:text-left">
            <a href="/" class=""
              ><img src="../assets/img/hand-logotipo.svg" class="md:block inline-block"
            /></a>
          </figure>
          <div class="w-full md:w-1/3 mb-6 md:mb-0 md:-mt-8 text-center z-10">
            <img src="../assets/img/Grafico-Sellar-trato.png" class="inline-block" />
          </div>
          <div class="w-full md:w-1/3 md:mb-0 hidden md:block">
            <div class="header-right-text text-center md:text-right">
              Hand Paraguay
            </div>
          </div>
        </div>
      </div>
      <div class="container justify-between text-center container-lg container-xl mt-4">
        <div class="w-full lg:w-2/5 mx-auto px-6 sm:px-12 lg:px-4">
          <h5 class="banner-h5 mb-4 lg:px-6 text-xl md:text-2xl">
            Problemas para encontrar, electricista, plomero, carpintero…? Tranquilo
          </h5>
          <h2 class="banner-heading text-5xl md:text-4xl mb-4 lg:px-6">
            ¡Llegó HAND para darte una mano!
          </h2>
          <video class="mb-4" width="100%" height="auto" controls>
            <source src="../assets/img/hand-help.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </header>

    <main>
      <section class="flex py-5 md:py-4 lg:py-4 mb-2">
        <div class="container flex flex-col container-xl md:flex-row md:items-center">
          <div class="w-full mx-auto  px-6 sm:px-12 lg:px-4 lg:w-2/5 md:mb-0">
            <router-link :to="{ name: 'RegisterClient' }" class="text-lg">
              Registrate como usuario y empezá a contratar ayuda para tus proyectos
            </router-link>
          </div>
        </div>
      </section>
    </main>

    <footer class="footer pt-12 pb-24">
      <div class="container px-0 container-lg">
        <div class="flex flex-wrap">
          <div class="w-full px-2 mb-2 text-center">
            <p class="mt-2">
              (c) Hand Paraguay - Hand y la tipografía con una mano en la H son marcas registradas
              de Hand Paraguay. Todos los derechos reservados.
            </p>
            <img src="../assets/img/hand-logotipo-grey.svg" class="inline-block" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import validateEmail from "@/utils/methods/validateEmail";
import scrollToSection from "@/utils/methods/scrollToSection";
export default {
  data() {
    return { submited: false, email: "", name: "", processingIntrested: false };
  },
  methods: {
    validateEmail,
    scrollToSection,
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingIntrested = true;
      if (this.validateForm()) {
        this.$store
          .dispatch("registerInterested", {
            email: this.email,
            name: this.name
          })
          .then(() => {
            this.processingIntrested = false;
            this.submited = false;
            let Message = `Gracias por registrarse! muy pronto estará recibiendo novedades de Hand!`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", Message);
            this.$store.dispatch("setShowAlert", true);
            this.email = "";
            this.name = "";
            this.scrollToSection("intrested_form");
          })
          .catch(() => {
            this.processingIntrested = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            this.scrollToSection("intrested_form");
          });
      } else {
        this.processingIntrested = false;
      }
    },
    validateForm() {
      return this.name != "" && this.email != "" && this.validateEmail() == true;
    }
  },
  computed: {
    ...mapGetters(["error"])
  }
};
</script>
<style scoped>
@import "../assets/css/help.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
