import store from '@/store';
const redirectBasedRole = (next) => {
  const redirectByRole = {
    client: 'Dashboard',
    admin: 'AdminDashboard',
    professional: 'ProfessionalDashboard',
  };

  return next({ name: redirectByRole[store.getters.user.userType] });
};

export default redirectBasedRole;
