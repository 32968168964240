var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"px-2 mb-4 lg:w-3/12"},[_c('nav',{staticClass:"lg:-mt-4"},[_c('ul',[_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('service-request') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'ServiceRequest' }}},[_c('span',[_vm._v("Servicios solicitados")]),_c('svg',{class:[
              _vm.currentPage.includes('service-request') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('message') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'Message' }}},[_c('span',[_vm._v("Mensajes")]),_c('svg',{class:[
              _vm.currentPage.includes('message') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('a',{staticClass:"flex items-center justify-between py-4 text-black",attrs:{"href":"#"}},[_c('span',[_vm._v("Facturas")]),_c('svg',{staticClass:"text-black icon icon--lg"},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])]),_c('li',[_c('router-link',{class:[
                    _vm.currentPage.includes('client-complete-profile') ? 'text-brand-secondary' : 'text-black',
                    'flex items-center justify-between py-4'
                  ],attrs:{"to":{ name: 'ClientProfile' }}},[_c('span',[_vm._v("Mis datos")]),_c('svg',{class:[
              _vm.currentPage.includes('client-complete-profile') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }