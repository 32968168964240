export default function(file, key) {
  let imageHaveError = [];
  this.selectedProfessions[key].image = [];
  for (var i = 0; i < file.length; i++) {
    if (file[i].size > 1024 * 1024 * 10) {
      imageHaveError.push({ filename: file[i].name, hasError: "size" });
      this.selectedProfessions[key].images.splice(i, 1);
    } else {
      if (file[i]["type"] == "image/png" || file[i]["type"] == "image/jpeg") {
        var reader = new FileReader();
        var vm = this;
        reader.onload = e => {
          vm.selectedProfessions[key].image.push(e.target.result);
        };
        reader.readAsDataURL(file[i]);
      } else {
        imageHaveError.push({ filename: file[i].name, hasError: "type" });
        this.selectedProfessions[key].images.splice(i, 1);
      }
    }
  }
  if (imageHaveError.length > 0) {
    this.imageSizeError = "";
    for (var t = 0; t < imageHaveError.length; t++) {
      if (imageHaveError[t].hasError == "type") {
        this.imageSizeError +=
          imageHaveError[t].filename + " Formato de fotografía no soportado <br>";
      } else {
        this.imageSizeError +=
          imageHaveError[t].filename +
          " supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB <br>";
      }
      /* "El archivo " +
          imageHaveError.join(" , ") +
          " que intenta agregar supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB"; */
    }
  } else {
    this.imageSizeError = "";
  }
}
