import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as ls from "@/config/local-storage-help";
import GAuth from "vue-google-oauth2";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import StarRating from "vue-star-rating";
import vSelect from "vue-select";
import ToggleButton from "vue-js-toggle-button";
import VueCryptojs from "vue-cryptojs";
import "sweetalert2/dist/sweetalert2.min.css";
import VueMeta from "vue-meta";
import store from "./store";
import Router from "vue-router";
import "toastify-js/src/toastify.css"
import "@/assets/v2/css/global.css"
import "vue-select/dist/vue-select.css";
Vue.component('star-rating',StarRating)
Vue.component("v-select", vSelect);
Vue.config.productionTip = false;
Vue.prototype.$ls = ls;
Vue.prototype.moment = moment;
Vue.use(GAuth, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENTID,
  scope: "profile email",
  prompt: "consent",
});
Vue.use(VueCryptojs);
Vue.use(ToggleButton);
Vue.use(Router);
Vue.use(VueMeta);
moment.locale("es");
export const bus = new Vue();
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
