<template>
  <section class="px-2 lg:w-8/12">
    <SearchFilters />
  </section>
</template>
<script>
import SearchFilters from "@/views/admin/Sales/SearchFilters.vue";

export default {
  components: { SearchFilters },
};
</script>
