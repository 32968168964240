<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="flex">
      <magnifying-glass-icon color="#686b79" class="w-10 h-10 mr-3" />
      <span class="font-bold text-2xl">No se encontraron resultados</span>
    </div>
  </div>
</template>
<script>
import { MagnifyingGlassIcon } from "@/components/icons";

export default {
  components: {
    MagnifyingGlassIcon,
  },
};
</script>
