<template>
    <VuePhoneNumberInput
      v-model="phone"
      color="#63b3ed"
      :class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': false
                      }"
      valid-color="#63b3ed"
      error-color="#63b3ed"
      default-country-code="PY"
      :translations="translations"
      :no-example="true"
      :show-code-on-list="true"
      @update="onInput"
    />
</template>
<script>
import {CaretDownIcon} from "../icons"
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  components:{
    CaretDownIcon,
    VuePhoneNumberInput
  },
  data(){
    return {
      phone:null,
      translations:{
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :"
      }
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event);
    }
  }
}
</script>
<style scoped>
  .input{
    width: 100%;
    height: 37px;
    border-radius: 4px;
    border:1px solid #CCCCCC;
    display: flex;
    background-color: white;
  }

  .input

  .input .number-input{
    width: 100%;
    padding-left: 18px;
    height: 100%;
  }

</style>
