<template>
  <Datepicker
    v-model="dateRange"
    :range="true"
    lang="es"
    :circle="false"
    text-format="short"
    placeholder="Rango de fechas"
    :date-format="{
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }"
  />
</template>

<script>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";

export default {
  props: {
    onUpdateDateRange: {
      type: Function,
      default: () => {},
    },
    range: {
      type: Object,
      default: () => ({
        startDate: new Date(),
        endDate: new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000),
      }),
    },
  },
  data() {
    return {
      dateRange: [this.range.startDate, this.range.endDate],
    };
  },
  watch: {
    dateRange(range) {
      this.$emit("updated", {
        startDate: range[0],
        endDate: range[1],
      });
    },
  },
  components: {
    Datepicker: VueDatepickerUi,
  },
};
</script>
<style scoped>
* {
  text-transform: capitalize;
}
</style>
