<template>
  <div
    :class="[
      `w-full h-24 bg-white my-3 flex items-center justify-between p-3 cursor-pointer hover:bg-brand-primary hover:text-white`,
    ]"
    :style="professional.disabled ? `pointer-events: none; opacity: 0.7;` : ``"
    @click="() => onClick({ professional })"
  >
    <div class="flex items-center">
      <ProfilePicture class="w-16 h-16" :url="professional.user.pictureProfileUrl" />
      <span class="font-bold ml-3">{{
        professional.user.firstName + " " + professional.user.lastName
      }}</span>
    </div>
    <div v-if="professional.disabled" class="flex items-center">
      <span class="font-semibold mr-3">{{ disabled_message }}</span>
      <NoSymbolIcon class="w-12 h-12" color="#BEBEBE" />
    </div>
  </div>
</template>
<script>
import { ProfilePicture } from "@/components";
import { NoSymbolIcon } from "@/components/icons";
export default {
  props: {
    professional: {
      type: Object,
      default: () => ({}),
    },
    disabled_message: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    ProfilePicture,
    NoSymbolIcon,
  },
};
</script>
