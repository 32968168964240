<template>
  <div class="register-professional">
    <Header />
    <main>
      <div id="register_professional">
        <section class="py-5 md:py-8 lg:py-10 bg-white">
          <div class="container container-xl">
            <div class="flex flex-wrap">
              <div class="w-full px-6 mb-4 md:w-1/2" id="professional_form">
                <Messages />
                <h3 class="text-2xl" style="line-height: 29px">
                  Registrarme como profesional o empresa
                </h3>
                <p class="text-black text-xl">Empieza a conseguir nuevos clientes</p>
                <FacebookRegister :title="'Registrate con Facebook'"  @updateData="updateValuesFromSocial" class="my-2" />
                <GoogleRegister :title="'Registrate con Google'"  @updateData="updateValuesFromSocial" class="my-2" />
                <form class="rounded">
                  <fieldset class="p-4 mb-3 border border-gray-400 rounded">
                    <legend>Datos personales</legend>
                    <label>
                      <span>Nombre</span>
                      <input
                        class="block w-full mt-1 form-input"
                        type="text"
                        placeholder="Ingresa tu nombre completo aquí"
                        v-model="firstName"
                        ref="firstName"
                        v-bind:class="{
                          'bg-red-100 border-red-700':
                            (submited || requiredWarning.firstName) && !firstName,
                        }"
                        @focusout="requiredWarning.firstName = true"
                      />
                      <span
                        class="error"
                        v-if="(submited || requiredWarning.firstName) && !firstName"
                        >Por favor, introduzca su nombre</span
                      >
                    </label>

                    <label>
                      <span>Apellido</span>
                      <input
                        class="block w-full mt-1 form-input"
                        type="text"
                        placeholder="Ingresa tu apellido aquí"
                        v-model="lastName"
                        v-bind:class="{
                          'bg-red-100 border-red-700':
                            (submited || requiredWarning.lastName) && !lastName,
                        }"
                        @focusout="requiredWarning.lastName = true"
                      />
                      <span class="error" v-if="(submited || requiredWarning.lastName) && !lastName"
                        >Por favor ingrese su apellido</span
                      >
                    </label>
                    <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                      <div>
                        <label>
                          <span>Celular</span>
                          <VuePhoneNumberInput
                            v-model="telnumber"
                            color="#63b3ed"
                            class="mt-1"
                            v-bind:class="{
                              'bg-red-100 border-red-700':
                                (submited || requiredWarning.phoneNumber) && !isPhoneValid,
                            }"
                            valid-color="#63b3ed"
                            error-color="#63b3ed"
                            default-country-code="PY"
                            :translations="translations"
                            :no-example="true"
                            :show-code-on-list="true"
                            @update="phoneUpdate"
                            @phone-number-blur="requiredWarning.phoneNumber = true"
                          />
                          <span
                            class="error"
                            v-if="(submited || requiredWarning.phoneNumber) && !isPhoneValid"
                            >Por favor, introduzca su número de móvil</span
                          >
                        </label>
                      </div>
                      <div>
                        <label>
                          <span>Email</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="email"
                            placeholder="Ingresa tu dirección de Email"
                            v-model="email"
                            v-bind:class="{
                              'bg-red-100 border-red-700':
                                ((submited || requiredWarning.email) && !validateEmail()) ||
                                isEmailExist,
                            }"
                            @keyup="IsProfessionalExist()"
                            @focusout="requiredWarning.email = true"
                          />
                          <span class="error" v-if="(submited || requiredWarning.email) && !email"
                            >Por favor ingrese su email</span
                          >
                          <span
                            class="error"
                            v-if="(submited || requiredWarning.email) && !validateEmail() && email"
                            >Por favor introduzca un correo electrónico válido</span
                          >
                          <span class="error" v-if="isEmailExist"
                            >El correo electrónico ya está registrado.</span
                          >
                        </label>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                      <div>
                        <label>
                          <span>C.I</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="text"
                            placeholder="Ingresa tu número de C.I."
                            v-model="identificationNumber"
                            v-bind:class="{
                              'bg-red-100 border-red-700':
                                (submited || requiredWarning.identificationNumber) &&
                                !identificationNumber,
                            }"
                            @keyup="taxNumber = $event.target.value"
                            @focusout="requiredWarning.identificationNumber = true"
                          />
                          <span
                            class="error"
                            v-if="
                              (submited || requiredWarning.identificationNumber) &&
                              !identificationNumber
                            "
                            >Por favor Ingresa tu número de documento o C.I.</span
                          >
                        </label>
                      </div>
                      <div>
                        <label>
                          <span>R.U.C.</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="text"
                            placeholder="Ingresa tu número de R.U.C."
                            v-model="taxNumber"
                            v-bind:class="{
                              'bg-red-100 border-red-700':
                                (submited || requiredWarning.taxNumber) && !taxNumber,
                            }"
                            @focusout="requiredWarning.taxNumber = true"
                          />
                          <span
                            class="error"
                            v-if="(submited || requiredWarning.taxNumber) && !taxNumber"
                            >Por favor Ingresa tu número de documento o R.U.C.</span
                          >
                        </label>
                      </div>
                    </div>
                    <label>
                      <span>Dirección</span>
                      <textarea
                        class="block w-full h-32 mx-auto form-control height-textarea"
                        v-model="address"
                        placeholder="Ingresar nombres de calles, numeración de casa y barrio"
                        v-bind:class="{
                          'bg-red-100 border-red-700':
                            (submited || requiredWarning.address) && !address,
                        }"
                        @focusout="requiredWarning.address = true"
                      ></textarea>
                      <span class="error" v-if="(submited || requiredWarning.address) && !address"
                        >Por favor Ingresa tu dirección</span
                      >
                    </label>
                    <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                      <div>
                        <label>
                          <span>Departamento</span>
                          <select
                            class="block w-full mt-1 rounded form-select"
                            v-model="department"
                            @change="GetDepartmentLocations()"
                            v-bind:class="{
                              'bg-red-100 border-red-700':
                                (submited || requiredWarning.department) && !department,
                            }"
                            @focusout="requiredWarning.department = true"
                          >
                            <option disabled v-if="activeDepartments.length <= 0" :value="null">
                              Departamento no encontrado
                            </option>
                            <option disabled v-else :value="null">Seleccionar Departamento</option>
                            <option
                              v-for="department in activeDepartments"
                              :key="department.id"
                              :value="department.id"
                            >
                              {{ department.description }}
                            </option>
                          </select>
                          <span
                            class="error"
                            v-if="(submited || requiredWarning.department) && !department"
                            >Por favor Ingresa tu Departamento</span
                          >
                        </label>
                      </div>
                      <div>
                        <label>
                          <span>Ciudad</span>
                          <select
                            class="block w-full mt-1 rounded form-select"
                            v-model="location"
                            v-bind:class="{
                              'bg-red-100 border-red-700':
                                (submited || requiredWarning.location) && !location,
                            }"
                            @focusout="requiredWarning.location = true"
                          >
                            <option
                              disabled
                              v-if="activeDepartmentLocations.length <= 0"
                              :value="null"
                            >
                              Ciudad no encontrada
                            </option>
                            <option disabled v-else :value="null">Seleccione Ciudad</option>
                            <option
                              v-for="departmentLocation in activeDepartmentLocations"
                              :key="departmentLocation.id"
                              :value="departmentLocation.id"
                            >
                              {{ departmentLocation.description }}
                            </option>
                          </select>
                          <span
                            class="error"
                            v-if="(submited || requiredWarning.location) && !location"
                            >Por favor Ingresa tu Ciudad</span
                          >
                        </label>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 xl:grid-cols-2 xl:grid-gap-4">
                      <div>
                        <label>
                          <span>Profesión</span>
                          <multiselect
                            v-model="profession"
                            placeholder="Elige una o varias profesión(es) de la lista"
                            label="description"
                            track-by="id"
                            :options="activeProfessions"
                            :multiple="true"
                            selectLabel="presione enter para seleccionar"
                            deselectLabel="presione enter para remover"
                            v-bind:class="{
                              'multiselect-warning bg-red-100 border-red-700':
                                (submited || requiredWarning.profession) && profession.length <= 0,
                            }"
                            @close="requiredWarning.profession = true"
                          >
                            <span slot="noResult">No Profession found.</span></multiselect
                          >
                        </label>
                        <label class="flex">
                          <span
                            class="error"
                            v-if="
                              (submited || requiredWarning.profession) && profession.length <= 0
                            "
                            >Debe seleccionar al menos una profesión</span
                          >
                        </label>
                      </div>
                      <div>
                        <label>
                          <span>Otro oficio / categoría</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="text"
                            placeholder="Escribí tu profesión aquí"
                            v-model="otherProfession"
                          />
                        </label>
                      </div>
                    </div>

                    <div class="flex my-4">
                      <div
                        class="p-2 w-16 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                      >
                        <img src="../assets/img/Icon-Vector.svg" class="inline-block" />
                      </div>
                      <div class="w-auto p-2 bg-info-content-yellow text-black">
                        En un proceso posterior podrás agregar en detalles los servicios que
                        ofreces.
                      </div>
                    </div>
                    <h5 class="text-center my-4 text-xl">Adjuntar archivos</h5>
                    <label>
                      <span>Foto de cédula de identidad</span>
                      <div
                        v-bind:class="{
                          'bg-red-100 border-red-700':
                            (submited || requiredWarning.identityImages) &&
                            this.identityImages &&
                            this.identityImages.length > 2,
                        }"
                      >
                        <input
                          class="hidden w-full mt-1 mb-2 form-input"
                          type="file"
                          name="identityimages[]"
                          @change="identityImagesAdd"
                          placeholder="Nombre"
                          ref="identityfile"
                          multiple
                        />
                      </div>
                      <div>
                        <button
                          type="button"
                          @click="
                            $refs.identityfile.click();
                            requiredWarning.identityImages = true;
                          "
                          class="btn btn--primary"
                        >
                          Elegir archivos
                        </button>
                      </div>
                      <!-- new image end -->
                      <span
                        class="error"
                        v-if="
                          (submited || requiredWarning.identityImages) &&
                          this.identityImages &&
                          this.identityImages.length > 2
                        "
                        >You can upload Maximum 2 images.</span
                      >
                      <div v-if="identityImageSizeError">
                        <span class="error">{{ identityImageSizeError }}</span>
                      </div>
                    </label>
                    <div
                      class="grid mb-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                      v-if="identityImage && identityImage.length > 0"
                    >
                      <figure
                        class="relative border border-gray-200 rounded shadow"
                        v-for="(img, key) in identityImage"
                        :key="key"
                      >
                        <img :src="img" class="w-full" />
                        <span
                          v-show="identityImage"
                          @click="removeIdentityImage(key)"
                          class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                          >x</span
                        >
                      </figure>
                    </div>
                    <label>
                      <span>Antecedentes policiales</span>
                      <div
                        v-bind:class="{
                          'bg-red-100 border-red-700':
                            (submited || requiredWarning.images) &&
                            this.images &&
                            this.images.length > 3,
                        }"
                      >
                        <input
                          class="hidden w-full mt-1 mb-2 form-input"
                          type="file"
                          name="images[]"
                          @change="imagesAdd"
                          placeholder="Nombre"
                          ref="file"
                          multiple
                        />
                      </div>
                      <div>
                        <button
                          type="button"
                          @click="
                            $refs.file.click();
                            requiredWarning.images = true;
                          "
                          class="btn btn--primary"
                        >
                          Elegir archivos
                        </button>
                      </div>
                      <!-- new image end -->
                      <span
                        class="error"
                        v-if="
                          (submited || requiredWarning.images) &&
                          this.images &&
                          this.images.length > 3
                        "
                        >You can upload Maximum 3 images.</span
                      >
                      <div v-if="imageSizeError">
                        <span class="error">{{ imageSizeError }}</span>
                      </div>
                    </label>
                    <div
                      class="grid mb-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                      v-if="image && image.length > 0"
                    >
                      <figure
                        class="relative border border-gray-200 rounded shadow"
                        v-for="(img, key) in image"
                        :key="key"
                      >
                        <img :src="img" class="w-full" />
                        <span
                          v-show="image"
                          @click="removeImage(key)"
                          class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                          >x</span
                        >
                      </figure>
                    </div>
                    <div class="flex my-4">
                      <div
                        class="p-2 w-16 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                      >
                        <img src="../assets/img/Icon-Celular.png" class="inline-block" />
                      </div>
                      <div class="w-auto p-2 bg-info-content-yellow text-black">
                        Si aún no cuentas con este documento, cuándo lo tengas enviar al
                        0986-130-808
                      </div>
                    </div>

                    <label>
                      <span>Foto de perfil</span>
                      <div>
                        <input
                          class="hidden w-full mt-1 mb-2 form-input"
                          type="file"
                          name="profileImage"
                          @change="profileImageAdd"
                          ref="profileImage"
                        />
                      </div>
                      <div>
                        <button
                          type="button"
                          @click="$refs.profileImage.click()"
                          class="btn btn--primary"
                        >
                          Elegir archivos
                        </button>
                      </div>
                      <div v-if="profileImageSizeError">
                        <span class="error">{{ profileImageSizeError }}</span>
                      </div>
                    </label>
                    <div
                      class="grid mb-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                      v-if="imagePreview"
                    >
                      <figure class="relative border border-gray-200 rounded shadow">
                        <img :src="imagePreview" class="w-full" />
                        <span
                          v-show="imagePreview"
                          @click="removeProfileImage()"
                          class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                          >x</span
                        >
                      </figure>
                    </div>
                    <div class="flex my-4">
                      <div
                        class="p-2 w-32 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                      >
                        <img src="../assets/img/icon-Idea.svg" class="inline-block" />
                      </div>
                      <div class="w-auto h-auto p-2 bg-info-content-yellow text-black text-sm">
                        Se recomienda cargar una foto de formato cuadrado (1:1) y de plano cercano.
                        Las fotos de personas tienen mejor rendimiento que las de logos o
                        situaciones. No usar fotos de volantes o similares.
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="p-4 mb-3 border border-gray-400 rounded">
                    <legend>Referencias</legend>
                    <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                      <div>
                        <label>
                          <span>Cliente referencial 1</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="text"
                            placeholder="Nombre y apellido"
                            v-model="laboralReferenceName1"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span>Teléfono cliente 1</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="text"
                            placeholder="Número de telefono"
                            v-model="laboralReferencePhone1"
                          />
                        </label>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                      <div>
                        <label>
                          <span>Cliente referencial 2</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="text"
                            placeholder="Nombre y apellido"
                            v-model="laboralReferenceName2"
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          <span>Teléfono cliente 2</span>
                          <input
                            class="block w-full mt-1 form-input"
                            type="text"
                            placeholder="Número de telefono"
                            v-model="laboralReferencePhone2"
                          />
                        </label>
                      </div>
                    </div>
                    <div class="flex my-4">
                      <div
                        class="p-2 w-32 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                      >
                        <img src="../assets/img/icon-Idea.svg" class="inline-block" />
                      </div>
                      <div class="w-auto h-auto p-2 bg-info-content-yellow text-sm text-black">
                        Indica antiguos clientes que puedan brindar referencias sobre tu calidad de
                        servicio. Los usuarios compararan tu perfil con el de otros prestadores y
                        tendrás mejores resultados teniendo calificaciones desde el inicio.
                      </div>
                    </div>
                  </fieldset>

                  <label class="flex my-6">
                    <input
                      type="checkbox"
                      class="form-checkbox"
                      v-model="acceptPolicy"
                      v-bind:class="{
                        'bg-red-100 border-red-700':
                          (submited || requiredWarning.acceptPolicy) && acceptPolicy != true,
                      }"
                      @focusout="requiredWarning.acceptPolicy = true"
                    />
                    <span class="ml-6 font-normal font-body"
                      >Estoy de acuerdo con los
                      <router-link
                        :to="{ name: 'TermsCondition' }"
                        class="underline"
                        target="_blank"
                        >términos de uso</router-link
                      >
                      de Hand y al hacer click en el cuadro y continuar, acepto que sus
                      representantes puedan comunicarse conmigo por correo electrónicom o teléfono
                      que proporcione, inclusive con fines de marketing. He leído y entiendo la
                      <router-link
                        :to="{ name: 'TermsCondition' }"
                        class="underline"
                        target="_blank"
                        >política de privacidad</router-link
                      >
                      relevante.</span
                    >
                  </label>
                  <label class="flex">
                    <span
                      class="error"
                      v-if="(submited || requiredWarning.acceptPolicy) && acceptPolicy != true"
                      >Debe aceptar las politicas de privacidad y condiciones para registrarse</span
                    >
                  </label>
                  <label class="flex">
                    <vue-recaptcha
                      :sitekey="sitekey"
                      :loadRecaptchaScript="true"
                      ref="recaptcha"
                      @verify="onVerify"
                      @expired="onExpired"
                      size="invisible"
                    ></vue-recaptcha>
                  </label>

                  <button
                    class="w-full btn btn--md py-4 btn--secondary rounded-full"
                    :disabled="processingRegister"
                    @click.prevent="onSubmit()"
                  >
                    Registrame ahora
                  </button>

                  <div class="py-4 px-0 lg:px-20 mx-auto text-center">
                    <p class="text-black mb-8 text-center">
                      Se le enviarán instrucciones a su casilla de email para concluir el proceso de
                      registro de su perfil profesional.
                    </p>
                    <p>
                      <a
                        href="https://wa.me/595986130808"
                        target="_blank"
                        class="btn w-full block md:inline-flex py-4 btn--contact rounded-lg md:rounded-full text-xl"
                        ><img src="../assets/img/logo-whatsapp.svg" class="w-8 mx-auto md:hidden" />
                        <span> ¿Necesitas ayuda? <br class="block sm:hidden" />0986-130-808 </span>
                        <img src="../assets/img/logo-whatsapp.svg" class="w-8 ml-4 hidden md:block"
                      /></a>
                    </p>
                  </div>
                </form>
                <figure class="flex mx-auto mb-4 items-center justify-center md:hidden block">
                  <img src="../assets/img/img-circle.png" class="" />
                </figure>
              </div>
              <div class="w-full px-6 mb-4 md:w-1/2">
                <h3 class="mb-4 text-2xl" style="line-height: 29px">
                  Si cumplís con estas condiciones inscribite <br />
                  en nuestra plataforma y creá tu perfil ahora
                </h3>
                <ul class="sign-up-condition">
                  <li class="mb-6">Experiencia trabajando en tu oficio</li>
                  <li class="mb-6">Responsable y honesto</li>
                  <li class="mb-6">
                    Comprometido en brindar un buen servicio y atención a los clientes
                  </li>
                  <li class="mb-6">Tener celular con internet</li>
                  <li class="mb-6">
                    Completar el registro y subir documentaciones requeridas (Cedula de identidad,
                    foto de perfil, antecedentes policiales y/o judiciales, RUC)
                  </li>
                  <li class="mb-6">RUC, si no tienes te ayudamos a obtenerlo.</li>
                </ul>
                <p class="text-black">
                  Hand no es empleador, ayudamos a conectar profesionales de servicios
                  independientes con clientes
                </p>
                <div class="text-center">
                  <figure class="flex items-center justify-center mx-auto mb-4 rounded-full">
                    <img src="../assets/img/Grafico-Sellar.png" class="rounded-full" />
                  </figure>
                  <figure class="flex mx-auto mb-4 items-center justify-center md:block hidden">
                    <img src="../assets/img/img-circle.png" class="inline-block" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="relative"
          style="background: linear-gradient(270deg, #ffbc11 16.98%, #ff783e 73.75%)"
        >
          <div class="container relative z-10 flex items-center banner-section">
            <div class="w-full md:w-1/2 lg:w-1/3 -mt-20 md:mt-0 md:px-2 px-6">
              <img src="../assets/img/hero-collage.png" class="md:hidden block" />
              <h2 class="text-white md:w-4/5 md:text-6xl banner-heading">
                Los clientes te estan buscando
              </h2>
              <p class="text-white text-xl mb-8">
                Hand te conecta con los clientes que necesitas para hacer crecer tu negocio
              </p>
              <button
                @click="goToProfessionalForm()"
                class="btn btn--primary btn--md banner-btn text-base px-2 md:px-2 py-3 uppercase"
              >
                ¡crea tu perfil gratis ahora mismo!
              </button>
            </div>
            <div class="w-full md:w-1/2 lg:w-2/3 hidden md:block">
              <img src="../assets/img/hero-collage.png" />
            </div>
          </div>
        </section>

        <section class="py-5 md:pt-8 lg:pt-16 md:pb-6 lg:pb-6 bg-white">
          <div class="container text-center container-xl py-0 md:py-10">
            <h3 class="mb-8 mt-5">¿Porque trabajar con Hand?</h3>
            <div class="flex flex-wrap">
              <div class="w-full px-6 md:px-4 lg:px-12 mb-4 md:w-1/3">
                <figure
                  class="flex items-center justify-center w-32 h-32 mx-auto mb-4 border rounded-full shadow bg-brand-secondary border-brand-secondary"
                >
                  <img src="../assets/img/Icon-Billetes.svg" class="w-20 h-20" />
                </figure>

                <p class="text-black text-xl px-8 md:px-4 lg:px-12">
                  Consigue más trabajos y gana más dinero ayudando a clientes que necesitan tu
                  trabajo.
                </p>
              </div>

              <div class="w-full px-6 md:px-4 lg:px-12 md:w-1/3">
                <figure
                  class="flex items-center justify-center w-32 h-32 mx-auto mb-4 border rounded-full shadow bg-brand-secondary border-brand-secondary"
                >
                  <img src="../assets/img/Icon-Usuario-estrella.svg" class="w-20 h-20" />
                </figure>

                <p class="text-black text-xl px-4 md:px-8 lg:px-12">
                  Construye tu imagen en internet para ofrecer tus servicios y llegar a más
                  clientes.
                </p>
              </div>

              <div class="w-full px-8 md:px-4 lg:px-12 mb-4 md:w-1/3">
                <figure
                  class="flex items-center justify-center w-32 h-32 mx-auto mb-4 border rounded-full shadow bg-brand-secondary border-brand-secondary"
                >
                  <img src="../assets/img/Icon-Celular.svg" class="w-20 h-20" />
                </figure>

                <p class="text-black text-xl px-8 md:px-4 lg:px-12">
                  Herramientas online de utilidad para hacer mucho más eficiente tu gestión diaria.
                </p>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-full px-6">
                <h5 class="my-8">¡Y muchos otros beneficios!</h5>
                <button
                  class="btn btn--primary banner-btn btn--md sm:px-8 md:px-12 py-3 uppercase"
                  @click="goToProfessionalForm()"
                >
                  Crea tu perfil gratis ahora<br class="hidden sm:block" />
                  y llegá a más clientes
                </button>
              </div>
            </div>
          </div>
        </section>
        <section class="py-5 md:py-6 lg:py-6 bg-white">
          <div class="container text-center container-xl">
            <h3 class="mb-8">¿Cómo hago para trabajar con Hand?</h3>
            <div class="flex flex-wrap">
              <div class="w-full px-6 md:px-4 lg:px-12 mb-4 md:w-1/3">
                <h5 class="mb-4 md:h-12 lg:h-16">Crea tu perfil</h5>
                <figure
                  class="flex items-center justify-center w-56 h-56 mx-auto mb-4 border rounded-full shadow"
                >
                  <img src="../assets/img/img-1.png" class="w-full rounded-full" />
                </figure>

                <p class="text-black text-lg text-left px-8 md:px-4 lg:px-8">
                  Ingresa tus datos a la plataforma, completá tu perfil y sube las documentaciones
                  requeridas para que los clientes te puedan conocer y contratar.
                </p>
              </div>

              <div class="w-full px-8 md:px-4 lg:px-12 mb-4 md:w-1/3">
                <h5 class="mb-4 md:h-12 lg:h-16">
                  Revisión y alta en la<br />
                  plataforma
                </h5>
                <figure
                  class="flex items-center justify-center w-56 h-56 mx-auto mb-4 border rounded-full shadow bg-brand-secondary border-brand-secondary"
                >
                  <img src="../assets/img/Icon-Tableta.svg" />
                </figure>

                <p class="text-black text-lg text-left px-8 md:px-4 lg:px-8">
                  Tus datos pasan por un proceso de revisión y validación, una vez aprobado te
                  enviamos la confirmación a tu email y hacemos visible tu perfil.
                </p>
              </div>

              <div class="w-full px-8 md:px-4 lg:px-12 mb-4 md:w-1/3">
                <h5 class="mb-4 md:h-12 lg:h-16">¡Listo!</h5>
                <figure
                  class="flex items-center justify-center w-56 h-56 mx-auto mb-4 border rounded-full shadow"
                >
                  <img src="../assets/img/img-2.png" class="w-full rounded-full" />
                </figure>

                <p class="text-black text-lg text-left px-8 md:px-4 lg:px-8">
                  Aceptado todos los términos y condiciones estas listo para recibir trabajos y
                  empezar a ganar más dinero!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import GetDepartments from "@/utils/methods/GetDepartments";
import GetDepartmentLocations from "@/utils/methods/GetDepartmentLocations";
import validateEmail from "@/utils/methods/validateEmail";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import scrollToSection from "@/utils/methods/scrollToSection";
import GetProfessions from "@/utils/methods/GetProfessions";
import activeProfessions from "@/utils/computed/activeProfessions";
import multiselect from "vue-multiselect";
import VueRecaptcha from "vue-recaptcha";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import FacebookRegister from "@/components/FacebookRegister.vue";
import GoogleRegister from "@/components/GoogleRegister.vue"
export default {
  data() {
    return {
      submited: false,
      requiredWarning: {
        firstName: false,
        lastName: false,
        phoneNumber: false,
        email: false,
        identificationNumber: false,
        taxNumber: false,
        address: false,
        acceptPolicy: false,
        department: false,
        location: false,
        profession: false,
        laboralReferenceName1: false,
        laboralReferencePhone1: false,
        laboralReferenceName2: false,
        laboralReferencePhone2: false,
        identityImages: false,
        images: false,
      },
      firstName: "",
      lastName: "",
      phoneNumber: "",
      countryCode: null,
      countryCallingCode: null,
      email: "",
      identificationNumber: "",
      taxNumber: "",
      address: "",
      laboralReferenceName1: "",
      laboralReferencePhone1: "",
      laboralReferenceName2: "",
      laboralReferencePhone2: "",
      acceptPolicy: false,
      processingRegister: false,
      department: null,
      departments: [],
      departmentLocations: [],
      location: null,
      isEmailExist: false,
      images: [],
      image: [],
      profileImage: null,
      imagePreview: null,
      identityImages: [],
      identityImage: [],
      professions: [],
      profession: [],
      otherProfession: "",
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      recaptchaToken: "",
      imageSizeError: "",
      imageURL: "",
      identityImageSizeError: "",
      profileImageSizeError: "",
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :",
      },
      telnumber: "",
      isPhoneValid: false,
      oauthUid: "",
      oauthProvider:""
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetDepartments();
    this.GetProfessions();
  },
  computed: {
    ...mapGetters(["error"]),
    activeDepartments,
    activeDepartmentLocations,
    activeProfessions,
  },
  methods: {
    GetDepartments,
    GetDepartmentLocations,
    validateEmail,
    scrollToSection,
    GetProfessions,
    async updateValuesFromSocial(data) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.oauthUid = data.oauthUid;
        this.oauthProvider = data.oauthProvider;
        const response = await fetch(data.pictureProfileUrl);
        const blob = await response.blob();
        this.profileImageAdd({target:{files:[blob]}});
    },
    onSubmit() {
      this.submited = true;
      this.processingRegister = true;
      if (this.validateForm()) {
        this.$refs.recaptcha.execute();
      } else {
        this.processingRegister = false;
        setTimeout(function () {
          var d = document.getElementsByClassName("bg-red-100")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    registerProfessional() {
      var images = require.context("../assets/../assets/../assets/img/", false, /\.gif$/);
      Swal.fire({
        imageUrl: images("./loading.gif"),
        imageAlt: "Loader",
        showConfirmButton: false,
        background: "transparent",
        backdrop: `rgba(0,0,0,0.6)`,
        allowOutsideClick: false,
      });
      var formdata = new FormData();
      for (var i = 0; i < this.images.length; i++) {
        let file = this.images[i];
        formdata.append("files", file);
      }
      for (var k = 0; k < this.identityImages.length; k++) {
        let file = this.identityImages[k];
        formdata.append("identityImage", file);
      }
      if (this.profileImage) {
        for (var j = 0; j < this.profileImage.length; j++) {
          let file = this.profileImage[j];
          formdata.append("profileImage", file);
        }
      }
      formdata.append("firstName", this.firstName);
      formdata.append("lastName", this.lastName);
      formdata.append("email", this.email);
      formdata.append("phoneNumber", this.phoneNumber);
      formdata.append("countryCode", this.countryCode);
      formdata.append("countryCallingCode", this.countryCallingCode);
      formdata.append("identificationNumber", this.identificationNumber);
      formdata.append("taxNumber", this.taxNumber);
      formdata.append("address", this.address);
      formdata.append("serviceDescription", "test");
      formdata.append("laboralReferenceName1", this.laboralReferenceName1);
      formdata.append("laboralReferencePhone1", this.laboralReferencePhone1);
      formdata.append("laboralReferenceName2", this.laboralReferenceName2);
      formdata.append("laboralReferencePhone2", this.laboralReferencePhone2);
      formdata.append("location_id", this.location);
      formdata.append("profession", JSON.stringify(this.profession));
      formdata.append("otherProfession", this.otherProfession);
      formdata.append("recaptchaToken", this.recaptchaToken);
      formdata.append("oauthUid", this.oauthUid);
      formdata.append("oauthProvider", this.oauthProvider);
      this.$store
        .dispatch("registerProfessional", formdata)
        .then(() => {
          this.submited = false;
          this.firstName = "";
          this.lastName = "";
          this.phoneNumber = "";
          this.countryCode = null;
          this.countryCallingCode = null;
          this.email = "";
          this.identificationNumber = "";
          this.taxNumber = "";
          this.address = "";
          this.laboralReferenceName1 = "";
          this.laboralReferencePhone1 = "";
          this.laboralReferenceName2 = "";
          this.laboralReferencePhone2 = "";
          this.acceptPolicy = false;
          this.processingRegister = false;
          this.department = null;
          this.departments = [];
          this.departmentLocations = [];
          this.location = null;
          this.profession = null;
          this.otherProfession = "";
          this.images = [];
          this.image = [];
          this.profileImage = null;
          this.imagePreview = null;
          this.identityImages = [];
          this.identityImage = [];
          this.$refs.file.value = "";
          Swal.close();
          this.processingRegister = false;
          this.oauthUid = "";
          this.oauthProvider = "";
          this.$refs.recaptcha.reset();
          Swal.fire({
            title: "",
            icon: "success",
            html: `Gracias por registrarse en Hand! Su solicitud ha sido enviada exitosamente.
               Se realizará una revisión de la misma, esté atento a su correo, ya que dentro de
               las 72 hs le llegará la aprobación y los detalles para completar el proceso`,
            confirmButtonText: "Continuar",
            backdrop: false,
          }).then((result) => {
            if (result.value) {
              this.$router.push({ path: "/" });
            }
          });
        })
        .catch(() => {
          Swal.close();
          this.processingRegister = false;
          this.$refs.recaptcha.reset();
          Swal.fire({
            title: "",
            icon: "error",
            html: this.error.response.data.msg,
            confirmButtonText: "Continuar",
            backdrop: false,
          }).then((result) => {
            if (result.value) {
              this.scrollToSection("professional_form");
            }
          });
        });
    },
    validateForm() {
      return (
        this.firstName != "" &&
        this.lastName != "" &&
        this.isPhoneValid == true &&
        this.email != "" &&
        this.validateEmail() == true &&
        this.isEmailExist == false &&
        this.identificationNumber != "" &&
        this.taxNumber != "" &&
        this.address != "" &&
        this.department != null &&
        this.location != null &&
        this.profession.length > 0 &&
        this.images &&
        this.images.length <= 3 &&
        this.identityImages &&
        this.identityImages.length <= 2 &&
        this.acceptPolicy == true
      );
    },

    IsProfessionalExist() {
      if (this.validateEmail()) {
        this.$store
          .dispatch("IsProfessionalExist", {
            emailId: this.email,
          })
          .then((response) => {
            if (response.user) {
              this.isEmailExist = true;
            } else {
              this.isEmailExist = false;
            }
          })
          .catch(() => {
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.isEmailExist = false;
      }
    },

    imagesAdd(e) {
      var files = e.target.files || e.dataTransfer.files;

      Array.prototype.push.apply(this.images, files);
      if (!this.images.length) return;
      this.createImage(this.images);
    },
    createImage(file) {
      this.image = [];
      let imageHaveError = [];
      for (var i = 0; i < file.length; i++) {
        if (file[i].size > 1024 * 1024 * 10) {
          imageHaveError.push(file[i].name);
          this.images.splice(i, 1);
        } else {
          var reader = new FileReader();
          var vm = this;
          reader.onload = (e) => {
            vm.image.push(e.target.result);
          };
          reader.readAsDataURL(file[i]);
        }
      }
      if (imageHaveError.length > 0) {
        this.imageSizeError =
          "El archivo " +
          imageHaveError.join(" , ") +
          " que intenta agregar supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB";
      } else {
        this.imageSizeError = "";
      }
    },

    removeImage(key) {
      this.image.splice(key, 1);
      this.images.splice(key, 1);

      if (!this.image.length) {
        this.$refs.file.value = "";
      }
    },
    identityImagesAdd(e) {
      var files = e.target.files || e.dataTransfer.files;

      Array.prototype.push.apply(this.identityImages, files);
      if (!this.identityImages.length) return;
      this.createIdentityImage(this.identityImages);
    },
    createIdentityImage(file) {
      this.identityImage = [];
      let identityImageHaveError = [];
      for (var i = 0; i < file.length; i++) {
        if (file[i].size > 1024 * 1024 * 10) {
          identityImageHaveError.push(file[i].name);
          this.identityImages.splice(i, 1);
        } else {
          var reader = new FileReader();
          var vm = this;
          reader.onload = (e) => {
            vm.identityImage.push(e.target.result);
          };
          reader.readAsDataURL(file[i]);
        }
      }
      if (identityImageHaveError.length > 0) {
        this.identityImageSizeError =
          "El archivo " +
          identityImageHaveError.join(" , ") +
          " que intenta agregar supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB";
      } else {
        this.identityImageSizeError = "";
      }
    },

    removeIdentityImage(key) {
      this.identityImage.splice(key, 1);
      this.identityImages.splice(key, 1);

      if (!this.identityImage.length) {
        this.$refs.identityfile.value = "";
      }
    },
    profileImageAdd(e) {
      this.profileImage = e.target.files || e.dataTransfer.files;
      if (!this.profileImage) return;
      let profileImageHaveError = "";
      if (this.profileImage[0].size > 1024 * 1024 * 10) {
        profileImageHaveError = this.profileImage[0].name;
        this.profileImage = null;
        this.$refs.profileImage.value = "";
      } else {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          vm.imagePreview = e.target.result;
        };
        reader.readAsDataURL(this.profileImage[0]);
      }
      if (profileImageHaveError.length > 0) {
        this.profileImageSizeError =
          "El archivo " +
          profileImageHaveError +
          " que intenta agregar supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB";
      } else {
        this.profileImageSizeError = "";
      }
    },
    removeProfileImage() {
      this.profileImage = null;
      this.imagePreview = null;
      if (!this.profileImage) {
        this.$refs.profileImage.value = "";
      }
    },
    goToProfessionalForm() {
      this.scrollToSection("professional_form");
      var self = this;
      setTimeout(function () {
        self.$refs.firstName.focus();
      }, 1500);
    },
    onVerify: function (recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
      this.registerProfessional();
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
      this.recaptchaToken = "";
    },
    phoneUpdate(val) {
      this.phoneNumber = val.nationalNumber;
      this.countryCode = val.countryCode;
      this.countryCallingCode = val.countryCallingCode;
      if (val.countryCode == "PY") {
        if (val.nationalNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    },
  },
  components: {
    Header,
    Footer,
    Messages,
    multiselect,
    VueRecaptcha,
    VuePhoneNumberInput,
    FacebookRegister,
    GoogleRegister
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag {
  background: #f3f2f6;
  padding: 7px 38px 10px 15px;
  border-radius: 0px;
  white-space: unset;
}
.multiselect__tags span {
  font-weight: 400;
  font-size: 20px;
  color: #929293;
}
.multiselect__tag-icon {
  top: 7px;
  right: 10px;
  font-weight: 400;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #929293;
  font-size: 30px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #f3f2f6;
  color: #929293;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #929293;
}
.multiselect-warning .multiselect__tags {
  border-color: #ca3150 !important;
  background-color: #ffecef !important;
}
.multiselect-warning .multiselect__input,
.multiselect-warning .multiselect__single {
  background-color: #ffecef !important;
}
@media (max-width: 640px) {
  .multiselect__option {
    min-height: 50px;
  }
  .multiselect__option:after {
    position: relative;
    line-height: 18px;
    display: block;
    text-align: center;
  }
  .multiselect__option--selected.multiselect__option--highlight:after {
    background: transparent;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px !important;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px !important;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px !important;
  }
}
</style>
<style scoped>
@import "../assets/css/registrarme-profesional.css";

.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
