<template>
    <svg :width="width" :height="height" :class="`stroke-${color}`" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.88976 14.5639C3.18922 14.5639 1 12.4698 1 9.8867C1 7.30358 3.18922 5.20954 5.88976 5.20954C6.30235 3.37145 7.77317 1.87109 9.74817 1.27363C11.7232 0.676164 13.9023 1.07237 15.4647 2.313C17.0271 3.55362 17.7354 5.45019 17.3228 7.28828H18.3622C20.3713 7.28828 22 8.91698 22 10.9261C22 12.9352 20.3713 14.5639 18.3622 14.5639H17.3228" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.96777 11.4457L11.0859 8.32764L14.204 11.4457"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.0861 8.32764V17.682"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
    props:{
      width:{
        type:Number,
        default:23
      },
      height:{
        type:Number,
        default:19
      },
      color:{
        type:String,
        default:'#ffffff'
      }
    }
}
</script>
