<template>
  <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-[#262626] bg-opacity-70 transition-opacity"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-center xl:items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div ref="modal" id="modal" class="bg-transparent p-[43px]">
            <div class="w-full h-[500px] flex items-center justify-center">
              <spin-icon :width="100" :height="100" :color="'primary'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/v2/Modal.vue";
import {SpinIcon} from "@/components/icons";
import {disableScroll, enableScroll} from "@/utils/v2/scroll-management";

export default {
  components:{
    Modal,
    SpinIcon
  },
  beforeDestroy() {
    enableScroll()
  },
  mounted() {
    disableScroll()
  }
}
</script>
