export default function(onlyActive=false) {
  this.$store
    .dispatch("GetProfessions",onlyActive)
    .then(response => {
      this.professions = response.professions;
    })
    .catch(() => {
      let errorMessage = this.error.response.data.msg;
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", errorMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
