export default function() {
  if (this.selectedProfessions.length > 0) {
    for (var x = 0; x < this.selectedProfessions.length; x++) {
      if (this.selectedServices && this.selectedServices.length > 0) {
        let isexistService = false;
        for (var y = 0; y < this.selectedServices.length; y++) {
          if (this.selectedServices[y].profession_id == this.selectedProfessions[x].id) {
            isexistService = true;
          }
        }

        if (!isexistService || this.selectedProfessions[x].images.length > 5) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}
