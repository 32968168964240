<template>
  <section class="px-2 lg:w-8/12">
    <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
      <h4 class="mb-3 lg:mt-0 lg:mb-0">
        <svg class="-mt-2 icon">
          <use
            href="../../assets/img/icons.svg#icon-todo"
            xlink:href="../../assets/img/icons.svg#icon-todo"
          />
        </svg>
        Presupuestos solicitados
      </h4>
      <div>
        <label class="inline-flex items-center mb-0">
          <input
            type="radio"
            class="form-radio"
            name="solicitudEstado"
            value="pending"
            v-model="requestStatus"
            @change="GetUserBudgetRequest()"
          />
          <span class="ml-2">Pendientes</span>
        </label>
        <label class="inline-flex items-center mb-0 ml-6">
          <input
            type="radio"
            class="form-radio"
            name="solicitudEstado"
            value="complete"
            v-model="requestStatus"
            @change="GetUserBudgetRequest()"
          />
          <span class="ml-2">Concretados</span>
        </label>
      </div>
    </div>

    <div v-if="budgetRequests.length > 0">
      <div
        class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
        v-for="(budgetRequest, index) in budgetRequests"
        :key="index"
      >
        <header
          class="flex items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white border-b border-gray-200"
        >
          <div class="flex flex-col lg:flex-row">
            <strong>Estado:</strong>
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
              v-if="budgetRequest.status == 'complete'"
              >Concretado</span
            >
            <span class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary" v-else>Pendiente</span>
          </div>
        </header>

        <div class="flex flex-col justify-between pt-4 lg:flex-row">
          <figure class="flex items-center lg:mb-0 lg:w-2/12 lg:mr-4 lg:flex-col">
            <img
              :src="budgetRequest.Professional.pictureProfileUrl"
              :alt="
                budgetRequest.Professional.firstName + ' ' + budgetRequest.Professional.lastName
              "
              class="object-cover shadow avatar avatar--xl"
            />
            <figcaption
              class="pl-2 text-2xl text-center text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
            >
              {{ budgetRequest.Professional.firstName }}
              {{ budgetRequest.Professional.lastName }}
            </figcaption>
          </figure>

          <hr class="my-3 -mx-5 border-gray-200 lg:hidden" />

          <div class="flex-1">
            <div class="flex flex-col -mx-2 lg:flex-row">
              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Orden:</h6>
                <p>{{ budgetRequest.id }}</p>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Solicitado el:</h6>
                <p>{{ moment(budgetRequest.createdOn).format("DD/MM/YYYY") }}</p>
                <h6 class="mb-0">Agendado para:</h6>
                <p>
                  {{ moment(budgetRequest.budgetScheduledDate).format("DD/MM/YYYY - HH:MM") }}hs
                </p>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-2">Created By:</h6>
                <figure class="flex lg:flex-col">
                  <img
                    :src="budgetRequest.User.pictureProfileUrl"
                    :alt="budgetRequest.User.firstName + ' ' + budgetRequest.User.lastName"
                    class="object-cover shadow avatar avatar--xl"
                  />
                  <figcaption
                    class="pl-2 text-2xl text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
                  >
                    {{ budgetRequest.User.firstName }} {{ budgetRequest.User.lastName }}
                  </figcaption>
                </figure>
              </div>
            </div>
            <h6 class="mb-0">Información adicional:</h6>
            <p>{{ budgetRequest.description }}</p>
            <h6>Imágenes adjuntadas:</h6>
            <div
              class="grid mb-4 grid-columns-4 md:grid-columns-6 grid-gap-2"
              v-if="budgetRequest.Photos && budgetRequest.Photos.length > 0"
            >
              <figure
                class="p-1 border border-gray-200 rounded shadow"
                v-for="(Photo, index) in budgetRequest.Photos"
                :key="index"
              >
                <img :src="Photo.photoUrl" class="w-full" />
              </figure>
            </div>
          </div>
        </div>
        <footer
          class="relative flex flex-col items-center px-5 py-2 mt-3 -mx-5 text-xs bg-gray-100 border-t lg:flex-row bordera-gray-200"
        >
          <div class="w-full text-right">
            <button
              v-if="budgetRequest.status == 'pending'"
              @click="
                updateBudgetRequestStatus(budgetRequest.id, {
                  status: 'complete',
                })
              "
              :disabled="processingBudgetRequests"
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--secondary bg-green-btn px-5"
            >
              Concretar
            </button>
            <button
              v-else-if="budgetRequest.status == 'complete'"
              @click="
                updateBudgetRequestStatus(budgetRequest.id, {
                  status: 'pending',
                })
              "
              :disabled="processingBudgetRequests"
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--secondary px-5"
            >
              Revertir
            </button>
          </div>
        </footer>
      </div>
    </div>
    <div class="container msg-section" v-else>
      <section
        class="flex flex-col items-center md:flex-row alert alert--success shadow alert--info"
      >
        <div class="md:pr-6 mb-2">
          <svg class="text-6xl text-blue-800 icon md:text-5xl">
            <use
              href="../../assets/img/icons.svg#icon-alert-circle-i"
              xlink:href="../../assets/img/icons.svg#icon-alert-circle-i"
            />
          </svg>
        </div>
        <div class="text-center md:text-left">
          <p class="text-sm">No hay solicitudes de presupuestos</p>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import GetUserBudgetRequest from "@/utils/methods/GetUserBudgetRequest";
export default {
  data() {
    return {
      processingBudgetRequests: false,
      requestStatus: "pending",
      budgetRequests: {},
      updateBudgetRequestData: {
        requestData: {},
        budgetId: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetUserBudgetRequest();
  },
  methods: {
    GetUserBudgetRequest,
    updateBudgetRequestStatus(budgetId, data) {
      this.processingBudgetRequests = true;
      this.updateBudgetRequestData.budgetId = budgetId;
      this.updateBudgetRequestData.requestData = data;
      this.$store
        .dispatch("updateBudgetRequestStatus", this.updateBudgetRequestData)
        .then(() => {
          this.processingBudgetRequests = false;
          this.GetUserBudgetRequest();
          let registerMessage = `Budget Request Status Updated Successfully.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", registerMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingBudgetRequests = false;
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
  },
  filters: {
    thousandSeparator,
  },
  computed: {
    ...mapGetters(["error", "user"]),
  },
  components: {},
};
</script>
<style lang="css" scoped>
@import "../../assets/css/dashboard.css";
.success {
  color: #1bb01b;
}
.bg-green-btn {
  background-color: #04b00b !important;
}
</style>
