<template>
  <div class="w-full p-3 my-5 bg-white flex flex-col cursor-pointer">
    <div
      @click="collapse = !collapse"
      :class="[`w-full flex items-center justify-between pb-2`, {}]"
    >
      <div class="flex">
        <ProfilePicture class="w-16 h-16 mr-3" :url="professional_picture" />
        <div class="w-max flex flex-col">
          <div class="flex">
            <span class="font-bold mr-4">{{ date }}</span>
            <span class="font-bold mr-4">{{ service_request.serviceHour }}</span>
            <span class="font-bold mr-4">{{ total_price }}</span>
          </div>
          <h4>{{ professional_fullname }}</h4>
        </div>
      </div>
      <span :class="[`font-bold text-2xl`, statusStyle]">{{ statusTitle }}</span>
    </div>
    <div v-if="collapse" class="w-full flex bg-white border-t p-3">
      <ul>
        <li v-for="service in services" :key="service.id">
          <span class="mr-4">{{ service.description }} </span>
          <span class="mr-4">x{{ service.quantity }}</span>
          <span class="mr-4">{{ formatCurrency(service.price * service.quantity) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { ProfilePicture } from "@/components";
import dayjs from "dayjs";
import statusTranslations from "@/utils/helpers/ServiceRequest/statusTranslations";
export default {
  props: {
    service_request: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      collapse: false,
    };
  },
  methods: {
    formatCurrency(value) {
      return "₲ " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    statusTitle() {
      return statusTranslations[this.service_request.status];
    },
    statusStyle() {
      const style = {
        complete: "text-green-700",
        ongoing: "text-brand-primary",
        pending: "text-brand-secondary",
      };
      return style[this.service_request.status] || {};
    },
    professional_fullname() {
      const { firstName, lastName } = this.service_request.professional_profile.user;
      return firstName + " " + lastName;
    },
    professional_picture() {
      return this.service_request.professional_profile.user.pictureProfileUrl;
    },
    date() {
      return dayjs(this.service_request.date).format("DD/MM/YYYY");
    },
    total_price() {
      return this.formatCurrency(this.service_request.total_price);
    },
    professional_services() {
      return this.service_request.professional_profile.services;
    },
    services() {
      return this.service_request.details.map((detail) => {
        const service = this.professional_services.find((s) => s.service_id === detail.service_id);
        return {
          description: detail.service.description,
          price: service.price,
          quantity: detail.quantity,
        };
      });
    },
  },
  components: {
    ProfilePicture,
  },
};
</script>
