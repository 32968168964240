<template>
  <div class="new-home">
    <section class="py-8 bg-pattern-2">
      <div class="container container-xl px-6 md:px-0">
        <h5 class="text-center mb-8 text-2xl md:text-3xl text-gray-900">
          Busca entre los profesionales más solicitados
        </h5>

        <div
          class="px-4 md:px-0 grid grid-columns-1 grid-gap-2 md:grid-columns-4 lg:grid-columns-5 md:grid-gap-4"
        >
          <article
            class="card-profesion"
            v-for="(professionItem, index) in professions.slice(0, showProfession)"
            :id="'card-profession-' + index"
            :key="professionItem.id"
          >
            <img
              class="w-20 h-20 icon inline-block text-brand-secondary"
              :src="require(`../../assets/img/icons/icon-${professionItem.type}.svg`)"
              v-if="professionItem.type"
            />

            <svg class="w-20 h-20 icon inline-block text-brand-secondary" v-else>
              <use
                href="../../assets/img/icons.svg#icon-todo"
                xlink:href="img/icons.svg#icon-todo"
              />
            </svg>
            <h6 class="hover:no-underline">{{ professionItem.description }}</h6>
            <a href="javascript:void(0)" @click="selectProfession(professionItem)"></a>
          </article>
        </div>
        <div class="w-full text-center my-8">
          <button
            class="w-full md:w-auto btn btn--lg btn--primary rounded uppercase px-4 md:px-16"
            @click="showAllProfession()"
            :disabled="showProfession >= this.professions.length"
          >
            ver más profesiones
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showProfession: 10
    };
  },
  name: "Professions",
  props: {
    msg: String,
    professions: Array
  },
  methods: {
    showAllProfession() {
      if (this.professions && this.professions.length > 0)
        this.showProfession = this.professions.length;
    },
    selectProfession(professionItem) {
      this.$emit("selectProfession", professionItem);
    }
  }
};
</script>
<style scoped>
@import "../../assets/css/index.css";
</style>
