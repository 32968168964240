<template>
  <aside class="px-2 mb-4 lg:w-3/12">
    <nav class="lg:-mt-4">
      <ul>
        <li>
          <router-link
            :to="{ name: 'ServiceRequest' }"
            :class="[
              currentPage.includes('service-request') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4'
            ]"
          >
            <span>Servicios solicitados</span>
            <svg
              :class="[
                currentPage.includes('service-request') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg'
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'Message' }"
            :class="[
              currentPage.includes('message') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4'
            ]"
          >
            <span>Mensajes</span>
            <svg
              :class="[
                currentPage.includes('message') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg'
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <a
            href="#"
            class="flex items-center justify-between py-4 text-black"
          >
            <span>Facturas</span>
            <svg class="text-black icon icon--lg">
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </a>
        </li>
        <li>
          <router-link
            :to="{ name: 'ClientProfile' }"
            :class="[
                      currentPage.includes('client-complete-profile') ? 'text-brand-secondary' : 'text-black',
                      'flex items-center justify-between py-4'
                    ]"
          >
            <span>Mis datos</span>
            <svg
              :class="[
                currentPage.includes('client-complete-profile') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg'
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: "ProfileSidebar",
  computed: {
    currentPage() {
      return this.$route.path;
    }
  },
  props: {
    msg: String
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/css/dashboard.css";
</style>
