import { HTTP } from '@/config/http-constants';
export default {
  searchForServiceRequest({
    service_request_id,
    query = '',
    page = 1,
    filterBy = 'names_lastnames',
  }) {
    return HTTP.get(
      'api/professionals/search-for-service-request/' +
        service_request_id +
        '?query=' +
        query +
        '&page=' +
        page +
        '&filterBy=' +
        filterBy,
    );
  },
  requests(data){
    const searchParams = new URLSearchParams(data).toString();
    return HTTP.get(`api/professionals/requests?${searchParams}`)
  },
  getProfileByToken({token})
  {
    return HTTP.get(`/public/professionals/` + token);
  },
  updateProfile({token,data})
  {
    //return HTTP.put('/public/professional-profile/'+token,data)
    return new Promise((resolve,reject)=>{
      setTimeout(()=>{
        reject("Success")
      },3000)
    })
  },
  deleteProfessionByToken({token,profession_id})
  {
    return HTTP.delete(`/public/professional-profession/${profession_id}/professional-profile/${token}`)
  },
  createProfessionServiceByToken({token,service_id})
  {
    return HTTP.post(`/public/professional-service/${token}`,{service_id})
  },
  deleteProfessionServiceByToken({token,service_id})
  {
    return HTTP.delete(`/public/professional-service/${service_id}/professional-profile/${token}`)
  },
  updateProfileTemporaryData({data,access_token})
  {
    return HTTP.put('/public/professional-profile/temporary-data/'+access_token,data)
  },
  getCharacteristics() {
    return HTTP.get('/public/professionals/characteristics')
  }
};
