<template>
  <div class="w-full">
    <section class="flex bg-white">
      <div class="container flex flex-col container-xl md:flex-row mt-6 md:mt-24 p-10 md:p-0 mb-48">
        <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0 mx-auto">
          <form
            @submit.prevent="onSubmit"
            method="post"
          >
            <fieldset>
              <h4 class="">Foto cédula de identidad</h4>
              <p class="mb-6">Texto legal</p>
            </fieldset>
            <fieldset class="">
              <div class="grid grid-cols-1 xl-grid-cols-2 lg:grid-gap-4">
                <div>
                  <form-label :title="'Cargar foto de cédula de identidad'" :size="'small'">
                    <template #tooltip>
                      <div class="ml-1 inline-block">
                        <Tooltip message="Al sacar o subir la foto ten en consideración que la información debe leerse con claridad. Evita fotos oscuras y poco nítidas">
                          <img src="@/assets/v2/svg/info.svg" class="inline-block info-tooltip"/>
                        </Tooltip>
                      </div>
                    </template>
                    <file-input :accept="'.jpg, .png'" :text="'Cargar Imagen'" @selected="handleSelectedFileFront" @deleted="handleDeletedFileFront" :color="'primary'">
                      <template #placeholder>
                        <span class="text-dark">Cargar foto del <span class="font-bold">FRENTE</span></span>
                      </template>
                      <template #trigger-icon>
                        <cloud-upload-icon :color="'white'" />
                      </template>
                      <template #modal-content>
                        <span class="text-[25px] leading-[29px] mt-[70px] text-black font-bold">Foto cédula de identidad</span>
                        <p class="mt-1">Texto con indicaciones</p>
                        <div class="border border-gray-800 rounded-[6px] py-[16px] px-[15px] mt-[32px] mx-[60px] flex">
                          <img src="@/assets/v2/img/cedula-front.png" alt="">
                          <div class="flex-grow flex flex-col ml-[16px]">
                            <div class="w-full flex items-center">
                              <check-circle-icon />
                              <span class="text-[14px] leading-[18px] font-semibold text-black ml-[10px]">Referencia correcta de foto</span>
                            </div>
                            <div class="w-full mt-[7px]">
                              <p class="text-black text-[14px]">Esta es una referencia correcta de cómo debería verse la foto que cargues de tu 
                                <span class="font-bold">CÉDULA DE IDENTIDAD</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                    </file-input>
                    <span class="error" v-if="submited && !identificationFrontImage">Por favor selecciona una imagen frontal de tu cédula de identidad</span>
                    <span class="error" v-if="submited && !checkIdentificationImageSize(identificationFrontImage)">
                      El archivo que intenta agregar supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB
                    </span>
                  </form-label>
                </div>
                <div class="back-id-input-container">
                  <form-label :title="''">
                    <file-input :accept="'.jpg, .png'" :text="'Cargar imagen'" @selected="handleSelectedFileBack" @deleted="handleDeletedFileFront" :color="'primary'">
                      <template #placeholder>
                        <span class="text-dark">Cargar foto del <span class="font-bold">DORSO</span></span>
                      </template>
                      <template #trigger-icon>
                        <cloud-upload-icon :color="'white'" />
                      </template>
                      <template #modal-content>
                        <span class="text-[25px] leading-[29px] mt-[70px] text-black font-bold">Foto cédula de identidad</span>
                        <p class="mt-1">Texto con indicaciones</p>
                        <div class="border border-gray-800 rounded-[6px] py-[16px] px-[15px] mt-[32px] mx-[60px] flex">
                          <img src="@/assets/v2/img/cedula-back.png" alt="">
                          <div class="flex-grow flex flex-col ml-[16px]">
                            <div class="w-full flex items-center">
                              <check-circle-icon />
                              <span class="text-[14px] leading-[18px] font-semibold text-black ml-[10px]">Referencia correcta de foto</span>
                            </div>
                            <div class="w-full mt-[7px]">
                              <p class="text-black text-[14px]">Esta es una referencia correcta de cómo debería verse la foto que cargues de tu 
                                <span class="font-bold">CÉDULA DE IDENTIDAD</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                    </file-input>
                    <span class="error" v-if="submited && !identificationBackImage">Por favor selecciona una imagen del dorso de tu cédula de identidad</span>
                    <span class="error" v-if="submited && !checkIdentificationImageSize(identificationBackImage)">
                      El archivo que intenta agregar supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB
                    </span>
                  </form-label>
                </div>
              </div>
            </fieldset>

            <div class="mt-8 mb-4">
              <input
                type="checkbox"
                v-model="acceptPolicy"
                class="form-checkbox"
                value="true"
              />
              <span class="ml-2 font-normal font-body">
                Estoy de acuerdo con los 
                <router-link :to="{ name: 'TermsCondition' }" 
                  target="_blank">términos de uso 
                </router-link>
                de Hand y al hacer click en el cuadro y continuar, acepto que Hand o sus representantes puedan comunicarse conmigo
                por correo electrónico, teléfono o SMS(incluído el sistema de marcación telefónica automática) a la dirección o número
                de correo electrónico que proporcione, inclusive con fines de marketing. He leído y entiendo la
                <router-link :to="{ name: 'PrivacyPolicy' }" 
                  target="_blank">política de privacidad
                </router-link>
                relevante.
              </span>
              <span class="error block mt-2" v-if="submited && acceptPolicy != true">Debe aceptar las politicas de privacidad y condiciones para registrarse</span>
            </div>

            <div class="justify-center flex w-full">
              <vue-recaptcha
                :sitekey="sitekey"
                :loadRecaptchaScript="true"
                ref="recaptcha"
                @verify="onRecaptchaVerify"
                @expired="onRecaptchaExpired"
              ></vue-recaptcha>
              <span class="error" v-if="submited && !isRecaptchaVerify">Por favor, verifique el captcha</span>
            </div>

            <div class="w-full flex justify-center mt-[20px]">
              <div class="w-[100%] xl:w-[40%] flex flex-col items-center">
                <rounded-button :disabled="processing" :onClick="onSubmit">
                  Enviar solicitud
                </rounded-button>
                <div class="w-max flex items-center max-xl:flex-col justify-between mt-[21px]">
                  <span>¿Necesitas ayuda?</span>
                  <div>
                    <whatsapp-icon class="mx-[6px] inline-block"/>
                    <custom-link :href="'#'">0981888777</custom-link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Tooltip from "@/components/v2/Tooltip.vue";
import { mapGetters } from "vuex";
import { RoundedButton } from "@/components/v2/buttons"
import { FormLabel, FileInput } from "@/components/v2/forms";
import { CloudUploadIcon, CheckCircleIcon } from "@/components/v2/icons";
import VueRecaptcha from "vue-recaptcha";
import {WhatsappIcon} from "@/components/v2/icons";
import CustomLink from "@/components/v2/Link.vue"
export default {
  data() {
    return {
      submited: false,
      processing: false,
      identificationFrontImage: null,
      identificationBackImage: null,
      acceptPolicy: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      recaptchaToken: "",
      isRecaptchaVerify: false,
    };
  },
  mounted() {
    if (!this.RegisterProfessionalTempData || !this.RegisterProfessionalTempData.location_id) {
      this.$router.push({ name: 'RegisterProfessionalStep2' })
    }

    this.$store.dispatch("setShowAlert", false);
    this.$store.dispatch("setShowHeaderBackButton", true);
    this.$store.dispatch("setShowHeaderCancelButton", true);
    this.$store.dispatch("saveRegisterProfessionalCurrentStep", 3);
  },
  beforeDestroy() {
    this.$store.dispatch("setShowHeaderBackButton", false);
    this.$store.dispatch("setShowHeaderCancelButton", false);
  },
  methods: {
    handleSelectedFileFront(file){
      this.identificationFrontImage = file
    },
    handleSelectedFileBack(file){
      this.identificationBackImage = file
    },
    handleDeletedFileFront(file){
      this.identificationFrontImage = null
    },
    handleDeletedFileBack(file){
      this.identificationBackImage = null
    },
    async onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processing = true;

      if (this.validateForm()) {
        var formData = new FormData();

        formData.append('firstName', this.RegisterProfessionalTempData.firstName)
        formData.append('lastName', this.RegisterProfessionalTempData.lastName)
        formData.append('password', this.RegisterProfessionalTempData.password)
        formData.append('countryCode', this.RegisterProfessionalTempData.countryCode)
        formData.append('phoneNumber', this.RegisterProfessionalTempData.phoneNumber)
        formData.append('identificationNumber', this.RegisterProfessionalTempData.identificationNumber)
        formData.append('birthDate', this.RegisterProfessionalTempData.birthDate)
        formData.append('location_id', this.RegisterProfessionalTempData.location_id)
        formData.append("email", this.RegisterProfessionalTempData.email);
        formData.append("oauthProvider", this.RegisterProfessionalTempData.oauthProvider);
        formData.append("oauthUid", this.RegisterProfessionalTempData.oauthUid);
        formData.append("identityImage", this.identificationFrontImage);
        formData.append("identityImage", this.identificationBackImage);
        formData.append("serviceDescription", "description");
        formData.append("recaptchaToken", this.recaptchaToken);

        const response = await fetch(this.RegisterProfessionalTempData.pictureProfileUrl);
        const pictureBlob = await response.blob();

        formData.append("profileImage", pictureBlob);

        if (this.validateForm()) {
          this.$store.dispatch("registerProfessional", formData)
          .then(() => {
            this.processing = false;
            this.$router.push({ name: 'ValidateUser' })
          }).catch(() => {
            this.processing = false;
            const errorMessage = this.error.response.data.msg ? this.error.response.data.msg : this.error.response.data.errors[0].msg;

            this.$store.dispatch("setShowAlert", true);
            this.$store.dispatch("setIsErrorAlert", true)
            this.$store.dispatch("setGlobalMessage", errorMessage)
          })
        }
      } else {
        this.processing = false;
      }
    },
    validateForm() {
      return (
        this.identificationFrontImage != null &&
        this.checkIdentificationImageSize(this.identificationFrontImage) &&
        this.checkIdentificationImageSize(this.identificationBackImage) &&
        this.identificationBackImage != null &&
        this.acceptPolicy == true &&
        this.isRecaptchaVerify == true
      );
    },
    checkIdentificationImageSize(file) {
      const limitSize = 1024 * 1024 * 10

      if (file && file.size < limitSize) {
        return true
      }

      return false
    },
    onRecaptchaVerify(recaptchaToken) {
      this.isRecaptchaVerify = true
      this.recaptchaToken = recaptchaToken;
    },
    onRecaptchaExpired() {
      this.isRecaptchaVerify = false
      this.$refs.recaptcha.reset();
      this.recaptchaToken = "";
    },
  },
  computed: {
    ...mapGetters(["error", "RegisterProfessionalTempData"]),
  },
  components: {
    Tooltip,
    RoundedButton,
    FileInput, 
    CloudUploadIcon,
    CheckCircleIcon,
    FormLabel,
    VueRecaptcha,
    WhatsappIcon,
    CustomLink
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
.whatsapp-contact-icon {
  top: 2px;
}
.back-id-input-container {
  margin-top: 24px;
}

</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>
