
import redirectBasedRole from "./redirect-base-role";
import {Professionals} from "../../services"
const checkProfessionalByToken = (to, from, next) => {
  const back = (error)=>next({path:"/"})
  Professionals.getProfileByToken({token:to.params.token})
    .then(next)
    .catch(back)
};

export default checkProfessionalByToken;
