<template>
<div class="w-full flex justify-end items-start pr-[50px]">
  <div class="flex flex-col justify-center items-center mt-[115px]">
    <template v-for="(step,i) in steps">
        <div :class="[
                        `rounded-full `,
                        isCompleted(i) || isCurrent(i) ? 'bg-primary' : 'bg-gray2',
                        isCurrent(i) ? 'w-[17px] h-[17px]' : 'w-[9px] h-[9px]'
                     ]">
        </div>
        <div
          v-if="!isLast(i)"
          :class="[`w-px h-[100px]`,isCompleted(i+1) ?'bg-primary':'bg-gray2']"
        ></div>
    </template>
  </div>
  <div class="flex flex-col mt-[100px] ml-[22px]">
    <template v-for="(step,i) in steps">
      <div :class="[`flex flex-col mb-[70px]`,isCurrent(i) ? 'text-black' : 'text-gray3']">
        <span class="font-semibold leading-[20px]">{{step.title}}</span>
        <span class="leading-[20px]">{{ step.description }}</span>
      </div>
    </template>
  </div>
</div>
</template>
<script>
export default{
  data()
  {
    return {
      steps:[
        {
          title:"Paso 1",
          description:"Seleccionar rubros"
        },
        {
          title:"Paso 2",
          description:"Seleccionar área de cobertura"
        },
        {
          title:"Paso 3",
          description:"Descripción profesional"
        },
      ],
    }
  },
  methods:{
    isLast(stepIndex)
    {
      return stepIndex===this.steps.length-1
    },
    isCompleted(stepIndex)
    {
      return stepIndex<this.$route.params.step;
    },
    isCurrent(stepIndex)
    {
      return this.$route.params.step==(stepIndex+1)
    }
  },
}
</script>
