<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <ProfessionalProfileSidebar />
          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between mb-2 lg:mb-4 lg:flex-row lg:items-center">
              <h4 class="mb-0 lg:mt-0">
                <svg class="-mt-2 icon">
                  <use
                    href="../../assets/img/icons.svg#icon-email"
                    xlink:href="../../assets/img/icons.svg#icon-email"
                  />
                </svg>
                Editar profesión/servicios
              </h4>
            </div>

            <div class="mb-2 overflow-x-auto bg-white rounded shadow-md register-professional">
              <div class="container flex flex-col container-xl md:flex-row md:items-center">
                <div class="w-full mb-16 md:mb-0 p-5">
                  <h3 class="text-center text-brand-secondary mb-4 md:w-2/3 md:mx-auto">
                    ¿Que servicios ofreces?
                  </h3>

                  <div class="flex my-4 justify-center">
                    <div
                      class="p-2 w-32 h-auto flex items-center justify-center bg-info-icon-red text-center"
                    >
                      <img src="../../assets/img/Icon-Vector.svg" class="inline-block" />
                    </div>
                    <div class="w-auto py-2 px-6 bg-info-content-red text-black">
                      Atención! Tenga en cuenta que al editar las profesiones y/o servicios serán reseteados
                      por seguridad, y va requerir la configuración de servicios y precios nuevamente.
                    </div>
                  </div>
                  <div class=" md:px-0">
                    <div>
                      <form @submit.prevent="confirmUpdateService" method="post">
                        <div v-if="selectedProfessions && selectedProfessions.length > 0">
                          <div
                            class="relative my-6 bg-white border shadow-lg border-solidborder-gray-400 rounded p-6"
                            v-for="(profession, index) in selectedProfessions"
                            :key="index"
                            :id="'profession_' + profession.id"
                            v-bind:class="{
                              OnErrorScrollHere:
                                submited && !checkProfessionServiceByType(profession.type)
                            }"
                          >
                            <span
                              v-if="selectedProfessions.length > 1"
                              @click="removeProfession(index)"
                              class="absolute top-0 right-0 flex items-center justify-center w-8 h-8 p-2 text-lg text-white btn btn--secondary remove-btn"
                              >X</span
                            >
                            <div class="mb-4">
                              <article>
                                <header class="w-full items-center py-3 mb-5">
                                  <h5 class="text-center" style="font-size: 1.375rem;">
                                    {{ profession.description }}
                                  </h5>
                                  <div class="flex flex-wrap items-center justify-center">
                                    <div class="w-full sm:w-1/2 md:w-1/2">
                                      <div class="flex my-4 justify-center">
                                        <div
                                          class="p-2 w-16 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                                        >
                                          <img
                                            src="../../assets/img/icon-Idea.svg"
                                            class="inline-block"
                                          />
                                        </div>
                                        <div
                                          class="w-auto py-2 px-6 bg-info-content-yellow text-black"
                                        >
                                          Selecciona los servicios que ofreces.
                                        </div>
                                      </div>
                                      <div
                                        class="text-center p-2"
                                        v-bind:class="{
                                          'bg-red-100 border-red-700':
                                            submited &&
                                            !checkProfessionServiceByType(profession.type)
                                        }"
                                      >
                                        <span
                                          class="error"
                                          v-if="
                                            submited &&
                                              !checkProfessionServiceByType(profession.type)
                                          "
                                          >Debe seleccionar al menos un servicio</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </header>

                                <ul
                                  class="mb-4 grid grid-columns-1 md:grid-gap-4 md:grid-columns-2 lg:grid-gap-8 lg:grid-columns-3"
                                  v-if="
                                    servicesByType(profession.type) &&
                                      servicesByType(profession.type).length > 0
                                  "
                                >
                                  <li
                                    v-for="(service, serviceIndex) in servicesByType(
                                      profession.type
                                    )"
                                    :key="serviceIndex"
                                  >
                                    <label class="flex items-center">
                                      <input
                                        type="checkbox"
                                        v-model="professionalServices"
                                        class="form-checkbox"
                                        :value="service.id"
                                        @change="
                                          selectProfessionService(
                                            $event,
                                            service.id,
                                            profession.type
                                          )
                                        "
                                      />
                                      <span class="ml-4 text-black">
                                        {{ service.description }}
                                      </span>
                                    </label>
                                  </li>
                                </ul>
                              </article>
                              <div
                                class="mb-4 items-center justify-center bg-light-grey-color rounded p-5"
                              >
                                <div>
                                  <p class="text-black text-center font-medium">
                                    Indica los años de experiencia que tienes en el rubro
                                  </p>
                                </div>
                                <div class="flex w-full items-center justify-center">
                                  <div class="w-full md:w-1/3">
                                    <select
                                      class="block w-full mt-1 rounded form-select"
                                      v-model="selectedProfessions[index].experience"
                                    >
                                      <option value="null">Años de experiencia</option>
                                      <option value="1">1 año</option>
                                      <option value="2">2 años</option>
                                      <option value="3">3 años</option>
                                      <option value="4">4 años</option>
                                      <option value="5">5 años</option>
                                      <option value="6">6 años</option>
                                      <option value="7">7 años</option>
                                      <option value="8">8 años</option>
                                      <option value="9">9 años</option>
                                      <option value="10">10 años</option>
                                      <option value="11">11 a 20 años</option>
                                      <option value="12">21 a 30 años</option>
                                      <option value="13"> más de 30 años</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="relative my-6 bg-white border shadow-lg border-solidborder-gray-400 rounded p-6"
                        >
                          <div class="flex mb-4 items-center justify-center">
                            <div class="w-full md:w-2/3">
                              <p class="text-black text-center font-medium md:px-6 text-lg">
                                ¿Tienes otro oficio en el cuál te desempeñas que quieres ofrecer?
                                Suma otro rubro/categoría a tu perfil.
                              </p>
                              <label>
                                <span>Oficio(s)</span>
                                <select
                                  class="block w-full mt-1 rounded form-select"
                                  v-model="addProfession"
                                  @change="addNewProfession"
                                >
                                  <option value="null"> Elige uno o varios oficios</option>
                                  <option
                                    v-for="professionItem in professions"
                                    :key="professionItem.id"
                                    :value="professionItem"
                                    >{{ professionItem.description }}</option
                                  >
                                </select>
                                <span class="error" v-if="showProfessionLimitError"
                                  >You Can select Maximum 5 Profession.</span
                                >
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="flex justify-end">
                          <button class="w-full btn btn--success lg:w-auto">Guardar datos</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfessionalProfileSidebar from "@/components/ProfessionalProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import scrollToSection from "@/utils/methods/scrollToSection";
import GetProfessions from "@/utils/methods/GetProfessions";
import getServiceByType from "@/utils/methods/getServiceByType";
import servicesByType from "@/utils/methods/servicesByType";
import removeProfession from "@/utils/methods/removeProfession";
import addNewProfession from "@/utils/methods/addNewProfession";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      submited: false,
      processingProfessionalData: false,
      processingUpdateProfessional: false,
      ProfessionalId: null,
      professionalInfo: {},
      selectedProfessions: [],
      professions: [],
      services: [],
      professionalServices: [],
      selectedServices: [],
      addProfession: null,
      showProfessionLimitError: false,
      updateProfessionalData: {
        professionalId: null,
        requestData: {
          professions: [],
          services: []
        }
      }
    };
  },
  created() {
    this.ProfessionalId = this.user.id;
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetProfessionalData();
    this.GetProfessions();
  },
  beforeDestroy() {},
  methods: {
    scrollToSection,
    GetProfessions,
    getServiceByType,
    servicesByType,
    removeProfession,
    addNewProfession,
    checkProfessionService() {
      if (this.selectedProfessions.length > 0) {
        for (var x = 0; x < this.selectedProfessions.length; x++) {
          if (this.selectedServices && this.selectedServices.length > 0) {
            let isexistService = false;
            for (var y = 0; y < this.selectedServices.length; y++) {
              if (this.selectedServices[y].professionType == this.selectedProfessions[x].type) {
                isexistService = true;
              }
            }
            if (!isexistService) {
              return false;
            }
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    checkProfessionServiceByType(professionType) {
      if (this.selectedServices.length > 0) {
        let isexistService = false;
        for (var z = 0; z < this.selectedServices.length; z++) {
          if (this.selectedServices[z].professionType == professionType) {
            isexistService = true;
          }
        }
        if (!isexistService) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    },
    selectProfessionService(e, serviceid, professionType) {
      if (serviceid && professionType) {
        if (e.target.checked) {
          this.selectedServices.push({ service_id: serviceid, professionType: professionType });
        } else {
          for (var i = 0; i < this.selectedServices.length; i++) {
            if (
              this.selectedServices[i].service_id == serviceid &&
              this.selectedServices[i].professionType == professionType
            ) {
              this.selectedServices.splice(i, 1);
            }
          }
        }
      }
    },
    GetProfessionalData() {
      this.processingProfessionalData = true;
      this.$store
        .dispatch("GetProfessionalData", {
          userId: this.ProfessionalId
        })
        .then(response => {
          this.professionalInfo = response;
          for (var p = 0; p < this.professionalInfo.Services.length; p++) {
            this.professionalServices.push(this.professionalInfo.Services[p].id);
            this.selectedServices.push({
              service_id: this.professionalInfo.Services[p].id,
              professionType: this.professionalInfo.Services[p].type
            });
          }
          this.selectedProfessions = this.professionalInfo.Profession;
          for (var i = 0; i < this.selectedProfessions.length; i++) {
            this.selectedProfessions[i].experience = 1;
            this.getServiceByType(this.selectedProfessions[i].type);
          }
          this.processingProfessionalData = false;
        })
        .catch(() => {
          this.processingProfessionalData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    updateProfessionalService() {
      this.updateProfessionalData.professionalId = this.ProfessionalId;
      this.updateProfessionalData.requestData.professions = [];
      this.updateProfessionalData.requestData.services = [];
      if (this.selectedProfessions && this.selectedProfessions.length > 0) {
        for (var i = 0; i < this.selectedProfessions.length; i++) {
          this.updateProfessionalData.requestData.professions.push({
            profession_id: this.selectedProfessions[i].id,
            experience: this.selectedProfessions[i].experience
          });
          if (this.selectedServices.length > 0) {
            for (var j = 0; j < this.selectedServices.length; j++) {
              if (this.selectedServices[j].professionType == this.selectedProfessions[i].type) {
                this.updateProfessionalData.requestData.services.push({
                  service_id: this.selectedServices[j].service_id
                });
              }
            }
          }
        }
      }
      this.$store
        .dispatch("updateProfessionalService", this.updateProfessionalData)
        .then(() => {
          this.processingUpdateProfessional = false;
          this.submited = false;
          let UpdateServiceMessage = `Perfil actualizado correctamente.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", UpdateServiceMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingUpdateProfessional = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
        });
    },
    confirmUpdateService() {
      this.submited = true;
      this.processingUpdateProfessional = true;
      if (this.validateForm()) {
        Swal.fire({
          title: "",
          text: "Esta seguro que desea re-establecer sus datos de oficio(s) y servicios?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No"
        }).then(result => {
          if (result.value) {
            this.updateProfessionalService();
          } else {
            this.processingreview = false;
            this.submited = false;
          }
        });
      } else {
        this.processingUpdateProfessional = false;
        setTimeout(function() {
          var d = document.getElementsByClassName("OnErrorScrollHere")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    validateForm() {
      return this.checkProfessionService();
    }
  },
  computed: {
    ...mapGetters(["error", "user"])
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfessionalProfileSidebar
  }
};
</script>
<style lang="css" scoped>
@import "../../assets/css/registrarme-profesional.css";
@import "../../assets/css/dashboard.css";

.success {
  color: #1bb01b;
}
.unread_message {
  font-weight: 900;
  color: black;
}
.unread_message_row {
  background: #eee;
}
.register-professional .bg-info-icon-red {
  background-color: #d53227;
}
.register-professional .bg-info-content-red {
  background-color: #ee5a4f;
  color: white;
}
</style>
