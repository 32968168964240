<template>
  <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="w-full flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="w-1/2 relative transform overflow-hidden rounded-lg bg-gray-600 text-left shadow-xl transition-all sm:my-8 sm:w-full"
        >
          <div class="w-full flex-1 flex flex-col">
            <div class="p-5 overflow-hidden bg-white border border-gray-400 rounded">
              <div class="w-full flex flex-col">
                <div class="w-full flex justify-between mb-4">
                  <div class="flex items-center">
                    <span class="font-bold text-lg mr-4">#{{ serviceRequest.id }}</span>
                    <span
                      :class="[
                        'font-bold text-lg',
                        { 'text-brand-secondary success': serviceRequest.status == 'complete' },
                        { 'text-brand-primary': serviceRequest.status == 'ongoing' },
                        { 'text-brand-secondary': serviceRequest.status == 'pending' },
                      ]"
                    >
                      {{ statusTitle }}
                    </span>
                  </div>
                  <div @click="$emit('close-modal')">
                    <TimesIcon class="cursor-pointer" color="#000" />
                  </div>
                </div>
                <Services
                  :serviceRequest="serviceRequest"
                  :details="serviceRequest.details"
                  :extra_services="serviceRequest.extra_services"
                />
                <div v-if="serviceRequest.status === 'complete'" id class="w-full flex flex-col my-3">
                  <div class="flex flex-col">
                    <span class="text-lg font-bold">Detalles</span>
                    <span class="text-base">{{ serviceRequest.service_finished_description }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span class="text-lg font-bold">Foto</span>
                    <img :src="serviceRequest.service_finished_photo" class="w-[150px] h-auto rounded" alt="">
                  </div>
                </div>
                <div class="w-full flex my-3" style="height: 200px">
                  <div class="w-1/2">
                    <l-map :zoom="20" :center="location_coordinates">
                      <l-tile-layer
                        :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"
                      ></l-tile-layer>
                      <l-marker :lat-lng="location_coordinates"></l-marker>
                    </l-map>
                  </div>
                  <div class="w-1/2 h-full flex flex-col justify-center text-xl px-5">
                    <div class="flex items-center">
                      <map-pin-icon class="w-6 h-6 mr-2" color="#686b79" /><i class="text-base font-bold">{{
                        serviceRequest.direction
                      }}</i>
                    </div>
                    <div class="flex items-center">
                      <calendar-days-icon class="w-6 h-6 mr-2" color="#686b79" /><i
                        class="text-base font-bold"
                        >{{ date }}</i
                      >
                    </div>
                    <div class="flex items-center">
                      <clock-icon class="w-6 h-6 mr-2" color="#686b79" /><i class="text-base font-bold">{{
                        serviceRequest.serviceHour
                      }}</i>
                    </div>
                  </div>
                </div>
                <div class="w-full flex">
                  <div class="w-1/2 flex">
                    <User :type="'Cliente'" :user="client.user" />
                  </div>
                  <div class="w-1/2 flex items-center">
                    <User :type="'Profesional'" :user="professional.user" />
                    <div class="cursor-pointer" @click="onRequestChangeProfessional">
                      <PencilIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import thousandSeparator from "@/utils/filters/thousandSeparator";
import statusTranslations from "@/utils/helpers/ServiceRequest/statusTranslations";
import User from "./User.vue";
import Services from "./Services.vue";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import { MapPinIcon, CalendarDaysIcon, ClockIcon, PencilIcon, TimesIcon } from "@/components/icons";
import dayjs from "dayjs";
export default {
  props: {
    serviceRequest: {
      type: Object,
      default: () => ({}),
    },
    onRequestChangeProfessional: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {},
  filters: {
    thousandSeparator,
  },
  computed: {
    statusTitle() {
      return statusTranslations[this.serviceRequest.status];
    },
    professional() {
      return this.serviceRequest.professional_profile;
    },
    client() {
      return this.serviceRequest.client_profile;
    },
    location_coordinates() {
      return [parseFloat(this.serviceRequest.latitude), parseFloat(this.serviceRequest.longitude)];
    },
    date() {
      return dayjs(this.serviceRequest.date).format("DD/MM/YYYY");
    },
  },
  components: {
    Services,
    TimesIcon,
    User,
    LMap,
    LMarker,
    LTileLayer,
    MapPinIcon,
    CalendarDaysIcon,
    ClockIcon,
    PencilIcon,
  },
};
</script>
<style lang="css" scoped>
.success {
  color: #1bb01b;
}
</style>
