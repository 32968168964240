<template>
  <div class="">
    <div class="container">
      <div class="my-10 mb-10">
        <h3>Botones</h3>
        <div class="my-3">
          <rounded-button>
            Button
          </rounded-button>
        </div>
        <div class="my-3">
          <rounded-button disabled>
            Button disabled
          </rounded-button>
        </div>
        <div class="my-3">
          <rounded-button ghost>
            Button
          </rounded-button>
        </div>
        <div class="my-3">
          <rounded-button ghost disabled>
            Button
          </rounded-button>
        </div>
        <div class="my-3 flex">
          <div class="w-1/2 flex flex-col mr-1">
            <div class="my-3">
              <rounded-button ghost>
                Button
              </rounded-button>
            </div>
            <div class="my-3">
              <rounded-button>
                Button
              </rounded-button>
            </div>
          </div>
          <div class="w-1/2 flex flex-col ml-1">
            <div class="my-3">
              <rounded-button ghost :color="'secondary'">
                Button
              </rounded-button>
            </div>
            <div class="my-3">
              <rounded-button :color="'secondary'">
                Button
              </rounded-button>
            </div>
          </div>

        </div>
        <div class="my-3 flex">
          <div class="w-1/2 flex flex-col mr-1">
            <div class="my-3">
              <rounded-button ghost>
                Button
                <div class="ml-[10px]">
                  <cloud-upload-icon :color="'primary'"/>
                </div>
              </rounded-button>
            </div>
            <div class="my-3">
              <rounded-button>
                Button
                <div class="ml-[10px]">
                  <cloud-upload-icon :color="'white'"/>
                </div>
              </rounded-button>
            </div>
          </div>
          <div class="w-1/2 flex flex-col ml-1">
            <div class="my-3">
              <rounded-button ghost :color="'secondary'">
                <div class="mr-[10px]">
                  <cloud-upload-icon :color="'secondary'"/>
                </div>
                Button
              </rounded-button>
            </div>
            <div class="my-3">
              <rounded-button :color="'secondary'">
                <div class="mr-[10px]">
                  <cloud-upload-icon :color="'white'"/>
                </div>
                Button
              </rounded-button>
            </div>
          </div>

        </div>
        <div class="my-3 flex">
          <div class="w-1/2 flex flex-col mr-1">
            <div class="my-3">
              <rounded-button ghost :color="'dark'">
                Button
                <div class="ml-[10px]">
                  <cloud-upload-icon :color="'dark'"/>
                </div>
              </rounded-button>
            </div>
            <div class="my-3">
              <rounded-button :color="'dark'">
                Button
                <div class="ml-[10px]">
                  <cloud-upload-icon :color="'white'"/>
                </div>
              </rounded-button>
            </div>
          </div>
          <div class="w-1/2 flex flex-col ml-1">
            <div class="my-3">
              <rounded-button ghost :color="'dark'">
                <div class="w-full flex items-center justify-between px-[20px]">
                  <arrow-left-icon :color="'dark'"/>
                  Button
                </div>
              </rounded-button>
            </div>
            <div class="my-3">
              <rounded-button :color="'dark'">
                <div class="w-full flex items-center justify-between px-[20px]">
                  <arrow-left-icon :color="'white'"/>
                  Button
                </div>
              </rounded-button>
            </div>
          </div>
        </div>
        <div class="my-3 flex">
          <div class="w-1/2 mr-1">
            <social-button :icon="'facebook'" :title="'Facebook'"/>
          </div>
          <div class="w-1/2 ml-1">
            <social-button :icon="'google'" :title="'Google'"/>
          </div>
        </div>
      </div>
      <h3>Inputs de archivos</h3>
      <div class="w-full flex flex-col my-3">
        <h4>Default</h4>
        <file-input @selected="handleSelectedFile" />
      </div>
      <div class="w-full my-3">
        <h4>Custom</h4>
        <file-input :accept="'.jpg, .png'" @selected="handleSelectedFile" :color="'dark'">
          <template #placeholder>
            <span class="text-primary">Elija una <span class="font-bold">foto</span></span>
          </template>
          <template #trigger-icon>
            <plus-circle-icon :width="15" :height="15"/>
          </template>
          <template #modal-content>
            <span class="text-[25px] leading-[29px] text-black font-bold">Title</span>
            <span class="leading-[24px] text-dark">SubTitle</span>
            <div class="w-full border border-green rounded-[6px] py-[16px] px-[20px] mt-[32px] flex">
              <img src="../../assets/v2/img/cedula-front.png" alt="">
              <div class="flex-grow flex flex-col ml-[16px]">
                <div class="w-full flex items-center">
                  <check-circle-icon />
                  <span class="text-[14px] leading-[18px] font-semibold text-black ml-[10px]">Title</span>
                </div>
                <div class="w-full mt-[7px]">
                  <p class="text-gray leading-[16px]">Lorem ipsum dolor sit amet, saepe sit ut vel!Lorem ipsum dolor sit amet, saepe sit ut vel</p>
                </div>
              </div>
            </div>
          </template>
        </file-input>
      </div>
      <div class="w-full my-3">
        <h4>Disabled</h4>
        <file-input @selected="handleSelectedFile" :disabled="true" :color="'secondary'">
          <template #placeholder>
            <span class="text-secondary">Elija una imagen</span>
          </template>
        </file-input>
      </div>
      <h3>Confirmaciones</h3>
      <div class="w-full flex flex-col my-3">
        <h4>Default</h4>
        <confirmation :show="showConfirmation" @afterConfirmation="testAfterConfirmation"  
                      :subtitle="'Por favor confirma tu dirección y area de cobertura para tomar los pedidos de servicios'"
                      :buttonText ="'Confirmar Direccion'"
                      >
          <template #modal-content>
            <div class="w-full border border-green bg-green2 rounded-[6px] py-[16px] px-[20px] mt-[32px] flex">
              <div class="flex-grow flex flex-col ml-[16px]">
                <div class="w-full flex items-center">
                  <span class="text-[18px] font-semibold text-black">Nombre de la calle 234</span>
                </div>
                <div class="w-full mt-[15px]">
                  <p class="text-black text-[14px]">20 km a la redonda desde la dirección ingresada.</p>
                </div>
              </div>
            </div>
          </template>
        </confirmation>
        <rounded-button :onClick="toggleConfirmation" :color="'primary'">
          Mostrar Confirmacion
        </rounded-button>
      </div>
      <h3>Formularios</h3>
      <div class="w-full flex flex-col my-3">
        <h4>Selector Numérico</h4>
        <number-selector :initial_value="1"/>
      </div>
      <div class="w-full flex flex-col my-3">
        <h4>Select - Default</h4>
        <selector :placeholder="'Seleccionar país'" :options="[{label: 'Canada', code: 'ca'}, {label: 'United States', code: 'us'}]"/>
      </div>
      <div class="w-full flex flex-col my-3">
        <h4>Select - Disabled</h4>
        <selector :disabled="true" :placeholder="'Seleccionar país'" :options="[{label: 'Canada', code: 'ca'}, {label: 'United States', code: 'us'}]"/>
      </div>
      <h3>&nbsp;</h3>
      <h3>&nbsp;</h3>
    </div>
  </div>
</template>
<script>
import {RoundedButton,SocialButton} from "@/components/v2/buttons"
import Rounded from "@/components/v2/buttons/Rounded.vue";
import {CloudUploadIcon,ArrowLeftIcon,CheckCircleIcon,PlusCircleIcon} from "@/components/v2/icons";
import FileInput from "@/components/v2/forms/FileInput.vue";
import Confirmation from "@/components/v2/forms/Confirmation.vue";
import NumberSelector from "@/components/v2/forms/NumberSelector.vue";
import { Selector } from "@/components/v2/forms";
export default {
  components:{
    FileInput,
    Confirmation,
    Rounded,
    RoundedButton,
    SocialButton,
    CloudUploadIcon,
    ArrowLeftIcon,
    CheckCircleIcon,
    PlusCircleIcon,
    NumberSelector,
    Selector
  },
  data()
  {
    return {
      file:null,
      showConfirmation:false
    }
  },
  methods:{
    testAfterConfirmation(){
      alert("Se ha confirmado")
    },
    toggleConfirmation () {
        this.showConfirmation = !this.showConfirmation
    },
    handleSelectedFile(file)
    {
      this.file = file
    }
  }
}
</script>
