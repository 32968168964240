<template>
  <div class="flex flex-col">
    <input
      :id="id"
      type="file"
      class="w-0 h-0 d-none m-0 p-0"
      style="width:0px;height:0px;display: none;padding: 0;margin: 0;"
      :accept="accept"
      :multiple="multiple"
      @change="fileSelected"
    />
    <slot name="label"></slot>
    <div :class="[
                    `h-[49px] flex items-center justify-between px-[13px] border  rounded-[4px]`,
                    {'mt-[14px]':!!$slots.label},
                    !files.length ? `border-grayLight` : `border-green bg-[#E8FFF3]`,
                    disabled ? 'opacity-50 pointer-events-none' : ''
                 ]">
      <template v-if="!files.length">
        <slot name="placeholder">
          <span class="text-dark2">Seleccionar archivo</span>
        </slot>
      </template>
      <template v-else>
        <span class="text-dark2">{{ multiple ? `${files.length} archivos` : files[0].name}}</span>
      </template>

      <template v-if="!showDeleteFileButton">
        <label
          :for="!$slots['modal-content'] ? 'file-input' : ''"
          @mouseover="showDeleteButton"
          @click="showModal=true"
          :class="[`w-[41px] h-[30px] m-0 rounded-[4px] flex items-center justify-center cursor-pointer`,!files.length ? `bg-${color} ` : `bg-green`]"
        >
          <template v-if="!files.length" >
            <slot name="trigger-icon">
              <cloud-upload-icon :color="'white'" />
            </slot>
          </template>
          <template v-else>
            <check-icon />
          </template>
        </label>
      </template>
      <template v-else>
        <div
          :class="[`w-[41px] h-[30px] rounded-[4px] flex items-center justify-center cursor-pointer bg-secondary`]"
          @mouseleave="showDeleteFileButton=false"
          @click="deleteFile"
        >
          <trash-icon />
        </div>
      </template>
    </div>
    <modal v-if="showModal" @close="showModal=false">
        <div class="w-full flex flex-col items-center">
          <slot name="modal-content"></slot>
          <label :for="id" class="mt-[30px] text-white">
            <rounded-button :color="color" :size="'large'">
              <span class=" text-[16px] font-medium mr-[15px] text-white">{{text}}</span>
              <slot name="trigger-icon">
                <cloud-upload-icon :color="'white'" />
              </slot>
            </rounded-button>
          </label>
        </div>
    </modal>
  </div>
</template>
<script>
import { CameraIcon, AlertCircleIcon, CloudUploadIcon,CheckCircleIcon,CheckIcon,TrashIcon } from "@/components/v2/icons";
import Tooltip from "@/components/v2/Tooltip.vue";
import Modal from "@/components/v2/Modal.vue";
import {RoundedButton} from "@/components/v2/buttons";
export default {
  components: {
                AlertCircleIcon,
                CameraIcon,
                Tooltip,
                CloudUploadIcon,
                Modal,
                CheckCircleIcon,
                RoundedButton,
                CheckIcon,
                TrashIcon
              },
  props:{
    color:{
      type:String,
      default:"primary"
    },
    disabled:{
      type:Boolean,
      default:false
    },
    accept:{
      type:String,
      default:''
    },
    text:{
      type:String,
      default: 'Subir'
    },
    multiple:{
      type:Boolean,
      default:false
    }
  },
  data()
  {
    return {
      showModal:false,
      showDeleteFileButton:false,
      files:[],
      fileName:'',
      id:'file-input-'+(Math.random())
    }
  },
  methods:{
    fileSelected(event)
    {
      this.files = event.target.files
      this.$emit('selected', this.multiple ? this.files : this.files[0])
      this.showModal = false
      //const image_url = URL.createObjectURL(file)
    },
    showDeleteButton()
    {
      this.showDeleteFileButton = !!this.files.length
    },
    deleteFile()
    {
      this.$emit('deleted', this.multiple ? this.files : this.files[0])

      this.files = []
      this.showDeleteFileButton=false
    },
  }
}
</script>
