<template>
  <div :class="[`w-full px-[20px] pt-[36px] pb-[58px] xl:p-[58px] xl:flex xl:items-center flex-wrap max-xl:border-l min-xl:border-l-0 border-y border-r border-gray2 rounded-[4px] mb-[30px]`]">
    <div class="w-full xl:w-3/4 flex flex-col">
      <div class="w-full flex max-xl:flex-col xl:flex-row xl:mb-[51px]">
        <div class="max-xl:w-full xl:w-max flex flex-col mb-[45px] xl:mb-0">
          <span class="font-medium text-black leading-[24px] mb-[6px]">Selecciona el rubro</span>
          <div class="max-xl:w-full xl:w-[250px]">
            <selector
              :key="rubro.id"
              :placeholder="'Listado de rubros'"
              :options="professions_options"
              :initial_value="selected"
              @selected="onSelectRubro"
            />
          </div>
        </div>
        <div class="max-xl:w-full xl:w-max flex flex-col xl:ml-[31px] mb-[45px] xl:mb-0">
          <span class="font-medium text-black leading-[24px] mb-[6px]">Años de experiencia</span>
          <div class="max-xl:w-full xl:w-[157px]">
            <number-selector
              :key="rubro.id"
              :initial_value="rubro.year"
              :max="30"
              @change="onSelectYear"
            />
          </div>
        </div>
      </div>
      <template v-if="services.length">
        <div class="w-full grid max-xl:grid-cols-1 max-xl:gap-y-[25px] max-xl:gap-x-[40px] xl:grid-cols-4 xl:gap-x-[50px] xl:gap-y-[20px]">
          <div class="col-span-2">
            <span class="text-[14px] xl:text-[16px] text-black leading-[18px] xl:leading-[24px] font-bold">Selecciona las especificaciones del rubro</span>
          </div>
          <div class="max-xl:hidden xl:block">
            <span class="text-black font-bold">
              Precio
              <div class="ml-1 inline-block">
                <Tooltip message="Los precios son referenciales base de diagnóstico, se pueden modificar de acuerdo a sus requerimientos.">
                  <img src="@/assets/v2/svg/info.svg" class="inline-block w-[15px]"/>
                </Tooltip>
              </div>
            </span>
          </div>
          <div class="max-xl:hidden xl:block">
            <span class="text-black font-bold">
              Garantía
              <div class="ml-1 inline-block">
                <Tooltip message="Se solicita una garantía mínima de 30 días por la mano de obra desde la terminación del trabajo.">
                  <img src="@/assets/v2/svg/info.svg" class="inline-block w-[15px]"/>
                </Tooltip>
              </div>
            </span>
          </div>
          <template v-for="(service) in services">
            <div class="pr-[5px] mt-[5px] col-span-2" :key="service.id">
              <checkbox
                :initial_value="!!service.selected"
                @change="(checked)=>onServiceClicked({isChecked:checked,serviceId:service.id})"
              >
                {{ service.description }}
              </checkbox>
            </div>
            <div :key="'price_'+service.id">
              <div class="max-xl:block xl:hidden mb-[5px]">
                <span class="text-black font-bold">
                  Precio
                  <div class="ml-1 inline-block">
                    <Tooltip message="Los precios son referenciales base de diagnóstico, se pueden modificar de acuerdo a sus requerimientos.">
                      <img src="@/assets/v2/svg/info.svg" class="inline-block w-[15px]"/>
                    </Tooltip>
                  </div>
                </span>
              </div>
              <input
                :value="thousandSeparator(service.price)"
                class="block form-input mt-[5px] w-full"
                type="text"
                placeholder="Ingresa el precio"
                @input="onInputServicePrice($event.target.value, service.id)"
              />
            </div>
            <div :key="'guaranteeTime_'+service.id">
              <div class="max-xl:block xl:hidden mb-[5px]">
                <span class="text-black font-bold">
                  Garantía
                  <div class="ml-1 inline-block">
                    <Tooltip message="Se solicita una garantía mínima de 30 días por la mano de obra desde la terminación del trabajo.">
                      <img src="@/assets/v2/svg/info.svg" class="inline-block w-[15px]"/>
                    </Tooltip>
                  </div>
                </span>
              </div>
              <selector
                :placeholder="'Meses'"
                :initial_value="{label: service.guaranteeTime, value: service.guaranteeTime}"
                :options="guarantee_options"
                @selected="onSelectGuarantee($event, service.id)"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="w-full flex items-center justify-center mt-[80px]">
        <template v-if="!disable_delete">
          <div @click="$emit('delete')" class="cursor-pointer flex items-center">
            <trash-icon :color="'black'" />
            <span class="text-black text-[14px] leading-[16px] ml-[5px] font-bold underline">Eliminar rubro</span>
          </div>
        </template>
    </div>
  </div>
</template>
<script>
import {Selector,Checkbox} from "@/components/v2/forms";
import {TrashIcon} from "@/components/v2/icons";
import {mapState} from "vuex";
import Tooltip from "@/components/v2/Tooltip.vue";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import NumberSelector from "@/components/v2/forms/NumberSelector.vue";

export default{
  components:{
    Selector,
    Checkbox,
    TrashIcon,
    Tooltip,
    NumberSelector
  },
  props:{
    index:{
      type:Number,
      default:0
    },
    props:{
      type:Number,
      default:0
    },
    disable_delete:{
      type:Boolean,
      default:false
    },
    disabled_professions:{
      type:Array,
      default:()=>([])
    },
    initial_value:{
      type:Object,
      default:()=>({})
    }
  },
  data()
  {
    return {
      guarantee_times:Array.from({length: 12}, (_, i) => i + 1),
      rubro: {
        id:"",
        year:1,
        services:[],
      },
      services:[],
      selected: null
    }
  },
  mounted() {
    this.rubro = this.initial_value
    this.selected = this.initial_value.id
                  ? { label: this.initial_value.description, value: this.initial_value.id } 
                  : null
  },
  methods:{
    thousandSeparator,
    onSelectRubro(selectedRubro)
    {
      if (selectedRubro && this.rubro.id != selectedRubro.value) {
        this.selected = selectedRubro ? this.professions_options.find(profession => profession.value == selectedRubro.value) : null
        this.rubro = {
          ...this.professions.find(profession => profession.id == selectedRubro.value),
          services: this.rubros.find(rubro => rubro.id == selectedRubro.value) ? this.rubros.find(rubro => rubro.id == selectedRubro.value).services : [],
          year: this.rubro.year
        }

        this.$emit('change',this.rubro) 
      }
    },
    onSelectYear(year)
    {
      this.rubro = {
        ...this.rubro,
        year: year
      }
      this.$emit('change',this.rubro)
    },
    onSelectGuarantee(guaranteeTime, serviceId)
    {
      console.log(guaranteeTime)
      this.rubro.services.map(service => {
        if(service.id == serviceId) {
          service.guaranteeTime = guaranteeTime.value
        }

        return service
      })
      this.$emit('change',this.rubro)
    },
    onInputServicePrice(price, serviceId)
    {
      const newPrice = price ? price.replace('.', '') : ''
      
      this.rubro.services.map(service => {
        if(service.id == serviceId) {
          service.price = parseInt(newPrice)
        }

        return service
      })

      this.$emit('change',this.rubro)
    },
    onServiceClicked({isChecked,serviceId})
    {
      if(isChecked) {
        if (!this.rubro.services.find(service => service.id == serviceId)) {
          this.rubro.services.push({...this.professions.find(p => p.id == this.rubro.id).services.find(service => service.id === serviceId), selected: true})
        }
      }else{
        this.rubro.services = this.rubro.services.filter(service => service.id != serviceId)
      }
      this.$emit('change',this.rubro)
    }
  },
  computed:{
    professions_options()
    {
      return this.professions.filter(p=>p.id==this.rubro.id || !this.disabled_professions.includes(p.id))
                            .map(profession=>{
                              return  {
                                        value:profession.id,
                                        label:profession.description
                                      }
                            })
    },
    guarantee_options()
    {
      return this.guarantee_times.map(year=>{
        return {
          value:year,
          label:year
        }
      })
    },
    ...mapState("completeProfessionalProfile",["professions", "rubros"])
  },
  watch:{
    rubro(newRubro,oldRubro)
    {
      if(oldRubro!=newRubro)
      {
        this.services = (!!newRubro && !!newRubro.id) ? this.professions.find(p=>p.id===newRubro.id).services : []

        if (oldRubro.id != newRubro.id && this.rubro.services.length) {
          this.rubro.services.map((rubroService) => {
            let findService = this.services.find(service => service.id == rubroService.id)

            if (findService) {
              findService.selected = true
              findService.price = rubroService.price
              findService.guaranteeTime = rubroService.guaranteeTime
            }
          })
        }
      }
    },
    initial_value(initial_value)
    {
      if(initial_value.id && !this.rubro.id)
      {
        this.rubro = initial_value
      }
    }
  }
}
</script>
<style scoped>
  .form-input {
    padding: 6px 10px;
    margin-top: 0;
  }
</style>
