<template>
  <section class="px-2 lg:w-8/12 flex">
    <List :onItemClicked="onClientSelected" />
    <Profile v-if="client" :client="client" @show-service-requests="show_service_requests = true" />
    <template v-if="show_service_requests">
      <ServiceRequests @hide="hideServiceRequests" :client="client" />
    </template>
  </section>
</template>

<script>
import List from "./List/index.vue";
import Profile from "./Profile/index.vue";
import ServiceRequests from "./ServiceRequests/index.vue";
export default {
  components: {
    List,
    Profile,
    ServiceRequests,
  },
  data() {
    return {
      client: null,
      show_service_requests: false,
    };
  },
  methods: {
    onClientSelected({ item }) {
      this.client = item;
    },
    showServiceRequests() {
      this.show_service_requests = true;
    },
    hideServiceRequests() {
      this.show_service_requests = false;
    },
  },
};
</script>
<style scoped></style>
