<template>
  <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-[#262626] bg-opacity-30 transition-opacity backdrop-blur-sm"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-center xl:items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
          <div ref="modal" id="modal" :class="`bg-${background} py-[30px] px-[50px]`">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { disableScroll, enableScroll } from "@/utils/v2/scroll-management";
import { onClickOutside } from '@vueuse/core'
export default {
  props:{
    background:{
      type:String,
      default:'white'
    },
    disable_close:{
      type:Boolean,
      default:false
    }
  },
  mounted() {
    disableScroll()
    if(!this.disable_close)
    {
      onClickOutside(this.$refs.modal,this.closeModal)
    }
  },
  methods:{
    closeModal()
    {
      enableScroll()
      this.$emit('close')
    }
  },
  watch:{
    disable_close(newValue,oldValue)
    {
      if(newValue && !oldValue)
      {
        onClickOutside(this.$refs.modal,this.closeModal)
      }
    }
  }
}
</script>
