<template>
  <div class="register-professional" v-bind:class="{ 'bg-white': step > 1 }">
    <header class="header bg-white pt-6 pb-12">
      <div class="container container-xl">
        <div class="flex flex-wrap items-center justify-between md:px-4 px-6">
          <div class="w-full block md:hidden text-center mb-3">
            <!-- <a href="/login" class="w-full btn btn--mobile rounded-full uppercase text-lg px-5 py-3"
              ><span class="justify-between">Ingresar</span>
              <img src="../assets/img/Icon-ingresar-grey.svg" class="text-white w-8 ml-auto" />
            </a> -->
          </div>
          <figure class="w-full md:w-auto text-center md:text-left">
            <a href="/"><img src="../assets/img/hand-logo.svg" class="inline-block md:block"/></a>
          </figure>

          <nav class="w-auto hidden md:block">
            <ul class="flex flex-wrap items-center text-sm lg:text-base">
              <li class="px-6">
                <a href="#" class="text-black text-xl font-bold">¿Cómo funciona?</a>
              </li>
              <!-- <li>
                <a href="/login" class="btn btn--secondary rounded-full uppercase text-lg px-8 py-3"
                  >Ingresar
                  <img src="../assets/img/Icon-ingresar.svg" class="text-white w-8 ml-6" />
                </a>
              </li> -->
            </ul>
          </nav>
        </div>
      </div>
    </header>

    <main>
      <Messages />
      <section
        id="register_professional"
        class="flex py-4 md:my-10 bg-white"
        v-if="professionalInfo.userId"
        v-show="step > 1 && step < 5"
      >
        <div class="container flex flex-col container-xl md:flex-row md:items-center">
          <div class="w-full mb-16 md:mb-0">
            <div class="relative px-4 md:px-6">
              <ul class="progress-tracker progress-tracker--text progress-tracker--center">
                <li
                  class="progress-step"
                  v-bind:class="{
                    'is-active ': step === 2,
                    'is-complete': step > 2
                  }"
                >
                  <span class="progress-marker"></span>
                  <span class="text-base progress-text">
                    <h5 class="progress-title text-lg">Paso 1</h5>
                    Describite
                  </span>
                </li>

                <li
                  class="progress-step"
                  v-bind:class="{
                    'is-active ': step === 3,
                    'is-complete': step > 3
                  }"
                >
                  <span class="progress-marker"></span>
                  <span class="text-base progress-text">
                    <h5 class="progress-title text-lg">Paso 2</h5>
                    Seleccionar rubros
                  </span>
                </li>

                <li
                  class="progress-step"
                  v-bind:class="{
                    'is-active': step === 4 || step === 5
                  }"
                >
                  <span class="progress-marker"></span>
                  <span class="text-base progress-text">
                    <h5 class="progress-title text-lg">Paso 3</h5>
                    Seleccionar área de cobertura
                  </span>
                </li>
              </ul>
            </div>
            <div class="step2" v-show="step === 2">
              <h3 class="text-center text-brand-secondary mb-4 md:w-2/3 md:mx-auto">
                ¡Continuemos {{ professionalInfo.firstName }} {{ professionalInfo.lastName }}!
              </h3>
              <h5 class="mb-2 text-xl font-medium text-center md:w-2/3 md:mx-auto">
                Aquí puedes presentarte y describirte para que tus futuros clientes te conozcan
                mejor (este texto va a aparecer en tu perfil profesional)
              </h5>
              <form
                class="relative p-0 px-6 my-6 bg-white border border-none shadow-none md:shadow-md md:border-solid md:border-gray-400 md:rounded md:p-8"
              >
                <div class="flex flex-wrap my-4">
                  <div class="w-full md:w-1/3 md:pr-6">
                    <div
                      class="border border-solid border-gray-400 rounded md:border-none md:shadow-none shadow-md p-6 md:p-0 mb-6 md:mb-0"
                    >
                      <h5 class="mb-3 text-lg font-medium text-center md:text-left">
                        Algunos consejos;
                      </h5>
                      <ol class="advice-list">
                        <li>
                          Preséntate brevemente y describe tus cualidades más importantes.
                        </li>
                        <li>
                          Escribe si tienes certificación profesional y de donde es.
                        </li>
                        <li>
                          Haz un resumen general de los servicios que ofreces y las fortalezas de
                          contratar tu trabajo con respecto a los competidores (en tu perfil el
                          cliente podrá ver los detalles de servicios ofrecidos que marques en el
                          siguiente paso).
                        </li>
                        <li>
                          Es importante mencionar sobre LA GARANTÍA que ofreces por tu trabajo.
                          Puede ser determinante para que te puedan elegir.
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div
                    class="w-full md:w-2/3 px-5"
                    v-bind:class="{
                      OnErrorScrollHere: submited && !coverLetter
                    }"
                  >
                    <h5 class="mb-3 text-lg font-medium text-center md:text-left">
                      Describe aquí tu oficio y porque los clientes deben elegirte;
                    </h5>

                    <textarea
                      class="block w-full mx-auto form-control desc-text text-black"
                      v-bind:class="{
                        'bg-red-100 border-red-700': submited && !coverLetter
                      }"
                      placeholder="Soy electricista profesional matriculado por la ANDE, tengo 10 años de experiencia.

Soy honesto y responsable. Ofrezco servicio de electricidad a domicilio, estamos para brindar soluciones a los problemas presentados en su hogar para su seguridad y la de toda su familia.

Realizo un trabajo serio, de calidad y a un precio justo, por lo cual siempre los clientes nos vuelven a elegir.

Ofrezco garantía de 3 meses por los trabajos realizados para su tranquilidad."
                      v-model="coverLetter"
                    ></textarea>

                    <span class="error" v-if="submited && !coverLetter"
                      >Por favor Descripción del servicio</span
                    >
                    <button type="button" class="w-full btn btn--md btn-character">
                      {{ countDescriptionChar }} caracteres (recomendamos minimamente 200
                      caracteres)
                    </button>
                  </div>
                </div>
              </form>
              <div class="flex flex-wrap block md:hidden px-5 md:px-0">
                <div class="w-full">
                  <h5 class="mb-2 text-base font-medium text-center">
                    Por favor vuelve a leer tu descripción para asegurarte de que este lo mejor
                    redactada posible, después de darle finalizar recién la podras editar en tu
                    perfil.
                  </h5>
                </div>
              </div>
              <div class="flex flex-wrap justify-between pt-4 nav-step px-5 md:px-0">
                <div class="w-1/2 md:w-1/3"></div>
                <div class="w-full hidden md:block md:w-1/3">
                  <h5 class="mb-2 text-base font-medium text-center">
                    Por favor vuelve a leer tu descripción para asegurarte de que este lo mejor
                    redactada posible, después de darle finalizar recién la podras editar en tu
                    perfil.
                  </h5>
                </div>
                <div class="w-1/2 md:w-1/3">
                  <div class="text-right">
                    <a
                      href="javascript:void(0)"
                      class="btn btn--primary py-4"
                      @click.prevent="next()"
                    >
                      <span class="justify-between"> Siguiente</span>
                      <svg
                        class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                      >
                        <use
                          href="../assets/img/icons.svg#icon-tail-right"
                          xlink:href="../assets/img/icons.svg#icon-tail-right"
                        ></use>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="step3" v-show="step === 3">
              <h3 class="text-center text-brand-secondary mb-4 md:w-2/3 md:mx-auto">
                ¿Que servicios ofreces?
              </h3>
              <h5 class="mb-2 text-xl font-medium text-center md:w-2/3 md:mx-auto">
                Lee atentamente y marca los servicios que ofreces a los clientes por los cuales te
                pueden contratar o pedir presupuestos.
              </h5>

              <div class="px-4 md:px-0">
                <div>
                  <div v-if="selectedProfessions && selectedProfessions.length > 0">
                    <div
                      class="relative my-6 bg-white border shadow-lg border-solidborder-gray-400 rounded p-6"
                      v-for="(profession, index) in selectedProfessions"
                      :key="index"
                      :id="'profession_' + profession.id"
                      v-bind:class="{
                        OnErrorScrollHere: submited && !checkProfessionServiceById(profession.id)
                      }"
                    >
                      <span
                        v-if="selectedProfessions.length > 1"
                        @click="removeProfession(index)"
                        class="absolute top-0 right-0 flex items-center justify-center w-8 h-8 p-2 text-lg text-white btn btn--secondary remove-btn"
                        >X</span
                      >
                      <div class="mb-4">
                        <article>
                          <header class="w-full items-center py-3 mb-5">
                            <h5 class="text-center" style="font-size: 1.375rem;">
                              {{ profession.description }}
                            </h5>
                            <div class="flex flex-wrap items-center justify-center">
                              <div class="w-full sm:w-1/2 md:w-1/3">
                                <div class="flex my-4 justify-center">
                                  <div
                                    class="p-2 w-16 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                                  >
                                    <img src="../assets/img/icon-Idea.svg" class="inline-block" />
                                  </div>
                                  <div class="w-auto py-2 px-6 bg-info-content-yellow text-black">
                                    Selecciona los servicios que ofreces.
                                  </div>
                                </div>
                                <div
                                  class="text-center p-2"
                                  v-bind:class="{
                                    'bg-red-100 border-red-700':
                                      submited && !checkProfessionServiceById(profession.id)
                                  }"
                                >
                                  <span
                                    class="error"
                                    v-if="submited && !checkProfessionServiceById(profession.id)"
                                    >Debe seleccionar al menos un servicio</span
                                  >
                                </div>
                              </div>
                            </div>
                          </header>

                          <ul
                            class="mb-4 grid grid-columns-1 md:grid-gap-4 md:grid-columns-2 lg:grid-gap-8 lg:grid-columns-3"
                            v-if="
                              servicesByType(profession.type) &&
                                servicesByType(profession.type).length > 0
                            "
                          >
                            <li
                              v-for="(service, serviceIndex) in servicesByType(profession.type)"
                              :key="serviceIndex"
                            >
                              <label class="flex items-center">
                                <input
                                  type="checkbox"
                                  v-model="serviceSelected"
                                  class="form-checkbox"
                                  :value="service.id"
                                  @change="
                                    selectProfessionService($event, service.id, profession.id)
                                  "
                                />
                                <span class="ml-4 text-black">
                                  {{ service.description }}
                                </span>
                              </label>
                            </li>
                          </ul>
                        </article>
                        <div
                          class="mb-4 items-center justify-center bg-light-grey-color rounded p-5"
                        >
                          <div>
                            <p class="text-black text-center font-medium">
                              Indica los años de experiencia que tienes en el rubro
                            </p>
                          </div>
                          <div class="flex w-full items-center justify-center">
                            <div class="w-full md:w-1/3">
                              <select
                                class="block w-full mt-1 rounded form-select"
                                v-model="selectedProfessions[index].experience"
                              >
                                <option value="null">Años de experiencia</option>
                                <option value="1">1 año</option>
                                <option value="2">2 años</option>
                                <option value="3">3 años</option>
                                <option value="4">4 años</option>
                                <option value="5">5 años</option>
                                <option value="6">6 años</option>
                                <option value="7">7 años</option>
                                <option value="8">8 años</option>
                                <option value="9">9 años</option>
                                <option value="10">10 años</option>
                                <option value="11">11 a 20 años</option>
                                <option value="12">21 a 30 años</option>
                                <option value="13"> más de 30 años</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 md:mt-6 mb-4 items-center justify-center">
                        <div>
                          <p class="text-black text-center font-medium md:px-6 text-lg">
                            Ofreces servicios no listados arriba, agregalos aquí tildando la casilla
                            y escribiendo el nombre del servicio.
                          </p>
                        </div>
                        <div class="w-full items-center justify-center">
                          <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-8">
                            <div>
                              <label>
                                <input
                                  class="block w-full mt-1 form-input"
                                  type="text"
                                  placeholder="Nombre del servicio"
                                  v-model="selectedProfessions[index].extraService1"
                                />
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  class="block w-full mt-1 form-input"
                                  type="text"
                                  placeholder="Nombre del servicio  "
                                  v-model="selectedProfessions[index].extraService2"
                                />
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  class="block w-full mt-1 form-input"
                                  type="text"
                                  placeholder="Nombre del servicio"
                                  v-model="selectedProfessions[index].extraService3"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <!-- image component -->
                        <div class=" flex my-4">
                          <div
                            class="p-2  w-16 h-auto flex items-center justify-center   bg-info-icon-yellow text-center"
                          >
                            <img src="../assets/img/Icon-Celular.png" class="inline-block" />
                          </div>
                          <div class="w-auto p-2 bg-info-content-yellow text-black ">
                            Es importante que muestres tus trabajos a tus clientes, una buena
                            fotografía puede ser fundamental al momento de contratarte. Puedes subir
                            hasta 5 imágenes
                          </div>
                        </div>
                        <label>
                          <span>Foto(s) de trabajos</span>
                          <div>
                            <input
                              class="hidden w-full mt-1 mb-2 form-input"
                              type="file"
                              name="images[]"
                              @change="imagesAdd($event, index)"
                              placeholder="Nombre"
                              :ref="'file_' + profession.id"
                              multiple
                            />
                          </div>
                          <div
                            v-bind:class="{
                              OnErrorScrollHere:
                                (submited || requiredWarning.images) &&
                                selectedProfessions[index].images &&
                                selectedProfessions[index].images.length > 5
                            }"
                          >
                            <button
                              type="button"
                              @click="
                                selectFile(profession.id);
                                requiredWarning.images = true;
                              "
                              class="btn btn--primary"
                            >
                              Elegir archivos
                            </button>
                          </div>
                          <!-- new image end -->
                          <span
                            class="error"
                            v-if="
                              (submited || requiredWarning.images) &&
                                selectedProfessions[index].images &&
                                selectedProfessions[index].images.length > 5
                            "
                            >Solo se pueden subir hasta 5 imágenes</span
                          >
                          <div v-if="imageSizeError">
                            <span class="error" v-html="imageSizeError"></span>
                          </div>
                        </label>
                        <div
                          class="grid mb-4 grid-columns-6 md:grid-columns-6 grid-gap-2"
                          v-if="
                            selectedProfessions[index].image &&
                              selectedProfessions[index].image.length > 0
                          "
                        >
                          <figure
                            class="relative  border border-gray-200 rounded shadow"
                            v-for="(img, key) in selectedProfessions[index].image"
                            :key="key"
                          >
                            <img :src="img" class="w-full" />
                            <span
                              v-show="image"
                              @click="removeImage(index, key, profession.id)"
                              class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                              >x</span
                            >
                          </figure>
                        </div>

                        <!-- image component end -->
                      </div>
                    </div>
                  </div>
                  <div
                    class="relative my-6 bg-white border shadow-lg border-solidborder-gray-400 rounded p-6"
                  >
                    <div class="flex mb-4 items-center justify-center">
                      <div class="w-full md:w-2/3">
                        <p class="text-black text-center font-medium md:px-6 text-lg">
                          ¿Tienes otro oficio en el cuál te desempeñas que quieres ofrecer? Suma
                          otro rubro/categoría a tu perfil.
                        </p>
                        <label>
                          <span>Oficio(s)</span>
                          <select
                            class="block w-full mt-1 rounded form-select"
                            v-model="addProfession"
                            @change="addNewProfession"
                          >
                            <option value="null"> Elige uno o varios oficios</option>
                            <option
                              v-for="professionItem in professions"
                              :key="professionItem.id"
                              :value="professionItem"
                              >{{ professionItem.description }}</option
                            >
                          </select>
                          <span class="error" v-if="showProfessionLimitError"
                            >You Can select Maximum 5 Profession.</span
                          >
                        </label>
                      </div>
                    </div>

                    <!-- button-->
                    <div class="text-right">
                      <button class="btn btn--secondary py-4">
                        <span class="justify-between">Agregar más oficios</span>
                      </button>
                    </div>

                    <!-- button end-->
                  </div>
                </div>
                <div class="flex flex-wrap block md:hidden px-5 md:px-0">
                  <div class="w-full">
                    <h5 class="mb-2 text-base font-medium text-center">
                      Por favor vuelve a leer tu descripción para asegurarte de que este lo mejor
                      redactada posible, después de darle finalizar recién la podras editar en tu
                      perfil.
                    </h5>
                  </div>
                </div>
                <div class="flex flex-wrap justify-between pt-4 nav-step px-5 md:px-0">
                  <div class="w-1/2 md:w-1/3">
                    <a
                      href="javascript:void(0)"
                      class="btn btn--primary py-4"
                      @click.prevent="prev()"
                    >
                      <svg
                        class="w-5 text-white icon icon--outline icon--md icon--align-left mr-auto"
                      >
                        <use
                          href="../assets/img/icons.svg#icon-tail-left"
                          xlink:href="../assets/img/icons.svg#icon-tail-left"
                        />
                      </svg>
                      <span class="justify-between"> Anterior</span>
                    </a>
                  </div>
                  <div class="w-full hidden md:block md:w-1/3">
                    <h5 class="mb-2 text-base font-medium text-center">
                      Por favor vuelve a leer tu descripción para asegurarte de que este lo mejor
                      redactada posible, después de darle finalizar recién la podras editar en tu
                      perfil.
                    </h5>
                  </div>
                  <div class="w-1/2 md:w-1/3">
                    <div class="text-right">
                      <a
                        href="javascript:void(0)"
                        class="btn btn--primary py-4"
                        @click.prevent="next()"
                      >
                        <span class="justify-between"> Siguiente</span>
                        <svg
                          class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                        >
                          <use
                            href="../assets/img/icons.svg#icon-tail-right"
                            xlink:href="../assets/img/icons.svg#icon-tail-right"
                          ></use>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step4" v-show="step === 4">
              <h3 class="text-center text-brand-secondary mb-4 md:w-2/3 md:mx-auto">
                Área de cobertura
              </h3>
              <h5 class="mb-2 text-xl font-medium text-center md:w-2/3 md:mx-auto">
                Es importante marcar la ubicación de tu domicilio/negocio para que los clientes que
                busquen profesionales cerca tuyo puedan encontrarte al activar la geolocalización
              </h5>
              <div class="grid grid-cols-3 gap-4 md:gap-8 lg:gap-32 px-5 md:px-0 my-8">
                <div class="col-span-3 md:col-span-1">
                  <button
                    class="w-full btn btn--md btn--secondary"
                    :disabled="gettingLocation"
                    @click.prevent="getGeoLocation()"
                  >
                    Ubicame en el mapa
                  </button>
                </div>
                <div class="col-span-3 md:col-span-2">
                  <div class="flex justify-center">
                    <div class="w-16 h-auto flex items-center justify-center text-center">
                      <img src="../assets/img/icon-atencion.svg" class="inline-block" />
                    </div>
                    <div class="w-auto px-2 clr-orange">
                      Si el sistema automático no te ubica correctamente en el mapa, por favor
                      reubica el pin azúl manualmente al lugar correcto.
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-5 md:px-0">
                <section class="relative border border-gray-200 rounded">
                  <div style="width: 100%; height: 500px;">
                    <l-map :zoom="zoom" :center="markerLatLng" ref="mymap">
                      <l-tile-layer :url="url"></l-tile-layer>
                      <l-circle :lat-lng="circle.center" :radius="circle.radius" />
                      <l-marker
                        :lat-lng="markerLatLng"
                        :draggable="true"
                        @update:lat-lng="locationUpdate"
                        ref="marker"
                      >
                        <l-popup>Mové este punto a tu ubicación</l-popup></l-marker
                      >
                    </l-map>
                  </div>
                </section>
              </div>
              <div class="flex w-full items-center justify-center my-4 px-5 md:px-0">
                <div class="w-2/3 md:w-1/3">
                  <p class="text-black text-center font-medium">
                    Hasta a que distancia podrías tomar trabajos?
                  </p>

                  <dropdownDistance
                    v-model="distance"
                    :on-selected-event="setMapCircle"
                  ></dropdownDistance>
                </div>
              </div>
              <div class=" flex my-4">
                <div class="w-19 h-auto flex ">
                  <input
                    type="checkbox"
                    class="form-checkbox"
                    v-model="acceptPolicy"
                    v-bind:class="{
                      'bg-red-100 border-red-700': submited && acceptPolicy != true
                    }"
                  />
                </div>
                <div class="w-auto h-auto  ">
                  <div class="mb-4">
                    <span class="font-bold text-md ml-2 text-black"
                      >Acepto los términos del servicio</span
                    >
                  </div>
                  <div class="mb-4">
                    <span class="font-normal font-body "
                      >Estoy de acuerdo con los
                      <router-link
                        :to="{ name: 'TermsCondition' }"
                        class="underline"
                        target="_blank"
                        >términos y condiciones</router-link
                      >
                      de Hand y al hacer click en el cuadro y continuar. He leído y entiendo el
                      <router-link
                        :to="{ name: 'ProfessionalContract' }"
                        class="underline"
                        target="_blank"
                        >acuerdo con prestadores</router-link
                      >
                      como parte del contrato y los términos de uso.
                    </span>
                  </div>
                </div>
              </div>

              <label class="flex">
                <span class="error" v-if="submited && acceptPolicy != true"
                  >Debe aceptar las politicas de privacidad y condiciones para registrarse</span
                >
              </label>
              <div class="flex flex-wrap block md:hidden px-5 md:px-0">
                <div class="w-full">
                  <h5 class="mb-2 text-base font-medium text-center">
                    Por favor vuelve a leer tu descripción para asegurarte de que este lo mejor
                    redactada posible, después de darle finalizar recién la podras editar en tu
                    perfil.
                  </h5>
                </div>
              </div>
              <div class="flex flex-wrap justify-between pt-4 nav-step px-5 md:px-0">
                <div class="w-1/2 md:w-1/3">
                  <a
                    href="javascript:void(0)"
                    class="btn btn--primary py-4"
                    @click.prevent="prev()"
                  >
                    <svg
                      class="w-5 text-white icon icon--outline icon--md icon--align-left mr-auto"
                    >
                      <use
                        href="../assets/img/icons.svg#icon-tail-left"
                        xlink:href="../assets/img/icons.svg#icon-tail-left"
                      />
                    </svg>
                    <span class="justify-between"> Anterior</span>
                  </a>
                </div>
                <div class="w-full hidden md:block md:w-1/3">
                  <h5 class="mb-2 text-base font-medium text-center">
                    Por favor vuelve a leer tu descripción para asegurarte de que este lo mejor
                    redactada posible, después de darle finalizar recién la podras editar en tu
                    perfil.
                  </h5>
                </div>
                <div class="w-1/2 md:w-1/3">
                  <div class="text-right">
                    <a
                      href="javascript:void(0)"
                      class="btn btn--primary py-4 bg-green-btn"
                      @click.prevent="updateProfessional()"
                    >
                      <span class="justify-between"> Finalizar</span>
                      <img
                        src="../assets/img/Icon-ok.svg"
                        class="w-8 text-white icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="flex py-4 md:my-10" v-show="step === 5">
        <div class="container flex flex-col container-xl md:flex-row md:items-center">
          <div class="w-full mx-auto mb-16 md:mb-0 lg:w-8/12">
            <div class="flex flex-col justify-center p-5 bg-white md:p-16">
              <div class="w-full text-center">
                <img src="../assets/img/Emoji.svg" class="inline-block mb-4" />
              </div>
              <h3 class="text-center text-brand-secondary mb-4">
                ¡Finalizamos!
              </h3>
              <p class="mb-2 text-center text-xl font-normal md:w-10/12 md:mx-auto">
                Estas listo para empezar a recibir solicitudes de servicios y/o pedidos de
                presupuestos. Ingresá a tu perfil para completar los precios de tus servicios si lo
                deseas, esto facilitará a los clientes que quieren elegir tus servicios. Muy pronto
                te avisaremos cuando los clientes ya te podrán contactar!
              </p>
              <p class="mb-6 text-center text-xl font-normal md:w-10/12 md:mx-auto">
                Será redirigido a la pagina de inicio en {{ formattedTimeLeft }} segundos
              </p>
              <router-link to="/" class="mx-auto btn btn--primary bg-green-btn px-8 py-4">
                <img
                  src="../assets/img/Icon-home.svg"
                  class="w-8 text-white icon icon--outline icon--stroke-3 icon--md icon--align-left mr-auto"
                />
                <span class="justify-between"> Ir al inicio de Hand</span>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import scrollToSection from "@/utils/methods/scrollToSection";
import GetProfessions from "@/utils/methods/GetProfessions";
import getServiceByType from "@/utils/methods/getServiceByType";
import servicesByType from "@/utils/methods/servicesByType";
import removeProfession from "@/utils/methods/removeProfession";
import addNewProfession from "@/utils/methods/addNewProfession";
import imagesAdd from "@/utils/methods/imagesAdd";
import createImage from "@/utils/methods/createImage";
import removeImage from "@/utils/methods/removeImage";
import selectFile from "@/utils/methods/selectFile";
import checkProfessionService from "@/utils/methods/checkProfessionService";
import checkProfessionServiceById from "@/utils/methods/checkProfessionServiceById";
import selectProfessionService from "@/utils/methods/selectProfessionService";
import activeProfessions from "@/utils/computed/activeProfessions";

import { latLng } from "leaflet";
import { LMap, LTileLayer, LCircle, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import dropdownExperience from "@/components/dropdownExperience.vue";
import dropdownDistance from "@/components/dropdownDistance.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
  data() {
    return {
      submited: false,
      processingUpdateProfessional: false,
      token: "",
      professions: [],
      profession: [],
      otherProfession: "",
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      isverify: false,
      recaptchaToken: "",
      step: 2,
      coverLetter: "",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 10,
      geolocation: {
        latitude: -25.3211306,
        longitude: -57.5928935
      },
      markerLatLng: [-25.3211306, -57.5928935],
      circle: {
        center: latLng(-25.3211306, -57.5928935),
        radius: 4500
      },
      gettingLocation: false,
      professionalInfo: [],
      services: [],
      selectedProfessions: [],
      addProfession: null,
      distance: "10",
      selectedServices: [],
      showCustomProfessionField: false,
      experience: [],
      updateProfessionalData: {
        token: null,
        requestData: {
          coverLetter: "",
          latitude: -25.3211306,
          longitude: -57.5928935,
          distance: 10,
          professions: [],
          services: []
        }
      },
      showProfessionLimitError: false,
      images: [],
      image: [],
      imageSizeError: "",
      acceptPolicy: false,
      isShowTerms: false,
      requiredWarning: {
        images: false
      },
      timelimit: 20,
      timePassed: 0,
      timerInterval: null,

      stepName: "",
      tempData: {
        coverLetter: "",
        latitude: -25.3211306,
        longitude: -57.5928935,
        distance: "10",
        selectedServices: [],
        selectedProfessions: []
      },
      serviceSelected: []
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.token = this.$route.params.tid;
    this.goToStep();
    this.getProfessionalInfo();
    this.GetProfessions();
    var self = this;
    setTimeout(function() {
      self.getSavedData();
    }, 1000);
  },
  watch: {
    "$route.params": "goToStep",
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    }
  },
  computed: {
    ...mapGetters(["error", "StepTempData"]),
    activeProfessions,
    countDescriptionChar() {
      if (this.coverLetter.length) {
        return this.coverLetter.length;
      } else {
        return 0;
      }
    },
    timeLeft() {
      return this.timelimit - this.timePassed;
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${seconds}`;
    }
  },
  methods: {
    scrollToSection,
    GetProfessions,
    getServiceByType,
    servicesByType,
    removeProfession,
    addNewProfession,
    createImage,
    removeImage,
    selectFile,
    checkProfessionService,
    checkProfessionServiceById,
    imagesAdd,
    selectProfessionService,

    updateProfessional() {
      this.submited = true;
      this.processingUpdateProfessional = true;
      if (this.validateForm(this.step)) {
        var images = require.context("../assets/../assets/../assets/img/", false, /\.gif$/);
        Swal.fire({
          imageUrl: images("./loading.gif"),
          imageAlt: "Loader",
          showConfirmButton: false,
          background: "transparent",
          backdrop: `rgba(0,0,0,0.6)`,
          allowOutsideClick: false
        });
        this.updateProfessionalData.token = this.token;
        var formdata = new FormData();
        formdata.append("coverLetter", this.coverLetter);
        formdata.append("latitude", this.geolocation.latitude);
        formdata.append("longitude", this.geolocation.longitude);
        formdata.append("distance", this.distance);
        this.updateProfessionalData.requestData.professions = [];
        this.updateProfessionalData.requestData.services = [];
        if (this.selectedProfessions && this.selectedProfessions.length > 0) {
          for (var i = 0; i < this.selectedProfessions.length; i++) {
            if (
              this.selectedProfessions[i].images &&
              this.selectedProfessions[i].images.length > 0
            ) {
              for (var p = 0; p < this.selectedProfessions[i].images.length; p++) {
                let file = this.selectedProfessions[i].images[p];
                formdata.append("files", file);
              }
            }
            this.updateProfessionalData.requestData.professions.push({
              profession_id: this.selectedProfessions[i].id,
              experience: this.selectedProfessions[i].experience
            });

            if (this.selectedServices.length > 0) {
              let isexistService = false;
              for (var j = 0; j < this.selectedServices.length; j++) {
                if (this.selectedServices[j].profession_id == this.selectedProfessions[i].id) {
                  if (!isexistService) {
                    this.updateProfessionalData.requestData.services.push({
                      service_id: this.selectedServices[j].service_id,
                      extraService1: this.selectedProfessions[i].extraService1,
                      extraService2: this.selectedProfessions[i].extraService2,
                      extraService3: this.selectedProfessions[i].extraService3
                    });
                  } else {
                    this.updateProfessionalData.requestData.services.push({
                      service_id: this.selectedServices[j].service_id,
                      extraService1: "",
                      extraService2: "",
                      extraService3: ""
                    });
                  }
                  isexistService = true;
                }
              }
              /* if (!isexistService) {
                this.updateProfessionalData.requestData.services.push({
                  service_id: "",
                  extraService1: this.selectedProfessions[i].extraService1,
                  extraService2: this.selectedProfessions[i].extraService2,
                  extraService3: this.selectedProfessions[i].extraService3
                });
              } */
            } else {
              this.updateProfessionalData.requestData.services.push({
                service_id: "",
                extraService1: this.selectedProfessions[i].extraService1,
                extraService2: this.selectedProfessions[i].extraService2,
                extraService3: this.selectedProfessions[i].extraService3
              });
            }
          }
        }
        formdata.append(
          "professions",
          JSON.stringify(this.updateProfessionalData.requestData.professions)
        );
        formdata.append(
          "services",
          JSON.stringify(this.updateProfessionalData.requestData.services)
        );
        this.updateProfessionalData.formdata = formdata;

        this.$store
          .dispatch("updateProfessional", this.updateProfessionalData)
          .then(() => {
            this.$store.dispatch("removeStepTempData");
            this.processingUpdateProfessional = false;
            Swal.close();
            this.redirectStep(this.step + 1);
            setTimeout(function() {
              window.scrollTo({ top: 100, behavior: "smooth" });
            }, 1000);
          })
          .catch(() => {
            this.processingUpdateProfessional = false;
            Swal.close();
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
          });
      } else {
        this.processingUpdateProfessional = false;
        setTimeout(function() {
          var d = document.getElementsByClassName("OnErrorScrollHere")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.$router.push("/login");
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    validateForm(step) {
      if (step == 2) {
        return this.coverLetter != "";
      } else if (step == 3) {
        return this.checkProfessionService();
      } else if (step == 4) {
        return this.acceptPolicy == true;
      }
    },
    prev() {
      this.redirectStep(this.step - 1);
    },
    next() {
      this.submited = true;
      if (this.validateForm(this.step)) {
        this.submited = false;
        this.redirectStep(this.step + 1);
      } else {
        setTimeout(function() {
          var d = document.getElementsByClassName("OnErrorScrollHere")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    mapLoad() {
      setTimeout(() => {
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
    },
    getGeoLocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = true;
        this.$refs.marker.mapObject.openPopup();
        return;
      }
      this.gettingLocation = true;
      /* eslint-disable */
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.gettingLocation = false;
          this.markerLatLng = [pos.coords.latitude, pos.coords.longitude];
          this.circle.center = latLng(pos.coords.latitude, pos.coords.longitude);
          this.errorStr = false;
        },
        err => {
          this.gettingLocation = false;
          this.errorStr = true;
          this.$refs.marker.mapObject.openPopup();
        }
      );
      /* eslint-disable */
    },
    locationUpdate(location) {
      this.geolocation = { latitude: location.lat, longitude: location.lng };
      this.circle.center = latLng(location.lat, location.lng);
    },
    getProfessionalInfo() {
      this.processingGetProfessionalData = true;
      if (this.token) {
        this.$store
          .dispatch("getProfessionalInfo", {
            tid: this.token
          })
          .then(response => {
            this.professionalInfo = response;
            this.selectedProfessions = this.professionalInfo.Profession;
            for (var i = 0; i < this.selectedProfessions.length; i++) {
              this.selectedProfessions[i].experience = 1;
              this.selectedProfessions[i].extraService1 = "";
              this.selectedProfessions[i].extraService2 = "";
              this.selectedProfessions[i].extraService3 = "";
              this.$set(this.selectedProfessions[i], "images", []);
              this.$set(this.selectedProfessions[i], "image", []);

              this.getServiceByType(this.selectedProfessions[i].type);
            }
          })
          .catch(() => {
            this.processingGetProfessionalData = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingGetProfessionalData = false;
        this.$router.push("/");
      }
    },
    setMapCircle() {
      if (this.distance > 20) {
        this.zoom = 9;
      } else {
        this.zoom = 10;
      }
      this.circle.radius = 1000 * this.distance;
    },
    AddCustomProfessionField() {
      this.showCustomProfessionField = true;
    },

    toggleTerms() {
      this.isShowTerms = !this.isShowTerms;
    },
    checkProfessionService() {
      if (this.selectedProfessions.length > 0) {
        for (var x = 0; x < this.selectedProfessions.length; x++) {
          if (this.selectedServices.length > 0) {
            let isexistService = false;
            for (var y = 0; y < this.selectedServices.length; y++) {
              if (this.selectedServices[y].profession_id == this.selectedProfessions[x].id) {
                isexistService = true;
              }
            }

            if (!isexistService || this.selectedProfessions[x].images.length > 5) {
              return false;
            }
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    checkProfessionServiceById(professionId) {
      if (this.selectedServices.length > 0) {
        let isexistService = false;
        for (var z = 0; z < this.selectedServices.length; z++) {
          if (this.selectedServices[z].profession_id == professionId) {
            isexistService = true;
          }
        }
        if (!isexistService) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    },

    goToStep() {
      if (this.$route.params.step) {
        this.stepName = this.$route.params.step;
        if (this.stepName == "rubro") {
          this.step = 3;
        } else if (this.stepName == "cobertura") {
          this.mapLoad();
          this.step = 4;
        } else if (this.stepName == "success") {
          this.step = 5;
          this.startTimer();
        }
      } else {
        this.step = 2;
      }
      this.scrollToSection("register_professional");
    },
    redirectStep(step) {
      let stepName = "";
      if (step == 2) {
        stepName = "";
      } else if (step == 3) {
        stepName = "rubro";
      } else if (step == 4) {
        stepName = "cobertura";
      } else if (step == 5) {
        stepName = "success";
      }
      if (step != 5) {
        this.saveTempData();
      }

      if (stepName == "") {
        this.$router.push({
          name: "ProfessionalRegisterComplete",
          params: { tid: this.token }
        });
      } else {
        this.$router.push({
          name: "ProfessionalRegisterCompleteStep",
          params: { tid: this.token, step: stepName }
        });
      }
    },
    saveTempData() {
      this.tempData.coverLetter = this.coverLetter;
      this.tempData.latitude = this.geolocation.latitude;
      this.tempData.longitude = this.geolocation.longitude;
      this.tempData.distance = this.distance;
      this.tempData.selectedServices = JSON.stringify(this.selectedServices);
      this.tempData.selectedProfessions = JSON.stringify(this.selectedProfessions);
      this.$store.dispatch("saveStepTempData", this.tempData);
    },
    getSavedData() {
      if (this.StepTempData && this.StepTempData != null) {
        this.coverLetter = this.StepTempData.coverLetter;
        this.geolocation.latitude = this.StepTempData.latitude;
        this.geolocation.longitude = this.StepTempData.longitude;
        this.markerLatLng = [this.geolocation.latitude, this.geolocation.longitude];
        this.circle.center = latLng(this.geolocation.latitude, this.geolocation.longitude);
        this.distance = this.StepTempData.distance;
        this.selectedServices = JSON.parse(this.StepTempData.selectedServices);
        this.selectedServices.forEach(e => {
          this.serviceSelected.push(e.service_id);
        });
        this.selectedProfessions = JSON.parse(this.StepTempData.selectedProfessions);
        this.services = [];
        this.selectedProfessions.forEach(e => {
          this.getServiceByType(e.type);
        });
        this.stepBasedOnSaveData();
      } else {
        if (this.step != 5 && this.step != 2) {
          this.redirectStep(2);
        }
      }
    },
    stepBasedOnSaveData() {
      if (this.step && this.step != 5) {
        for (var st = 2; st < this.step; st++) {
          if (!this.validateForm(st)) {
            this.redirectStep(st);
            break;
          }
        }
      }
    }
  },
  components: {
    Footer,
    Messages,
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    LPopup,
    dropdownExperience,
    dropdownDistance
  }
};
</script>

<style scoped>
@import "../assets/css/registrarme-profesional.css";

.error {
  color: #e54d42;
  border-left-color: #b82e24;
}

.is-active {
  transform: translateY(8px/ 4) rotate(225deg);
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}

.arrow-down {
  position: absolute;
  bottom: -13px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #375be5;
}
</style>
