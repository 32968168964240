<template>
  <div class="tooltip">
    <slot>
    </slot>
    <span class="tooltip-text">{{ message }}</span>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
  props: {
    message: String
  },
};
</script>
<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  
}
.tooltip .tooltip-text {
  visibility: hidden;
  line-height:15px;
  width: 300px;
  background-color: #666C7C;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -155px;
  font-family: 'Montserrat', sans-serif;
}
.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color:  #666C7C transparent transparent transparent;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
</style>
