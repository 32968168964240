<template>
  <Datepicker
    v-model="date"
    :range="false"
    lang="es"
    :circle="false"
    text-format="short"
    placeholder="Fecha"
    :date-format="{
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }"
  />
</template>

<script>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";

export default {
  props: {
    onUpdateDate: {
      type: Function,
      default: () => {},
    },
    initialDate: {
      type: Date,
      default:()=> new Date(),
    },
  },
  data() {
    return {
      date: new Date(),
    };
  },
  watch: {
    date(date) {
      this.$emit("updated", { date });
    },
  },
  components: {
    Datepicker: VueDatepickerUi,
  },
};
</script>
<style scoped>
* {
  text-transform: capitalize;
}
</style>
