<template>
  <div
    @click="onClick"
    :class="[
                `w-full h-[45px] flex items-center justify-center font-bold`,
                `bg-none text-${color} underline`,
                disabled ? `pointer-events-none opacity-50` : 'cursor-pointer',
              ]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props:{
    onClick:{
      type:Function,
      default: ()=>{}
    },
    disabled:{
      type:Boolean,
      default:false
    },
    color:{
      type:String,
      default:'primary'
    }
  }
}
</script>
