<template>
  <div class="control-panel professional-profile">
    <Header @goToServiceRequest="goToServiceRequestEvent($event)" />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <ProfessionalProfileSidebar />

          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between lg:flex-row lg:items-center mb-2">
              <h5 class="mb-3 lg:mt-0 lg:mb-2">
                <svg class="-mt-2 icon">
                  <use
                    href="../../assets/img/icons.svg#icon-todo"
                    xlink:href="../../assets/img/icons.svg#icon-todo"
                  />
                </svg>
                Pedido de Servicios
              </h5>
              <div class="flex justify-center">
                <button
                  class="rounded btn--primary lg:w-auto btn btn--md px-4"
                  @click="GetUserServiceRequest"
                >
                  <span class="justify-between text-base mr-5"> Actualizar</span
                  ><img
                    src="../../assets/img/icono-refrescar-blue.svg"
                    class="icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                  />
                </button>
              </div>
            </div>
            <div>
              <div v-if="serviceRequests.length > 0">
                <div
                  class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
                  v-for="(serviceRequest, serviceRequestIndex) in serviceRequests"
                  :key="'requestOverview_' + serviceRequestIndex"
                >
                  <header
                    :id="'service_' + serviceRequest.id"
                    class="items-center cursor-pointer justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white md:border-b border-gray-200"
                  >
                    <div class="flex flex-wrap text-center md:text-left">
                      <div class="w-auto flex text-base text-black font-semibold">
                        <div class="mb-2 md:mb-0">
                          Estado:
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 font-bold"
                            v-if="serviceRequest.status == 'cancelled'"
                            >Cancelado
                          </span>
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold success"
                            v-if="serviceRequest.status == 'complete'"
                            >Concretado
                          </span>
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 text-brand-primary font-bold"
                            v-if="serviceRequest.status == 'ongoing'"
                            >En curso
                          </span>
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold"
                            v-if="serviceRequest.status == 'pending'"
                            >Pendiente (en espera que aceptes el trabajo)</span
                          >
                        </div>
                        <div @click="() => showLocationDetails({ serviceRequest })" class="ml-6">
                          <map-pin-icon :color="'#000'" />
                        </div>
                      </div>
                      <div class="w-full md:w-auto text-center md:text-right md:ml-auto text-black">
                        <span>Orden: {{ serviceRequest.id }}</span>
                        <span class="ml-4" v-if="contentVisible === serviceRequest.id">
                          <img
                            src="../../assets/img/collapse-arrow.png"
                            alt=""
                            class="w-8 rounded-full inline-block bg-gray-600"
                        /></span>
                        <span
                          @click="
                            contentVisible === serviceRequest.id
                              ? (contentVisible = false)
                              : (contentVisible = serviceRequest.id)
                          "
                          class="ml-4"
                          v-if="contentVisible != serviceRequest.id"
                        >
                          <img
                            src="../../assets/img/expand-arrow.png"
                            alt=""
                            class="rounded-full bg-gray-600 w-8 inline-block"
                        /></span>
                      </div>
                      <div class="w-full text-center text-black service-step">
                        <ul
                          class="progress-tracker progress-tracker--text progress-tracker--center mb-0"
                        >
                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ': serviceRequest.status == 'pending',
                              'is-complete':
                                serviceRequest.status == 'ongoing' ||
                                serviceRequest.status == 'complete',
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">Paso 1</h5>
                              Pendiente
                            </span>
                          </li>

                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ': serviceRequest.status == 'ongoing',
                              'is-complete': serviceRequest.status == 'complete',
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">Paso 2</h5>
                              Finalizar trabajo
                            </span>
                          </li>

                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ': serviceRequest.status == 'complete',
                              'is-complete': serviceRequest.paymentType != null,
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">Paso 3</h5>
                              Concretado
                            </span>
                          </li>
                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ':
                                serviceRequest.paid == false &&
                                (serviceRequest.paymentType == 'CASH' ||
                                  serviceRequest.paymentType == 'TRANSFER'),
                              'is-complete': serviceRequest.paid == true,
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">Paso 4</h5>
                              Trabajo Cobrado
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </header>
                  <div v-if="contentVisible === serviceRequest.id">
                    <div v-if="serviceRequest.paid == false">
                      <!-- ongoing start -->
                      <div v-if="serviceRequest.status == 'ongoing'">
                        <!-- request Overview start -->
                        <div>
                          <div class="flex flex-wrap">
                            <div class="w-full px-2 md:w-2/5">
                              <div class="flex items-center">
                                <figure class="avatar w-16 h-16">
                                  <img
                                    :src="serviceRequest.User.pictureProfileUrl"
                                    :alt="
                                      serviceRequest.User.firstName +
                                      ' ' +
                                      serviceRequest.User.lastName
                                    "
                                  />
                                </figure>
                                <div class="ml-3">
                                  <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                    {{ serviceRequest.User.firstName }}
                                    {{ serviceRequest.User.lastName }}
                                  </h6>
                                </div>
                              </div>
                            </div>

                            <div class="w-full px-2 md:w-1/5 text-center hidden md:block">
                              <img
                                src="../../assets/img/icon-flecha-arrow.svg"
                                alt=""
                                class="inline-block"
                              />
                            </div>

                            <div class="w-full px-2 md:w-2/5 hidden md:block">
                              <div class="flex items-center">
                                <figure class="mr-3 avatar w-16 h-16 block md:hidden">
                                  <img
                                    :src="serviceRequest.Professional.pictureProfileUrl"
                                    :alt="
                                      serviceRequest.Professional.firstName +
                                      ' ' +
                                      serviceRequest.Professional.lastName
                                    "
                                  />
                                </figure>
                                <div class="ml-0 md:ml-auto">
                                  <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                    {{ serviceRequest.Professional.firstName }}
                                    {{ serviceRequest.Professional.lastName }}
                                  </h6>
                                </div>
                                <figure class="ml-3 avatar w-16 h-16 md:block hidden">
                                  <img
                                    :src="serviceRequest.Professional.pictureProfileUrl"
                                    :alt="
                                      serviceRequest.Professional.firstName +
                                      ' ' +
                                      serviceRequest.Professional.lastName
                                    "
                                  />
                                </figure>
                              </div>
                            </div>
                          </div>
                          <hr class="mt-3 border-gray-200 block md:hidden" />
                          <hr class="mt-3 border-gray-200 hidden md:block -mx-5" />
                          <!-- step1 -->
                          <div class="flex flex-col items-center lg:flex-row py-2">
                            <div class="w-full md:w-2/3 text-center md:text-left mb-2 md:mb-0">
                              <img
                                src="../../assets/img/map.png"
                                alt="map"
                                class="w-12 inline mr-2"
                              />
                              <button
                                class="rounded btn--primary lg:w-auto btn btn--md px-3 py-3"
                                @click="
                                  selectServiceAdress(serviceRequest);
                                  $refs.modalAdressInfo.openModal();
                                "
                              >
                                <svg class="icon text-white icon--align-left mr-auto">
                                  <use
                                    href="../../assets/img/icons.svg#icon-pin"
                                    xlink:href="../../assets/img/icons.svg#icon-pin"
                                  />
                                </svg>
                                <span class="justify-between text-base ml-4">
                                  Ver lugar y dirección</span
                                >
                              </button>
                            </div>
                            <div class="w-full md:w-1/3 text-center md:text-right">
                              <router-link
                                :to="{
                                  name: 'ProfessionalMessageResponder',
                                  params: {
                                    otype: 'servicerequest',
                                    oid: serviceRequest.id,
                                    ostatus: serviceRequest.status,
                                  },
                                }"
                                class="rounded btn--secondary lg:w-auto btn btn--md px-3 py-3"
                              >
                                <img
                                  src="../../assets/img/comment-alt-message.svg"
                                  class="icon icon--outline icon--stroke-3 icon--md icon--align-left mr-auto"
                                />
                                <span class="justify-between text-base ml-8"> Enviar mensaje</span>
                              </router-link>
                            </div>
                          </div>

                          <div class="flex flex-col justify-between lg:flex-row">
                            <section class="w-full">
                              <section class="mb-4 bg-white">
                                <hr class="border-gray-200 hidden md:block -mx-5" />
                                <div class="-mx-5">
                                  <h6
                                    class="my-4 text-black font-semibold text-center block md:hidden"
                                  >
                                    Servicio/s solicitado/s:
                                  </h6>
                                  <table class="w-full text-left border-collapse p-4 mt-0">
                                    <thead class="hidden md:table-header-group">
                                      <tr
                                        class="flex border-b border-gray-200 table-row text-black"
                                      >
                                        <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                                        <th class="px-4 py-3 text-right">Precio:</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        class="flex border-b border-gray-200 table-row"
                                        style="background-color: #f9fafc"
                                        v-for="(Service, index) in serviceRequest.Services"
                                        :key="'payment_step_1_' + index"
                                      >
                                        <td class="px-2 md:px-4 py-2">
                                          <div class="flex items-center">
                                            <div>
                                              <h6 class="my-0 text-black">
                                                {{ Service.quantity }}x &nbsp;
                                                {{ Service.description }}
                                              </h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="px-4 py-2 text-right">
                                          <span class="text-black text-xl">
                                            Gs. {{ Service.price | thousandSeparator }}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div
                                    class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4 border-b border-gray-500"
                                    style="background-color: #e4e9fb"
                                  >
                                    <div class="px-2 md:px-4 py-2">
                                      <p class="text-left mb-1 text-black font-semibold">
                                        Describa aquí el trabajo final realizado:
                                      </p>
                                      <div class="flex items-center">
                                        <div class="w-full">
                                          <label class="mb-0">
                                            <input
                                              class="block w-full form-input placeholder-gray-800"
                                              type="text"
                                              v-model="extraService.description"
                                              placeholder="Resuma aquí "
                                              v-bind:class="{
                                                'bg-red-100 border-red-700 OnErrorScrollHere':
                                                  submited && !extraService.description,
                                              }"
                                            />
                                            <span
                                              class="error"
                                              v-if="submited && !extraService.description"
                                            >
                                              Por favor ingrese una descripción</span
                                            >
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="px-2 md:px-4 py-2 text-right">
                                      <p class="text-left mb-1">
                                        por Cargar aquí costo final/adicionales:
                                      </p>
                                      <div class="flex items-stretch text-lg md:flex-row">
                                        <input
                                          class="block w-1/2 form-input h-auto placeholder-gray-800"
                                          type="text"
                                          v-model="extraService.price"
                                          @keyup="formatAmount(extraService.price)"
                                          placeholder="122.333.333 "
                                          v-bind:class="{
                                            'bg-red-100 border-red-700 OnErrorScrollHere':
                                              submited && !isNumber(extraService.price),
                                          }"
                                        />

                                        <button
                                          class="ml-2 justify-center font-semibold btn btn--primary w-1/2 h-auto icon-text-aligner"
                                          @click="addExtraService(serviceRequest.id)"
                                        >
                                          Agregar
                                        </button>
                                      </div>
                                      <p class="text-left">
                                        <span class="error" v-if="submited && !extraService.price"
                                          >Por favor ingrese una price</span
                                        >
                                        <span
                                          class="error"
                                          v-if="
                                            submited &&
                                            extraService.price &&
                                            !isNumber(extraService.price)
                                          "
                                          >Por favor Ingrese un valor numérico</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                  <table class="w-full text-left border-collapse p-4 mt-0">
                                    <tbody>
                                      <tr
                                        class="flex border-b border-gray-200 table-row"
                                        style="background-color: #e4e9fb"
                                        v-for="(Item, key) in serviceRequest.ExtraServices"
                                        :key="'additional_' + key"
                                      >
                                        <td class="px-2 md:px-4 py-2 w-1/2">
                                          <div class="flex items-center">
                                            <div>
                                              <h6 class="my-0 text-black">
                                                {{ Item.description }}
                                              </h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="px-4 py-2 text-right">
                                          <span class="text-black text-xl">
                                            Gs. {{ Item.price | thousandSeparator }}
                                          </span>

                                          <img
                                            @click="removeExtraService(serviceRequest.id, Item.id)"
                                            src="../../assets/img/icon-borrar.svg"
                                            class="inline-block ml-2 pointer"
                                            role="button"
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table class="w-full text-left border-collapse p-4 mt-0">
                                    <tbody>
                                      <tr
                                        class="flex border-b border-gray-200 table-row"
                                        style="background-color: #fff"
                                      >
                                        <td class="px-2 md:px-4 py-2 w-1/2">
                                          <div class="flex items-center">
                                            <div>
                                              <h6 class="my-0 text-black">Sub-total</h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="px-4 py-2 text-right">
                                          <span class="text-black text-xl">
                                            Gs.
                                            {{
                                              subTotalServiceCost(serviceRequest)
                                                | thousandSeparator
                                            }}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr
                                        class="flex border-b border-gray-200 table-row"
                                        style="background-color: #fff"
                                      >
                                        <td class="px-2 md:px-4 py-2">
                                          <div class="flex items-center">
                                            <div>
                                              <h6 class="my-0 text-black">+10% I.V.A.</h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="px-4 py-2 text-right">
                                          <span class="text-black text-xl">
                                            Gs.
                                            {{ totalTax(serviceRequest) | thousandSeparator }}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr
                                        class="flex border-b border-gray-200 table-row"
                                        style="background-color: #fff"
                                      >
                                        <td class="px-2 md:px-4 py-2">
                                          <div class="flex items-center">
                                            <div>
                                              <h6 class="my-0 text-black">
                                                Soporte - comisión HAND
                                              </h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td class="px-4 py-2 text-right">
                                          <span class="text-black text-xl">
                                            Gs.
                                            {{ totalComision(serviceRequest) | thousandSeparator }}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tfoot>
                                      <tr
                                        class="flex border-b border-gray-200 table-row"
                                        style="background-color: #e4e9fb"
                                      >
                                        <td class="px-2 md:px-4 py-2">
                                          <div class="flex items-center">
                                            <div>
                                              <h6 class="my-0 text-black">Total a pagar:</h6>
                                            </div>
                                          </div>
                                        </td>

                                        <td class="px-4 py-2 text-right">
                                          <span class="text-black text-xl">
                                            Gs.
                                            {{ totalPayment(serviceRequest) | thousandSeparator }}
                                          </span>
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </section>
                            </section>
                          </div>
                          <!-- step1 End -->
                        </div>
                        <!-- request overview end -->
                      </div>
                      <!-- ongoing end -->
                      <!-- other request start-->
                      <div v-else>
                        <div class="flex flex-col justify-between lg:flex-row">
                          <section class="w-full">
                            <section class="my-4 bg-white">
                              <div class="flex flex-wrap">
                                <div class="w-full px-2 md:w-2/5">
                                  <div class="flex items-center">
                                    <figure class="avatar w-16 h-16">
                                      <img
                                        :src="serviceRequest.User.pictureProfileUrl"
                                        :alt="
                                          serviceRequest.User.firstName +
                                          ' ' +
                                          serviceRequest.User.lastName
                                        "
                                      />
                                    </figure>
                                    <div class="ml-3">
                                      <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                        {{ serviceRequest.User.firstName }}
                                        {{ serviceRequest.User.lastName }}
                                      </h6>
                                    </div>
                                  </div>
                                </div>

                                <div class="w-full px-2 md:w-1/5 text-center hidden md:block">
                                  <img
                                    src="../../assets/img/icon-flecha-arrow.svg"
                                    alt=""
                                    class="inline-block"
                                  />
                                </div>

                                <div class="w-full px-2 md:w-2/5 hidden md:block">
                                  <div class="flex items-center">
                                    <figure class="mr-3 avatar w-16 h-16 block md:hidden">
                                      <img
                                        :src="serviceRequest.Professional.pictureProfileUrl"
                                        :alt="
                                          serviceRequest.Professional.firstName +
                                          ' ' +
                                          serviceRequest.Professional.lastName
                                        "
                                      />
                                    </figure>
                                    <div class="ml-0 md:ml-auto">
                                      <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                        {{ serviceRequest.Professional.firstName }}
                                        {{ serviceRequest.Professional.lastName }}
                                      </h6>
                                    </div>
                                    <figure class="ml-3 avatar w-16 h-16 md:block hidden">
                                      <img
                                        :src="serviceRequest.Professional.pictureProfileUrl"
                                        :alt="
                                          serviceRequest.Professional.firstName +
                                          ' ' +
                                          serviceRequest.Professional.lastName
                                        "
                                      />
                                    </figure>
                                  </div>
                                </div>
                              </div>
                              <hr class="mt-3 border-gray-200 block md:hidden" />
                              <hr class="mt-3 border-gray-200 hidden md:block -mx-5" />
                              <div class="-mx-5">
                                <h6
                                  class="my-4 text-black font-semibold text-center block md:hidden"
                                >
                                  Servicio/s solicitado/s:
                                </h6>
                                <table class="w-full text-left border-collapse p-4 mt-0">
                                  <thead class="hidden md:table-header-group">
                                    <tr class="flex border-b border-gray-200 table-row text-black">
                                      <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                                      <th class="px-4 py-3">Precio:</th>
                                      <th class="px-4 py-3 text-right">Cantidad:</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      class="flex border-b border-gray-200 table-row"
                                      v-for="(Service, ServiceIndex) in serviceRequest.Services"
                                      :key="'service_status' + ServiceIndex"
                                    >
                                      <td class="px-2 md:px-4 py-2">
                                        <div class="flex items-center">
                                          <div>
                                            <h6 class="my-0 text-gray-900">
                                              {{ Service.description }}
                                            </h6>
                                            <span class="text-black text-xl block md:hidden">
                                              Gs.
                                              {{ Service.price | thousandSeparator }}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="px-4 py-2 hidden md:table-cell">
                                        <span class="text-black text-xl">
                                          Gs.
                                          {{ Service.price | thousandSeparator }}
                                        </span>
                                      </td>
                                      <td class="px-2 md:px-4 py-2 text-right w-1/2 md:w-auto">
                                        <button
                                          class="justify-center btn icon-text-aligner bg-white hover:bg-white px-3 h-10"
                                          @click="decrement(serviceRequestIndex, ServiceIndex)"
                                          :disabled="Service.quantity == 1"
                                          v-if="serviceRequest.status == 'pending'"
                                        >
                                          <img
                                            src="../../assets/img/icon-minus.svg"
                                            class="inline-block py-2"
                                          />
                                        </button>
                                        <span class="text-black text-xl md:mx-4 mx-2">
                                          {{ Service.quantity }}
                                        </span>
                                        <button
                                          v-if="serviceRequest.status == 'pending'"
                                          class="justify-center btn icon-text-aligner bg-white hover:bg-white px-3 h-10"
                                          @click="increment(serviceRequestIndex, ServiceIndex)"
                                        >
                                          <img
                                            src="../../assets/img/icon-plus.svg"
                                            class="inline-block"
                                          />
                                        </button>
                                      </td>
                                    </tr>
                                    <tr
                                      class="flex border-b border-gray-200 table-row"
                                      v-for="(
                                        extraService, extraServiceIndex
                                      ) in serviceRequest.ExtraServices"
                                      :key="'extra_service_status' + extraServiceIndex"
                                    >
                                      <td class="px-2 md:px-4 py-2">
                                        <div class="flex items-center">
                                          <div>
                                            <h6 class="my-0 text-gray-900">
                                              {{ extraService.description }}
                                            </h6>
                                            <span class="text-black text-xl block md:hidden">
                                              Gs.
                                              {{ extraService.price | thousandSeparator }}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="px-4 py-2 hidden md:table-cell">
                                        <span class="text-black text-xl">
                                          Gs.
                                          {{ extraService.price | thousandSeparator }}
                                        </span>
                                      </td>
                                      <td class="px-2 md:px-4 py-2 text-right"></td>
                                    </tr>
                                    <tr
                                      class="flex border-b border-gray-200 table-row"
                                      v-if="serviceRequest.status == 'complete'"
                                    >
                                      <td class="px-2 md:px-4 py-2">
                                        <div class="flex items-center">
                                          <div>
                                            <h6 class="my-0 text-gray-900">
                                              Soporte - comisión HAND
                                            </h6>
                                            <span class="text-black text-xl block md:hidden">
                                              Gs.
                                              {{
                                                totalComision(serviceRequest) | thousandSeparator
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="px-4 py-2 hidden md:table-cell">
                                        <span class="text-black text-xl">
                                          Gs.
                                          {{ totalComision(serviceRequest) | thousandSeparator }}
                                        </span>
                                      </td>
                                      <td class="px-2 md:px-4 py-2 text-right"></td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr class="flex border-b border-gray-200 bg-white table-row">
                                      <td class="px-2 md:px-4 py-2">
                                        <div class="flex items-center">
                                          <div>
                                            <h6 class="my-0 text-black font-bold">Total:</h6>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="hidden md:table-cell px-4 py-2">
                                        <span
                                          class="text-black text-xl font-bold"
                                          v-if="serviceRequest.status == 'complete'"
                                        >
                                          Gs.
                                          {{ totalPayment(serviceRequest) | thousandSeparator }}
                                        </span>
                                        <span class="text-black text-xl font-bold" v-else>
                                          Gs.
                                          {{ totalPayment(serviceRequest) | thousandSeparator }}
                                        </span>
                                      </td>
                                      <td class="px-4 py-2 text-right table-cell">
                                        <span
                                          class="text-black text-xl font-bold md:hidden"
                                          v-if="serviceRequest.status == 'complete'"
                                        >
                                          Gs.
                                          {{ totalPayment(serviceRequest) | thousandSeparator }}
                                        </span>
                                        <span class="text-black text-xl font-bold md:hidden" v-else>
                                          Gs.
                                          {{ totalPayment(serviceRequest) | thousandSeparator }}
                                        </span>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                              <div
                                class="flex flex-wrap md:flex-row items-center my-4"
                                v-if="serviceRequest.status == 'pending'"
                              >
                                <div class="w-full md:w-auto">
                                  <p class="clr-blue text-center md:text-left">
                                    Podes seguir sumando servicios a la lista
                                  </p>
                                </div>
                                <div class="w-full md:w-auto text-center md:text-right ml-auto">
                                  <button
                                    class="rounded btn--primary lg:w-auto btn btn--md px-4"
                                    @click="
                                      selectServiceRequest(serviceRequestIndex, serviceRequest);
                                      $refs.modalService.openModal();
                                    "
                                  >
                                    <span class="justify-between text-base mr-5">
                                      Agregar servicios</span
                                    ><img
                                      src="../../assets/img/icon-circle-plus.svg"
                                      class="icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                                    />
                                  </button>
                                </div>
                              </div>
                            </section>
                            <hr
                              class="my-3 -mx-5 border-gray-200"
                              v-if="serviceRequest.status != 'complete'"
                            />
                            <section
                              class="my-4 overflow-hidden bg-white"
                              v-if="
                                serviceRequest.status == 'pending' ||
                                serviceRequest.status == 'ongoing'
                              "
                            >
                              <h5 class="font-semibold text-base">
                                <img
                                  src="../../assets/img/icon-agenda.svg"
                                  class="inline-block mr-1"
                                />
                                Fecha y horario del servicio
                              </h5>

                              <div class="flex flex-wrap my-4" style="background-color: #e4e9fb">
                                <div
                                  class="w-full px-3 md:px-6 py-3 md:w-1/2 border-b md:border-b-0 md:border-r border-gray-600"
                                >
                                  <p class="mb-0">Solicitado/s el:</p>
                                  <p class="text-lg mb-4 font-bold text-black capitalize">
                                    {{
                                      moment(serviceRequest.createdOn).format("dddd ,D MMMM, YYYY")
                                    }}
                                    <br />
                                    {{ moment(serviceRequest.createdOn).format("LT") }}
                                    Hs.
                                  </p>
                                  <button
                                    class="btn w-full bg-white hover:bg-white clr-orange-btn px-2"
                                  >
                                    <span class="justify-between text-base">
                                      {{ lapsedCreatedTime(serviceRequest.createdOn) }}</span
                                    >
                                  </button>
                                </div>
                                <div class="w-full px-3 md:px-6 py-3 md:w-1/2 border-gray-600">
                                  <p class="mb-0">Agendado/s para:</p>
                                  <p class="text-lg mb-4 font-bold text-black capitalize">
                                    {{
                                      moment(serviceRequest.serviceScheduledDate).format(
                                        "dddd ,D MMMM, YYYY",
                                      )
                                    }}
                                    <br />
                                    {{ moment(serviceRequest.serviceScheduledDate).format("LT") }}
                                    Hs.
                                  </p>
                                  <button
                                    class="btn w-full bg-white hover:bg-white clr-red-btn px-2"
                                    v-if="serviceRequest.status == 'pending'"
                                    @click="selectedCancleRequest(serviceRequest)"
                                  >
                                    <span class="justify-between text-base">
                                      Cancelar pedido de trabajo
                                    </span>
                                  </button>
                                  <button
                                    class="btn w-full bg-white hover:bg-white clr-blue-btn px-2"
                                    v-else
                                  >
                                    <span class="justify-between text-base">
                                      {{ lapsedScheduledTime(serviceRequest.serviceScheduledDate) }}
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div class="flex my-4" v-if="serviceRequest.status == 'pending'">
                                <div
                                  class="p-2 w-16 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                                >
                                  <img
                                    src="../../assets/img/icon-agenda-black.svg"
                                    class="inline-block"
                                  />
                                </div>
                                <div class="w-full p-2 bg-info-content-yellow text-black">
                                  En caso de que no puedas cumplir con la fecha y el horario podes
                                  enviarle una disponibilidad alternativa en “enviar mensajes”.
                                  Tener en cuenta que cancelar un pedido de trabajo podría afectar
                                  tu calificación en el sistema.
                                </div>
                              </div>
                              <div
                                class="flex flex-col items-center lg:flex-row"
                                v-if="
                                  serviceRequest.status == 'pending' ||
                                  serviceRequest.status == 'ongoing'
                                "
                              >
                                <div class="w-full text-center md:text-left">
                                  <img
                                    src="../../assets/img/map.png"
                                    alt="map"
                                    class="w-16 inline mr-2"
                                  />
                                  <button
                                    class="rounded btn--primary lg:w-auto btn btn--md px-3 py-3"
                                    @click="
                                      selectServiceAdress(serviceRequest);
                                      $refs.modalAdressInfo.openModal();
                                    "
                                  >
                                    <svg class="icon text-white icon--align-left mr-auto">
                                      <use
                                        href="../../assets/img/icons.svg#icon-pin"
                                        xlink:href="../../assets/img/icons.svg#icon-pin"
                                      />
                                    </svg>
                                    <span class="justify-between text-base ml-4">
                                      Ver lugar y dirección</span
                                    >
                                  </button>
                                </div>
                              </div>
                            </section>
                            <hr
                              class="my-3 -mx-5 border-gray-200"
                              v-if="serviceRequest.status != 'complete'"
                            />
                            <section
                              class="mt-4 mb-8 overflow-hidden bg-white"
                              v-if="serviceRequest.status != 'complete'"
                            >
                              <h5 class="font-semibold text-base mb-3">
                                <img
                                  src="../../assets/img/icon-info.svg"
                                  class="inline-block mr-1"
                                />
                                Información adicional sobre el pedido:
                              </h5>
                              <section class="flex mb-4 items-center">
                                <figure
                                  class="mr-2 bg-white border border-gray-200 avatar w-16 h-16"
                                >
                                  <img
                                    :src="serviceRequest.User.pictureProfileUrl"
                                    :alt="
                                      serviceRequest.User.firstName +
                                      ' ' +
                                      serviceRequest.User.lastName
                                    "
                                  />
                                </figure>
                                <div class="flex-1 bg-white border border-gray-200 rounded">
                                  <header
                                    class="flex items-center justify-between font-semibold px-4 py-2"
                                    style="background-color: #f2f5ff"
                                  >
                                    <div
                                      class="flex flex-col lg:flex-row text-base text-black capitalize"
                                    >
                                      <span>{{
                                        moment(serviceRequest.createdOn).format(
                                          "dddd ,D MMMM, YYYY",
                                        )
                                      }}</span>
                                    </div>
                                  </header>
                                  <p class="mb-0 p-2 text-black text-lg font-semibold">
                                    {{ serviceRequest.description }}
                                  </p>
                                </div>
                              </section>

                              <div class="relative flex flex-col items-center lg:flex-row">
                                <div class="w-full text-right">
                                  <router-link
                                    :to="{
                                      name: 'ProfessionalMessageResponder',
                                      params: {
                                        otype: 'servicerequest',
                                        oid: serviceRequest.id,
                                        ostatus: serviceRequest.status,
                                      },
                                    }"
                                    class="rounded btn--secondary lg:w-auto btn btn--md px-3 py-3"
                                  >
                                    <img
                                      src="../../assets/img/comment-alt-message.svg"
                                      class="icon icon--outline icon--stroke-3 icon--md icon--align-left mr-auto"
                                    />
                                    <span class="justify-between text-base ml-8">
                                      Enviar mensaje</span
                                    >
                                  </router-link>
                                </div>
                              </div>
                            </section>
                            <hr
                              class="my-3 -mx-5 border-gray-200"
                              v-if="
                                serviceRequest.status != 'complete' &&
                                serviceRequest.status != 'ongoing' &&
                                serviceRequest.Photos &&
                                serviceRequest.Photos.length > 0
                              "
                            />
                            <section
                              class="my-4 overflow-hidden bg-white"
                              v-if="
                                serviceRequest.status != 'complete' &&
                                serviceRequest.status != 'ongoing' &&
                                serviceRequest.Photos &&
                                serviceRequest.Photos.length > 0
                              "
                            >
                              <p class="text-black text-center">
                                {{ serviceRequest.Photos.length }} imágenes adjuntas para una mejor
                                descripción de lo requerido
                              </p>
                              <div
                                class="grid mb-4 grid-columns md:grid-columns-3 grid-gap-1 p-2 rounded"
                                style="background-color: #dddddd"
                              >
                                <figure
                                  class="p-1 border border-gray-200 rounded shadow-sm relative"
                                  v-for="Photo in serviceRequest.Photos"
                                  :key="'photo_' + Photo.id"
                                >
                                  <a :href="Photo.photoUrl" role="button" download>
                                    <img :src="Photo.photoUrl" class="w-full" />
                                  </a>
                                </figure>
                              </div>
                            </section>
                          </section>
                        </div>
                      </div>
                      <!-- other request status end -->
                      <footer
                        :id="'service_' + serviceRequest.id"
                        class="relative items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-gray-100 border-t border-gray-200"
                      >
                        <div class="flex flex-wrap text-center items-center md:text-left">
                          <div class="w-full flex md:w-auto text-base text-black font-semibold">
                            <div class="flex mb-2 md:mb-0">
                              Estado: <br class="md:hidden block" />

                              <span
                                class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold"
                                v-if="serviceRequest.status == 'cancelled'"
                                >Cancelado
                              </span>
                              <span
                                class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold success"
                                v-if="serviceRequest.status == 'complete'"
                                >Trabajo terminado
                              </span>
                              <span v-if="serviceRequest.status == 'ongoing'">
                                <span class="-mt-1 lg:mt-0 lg:pl-2 text-brand-primary font-bold"
                                  >Trabajo aceptado </span
                                ><br />
                                <span
                                  class="pr-1 text-brand-secondary font-medium inline-block md:hidden"
                                  >(*)</span
                                >
                                <span
                                  class="text-black text-base font-medium inline-block md:hidden"
                                  >Una vez finalizado el trabajo haz click aquí</span
                                >
                              </span>
                              <span
                                class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold"
                                v-if="serviceRequest.status == 'pending'"
                                >Pendiente (en espera que aceptes el trabajo)</span
                              >
                            </div>
                          </div>

                          <div
                            class="w-full md:w-auto text-center md:text-right md:ml-auto text-black"
                          >
                            <button
                              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
                              @click="
                                updateServiceRequestStatus(serviceRequest.id, {
                                  status: 'ongoing',
                                })
                              "
                              v-if="serviceRequest.status == 'pending'"
                              :disabled="processingServiceRequests"
                            >
                              Aceptar trabajo
                            </button>
                            <button
                              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
                              @click="showTotalAmountWarning()"
                              v-if="
                                serviceRequest.status == 'ongoing' &&
                                totalPayment(serviceRequest) == 0
                              "
                            >
                              Finalizar Trabajo
                            </button>
                            <button
                              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
                              @click="
                                updateServiceRequestStatus(serviceRequest.id, {
                                  status: 'complete',
                                })
                              "
                              v-if="
                                serviceRequest.status == 'ongoing' &&
                                totalPayment(serviceRequest) > 0
                              "
                              :disabled="processingServiceRequests"
                            >
                              Finalizar Trabajo
                            </button>

                            <button
                              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded"
                              @click="
                                updateServiceRequestStatus(serviceRequest.id, {
                                  paid: true,
                                })
                              "
                              v-if="
                                serviceRequest.status == 'complete' &&
                                (serviceRequest.paymentType == 'CASH' ||
                                  serviceRequest.paymentType == 'TRANSFER')
                              "
                              :disabled="
                                processingServiceRequests || serviceRequest.paymentType == null
                              "
                              v-bind:class="{
                                'bg-green-btn':
                                  !processingServiceRequests && serviceRequest.paymentType != null,
                              }"
                            >
                              Trabajo cobrado
                            </button>
                          </div>
                        </div>
                      </footer>
                    </div>
                    <!-- paid and reiewd content start-->

                    <div
                      class="flex flex-col justify-between lg:flex-row"
                      v-else-if="serviceRequest.paid == true"
                    >
                      <section class="w-full">
                        <section class="my-4 bg-white">
                          <div class="">
                            <h6 class="my-4 text-black text-xl font-semibold text-center">
                              Resumen de servicios
                            </h6>
                            <div class="flex flex-wrap">
                              <div class="w-full px-2 md:w-2/5">
                                <div class="flex items-center">
                                  <figure class="avatar w-16 h-16">
                                    <img
                                      :src="serviceRequest.User.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.User.firstName +
                                        ' ' +
                                        serviceRequest.User.lastName
                                      "
                                    />
                                  </figure>
                                  <div class="ml-3">
                                    <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                      {{ serviceRequest.User.firstName }}
                                      {{ serviceRequest.User.lastName }}
                                    </h6>
                                  </div>
                                </div>
                              </div>

                              <div class="w-full px-2 md:w-1/5 text-center hidden md:block">
                                <img
                                  src="../../assets/img/icon-flecha-arrow.svg"
                                  alt=""
                                  class="inline-block"
                                />
                              </div>

                              <div class="w-full px-2 md:w-2/5 hidden md:block">
                                <div class="flex items-center">
                                  <figure class="mr-3 avatar w-16 h-16 block md:hidden">
                                    <img
                                      :src="serviceRequest.Professional.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.Professional.firstName +
                                        ' ' +
                                        serviceRequest.Professional.lastName
                                      "
                                    />
                                  </figure>
                                  <div class="ml-0 md:ml-auto">
                                    <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                      {{ serviceRequest.Professional.firstName }}
                                      {{ serviceRequest.Professional.lastName }}
                                    </h6>
                                  </div>
                                  <figure class="ml-3 avatar w-16 h-16 md:block hidden">
                                    <img
                                      :src="serviceRequest.Professional.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.Professional.firstName +
                                        ' ' +
                                        serviceRequest.Professional.lastName
                                      "
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <table class="w-full text-left border-collapse p-4 mt-1">
                              <thead>
                                <tr
                                  class="flex border-b border-t border-gray-200 table-row text-black"
                                >
                                  <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                                  <th class="px-4 py-3 text-right">Precio:</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="flex border-b border-gray-200 table-row"
                                  style="background-color: #fff"
                                  v-for="(Service, index) in serviceRequest.Services"
                                  :key="'service_status_paid_' + index"
                                >
                                  <td class="px-2 md:px-4 py-2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black flex">
                                          <span class="w-8 mr-2"> {{ Service.quantity }}x</span
                                          >{{ Service.description }}
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl">
                                      Gs.
                                      {{ Service.price | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  class="flex border-b border-gray-200 table-row"
                                  style="background-color: #fff"
                                  v-for="(Item, index) in serviceRequest.ExtraServices"
                                  :key="'extraservice_paid_' + index"
                                >
                                  <td class="px-2 md:px-4 py-2 w-1/2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black">
                                          {{ Item.description }}
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl">
                                      Gs. {{ Item.price | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>

                                <tr
                                  class="flex border-b border-gray-200 table-row"
                                  style="background-color: #fff"
                                >
                                  <td class="px-2 md:px-4 py-2 w-1/2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black font-bold">Sub-total</h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl font-bold">
                                      Gs.
                                      {{ subTotalServiceCost(serviceRequest) | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  class="flex border-b border-gray-200 table-row"
                                  style="background-color: #fff"
                                >
                                  <td class="px-2 md:px-4 py-2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black font-bold">+10% I.V.A.</h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl font-bold">
                                      Gs.
                                      {{ totalTax(serviceRequest) | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  class="flex border-b border-gray-200 table-row"
                                  style="background-color: #fff"
                                >
                                  <td class="px-2 md:px-4 py-2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black font-bold">
                                          Soporte - comisión HAND
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl font-bold">
                                      Gs.
                                      {{ totalComision(serviceRequest) | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr class="flex table-row">
                                  <td class="px-2 md:px-4 py-2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black font-bold">Total:</h6>
                                      </div>
                                    </div>
                                  </td>

                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl font-bold">
                                      Gs.
                                      {{ totalPayment(serviceRequest) | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </section>
                      </section>
                    </div>

                    <!-- paid and reviewd content end-->
                  </div>
                </div>
              </div>
              <div class="container msg-section" v-else>
                <section
                  class="flex flex-col items-center md:flex-row alert alert--success shadow alert--info"
                >
                  <div class="md:pr-6 mb-2">
                    <svg class="text-6xl text-blue-800 icon md:text-5xl">
                      <use
                        href="../../assets/img/icons.svg#icon-alert-circle-i"
                        xlink:href="../../assets/img/icons.svg#icon-alert-circle-i"
                      />
                    </svg>
                  </div>
                  <div class="text-center md:text-left">
                    <p class="text-sm">No hay ordenes de trabajo que mostrar.</p>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <!-- modal start-->
    <div class="modal-adress-info">
      <modal ref="modalAdressInfo">
        <template v-slot:header>
          <h1></h1>
        </template>
        <template v-slot:body>
          <section class="p-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded">
            <h5 class="p-4 mt-0 -mx-5 -mt-5 font-semibold bg-gray-100 border-b border-gray-200">
              <img src="../../assets/img/icon-pin.svg" class="inline-block mr-1" />
              Lugar a realizar el servicio
            </h5>
            <div class="flex mt-4 items-center justify-center">
              <div class="w-full">
                <div class="bg-gray-100 p-3 rounded">
                  <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                    <div>
                      <label>
                        <span>Departamento</span>

                        <select
                          class="block w-full mt-1 rounded form-select"
                          v-model="selectedServiceAdress.department"
                          disabled
                        >
                          <option disabled v-if="activeDepartments.length <= 0" :value="null">
                            Departamento no encontrado
                          </option>
                          <option disabled v-else :value="null">Seleccionar Departmento</option>
                          <option
                            v-for="department in activeDepartments"
                            :key="department.id"
                            :value="department.id"
                          >
                            {{ department.description }}
                          </option>
                        </select>
                      </label>
                    </div>
                    <div>
                      <label>
                        <span>Ciudad</span>
                        <select
                          class="block w-full mt-1 rounded form-select"
                          v-model="selectedServiceAdress.location_id"
                          disabled
                        >
                          <option
                            disabled
                            v-if="activeDepartmentLocations.length <= 0"
                            :value="null"
                          >
                            Ciudad no encontrada
                          </option>
                          <option disabled v-else :value="null">Seleccione Ciudad</option>
                          <option
                            v-for="departmentLocation in activeDepartmentLocations"
                            :key="departmentLocation.id"
                            :value="departmentLocation.id"
                          >
                            {{ departmentLocation.description }}
                          </option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="bg-gray-100 p-3 rounded">
                  <label>
                    <span>Dirección</span>
                    <textarea
                      v-model="selectedServiceAdress.direction"
                      class="block w-full h-32 mx-auto form-control text-black height-textarea"
                      placeholder=""
                      readonly
                    ></textarea>
                  </label>

                  <label>
                    <span>Mapa</span>
                    <div class="w-full md:text-left text-center my-4">
                      <a
                        target="_blank"
                        :href="getGoogleMapUrl(selectedServiceAdress)"
                        class="rounded btn--primary lg:w-auto btn btn--md"
                      >
                        <span class="justify-between text-base text-white"> Como llégar?</span>
                      </a>
                    </div>

                    <section class="relative mb-8 border border-gray-200 rounded md:px-0">
                      <div style="width: 100%; height: 300px">
                        <l-map :zoom="zoom" :center="markerLatLng" ref="mymap">
                          <l-tile-layer :url="url"></l-tile-layer>
                          <l-circle :lat-lng="circle.center" :radius="circle.radius" />
                          <l-marker :lat-lng="markerLatLng" :draggable="false" ref="marker">
                          </l-marker>
                        </l-map>
                      </div>
                    </section>
                  </label>
                </div>
              </div>
            </div>
          </section>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
    <modal-service-request-location
      v-if="showModalLocationDetails"
      :service-request="selectedRequest"
      @close-modal="closeModalLocationDetails"
    />
    <!-- modal start-->
    <modal ref="modalService">
      <template v-slot:header>
        <h1></h1>
      </template>
      <template v-slot:body>
        <section class="w-full professional-profile">
          <div class="flex flex-col md:flex-row">
            <div class="w-full md:w-2/3">
              <h4>
                <svg class="-mt-2 icon">
                  <use
                    href="../../assets/img/icons.svg#icon-todo"
                    xlink:href="img/icons.svg#icon-todo"
                  />
                </svg>
                Servicios ofrecidos
              </h4>
            </div>
            <div class="w-full md:w-1/3">
              <div class="flex items-stretch mb-4 text-lg shadow-xl md:flex-row">
                <input
                  type="text"
                  placeholder="Filtrar servicios"
                  class="text-gray-800 placeholder-gray-800 form-input w-4/5 md:mb-0 rounded-r-none h-auto"
                  v-model="searchQuery"
                />

                <button
                  class="justify-center font-semibold btn btn--primary w-1/5 h-auto rounded-l-none icon-text-aligner"
                  @click="searchService()"
                >
                  <svg class="text-white icon icon--outline icon--stroke-2 icon--md">
                    <use
                      href="../../assets/img/icons.svg#icon-search"
                      xlink:href="img/icons.svg#icon-search"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="flex mb-2 px-0 md:px-6 hidden md:flex">
            <div class="w-auto">
              <p class="clr-blue">Por favor seleccione la casilla de cada servicio que necesita</p>
            </div>
            <div class="w-12 text-right ml-auto">
              <img src="../../assets/img/icon-arrow-down.svg" class="inline-block" />
            </div>
          </div>
          <section class="overflow-hidden bg-white border border-gray-400 rounded rounded-b-none">
            <div class="px-5 py-2 block md:hidden">
              <div class="flex items-center">
                <div class="w-auto">
                  <p class="clr-blue">
                    Por favor seleccione la casilla de cada servicio que necesita
                  </p>
                </div>
                <div class="w-12 text-right ml-auto px-2">
                  <img src="../../assets/img/icon-arrow-down.svg" class="inline-block" />
                </div>
              </div>
            </div>
            <table class="w-full text-left border-collapse p-4 mt-0">
              <tbody>
                <tr
                  class="flex border-b border-gray-200 table-row"
                  v-for="(Service, index) in filterService.slice(0, showService)"
                  :key="index"
                  style="background-color: white"
                >
                  <td class="px-6 py-4">
                    <div class="flex items-center">
                      <div>
                        <h6 class="my-0 text-gray-900">
                          {{ Service.description }}
                        </h6>
                        <span class="mr-4 text-black text-xl block md:hidden" v-if="Service.price">
                          Gs.{{ Service.price | thousandSeparator }}
                        </span>
                        <span class="mr-4 text-black text-xl block md:hidden" v-else> Gs. 0 </span>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 text-right">
                    <span
                      class="mr-4 text-black text-xl hidden md:inline-block"
                      v-if="Service.price"
                    >
                      Gs.{{ Service.price | thousandSeparator }}
                    </span>
                    <span class="mr-4 text-black text-xl hidden md:inline-block" v-else>
                      Gs. 0
                    </span>
                    <input
                      type="checkbox"
                      class="form-checkbox"
                      v-model="selectedServices"
                      :value="Service"
                      @change="addService($event, Service)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-5">
              <div class="flex my-8 items-center justify-center" v-if="filterService.length == 0">
                <div class="w-full md:w-1/2">
                  <p class="text-black text-center font-medium md:px-8 text-lg mb-5">
                    Su búsqueda no dio resultados por favor revise el texto de su búsqueda o cambie
                    su búsqueda
                  </p>

                  <div class="flex my-4 justify-center">
                    <button
                      class="btn btn--md font-medium bg-red-btn px-6 py-2"
                      @click="clearSearchService()"
                    >
                      Borrar búsqueda
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex mb-4">
                <div class="w-auto">
                  <p class="clr-red">
                    Por favor seleccione la casilla de cada servicio que necesita
                  </p>
                </div>
                <div class="w-12 text-right px-2 ml-auto">
                  <img src="../../assets/img/icon-arrow-top.svg" class="inline-block" />
                </div>
              </div>

              <div class="flex flex-wrap md:flex-row items-center my-4">
                <div class="w-full md:w-auto mb-2 md:mb-0"></div>
                <div
                  class="w-full md:w-auto text-center md:text-right ml-auto"
                  v-if="services.length > showService"
                >
                  <button class="btn btn--primary py-3 w-full" @click="showMoreService()">
                    <img
                      src="../../assets/img/icon-flecha.svg"
                      class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-left mr-auto"
                    />
                    <span class="justify-between text-base px-3"> Mostrar 5 servicios más</span>
                    <img
                      src="../../assets/img/icon-flecha.svg"
                      class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                    />
                  </button>
                </div>
              </div>
            </div>
          </section>
        </section>
      </template>

      <template v-slot:footer> </template>
    </modal>
    <!-- modal end -->
    <!-- modal start-->
    <div class="cancle-service">
      <modal ref="cancleServiceRequest">
        <template v-slot:header>
          <h1></h1>
        </template>

        <template v-slot:body>
          <div class="flex flex-row items-center justify-center mb-6">
            <div class="w-32 h-auto flex justify-center text-center">
              <img src="../../assets/img/icon-Advertencia.svg" class="inline-block" />
            </div>
            <div class="w-auto px-2 text-xl text-black leading-tight font-bold">
              ¿Estas seguro<br />de que quieres cancelar el pedido de trabajo?
            </div>
          </div>
          <div class="flex flex-row justify-center">
            <div class="w-full">
              <p class="text-black mb-8 text-lg">
                Cancelar un pedido de trabajo puede afectar negativamente tu calificación en el
                sistema. Si se trata de un problema de tiempos te recomendamos enviarle un mensaje
                al cliente para tratar de coordinar una nueva fecha y hora.
              </p>
              <button
                @click="redirectToMessage"
                class="rounded w-full btn btn--md shadow-md py-3 px-4 mb-4 confirm-button-class"
              >
                <span class="justify-between text-base mr-8 clr-blue">
                  Enviar mensaje al cliente</span
                >
                <img
                  src="../../assets/img/icon-mail.svg"
                  class="icon icon--outline icon--stroke-3 icon--md icon--align-left ml-auto"
                />
              </button>
              <button
                @click="cancleServiceRequest()"
                class="rounded w-full btn btn--md shadow-md py-3 px-4 mb-4 confirm-button-class"
              >
                <span class="justify-between text-base mr-8 clr-red">
                  Cancelar pedido de trabajo</span
                >
                <img
                  src="../../assets/img/icon-Cerrar.svg"
                  class="icon icon--outline icon--stroke-3 icon--md icon--align-left ml-auto"
                />
              </button>
              <button
                class="rounded w-full btn btn--md shadow-md py-3 px-4 confirm-button-class"
                @click="closeCancleService()"
              >
                <span class="justify-between text-base clr-grey"> Cerrar este dialogo</span>
              </button>
            </div>

            <hr />
          </div>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfessionalProfileSidebar from "@/components/ProfessionalProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import totalServiceCost from "@/utils/methods/totalServiceCost";
import scrollToSection from "@/utils/methods/scrollToSection";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import lapsedCreatedTime from "@/utils/methods/lapsedCreatedTime";
import lapsedScheduledTime from "@/utils/methods/lapsedScheduledTime";
import GetDepartments from "@/utils/methods/GetDepartments";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LCircle, LMarker } from "vue2-leaflet";
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
import { bus } from "../../main";
import { MapPinIcon } from "@/components/icons";
import ModalServiceRequestLocation from "./ModalServiceRequestLocation.vue";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
const handToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export default {
  data() {
    return {
      submited: false,
      processingServiceRequests: false,
      serviceRequests: {},
      updateServiceRequestData: {
        serviceId: null,
        requestData: {},
      },
      searchQuery: "",
      filterService: [],
      showService: 10,
      services: [],
      selectedServices: [],
      selectedServiceRequestIndex: null,
      extraService: {
        description: "",
        price: "",
      },
      addExtraServiceData: {
        orderId: null,
        requestData: {},
      },
      processingAddItems: false,
      contentVisible: false,
      processingProfessionalData: false,
      processingUpdateServiceRequest: false,
      updateServiceData: {
        orderId: null,
        requestData: {},
      },
      updateServiceQuantityData: {
        orderId: null,
        serviceDetailId: null,
        requestData: {
          quantity: null,
        },
      },
      selectedRequest: {},
      selectedServiceAdress: {
        direction: null,
        department: null,
        location_id: null,
        latitude: -25.3211306,
        longitude: -57.5928935,
      },
      departments: [],
      departmentLocations: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 10,
      markerLatLng: [-25.3211306, -57.5928935],
      circle: {
        center: latLng(-25.3211306, -57.5928935),
        radius: 4500,
      },
      showModalLocationDetails: false,
    };
  },
  created() {
    bus.$on("refreshIt", (data) => {
      if (data == "yes") {
        this.GetUserServiceRequest();
      }
    });
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetDepartments();
    this.GetUserServiceRequest();
    if (this.$route.params.id) {
      var id = this.$route.params.id;
      var sectionId = "service_" + id;
      this.scrollToSection(sectionId);
    }
  },
  watch: {
    "$route.params": "goToServiceRequest",
  },
  methods: {
    showLocationDetails({ serviceRequest }) {
      this.showModalLocationDetails = true;
      this.selectedRequest = serviceRequest;
    },
    closeModalLocationDetails() {
      this.showModalLocationDetails = false;
      this.selectedRequest = {};
    },
    GetDepartments,
    GetDepartmentLocations(isSetDefault) {
      this.$store
        .dispatch("GetDepartmentLocations", {
          departmentId: this.selectedServiceAdress.department,
        })
        .then((response) => {
          this.departmentLocations = response.data.locations;
          if (isSetDefault && this.departmentLocations.length > 0) {
            this.selectedServiceAdress.location_id = this.departmentLocations[0].id;
          }
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    GetLocationDepartment(locationId) {
      this.$store
        .dispatch("GetLocationDepartment", {
          locationId: locationId,
        })
        .then((response) => {
          this.$store.dispatch("setShowAlert", false);
          this.selectedServiceAdress.department = response.locations.department_id;
          this.GetDepartmentLocations();
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    GetUserServiceRequest() {
      this.processingServiceRequests = true;
      this.$store
        .dispatch("GetUserServiceRequest", {
          userId: this.user.id,
        })
        .then((response) => {
          this.serviceRequests = response;
          this.processingServiceRequests = false;
        })
        .catch(() => {
          this.processingServiceRequests = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    totalServiceCost,
    scrollToSection,
    subTotalServiceCost(requestData) {
      if (this.totalPayment(requestData) && this.totalPayment(requestData) > 0) {
        return (
          this.totalPayment(requestData) -
          this.totalTax(requestData) -
          this.totalComision(requestData)
        );
      } else {
        return 0;
      }
    },
    totalTax(requestData) {
      var ivaGravado = 0;
      if (requestData.Profession && requestData.Profession[0].ivaGravado) {
        ivaGravado = requestData.Profession[0].ivaGravado;
      }
      if (this.totalPayment(requestData) && this.totalPayment(requestData) > 0 && ivaGravado > 0) {
        return Math.round(this.totalPayment(requestData) / ivaGravado);
      } else {
        return 0;
      }
    },
    totalComision(requestData) {
      var comisionGravado = 0;
      if (requestData.Professional && requestData.Professional.comisionGravado) {
        comisionGravado = requestData.Professional.comisionGravado;
      }
      if (
        this.totalPayment(requestData) &&
        this.totalPayment(requestData) > 0 &&
        comisionGravado > 0
      ) {
        return Math.round(this.totalPayment(requestData) / comisionGravado);
      } else {
        return 0;
      }
    },
    totalPayment(requestData) {
      if (requestData.Services && requestData.Services.length > 0) {
        var sum = 0;
        requestData.Services.forEach((e) => {
          sum += e.quantity * e.price;
        });
        if (requestData.ExtraServices && requestData.ExtraServices.length > 0) {
          requestData.ExtraServices.forEach((e) => {
            sum += 1 * e.price;
          });
        }
        return sum;
      } else {
        return 0;
      }
    },
    increment(serviceRequestIndex, ServiceIndex) {
      var orderID = this.serviceRequests[serviceRequestIndex].id;
      var serviceDetailId =
        this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].order_detail_id;
      var qty = this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity;
      this.updateServiceQuantity(orderID, serviceDetailId, qty + 1);
    },
    decrement(serviceRequestIndex, ServiceIndex) {
      if (this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity === 1) {
        this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity = 1;
      } else {
        var orderID = this.serviceRequests[serviceRequestIndex].id;
        var serviceDetailId =
          this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].order_detail_id;
        var qty = this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity;
        this.updateServiceQuantity(orderID, serviceDetailId, qty - 1);
      }
    },
    updateServiceRequestStatus(serviceId, data) {
      this.processingServiceRequests = true;
      this.updateServiceRequestData.serviceId = serviceId;
      this.updateServiceRequestData.requestData = data;
      this.$store
        .dispatch("updateServiceRequestStatus", this.updateServiceRequestData)
        .then(() => {
          this.processingServiceRequests = false;

          if (data.status == "ongoing") {
            Swal.fire({
              title: "El trabajo fue aceptado correctamente",
              icon: "success",
              padding: "1.25em 3em",
              html: `<div class="text-left mb-2">Siguientes pasos:
              </div><ol class="text-left" style="list-style:auto;">
              <li class="mb-2">El cliente recibió una notificación que usted aceptó el trabajo</li>
              <li>El día de realización del trabajo ingresar a su panel y buscar la orden de trabajo “En proceso”</li>
              <li>Al terminar el trabajo marcar “trabajo realizado” y confirmar el pago</li>
              </ol> `,
              confirmButtonText: "Continuar",
              backdrop: false,
            }).then((result) => {
              if (result.value) {
                this.GetUserServiceRequest();
                this.contentVisible = serviceId;
                this.$router
                  .push({
                    name: "ProfessionalServiceRequestSection",
                    params: { id: serviceId },
                  })
                  .catch((error) => {
                    if (error.name == "NavigationDuplicated") {
                      this.goToServiceRequest();
                    } else if (error.name != "NavigationDuplicated") {
                      throw error;
                    }
                  });
              }
            });
          } else if (data.status == "complete") {
            Swal.fire({
              title: "El cliente debe realizar el pago.",
              icon: "success",
              padding: "1.25em 3em",
              html: `<div class="text-left mb-2">Opciones:</div>
              <ol class="comp-modal text-left mb-2" style="list-style:auto;">
              <li>Tarjeta de crédito online en la plataforma.</li>
              <li>Transferencia bancaria a HAND.</li>
              <li>Pago en efectivo, que se lo entregará a usted.</li>
              </ol>
              <ul class="text-left comp-modal">
              <li>Opción 1 y 2 confirmar con HAND, ver comprobante de pago del cliente.</li>
              <li>Efectivo, recibir el pago y marcar“ trabajo cobrado”.</li>
              <li>Recordar al cliente que lo califique.</li>
              </ul> `,
              confirmButtonText: "Continuar",
              backdrop: false,
            }).then((result) => {
              if (result.value) {
                this.GetUserServiceRequest();
                this.contentVisible = serviceId;
                this.$router
                  .push({
                    name: "ProfessionalServiceRequestSection",
                    params: { id: serviceId },
                  })
                  .catch((error) => {
                    if (error.name == "NavigationDuplicated") {
                      this.goToServiceRequest();
                    } else if (error.name != "NavigationDuplicated") {
                      throw error;
                    }
                  });
              }
            });
          } else {
            this.GetUserServiceRequest();
            let registerMessage = `Estado de orden actualizado correctamente`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", registerMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        })
        .catch(() => {
          this.processingServiceRequests = false;
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    selectServiceRequest(serviceRequestIndex, ServiceRequest) {
      this.selectedServiceRequestIndex = serviceRequestIndex;
      this.GetProfessionalData(ServiceRequest);
    },
    searchService() {
      if (this.searchQuery) {
        this.filterService = this.services.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.description.toLowerCase().includes(v));
        });
      } else {
        this.filterService = this.services;
      }
    },
    showMoreService() {
      this.showService = this.showService + 5;
    },
    clearSearchService() {
      this.searchQuery = "";
      this.filterService = this.services;
    },
    addService(e, service) {
      const f = this.serviceRequests[this.selectedServiceRequestIndex].Services.findIndex(
        (_item) => _item.id === service.id,
      );
      var orderID = this.serviceRequests[this.selectedServiceRequestIndex].id;
      var services = this.serviceRequests[this.selectedServiceRequestIndex].Services;
      var updatedServices = [];
      if (e.target.checked) {
        if (f <= -1) {
          for (var p = 0; p < services.length; p++) {
            updatedServices.push({
              id: services[p].id,
              quantity: services[p].quantity,
            });
          }
          updatedServices.push({
            id: service.id,
            quantity: 1,
          });
          this.updateServiceRequest(orderID, updatedServices);
        }
      } else {
        services.splice(f, 1);
        for (var u = 0; u < services.length; u++) {
          updatedServices.push({
            id: services[u].id,
            quantity: services[u].quantity,
          });
        }
        this.updateServiceRequest(orderID, updatedServices);
      }
    },
    addExtraService(requestID) {
      this.submited = true;
      this.processingAddItems = true;
      if (this.validateExtraServiceForm()) {
        this.extraService.price = this.extraService.price.replace(".", "");
        this.addExtraServiceData.orderId = requestID;
        this.addExtraServiceData.requestData = this.extraService;
        this.$store
          .dispatch("addExtraService", this.addExtraServiceData)
          .then(() => {
            this.processingAddItems = false;
            this.submited = false;
            handToast.fire({
              icon: "success",
              title: "Servicio adicional agregado exitosamente",
            });
            this.GetUserServiceRequest();
            this.extraService.description = "";
            this.extraService.price = "";
          })
          .catch(() => {
            this.processingAddItems = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingAddItems = false;
        setTimeout(function () {
          var d = document.getElementsByClassName("OnErrorScrollHere")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    validateExtraServiceForm() {
      return (
        this.extraService.description != "" &&
        this.extraService.price != "" &&
        this.isNumber(this.extraService.price)
      );
    },
    removeExtraService(orderId, extraServiceId) {
      this.$store
        .dispatch("removeExtraService", {
          orderId: orderId,
          extraServiceId: extraServiceId,
        })
        .then(() => {
          handToast.fire({
            icon: "success",
            title: "Servicio adicional eliminado correctamente",
          });
          this.GetUserServiceRequest(true, orderId);
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
        });
    },
    isNumber(val) {
      val = val.replace(".", "");
      if (!/^[-]?[0-9]+$/.test(val) || val == "") {
        return false;
      } else {
        return true;
      }
    },
    GetProfessionalData(ServiceRequest) {
      this.processingProfessionalData = true;
      this.$store
        .dispatch("GetProfileData", {
          professionalId: ServiceRequest.Professional.user_id,
          professionId: ServiceRequest.Profession[0].id,
        })
        .then((response) => {
          this.services = response.Services;
          this.filterService = this.services;

          this.selectedServices = [];
          ServiceRequest.Services.forEach((e) => {
            const i = this.services.findIndex((_item) => _item.id === e.id);
            if (i > -1) {
              this.selectedServices.push(this.services[i]);
            }
          });
          this.processingProfessionalData = false;
        })
        .catch(() => {
          this.processingProfessionalData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    lapsedCreatedTime,
    lapsedScheduledTime,
    updateServiceRequest(orderID, services) {
      this.processingUpdateServiceRequest = true;
      this.updateServiceData.orderId = orderID;
      this.updateServiceData.requestData.services = services;
      this.$store
        .dispatch("updateServiceRequest", this.updateServiceData)
        .then(() => {
          this.processingUpdateServiceRequest = false;
          this.GetUserServiceRequest();
          handToast.fire({
            icon: "success",
            title: "servicios actualizados con éxito",
          });
        })
        .catch(() => {
          this.processingUpdateServiceRequest = false;

          handToast.fire({
            icon: "error",
            title: "Error ocurrido: algo salió mal, intente de nuevo",
          });
        });
    },
    updateServiceQuantity(orderId, serviceDetailId, qty) {
      this.processingUpdateServiceRequest = true;
      this.updateServiceQuantityData.orderId = orderId;
      this.updateServiceQuantityData.serviceDetailId = serviceDetailId;
      this.updateServiceQuantityData.requestData.quantity = qty;

      this.$store
        .dispatch("updateServiceQuantity", this.updateServiceQuantityData)
        .then(() => {
          this.processingUpdateServiceRequest = false;
          this.GetUserServiceRequest();
          handToast.fire({
            icon: "success",
            title: "Cantidad de servicio actualizada correctamente",
          });
        })
        .catch(() => {
          this.processingUpdateServiceRequest = false;
          handToast.fire({
            icon: "error",
            title: "Error ocurrido: algo salió mal, intente de nuevo",
          });
        });
    },
    goToServiceRequestEvent(notification) {
      if (notification.eventId) {
        var id = notification.eventId;
        var sectionId = "service_" + id;
        this.scrollToSection(sectionId);
      }
    },
    goToServiceRequest() {
      if (this.$route.params.id) {
        var id = this.$route.params.id;
        var sectionId = "service_" + id;
        this.scrollToSection(sectionId);
      }
    },

    selectedCancleRequest(requestData) {
      this.selectedRequest = requestData;
      this.$refs.cancleServiceRequest.openModal();
    },
    closeCancleService() {
      this.selectedRequest = {};
      this.$refs.cancleServiceRequest.closeModal();
    },
    redirectToMessage() {
      this.$refs.cancleServiceRequest.closeModal();
      this.$router.push({
        name: "ProfessionalMessageResponder",
        params: {
          otype: "servicerequest",
          oid: this.selectedRequest.id,
          ostatus: this.selectedRequest.status,
        },
      });
    },
    cancleServiceRequest() {
      this.updateServiceRequestStatus(this.selectedRequest.id, {
        status: "cancelled",
      });
      this.selectedRequest = {};
      this.$refs.cancleServiceRequest.closeModal();
    },
    selectServiceAdress(ServiceRequest) {
      this.selectedServiceAdress.location_id = ServiceRequest.location_id;
      this.selectedServiceAdress.longitude = ServiceRequest.longitude;
      this.selectedServiceAdress.latitude = ServiceRequest.latitude;
      this.selectedServiceAdress.direction = ServiceRequest.direction;
      if (ServiceRequest.latitude && ServiceRequest.longitude) {
        this.circle.center = latLng(ServiceRequest.latitude, ServiceRequest.longitude);
        this.markerLatLng = [ServiceRequest.latitude, ServiceRequest.longitude];
      }
      this.GetLocationDepartment(this.selectedServiceAdress.location_id);
    },
    formatAmount(val) {
      val = val.replace(".", "");
      if (Number(val) > 0) {
        this.extraService.price = Number(val)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    showTotalAmountWarning() {
      let warningMessage =
        "No puede finalizar un trabajo con costo 0, debe agregar al menos un gasto adicional";
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", warningMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getGoogleMapUrl(ServiceAdress) {
      //let address = ServiceAdress.direction;
      let latitude = ServiceAdress.latitude;
      let longitude = ServiceAdress.longitude;
      let url =
        "https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=" +
        latitude +
        "," +
        longitude;
      url = encodeURI(url);
      return url;
    },
    refreshServiceRequestEvent() {
      this.GetUserServiceRequest();
    },
  },
  filters: {
    thousandSeparator,
    twoDigit: function (value) {
      if (!value) return "";
      return ("0" + value).slice(-2);
    },
  },
  computed: {
    ...mapGetters(["error", "user"]),
    activeDepartments,
    activeDepartmentLocations,
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfessionalProfileSidebar,
    Modal,
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    MapPinIcon,
    ModalServiceRequestLocation,
  },
};
</script>
<style lang="css" scoped>
@import "../../assets/css/control-panel.css";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.success {
  color: #1bb01b;
}
.capitalize {
  text-transform: capitalize;
}
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.clr-red-btn {
  color: #d32011 !important;
  border: 1px solid #d32011;
}
</style>
<style lang="css">
.cancle-service .modal__dialog {
  width: 25%;
}
.cancle-service .modal__header,
.cancle-service .modal__footer {
  display: none;
}
.cancle-service .modal__body {
  padding: 30px;
}
@media screen and (max-width: 992px) {
  .cancle-service .modal__dialog {
    width: 90%;
  }
}
.clr-blue {
  color: #375be5 !important;
}
.clr-red {
  color: #d32011 !important;
}
.modal-adress-info .modal__dialog {
  width: 50%;
}
.modal-adress-info .modal__footer {
  display: none;
}
@media screen and (max-width: 992px) {
  .modal-adress-info .modal__dialog {
    width: 90%;
  }
}
ul.comp-modal li:before {
  content: ">";
  padding-right: 5px;
}
ul.comp-modal li {
  text-indent: -1em;
  margin-bottom: 5px;
}
ol.comp-modal li {
  margin-bottom: 5px;
}
.progress-marker:before {
  z-index: 2 !important;
}
.progress-marker:after {
  z-index: 1 !important;
}
</style>
