<template>
  <div>
    <Header />
    <Breadcrumb :items="BreadcrumbItems" />
    <Messages />
    <section class="py-3">
      <div class="container container-xl">
        <div class="flex flex-col justify-between my-4 -mx-2 lg:flex-row">
          <aside class="px-2 mb-4 lg:w-3/12">
            <div
              class="overflow-hidden bg-white rounded shadow-lg md:sticky md:top-0"
              v-if="ServiceRequestdata.Professional"
            >
              <img
                class="object-cover w-full h-56"
                :src="ServiceRequestdata.Professional.pictureProfileUrl"
                :alt="
                  ServiceRequestdata.Professional.firstName +
                    ' ' +
                    ServiceRequestdata.Professional.lastName
                "
              />
              <div class="px-6 py-4">
                <p class="mb-0 text-sm text-gray-500">Nombre:</p>
                <h5 class="mt-0 mb-2">
                  {{ ServiceRequestdata.Professional.firstName }}
                  {{ ServiceRequestdata.Professional.lastName }}
                </h5>

                <p class="mb-0 text-sm text-gray-500">Trabajo realizado:</p>
                <ul class="pt-2">
                  <li
                    class="mb-3 icon-text-aligner"
                    v-for="(Service, index) in ServiceRequestdata.Services"
                    :key="index"
                  >
                    <svg class="flex-none icon">
                      <use
                        href="../assets/img/icons.svg#icon-check-circle-alt"
                        xlink:href="img/icons.svg#icon-check-circle-alt"
                      />
                    </svg>
                    <span class="ml-2">{{ Service.description }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </aside>
          <section class="px-2 lg:w-9/12 md:pr-2 md:pl-4">
            <h4>
              <svg class="-mt-2 icon">
                <use
                  href="../assets/img/icons.svg#icon-chat-round-content"
                  xlink:href="img/icons.svg#icon-chat-round-content"
                />
              </svg>
              Dejanos tu comentario
            </h4>

            <section class="p-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded">
              <form @submit.prevent="validateRating" method="post">
                <p>
                  Ayudanos a mejorar, tu opinión es importante, dejando un comentario y una
                  puntuación sobre el trabajo realizado por el profesional contratado ayudaras a
                  mejorar la plataforma, haciendo que tanto el profesional como otros usuarios se
                  beneficien.
                </p>
                <div class="flex items-center">
                  <span class="my-rating" @mouseleave="showCurrentRating(0)">
                    <star-rating
                      v-model="reviewdata.rate"
                      inactive-color="#dfe0e6"
                      :show-rating="false"
                      active-color="#ffd83e"
                      :increment="0.5"
                      :star-size="30"
                      @current-rating="showCurrentRating"
                      @rating-selected="setCurrentSelectedRating"
                    ></star-rating>
                  </span>
                  <span class="pl-3 text-3xl live-rating font-display text-brand-secondary">
                    {{ currentRating }}
                  </span>
                </div>
                <div class="flex items-center mb-4">
                  <span class="error" v-if="submited && !reviewdata.rate > 0"
                    >Please Select Review Rating</span
                  >
                </div>
                <textarea
                  rows="5"
                  class="w-full mt-1 form-input"
                  placeholder="Ingresar un comentario"
                  v-model="reviewdata.description"
                  v-bind:class="{
                    'bg-red-100 border-red-700': submited && !reviewdata.description
                  }"
                ></textarea>
                <div class="flex items-center mb-2">
                  <span class="error" v-if="submited && !reviewdata.description"
                    >Favor ingrese una descripción</span
                  >
                </div>
                <button
                  type="submit"
                  class="w-full btn btn--success btn--md"
                  :disabled="processingreview"
                >
                  Enviar comentario
                </button>
              </form>
            </section>
          </section>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      submited: false,
      rating: 0.0,
      reviewdata: {
        rate: 0,
        description: ""
      },
      currentRating: 0.0,
      currentSelectedRating: 0,
      processingGetServiceDetails: false,
      processingreview: false,
      serviceToken: null,
      ServiceRequestdata: {},
      BreadcrumbItems: [{ name: "Inicio", link: "/" }]
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetServiceRequestDetails();
  },
  created() {
    if (this.$route.params.stid) {
      this.serviceToken = this.$route.params.stid;
    }
  },
  methods: {
    setRating: function(rating) {
      this.rating = rating;
    },
    showCurrentRating: function(rating) {
      this.currentRating = rating === 0 ? this.currentSelectedRating : rating;
    },
    setCurrentSelectedRating: function(rating) {
      this.currentSelectedRating = rating;
    },
    GetServiceRequestDetails() {
      this.processingGetServiceDetails = true;
      this.$store
        .dispatch("GetServiceRequestDetails", {
          token: this.serviceToken
        })
        .then(response => {
          this.ServiceRequestdata = response[0];
          this.BreadcrumbItems.push({
            name:
              this.ServiceRequestdata.Professional.firstName +
              " " +
              this.ServiceRequestdata.Professional.lastName,
            link: "/professionals/complete-profile/" + this.ServiceRequestdata.Professional.id
          });
          this.BreadcrumbItems.push({
            name: "Review",
            islast: true
          });
          this.processingGetServiceDetails = false;
        })
        .catch(() => {
          this.processingGetServiceDetails = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    validateRating(e) {
      e.preventDefault();
      this.submited = true;
      this.processingreview = true;
      this.reviewdata.professional_id = this.ServiceRequestdata.Professional.id;
      this.reviewdata.service_id = this.ServiceRequestdata.id;
      this.reviewdata.user_id = this.user.id;
      if (this.validatereviewForm()) {
        if (this.reviewdata.rate <= 1) {
          Swal.fire({
            title: "",
            text:
              "Has seleccionado el puntaje más bajo, por favor asegurese que seleccionó correctamente, ya que el mismo va a incidir negativamente en el perfil del profesional. Desea continuar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then(result => {
            if (result.value) {
              this.submitProfessionalReview();
            } else {
              this.processingreview = false;
              this.submited = false;
            }
          });
        } else {
          this.submitProfessionalReview();
        }
      } else {
        this.processingreview = false;
      }
    },
    submitProfessionalReview() {
      this.$store
        .dispatch("submitProfessionalReview", this.reviewdata)
        .then(() => {
          let reviewsubmitMessage = `Reseña enviada correctamente`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", reviewsubmitMessage);
          this.$store.dispatch("setShowAlert", true);
          this.currentRating = 0.0;
          this.currentSelectedRating = 0;
          this.rating = 0.0;
          this.reviewdata = {
            rate: 0,
            description: ""
          };
          this.processingreview = false;
          this.submited = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          this.processingreview = false;
          this.submited = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    validatereviewForm() {
      return this.reviewdata.rate > 0 && this.reviewdata.description != "";
    }
  },
  computed: {
    ...mapGetters(["error", "user"])
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb
  }
};
</script>
<style lang="css" scoped>
@import "../assets/css/review.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
