<template>
  <svg :width="width" :height="height" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.3999 5.3998H20.5999M7.3999 1.7998H14.5999M8.5999 17.3998V10.1998M13.3999 17.3998V10.1998M15.1999 22.1998H6.7999C5.47442 22.1998 4.3999 21.1253 4.3999 19.7998L3.85198 6.64976C3.82358 5.96801 4.3686 5.3998 5.05094 5.3998H16.9489C17.6312 5.3998 18.1762 5.96801 18.1478 6.64976L17.5999 19.7998C17.5999 21.1253 16.5254 22.1998 15.1999 22.1998Z" :class="`stroke-${color}`" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default{
  props:{
    width:{
      type:Number,
      default:22
    },
    height:{
      type:Number,
      default:24
    },
    color:{
      type:String,
      default:'white'
    }
  }
}
</script>
