<template>
    <section class="flex bg-white">
      <div class="container flex flex-col container-xl md:flex-row mt-6 md:mt-24 p-10 md:p-0 mb-48">
        <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0 mx-auto">
          <Messages />
          <form
            @submit.prevent="onSubmit"
            method="post"
          >
            <fieldset class="mb-3">
              <h4 class="">Datos personales</h4>
              <p class="mb-6">Texto legal</p>
              <div class="grid grid-cols-1 xl-grid-cols-2 grid-gap-4">
                <div class="mt-[15px] xl:mt-[0px]">
                  <form-label :title="'Departamento'">
                    <select
                      class="block w-full rounded form-select"
                      v-model="department"
                      @change="GetDepartmentLocations()"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !department
                      }"
                    >
                      <option disabled v-if="activeDepartments.length <= 0" :value="null"
                      >Departamento no encontrado</option
                      >
                      <option disabled v-else :value="null">Seleccionar Departamento</option>
                      <option
                        v-for="department in activeDepartments"
                        :key="department.id"
                        :value="department.id"
                      >{{ department.description }}</option
                      >
                    </select>
                    <span class="error" v-if="submited && !department"
                    >Por favor Ingresa tu Departamento</span
                    >
                  </form-label>
                </div>
                <div class="mt-[15px] xl:mt-[0px]">
                  <form-label :title="'Ciudad'">
                    <select
                      class="block w-full rounded form-select"
                      v-model="location"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !location
                      }"
                    >
                      <option disabled v-if="activeDepartmentLocations.length <= 0" :value="null"
                      >Ciudad no encontrada</option
                      >
                      <option disabled v-else :value="null">Seleccione Ciudad</option>
                      <option
                        v-for="departmentLocation in activeDepartmentLocations"
                        :key="departmentLocation.id"
                        :value="departmentLocation.id"
                      >{{ departmentLocation.description }}</option
                      >
                    </select>
                    <span class="error" v-if="submited && !location"
                    >Por favor Ingresa tu Ciudad</span
                    >
                  </form-label>
                </div>
              </div>
              <div class="grid grid-cols-1 xl-grid-cols-2 grid-gap-4 mt-[15px] mb-[30px] xl:mt-[0px] xl:mb-[15px]">
                <div class="mt-4">
                  <form-label :title="'Email'">
                    <input
                      v-model="email"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !validateEmail()
                      }"
                      class="block w-full form-input"
                      type="text"
                      placeholder="Ingresa tu correo electrónico"
                    />
                    <span class="error" v-if="submited && !email">Por favor ingrese su correo</span>
                    <span class="error" v-if="submited && !validateEmail() && email"
                    >Ingrese un correo válido</span
                    >
                  </form-label>
                </div>
                <div class="mt-[15px] xl:mt-[16px]">
                  <form-label :title="'Número de identidad'">
                    <input
                      v-model="identificationNumber"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !identificationNumber
                      }"
                      class="block w-full form-input"
                      type="text"
                      placeholder="Ingresa tu número de identidad"
                    />
                    <span class="error" v-if="submited && !identificationNumber">Por favor ingresa tu número de identidad</span>
                  </form-label>
                </div>
              </div>
            </fieldset>
            <label class="flex items-center mt-5">
              <div class="mx-auto">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="acceptPolicy"
                  value="true"
                  v-bind:class="{
                    'bg-red-100 border-red-700 OnErrorScrollHere': submited && acceptPolicy != true
                  }"
                />
                <span class="ml-2 font-normal font-body"
                >He leído y acepto la
                  <router-link :to="{ name: 'TermsCondition' }" class="underline" target="_blank"
                  >política de privacidad y condiciones</router-link
                  ></span
                >
              </div>
            </label>
            <label class="flex items-center">
              <span class="error" v-if="submited && acceptPolicy != true"
              >Debe aceptar las politicas de privacidad y condiciones para registrarse</span
              >
            </label>

            <div class="w-full flex justify-center">
              <div class="w-[100%] xl:w-[40%] flex flex-col items-center">
                <rounded-button :onClick="onSubmit" :disabled="processingRegister">
                  Crear perfil cliente
                </rounded-button>
                <div class="w-max flex items-center max-xl:flex-col justify-between mt-[21px]">
                  <span>¿Necesitas ayuda?</span>
                  <div>
                    <whatsapp-icon class="mx-[6px] inline-block"/>
                    <custom-link :href="'#'">0981888777</custom-link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
</template>

<script>
import {RoundedButton} from "@/components/v2/buttons";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import validateEmail from "@/utils/methods/validateEmail";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
import GetDepartments from "@/utils/methods/GetDepartments";
import GetDepartmentLocations from "@/utils/methods/GetDepartmentLocations";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Swal from "sweetalert2";
import {WhatsappIcon} from "@/components/v2/icons";
import { FormLabel } from "@/components/v2/forms";
import CustomLink from "@/components/v2/Link.vue"
import Tooltip from "@/components/v2/Tooltip.vue";
export default {
  data() {
    return {
      colorMessage: "#66A7E5",
      submited: false,
      email: "",
      location: null,
      identificationNumber: null,
      acceptPolicy: false,
      registerError: false,
      processingRegister: false,
      department: null,
      departments: [],
      departmentLocations: [],
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.$store.dispatch("setShowHeaderBackButton", true);
    this.$store.dispatch("setShowHeaderCancelButton", true);
    this.GetDepartments();
    this.$store.dispatch("saveRegisterClientCurrentStep", 2);
  },
  beforeDestroy() {
    this.$store.dispatch("setShowHeaderBackButton", false);
    this.$store.dispatch("setShowHeaderCancelButton", false);
    this.$store.dispatch("setIsErrorAlert", false);
    this.$store.dispatch("setShowAlert", false);
  },
  methods: {
    GetDepartments,
    GetDepartmentLocations,
    validateEmail,
    onNextStep(e) {
      e.preventDefault();
      this.submited = true;
      this.processing = true;
      
      if (this.validateForm()) {
        this.$store.dispatch("hideMessageLink");
        this.$store.dispatch("saveRegisterClientTempData", {
          email: this.email,
          location_id: this.location,
          identificationNumber: this.identificationNumber,
        }).then(() => {
          this.$router.push({ name: 'ValidateUser' })
        })
      } else {
        
      }
      this.processing = false;
    },
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingRegister = true;
      if (this.validateForm()) {
        this.$store.dispatch("hideMessageLink");
        this.$store
          .dispatch("createClient", {
            ...this.RegisterClientTempData,
            email: this.email,
            location_id: this.location,
            identificationNumber: this.identificationNumber.replace(/\./g, ''),
          })
          .then(() => {
            this.submited = false;
            this.processingRegister = false;
            this.$router.push({ name: 'ValidateUser' })
            Swal.fire({
              title: "",
              icon: "success",
              padding: "1.25em 3em",
              html: `Gracias por registrarte en Hand!.
Te enviamos un código de validación a tu whatsapp (esto es solo por única vez).`,
              confirmButtonText: "Continuar",
              allowOutsideClick: false
            }).then(result => {
              if (result.value) {
                this.$store.dispatch("removeRegisterClientTempData");
                this.$store.dispatch("removeRegisterClientCurrentStep");
              }
            });
          })
          .catch(() => {
            this.processingRegister = false;
            let errorMessage = "";
            if (this.error.response.data.provider) {
              if (this.error.response.data.provider == "mail") {
                this.$store.dispatch("setIsShowMailLogin", true);
                errorMessage =
                  "Este teléfono ya ha sido registrado manualmente, por favor elige otro o inicie sesión ";
              } else if (this.error.response.data.provider == "facebook") {
                this.$store.dispatch("setIsShowFacebookLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado por facebook, por favor inicie sesión ";
              } else if (this.error.response.data.provider == "google") {
                this.$store.dispatch("setIsShowGoogleLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado por google, por favor inicie sesión ";
              }
            } else {
              if (this.error.response.data.errors) {
                errorMessage = this.error.response.data.errors[0].msg
              } else {
                errorMessage = this.error.response.data.msg
              }
            }

            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
          });
      } else {
        this.processingRegister = false;
      }
    },
    validateForm() {
      return (
        this.email != "" &&
        this.validateEmail() == true &&
        this.department != null &&
        this.location != null &&
        this.acceptPolicy == true
      );
    }
  },
  computed: {
    ...mapGetters(["error", "RegisterClientTempData"]),
    activeDepartments,
    activeDepartmentLocations
  },
  components: {
    Footer,
    FormLabel,
    Messages,
    Breadcrumb,
    VuePhoneNumberInput,
    Facebook,
    Google,
    RoundedButton,
    WhatsappIcon,
    CustomLink,
    Tooltip
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
.whatsapp-contact-icon {
  top: 2px;
}
</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>
