import store from "@/store";
import redirectBasedRole from "./redirect-base-role";
const ifAuthenticated = (to, from, next) => {


  if (!store.getters.isAuthenticated) {
    return next({
      name: "LoginClient",
      query: { redirect: to.fullPath }
    });
  }

  if(to.meta.permission!==store.getters.user.userType)
  {
    return redirectBasedRole(next);
  }

  return next();

};
export default ifAuthenticated;
