<template>
<div>
  <professional-request-item />
</div>
</template>
<script>
import ProfessionalRequestItem from "./Item/index.vue"
export default {
  components:{
    ProfessionalRequestItem
  }
}
</script>
