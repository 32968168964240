<template>
  <router-view></router-view>
</template>

<script>

export default {
  data() {
    return {
      route: this.$router.history.current.path
    }
  }
}
</script>
