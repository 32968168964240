<template>
  <div class="w-screen h-screen p-3">
    <input type="file" @change="onSelectFile">
    {{response}}
  </div>
</template>
<script>
import {Files} from "@/services";

export default {
  data()
  {
    return {
      response:{}
    }
  },
  methods:{
    onSelectFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      const data = new FormData()
      data.append("image",files[0])
      Files.upload(data)
        .then((response)=>{
          console.log(response)
          this.response = response
        })
        .catch(error=>{
          console.log(error)
          this.response = error
        })
      //console.log(e)

    },
  }
}
</script>
