<template>
  <section class="px-2 lg:w-8/12">
    <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
      <h4 class="mb-3 lg:mt-0 lg:mb-0">
        <svg class="-mt-2 icon">
          <use
            href="../../assets/img/icons.svg#icon-todo"
            xlink:href="../../assets/img/icons.svg#icon-todo"
          />
        </svg>

        Solicitud de transferencia de servicios
      </h4>
    </div>
    <div v-if="transferRequests.length > 0">
      <div
        class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
        v-for="(transferRequest, index) in transferRequests"
        :key="index"
      >
        <header
          class="flex items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white border-b border-gray-200"
        >
          <div class="flex flex-col lg:flex-row">
            <strong>Estado:</strong>
            <span
              class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
              v-if="transferRequest.status == 'complete'"
              >Concretado</span
            >
            <span class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary" v-else>Pendiente</span>
          </div>
        </header>

        <div class="flex flex-col justify-between pt-4 lg:flex-row">
          <figure class="flex items-center lg:mb-0 lg:w-2/12 lg:mr-4 lg:flex-col">
            <img
              :src="transferRequest.Professional.pictureProfileUrl"
              :alt="
                transferRequest.Professional.firstName + ' ' + transferRequest.Professional.lastName
              "
              class="object-cover shadow avatar avatar--xl"
            />
            <figcaption
              class="pl-2 text-2xl text-center text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
            >
              {{ transferRequest.Professional.firstName }}
              {{ transferRequest.Professional.lastName }}
            </figcaption>
          </figure>

          <hr class="my-3 -mx-5 border-gray-200 lg:hidden" />

          <div class="flex-1">
            <div class="flex flex-col -mx-2 lg:flex-row">
              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Servicio solicitado:</h6>
                <ul class="mb-3 ml-8 list-disc">
                  <li v-for="(Service, index) in transferRequest.Services" :key="index">
                    {{ Service.description }}
                  </li>
                </ul>
                <h6 class="mb-0">Orden:</h6>
                <p>{{ transferRequest.id }}</p>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-0">Solicitado el:</h6>
                <p>{{ moment(transferRequest.createdOn).format("DD/MM/YYYY") }}</p>
                <h6 class="mb-0">Agendado para:</h6>
                <p>
                  {{ moment(transferRequest.serviceScheduledDate).format("DD/MM/YYYY - HH:MM") }}hs
                </p>
                <h6 class="mb-0">Precio:</h6>
                <p>Gs.{{ totalServiceCost(transferRequest.Services) | thousandSeparator }}</p>
              </div>

              <div class="px-2 w-1/1 lg:w-1/3">
                <h6 class="mb-2">Created By:</h6>
                <figure class="flex lg:flex-col">
                  <img
                    :src="transferRequest.User.pictureProfileUrl"
                    :alt="transferRequest.User.firstName + ' ' + transferRequest.User.lastName"
                    class="object-cover shadow avatar avatar--xl"
                  />
                  <figcaption
                    class="pl-2 text-2xl text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
                  >
                    {{ transferRequest.User.firstName }} {{ transferRequest.User.lastName }}
                  </figcaption>
                </figure>
              </div>
            </div>
            <h6 class="mb-0">Información adicional:</h6>
            <p>{{ transferRequest.description }}</p>
            <h6>Transfer Reciept:</h6>
            <div class="grid mb-4 grid-columns-2 grid-gap-2" v-if="transferRequest.transferReceipt">
              <figure class="reciept-container relative border border-gray-200 rounded shadow">
                <img :src="transferRequest.transferReceipt" class="w-full" />
                <div class="reciept-overlay">
                  <div
                    class="grid mb-4 grid-columns-2 grid-gap-2 h-full justify-center items-center"
                  >
                    <div class="text-center">
                      <img
                        role="button"
                        src="../../assets/img/icon-file-preview.png"
                        class="inline-block pointer"
                        @click="viewFullImage(transferRequest.transferReceipt)"
                      />
                    </div>
                    <div class="text-center">
                      <a :href="transferRequest.transferReceipt" role="button" download>
                        <img
                          src="../../assets/img/icon-download.png"
                          class="inline-block pointer"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </figure>
            </div>
          </div>
        </div>

        <footer
          class="relative flex flex-col items-center px-5 py-2 mt-3 -mx-5 text-xs bg-gray-100 border-t lg:flex-row bordera-gray-200"
        >
          <div class="pb-12 lg:pb-0 lg:pr-24">
            <span class="pr-1 text-brand-secondary">(*)</span>Aceptado la solicitud, el prefesional
            se pondrá en contacto con usted, para coordinar el servicio, habilitando el botón de
            pago una vez que haya concluido el servicio solicitado
          </div>
          <div class="absolute bottom-0 flex-none w-full lg:relative lg:w-auto">
            <button
              class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success tooltip"
              @click="markAsPaid(transferRequest.id)"
              :disabled="processingMarkAsPaid"
            >
              Aprobar transferencia
            </button>
          </div>
        </footer>
      </div>
    </div>
    <div class="container msg-section" v-else>
      <section
        class="flex flex-col items-center md:flex-row alert alert--success shadow alert--info"
      >
        <div class="md:pr-6 mb-2">
          <svg class="text-6xl text-blue-800 icon md:text-5xl">
            <use
              href="../../assets/img/icons.svg#icon-alert-circle-i"
              xlink:href="../../assets/img/icons.svg#icon-alert-circle-i"
            />
          </svg>
        </div>
        <div class="text-center md:text-left">
          <p class="text-sm">No se encontró ninguna solicitud de transferencia pendiente.</p>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AdminProfileSidebar from "@/components/AdminProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import totalServiceCost from "@/utils/methods/totalServiceCost";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      processingTransferRequests: false,
      transferRequests: {},
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetTransferRequests();
  },
  methods: {
    GetTransferRequests() {
      this.processingServiceRequests = true;
      this.$store
        .dispatch("GetTransferRequests")
        .then((response) => {
          this.transferRequests = response;
          this.processingTransferRequests = false;
        })
        .catch(() => {
          this.processingTransferRequests = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    viewFullImage(img) {
      Swal.fire({
        imageUrl: img,
        padding: "0px",
        imageAlt: "Preview Image",
        showConfirmButton: false,
        showCloseButton: true,
      });
    },
    markAsPaid(serviceId) {
      this.processingMarkAsPaid = true;
      this.$store
        .dispatch("markAsPaid", { serviceId: serviceId })
        .then(() => {
          this.processingMarkAsPaid = false;
          this.GetTransferRequests();
          let registerMessage = `Aprobar la transferencia se realizó correctamente`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", registerMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingMarkAsPaid = false;
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    totalServiceCost,
  },
  filters: {
    thousandSeparator,
  },
  computed: {
    ...mapGetters(["error", "user"]),
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    AdminProfileSidebar,
  },
};
</script>
<style lang="css" scoped>
@import "../../assets/css/dashboard.css";
.success {
  color: #1bb01b;
}
.reciept-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(255, 255, 255, 0.8);
}

.reciept-container:hover .reciept-overlay {
  opacity: 1;
}
</style>
