<template>
  <section class="px-2 lg:w-8/12">
    <Export :type="'service_request'"/>
    <SearchFilters @filters-updated="listServiceRequests" />
    <div class="w-full flex justify-center mt-3">
      <i class="font-medium text-xl">{{totalItems}} resultados en total</i>
    </div>
    <Paginator
      :currentPage="currentPage"
      :totalPages="totalPages"
      :onClickPage="listServiceRequests"
      :showPrevBtn="showPrevBtn"
      :showNextBtn="showNextBtn"
    />
    <template v-for="serviceRequest in serviceRequests">
      <ServiceRequestItem
        :key="serviceRequest.id"
        :serviceRequest="serviceRequest"
        @show-details="() => showServiceRequestDetails(serviceRequest)"
      />
    </template>
    <template v-if="isLoading">
      <ListSkeleton />
    </template>
    <template v-if="error">
      <ErrorFeedback />
    </template>
    <template v-if="showModalSelectProfessional">
      <ModalSelectProfessional
        :onSelectProfessional="onSelectProfessional"
        @close-modal="showModalSelectProfessional = false"
        :list-professionals-service="listProfessionalsForChange"
        :paginate="true"
        :disabled_message="'No compatible con la orden de trabajo'"
      />
    </template>
    <template v-if="showModalServiceRequestDetails">
      <ModalServiceRequestDetails
        @close-modal="showModalServiceRequestDetails = false"
        :serviceRequest="service_request"
        :onRequestChangeProfessional="() => selectProfessional({ service_request })"
      />
    </template>
    <Paginator
      :currentPage="currentPage"
      :totalPages="totalPages"
      :onClickPage="listServiceRequests"
      :showPrevBtn="showPrevBtn"
      :showNextBtn="showNextBtn"
    />
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AdminProfileSidebar from "@/components/AdminProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import scrollToSection from "@/utils/methods/scrollToSection";
import { Professionals, ServiceRequests } from "@/services";
import ServiceRequestItem from "./List/Item/index.vue";
import ListEmptyFeedback from "./EmptyFeedback.vue";
import ListSkeleton from "./List/ListSkeleton.vue";
import ListFilter from "./List/ListFilter.vue";
import { Paginator } from "@/components";
import ModalSelectProfessional from "@/views/admin/ModalSelectProfessional/index.vue";
import Swal from "sweetalert2";
import ModalServiceRequestDetails from "./List/Item/ModalDetails.vue";
import SearchFilters from "./SearchFilters.vue";
import statusTranslations from "@/utils/helpers/ServiceRequest/statusTranslations";
import dayjs from "dayjs";
import ErrorFeedback from "./ErrorFeedback.vue";
import Export from "../Export.vue";
export default {
  data() {
    return {
      isLoading: false,
      serviceRequests: [],
      currentPage: 1,
      totalPages: 0,
      showPrevBtn: false,
      showNextBtn: false,
      showModalSelectProfessional: false,
      service_request: null,
      showModalServiceRequestDetails: false,
      error: null,
      totalItems:0,
      query:"",
      queryFilters:[],
      date: '',
      states: Object.keys(statusTranslations)
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.listServiceRequests({ status: "pending" });
  },
  methods: {
    showServiceRequestDetails(serviceRequest) {
      this.service_request = serviceRequest;
      this.showModalServiceRequestDetails = true;
    },
    onSelectProfessional({ professional }) {
      this.showModalSelectProfessional = false;
      Swal.fire({
        title: "Cambiar profesional",
        icon: "question",
        html: `
          <div class="w-full flex items-center justify-center">
                <div class="flex flex-col items-center" style="filter: grayscale(100%);opacity: 0.8;">
                    <img src="${this.service_request.professional_profile.user.pictureProfileUrl}" class="rounded-full w-16 h-16" alt="">
                    <span class="font-semibold mt-2">
                        ${this.service_request.professional_profile.user.firstName} ${this.service_request.professional_profile.user.lastName}
                    </span>
                </div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-10 h-10 mx-3"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
                <div class="flex flex-col items-center" >
                    <img src="${professional.user.pictureProfileUrl}" class="rounded-full w-16 h-16" alt="">
                    <span class="font-semibold mt-2">${professional.user.firstName} ${professional.user.lastName}</span>
                </div>
          </div>
        `,
        confirmButtonText: "Confirmar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        backdrop: false,
      }).then(({ value: confirmed }) => {
        if (confirmed) {
          this.changeServiceRequestProfessional({
            service_request: this.service_request,
            professional,
          });
        }
      });
    },
    changeServiceRequestProfessional({ service_request, professional }) {
      this.isLoading = true;
      ServiceRequests.changeProfessional({
        service_request_id: service_request.id,
        professional_profile_id: professional.id,
      })
        .then(({ data }) => {
          this.$store.dispatch("setShowAlert", true);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", "Profesional cambiado");
          this.listServiceRequests({ status: "pending" });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    listProfessionalsForChange({ query = "", page = 1 }) {
      return Professionals.searchForServiceRequest({
        service_request_id: this.service_request.id,
        query,
        page,
      });
    },
    selectProfessional({ service_request }) {
      this.service_request = service_request;
      this.showModalServiceRequestDetails = false;
      this.showModalSelectProfessional = true;
    },
    listServiceRequests({
      states = this.states,
      query = this.query,
      date = this.date,
      page = 1,
      queryFilters= this.queryFilters
    }) {
      this.query = query;
      this.queryFilters = queryFilters;
      this.isLoading = true;
      this.date = date;
      this.states = states;
      ServiceRequests.all({ states, query, date, page,queryFilters })
        .then(({ data }) => {
          this.serviceRequests = data.data;
          this.currentPage = data.meta.page.current_page;
          this.totalPages = data.meta.page.last_page;
          this.showPrevBtn = data.meta.page.hasPreviousPage;
          this.showNextBtn = data.meta.page.hasNextPage;
          this.totalItems = data.meta.page.total
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    scrollToSection,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    ErrorFeedback,
    ListEmptyFeedback,
    ListSkeleton,
    Header,
    Footer,
    Messages,
    Breadcrumb,
    AdminProfileSidebar,
    ServiceRequestItem,
    ListFilter,
    Paginator,
    ModalSelectProfessional,
    ModalServiceRequestDetails,
    SearchFilters,
    Export
  },
};
</script>
<style lang="css" scoped></style>
