export default function() {
  this.$store
    .dispatch("GetMessageRequestCount")
    .then(response => {
      let oldtotalReadCount = this.totalReadCount;
      this.totalReadCount = response.result.totalReadCount;
      let oldmessageRequestCount = this.messageRequestCount;
      this.messageRequestCount = response.result.totalCount;
      if (
        this.messageRequestCount != oldmessageRequestCount ||
        this.totalReadCount != oldtotalReadCount
      ) {
        this.getMessages();
        this.$emit("refreshMessage");
      }
    })
    .catch(() => {
      let errorMessage = this.error.response.data.msg;
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", errorMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
