<template>
  <div class="w-full flex items-center border border-grayLight rounded-[4px] bg-white">
    <div class="cursor-pointer py-[6px] px-[15px] text-gray" @click="onSubtract">
        <span class="text-[24px] block leading-[24px] select-none">-</span>
    </div>
    <div class="flex-1 w-full">
        <input class="text-black text-center w-full focus:outline-none" type="number" :name="name" :value="value" :min="min" :max="max" @input="onInput">
    </div>
    <div class="cursor-pointer py-[6px] px-[15px]" @click="onAdd">
        <span class="text-[24px] block leading-[24px] select-none text-gray">+</span>
    </div>
  </div>
</template>
<script>

export default {
  props:{
    name: {
        type: String,
        default: ''
    },
    initial_value:{
        type: Number,
        default: 1
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      value: 1
    }
  },
  mounted() {
    this.value = this.initial_value
  },
  methods: {
    onInput(event) {
        this.$emit('change', event.target.value)
    },
    onSubtract() {
        if (this.value > 1) {
          this.value = this.value - 1
          this.$emit('change', this.value)   
        }
    },
    onAdd() {
      this.value = this.value + 1
      this.$emit('change', this.value)
    }
  }
}
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
