<template>
  <div id="app">
    <MainLayout />
    <a class="hand-wapp" href="https://wa.me/595986130808" target="_blank"
      ><img src="./assets/img/whatsapp.png" borde="0" alt="Whatsapp"
    /></a>
  </div>
</template>

<script>
import MainLayout from './components/MainLayout.vue';

export default {
  name: 'app',
  metaInfo() {
    return {
      title: 'Hand - ¡te da una mano!',
      meta: [
        {
          name: 'description',
          content:
            'Encontrá aquí soluciones para el hogar, electricidad, plomería, técnico de aires, y otros servicios. Contratá técnicos con mayor facilidad, confianza y seguridad.',
        },
        { property: 'og:title', content: 'Hand - ¡te da una mano!' },
        { property: 'og:site_name', content: 'Hand' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  components: {
    MainLayout,
  },
};
</script>
<style scoped>
a.hand-wapp,
a.hand-wapp:hover {
  background: #2cb742;
  padding: 10px;
  color: white;
  border-radius: 50px;
  font-weight: 600;
  display: flex;
}
.hand-wapp {
  position: fixed;
  bottom: 20px;
  left: 20px !important;
  z-index: 15;
}
</style>
