export default function() {
  this.processingGetMessage = true;
  this.$store
    .dispatch("GetMessageData", this.messageData)
    .then(response => {
      if((response.messages && response.messages.length>0 ) || this.messageData.type== 'budget') {
      this.messageDetailData = response.messages[0];
       }
       else{
         this.getServiceRequestData();
       }
      this.processingGetMessage = false;
    })
    .catch(() => {
      this.processingGetMessage = false;
      let errorMessage = this.error.response.data.msg;
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", errorMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
