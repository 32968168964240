<template>
  <div class="w-full">
    <section class="flex bg-white">
      <div class="container flex flex-col container-xl md:flex-row mt-6 md:mt-24 p-10 md:p-0 mb-48">
        <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0 mx-auto">
          <form
            method="post"
          >
            <fieldset class="mb-3">
              <h4 class="">Datos personales</h4>
              <p class="mb-6">Texto legal</p>
              <div class="grid grid-cols-1 xl-grid-cols-2 lg:grid-gap-4">
                <div class="mt-[15px] xl:mt-[0px]">
                  <form-label :title="'Departamento'">
                    <select
                      class="block w-full form-input"
                      v-model="department"
                      @change="GetDepartmentLocations()"
                    >
                      <option disabled v-if="activeDepartments.length <= 0" :value="null"
                        >Departamento no encontrado</option
                      >
                      <option disabled v-else :value="null">Seleccionar Departamento</option>
                      <option
                        v-for="department in activeDepartments"
                        :key="department.id"
                        :value="department.id"
                        >{{ department.description }}</option
                      >
                    </select>
                    <span class="error" v-if="submited && !department">Por favor Ingresa tu Departamento</span>
                  </form-label>
                </div>
                <div class="mt-[15px] xl:mt-[0px]">
                  <form-label :title="'Ciudad'">
                    <select
                      class="block w-full form-input"
                      v-model="location"
                    >
                      <option disabled v-if="activeDepartmentLocations.length <= 0" :value="null"
                        >Ciudad no encontrada</option
                      >
                      <option disabled v-else :value="null">Seleccione Ciudad</option>
                      <option
                        v-for="departmentLocation in activeDepartmentLocations"
                        :key="departmentLocation.id"
                        :value="departmentLocation.id"
                        >{{ departmentLocation.description }}</option
                      >
                    </select>
                    <span class="error" v-if="submited && !location">Por favor Ingresa tu Ciudad</span>
                  </form-label>
                </div>
              </div>
              <div class="grid grid-cols-1 xl-grid-cols-2 lg:grid-gap-4 mt-[15px] mb-[30px] xl:mt-[0px] xl:mb-[15px]">
                <div class="mt-4">
                  <form-label :title="'Número de identidad'">
                    <template #tooltip>
                      <Tooltip message="Agregar el número de la cédula de identidad sin puntos">
                        <img src="@/assets/v2/svg/info.svg" class="inline-block info-tooltip"/>
                      </Tooltip>
                    </template>
                    <input
                      v-model="identificationNumber"
                      class="block w-full mt-1 form-input"
                      type="text"
                      placeholder="Ingresa tu número de identidad"
                    />
                    <span class="error" v-if="submited && !identificationNumber">Por favor ingresa tu número de identidad</span>
                  </form-label>
                </div>
                <div class="mt-[15px] xl:mt-[16px]">
                  <form-label :title="'Fecha de nacimiento'">
                    <input
                      v-model="birthDate"
                      class="block w-full mt-1 form-input"
                      type="text"
                      placeholder="XX/XX/XXXX"
                    />
                    <span class="error" v-if="submited && !birthDate">Por favor ingrese su fecha de nacimiento</span>
                    <span class="error" v-if="submited && !validateDate(birthDate)">
                      La fecha debe tener el siguiente formato dd/mm/yyyy
                    </span>
                  </form-label>
                </div>
              </div>
            </fieldset>

            <div class="w-full flex justify-center">
              <div class="w-[100%] xl:w-[40%] flex flex-col items-center">
                <rounded-button :disabled="processing" :onClick="onNextStep">
                  Siguiente
                </rounded-button>
                <div class="w-max flex items-center max-xl:flex-col justify-between mt-[21px]">
                  <span>¿Necesitas ayuda?</span>
                  <div>
                    <whatsapp-icon class="mx-[6px] inline-block"/>
                    <custom-link :href="'#'">0981888777</custom-link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Tooltip from "@/components/v2/Tooltip.vue";
import { mapGetters } from "vuex";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
import GetDepartments from "@/utils/methods/GetDepartments";
import GetDepartmentLocations from "@/utils/methods/GetDepartmentLocations";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import { RoundedButton } from "@/components/v2/buttons"
import { FormLabel } from "@/components/v2/forms";
import validateDate from "@/utils/methods/validateDate";
import formatDate from "@/utils/methods/validateDate";
import {WhatsappIcon} from "@/components/v2/icons";
import CustomLink from "@/components/v2/Link.vue"
export default {
  data() {
    return {
      submited: false,
      processing: false,
      location: null,
      identificationNumber: null,
      birthDate: null,
      department: null,
      departments: [],
      departmentLocations: [],
    };
  },
  mounted() {
    if (!this.RegisterProfessionalTempData) {
      this.$router.push({ name: 'RegisterProfessionalStep1' })
    }

    this.$store.dispatch("setShowAlert", false);
    this.$store.dispatch("setShowHeaderBackButton", true);
    this.$store.dispatch("setShowHeaderCancelButton", true);
    this.$store.dispatch("saveRegisterProfessionalCurrentStep", 2);
    this.GetDepartments();
  },
  beforeDestroy() {
    this.$store.dispatch("setShowHeaderBackButton", false);
    this.$store.dispatch("setShowHeaderCancelButton", false);
  },
  methods: {
    validateDate,
    GetDepartments,
    GetDepartmentLocations,
    onNextStep(e) {
      e.preventDefault();
      this.submited = true;
      this.processing = true;
      
      if (this.validateForm()) {
        this.$store.dispatch("saveRegisterProfessionalTempData", {
          ...this.RegisterProfessionalTempData,
          location_id: this.location,
          identificationNumber: this.identificationNumber,
          birthDate: this.formatBirthDate(this.birthDate),
          userType: "professional"
        }).then(() => {
          this.$router.push({ name: 'RegisterProfessionalStep3' })
        })
      }

      this.processing = false;
    },
    validateForm() {
      return (
        this.department != null &&
        this.location != null &&
        this.identificationNumber != "" &&
        this.birthDate != "" &&
        this.validateDate(this.birthDate)
      );
    },
    formatBirthDate(date) {
      const splitDate = date.split("/")
      const formatted = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
      
      return formatted
    }
  },
  computed: {
    ...mapGetters(["RegisterProfessionalTempData"]),
    activeDepartments,
    activeDepartmentLocations
  },
  components: {
    Facebook,
    Google,
    Tooltip,
    RoundedButton,
    FormLabel,
    WhatsappIcon,
    CustomLink
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
.whatsapp-contact-icon {
  top: 2px;
}
</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>
