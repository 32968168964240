var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col items-center"},[_c('img',{ref:"profilePicture",staticClass:"rounded-full mr-3 w-1/2",attrs:{"src":!!_vm.client.pictureProfileUrl
        ? _vm.client.pictureProfileUrl
        : '/img/default-user-icon.4aa4dc7e.jpg',"alt":""}}),_c('div',{staticClass:"w-full"},[_c('fieldset',[_c('label',[_c('span',[_vm._v("Nombres")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.names),expression:"names"}],staticClass:"form-input mt-1 block w-full",attrs:{"type":"text"},domProps:{"value":(_vm.names)},on:{"input":function($event){if($event.target.composing)return;_vm.names=$event.target.value}}})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('label',[_c('span',[_vm._v("Teléfono 1")]),_c('VuePhoneNumberInput',{staticClass:"mt-1 bg-red-100 border-red-700 OnErrorScrollHere'",attrs:{"color":"#63b3ed","valid-color":"#63b3ed","error-color":"#63b3ed","default-country-code":"PY","translations":{
            countrySelectorLabel: 'Prefijo',
            countrySelectorError: 'Elija un prefijo',
            phoneNumberLabel: 'Ingresa tu número',
            example: 'Ejemplo :',
          },"no-example":true,"show-code-on-list":true}})],1),_c('label',[_c('span',[_vm._v("Teléfono 2")]),_c('VuePhoneNumberInput',{staticClass:"mt-1 bg-red-100 border-red-700 OnErrorScrollHere'",attrs:{"color":"#63b3ed","valid-color":"#63b3ed","error-color":"#63b3ed","default-country-code":"PY","translations":{
            countrySelectorLabel: 'Prefijo',
            countrySelectorError: 'Elija un prefijo',
            phoneNumberLabel: 'Ingresa tu número',
            example: 'Ejemplo :',
          },"no-example":true,"show-code-on-list":true}})],1),_vm._m(4),_c('label',[_c('span',[_vm._v("Ciudad")]),_c('select',{staticClass:"block w-full mt-1 rounded form-select"},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccionar ciudad")]),_vm._l(([]),function(department){return _c('option',{key:department.id,domProps:{"value":department.id}},[_vm._v(" "+_vm._s(department.description)+" ")])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',[_vm._v("Apellidos")]),_c('input',{staticClass:"form-input mt-1 block w-full",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',[_vm._v("Cédula de identidad")]),_c('input',{staticClass:"form-input mt-1 block w-full",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',[_vm._v("R.U.C")]),_c('input',{staticClass:"form-input mt-1 block w-full",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',[_vm._v("Email")]),_c('input',{staticClass:"form-input mt-1 block w-full",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',[_vm._v("Dirección")]),_c('textarea',{staticClass:"form-input mt-1 block w-full"})])
}]

export { render, staticRenderFns }