<template>
  <aside class="px-2 mb-4 lg:w-3/12">
    <nav class="lg:-mt-4">
      <ul>
        <template v-for="(link, i) in links">
          <li :key="i">
            <router-link
              :to="link.route"
              :class="[
                $route.name == link.route.name || $route.path == link.route.path
                  ? 'text-brand-secondary'
                  : 'text-black',
                'flex items-center justify-between py-4',
              ]"
            >
              <span>{{ link.title }}</span>
              <chevron-right-icon
                :color="
                  $route.name == link.route.name || $route.path == link.route.path
                    ? '#ff783e'
                    : '#000'
                "
              />
            </router-link>
          </li>
        </template>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { ChevronRightIcon } from "@/components/icons";
export default {
  props: {
    msg: String,
    links: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ChevronRightIcon,
  },
};
</script>
