import Vue from 'vue';
import Router from 'vue-router';
import MainLayout from '@/components/MainLayout.vue';
import Welcome from '@/views/Welcome.vue';
import Login from '@/views/Login.vue';
import RegisterClientOld from '@/views/RegisterClient.vue';

import RegisterClient from "@/views/v2/client/auth/register/index.vue";
import RegisterClientStep1 from "@/views/v2/client/auth/register/Step1.vue";
import RegisterClientStep2 from "@/views/v2/client/auth/register/Step2.vue";
import ValidateUser from "@/views/v2/ValidateUser.vue";
import RegisterProfessional from "@/views/v2/professional/auth/register/index.vue";
import RegisterProfessionalStep1 from "@/views/v2/professional/auth/register/Step1.vue";
import RegisterProfessionalStep2 from "@/views/v2/professional/auth/register/Step2.vue";
import RegisterProfessionalStep3 from "@/views/v2/professional/auth/register/Step3.vue";
import RegisterProfessionalCompleteStep from "@/views/v2/professional/auth/register/CompleteStep.vue";
import RecoverPassword from '@/views/RecoverPassword.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ActivateUser from '@/views/ActivateUser.vue';
import SearchProfessionals from '@/views/SearchProfessionals.vue';
import ProfessionalsProfile from '@/views/ProfessionalsProfile.vue';
import OldProfessionalsProfile from '@/views/OldProfessionalsProfile.vue';
import ProfessionalsReview from '@/views/ProfessionalsReview.vue';
import ClientProfile from '@/views/ClientProfile.vue';
import ServiceRequest from '@/views/ServiceRequest.vue';
import PaymentResult from '@/views/PaymentResult.vue';
import BudgetRequest from '@/views/BudgetRequest.vue';
import RegisterProfessionals from '@/views/RegisterProfessionals.vue';
import ProfessionalRegister from '@/views/ProfessionalRegister.vue';
import Message from '@/views/Message.vue';
import MessageResponder from '@/views/MessageResponder.vue';
import TermsCondition from '@/views/TermsCondition.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import WhoWeAre from '@/views/WhoWeAre.vue';
import NewPortal from '@/views/NewPortal.vue';
import Help from '@/views/Help.vue';
import ProfessionalRegisterComplete from '@/views/ProfessionalRegisterComplete.vue';
import ClientFiles from "@/views/client/ClientFiles.vue";
import Layout from "@/components/v2/Layout.vue";
import LoginClient from "@/views/v2/client/auth/Login.vue"
import LoginProfessional from "@/views/v2/professional/auth/Login.vue"
import ProfessionalCompleteProfile from "@/views/v2/professional/complete-profile/index.vue"
import Components from "@/views/v2/Components.vue"
/* professional route component start*/
import ProfessionalServiceRequest from '@/views/professional/ServiceRequest.vue';
import ProfessionalBudgetRequest from '@/views/professional/BudgetRequest.vue';
import ProfessionalProfile from '@/views/professional/Profile.vue';
import ProfessionalMessage from '@/views/professional/Message.vue';
import ProfessionalMessageResponder from '@/views/professional/MessageResponder.vue';
import EditProfessionService from '@/views/professional/EditProfessionService.vue';
import EditProfessionPhoto from '@/views/professional/EditProfessionPhoto.vue';
import ProfessionalContract from '@/views/professional/ProfessionalContract.vue';
import AppRedirect from '@/views/AppRedirect.vue';
import { AdminRoutes } from '../router/routes';
import {
  ifAuthenticated,
  ifNotAuthenticated,
  isProfessional
} from './middlewares';

export default new Router({
  mode: 'history',
  routes: [
    ...AdminRoutes,
    {
      path: '/',
      component: MainLayout,
      children: [
        {
          path: '/',
          name: 'Home',
          component: Welcome,
          beforeEnter: isProfessional,
        },
        {
          path: '/app-redirect',
          name: 'AppRedirect',
          component: AppRedirect
        },
        {
          path:'/components',
          name:'Components',
          component: Components
        },
        {
          path: '/register-client',
          name: 'RegisterClientOld',
          component: RegisterClientOld,
          beforeEnter: ifNotAuthenticated,
          meta: {
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Registrarme', islast: true },
            ],
          },
        },
        {
          path: '/recover-password/:tid',
          name: 'RecoverPassword',
          component: RecoverPassword,
        },
        {
          path: '/forgot',
          name: 'ForgotPassword',
          component: ForgotPassword,
          beforeEnter: ifNotAuthenticated,
          meta: {
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Recuperar contraseña', islast: true },
            ],
          },
        },
        {
          path: '/activate/:tid',
          props: true,
          name: 'ActivateUser',
          component: ActivateUser,
        },
        {
          path: '/professionals/search',
          name: 'ProfessionalsSearch',
          component: SearchProfessionals,
          beforeEnter: isProfessional,
          meta: {
            breadcrumb: [{ name: 'Inicio', link: '/' }],
          },
        },
        {
          path: '/old-professionals/complete-profile/:pid',
          name: 'OldProfessionalsProfile',
          component: OldProfessionalsProfile,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [{ name: 'Inicio', link: '/' }],
          },
        },
        {
          path: '/professionals/complete-profile/:proid/:pid',
          name: 'ProfessionalsProfile',
          component: ProfessionalsProfile,
          meta: {
            permission: 'client',
            breadcrumb: [{ name: 'Inicio', link: '/' }],
          },
        },
        {
          path: '/professionals/review/:stid',
          name: 'ProfessionalsReview',
          component: ProfessionalsReview,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [{ name: 'Inicio', link: '/' }],
          },
        },
        {
          path: '/client-complete-profile',
          name: 'ClientProfile',
          component: ClientProfile,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: '/service-request',
          name: 'ServiceRequest',
          component: ServiceRequest,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: '/service-request/:id',
          name: 'ServiceRequestSection',
          component: ServiceRequest,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: '/payment/result/:hash',
          props: true,
          name: 'ServiceRequestResult',
          component: PaymentResult,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: '/old-register-professional',
          name: 'RegisterProfessionals',
          component: RegisterProfessionals,
          beforeEnter: ifNotAuthenticated,
          meta: {
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Registrarme', islast: true },
            ],
          },
        },
        {
          path: '/register-professional',
          name: 'ProfessionalRegister',
          component: ProfessionalRegister,
          beforeEnter: ifNotAuthenticated,
          meta: {
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Registrarme', islast: true },
            ],
          },
        },
        {
          path: "/registro-profesionales",
          name: "ProfessionalsRegister",
          component: ProfessionalRegister,
          beforeEnter: ifNotAuthenticated,
          meta: {
            breadcrumb: [
              { name: "Inicio", link: "/" },
              { name: "Registrarme", islast: true }
            ]
          }
        },
        {
          path: "/registro-profesionales-complete/:tid",
          name: "ProfessionalRegisterComplete",
          component: ProfessionalRegisterComplete,
          beforeEnter: ifNotAuthenticated,
          meta: {
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Registrarme', islast: true },
            ],
          },
        },
        {
          path: '/registro-profesionales-complete/:tid/:step',
          name: 'ProfessionalRegisterCompleteStep',
          component: ProfessionalRegisterComplete,
          beforeEnter: ifNotAuthenticated,
          meta: {
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Registrarme', islast: true },
            ],
          },
        },
        {
          path: '/budget-request/:id',
          name: 'BudgetRequestSection',
          component: BudgetRequest,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: '/message',
          name: 'Message',
          component: Message,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: '/message-responder/:otype/:oid',
          name: 'MessageResponderWithoutStatus',
          component: MessageResponder,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: '/message-responder/:otype/:oid/:ostatus',
          name: 'MessageResponder',
          component: MessageResponder,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },

        {
          path: '/board/flight',
          name: 'Dashboard',
          component: Welcome,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'client',
          },
        },
        {
          path: '/files',
          name: 'Files',
          component: ClientFiles,
          meta: {
            permission: 'client',
          },
        },
        {
          path: '/terminos-condiciones',
          name: 'TermsCondition',
          component: TermsCondition,
        },
        {
          path: '/politicas-de-privacidad',
          name: 'PrivacyPolicy',
          component: PrivacyPolicy,
        },
        {
          path: '/new-portal',
          name: 'NewPortal',
          component: NewPortal,
        },
        {
          path: '/help',
          name: 'Help',
          component: Help,
        },
        {
          path: '/who-we-are',
          name: 'WhoWeAre',
          component: WhoWeAre,
        },
      ],
    },
    {
      path: '/login',
      component: Layout,
      children: [
        {
          path: 'client',
          name: 'LoginClient',
          component: LoginClient,
          beforeEnter: ifNotAuthenticated,
          meta: {
            headerLeftSection: {
              title: "Portal cliente",
            }
          },
        },
        {
          path: 'professional',
          name: 'LoginProfessional',
          component: LoginProfessional,
          beforeEnter: ifNotAuthenticated,
          meta: {
            headerLeftSection: {
              title: "Portal profesional",
            }
          },
        },
        {
          path: 'validate',
          name: 'ValidateUser',
          component: ValidateUser,
          beforeEnter: ifNotAuthenticated,
          meta: {
            headerLeftSection: {
              title: "Validar usuario",
            }
          },
        },
        {
          path:'/professionals/complete-profile/:token/step/:step',
          name:"ProfessionalCompleteProfile",
          component:ProfessionalCompleteProfile,
          meta: {
            headerLeftSection: {
              title: "Portal de profesionales",
            }
          },
        }
      ]
    },
    {
      path: '/register',
      component: Layout,
      children: [
        {
          path: '/register/client',
          component: RegisterClient,
          children:[
            {
              path:'',
              redirect:'1',
              name: 'RegisterClient',
            },
            {
              path:'1',
              name: 'RegisterClientStep1',
              component:RegisterClientStep1,
              beforeEnter: ifNotAuthenticated,
              meta: {
                headerLeftSection: {
                  title: "Registrate como cliente",                
                }
              },
            },
            {
              path:'2',
              name: 'RegisterClientStep2',
              component:RegisterClientStep2,
              beforeEnter: ifNotAuthenticated,
              meta: {
                headerLeftSection: {
                  title: "Registrate como cliente",                
                }
              },
            }
          ]
        },
        {
          path: '/register/professional',
          component: RegisterProfessional,
          children:[
            {
              path:'',
              redirect:'1',
              name: 'RegisterProfessional',
            },
            {
              path:'1',
              name: 'RegisterProfessionalStep1',
              component:RegisterProfessionalStep1,
              beforeEnter: ifNotAuthenticated,
              meta: {
                headerLeftSection: {
                  title: "Registrate como profesional",
                  pathName: "RegisterClient",
                }
              },
            },
            {
              path:'2',
              name: 'RegisterProfessionalStep2',
              component:RegisterProfessionalStep2,
              beforeEnter: ifNotAuthenticated,
              meta: {
                headerLeftSection: {
                  title: "Registrate como profesional",
                  pathName: "RegisterClient",
                }
              },
            },
            {
              path:'3',
              name: 'RegisterProfessionalStep3',
              component:RegisterProfessionalStep3,
              beforeEnter: ifNotAuthenticated,
              meta: {
                headerLeftSection: {
                  title: "Registrate como profesional",
                  pathName: "RegisterClient",
                }
              },
            },
            {
              path:'complete',
              name: 'RegisterProfessionalCompleteStep',
              component:RegisterProfessionalCompleteStep,
              beforeEnter: ifNotAuthenticated,
              meta: {
                headerLeftSection: {
                  title: "Registrate como profesional",
                  pathName: "RegisterClient",
                }
              },
            }
          ],
        }
      ]
    },
    {
      path: '/professional',
      component: MainLayout,
      children: [
        {
          name: 'ProfessionalDashboard',
          path: '/',
          redirect: 'service-request',
        },
        {
          path: 'service-request',
          name: 'ProfessionalServiceRequest',
          component: ProfessionalServiceRequest,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [{ name: 'Mi panel', islast: true }],
          },
        },
        {
          path: 'service-request/:id',
          name: 'ProfessionalServiceRequestSection',
          component: ProfessionalServiceRequest,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: 'budget-request',
          name: 'ProfessionalBudgetRequest',
          component: ProfessionalBudgetRequest,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [{ name: 'Mi panel', islast: true }],
          },
        },
        {
          path: 'budget-request/:id',
          name: 'ProfessionalBudgetRequestSection',
          component: ProfessionalBudgetRequest,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },

        {
          path: 'edit-complete-profile',
          name: 'ProfessionalProfile',
          component: ProfessionalProfile,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [{ name: 'Mi panel', islast: true }],
          },
        },
        {
          path: 'message',
          name: 'ProfessionalMessage',
          component: ProfessionalMessage,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [{ name: 'Mi panel', islast: true }],
          },
        },
        {
          path: 'message-responder/:otype/:oid',
          name: 'ProfessionalMessageResponderWithoutStatus',
          component: ProfessionalMessageResponder,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: 'message-responder/:otype/:oid/:ostatus',
          name: 'ProfessionalMessageResponder',
          component: ProfessionalMessageResponder,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [
              { name: 'Inicio', link: '/' },
              { name: 'Mi panel', islast: true },
            ],
          },
        },
        {
          path: 'edit-profession',
          name: 'EditProfessionService',
          component: EditProfessionService,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [{ name: 'Mi panel', islast: true }],
          },
        },
        {
          path: 'edit-photo',
          name: 'EditProfessionPhoto',
          component: EditProfessionPhoto,
          beforeEnter: ifAuthenticated,
          meta: {
            permission: 'professional',
            breadcrumb: [{ name: 'Mi panel', islast: true }],
          },
        },
        {
          path: 'contract',
          name: 'ProfessionalContract',
          component: ProfessionalContract,
        },
      ],
    },
  ],
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});
