<template>
  <select
    class="block w-full mt-1 rounded form-select"
    v-model="distance"
    @input="$emit('input', $event.target.value)"
    @change="onSelectedEvent(distance)"
  >
    <option value="5">5 km a la redonda</option>
    <option value="10">10 km a la redonda</option>
    <option value="20">20 km a la redonda</option>
    <option value="30">30 km a la redonda</option>
    <option value="40">40 km a la redonda</option>
    <option value="50">50 km a la redonda</option>
  </select>
</template>

<script>
export default {
  name: "dropdownDistance",
  data() {
    return {
      distance: "10"
    };
  },
  props: {
    value: String,
    onSelectedEvent: Function
  },

  watch: {
    value: function(newValue) {
      this.distance = newValue;
    }
  }
};
</script>
<style scoped>
@import "../assets/css/busqueda.css";
</style>
