<template>
  <svg :width="width" :height="height" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 6L0.535899 -6.52533e-07L7.4641 -4.68497e-08L4 6Z" fill="black"/>
  </svg>
</template>
<script>
export default{
  props:{
    width:{
      type:Number,
      default:8
    },
    height:{
      type:Number,
      default: 8
    }
  }
}
</script>
