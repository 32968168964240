<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <ProfessionalProfileSidebar v-if="user.userType == 'professional'" />
          <AdminProfileSidebar v-if="user.userType == 'admin'" />
          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
              <h4 class="mb-0 lg:mt-0">
                <svg class="-mt-2 icon">
                  <use
                    href="../../assets/img/icons.svg#icon-user-small"
                    xlink:href="../../assets/img/icons.svg#icon-user-small"
                  />
                </svg>
                Mis datos
              </h4>
            </div>
            <div class="hand-tab">
              <ul class="flex border-b-none list-tab">
                <li @click="selectTab('info')" :class="{ 'is-active': activeTab == 'info' }">
                  <button class="inline-block  py-2 px-4  font-semibold" href="#">
                    Información
                  </button>
                </li>
                <li @click="selectTab('service')" :class="{ 'is-active': activeTab == 'service' }">
                  <button class="inline-block py-2 px-4  font-semibold" href="#">
                    Servicios/Precios
                  </button>
                </li>
              </ul>
              <!-- Tab Content -->
              <div class="p-5 mb-6 overflow-hidden bg-white border border-gray-400">
                <div class="hand-tab-content">
                  <!-- information tab -->
                  <div v-show="activeTab == 'info'">
                    <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                      <svg class="icon">
                        <use
                          href="../../assets/img/icons.svg#icon-polaroid-user"
                          xlink:href="../../assets/img/icons.svg#icon-polaroid-user"
                        />
                      </svg>
                      Perfil
                    </h6>
                    <form @submit.prevent="updateClient" method="post">
                      <div class="flex">
                        <figure
                          class="flex items-center justify-center mr-4 shadow lg:mr-10 avatar avatar--xl"
                        >
                          <img
                            :src="clientdata.pictureProfileUrl"
                            :alt="clientdata.firstName + ' ' + clientdata.lastName"
                          />
                        </figure>

                        <div class="flex-1">
                          <label>
                            <div>
                              <input
                                class="hidden w-full mt-1 mb-2 form-input"
                                type="file"
                                name="profileImage"
                                @change="updateUserAvatar"
                                ref="file"
                              />
                            </div>
                            <div class="block w-full mt-1 mb-2 form-input">
                              <button
                                type="button"
                                @click="$refs.file.click()"
                                class="btn btn--primary"
                              >
                                Cambiar foto de perfil
                              </button>
                            </div>
                          </label>
                        </div>
                      </div>

                      <hr class="my-8 -mx-5 border-gray-200" />

                      <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                        <svg class="icon">
                          <use
                            href="../../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="../../assets/img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Datos Personales
                      </h6>

                      <div class="mb-2 grid lg:grid-columns-2 grid-gap-4">
                        <div>
                          <label class="mb-2">
                            <span>Nombre:</span>
                            <input
                              class="block w-full mt-1 form-input"
                              type="text"
                              v-model="updateClientData.firstName"
                              v-bind:class="{
                                'bg-red-100 border-red-700':
                                  submited && updateClientData.firstName == ''
                              }"
                              placeholder="Ingrese su nombre"
                            />
                            <span class="error" v-if="submited && updateClientData.firstName == ''"
                              >Por favor, introduzca su nombre</span
                            >
                          </label>
                        </div>
                        <div>
                          <label class="mb-2">
                            <span>Apellido:</span>
                            <input
                              class="block w-full mt-1 form-input"
                              v-model="updateClientData.lastName"
                              v-bind:class="{
                                'bg-red-100 border-red-700':
                                  submited && updateClientData.lastName == ''
                              }"
                              type="text"
                              placeholder="Ingrese su apellido"
                            />
                            <span class="error" v-if="submited && updateClientData.lastName == ''"
                              >Por favor ingrese su apellido</span
                            >
                          </label>
                        </div>

                        <div>
                          <label class="mb-2">
                            <span>Documento:</span>
                            <input
                              class="block w-full mt-1 form-input"
                              type="text"
                              v-model="updateClientData.identificationNumber"
                              placeholder="Ingrese su número de documento"
                            />
                          </label>
                        </div>

                        <div>
                          <label class="mb-2">
                            <span>Ruc:</span>
                            <input
                              class="block w-full mt-1 form-input"
                              type="text"
                              v-model="updateClientData.ruc"
                              placeholder="Ingrese su número de ruc"
                            />
                          </label>
                        </div>

                        <div>
                          <label class="mb-2">
                            <span>Celular:</span>
                            <VuePhoneNumberInput
                              v-model="telnumber"
                              color="#63b3ed"
                              class="mt-1"
                              v-bind:class="{
                                'bg-red-100 border-red-700 OnErrorScrollHere':
                                  submited && !isPhoneValid
                              }"
                              valid-color="#63b3ed"
                              error-color="#63b3ed"
                              :default-country-code="updateClientData.countryCode"
                              :translations="translations"
                              :no-example="true"
                              :show-code-on-list="true"
                              @update="phoneUpdate"
                            />
                            <span class="error" v-if="submited && !isPhoneValid">
                              Por favor ingrese su número
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="mb-2 grid">
                        <textarea
                          class="block w-full mx-auto form-control desc-text text-black"
                          placeholder="Descripción del servicio"
                          v-model="updateClientData.coverLetter"
                        ></textarea>
                      </div>
                      <div
                        class="mb-2 grid"
                        v-if="
                          clientdata && clientdata.Profession && clientdata.Profession.length > 0
                        "
                      >
                        <router-link
                          :to="{
                            name: 'ProfessionalsProfile',
                            params: {
                              proid: clientdata.Profession[0].id,
                              pid: encrypt(clientdata.userId)
                            }
                          }"
                          class="btn btn--primary  w-full md:w-1/4"
                        >
                          Mi perfil público
                        </router-link>
                      </div>
                      <hr class="my-8 -mx-5 border-gray-200" />
                      <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                        <svg class="icon">
                          <use
                            href="../../assets/img/icons.svg#icon-pin"
                            xlink:href="../../assets/img/icons.svg#icon-pin"
                          />
                        </svg>
                        Domicilio
                      </h6>
                      <div class="mb-2 grid lg:grid-columns-2 grid-gap-4">
                        <div>
                          <label class="mb-2">
                            <span>Calle 1:</span>
                            <input
                              class="block w-full mt-1 form-input"
                              v-model="updateClientData.address"
                              type="text"
                              placeholder="Ingrese una dirección"
                            />
                          </label>
                        </div>
                        <div>
                          <label class="block mb-2">
                            <span>Departamento:</span>
                            <select
                              class="block w-full mt-1 rounded form-select"
                              v-model="department"
                              @change="GetDepartmentLocations()"
                            >
                              <option disabled v-if="activeDepartments.length <= 0" :value="null"
                                >Departamento no encontrado</option
                              >
                              <option disabled v-else :value="null">Seleccionar Departmento</option>
                              <option
                                v-for="department in activeDepartments"
                                :key="department.id"
                                :value="department.id"
                                >{{ department.description }}</option
                              >
                            </select>
                          </label>
                        </div>
                        <div>
                          <label class="block mb-2">
                            <span>Ciudad:</span>
                            <select
                              class="block w-full mt-1 rounded form-select"
                              v-model="updateClientData.location_id"
                            >
                              <option
                                disabled
                                v-if="activeDepartmentLocations.length <= 0"
                                :value="null"
                                >Ciudad no encontrada</option
                              >
                              <option disabled v-else :value="null">Seleccione Ciudad</option>
                              <option
                                v-for="departmentLocation in activeDepartmentLocations"
                                :key="departmentLocation.id"
                                :value="departmentLocation.id"
                                >{{ departmentLocation.description }}</option
                              >
                            </select>
                          </label>
                        </div>
                      </div>
                      <hr class="my-8 -mx-5 border-gray-200" />
                      <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                        <svg class="icon">
                          <use
                            href="../../assets/img/icons.svg#icon-pin"
                            xlink:href="../../assets/img/icons.svg#icon-pin"
                          />
                        </svg>
                        Referencias
                      </h6>
                      <div class="mb-2 grid lg:grid-columns-2 grid-gap-4">
                        <div>
                          <label class="mb-2">
                            <span>Cliente referencial 1</span>
                            <input
                              class="block w-full mt-1 form-input"
                              v-model="updateClientData.laboralReferenceName1"
                              type="text"
                              placeholder="Nombre y apellido"
                            />
                          </label>
                        </div>
                        <div>
                          <label class="block mb-2">
                            <span>Teléfono cliente 1</span>
                            <input
                              class="block w-full mt-1 form-input"
                              v-model="updateClientData.laboralReferencePhone1"
                              type="text"
                              placeholder="Número de telefono"
                            />
                          </label>
                        </div>
                        <div>
                          <label class="block mb-2">
                            <span>Cliente referencial 2</span>
                            <input
                              class="block w-full mt-1 form-input"
                              v-model="updateClientData.laboralReferenceName2"
                              type="text"
                              placeholder="Nombre y apellido"
                            />
                          </label>
                        </div>
                        <div>
                          <label class="block mb-2">
                            <span>Teléfono cliente 2</span>
                            <input
                              class="block w-full mt-1 form-input"
                              v-model="updateClientData.laboralReferencePhone2"
                              type="text"
                              placeholder="Número de telefono"
                            />
                          </label>
                        </div>
                        <div>
                          <label class="block mb-2">
                            <span>Descripción cliente referencial 1</span>
                            <textarea
                              class="block w-full mx-auto form-control desc-text text-black"
                              placeholder="Descripción cliente referencial 1"
                              v-model="updateClientData.reference1Description"
                            ></textarea>
                          </label>
                        </div>
                        <div>
                          <label class="block mb-2">
                            <span>Descripción cliente referencial 2</span>
                            <textarea
                              class="block w-full mx-auto form-control desc-text text-black"
                              placeholder="Descripción cliente referencial 2"
                              v-model="updateClientData.reference2Description"
                            ></textarea>
                          </label>
                        </div>
                      </div>
                      <div class="mb-2" v-if="user.userType == 'admin'">
                        <div class="mb-2 grid lg:grid-columns-2 grid-gap-4">
                          <div>
                            <label class="block mb-2">
                              <span>Cuota de confianza y soporte %</span>
                              <input
                                class="block w-full mt-1 form-input"
                                v-model="comision"
                                type="text"
                                placeholder="Por favor ingrese la comisión"
                              />
                            </label>
                          </div>
                        </div>
                        <div class="mb-2 grid">
                          <div>
                            <label class="block mb-2">
                              <span>Obs</span>
                              <textarea
                                class="block w-full mx-auto form-control desc-text text-black"
                                v-model="observation"
                                placeholder="Obs"
                              />
                            </label>
                          </div>
                        </div>
                        <ul
                          class="mb-4 grid grid-columns-1 md:grid-gap-4 md:grid-columns-2 lg:grid-gap-4 lg:grid-columns-3"
                        >
                          <li>
                            <label class="flex items-center">
                              <input
                                type="checkbox"
                                class="form-checkbox"
                                v-model="updateDocumentRecord.hasJudicialRecords"
                                value="true"
                              />
                              <span class="ml-4 text-black">
                                Antecedentes Judiciales
                              </span>
                            </label>
                          </li>
                          <li>
                            <label class="flex items-center">
                              <input
                                type="checkbox"
                                class="form-checkbox"
                                v-model="updateDocumentRecord.hasPoliceRecords"
                                value="true"
                              />
                              <span class="ml-4 text-black">
                                Antecedentes Policiales
                              </span>
                            </label>
                          </li>
                          <li>
                            <label class="flex items-center">
                              <input
                                type="checkbox"
                                class="form-checkbox"
                                v-model="updateDocumentRecord.hasIdentificationNumber"
                                value="true"
                              />
                              <span class="ml-4 text-black">
                                Cedula de Identidad
                              </span>
                            </label>
                          </li>
                          <li>
                            <label class="flex items-center">
                              <input
                                type="checkbox"
                                class="form-checkbox"
                                v-model="updateDocumentRecord.hasLaboralReferences"
                                value="true"
                              />
                              <span class="ml-4 text-black">
                                Referencias Laboralales
                              </span>
                            </label>
                          </li>
                          <li>
                            <label class="flex items-center">
                              <input
                                type="checkbox"
                                class="form-checkbox"
                                v-model="updateDocumentRecord.hasProfessionalCertification"
                                value="true"
                              />
                              <span class="ml-4 text-black">
                                Certificación Profesional
                              </span>
                            </label>
                          </li>
                          <li>
                            <label class="flex items-center">
                              <input
                                type="checkbox"
                                class="form-checkbox"
                                v-model="updateDocumentRecord.hasAddressVerified"
                                value="true"
                              />
                              <span class="ml-4 text-black">
                                Dirección y Domicilio
                              </span>
                            </label>
                          </li>
                        </ul>
                        <div class="mb-2 grid">
                          <div>
                            <toggle-button
                              v-model="isAvailable"
                              :value="isAvailable"
                              :sync="true"
                              :color="{
                                checked: '#ff783e',
                                unchecked: '#CCCCCC',
                                disabled: '#E1F6FB'
                              }"
                              :switch-color="{ checked: '#E6E7EC', unchecked: '#E6E7EC' }"
                              :labels="{ checked: 'Disponible', unchecked: 'No disponible' }"
                              :width="115"
                              :height="40"
                              :font-size="11"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="flex justify-end">
                        <button class="w-full btn btn--success lg:w-auto">Guardar datos</button>
                      </div>
                    </form>
                  </div>
                  <!--information tab end -->
                  <!--service tab start -->
                  <div v-show="activeTab == 'service'">
                    <form @submit.prevent="updateServicePrice" method="post">
                      <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                        <svg class="icon">
                          <use
                            href="../../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="../../assets/img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Servicios
                      </h6>
                      <div class="flex justify-end">
                        <button
                          type="button"
                          class="btn btn--xs btn--outline--primary tooltip"
                          @click="$refs.modalService.openModal()"
                        >
                          <span class="tooltiptext">Agregar más servicios</span>Agregar
                        </button>
                      </div>
                      <div v-if="selectedProfessions && selectedProfessions.length > 0">
                        <div>
                          <table class="w-full text-left border-collapse">
                            <tbody v-for="(profession, index) in selectedProfessions" :key="index">
                              <tr class="flex flex-col lg:table-row header-row">
                                <td class="my-2 uppercase text-brand-secondary icon-text-aligner">
                                  <img
                                    src="../../assets/img/icon-chevron-right.png"
                                    class="inline-block w-4"
                                  />{{ profession.description }}
                                </td>
                              </tr>
                              <tr
                                class="flex flex-col lg:table-row"
                                v-for="(service, serviceIndex) in allServicesByType(
                                  profession.type
                                )"
                                :key="serviceIndex"
                                v-bind:class="{
                                  newservice_row: service.isNew
                                }"
                              >
                                <td
                                  class="px-6 py-4 border-b border-gray-200"
                                  style=" vertical-align: top;"
                                >
                                  <div class="flex items-center">
                                    <div class="ml-0 md:ml-3">
                                      <small
                                        class="text-xs text-brand-secondary"
                                        v-if="service.isNew"
                                        >Nuevo
                                      </small>
                                      <h6 class="my-0 text-gray-900 ">
                                        {{ service.description }}
                                      </h6>
                                    </div>
                                  </div>
                                </td>

                                <td class="px-6 py-4 border-b border-gray-200">
                                  <div>
                                    <label class="block mb-2 whitespace-no-wrap">
                                      <div
                                        class="grid grid-cols-2 lg:grid-cols-1 lg:grid-gap-4 text-left lg:text-center items-center justify-between"
                                      >
                                        <div>
                                          <input
                                            type="text"
                                            v-model="servicePrice[service.id]"
                                            class=" text-center form-input"
                                            @keyup="
                                              formatAmount(servicePrice[service.id], service.id)
                                            "
                                            :id="service.id"
                                          />
                                          <span class="ml-2">Gs. </span>
                                          <div class="text-left">
                                            <div>
                                              <span class="font-semibold">
                                                IVA :
                                              </span>
                                              {{
                                                countTax(servicePrice[service.id], profession.iva)
                                              }}
                                            </div>
                                            <div>
                                              <span class="font-semibold">
                                                Cuota de confianza y soporte :
                                              </span>
                                              {{
                                                countComision(
                                                  servicePrice[service.id],
                                                  clientdata.comision
                                                )
                                              }}
                                            </div>
                                            <div>
                                              <span class="font-semibold"> Total : </span>
                                              {{
                                                countTotalPrice(
                                                  servicePrice[service.id],
                                                  profession.iva,
                                                  clientdata.comision
                                                )
                                              }}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="text-right">
                                          <span
                                            role="button"
                                            @click="
                                              isConfirmRemoveService(service.id, service.isNew)
                                            "
                                            class="ml-6 inline-block lg:hidden"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 352 512"
                                              class="w-4"
                                            >
                                              <path
                                                fill="currentColor"
                                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                              ></path></svg
                                          ></span>
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </td>

                                <td
                                  class="px-6 py-4 border-b-4 border-gray-200 md:border-b hidden md-table-cell"
                                >
                                  <span
                                    role="button"
                                    @click="isConfirmRemoveService(service.id, service.isNew)"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 352 512"
                                      class="w-4"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                      ></path></svg
                                  ></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="flex justify-end my-2">
                        <button class="w-full btn btn--success lg:w-auto">Guardar</button>
                      </div>
                    </form>
                    <!-- modal start-->
                    <modal ref="modalService">
                      <template v-slot:header>
                        <h1>Servicios</h1>
                      </template>

                      <template v-slot:body>
                        <div
                          v-if="selectedProfessions && selectedProfessions.length > 0"
                          class="register-professional"
                        >
                          <div
                            class="relative my-6 bg-white border shadow-lg border-solidborder-gray-400 rounded p-6"
                            v-for="(profession, index) in selectedProfessions"
                            :key="index"
                            :id="'profession_' + profession.id"
                          >
                            <div class="mb-4">
                              <article>
                                <header class="w-full items-center py-1 mb-2">
                                  <h5 class="text-center" style="font-size: 1.375rem;">
                                    {{ profession.description }}
                                  </h5>
                                </header>

                                <ul
                                  class="mb-4 grid grid-columns-1 md:grid-gap-4 md:grid-columns-2 lg:grid-gap-8 lg:grid-columns-3"
                                  v-if="
                                    unselectedServicesByType(profession.type) &&
                                      unselectedServicesByType(profession.type).length > 0
                                  "
                                >
                                  <li
                                    v-for="(addservice,
                                    addserviceIndex) in unselectedServicesByType(profession.type)"
                                    :key="addserviceIndex"
                                  >
                                    <label class="flex items-center">
                                      <input
                                        type="checkbox"
                                        v-model="newServices"
                                        class="form-checkbox"
                                        :value="addservice"
                                        @change="
                                          setDefaultPrice($event, addservice.id, addservice.price)
                                        "
                                      />
                                      <span class="ml-4 text-black">
                                        {{ addservice.description }}
                                      </span>
                                    </label>
                                  </li>
                                </ul>
                              </article>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-slot:footer> </template>
                    </modal>
                    <!-- modal end -->
                  </div>
                  <!--service tab end -->
                </div>
              </div>
              <!--tab Content end -->
            </div>
          </section>
        </div>
      </div>
    </section>
    <Footer />
    <!-- modal start-->
    <div class="validate-phone validate-phone-modal">
      <modal ref="validatePhone">
        <template v-slot:header>
          <h5 class="w-full text-center">Validación de Teléfono</h5>
        </template>

        <template v-slot:body>
          <div class="flex flex-row justify-center">
            <div class="w-full">
              <p class="text-black mb-8 text-lg">
                Por favor inserte el código de validación que le llegará al whatsapp asociado a su teléfono. Actualizaremos su número cuando realice la validación. 
              </p>
              <form @submit.prevent="validateUserPhone" method="post">
                <div>
                  <label>
                    <input
                      class="block w-full mt-1 form-input"
                      type="text"
                      v-model="validationCode"
                    />
                    <span class="error" v-if="validatePhoneSubmit && !validationCode">
                      Por favor inserte el código de validación
                    </span>
                  </label>
                </div>
                <button class="rounded w-full btn btn--md shadow-md py-3 px-4 btn--primary bg-green-btn" type="submit">
                  <span class="justify-between text-base ">Validar código</span>
                </button>
              </form>
            </div>
            <hr />
          </div>
        </template>
        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AdminProfileSidebar from "@/components/AdminProfileSidebar.vue";
import ProfessionalProfileSidebar from "@/components/ProfessionalProfileSidebar.vue";
import { mapGetters, mapMutations } from "vuex";
import Footer from "@/components/Footer.vue";
import Swal from "sweetalert2";
import GetDepartments from "@/utils/methods/GetDepartments";
import GetDepartmentLocations from "@/utils/methods/GetDepartmentLocations";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import getServiceByType from "@/utils/methods/getServiceByType";
import servicesByType from "@/utils/methods/servicesByType";
import Modal from "@/components/Modal";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import encrypt from "@/utils/methods/encrypt";
const handToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

export default {
  data() {
    return {
      submited: false,
      updatePrice: false,
      processingGetClientData: false,
      processingUpdateUser: false,
      processingupdateAvatar: false,
      ProfessionalId: null,
      clientdata: {},
      updateClientData: {
        firstName: "",
        lastName: "",
        identificationNumber: "",
        ruc: "",
        phoneNumber: "",
        countryCode: null,
        countryCallingCode: null,
        address: "",
        location_id: null,
        coverLetter: "",
        laboralReferenceName1: "",
        laboralReferencePhone1: "",
        laboralReferenceName2: "",
        laboralReferencePhone2: "",
        reference1Description: "",
        reference2Description: ""
      },
      updateAvatarData: {},
      department: null,
      departments: [],
      departmentLocations: [],
      location: null,
      activeTab: "info",
      services: [],
      updatePriceData: {
        professionalId: null,
        requestData: {}
      },
      selectedProfessions: [],
      newServices: [],
      servicePrice: [],
      updateDocumentRecord: {
        hasJudicialRecords: false,
        hasPoliceRecords: false,
        hasIdentificationNumber: false,
        hasLaboralReferences: false,
        hasProfessionalCertification: false,
        hasAddressVerified: false
      },
      comision: null,
      observation: null,
      isAvailable: true,
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :"
      },
      telnumber: "",
      isPhoneValid: false,
      SecretKey: process.env.VUE_APP_ENCRYPT_SECRETKEY,
      validationCode: "",
      validatePhoneSubmit: false
    };
  },
  created() {
    if (this.$route.params.id) {
      this.ProfessionalId = this.$route.params.id;
    } else {
      this.ProfessionalId = this.user.id;
    }
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetProfessionalData();
    this.GetDepartments();
  },
  computed: {
    ...mapGetters(["error", "user", "phoneValidation"]),
    activeDepartments,
    activeDepartmentLocations
  },

  methods: {
    encrypt,
    GetDepartments,
    GetDepartmentLocations,
    getServiceByType,
    servicesByType,
    newServiceByType(type) {
      if (this.newServices.length > 0) {
        return this.newServices.filter(service => {
          return service.type == type;
        });
      }
      return [];
    },
    selectedServicesByType(type) {
      if (this.clientdata.Services.length > 0) {
        return this.clientdata.Services.filter(service => {
          return service.type == type;
        });
      }
      return [];
    },
    allServicesByType(type) {
      let selectedService = this.selectedServicesByType(type);
      let newService = this.newServiceByType(type);
      let allService = selectedService;
      if (newService.length > 0) {
        allService = [].concat(selectedService, newService);
      }
      return allService.sort((a, b) => (a.description > b.description ? 1 : -1));
    },
    unselectedServicesByType(type) {
      if (this.services.length > 0) {
        return this.services.filter(service => {
          return service.type == type && !this.isSelectedService(service.id);
        });
      }
      return null;
    },
    isSelectedService(sID) {
      for (var j = 0; j < this.clientdata.Services.length; j++) {
        if (this.clientdata.Services[j].id == sID) {
          return true;
        }
      }
      return false;
    },
    updateClient(e) {
      e.preventDefault();
      this.submited = true;
      this.processingUpdateUser = true;
      this.updateClientData.userId = this.ProfessionalId;
      if (this.validateForm()) {
        if (this.user.userType == "admin") {
          this.updateClientData.hasAddressVerified = this.updateDocumentRecord.hasAddressVerified;
          this.updateClientData.hasIdentificationNumber = this.updateDocumentRecord.hasIdentificationNumber;
          this.updateClientData.hasJudicialRecords = this.updateDocumentRecord.hasJudicialRecords;
          this.updateClientData.hasLaboralReferences = this.updateDocumentRecord.hasLaboralReferences;
          this.updateClientData.hasPoliceRecords = this.updateDocumentRecord.hasPoliceRecords;
          this.updateClientData.hasProfessionalCertification = this.updateDocumentRecord.hasProfessionalCertification;
          this.updateClientData.comision = this.comision;
          this.updateClientData.observation = this.observation;
          this.updateClientData.isAvailable = this.isAvailable;
        }
        this.$store
          .dispatch("updateClient", this.updateClientData)
          .then(() => {
            this.processingUpdateUser = false;
            let registerMessage = `Perfil actualizado correctamente`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", registerMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch(() => {
            this.processingUpdateUser = false;
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingRegister = false;
      }
    },
    updateUserAvatar() {
      var images = require.context("../../assets/img/", false, /\.gif$/);
      Swal.fire({
        imageUrl: images("./loading.gif"),
        imageAlt: "Loader",
        showConfirmButton: false,
        background: "transparent",
        backdrop: `rgba(0,0,0,0.6)`,
        allowOutsideClick: false
      });
      this.processingupdateAvatar = true;
      this.updateAvatarData.userId = this.ProfessionalId;
      var formdata = new FormData();
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formdata.append("file", file);
      }
      this.updateAvatarData.formdata = formdata;
      this.$store
        .dispatch("updateUserAvatar", this.updateAvatarData)
        .then(response => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Imagen de perfil actualizada correctamente.",
            showConfirmButton: true
          });
          this.$refs.file.value = "";
          this.clientdata.pictureProfileUrl = response[1][0].pictureProfileUrl;
          this.processingupdateAvatar = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          Swal.close();
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          this.submited = false;
          this.processingupdateAvatar = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    validateForm() {
      return (
        this.updateClientData.firstName != "" &&
        this.updateClientData.lastName != "" &&
        this.isPhoneValid == true
      );
    },

    GetLocationDepartment() {
      this.$store
        .dispatch("GetLocationDepartment", {
          locationId: this.clientdata.Location.id
        })
        .then(response => {
          this.$store.dispatch("setShowAlert", false);

          this.department = response.locations.department_id;
          this.GetDepartmentLocations();
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    GetProfessionalData() {
      this.processingClientData = true;
      this.$store
        .dispatch("GetProfessionalData", {
          userId: this.ProfessionalId
        })
        .then(response => {
          this.clientdata = response;

          this.updateClientData.firstName = this.clientdata.firstName
            ? this.clientdata.firstName
            : "";
          this.updateClientData.lastName = this.clientdata.lastName || "";
          this.updateClientData.identificationNumber = this.clientdata.identificationNumber || "";
          this.updateClientData.ruc = this.clientdata.ruc || "";
          this.updateClientData.phoneNumber = this.clientdata.phoneNumber || "";
          this.telnumber = this.updateClientData.phoneNumber;
          this.updateClientData.countryCode = this.clientdata.countryCode
            ? this.clientdata.countryCode
            : "PY";
          this.updateClientData.countryCallingCode = this.clientdata.countryCallingCode
            ? this.clientdata.countryCallingCode
            : "595";
          this.updateClientData.address = this.clientdata.address || "";
          this.updateClientData.coverLetter = this.clientdata.coverLetter || "";
          this.updateClientData.laboralReferenceName1 = this.clientdata.laboralReferenceName1 || "";
          this.updateClientData.laboralReferencePhone1 =
            this.clientdata.laboralReferencePhone1 || "";
          this.updateClientData.laboralReferenceName2 = this.clientdata.laboralReferenceName2 || "";
          this.updateClientData.laboralReferencePhone2 =
            this.clientdata.laboralReferencePhone2 || "";
          this.updateClientData.reference1Description = this.clientdata.reference1Description || "";
          this.updateClientData.reference2Description = this.clientdata.reference2Description || "";
          if (this.clientdata.Location.id && this.clientdata.Location.id != null) {
            this.updateClientData.location_id = this.clientdata.Location.id;
            this.GetLocationDepartment();
          } else {
            this.updateClientData.location_id = null;
          }
          this.selectedProfessions = this.clientdata.Profession;

          for (var s = 0; s < this.selectedProfessions.length; s++) {
            this.getServiceByType(this.selectedProfessions[s].type);
          }
          for (var p = 0; p < this.clientdata.Services.length; p++) {
            let setPrice = Number(this.clientdata.Services[p].priceGravado)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            if (setPrice == "") {
              setPrice = 0;
            }
            this.$set(this.servicePrice, this.clientdata.Services[p].id, setPrice);
          }
          if (this.user.userType == "admin") {
            this.updateDocumentRecord.hasAddressVerified = this.clientdata.hasAddressVerified;
            this.updateDocumentRecord.hasIdentificationNumber = this.clientdata.hasIdentificationNumber;
            this.updateDocumentRecord.hasJudicialRecords = this.clientdata.hasJudicialRecords;
            this.updateDocumentRecord.hasLaboralReferences = this.clientdata.hasLaboralReferences;
            this.updateDocumentRecord.hasPoliceRecords = this.clientdata.hasPoliceRecords;
            this.updateDocumentRecord.hasProfessionalCertification = this.clientdata.hasProfessionalCertification;
            this.comision = this.clientdata.comision;
            this.observation = this.clientdata.observation;
            this.isAvailable = this.clientdata.isAvailable;
          }
          this.processingClientData = false;
        })
        .catch(() => {
          this.processingClientData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    selectTab(selectedTab) {
      this.activeTab = selectedTab;
    },
    reloadServices() {
      this.processingClientData = true;
      this.$store
        .dispatch("GetProfessionalData", {
          userId: this.ProfessionalId
        })
        .then(response => {
          this.clientdata = response;
          this.newServices = [];
          this.servicePrice = [];
          for (var p = 0; p < this.clientdata.Services.length; p++) {
            this.$set(
              this.servicePrice,
              this.clientdata.Services[p].id,
              Number(this.clientdata.Services[p].priceGravado)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            );
          }
          this.processingClientData = false;
        })
        .catch(() => {
          this.processingClientData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    updateServicePrice(e) {
      e.preventDefault();
      this.updatePrice = true;
      this.processingUpdatePrice = true;
      this.updatePriceData.professionalId = this.ProfessionalId;
      this.updatePriceData.requestData.services = [];
      if (this.clientdata.Services.length > 0) {
        for (var x = 0; x < this.clientdata.Services.length; x++) {
          let newPrice = this.servicePrice[this.clientdata.Services[x].id].replace(".", "");
          if (newPrice == "" && newPrice <= 0) {
            newPrice = 0;
          }

          this.updatePriceData.requestData.services.push({
            id: this.clientdata.Services[x].id,
            price: newPrice
          });
        }
      }
      if (this.newServices.length > 0) {
        for (var u = 0; u < this.newServices.length; u++) {
          let newPrice = this.servicePrice[this.newServices[u].id].replace(".", "");
          if (newPrice == "" && newPrice <= 0) {
            newPrice = 0;
          }
          this.updatePriceData.requestData.services.push({
            id: this.newServices[u].id,
            price: newPrice
          });
        }
      }
      this.$store
        .dispatch("updateServicePrice", this.updatePriceData)
        .then(() => {
          this.updatePrice = false;
          this.processingUpdatePrice = false;
          this.reloadServices();
          let UpdatePriceMessage = `Perfil actualizado correctamente.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", UpdatePriceMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingUpdatePrice = false;
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    formatAmount(val, id) {
      val = val.replace(".", "");
      if (Number(val) > 0) {
        this.$set(
          this.servicePrice,
          id,
          Number(val)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
      }
    },
    deleteProfessionService(serviceId) {
      this.$store
        .dispatch("deleteProfessionService", { userId: this.ProfessionalId, serviceId: serviceId })
        .then(() => {
          this.submited = false;
          this.reloadServices();
          let removeServiceMessage = `Service Remove successfully.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", removeServiceMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
        });
    },
    isConfirmRemoveService(serviceId, isNewService = false) {
      Swal.fire({
        title: "",
        text: "Está seguro que desea eliminar este servicio?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          if (!isNewService) {
            this.deleteProfessionService(serviceId);
          } else {
            const i = this.newServices.findIndex(_item => _item.id === serviceId);
            if (i > -1) {
              this.newServices.splice(i, 1);
            }
          }
        }
      });
    },
    setDefaultPrice(e, serviceid, serviceprice) {
      if (serviceid) {
        if (e.target.checked) {
          const i = this.newServices.findIndex(_item => _item.id === serviceid);
          if (i > -1) {
            this.$set(this.newServices[i], "isNew", true);
          }
          this.$set(this.servicePrice, serviceid, serviceprice.toString());
          handToast.fire({
            icon: "success",
            title: "Servicio agregado correctamente"
          });
        } else {
          this.$set(this.servicePrice, serviceid, "");
          handToast.fire({
            icon: "error",
            title: "Servicio descartado"
          });
        }
      }
    },
    countTax(price, tax) {
      let totalTax = 0;
      let value = price.replace(".", "");
      value = Number(value);
      if (value && value > 0) {
        totalTax = (value * tax) / 100;
      }
      return totalTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    countTotalPrice(price, tax, comission) {
      let totalPrice = 0;
      let value = price.replace(".", "");
      value = Number(value);
      if (value && value > 0) {
        totalPrice = (value * comission) / 100 + (value * tax) / 100 + value;
      }
      return totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    countComision(price, comission) {
      let totalComision = 0;
      let value = price.replace(".", "");
      value = Number(value);
      if (value && value > 0) {
        totalComision = (value * comission) / 100;
      }
      return totalComision.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    phoneUpdate(val) {
      this.updateClientData.phoneNumber = val.phoneNumber;
      this.updateClientData.countryCode = val.countryCode;
      this.updateClientData.countryCallingCode = val.countryCallingCode;
      if (val.countryCode == "PY") {
        if (val.nationalNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    },
    validateUserPhone(e) {
      e.preventDefault();
      this.validatePhoneSubmit = true;
      
      if (this.validationCode) {
        this.$store.dispatch("validatePhone", {
          code: this.validationCode,
          phoneNumber: this.updateClientData.phoneNumber,
          countryCode: this.updateClientData.countryCode
        }).then(() => {
          this.validatePhoneSubmit = false;
          this.processingUpdateUser = false;
          this.$refs.validatePhone.closeModal();

          this.validationCode = "";
          this.updateLocalData({...this.updateClientData, phoneNumber: `+${this.updateClientData.countryCallingCode}${this.updateClientData.phoneNumber}`})

          handToast.fire({
            icon: "success",
            title: 'Gracias por validar tu teléfono'
          });
        }).catch(() => {
          this.validatePhoneSubmit = false;

          let errorMessage = "No se ha podido validar su teléfono, favor intente de nuevo";

          handToast.fire({
            icon: "error",
            title: errorMessage
          });
        });
      } else {
        //this.validatePhoneSubmit = false;
      }
    },
    ...mapMutations(["updateLocalData"])
  },
  watch: {
    phoneValidation(newValue, oldValue) {
      if (newValue) {
        this.$refs.validatePhone.openModal();
      }
    }
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfessionalProfileSidebar,
    AdminProfileSidebar,
    Modal,
    VuePhoneNumberInput
  }
};
</script>
<style lang="css" scoped>
@import "../../assets/css/dashboard.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.hand-tab .list-tab {
  border: 1px solid #ccc;
  background-color: #dfe0e6;
}

.hand-tab .list-tab li:hover {
  background-color: #f7fafc;
}
.hand-tab .list-tab li.is-active,
.hand-tab .list-tab li.is-active:hover {
  background-color: #304fc8;
  color: white;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.newservice_row {
  background: #eee;
}
@media (min-width: 1024px) {
  .md-table-cell {
    display: table-cell;
  }
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
</style>
<style>
  .validate-phone .modal__dialog {
    width: 25%;
  }
  @media screen and (max-width: 992px) {
    .validate-phone .modal__dialog {
      width: 90%;
    }
  }
</style>
