<template>
  <div>
    <Header hide-how-works />
    <Messages />
    <section class="flex py-4 md:my-1">
      <div class="container container-xl flex flex-col md:flex-row md:items-center">
        <div class="w-full md:w-1/2">
          <form
            @submit.prevent="onSubmit"
            method="post"
            class="p-5 md:p-10 rounded border border-gray-400 bg-white"
          >
            <h3 class="mb-8">Recuperar contraseña</h3>
            <label>
              <span>Introduzca su nueva contraseña:</span>
              <input
                class="form-input mt-1 block w-full"
                v-bind:class="{ 'bg-red-100 border-red-700': submited && !password }"
                type="password"
                v-model="password"
                placeholder="Mínimo 8 caracteres"
              />
              <span class="error" v-if="submited && !password"
                >Por favor ingrese su contraseña</span
              >
              <span class="error" v-if="submited && !validatePassword()">
                La contraseña debe tener mínimamente 8 caracteres.
              </span>
            </label>
            <label>
              <span>Repite la contraseña:</span>
              <input
                v-model="password2"
                class="form-input mt-1 block w-full"
                v-bind:class="{ 'bg-red-100 border-red-700': submited && password != password2 }"
                type="password"
                placeholder="Mínimo 8 caracteres"
              />
              <span class="error" v-if="submited && password != password2"
                >Las contraseñas no coinciden</span
              >
            </label>
            <button
              type="submit"
              class="btn btn--md btn--secondary w-full"
              :disabled="processingRecover"
              style="background-color: #ff783e;"
            >
              Recuperar contraseña
            </button>
          </form>
        </div>

        <!-- <div class="w-full md:w-1/2">
          <section class="text-center md:p-20 mb-5 md:mb-0">
            <h4>¿Aún no estas registrado?</h4>
            <p>
              ¡Es muy fácil! Crea una cuenta y empieza a disfrutar de las ventajas que te ofrecemos
            </p>
            <a href="/register-client" class="btn btn--primary">Crear una cuenta</a>
          </section>
        </div> -->
      </div>
    </section>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import { mapGetters } from "vuex";
import validatePassword from "@/utils/methods/validatePassword";
export default {
  data() {
    return {
      colorMessage: "#66A7E5",
      submited: false,
      token: "",
      password: "",
      password2: "",
      processingRecover: false,
      recovererrormessage: ""
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.token = this.$route.params.tid;
  },
  methods: {
    validatePassword,
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingRecover = true;
      if (this.validateForm()) {
        this.$store
          .dispatch("RecoverPassword", {
            token: this.token,
            password: this.password,
            password2: this.password2
          })
          .then(() => {
            this.processingRecover = true;
            let reoverPasswordMessage = `Reset Password Successfully`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", reoverPasswordMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function() {
              self.$store.dispatch("setShowAlert", false);
              self.$router.push({ name: "Dashboard" });
            }, 2000);
          })
          .catch(() => {
            this.processingRecover = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingRecover = false;
      }
    },
    validateForm() {
      return this.password != "" && this.validatePassword() == true;
    }
  },
  computed: {
    ...mapGetters(["error"])
  },
  components: {
    Header,
    Footer,
    Messages
  }
};
</script>
<style scoped>
@import "../assets/css/ingresar-registro.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
