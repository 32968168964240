<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <div id="register_professional">
      <section class="flex py-4 md:my-10" v-show="step === 1">
        <div class="container flex flex-col container-xl md:flex-row md:items-center">
          <div class="w-full mx-auto mb-16 lg:w-1/2 md:mb-0">
            <form class="p-5 bg-white border border-gray-400 rounded md:p-10">
              <svg class="w-8 h-8 icon">
                <uses
                  href="../assets/img/icons.svg#icon-herramientas"
                  xlink:href="../assets/img/icons.svg#icon-herramientas"
                />
              </svg>
              <h3 class="my-1">Registrarme como profesional o empresa</h3>
              <h6 class="mb-4 text-brand-secondary">Empieza a conseguir nuevos clientes</h6>

              <fieldset class="p-4 mb-3 border border-gray-400 rounded">
                <legend>Datos Personales</legend>
                <label>
                  <span>Nombre:</span>
                  <input
                    v-model="firstName"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{ 'bg-red-100 border-red-700': submited && !firstName }"
                    type="text"
                    placeholder="Ponga su primer nombre"
                  />
                  <span class="error" v-if="submited && !firstName"
                    >Por favor, introduzca su nombre</span
                  >
                </label>
                <label>
                  <span>Apellido:</span>
                  <input
                    v-model="lastName"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{ 'bg-red-100 border-red-700': submited && !lastName }"
                    type="text"
                    placeholder="Ingrese su apellido"
                  />
                  <span class="error" v-if="submited && !lastName"
                    >Por favor ingrese su apellido</span
                  >
                </label>

                <label>
                  <span>Celular:</span>
                  <input
                    v-model="phoneNumber"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{ 'bg-red-100 border-red-700': submited && !phoneNumber }"
                    type="number"
                    placeholder="Ingresa un número para contacto"
                  />
                  <span class="error" v-if="submited && !phoneNumber"
                    >Por favor, introduzca su número de móvil</span
                  >
                </label>

                <label>
                  <span>Email:</span>
                  <input
                    v-model="email"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{
                      'bg-red-100 border-red-700': (submited && !validateEmail()) || isEmailExist
                    }"
                    type="email"
                    placeholder="Escribe tu email"
                    @keyup="IsProfessionalExist()"
                  />
                  <span class="error" v-if="submited && !email">Por favor ingrese su email</span>
                  <span class="error" v-if="submited && !validateEmail() && email"
                    >Por favor introduzca un correo electrónico válido</span
                  >
                  <span class="error" v-if="isEmailExist"
                    >El correo electrónico ya está registrado.</span
                  >
                </label>

                <label>
                  <span>RUC/C.I:</span>
                  <input
                    v-model="identificationNumber"
                    v-bind:class="{
                      'bg-red-100 border-red-700': submited && !identificationNumber
                    }"
                    class="block w-full mt-1 form-input"
                    type="text"
                    placeholder="Ingresa tu número de documento o ruc"
                  />
                </label>
                <span class="error" v-if="submited && !identificationNumber"
                  >Por favor Ingresa tu número de documento o ruc</span
                >
                <label>
                  <span>Dirección:</span>
                  <input
                    v-model="address"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{ 'bg-red-100 border-red-700': submited && !address }"
                    type="text"
                    placeholder="Ingresa tu dirección"
                  />
                  <span class="error" v-if="submited && !address"
                    >Por favor Ingresa tu dirección</span
                  >
                </label>
                <label>
                  <span class="block">Imagen de perfil:</span>
                  <!-- new image -->
                  <div>
                    <input
                      class="hidden w-full mt-1 mb-2 form-input"
                      type="file"
                      name="profileImage"
                      @change="profileImageAdd"
                      ref="profileImage"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      @click="$refs.profileImage.click()"
                      class="btn btn--primary"
                    >
                      Seleccionar imagen
                    </button>
                  </div>

                  <!-- new image end -->
                </label>

                <div
                  class="grid mb-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                  v-if="imagePreview"
                >
                  <figure class="relative  border border-gray-200 rounded shadow">
                    <img :src="imagePreview" class="w-full" />
                    <span
                      v-show="imagePreview"
                      @click="removeProfileImage()"
                      class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                      >x</span
                    >
                  </figure>
                </div>
              </fieldset>

              <fieldset class="p-4 mb-3 border border-gray-400 rounded">
                <legend>Datos Laborales</legend>

                <label>
                  <span>Dirección laboral:</span>
                  <input
                    v-model="laboralAddress"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{ 'bg-red-100 border-red-700': submited && !laboralAddress }"
                    type="text"
                    placeholder="Ingresa tu dirección"
                  />
                  <span class="error" v-if="submited && !laboralAddress"
                    >Por favor Dirección laboral</span
                  >
                </label>

                <label>
                  <span>Referencia laboral 1:</span>
                  <input
                    v-model="laboralReferenceName1"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{
                      'bg-red-100 border-red-700': submited && !laboralReferenceName1
                    }"
                    type="text"
                    placeholder="Nombre"
                  />
                  <span class="error" v-if="submited && !laboralReferenceName1"
                    >Por favor introduzca el Nombre</span
                  >
                  <input
                    v-model="laboralReferencePhone1"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{
                      'bg-red-100 border-red-700': submited && !laboralReferencePhone1
                    }"
                    type="number"
                    placeholder="Número de teléfono"
                  />
                  <span class="error" v-if="submited && !laboralReferencePhone1"
                    >Por favor introduzca el Número de teléfono</span
                  >
                </label>

                <label>
                  <span>Referencia laboral 2:</span>
                  <input
                    v-model="laboralReferenceName2"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{
                      'bg-red-100 border-red-700': submited && !laboralReferenceName2
                    }"
                    type="text"
                    placeholder="Nombre"
                  />
                  <span class="error" v-if="submited && !laboralReferenceName2"
                    >Por favor introduzca el Nombre</span
                  >
                  <input
                    v-model="laboralReferencePhone2"
                    class="block w-full mt-1 form-input"
                    v-bind:class="{
                      'bg-red-100 border-red-700': submited && !laboralReferencePhone2
                    }"
                    type="number"
                    placeholder="Número de teléfono"
                  />
                  <span class="error" v-if="submited && !laboralReferencePhone2"
                    >Por favor introduzca el Número de teléfono</span
                  >
                </label>
                <label>
                  <span>Departamento:</span>
                  <select
                    class="block w-full mt-1 rounded form-select"
                    v-model="department"
                    @change="GetDepartmentLocations()"
                  >
                    <option disabled v-if="activeDepartments.length <= 0" :value="null"
                      >Departamento no encontrado</option
                    >
                    <option disabled v-else :value="null">Seleccionar Departmento</option>
                    <option
                      v-for="department in activeDepartments"
                      :key="department.id"
                      :value="department.id"
                      >{{ department.description }}</option
                    >
                  </select>
                </label>
                <label>
                  <span>Ciudad:</span>
                  <select class="block w-full mt-1 rounded form-select" v-model="location">
                    <option disabled v-if="activeDepartmentLocations.length <= 0" :value="null"
                      >Ciudad no encontrada</option
                    >
                    <option disabled v-else :value="null">Seleccione Ciudad</option>
                    <option
                      v-for="departmentLocation in activeDepartmentLocations"
                      :key="departmentLocation.id"
                      :value="departmentLocation.id"
                      >{{ departmentLocation.description }}</option
                    >
                  </select>
                </label>
                <label>
                  <span class="block">Adjuntar archivos:</span>
                  <ul class="text-xs list-disc list-inside">
                    <li>Cédula de identidad y/o RUC</li>
                    <li>Antecedentes policiales</li>
                    <li>Foto de perfil</li>
                  </ul>
                  <!-- new image -->
                  <div>
                    <input
                      class="hidden w-full mt-1 mb-2 form-input"
                      type="file"
                      name="images[]"
                      @change="imagesAdd"
                      placeholder="Nombre"
                      ref="file"
                      multiple
                    />
                  </div>
                  <div>
                    <button type="button" @click="$refs.file.click()" class="btn btn--primary">
                      Adjuntar Archivo
                    </button>
                  </div>
                  <!-- new image end -->
                  <span class="error" v-if="submited && this.images && this.images.length > 3"
                    >You can upload Maximum 3 images.</span
                  >
                </label>
                <div
                  class="grid mb-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                  v-if="image && image.length > 0"
                >
                  <figure
                    class="relative  border border-gray-200 rounded shadow"
                    v-for="(img, key) in image"
                    :key="key"
                  >
                    <img :src="img" class="w-full" />
                    <span
                      v-show="image"
                      @click="removeImage(key)"
                      class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                      >x</span
                    >
                  </figure>
                </div>
                <p class="text-sm">
                  Sí no tienes a el documento de atendecentes policiales, puedes enviarlo despues
                  por whatsapp al
                  <strong>0986130808</strong>
                </p>
              </fieldset>

              <label class="flex items-center">
                <input
                  v-model="acceptPolicy"
                  type="checkbox"
                  value="true"
                  class="form-checkbox"
                  v-bind:class="{ 'bg-red-100 border-red-700': submited && acceptPolicy != true }"
                />
                <span class="ml-2 font-normal font-body">
                  He leído y acepto la
                  <router-link :to="{ name: 'TermsCondition' }" class="underline" target="_blank"
                    >política de privacidad y condiciones</router-link
                  >
                </span>
              </label>
              <label class="flex items-center">
                <span class="error" v-if="submited && acceptPolicy != true"
                  >Debe aceptar las politicas de privacidad y condiciones para registrarse</span
                >
              </label>
              <button
                type="button"
                class="w-full btn btn--md btn--secondary"
                :disabled="processingRegister"
                @click.prevent="next()"
              >
                Registrarme
              </button>

              <div class="py-5 text-center">
                <h5>¿Ya tienes una cuenta?</h5>
                <p>
                  <router-link to="/login" class="block p-2 underline">Iniciar sesión</router-link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="flex py-4 md:my-10" v-show="step > 1 && step < 5">
        <div class="container flex flex-col container-xl md:flex-row md:items-center">
          <div class="w-full mb-16 md:mb-0">
            <form class="relative p-5 bg-white border border-gray-400 rounded md:p-12">
              <ul class="progress-tracker progress-tracker--text progress-tracker--center">
                <li
                  class="progress-step "
                  v-bind:class="{
                    'is-active ': step === 2,
                    'is-complete': step > 2
                  }"
                >
                  <span class="progress-marker"></span>
                  <span class="text-sm progress-text">
                    <h5 class="progress-title">Paso 1</h5>
                    Seleccionar Rubros
                  </span>
                </li>
                <li
                  class="progress-step"
                  v-bind:class="{
                    'is-active ': step === 3,
                    'is-complete': step > 3
                  }"
                >
                  <span class="progress-marker"></span>
                  <span class="text-sm progress-text">
                    <h5 class="progress-title">Paso 2</h5>
                    Seleccionar área de cobertura
                  </span>
                </li>
                <li
                  class="progress-step"
                  v-bind:class="{
                    'is-active': step === 4
                  }"
                >
                  <span class="progress-marker"></span>
                  <span class="text-sm progress-text">
                    <h5 class="progress-title">Paso 3</h5>
                    Describirte
                  </span>
                </li>
              </ul>
              <div class="step1" v-show="step === 2">
                <h3 class="text-center text-brand-secondary">
                  ¡Continuemos {{ firstName }} {{ lastName }}!
                </h3>
                <h5 class="mb-2 font-normal text-center md:w-1/2 md:mx-auto">
                  Selecciona el rubro (máximo 5) al cual te dedicas, ten en cuenta que es la forma
                  como los futuros clientes te buscaran
                </h5>

                <article v-if="servicesByType('PLO') && servicesByType('PLO').length > 0">
                  <header class="flex items-center py-3 mb-5 border-b border-gray-200">
                    <svg class="w-8 h-8 mr-2 icon text-brand-secondary">
                      <use
                        href="../assets/img/icons.svg#icon-canilla"
                        xlink:href="../assets/img/icons.svg#icon-canilla"
                      ></use>
                    </svg>
                    <h5>Plomeria</h5>
                  </header>

                  <ul class="mb-4 grid grid-columns-2 grid-gap-2 md:grid-columns-4">
                    <li v-for="(service, index) in servicesByType('PLO')" :key="index">
                      <label class="flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          :value="service.id"
                          v-model="selectedServices"
                        />
                        <span class="ml-2 font-normal font-body">
                          {{ service.description }}
                        </span>
                      </label>
                    </li>
                  </ul>
                </article>

                <article v-if="servicesByType('TAA') && servicesByType('TAA').length > 0">
                  <header class="flex items-center py-3 mb-5 border-b border-gray-200">
                    <svg class="w-8 h-8 mr-2 icon text-brand-secondary">
                      <use
                        href="../assets/img/icons.svg#icon-refrigeracion"
                        xlink:href="../assets/img/icons.svg#icon-refrigeracion"
                      ></use>
                    </svg>
                    <h5>Técnico de Aire Acondicionado</h5>
                  </header>
                  <ul class="mb-4 grid grid-columns-2 grid-gap-2 md:grid-columns-4">
                    <li v-for="(service, index) in servicesByType('TAA')" :key="index">
                      <label class="flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          :value="service.id"
                          v-model="selectedServices"
                        />
                        <span class="ml-2 font-normal font-body">
                          {{ service.description }}
                        </span>
                      </label>
                    </li>
                  </ul>
                </article>

                <article v-if="servicesByType('CAR') && servicesByType('CAR').length > 0">
                  <header class="flex items-center py-3 mb-5 border-b border-gray-200">
                    <svg class="w-8 h-8 mr-2 icon text-brand-secondary">
                      <use
                        href="../assets/img/icons.svg#icon-serrucho"
                        xlink:href="../assets/img/icons.svg#icon-serrucho"
                      ></use>
                    </svg>
                    <h5>Carpintería</h5>
                  </header>
                  <ul class="mb-4 grid grid-columns-2 grid-gap-2 md:grid-columns-4">
                    <li v-for="(service, index) in servicesByType('CAR')" :key="index">
                      <label class="flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          :value="service.id"
                          v-model="selectedServices"
                        />
                        <span class="ml-2 font-normal font-body">
                          {{ service.description }}
                        </span>
                      </label>
                    </li>
                  </ul>
                </article>

                <article v-if="servicesByType('ELE') && servicesByType('ELE').length > 0">
                  <header class="flex items-center py-3 mb-5 border-b border-gray-200">
                    <svg class="w-8 h-8 mr-2 icon text-brand-secondary">
                      <use
                        href="../assets/img/icons.svg#icon-bombilla"
                        xlink:href="../assets/img/icons.svg#icon-bombilla"
                      />
                    </svg>
                    <h5>Electricista</h5>
                  </header>
                  <ul class="mb-4 grid grid-columns-2 grid-gap-2 md:grid-columns-4">
                    <li v-for="(service, index) in servicesByType('ELE')" :key="index">
                      <label class="flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          :value="service.id"
                          v-model="selectedServices"
                        />
                        <span class="ml-2 font-normal font-body">
                          {{ service.description }}
                        </span>
                      </label>
                    </li>
                  </ul>
                </article>
                <article v-if="servicesByType('JAR') && servicesByType('JAR').length > 0">
                  <header class="flex items-center py-3 mb-5 border-b border-gray-200">
                    <svg class="w-8 h-8 mr-2 icon text-brand-secondary">
                      <use
                        href="../assets/img/icons.svg#icon-user-small"
                        xlink:href="../assets/img/icons.svg#icon-user-small"
                      />
                    </svg>
                    <h5>Jardinería</h5>
                  </header>
                  <ul class="mb-4 grid grid-columns-2 grid-gap-2 md:grid-columns-4">
                    <li v-for="(service, index) in servicesByType('JAR')" :key="index">
                      <label class="flex items-center">
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          :value="service.id"
                          v-model="selectedServices"
                        />
                        <span class="ml-2 font-normal font-body">
                          {{ service.description }}
                        </span>
                      </label>
                    </li>
                  </ul>
                </article>
                <div class="flex justify-end pt-4">
                  <label class="flex items-center">
                    <span class="error" v-if="submited && selectedServices.length <= 0"
                      >Debe seleccionar al menos un servicio</span
                    >
                  </label>
                  <label class="flex items-center">
                    <span class="error" v-if="submited && selectedServices.length > 5"
                      >You can select maximum 5 service</span
                    >
                  </label>
                </div>
                <div class="flex justify-between pt-4">
                  <div>
                    <button @click.prevent="prev()" class="btn btn--primary icon-text-aligner">
                      <svg class="text-white icon icon--outline icon--md">
                        <use
                          href="../assets/img/icons.svg#icon-tail-left"
                          xlink:href="../assets/img/icons.svg#icon-tail-left"
                        />
                      </svg>
                      Anterior
                    </button>
                  </div>
                  <div>
                    <button @click.prevent="next()" class="btn btn--primary icon-text-aligner">
                      Siguiente
                      <svg
                        class="text-white icon icon--outline icon--stroke-3 icon--md icon--align-right"
                      >
                        <use
                          href="../assets/img/icons.svg#icon-tail-right"
                          xlink:href="../assets/img/icons.svg#icon-tail-right"
                        ></use>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="step3" v-show="step === 3">
                <h3 class="text-center text-brand-secondary">Área de cobertura</h3>
                <h5 class="mb-2 font-normal text-center md:w-1/2 md:mx-auto">
                  Indica el área máxima de cobertura de tu servicio
                </h5>
                <div v-if="errorStr" class="error">
                  Lo lamento, no pudimos acceder a tu ubicación, por favor, mueve el punto a tu
                  ubicación actual
                </div>
                <button
                  type="button"
                  class="w-full btn btn--md btn--secondary mb-2"
                  :disabled="gettingLocation"
                  @click.prevent="getGeoLocation()"
                >
                  Find Me
                </button>
                <section class="relative border border-gray-200 rounded">
                  <div style="width:100%;height:500px;">
                    <l-map :zoom="zoom" :center="markerLatLng" ref="mymap">
                      <l-tile-layer :url="url"></l-tile-layer>
                      <l-marker
                        :lat-lng="markerLatLng"
                        :draggable="true"
                        @update:lat-lng="locationUpdate"
                        ref="marker"
                      >
                        <l-popup>Mové este punto a tu ubicación</l-popup></l-marker
                      >
                    </l-map>
                  </div>
                </section>

                <div class="flex justify-between pt-4">
                  <div>
                    <button @click.prevent="prev()" class="btn btn--primary icon-text-aligner">
                      <svg class="text-white icon icon--outline icon--md">
                        <use
                          href="../assets/img/icons.svg#icon-tail-left"
                          xlink:href="../assets/img/icons.svg#icon-tail-left"
                        />
                      </svg>
                      Anterior
                    </button>
                  </div>
                  <div>
                    <button @click.prevent="next()" class="btn btn--primary icon-text-aligner">
                      Siguiente
                      <svg
                        class="text-white icon icon--outline icon--stroke-3 icon--md icon--align-right"
                      >
                        <use
                          href="../assets/img/icons.svg#icon-tail-right"
                          xlink:href="../assets/img/icons.svg#icon-tail-right"
                        ></use>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="step4" v-show="step === 4">
                <h3 class="text-center text-brand-secondary">Cuentanos un poco sobre ti</h3>
                <h5 class="mb-2 font-normal text-center md:w-1/2 md:mx-auto">
                  Puedes describirte para que tus futuros clientes te conozcan mejor
                </h5>

                <label class="mx-auto md:w-1/2">
                  <span>Descripción del servicio:</span>
                  <textarea
                    v-model="serviceDescription"
                    class="block w-full h-48 mx-auto form-control"
                    v-bind:class="{ 'bg-red-100 border-red-700': submited && !serviceDescription }"
                    placeholder="Ej.: Soy un electricista con mas de 15 años en la profesión, actualmente vivo Sajonia, me considero una persona responsable etc. (Mínimo 30 caracteres)"
                  ></textarea>
                  <span class="error" v-if="submited && !serviceDescription"
                    >Por favor Descripción del servicio</span
                  >
                </label>

                <div class="flex justify-between pt-4">
                  <div>
                    <button @click.prevent="prev()" class="btn btn--primary icon-text-aligner">
                      <svg class="text-white icon icon--outline icon--md">
                        <use
                          href="../assets/img/icons.svg#icon-tail-left"
                          xlink:href="../assets/img/icons.svg#icon-tail-left"
                        />
                      </svg>
                      Anterior
                    </button>
                  </div>
                  <div>
                    <button
                      class="btn btn--primary icon-text-aligner"
                      @click.prevent="registerProfessional()"
                    >
                      Siguiente
                      <svg
                        class="text-white icon icon--outline icon--stroke-3 icon--md icon--align-right"
                      >
                        <use
                          href="../assets/img/icons.svg#icon-tail-right"
                          xlink:href="../assets/img/icons.svg#icon-tail-right"
                        ></use>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="flex py-4 md:my-10" v-show="step === 5">
        <div class="container flex flex-col container-xl md:flex-row md:items-center">
          <div class="w-full mx-auto mb-16 md:mb-0 lg:w-8/12">
            <div
              class="flex flex-col justify-center p-5 bg-white border border-gray-400 rounded md:p-24"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                viewBox="0 0 48 48"
                class="mx-auto"
              >
                <g>
                  <path
                    d="M37.648,32.979,15.021,10.352a1,1,0,0,0-1.656.39L2.052,44.684A1,1,0,0,0,3,46a.987.987,0,0,0,.316-.052L37.258,34.635a1,1,0,0,0,.39-1.656Z"
                    fill="#efd358"
                  ></path>
                  <ellipse
                    cx="25.627"
                    cy="22.373"
                    rx="5"
                    ry="17"
                    transform="translate(-8.314 24.674) rotate(-45)"
                    fill="#e2ac4b"
                  ></ellipse>
                  <path
                    d="M31,25a1,1,0,0,1-.9-1.426c2.516-5.349,8.805-7.2,15.29-4.5a1,1,0,1,1-.77,1.846c-5.451-2.27-10.674-.828-12.71,3.5A1,1,0,0,1,31,25Z"
                    fill="#43a6dd"
                  ></path>
                  <circle cx="37" cy="9" r="3" fill="#e86c60"></circle>
                  <circle cx="41" cy="39" r="2" fill="#e2ac4b"></circle>
                  <circle cx="28" cy="18" r="2" fill="#5a7a84"></circle>
                  <circle cx="42.5" cy="26.5" r="1.5" fill="#ae453e"></circle>
                  <circle cx="17.5" cy="4.5" r="1.5" fill="#e2ac4b"></circle>
                  <path
                    d="M21,19a1,1,0,0,1-.6-1.8A12.026,12.026,0,0,0,24.081,2.394a1,1,0,0,1,1.838-.788A13.975,13.975,0,0,1,21.6,18.8.994.994,0,0,1,21,19Z"
                    fill="#e86c60"
                  ></path>
                  <path
                    d="M4.172,13.828a1,1,0,0,1-.707-.293,5,5,0,1,1,7.07-7.07A1,1,0,0,1,9.121,7.879a3.072,3.072,0,0,0-4.242,0,3,3,0,0,0,0,4.242,1,1,0,0,1-.707,1.707Z"
                    fill="#43a6dd"
                  ></path>
                </g>
              </svg>
              <h3 class="text-center text-brand-secondary">¡Finalizamos!</h3>
              <p class="mb-4 text-center md:w-10/12 md:mx-auto">
                Estás listo para empezar a recibir solicitudes de servicios y/o pedidos de
                presupuestos. Ingresá a tu perfil para completar los precios de tus servicios si lo
                deseas, esto facilitará a los clientes que quieran elegir tus servicios. Muy pronto
                te avisaremos cuando los clientes ya te podrán contactar!
              </p>
              <router-link to="/" class="mx-auto btn btn--primary">Ir al inicio</router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import GetDepartments from "@/utils/methods/GetDepartments";
import GetDepartmentLocations from "@/utils/methods/GetDepartmentLocations";
import validateEmail from "@/utils/methods/validateEmail";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import scrollToSection from "@/utils/methods/scrollToSection";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
  data() {
    return {
      submited: false,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      identificationNumber: "",
      address: "",
      serviceDescription: "",
      laboralAddress: "",
      laboralReferenceName1: "",
      laboralReferencePhone1: "",
      laboralReferenceName2: "",
      laboralReferencePhone2: "",
      acceptPolicy: false,
      processingRegister: false,
      department: null,
      departments: [],
      departmentLocations: [],
      location: null,
      step: 1,
      services: [],
      selectedServices: [],
      isEmailExist: false,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 7,
      geolocation: {
        latitude: -25.3211306,
        longitude: -57.5928935
      },
      markerLatLng: [-25.3211306, -57.5928935],
      errorStr: false,
      gettingLocation: false,
      images: [],
      image: [],
      profileImage: null,
      imagePreview: null
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetDepartments();
    this.GetServices();
  },
  computed: {
    ...mapGetters(["error"]),
    activeDepartments,
    activeDepartmentLocations
  },
  methods: {
    GetDepartments,
    GetDepartmentLocations,
    validateEmail,
    scrollToSection,
    registerProfessional() {
      this.submited = true;
      this.processingRegister = true;
      if (this.validateForm()) {
        var images = require.context("../assets/img/", false, /\.gif$/);
        Swal.fire({
          imageUrl: images("./loading.gif"),
          imageAlt: "Loader",
          showConfirmButton: false,
          background: "transparent",
          backdrop: `rgba(0,0,0,0.6)`,
          allowOutsideClick: false
        });
        var formdata = new FormData();
        for (var i = 0; i < this.images.length; i++) {
          let file = this.images[i];
          formdata.append("files", file);
        }
        if (this.profileImage) {
          for (var j = 0; j < this.profileImage.length; j++) {
            let file = this.profileImage[j];
            formdata.append("profileImage", file);
          }
        }
        formdata.append("firstName", this.firstName);
        formdata.append("lastName", this.lastName);
        formdata.append("email", this.email);
        formdata.append("phoneNumber", this.phoneNumber);
        formdata.append("identificationNumber", this.identificationNumber);
        formdata.append("address", this.address);
        formdata.append("serviceDescription", this.serviceDescription);
        formdata.append("laboralAddress", this.laboralAddress);
        formdata.append("laboralReferenceName1", this.laboralReferenceName1);
        formdata.append("laboralReferencePhone1", this.laboralReferencePhone1);
        formdata.append("laboralReferenceName2", this.laboralReferenceName2);
        formdata.append("laboralReferencePhone2", this.laboralReferencePhone2);
        formdata.append("location_id", this.location);
        formdata.append("services", JSON.stringify(this.selectedServices));
        formdata.append("geolocation", JSON.stringify(this.geolocation));

        this.$store
          .dispatch("registerProfessional", formdata)
          .then(() => {
            this.submited = false;
            this.firstName = "";
            this.lastName = "";
            this.phoneNumber = "";
            this.email = "";
            this.identificationNumber = "";
            this.address = "";
            this.serviceDescription = "";
            this.laboralAddress = "";
            this.laboralReferenceName1 = "";
            this.laboralReferencePhone1 = "";
            this.laboralReferenceName2 = "";
            this.laboralReferencePhone2 = "";
            this.acceptPolicy = false;
            this.processingRegister = false;
            this.department = null;
            this.departments = [];
            this.departmentLocations = [];
            this.location = null;
            this.services = [];
            this.$refs.file.value = "";
            Swal.close();
            this.processingRegister = false;
            this.step++;
            setTimeout(function() {
              window.scrollTo({ top: 100, behavior: "smooth" });
            }, 1000);
          })
          .catch(() => {
            Swal.close();
            this.processingRegister = false;
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);

            setTimeout(function() {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }, 1000);
          });
      } else {
        this.processingRegister = false;
      }
    },
    validateForm() {
      if (this.step == 1) {
        return (
          this.firstName != "" &&
          this.lastName != "" &&
          this.phoneNumber != "" &&
          this.email != "" &&
          this.validateEmail() == true &&
          this.isEmailExist == false &&
          this.identificationNumber != "" &&
          this.laboralAddress != "" &&
          this.laboralReferenceName1 != "" &&
          this.laboralReferencePhone1 != "" &&
          this.laboralReferenceName2 != "" &&
          this.laboralReferencePhone2 != "" &&
          this.images &&
          this.images.length <= 3 &&
          this.acceptPolicy == true
        );
      } else if (this.step == 2) {
        return this.selectedServices.length > 0 && this.selectedServices.length <= 5;
      } else if (this.step == 3) {
        return true;
      } else if (this.step == 4) {
        return this.serviceDescription != "";
      }
    },
    GetServices() {
      this.$store
        .dispatch("GetServices")
        .then(response => {
          this.services = response.services;
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    servicesByType(type) {
      if (this.services.length > 0) {
        return this.services.filter(service => {
          return service.type == type;
        });
      }
      return null;
    },
    prev() {
      this.step--;
      if (this.step == 3) {
        this.mapLoad();
      }
      this.scrollToSection("register_professional");
    },
    next() {
      this.submited = true;
      if (this.validateForm()) {
        this.submited = false;
        this.step++;
        if (this.step == 3) {
          this.mapLoad();
        }
        this.scrollToSection("register_professional");
      }
    },
    IsProfessionalExist() {
      if (this.validateEmail()) {
        this.$store
          .dispatch("IsProfessionalExist", {
            emailId: this.email
          })
          .then(response => {
            if (response.user) {
              this.isEmailExist = true;
            } else {
              this.isEmailExist = false;
            }
          })
          .catch(() => {
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.isEmailExist = false;
      }
    },
    mapLoad() {
      setTimeout(() => {
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
    },
    getGeoLocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = true;
        this.$refs.marker.mapObject.openPopup();
        return;
      }
      this.gettingLocation = true;
      /* eslint-disable */
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.gettingLocation = false;
          this.markerLatLng = [pos.coords.latitude, pos.coords.longitude];
          this.errorStr = false;
        },
        err => {
          this.gettingLocation = false;
          this.errorStr = true;
          this.$refs.marker.mapObject.openPopup();
        }
      );
      /* eslint-disable */
    },
    locationUpdate(location) {
      this.geolocation = { latitude: location.lat, longitude: location.lng };
    },
    imagesAdd(e) {
      var files = e.target.files || e.dataTransfer.files;
      this.images = [];
      Array.prototype.push.apply(this.images, files);
      if (!this.images.length) return;
      this.createImage(this.images);
    },
    createImage(file) {
      for (var i = 0; i < file.length; i++) {
        var reader = new FileReader();
        var vm = this;
        reader.onload = e => {
          vm.image.push(e.target.result);
        };
        reader.readAsDataURL(file[i]);
      }
    },

    removeImage(key) {
      this.image.splice(key, 1);
      this.images.splice(key, 1);

      if (!this.image.length) {
        this.$refs.file.value = "";
      }
    },
    profileImageAdd(e) {
      this.profileImage = e.target.files || e.dataTransfer.files;
      if (!this.profileImage) return;
      var reader = new FileReader();
      var vm = this;
      reader.onload = e => {
        vm.imagePreview = e.target.result;
      };
      reader.readAsDataURL(this.profileImage[0]);
    },
    removeProfileImage() {
      this.profileImage = null;
      this.imagePreview = null;
      if (!this.profileImage) {
        this.$refs.profileImage.value = "";
      }
    }
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  }
};
</script>
<style scoped>
@import "../assets/css/ingresar-registro.css";

.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
