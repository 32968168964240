import { HTTP } from "@/config/http-constants";
export default {
  all(data) {
    const searchParams = new URLSearchParams(data).toString();
    return HTTP.get(`/api/service-requests/all?${searchParams}`);
  },
  cancel({ id }) {
    return HTTP.put("api/service-requests/" + id + "/cancel");
  },
  changeProfessional({ service_request_id, professional_profile_id }) {
    return HTTP.put("api/service-requests/" + service_request_id + "/change-professional", {
      professional_profile_id,
    });
  },
  getByClient(data) {
    const searchParams = new URLSearchParams(data).toString();
    return HTTP.get(
      `/api/service-requests/${data.client_profile_id}/by-client-profile?${searchParams}`,
    );
  },
};
