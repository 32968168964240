<template>
  <svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :width="width" :height="height" rx="3" fill="white"/>
    <path d="M7.24572 23.5577L11.3378 20.4284C11.1038 19.7178 10.9773 18.9575 10.9773 18.1667C10.9773 17.3759 11.1038 16.6156 11.3378 15.9049L7.24572 12.7757C6.44762 14.3983 6 16.2277 6 18.1667C6 20.1057 6.44762 21.935 7.24572 23.5577Z" fill="#FBBC05"/>
    <path d="M11.3379 15.9049C12.2821 13.0373 14.9758 10.9773 18.1668 10.9773C19.8812 10.9773 21.4297 11.5856 22.6464 12.5811L26.1858 9.04167C24.0289 7.16136 21.2638 6 18.1668 6C13.3588 6 9.22488 8.75222 7.24588 12.7757L11.3379 15.9049Z" fill="#EA4335"/>
    <path d="M18.1668 30.3333C13.3575 30.3333 9.22255 27.5796 7.2442 23.5543L11.3346 20.4183C12.2757 23.2912 14.972 25.356 18.1668 25.356C19.7301 25.356 21.1152 24.9879 22.2122 24.2956L26.0969 27.3032C23.9704 29.2805 21.1344 30.3333 18.1668 30.3333Z" fill="#34A853"/>
    <path d="M18.1665 15.9546H29.5037C29.6696 16.6735 29.7802 17.4478 29.7802 18.1667C29.7802 22.1322 28.3294 25.2272 26.0967 27.3032L22.2119 24.2957C23.4821 23.494 24.3659 22.2578 24.6923 20.6553H18.1665V15.9546Z" fill="#4285F4"/>
  </svg>
</template>
<script>
  export default {
    props:{
      width:{
        type:Number,
        default:36
      },
      height:{
        type:Number,
        default:36
      }
    }
  }
</script>
