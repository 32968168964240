<template>
  <div
      @click="onClick"
      :class="[
                `w-full h-[45px] flex items-center justify-center rounded-[30px] font-bold`,
                ghost ? `bg-none border border-px border-${color} text-${color}` : `bg-${color} text-white`,
                disabled ? `pointer-events-none opacity-50` : 'cursor-pointer',
                size=='large' ? `px-[50px] py-[20px]` : size=='medium' ? `px-4` : ''
              ]">
      <slot></slot>
  </div>
</template>
<script>
export default {
  props:{
    title:{
      type:String,
      default:""
    },
    onClick:{
      type:Function,
      default: ()=>{}
    },
    disabled:{
      type:Boolean,
      default:false
    },
    ghost:{
      type:Boolean,
      default:false
    },
    color:{
      type:String,
      default:'primary'
    },
    size:{
      type:String,
      default:'medium'
    }
  }
}
</script>
