export default function() {
  if (this.selectedProfessions.length < 5) {
    this.showProfessionLimitError = false;
    if (this.addProfession.id) {
      let id = this.addProfession.id;
      let isExist = false;
      for (var i = 0; i < this.selectedProfessions.length; i++) {
        if (this.selectedProfessions[i].id == id) {
          isExist = true;
        }
      }
      if (!isExist) {
        this.addProfession.experience = 1;
        this.addProfession.extraService1 = "";
        this.addProfession.extraService2 = "";
        this.addProfession.extraService3 = "";
        let keyIndex = this.selectedProfessions.push(this.addProfession);
        this.$set(this.selectedProfessions[keyIndex - 1], "images", []);
        this.$set(this.selectedProfessions[keyIndex - 1], "image", []);
        this.getServiceByType(this.addProfession.type);
      }
      this.scrollToSection("profession_" + id);
    }
  } else {
    this.showProfessionLimitError = true;
  }
}
