<template>
  <aside class="px-2 mb-4 lg:w-1/3">
    <nav class="w-full lg:-mt-4">
      <ul>
        <li>
          <router-link
            :to="{ name: 'AdminServiceRequests' }"
            :class="[
              currentPage.includes('service-requests') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4',
            ]"
          >
            <span>Servicios solicitados</span>
            <svg
              :class="[
                currentPage.includes('service-requests') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg',
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'AdminBudgetRequest' }"
            :class="[
              currentPage.includes('budget-request') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4',
            ]"
          >
            <span>Presupuestos solicitados</span>
            <svg
              :class="[
                currentPage.includes('budget-request') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg',
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'AdminReview' }"
            :class="[
              currentPage.includes('review') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4',
            ]"
          >
            <span>Reviews</span>
            <svg
              :class="[
                currentPage.includes('review') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg',
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'AdminProfessionalRequest' }"
            :class="[
              currentPage.includes('professional-request') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4',
            ]"
          >
            <span>Solicitudes de profesionales</span>
            <svg
              :class="[
                currentPage.includes('professional-request')
                  ? 'text-brand-secondary'
                  : 'text-black',
                'icon icon--lg',
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'AdminTransferRequest' }"
            :class="[
              currentPage.includes('transfer-request') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4',
            ]"
          >
            <span>Transferencias bancarias a confirmar</span>
            <svg
              :class="[
                currentPage.includes('transfer-request') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg',
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'AdminClients' }"
            :class="[
              currentPage.includes('clients') ? 'text-brand-secondary' : 'text-black',
              'flex items-center justify-between py-4',
            ]"
          >
            <span>Clientes</span>
            <svg
              :class="[
                currentPage.includes('clients') ? 'text-brand-secondary' : 'text-black',
                'icon icon--lg',
              ]"
            >
              <use
                href="../assets/img/icons.svg#icon-right"
                xlink:href="../assets/img/icons.svg#icon-file-content"
              />
            </svg>
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'AdminProfileSidebar',
  computed: {
    currentPage() {
      return this.$route.path;
    },
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/css/dashboard.css';
</style>
