<template>
  <div class="w-screen container-background">
    <Header />
    <router-view></router-view>
    <Toast />
  </div>
</template>
<script>
import Header from "@/components/v2/Header.vue";
import Toast from "@/components/v2/Toast.vue";

export default {
  components:{
    Header,
    Toast
  }
}
</script>
<style scoped>
.container-background{
  background:white;
}
</style>
