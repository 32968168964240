/* eslint-disable */

const defaultState = {
  headerLoginText: "¿Sos nuevo usuario?",
  message: "",
  isSuccess: false,
  isInfo: false,
  isWarning: false,
  hasError: false,
  showAlert: false,
  showResendEmail: false,
  showCloseButton: false,
  ResendEmailId: "",
  showFacebookLogin: false,
  showGoogleLogin: false,
  showMailLogin: false,
  showHeaderBackButton: false,
  showHeaderCancelButton: false,
};

const actions = {
  setCurrentHeaderLoginText({ commit }, state) {
    commit("setHeaderLoginTextState", state);
  },
  setShowAlert({ commit }, state) {
    commit("setShowAlertState", state);
  },
  setGlobalMessage({ commit }, state) {
    commit("setGlobalMessageState", state);
  },
  setIsSuccessAlert({ commit }, state) {
    commit("setIsSuccessAlertState", state);
  },
  setIsInfoAlert({ commit }, state) {
    commit("setIsInfoAlertState", state);
  },
  setIsWarningAlert({ commit }, state) {
    commit("setIsWarningAlertState", state);
  },
  setIsResendEmail({ commit }, state) {
    commit("setIsResendEmail", state);
  },
  setIsErrorAlert({ commit }, state) {
    commit("setIsErrorAlertState", state);
  },
  setIsShowMailLogin({ commit }, state) {
    commit("setIsShowMailLogin", state);
  },
  setIsShowFacebookLogin({ commit }, state) {
    commit("setIsShowFacebookLogin", state);
  },
  setIsShowGoogleLogin({ commit }, state) {
    commit("setIsShowGoogleLogin", state);
  },
  hideMessageLink({ commit }, state) {
    commit("setIsResendEmail", "");
    commit("setIsShowFacebookLogin", false);
    commit("setIsShowGoogleLogin", false);
    commit("setIsShowMailLogin", false);
  },
  setIsShowCloseButton({ commit }, state) {
    commit("setIsShowCloseButton", state);
  },
  setShowHeaderBackButton({ commit }, state) {
    commit("setShowHeaderBackButton", state);
  },
  setShowHeaderCancelButton({ commit }, state) {
    commit("setShowHeaderCancelButton", state);
  },
};

const mutations = {
  setHeaderLoginTextState(state, headerLoginText) {
    state.headerLoginText = headerLoginText;
  },
  setShowAlertState(state, showAlert) {
    state.showAlert = showAlert;
  },
  setGlobalMessageState(state, message) {
    state.message = message;
  },
  setIsSuccessAlertState(state, isSuccess) {
    state.isSuccess = isSuccess;
  },
  setIsInfoAlertState(state, isInfo) {
    state.isInfo = isInfo;
  },
  setIsWarningAlertState(state, isWarning) {
    state.isWarning = isWarning;
  },
  setIsResendEmail(state, showResendEmail) {
    if (showResendEmail) {
      state.showResendEmail = true;
      state.ResendEmailId = showResendEmail;
    } else {
      state.showResendEmail = false;
      state.ResendEmailId = "";
    }
  },
  setIsErrorAlertState(state, hasError) {
    state.hasError = hasError;
  },
  setIsShowFacebookLogin(state, isShow) {
    state.showFacebookLogin = isShow;
  },
  setIsShowGoogleLogin(state, isShow) {
    state.showGoogleLogin = isShow;
  },
  setIsShowMailLogin(state, isShow) {
    state.showMailLogin = isShow;
  },
  setIsShowCloseButton(state, isShow) {
    state.showCloseButton = isShow;
  },
  setShowHeaderBackButton(state, show) {
    state.showHeaderBackButton = show;
  },
  setShowHeaderCancelButton(state, show) {
    state.showHeaderCancelButton = show;
  },
};

const getters = {
  headerLoginText: state => state.headerLoginText,
  showAlert: state => state.showAlert,
  message: state => state.message,
  isSuccess: state => state.isSuccess,
  isInfo: state => state.isInfo,
  isWarning: state => state.isWarning,
  hasError: state => state.hasError,
  showResendEmail: state => state.showResendEmail,
  ResendEmailId: state => state.ResendEmailId,
  showFacebookLogin: state => state.showFacebookLogin,
  showGoogleLogin: state => state.showGoogleLogin,
  showMailLogin: state => state.showMailLogin,
  showCloseButton: state => state.showCloseButton,
  showHeaderBackButton: state => state.showHeaderBackButton,
  showHeaderCancelButton: state => state.showHeaderCancelButton,
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations
};
