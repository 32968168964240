import store from "@/store";
import redirectBasedRole from "./redirect-base-role";
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    return next();
  }
  return redirectBasedRole(next);
};

export default ifNotAuthenticated;
