<template>
  <div class="w-full flex">
    <div class="flex flex-col items-center">
      <ProfilePicture :url="user.pictureProfileUrl" class="w-28 h-28 my-3" alt="" />
    </div>
    <div class="flex-1 flex flex-col justify-center ml-3">
      <span class="text-lg font-bold">{{ type }}</span>
      <span class="text-lg">{{ user.firstName + " " + user.lastName }}</span>
      <span class="font font-medium">{{ user.phoneNumber }}</span>
      <span class="font font-medium">{{ user.email }}</span>
    </div>
  </div>
</template>
<script>
import ProfilePicture from "@/components/ProfilePicture.vue";
export default {
  components: { ProfilePicture },
  props: {
    type: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
