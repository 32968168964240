var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"px-2 mb-4 lg:w-3/12"},[_c('nav',{staticClass:"lg:-mt-4"},[_c('ul',[_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('service-request') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'ProfessionalServiceRequest' }}},[_c('span',[_vm._v("Servicios solicitados")]),_c('svg',{class:[
              _vm.currentPage.includes('service-request') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('budget-request') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'ProfessionalBudgetRequest' }}},[_c('span',[_vm._v("Presupuestos solicitados")]),_c('svg',{class:[
              _vm.currentPage.includes('budget-request') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('message') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'ProfessionalMessage' }}},[_c('span',[_vm._v("Mensajes")]),_c('svg',{class:[
              _vm.currentPage.includes('message') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('edit-profession') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'EditProfessionService' }}},[_c('span',[_vm._v("Editar profesión/es")]),_c('svg',{class:[
              _vm.currentPage.includes('edit-profession') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])]),_c('router-link',{class:[
            _vm.currentPage.includes('edit-photo') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'EditProfessionPhoto' }}},[_c('span',[_vm._v("Editar fotos de trabajos")]),_c('svg',{class:[
              _vm.currentPage.includes('edit-photo') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('edit-complete-profile') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4'
          ],attrs:{"to":{ name: 'ProfessionalProfile' }}},[_c('span',[_vm._v("Mis datos")]),_c('svg',{class:[
              _vm.currentPage.includes('edit-complete-profile') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg'
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }