export default function(professionId) {
  if (this.selectedServices.length > 0) {
    let isexistService = false;
    for (var z = 0; z < this.selectedServices.length; z++) {
      if (this.selectedServices[z].profession_id == professionId) {
        isexistService = true;
      }
    }
    if (!isexistService) {
      return false;
    }
  } else {
    return false;
  }
  return true;
}
