<template>
  <div @click="onClick" class="social-button">
    <div class="logo">
      <component :is="icon+'-icon'"></component>
    </div>
    <span class="title">
      {{title}}
    </span>
  </div>
</template>
<script>
import {GoogleIcon,FacebookIcon} from "@/components/v2/icons";
export default {
  components:{
    GoogleIcon,
    FacebookIcon
  },
  props:{
    icon:{
      type:String,
      default:''
    },
    title:{
      type:String,
      default:''
    },
    onClick:{
      type:Function,
      default:()=>{}
    }
  }
}
</script>
<style scoped>
  .social-button{
    width: 100%;
    height: 53px;
    background-color: #375BE5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    cursor: pointer;
  }

  .social-button .logo{
    width: 36px;
    height: 36px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .social-button .title{
    color: white;
    font-weight: 700 !important;
  }
</style>
