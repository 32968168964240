<template>
<div class="w-full">
  <section class="flex bg-white">
    <div class="container flex flex-col container-xl md:flex-row mt-6 md:mt-24 p-10 md:p-0 mb-48">
      <div class="w-full md:w-1/2 md:pr-2 mx-auto">
        <form method="post">
          <fieldset>
            <h4 class="">Registrarme como cliente</h4>
            <p class="mb-6">Crea tu perfil de cliente en Hand de manera simple y rápida</p>
          </fieldset>
          <fieldset class="pt-4 mb-3">
            <div class="grid md:grid-columns-2 grid-gap-4">
              <div>
                <FacebookRegister ref="fbComponent" :usertype="'client'" @updateData="updateValuesFromSocial"/>
              </div>
              <div>
                <GoogleRegister ref="googleComponent" @updateData="updateValuesFromSocial"/>
              </div>
            </div>
          </fieldset>
          <separator class="mt-[30px] mb-[20px]"/>
          <fieldset class="">
            <div class="grid grid-cols-1 xl-grid-cols-2 grid-gap-4">
              <div class="mt-[15px] xl:mt-[0px]">
                <form-label :title="'Nombre y Apellido'">
                  <input
                      v-model="name"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !name
                      }"
                      class="block w-full form-input"
                      type="text"
                      placeholder="Ingresa tu nombre y apellido"
                    />
                    <span class="error" v-if="submited && !name">Por favor ingresa tu nombre y apellido</span>
                </form-label>
              </div>
              <div class="mt-[15px] xl:mt-[0px]">
                <form-label :title="'Teléfono'">
                  <phone-input :value="phoneNumber" @input="onPhoneInput" />
                  <span class="error" v-if="submited && !isPhoneValid">
                    Por favor ingrese su número (lo vamos a necesitar para poder darte soporte si
                    lo necesitas)
                  </span>
                </form-label>
              </div>
            </div>
            <div class="grid grid-cols-1 xl-grid-cols-2 lg:grid-gap-4 mt-[15px] mb-[30px] xl:mt-[0px] xl:mb-[15px]">
              <div class="mt-4">
                <form-label :title="'Contraseña'">
                  <password-input v-model="password" />
                  <span class="error" v-if="submited && !password">Por favor ingrese una contraseña</span>
                  <span class="error" v-if="submited && !validatePassword()">
                    La contraseña debe tener mínimamente 8 caracteres.
                  </span>
                  <label class="flex items-center">
                      <a :href="'#'" @click.prevent="suggestPassword" class="text-black text-sm underline">Sugerir contraseña</a>
                  </label>
                </form-label>
              </div>
              <div class="mt-4">
                <form-label :title="'Repite la contraseña'">
                  <password-input v-model="password2" />
                  <span class="error" v-if="submited && password != password2">Las contraseñas no coinciden</span>
                </form-label>
              </div>
            </div>
          </fieldset>

          <div class="w-full flex justify-center">
            <div class="w-[100%] xl:w-[40%] flex flex-col items-center">
              <rounded-button :disabled="processing" :onClick="onNextStep">
                Siguiente
              </rounded-button>
              <div class="w-max flex items-center max-xl:flex-col justify-between mt-[21px]">
                <span>¿Necesitas ayuda?</span>
                <div>
                  <whatsapp-icon class="mx-[6px] inline-block"/>
                  <custom-link :href="'#'">0981888777</custom-link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import validatePassword from "@/utils/methods/validatePassword";
import FacebookRegister from "@/components/FacebookRegister.vue";
import GoogleRegister from "@/components/GoogleRegister.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Swal from "sweetalert2";
import Separator from "@/components/v2/Separator.vue";
import {RoundedButton} from "@/components/v2/buttons"
import {WhatsappIcon} from "@/components/v2/icons";
import CustomLink from "@/components/v2/Link.vue"
import { PhoneInput, FormLabel, PasswordInput } from "@/components/v2/forms";
import Tooltip from "@/components/v2/Tooltip.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      submited: false,
      name: "",
      phoneNumber: "",
      countryCode: "",
      password: "",
      password2: "",
      isPhoneValid: false,
      processing: false,
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.$store.dispatch("setShowHeaderBackButton", true);
    this.$store.dispatch("setShowHeaderCancelButton", true);
    this.$store.dispatch("saveRegisterClientCurrentStep", 1);
  },
  beforeDestroy() {
    this.$store.dispatch("setShowHeaderBackButton", false);
    this.$store.dispatch("setShowHeaderCancelButton", false);
  },
  methods: {
    validatePassword,
    onNextStep(e) {
      e.preventDefault();
      this.submited = true;
      this.processing = true;
      
      if (this.validateForm()) {
        this.$store.dispatch("hideMessageLink");
        this.$store.dispatch("saveRegisterClientTempData", {
          firstName: this.name.split(' ')[0],
          lastName: this.name.split(' ')[1],
          phoneNumber: this.phoneNumber,
          countryCode: this.countryCode,
          password: this.password,
          password2: this.password2,
          userType: "client"
        }).then(() => {
          this.$router.push({ name: 'RegisterClientStep2' })
        })
      } else {
        
      }
      this.processing = false;
    },
    validateForm() {
      return (
        this.name != "" &&
        this.isPhoneValid == true &&
        this.password != "" &&
        this.validatePassword() == true &&
        this.password == this.password2
      );
    },
    onPhoneInput(val) {
      this.phoneNumber = val.phoneNumber;
      this.countryCode = val.countryCode;
      if (val.countryCode == "PY") {
        if (val.phoneNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    },
    suggestPassword() {
      var passwordSuggested = this.phoneNumber.slice(-3) + this.name.slice(0, 3) + (Math.floor(Math.random() * (999 - 100 + 1) ) + 100)
      this.password = passwordSuggested
      this.password2 = passwordSuggested
    },
    updateValuesFromSocial(data, callback) {
      let errorMessage = "";
      this.name = data.firstName+' '+data.lastName;

      if (this.phoneNumber) {
        if (this.isPhoneValid) {
          const actionType = 'socialLogin' + data.oauthProvider[0].toUpperCase() + data.oauthProvider.slice(1);

          this.$store.dispatch(actionType, {
            access_token: data.accessToken,
            phoneNumber: this.phoneNumber,
            countryCode: this.countryCode
          }).then(() => {
            callback();

            Swal.fire({
              title: "",
              icon: "success",
              padding: "1.25em 3em",
              html: `Gracias por registrarte en Hand!.
                  Te enviamos un código de validación a tu whatsapp (esto es solo por única vez).`,
              confirmButtonText: "Continuar",
              allowOutsideClick: false
            }).then(result => {
              if (result.value) {
                this.$store.dispatch("removeRegisterClientTempData");
                this.$store.dispatch("removeRegisterClientCurrentStep");

                this.$router.push({ name: 'ValidateUser' });
              }
            });
          }).catch(() => {
            callback();

            errorMessage = this.error.response.data.msg;

            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true); 
          });
        } else {
          errorMessage = "Por favor ingrese su número correctamente";
        }
      } else {
        errorMessage = "Por favor ingrese su número e intente nuevamente";
      }

      if (errorMessage != "") {
        callback();

        this.$store.dispatch("setIsErrorAlert", true);
        this.$store.dispatch("setGlobalMessage", errorMessage);
        this.$store.dispatch("setShowAlert", true); 
      }
    }
  },
  computed: {
    ...mapGetters(["error"])
  },
  components: {
    Separator,
    Footer,
    Messages,
    Breadcrumb,
    VuePhoneNumberInput,
    FacebookRegister,
    GoogleRegister,
    RoundedButton,
    WhatsappIcon,
    CustomLink, 
    PhoneInput,
    FormLabel,
    PasswordInput,
    Tooltip
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
.whatsapp-contact-icon {
  top: 2px;
}
.register-divider {
  opacity: .3;
}
.form-input {
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  background-color:#fff;
  border-color:#c0c3ce;
  border-width:1px;
  border-radius:.25rem;
  padding:.4rem .75rem;
  font-size:1rem;
  line-height:1.5
}
</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>

