<template>
    <div id="toast"></div>
</template>
<script>
import toast from "@/assets/v2/js/toast.js";
import { mapGetters } from "vuex";
export default {
    methods: {
        disableAlert() {
            this.$store.dispatch("setShowAlert", false)
            this.$store.dispatch("setIsSuccessAlert", false)
            this.$store.dispatch("setIsInfoAlert", false)
            this.$store.dispatch("setIsErrorAlert", false)
            this.$store.dispatch("setGlobalMessage", "")
        }
    },
    watch: {
        showAlert: function (newValue) {
            if (newValue) {
                if(this.isSuccess) this.toastType = "success"
                if(this.isInfo) this.toastType = "info"
                if(this.hasError) this.toastType = "error"
            
                toast(this.message, this.toastType, this.disableAlert()).showToast()
            }
        }
    },
    computed: {
        ...mapGetters(["showAlert", "message", "isSuccess", "isInfo", "hasError"]),
    }
}
</script>