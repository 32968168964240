<template>
  <div class="w-full flex flex-col">
    <div class="w-full flex">
      <div class="flex-1 h-16 flex items-center bg-white">
        <div class="flex-1 h-full flex items-center">
          <MagnifyingGlassIcon class="mx-3" :color="'#000'" />
          <input
            type="text"
            class="w-full h-full text-gray-800 placeholder-gray-800 border-none px-4 focus:outline-none"
            v-model="query"
          />
          <div @click="showFilters=!showFilters" class="mx-3 cursor-pointer">
            <adjustments-vertical-icon :color="'#7b8187'"  />
          </div>
        </div>
      </div>
      <div
        @click="sendFilters"
        class="justify-center h-16 font-semibold btn btn--secondary md:w-1/3 mb-4 md:mb-0 md:h-auto md:rounded-l-none"
      >
        <magnifying-glass-icon :color="'#fff'"/>
      </div>
    </div>
    <template v-if="showFilters">
      <div class="w-full flex flex-col bg-white p-3 mt-3">
        <div class="w-full flex items-center justify-end">
          <i class="font-bold text-xl mr-2">Filtros</i>
          <adjustments-vertical-icon :color="'#7b8187'"  />
        </div>
        <div class="w-full">
          <i class="font-bold">Buscar por</i>
          <div class="flex mr-4 mt-1">
            <template v-for="filter in queryFilters">
              <div class="flex mr-10">
                <input type="checkbox" v-model="filter.checked" />
                <span class="ml-2 font-semibold">{{ filter.label }}</span>
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-col my-3">
          <i class="font-bold">Estado</i>
          <div class="flex mr-4 mt-1">
            <template v-for="status in states">
              <div class="flex mr-10">
                <input type="checkbox" v-model="status.checked" />
                <span class="ml-2 font-semibold">{{ status.description }}</span>
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-col">
          <i class="font-bold">Fecha</i>
          <div class="flex mt-1">
            <date-picker :initial_date="date" @updated="onUpdateDate" />
          </div>
        </div>
      </div>
    </template>

  </div>
</template>
<script>
import { MagnifyingGlassIcon,AdjustmentsVerticalIcon } from "@/components/icons";
import statusTranslations from "@/utils/helpers/ServiceRequest/statusTranslations";
import { DatePicker } from "@/components/Forms";
import dayjs from "dayjs";
export default {
  data() {
    return {
      states: Object.keys(statusTranslations).map((state) => ({
        key: state,
        description: statusTranslations[state],
        checked: true,
      })),
      date: new Date(),
      query: "",
      queryFilters:[
        {
          value:"client_fullname",
          label:"Nombre completo del cliente",
          checked:true
        },
        {
          value:"professional_fullname",
          label:"Nombre completo del profesional",
          checked:true
        }
      ],
      queryFilterBy:"client_fullname",
      showFilters:false
    };
  },
  methods: {
    onUpdateDate({ date }) {
      this.date = date;
    },
    sendFilters() {

      this.$emit("filters-updated", {
        query: this.query,
        date: dayjs(this.date).format("YYYY-MM-DD"),
        states: this.states.filter((s) => s.checked).map((s) => s.key),
        queryFilters: this.queryFilters.filter(f=>f.checked).map(f=>f.value)
      });
    },
  },
  components: {
    MagnifyingGlassIcon,
    DatePicker,
    AdjustmentsVerticalIcon
  },
};
</script>
