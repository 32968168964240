<template>
  <div class="w-full px-[50px] max-xl:pt-[140px] xl:pt-[104px] flex flex-col justify-center">
    <party-icon />
    <div class="max-xl:w-full xl:w-1/2 mt-[20px] xl:mt-[43px]">
      <span class="font-bold text-[20px] xl:text-[35px] leading-[122%] text-[20px] text-black ">
        Felicidades, ya tienes un perfil profesional en Hand
      </span>
    </div>
    <div class="max-xl:w-full xl:w-1/2">
      <p class="text-[14px] xl:text-[20px] leading-[16px] xl:leading-[23px] text-dark3 my-[20px] xl:mb-[43px]">
        Ya puedes empezar a recibir solicitudes de servicios o prespuestos, acuérdate de solicitar a tus futuros clientes un comentario sobre tu desempeño, para que más personas puedan contratarte.
      </p>
    </div>
    <div class="max-xl:w-full xl:w-1/5 ">
      <router-link :to="{ name: 'LoginProfessional' }">
        <rounded-button>
          Comenzar
        </rounded-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import {PartyIcon} from "@/components/v2/icons";
import {RoundedButton} from "@/components/v2/buttons";
export default {
  components:{
    PartyIcon,
    RoundedButton
  }
}
</script>
