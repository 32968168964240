<template>
  <div class="w-screen">
    <router-view></router-view>
    <Banner />
  </div>
</template>
<script>
import Banner from "@/components/v2/Banner.vue"
export default {
  components:{
    Banner
  }
}
</script>
