<template>
  <div class="new-home">
    <section class="py-2 md:py-8 lg:py-8 bg-white">
      <div class="container  container-xl">
        <section class="overflow-hidden bg-white border shadow-xl  rounded mb-2">
          <div class="flex flex-col md:flex-row items-center">
            <figure class="flex-none mr-0 md:mr-2 overflow-hidden border border-gray-400">
              <img src="../../assets/img/iStock-1186956422.png" class="w-full md:w-auto" />
            </figure>

            <header class="flex-2 px-6 py-2 text-center md:text-left">
              <h2 class="my-0 mb-2 text-xl font-bold">
                ¿Necesita un trabajo de urgencia o un trabajo especifico?
              </h2>
              <p class="mt-0 mb-0 text-black">
                Describa aquí lo que necesita para poder asignarle un profesional que lo ayude.
              </p>
            </header>

            <div class="w-auto px-2 py-2 text-center md:text-left">
              <button
                class="btn btn--primary  text-center px-4 py-2 leading-tight font-semibold"
                v-if="isAuthenticated"
                @click="
                  ContactSidebarShow = true;
                  toggleHtmlClass();
                "
              >
                Solicitar presupuesto a medida
              </button>
              <button
                class="btn btn--primary text-center py-2 leading-tight font-semibold"
                v-else
                @click="ShowLoginWarning()"
              >
                Solicitar presupuesto a medida
              </button>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section class="py-2 md:py-8 lg:py-8 bg-white">
      <div class="container text-center container-xl">
        <h5 class="text-center mb-8 text-2xl md:text-3xl text-gray-900">
          ¿Como funciona Hand?
        </h5>
        <div class="flex flex-wrap px-6 md:px-0">
          <div class="w-full px-0 md:px-6 lg:px-16 md:w-1/3 mb-8 md:mb-0">
            <div class="text-center mb-4">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot dot-big text-white text-2xl">1</span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
            <h5 class="mb-4">
              Busca, analiza <br />
              y selecciona
            </h5>
            <figure
              class="flex items-center justify-center w-64 h-64 md:w-56 md:h-56 mx-auto mb-4 border rounded-full shadow"
            >
              <img src="../../assets/img/help-img-1.png" class="w-full rounded-full" />
            </figure>

            <p class="text-black text-lg text-center md:text-left px-8 md:px-4 lg:px-6">
              Buscá el servicio que estas necesitando y te aparecen los perfiles de los
              profesionales disponibles para ayudarte.
            </p>
          </div>

          <div class="w-full px-0 md:px-6 lg:px-16 md:w-1/3 mb-8 md:mb-0">
            <div class="text-center mb-4">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot dot-big text-white text-2xl">2</span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
            <h5 class="mb-4">
              Pide presupuesto<br />
              y confirma el trabajo
            </h5>
            <figure
              class="flex items-center justify-center w-64 h-64 md:w-56 md:h-56 mx-auto mb-4 border rounded-full shadow"
            >
              <img src="../../assets/img/help-img-2.png" class="w-full rounded-full" />
            </figure>

            <p class="text-black text-lg text-center md:text-left px-8 md:px-4 lg:px-6">
              Elegí el profesional que te de mayor confianza y contratá un trabajo con precios
              predeterminados o pedí un presupuesto.
            </p>
          </div>

          <div class="w-full px-0 md:px-6 lg:px-16 md:w-1/3 mb-8 md:mb-0">
            <div class="text-center mb-4">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot dot-big text-white text-2xl">3</span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
            <h5 class="mb-4">
              Paga online y<br />
              califica el servicio
            </h5>
            <figure
              class="flex items-center justify-center w-64 h-64 md:w-56 md:h-56 mx-auto mb-4 border rounded-full shadow"
            >
              <img src="../../assets/img/help-img-3.png" class="w-full rounded-full" />
            </figure>

            <p class="text-black text-lg text-center md:text-left px-8 md:px-4 lg:px-6">
              Realizá el pago online de manera segura con todos los métodos de pago y calificá el
              servicio recibido.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="py-2 md:py-12 lg:py-12 bg-white">
      <div class="container container-xl">
        <h5 class="text-center mb-8 text-2xl md:text-3xl text-gray-900">
          ¿Poque elegir Hand?
        </h5>
        <div class="grid grid-cols-1 md:grid-cols-3 md:gap-16 px-6 md:px-0">
          <div class="md:flex text-center md:text-left">
            <div class="md:flex-shrink-0">
              <img
                class="rounded-lg mt-2 inline-block"
                src="../../assets/img/Icon-perfil.svg"
                alt="Perfil"
              />
            </div>
            <div class="mt-4 md:mt-0 md:ml-8">
              <h5 class="mb-4 mt-0 text-xl">
                Perfíl <br class="hidden md:block" />
                del técnico
              </h5>
              <p class="text-black text-base">
                Podes ver en detalles el perfíl del profesional, calificaciones de usuarios
                anteriores, con datos certificados para tomar las mejores decisiones.
              </p>
            </div>
          </div>
          <div class="md:flex text-center md:text-left">
            <div class="md:flex-shrink-0">
              <img
                class="rounded-lg mt-2 inline-block"
                src="../../assets/img/Icon-verificacion.svg"
                alt="Perfil"
              />
            </div>
            <div class="mt-4 md:mt-0 md:ml-8">
              <h5 class="mb-4 mt-0 text-xl">
                Verificación<br class="hidden md:block" />
                de antecedentes
              </h5>
              <p class="text-black text-base">
                Se verifican previamente los antecedentes de los técnicos con una compañía externa
                dedicada al análisis de datos para prevenir riesgos y resguardar la integridad de
                los clientes
              </p>
            </div>
          </div>
          <div class="md:flex text-center md:text-left">
            <div class="md:flex-shrink-0">
              <img
                class="rounded-lg mt-2 inline-block"
                src="../../assets/img/icon-reputacion.svg"
                alt="Perfil"
              />
            </div>
            <div class="mt-4 md:mt-0 md:ml-8">
              <h5 class="mb-4 text-xl">
                Sistema<br class="hidden md:block" />
                de reputación
              </h5>
              <p class="text-black text-base">
                Luego del servicio podrá calificar al profesional y el servicio recibido, que se
                visualiza en el perfíl, además Hand utiliza las calificaciones para depurar la base
                de datos apuntando a la calidad y el profesionalismo.
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 md:gap-16 px-6 md:px-0">
          <div class="md:flex col-span-1 text-center md:text-left">
            <div class="md:flex-shrink-0">
              <img
                class="rounded-lg mt-2 inline-block"
                src="../../assets/img/Icon-medios-de-pago.svg"
                alt="Perfil"
              />
            </div>
            <div class="mt-4 md:mt-0 md:ml-8">
              <h5 class="mb-4 mt-0 text-xl">
                Medios
                <br class="hidden md:block" />
                de pago
              </h5>
              <p class="text-black text-base">
                Para tu facilidad podes realizar el pago online y pagar con varios métodos de pago,
                desde tarjetas de crédito, transferencias bancarias y efectivo.
              </p>
            </div>
          </div>

          <div class="col-span-2 text-center shadow-lg px-2 md:px-6 py-4 rounded">
            <div class="md:block hidden">
              <img
                class="mr-4 my-2 inline-block"
                src="../../assets/img/bancard-pago-seguro.svg"
                alt="bancard"
              />
              <img class="mr-2 my-2 inline-block" src="../../assets/img/Visa.svg" alt="Perfil" />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/mastercard.svg"
                alt="mastercard"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/maestro.svg"
                alt="maestro"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/American-Express.svg"
                alt="American Express"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/Diners-club.svg"
                alt="Diners club"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/pago-movil.svg"
                alt="pago movil"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/Transferencia-bancariaa.svg"
                alt="Transferencia bancariaa-bancariaail"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/logo-tigo-money.svg"
                alt="Transferencia bancariaa-bancariaail"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/medio-billetera-personal.svg"
                alt="medio billetera personal"
              />
            </div>
            <div class="mr-0 text-center block md:hidden">
              <img
                class="mr-4 my-2 inline-block"
                src="../../assets/img/bancard-pago-seguro-small.svg"
                alt="bancard"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/Visa-small.svg"
                alt="Perfil"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/mastercard-small.svg"
                alt="mastercard"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/maestro-small.svg"
                alt="maestro"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/American-Express-small.svg"
                alt="American Express"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/Diners-club-small.svg"
                alt="Diners club"
              /><br class="hidden md:block" />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/pago-movil-small.svg"
                alt="pago movil"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/Transferencia-bancariaa-small.svg"
                alt="Transferencia bancariaa-bancariaail"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/logo-tigo-money-small.svg"
                alt="Transferencia bancariaa-bancariaail"
              />
              <img
                class="mr-2 my-2 inline-block"
                src="../../assets/img/medio-billetera-personal-small.svg"
                alt="medio billetera personal"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-pattern-footer  py-8">
      <div class="container container-xl">
        <div class="flex flex-wrap footer-section">
          <div class="w-full md:w-1/5 px-16 md:px-2">
            <div
              class="text-center font-bold   md:text-left text-lg mb-4 logo-heading"
              style="font-size:25px;"
            >
              Más sobre nosotros
            </div>
            <figure class="text-center  md:text-left mb-4">
              <a href="/"
                ><img src="../../assets/img/footer-logo.svg" class="inline-block md:block"
              /></a>
            </figure>
            <div class="text-center  md:text-left hidden md:block">
              <a
                href="https://www.ultimahora.com/premios-conecta-tigo-ya-tiene-ganadores-n2919799.html"
              >
                <img src="../../assets/img/tigo-logo.svg" class="hidden md:block" />
              </a>
            </div>
          </div>
          <div class="w-full md:w-2/5 se-col px-16 ">
            <p class="heading">Ganador de premios conecta 2020</p>
            <div class="text-center  md:text-left block md:hidden my-4">
              <a
                href="https://www.ultimahora.com/premios-conecta-tigo-ya-tiene-ganadores-n2919799.html"
              >
                <img src="../../assets/img/tigo-logo.svg" class="inline-block md:block" />
              </a>
            </div>
            <p class="subheading">
              Concurso de innovación, emprendimiento con impacto social y transformación digital.
            </p>
          </div>

          <div class="w-full md:w-1/3  px-4">
            <div class="text-center text-black font-bold mb-4" style="font-size:29px;">
              Prensa
            </div>
            <div class="flex logo-col">
              <div class="w-full lg:w-2/3 px-4">
                <a
                  href="https://www.ultimahora.com/una-app-ofrece-personal-servicio-el-hogar-n2917671.html"
                >
                  <img
                    src="../../assets/img/ultimahora-logo.png"
                    class="mb-6 inline-block md:block"
                  />
                </a>
                <a
                  href="https://www.elnacional.com.py/economia/2020/12/25/hand-y-parkea-ganadores-de-los-premios-conecta-by-tigo-business/"
                >
                  <img src="../../assets/img/lanacion-logo.png" class="mb-6 inline-block md:block" />
                </a>
                <a
                  href="https://infonegocios.com.py/y-ademas/plataforma-de-intermediacion-para-encontrar-profesionales-de-mandos-medios-funcionara-desde-enero"
                  ><img src="../../assets/img/infonegocios-logo.png" class=" inline-block md:block"
                /></a>
              </div>
              <div class="w-full lg:w-1/3 px-4">
                <a
                  href="https://www.abc.com.py/empresariales/2021/01/09/ganadores-de-premios-conecta/"
                  ><img src="../../assets/img/abc-color-logo.png" class="mb-6 inline-block md:block"
                /></a>
                <a
                  href="http://www.5dias.com.py/2019/12/aplicacion-ayuda-a-contratar-los-servicios-tecnicos-para-el-hogar/"
                  ><img src="../../assets/img/drs-logo.png" class=" inline-block md:block"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <aside
      data-pushbar-id="right"
      class="w-full shadow-2xl pushbar from_right md:w-2/5"
      v-bind:class="{ opened: ContactSidebarShow }"
    >
      <header class="px-8 py-4 bg-gray-100">
        <div class="flex items-center justify-between">
          <div>
            <h5>Contactar</h5>
          </div>
          <div>
            <span
              data-pushbar-close
              class="close push_right"
              @click="
                ContactSidebarShow = false;
                toggleHtmlClass();
              "
            ></span>
          </div>
        </div>
      </header>
      <div class="px-8 py-4">
        <form @submit.prevent="validatebudgetRegister" method="post">
          <p>
            Rellena el siguiente formulario y un profesional del staff de Hand se pondrá en contacto
            contigo en la brevedad posible. Todos los datos que envíes serán tratados de forma
            confidencial
          </p>
          <h6>Describa el servicio que necesita</h6>
          <textarea
            class="w-full h-32 p-2 border border-gray-400 rounded"
            v-model="budget.description"
            v-bind:class="{
              'bg-red-100 border-red-700': submited && !budget.description
            }"
          ></textarea>
          <label class="flex items-center">
            <span class="error" v-if="submited && !budget.description"
              >Favor ingrese una descripción</span
            >
          </label>

          <label>
            <span>¿Cuando quieres el servicio?</span>
            <VueCtkDateTimePicker
              v-model="budget.date"
              color="#3e65ff"
              buttonColor="#3e65ff"
              :only-date="true"
              :no-header="true"
              :minDate="moment().format('YYYY-MM-DD')"
              locale="es"
              format="YYYY-MM-DD"
              formatted="dddd, D MMMM, YYYY"
              :noKeyboard="true"
              id="servicedate"
              class="schduledate-wrapper"
              :noLabel="true"
              :noButtonNow="true"
              v-bind:class="{
                'bg-red-100 border-red-700': submited && !budget.date
              }"
            />
            <span class="error" v-if="submited && !budget.date">Please select date</span>
          </label>

          <p>
            Adjunta algunas imágenes para una mejor descripción
            <strong class="text-sm text-brand-secondary">(puedes subir hasta 3 imágenes)</strong>
          </p>

          <input
            class="block w-full mt-1 mb-2 form-input"
            type="file"
            multiple
            placeholder="Nombre"
            ref="file"
          />
          <span class="error" v-if="submited && this.$refs.file && this.$refs.file.files.length > 3"
            >You can upload Maximum 3 images.</span
          >

          <label class="flex items-center">
            <input
              type="checkbox"
              class="form-checkbox"
              v-model="acceptPolicy"
              v-bind:class="{
                'bg-red-100 border-red-700': submited && acceptPolicy != true
              }"
            />
            <span class="ml-2 font-normal font-body">
              He leído y acepto la
              <router-link :to="{ name: 'TermsCondition' }" class="underline" target="_blank"
                >política de privacidad y condiciones</router-link
              >
            </span>
          </label>
          <label class="flex items-center">
            <span class="error" v-if="submited && acceptPolicy != true"
              >Debe aceptar las politicas de privacidad y condiciones</span
            >
          </label>
          <button class="w-full btn btn--md btn--secondary" :disabled="processingbudgetRegister">
            Pedir Presupuesto
          </button>
        </form>
      </div>
    </aside>
    <!-- modal start-->
    <div class="update-phone">
      <modal ref="updateUserPhone">
        <template v-slot:header>
          <h1></h1>
        </template>

        <template v-slot:body>
          <div class="flex flex-row justify-center">
            <div class="w-full">
              <p class="text-black mb-8 text-lg">
                Hemos detectado que aún no ha cargado su número de teléfono, para poder darle
                seguimiento y un buen soporte vamos a necesitarlo. Por favor ingrese su número de
                contacto.
              </p>
              <form @submit.prevent="updateUserPhone" method="post">
                <div>
                  <label>
                    <span>Celular</span>
                    <VuePhoneNumberInput
                      v-model="telnumber"
                      color="#63b3ed"
                      class="mt-1"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': phoneSubmit && !isPhoneValid
                      }"
                      valid-color="#63b3ed"
                      error-color="#63b3ed"
                      default-country-code="PY"
                      :translations="translations"
                      :no-example="true"
                      :show-code-on-list="true"
                      @update="phoneUpdate"
                    />
                    <span class="error" v-if="phoneSubmit && !isPhoneValid">
                      Por favor ingrese su número (lo vamos a necesitar para poder darte soporte si
                      lo necesitas)
                    </span>
                  </label>
                </div>
                <button
                  class="rounded w-full btn btn--md shadow-md py-3 px-4 btn--primary bg-green-btn"
                  type="submit"
                >
                  <span class="justify-between text-base ">Enviar</span>
                </button>
              </form>
            </div>

            <hr />
          </div>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
  </div>
</template>

<script>
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import { mapGetters } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
const handToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});
export default {
  data() {
    return {
      submited: false,
      processingbudgetRegister: false,
      ContactSidebarShow: false,
      budget: {
        date: this.moment().format("YYYY-MM-DD"),
        description: "",
        user_id: null
      },
      acceptPolicy: false,
      processingUpdateUser: false,
      phoneSubmit: false,
      updateClientData: {
        userId: null,
        phoneNumber: null,
        countryCode: null,
        countryCallingCode: null
      },
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :"
      },
      telnumber: "",
      isPhoneValid: false
    };
  },
  name: "WhoWeAre",
  computed: {
    ...mapGetters(["isAuthenticated", "user"])
  },
  methods: {
    toggleHtmlClass() {
      const el = document.documentElement;
      if (this.ContactSidebarShow) {
        el.classList.add("pushbar_locked");
      } else {
        el.classList.remove("pushbar_locked");
      }
    },
    validatebudgetRegister(e) {
      e.preventDefault();
      this.submited = true;
      this.processingbudgetRegister = true;
      this.budget.user_id = this.user.id;
      if (this.validatebudgetForm()) {
        if (!this.user.phoneNumber || this.user.phoneNumber == null) {
          this.callFunction = "budget";
          this.$refs.updateUserPhone.openModal();
        } else {
          this.$refs.updateUserPhone.closeModal();
          this.budgetRegister();
        }
      } else {
        this.processingbudgetRegister = false;
      }
    },
    budgetRegister() {
      var images = require.context("../../assets/img/", false, /\.gif$/);
      Swal.fire({
        imageUrl: images("./loading.gif"),
        imageAlt: "Loader",
        showConfirmButton: false,
        background: "transparent",
        backdrop: `rgba(0,0,0,0.6)`,
        allowOutsideClick: false
      });
      var formdata = new FormData();
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formdata.append("photos", file);
      }
      formdata.append("date", this.budget.date);
      formdata.append("description", this.budget.description);
      formdata.append("user_id", this.budget.user_id);

      this.$store
        .dispatch("budgetRequest", formdata)
        .then(() => {
          Swal.close();
          this.processingbudgetRegister = false;
          this.submited = false;
          this.budget = {
            services: [],
            date: this.moment().format("YYYY-MM-DD"),
            description: "",
            user_id: null
          };
          this.ContactSidebarShow = false;
          this.submited = false;
          this.processingbudgetRegister = false;
          this.toggleHtmlClass();
          handToast.fire({
            icon: "success",
            title: "Solicitud de presupuesto creada nos contactaremos con usted pronto!"
          });
        })
        .catch(() => {
          Swal.close();
          handToast.fire({
            icon: "error",
            title: "Error ocurrido: algo salió mal, intente de nuevo"
          });
          this.ContactSidebarShow = false;
          this.submited = false;
          this.toggleHtmlClass();
          this.processingbudgetRegister = false;
        });
    },
    validatebudgetForm() {
      return (
        this.budget.date != "" &&
        this.budget.description != "" &&
        this.budget.user_id != "" &&
        this.$refs.file &&
        this.$refs.file.files.length <= 3 &&
        this.acceptPolicy == true
      );
    },
    ShowLoginWarning() {
      let warningMessage =
        "Por favor  <a href='/login'> ingrese</a> al sistema para continuar o <a href='/register-client'>regístrese</a> si todavía no tiene una cuenta creada.";
      this.$store.dispatch("setIsWarningAlert", true);
      this.$store.dispatch("setGlobalMessage", warningMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateUserPhone(e) {
      e.preventDefault();
      this.phoneSubmit = true;
      this.processingUpdateUser = true;
      this.updateClientData.userId = this.user.id;
      if (this.isPhoneValid) {
        this.$store
          .dispatch("updateClient", this.updateClientData)
          .then(() => {
            this.phoneSubmit = false;
            this.processingUpdateUser = false;
            this.$refs.updateUserPhone.closeModal();
            this.budgetRegister();
          })
          .catch(() => {
            this.processingUpdateUser = false;
            let errorMessage =
              "No se ha podido actualizar su número de contacto, favor intente de nuevo";
            handToast.fire({
              icon: "error",
              title: errorMessage
            });
          });
      } else {
        this.processingRegister = false;
      }
    },
    phoneUpdate(val) {
      this.updateClientData.phoneNumber = val.nationalNumber;
      this.updateClientData.countryCode = val.countryCode;
      this.updateClientData.countryCallingCode = val.countryCallingCode;
      if (val.countryCode == "PY") {
        if (val.nationalNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    }
  },
  props: {
    msg: String
  },
  components: {
    VueCtkDateTimePicker,
    Modal,
    VuePhoneNumberInput
  }
};
</script>
<style scoped>
@import "../../assets/css/index.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
<style>
.se-col .heading {
  font-size: 28px;
  color: #375be5;
  line-height: 1.1;
}
.se-col .subheading {
  font-size: 24px;
  color: #888888;
  line-height: 1.1;
}
.footer-section {
  align-items: end;
}
.bg-pattern-footer {
  background-image: url("../../assets/img/bg-footer-sec.png");
  background-size: cover;
  background-position: center center;
}
.logo-col {
  align-items: center;
}
.logo-heading {
  color: #3c3e47;
  font-size: 29px;
}
</style>
