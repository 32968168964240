<template>
  <div class="input">
    <div class="flex-1">
      <input
        :type="show_password ? 'text': 'password'"
        :value="value"
        class="password-input"
        placeholder="Ingresa tu contraseña"
        @input="$emit('input', $event.target.value)"
      />
    </div>
    <div class="visibility-trigger" @click="show_password=!show_password">
      <component  :is="show_password ? 'eye-slash-icon':'eye-icon'" :color="'#999999'" :width="20" :height="20" ></component>
    </div>
  </div>
</template>
<script>
import {EyeIcon,EyeSlashIcon} from "@/components/v2/icons";

export default {
  components:{
    EyeIcon,
    EyeSlashIcon
  },
  props:{
    value:{
      type: String
    }
  },
  data()
  {
    return {
      show_password:false
    }
  }
}
</script>
<style scoped>
.input{
  width: 100%;
  height: 37px;
  border-radius: 4px;
  border:1px solid #CCCCCC;
  display: flex;
  background-color: white;
}

.input .visibility-trigger{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input .password-input{
  width: 100%;
  padding-left: 18px;
  height: 100%;
}

</style>
