<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <ProfileSidebar />
          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between lg:flex-row lg:items-center">
              <h4 class="flex mb-0 lg:mt-0">
                <span>
                  <svg class="mr-2 icon">
                    <use
                      href="../assets/img/icons.svg#icon-chat-round-content"
                      xlink:href="../assets/img/icons.svg#icon-chat-round-content"
                    />
                  </svg>
                </span>
                <span
                  v-if="messageDetailData.orderDetail && messageDetailData.orderDetail.length > 0"
                >
                  {{ messageDetailData.orderDetail[0].description }}
                </span>
              </h4>
            </div>

            <router-link
              :to="{
                name: 'BudgetRequestSection',
                params: { id: messageDetailData.orderDetail[0].id }
              }"
              class="text-brand-secondary flex items-center mb-4"
              v-if="
                messageDetailData.orderDetail &&
                  messageDetailData.orderDetail.length > 0 &&
                  messageData.type == 'budget'
              "
            >
              <svg class="text-brand-secondary icon icon--lg">
                <use
                  href="../assets/img/icons.svg#icon-left"
                  xlink:href="../assets/img/icons.svg#icon-file-content"
                />
              </svg>
              <span>Go Back To Budget Request</span>
            </router-link>
            <router-link
              :to="{
                name: 'ServiceRequestSection',
                params: {
                  id: messageDetailData.orderDetail[0].id
                }
              }"
              class="text-brand-secondary items-center mb-4 inline-block"
              v-if="
                messageDetailData.orderDetail &&
                  messageDetailData.orderDetail.length > 0 &&
                  messageData.type == 'servicerequest'
              "
            >
              <svg class="text-brand-secondary icon icon--lg">
                <use
                  href="../assets/img/icons.svg#icon-left"
                  xlink:href="../assets/img/icons.svg#icon-file-content"
                />
              </svg>
              <span>Volver a orden</span>
            </router-link>
            <button
              type="button"
              class="btn btn--primary ml-4"
              @click="$refs.orderDetails.openModal()"
            >
              Ver detalle de orden
            </button>
            <section
              class="flex mb-4"
              v-bind:class="{
                'md:mr-56': user.id != message.sentBy,
                'flex-row-reverse md:ml-56': user.id == message.sentBy
              }"
              v-for="(message, index) in messageDetailData.messageDetail"
              :key="index"
            >
              <figure
                class="bg-white border border-gray-200 avatar"
                v-bind:class="{
                  'mr-2': user.id != message.sentBy,
                  'ml-2': user.id == message.sentBy
                }"
              >
                <img
                  v-if="message.sentBy == messageDetailData.client.id"
                  :src="messageDetailData.client.pictureProfileUrl"
                  :alt="
                    messageDetailData.client.firstName + ' ' + messageDetailData.client.lastName
                  "
                />
                <img
                  v-if="message.sentBy == messageDetailData.professional.id"
                  :src="messageDetailData.professional.pictureProfileUrl"
                  :alt="
                    messageDetailData.professional.firstName +
                      ' ' +
                      messageDetailData.professional.lastName
                  "
                />
              </figure>
              <div
                class="flex-1 p-4 border border-gray-200 rounded"
                v-bind:class="{
                  'bg-white': user.id != message.sentBy,
                  'bg-gray-200': user.id == message.sentBy
                }"
              >
                <small
                  class="text-xs text-gray-400"
                  v-bind:class="{
                    'text-gray-400': user.id != message.sentBy,
                    'text-gray-700': user.id == message.sentBy
                  }"
                  >{{ moment(message.createdAt).format("DD/MM/YY  HH:MM") }}hs</small
                >
                <p class="mb-0">
                  {{ message.description }}
                </p>
                <p class="mb-0" v-if="message.attachFile">
                  <a :href="message.attachFile.fileUrl" target="_blank">{{
                    message.attachFile.description
                  }}</a>
                </p>
              </div>
            </section>
            <form @submit.prevent="submitMessage()" method="post">
              <textarea
                id="message-textarea"
                class="w-full p-4 mb-2 shadow-md md:h-24 form-textarea"
                placeholder="Escribe tu mensaje"
                v-model="messageData.description"
                v-bind:class="{
                  'bg-red-100 border-red-700':
                    submited && !messageData.description && !this.attachFile
                }"
              ></textarea>
              <div class="flex mb-2">
                <span class="error" v-if="submited && !messageData.description && !this.attachFile"
                  >Favor ingrese una descripción</span
                >
              </div>
              <div class="flex  professional-profile">
                <!-- <div
                  class="p-2 w-16 h-auto rounded rounded-r-none flex items-center justify-center bg-info-icon-yellow text-center"
                >
                  <img src="../assets/img/Icon-Vector.svg" class="inline-block" />
                </div> -->
                <div class="w-full  rounded rounded-l-none p-2 bg-info-content-yellow text-black">
                  Le recomendamos seguir las comunicaciones dentro de la plataforma, para una mejor
                  trazabilidad del trabajo, seguimiento, seguridad y garantía. No dejar datos
                  personales como celular o email. Si necesita ayuda adicional haga click aqui
                  <a href="https://wa.me/595986130808" target="_blank">
                    <img src="../assets/img/whatsapp.svg" class="icon " />
                    0986-130-808
                  </a>
                </div>
              </div>
              <div
                class="grid mb-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                v-if="attachFilePreview"
              >
                <figure class="relative border border-gray-200 rounded shadow">
                  <img :src="attachFilePreview" class="w-full" />
                  <span
                    v-show="attachFilePreview"
                    @click="removeattachFile()"
                    class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                    >x</span
                  >
                </figure>
              </div>
              <div class="flex mb-2">
                <span
                  class="error"
                  v-if="this.attachFile && this.attachFile[0].size > 1024 * 1024 * 50"
                  >File size must be less then 50MB</span
                >
              </div>
              <div class="flex justify-end mb-2">
                <!-- new image -->
                <div>
                  <input
                    class="hidden w-full mt-1 mb-2 form-input"
                    type="file"
                    name="attachFile"
                    @change="attachFileAdd"
                    ref="attachFile"
                  />
                </div>
                <div>
                  <button type="button" @click="$refs.attachFile.click()" class="btn btn--primary">
                    Adjuntar Archivo
                  </button>
                </div>
                <!-- new image end -->

                <button
                  type="submit"
                  class="w-full btn btn--success lg:w-auto ml-6"
                  :disabled="processingSaveMessage"
                >
                  Enviar mensaje
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </section>
    <!-- modal start-->
    <div class="order-details control-panel professional-profile">
      <modal ref="orderDetails">
        <template v-slot:header>
          <h1></h1>
        </template>

        <template v-slot:body>
          <div
            class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
            v-for="(serviceRequest, serviceRequestIndex) in messageDetailData.orderDetail"
            :key="'message_order_details' + serviceRequestIndex"
          >
            <header
              :id="'service_' + serviceRequest.id"
              class="items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white md:border-b border-gray-200"
            >
              <div class="flex flex-wrap text-center md:text-left">
                <div class="w-auto text-base text-black font-semibold"></div>
                <div class="w-full md:w-auto text-center md:text-right md:ml-auto text-black">
                  <span>Orden: {{ serviceRequest.id }}</span>
                </div>
              </div>
            </header>
            <hr class="mt-3 border-gray-200 block md:hidden" />
            <div>
              <div class="flex flex-col justify-between lg:flex-row">
                <section class="w-full">
                  <section class="my-4 bg-white">
                    <div class="flex flex-wrap">
                      <div class="w-full px-2 md:w-2/5">
                        <div class="flex items-center">
                          <figure class="avatar w-16 h-16">
                            <img
                              :src="serviceRequest.User.pictureProfileUrl"
                              :alt="
                                serviceRequest.User.firstName + ' ' + serviceRequest.User.lastName
                              "
                            />
                          </figure>
                          <div class="ml-3">
                            <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                              {{ serviceRequest.User.firstName }}
                              {{ serviceRequest.User.lastName }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="w-full text-center my-4 block md:hidden">
                        <hr class="mt-3 border-gray-200" />
                        <div class="-mt-5 w-24 mx-auto bg-white">
                          <img
                            src="../assets/img/icon-flecha-down.svg"
                            alt=""
                            class="inline-block"
                          />
                        </div>
                      </div>
                      <div class="w-full px-2 md:w-1/5 text-center hidden md:block">
                        <img
                          src="../assets/img/icon-flecha-arrow.svg"
                          alt=""
                          class="inline-block"
                        />
                      </div>

                      <div class="w-full px-2 md:w-2/5">
                        <div class="flex items-center">
                          <figure class="mr-3 avatar w-16 h-16 block md:hidden">
                            <img
                              :src="serviceRequest.Professional.pictureProfileUrl"
                              :alt="
                                serviceRequest.Professional.firstName +
                                  ' ' +
                                  serviceRequest.Professional.lastName
                              "
                            />
                          </figure>
                          <div class="ml-0 md:ml-auto">
                            <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                              {{ serviceRequest.Professional.firstName }}
                              {{ serviceRequest.Professional.lastName }}
                            </h6>
                          </div>
                          <figure class="ml-3 avatar w-16 h-16 md:block hidden">
                            <img
                              :src="serviceRequest.Professional.pictureProfileUrl"
                              :alt="
                                serviceRequest.Professional.firstName +
                                  ' ' +
                                  serviceRequest.Professional.lastName
                              "
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <hr class="mt-3 border-gray-200 block md:hidden" />
                    <hr class="mt-3 border-gray-200 hidden md:block -mx-5" />
                    <div class="-mx-5">
                      <h6 class="my-4 text-black font-semibold text-center block md:hidden">
                        Servicio/s solicitado/s:
                      </h6>
                      <table class="w-full text-left border-collapse p-4 mt-0">
                        <thead class="hidden md:table-header-group">
                          <tr class="flex border-b border-gray-200 table-row text-black">
                            <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                            <th class="px-4 py-3">Precio:</th>
                            <th class="px-4 py-3 text-right">Cantidad:</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="flex border-b border-gray-200 table-row"
                            v-for="(Service, ServiceIndex) in serviceRequest.Services"
                            :key="'service_status' + ServiceIndex"
                          >
                            <td class="px-2 md:px-4 py-2">
                              <div class="flex items-center">
                                <div>
                                  <h6 class="my-0 text-gray-900">
                                    {{ Service.description }}
                                  </h6>
                                  <span class="text-black text-xl block md:hidden">
                                    Gs. {{ Service.price | thousandSeparator }}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td class="px-4 py-2 hidden md:table-cell">
                              <span class="text-black text-xl">
                                Gs. {{ Service.price | thousandSeparator }}
                              </span>
                            </td>
                            <td class="px-2 md:px-4 py-2 text-right">
                              <span class="text-black text-xl md:mx-4 mx-2">
                                {{ Service.quantity }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>

                  <section class="my-4 overflow-hidden bg-white">
                    <h5 class="font-semibold text-base">
                      <img src="../assets/img/icon-agenda.svg" class="inline-block mr-1" />
                      Fecha y horario del servicio
                    </h5>

                    <div class="flex flex-wrap my-4" style="background-color: #e4e9fb">
                      <div
                        class="w-full px-3 md:px-6 py-3 md:w-1/2 border-b md:border-b-0 md:border-r border-gray-600"
                      >
                        <p class="mb-0">Solicitado/s el:</p>
                        <p class="text-lg mb-4 font-bold text-black capitalize">
                          {{ moment(serviceRequest.createdOn).format("dddd ,D MMMM, YYYY") }}
                          <br />
                          {{ moment(serviceRequest.createdOn).format("LT") }}
                          Hs.
                        </p>
                        <button class="btn w-full bg-white hover:bg-white clr-orange-btn px-2">
                          <span class="justify-between text-base">
                            {{ lapsedCreatedTime(serviceRequest.createdOn) }}</span
                          >
                        </button>
                      </div>
                      <div class="w-full px-3 md:px-6 py-3 md:w-1/2 border-gray-600">
                        <p class="mb-0">Agendado/s para:</p>
                        <p class="text-lg mb-4 font-bold text-black capitalize">
                          {{
                            moment(serviceRequest.serviceScheduledDate).format("dddd ,D MMMM, YYYY")
                          }}
                          <br />
                          {{ moment(serviceRequest.serviceScheduledDate).format("LT") }}
                          Hs.
                        </p>
                        <button class="btn w-full bg-white hover:bg-white clr-blue-btn px-2">
                          <span class="justify-between text-base">
                            {{ lapsedScheduledTime(serviceRequest.serviceScheduledDate) }}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="flex my-4">
                      <div
                        class="p-2 w-16 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                      >
                        <img src="../assets/img/icon-agenda-black.svg" class="inline-block" />
                      </div>
                      <div class="w-full p-2 bg-info-content-yellow text-black">
                        El profesional recibirá un pedido de reserva para el día y el horario, en
                        caso de que le quede bien recibirá una confimación, en caso de que no pueda
                        cumplir con el horario le envíara un día y horario alternativo que podrá
                        aceptar o sugerir otro día y horario.
                      </div>
                    </div>
                  </section>

                  <hr class="my-3 -mx-5 border-gray-200" />
                  <section class="my-4 overflow-hidden bg-white">
                    <p class="text-black text-center" v-if="serviceRequest.Photos.length >= 0">
                      {{ serviceRequest.Photos.length }} imágenes adjuntas para una mejor
                      descripción de lo requerido
                    </p>
                    <div
                      class="grid mb-4 grid-columns md:grid-columns-3 grid-gap-1 p-2 rounded"
                      style="background-color: #dddddd"
                      v-if="serviceRequest.Photos"
                    >
                      <figure
                        class="p-1 border border-gray-200 rounded shadow-sm relative"
                        v-for="Photo in serviceRequest.Photos"
                        :key="'photo_' + Photo.id"
                      >
                        <a :href="Photo.photoUrl" role="button" download>
                          <img :src="Photo.photoUrl" class="w-full" />
                        </a>
                      </figure>
                      <figure
                        class="p-1 border border-gray-200 rounded shadow-sm hidden md:block"
                        v-for="index in 3 - serviceRequest.Photos.length"
                        :key="index"
                      >
                        <img src="../assets/img/default.jpg" class="w-full" />
                      </figure>
                    </div>
                  </section>
                </section>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfileSidebar from "@/components/ProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import getMessageData from "@/utils/methods/getMessageData";
import submitMessage from "@/utils/methods/submitMessage";
import validateMessageForm from "@/utils/methods/validateMessageForm";
import readMessage from "@/utils/methods/readMessage";
import getMessagesCount from "@/utils/methods/getMessagesCount";
import scrollToBottom from "@/utils/methods/scrollToBottom";
import attachFileAdd from "@/utils/methods/attachFileAdd";
import removeattachFile from "@/utils/methods/removeattachFile";
import Modal from "@/components/Modal";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import getServiceRequestData from "@/utils/methods/getServiceRequestData";
import lapsedCreatedTime from "@/utils/methods/lapsedCreatedTime";
import lapsedScheduledTime from "@/utils/methods/lapsedScheduledTime";

export default {
  data() {
    return {
      submited: false,
      processingGetMessage: false,
      processingSaveMessage: false,
      processingReadMessage: false,
      messageData: {
        id: null,
        description: "",
        type: "",
        status: ""
      },
      messageDetailData: { orderDetail: {} },
      messagesCount: 0,
      interval: null,
      messageTimeout: process.env.VUE_APP_MESSAGE_TIMEOUT,
      attachFile: null,
      attachFilePreview: null
    };
  },
  mounted() {
    if (this.$route.params.otype && this.$route.params.oid) {
      this.messageData.id = this.$route.params.oid;
      this.messageData.type = this.$route.params.otype;
      if (this.$route.params.ostatus) {
        this.messageData.status = this.$route.params.ostatus;
      }
      this.readMessage();
      this.getMessageData(this.messageData.id, this.messageData.type);
      this.interval = setInterval(() => this.getMessagesCount(), this.messageTimeout * 1000);
    } else {
      this.$router.push({ name: "Message" });
    }
    this.scrollToBottom();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getMessageData,
    getServiceRequestData,
    submitMessage,
    validateMessageForm,
    getMessagesCount,
    scrollToBottom,
    readMessage,
    attachFileAdd,
    removeattachFile,
    lapsedCreatedTime,
    lapsedScheduledTime
  },
  filters: {
    thousandSeparator
  },
  computed: {
    ...mapGetters(["error", "user"])
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfileSidebar,
    Modal
  }
};
</script>
<style lang="css" scoped>
@import "../assets/css/dashboard.css";
@import "../assets/css/control-panel.css";
.success {
  color: #1bb01b;
}
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
<style lang="css">
.order-details .modal__dialog {
  width: 60%;
}
.order-details .modal__footer {
  display: none;
}
.order-details .modal__body {
  padding: 10px;
}
@media screen and (max-width: 992px) {
  .order-details .modal__dialog {
    width: 90%;
  }
}
</style>
