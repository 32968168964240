<template>
  <svg :width="width" :height="height" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :class="`stroke-${color}`" d="M1.5 16C2.83468 13.7892 5.75985 12.2762 10.3235 12.2762C14.8872 12.2762 17.8124 13.7892 19.1471 16M13.9487 4.6C13.9487 6.58823 12.3257 8.2 10.3235 8.2C8.32141 8.2 6.69836 6.58823 6.69836 4.6C6.69836 2.61177 8.32141 1 10.3235 1C12.3257 1 13.9487 2.61177 13.9487 4.6Z"  stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:20
    },
    height:{
      type:Number,
      default:17
    },
    color:{
      type:String,
      default:'black'
    }
  }
}
</script>
