<template>
  <div class="professional-profile">
    <Header />
    <div class="container">
      <div class="flex flex-col justify-between mt-4 -mx-2 lg:flex-row">
        <section class="lg:w-9/12 md:pr-2 md:px-0 px-6">
          <div class="flex flex-col md:flex-row mb-2">
            <div class="w-auto text-center">
              <Breadcrumb :items="BreadcrumbItems" />
            </div>
            <div class="w-auto text-center md:text-right md:ml-auto">
              <button
                @click="viewOtherProfessional()"
                class="btn btn--md bg-white hover:bg-white border border-gray-400 font-normal clr-grey-light px-4 py-1 text-base"
              >
                Otras profesiones
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="modal-otherprofession">
      <div class="flex other-profession" v-if="profiledata" ref="otherProfession">
        <div class="w-full">
          <div class="px-6 bg-white">
            <h2 class="text-left mb-0 pt-4 pb-8 text-lg font-semibold border-b border-gray-200">
              {{ profiledata.firstName }} {{ profiledata.lastName }}
            </h2>
            <div class="py-2">
              <h5
                class="mb-2 text-base text-left"
                v-if="profiledata.Profession && profiledata.Profession[0]"
              >
                {{ profiledata.Profession[0].description }}
              </h5>
              <div class="flex items-center leading-none md:flex-row">
                <div class="mr-1 font-bold text-black">{{ profiledata.rate | numberFormat }}/5</div>
                <star-rating
                  inactive-color="#dfe0e6"
                  :show-rating="false"
                  active-color="#ffd83e"
                  :increment="0.5"
                  :star-size="20"
                  :read-only="true"
                  :rating="profiledata.rate | numberFormat"
                ></star-rating>
              </div>
            </div>
          </div>
          <div class="border-t border-gray-200" style="background-color: #f7fafc">
            <div class="px-6">
              <h5 class="mb-0 py-4 text-base text-center border-b border-gray-200 text-left">
                Otras profesiones
              </h5>
              <div
                class="flex border-b border-gray-200 py-2 items-center"
                v-for="(service, index) in profiledata.Services"
                :key="index"
              >
                <div class="w-auto">
                  <h5 class="mb-2 text-base mt-0 text-left">
                    {{ service.description }}
                  </h5>
                  <div class="flex items-center leading-none flex-row">
                    <div class="mr-1 font-bold text-black">
                      {{ profiledata.rate | numberFormat }}/5
                    </div>
                    <star-rating
                      inactive-color="#dfe0e6"
                      :show-rating="false"
                      active-color="#ffd83e"
                      :increment="0.5"
                      :star-size="20"
                      :read-only="true"
                      :rating="profiledata.rate | numberFormat"
                    ></star-rating>
                  </div>
                </div>
                <div class="w-auto text-right ml-auto">
                  <img src="../assets/img/icon-link.svg" class="inline-block" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Messages />

    <section>
      <div v-if="processingGetProfileData">
        <div class="container container-xl mb-2">
          <img src="../assets/img/loading-gif.gif" class="w-full inline-block" />
        </div>
      </div>
      <div v-else>
        <div class="container container-xl">
          <div class="flex flex-col justify-between my-4 -mx-2 lg:flex-row">
            <section class="lg:w-9/12 md:pr-2 md:px-0 px-6">
              <article class="mb-8 bg-white border border-gray-400 rounded">
                <div class="flex flex-wrap">
                  <figure class="sm:flex-none flex-1 p-3">
                    <img
                      :src="profiledata.pictureProfileUrl"
                      :alt="profiledata.firstName + ' ' + profiledata.lastName"
                      class="w-full md:w-40 rounded"
                    />
                  </figure>

                  <header class="flex-1 p-3">
                    <h2 class="my-0 mb-2 md:mb-8 text-lg font-semibold ">
                      <span class="mr-2"
                        >{{ profiledata.firstName }} {{ profiledata.lastName }}
                      </span>
                      <span
                        v-if="profiledata.isAvailable"
                        class="inline-block md:inline-flex mt-2 md:mt-0 items-center justify-center px-3 py-3  text-base font-bold leading-none text-white bg-green-btn rounded-full"
                        >Disponible</span
                      >
                      <span
                        v-else
                        class="inline-block md:inline-flex mt-2 md:mt-0 items-center justify-center px-3 py-3  text-base font-bold leading-none text-white bg-red-600 rounded-full"
                        >No Disponible</span
                      >
                    </h2>
                    <hr />
                    <div class="my-2">
                      <h5
                        class="mb-2 text-base"
                        v-if="profiledata.Profession && profiledata.Profession[0]"
                      >
                        {{ profiledata.Profession[0].description }}
                      </h5>
                      <div class="flex items-center">
                        <div class="flex items-center leading-none md:flex-row">
                          <div class="mr-1 font-bold text-black">
                            {{ profiledata.rate | numberFormat }}/5
                          </div>
                          <star-rating
                            inactive-color="#dfe0e6"
                            :show-rating="false"
                            active-color="#ffd83e"
                            :increment="0.5"
                            :star-size="20"
                            :read-only="true"
                            :rating="profiledata.rate | numberFormat"
                          ></star-rating>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="grid grid-columns-2 grid-gap-4 my-2">
                      <div>
                        <p class="mb-0 text-sm text-gray-500">C.I:</p>
                        <h6 class="mt-0 mb-0">
                          ****.{{ profiledata.identificationNumber | lastthree }}
                        </h6>
                      </div>
                      <div>
                        <p class="mb-0 text-sm text-gray-500">
                          Zona de cobertura:
                        </p>
                        <h6 class="mt-0 mb-0" v-if="profiledata.Location">
                          {{ profiledata.Location.description }}
                        </h6>
                      </div>
                    </div>
                  </header>

                  <div
                    class="w-full md:w-auto py-3 px-6 leading-tight"
                    style="background-color: #e4e9fb"
                  >
                    <ul
                      class="grid grid-cols-2 md:grid-cols-1 md:grid-gap-2 md:text-left text-center"
                    >
                      <li class="md:border-none border-r-2 border-gray-600">
                        <p class="mb-0">
                          Servicios <br />
                          solicitados
                        </p>
                        <p
                          class="text-2xl mb-4 font-bold text-black"
                          v-if="profiledata.servicesCounter"
                        >
                          {{ profiledata.servicesCounter.total }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0">
                          Servicios <br />
                          concretados
                        </p>
                        <p
                          class="text-2xl font-bold text-black mb-0"
                          v-if="profiledata.servicesCounter"
                        >
                          {{ profiledata.servicesCounter.concrete }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </article>
              <h4 class="mb-4">
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-file-content"
                    xlink:href="../assets/img/icons.svg#icon-file-content"
                  />
                </svg>
                Resumen
              </h4>
              <article class="p-5 mb-8 bg-white border border-gray-400 rounded">
                <p class="mb-0 text-sm" style="color: #686b7b">Sobre mí</p>
                <p class="mt-0 mb-2 text-black">
                  {{ profiledata.coverLetter }}
                </p>
                <hr />
                <p class="mb-0 text-sm  mt-2" style="color: #686b7b">
                  Documentaciones al día
                </p>
                <ul class="grid md:grid-columns-3 text-black font-semibold">
                  <li class="flex items-center mb-3" v-if="profiledata.hasJudicialRecords">
                    <svg class="flex-none icon">
                      <use
                        href="../assets/img/icons.svg#icon-check-circle-alt"
                        xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                      />
                    </svg>
                    <span class="ml-2">Antecedentes Judiciales</span>
                  </li>
                  <li class="flex items-center mb-3" v-if="profiledata.hasPoliceRecords">
                    <svg class="flex-none icon">
                      <use
                        href="../assets/img/icons.svg#icon-check-circle-alt"
                        xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                      />
                    </svg>
                    <span class="ml-2">Antecedentes Policiales</span>
                  </li>
                  <li class="flex items-center mb-3" v-if="profiledata.hasIdentificationNumber">
                    <svg class="flex-none icon">
                      <use
                        href="../assets/img/icons.svg#icon-check-circle-alt"
                        xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                      />
                    </svg>
                    <span class="ml-2">Cedula de Identidad</span>
                  </li>
                  <li class="flex items-center mb-3" v-if="profiledata.hasLaboralReferences">
                    <svg class="flex-none icon">
                      <use
                        href="../assets/img/icons.svg#icon-check-circle-alt"
                        xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                      />
                    </svg>
                    <span class="ml-2">Referencias Laboralales</span>
                  </li>
                  <li
                    class="flex items-center mb-3"
                    v-if="profiledata.hasProfessionalCertification"
                  >
                    <svg class="flex-none icon">
                      <use
                        href="../assets/img/icons.svg#icon-check-circle-alt"
                        xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                      />
                    </svg>
                    <span class="ml-2">Certificación Profesional</span>
                  </li>
                  <li class="flex items-center mb-3" v-if="profiledata.hasAddressVerified">
                    <svg class="flex-none icon">
                      <use
                        href="../assets/img/icons.svg#icon-check-circle-alt"
                        xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                      />
                    </svg>
                    <span class="ml-2">Dirección y Domicilio</span>
                  </li>
                </ul>
              </article>

              <h4 class="mb-4">
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-chat-round-content"
                    xlink:href="../assets/img/icons.svg#icon-chat-round-content"
                  />
                </svg>
                Testimonios ({{ profiledata.opinionsCount }})
              </h4>
              <div class="mb-8">
                <div class="grid grid-columns-1 md:grid-gap-4 md:grid-columns-2">
                  <article
                    class="p-3 mb-2 bg-white border border-gray-400 rounded"
                    v-for="(Opinion, index) in profiledata.Opinions"
                    :key="index"
                  >
                    <div class="flex">
                      <figure class="flex-none mr-2 overflow-hidden w-16 h-16 rounded-full">
                        <img
                          :src="Opinion.pictureProfileUrl"
                          :alt="Opinion.firstName + ' ' + Opinion.lastName"
                        />
                      </figure>

                      <div class="flex-1 pl-2">
                        <div class="flex flex-col">
                          <div class="mr-2 mb-2">
                            <h6 class="my-0 text-sm">
                              {{ Opinion.firstName }} {{ Opinion.lastName }}
                            </h6>
                          </div>

                          <div class="flex items-center leading-none md:flex-row mb-2">
                            <div class="mr-1 font-bold text-black">
                              {{ Opinion.rate | numberFormat }}/5
                            </div>
                            <star-rating
                              :padding="1"
                              inactive-color="#dfe0e6"
                              :show-rating="false"
                              active-color="#ffd83e"
                              :increment="0.5"
                              :star-size="20"
                              :read-only="true"
                              :rating="Opinion.rate | numberFormat"
                            ></star-rating>
                          </div>
                        </div>
                        <p class="text-black  font-semibold">
                          {{ Opinion.description }}
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
                <div class="flex my-4 justify-center" v-if="profiledata.opinionsCount > 0">
                  <button
                    class="btn btn--md bg-transparent hover:bg-transparent text-base border-2 px-6 border-gray-400 font-normal clr-grey"
                  >
                    Ver más testimonios
                  </button>
                </div>
              </div>


              <h4 class="mb-4">
                <svg class="icon">
                  <use
                    href="../assets/img/icons.svg#icon-polaroid-user"
                    xlink:href="../assets/img/icons.svg#icon-polaroid-user"
                  />
                </svg>
                Fotografías
              </h4>

              <div class="flex mb-2 px-0 md:px-6 hidden md:flex">
                <div class="w-auto">
                  <p class="clr-blue font-semibold">
                    Haga click sobre la imagen para pre-visualizar
                  </p>
                </div>
              </div>
              <div class="grid mb-8 grid-columns-4 md:grid-columns-4 grid-gap-2"
                v-if="profiledata.Photos && profiledata.Photos.length > 0"
              >
                <figure
                  class="relative border border-gray-200 rounded shadow"
                  @click="viewFullImage(img.photoUrl)"
                  v-for="(img, key) in profiledata.Photos"
                  :key="key"
                  role="button"
                >
                  <div
                    :style="{'background':'url('+img.photoUrl+')'}"
                    class="w-full minimum-height bg-cover"
                  ></div>
                </figure>
              </div>

              <h4 class="mb-4">
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-chat-round-content"
                    xlink:href="../assets/img/icons.svg#icon-chat-round-content"
                  />
                </svg>
                Referencias ({{ referenceCount }})
              </h4>
              <div class="mb-8">
                <div class="grid grid-columns-1 md:grid-gap-4 md:grid-columns-2">
                  <article
                    class="p-3 mb-2 bg-white border border-gray-400 rounded"
                    v-if="profiledata.laboralReferenceName1 || profiledata.reference1Description"
                  >
                    <div class="flex">
                      <div class="">
                        <div class="flex flex-col">
                          <div class="mr-2 mb-2">
                            <h6 class="my-0 text-sm">
                              {{ profiledata.laboralReferenceName1 }}
                            </h6>
                          </div>
                        </div>
                        <p class="text-black">
                          {{ profiledata.reference1Description }}
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    class="p-3 mb-2 bg-white border border-gray-400 rounded"
                    v-if="profiledata.laboralReferenceName2 || profiledata.reference2Description"
                  >
                    <div class="flex">
                      <div class="">
                        <div class="flex flex-col">
                          <div class="mr-2 mb-2">
                            <h6 class="my-0 text-sm">
                              {{ profiledata.laboralReferenceName2 }}
                            </h6>
                          </div>
                        </div>
                        <p class="text-black">
                          {{ profiledata.reference2Description }}
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </section>
            <aside class="px-6 md:px-4 mb-4 lg:w-3/12 hidden md:block"></aside>
          </div>
          <div class="flex flex-col justify-between mt-4 -mx-2 lg:flex-row">
            <section class="lg:w-9/12 md:pr-2 md:px-0 px-6">
              <div class="flex flex-col md:flex-row">
                <div class="w-full md:w-2/3">
                  <h4>
                    <svg class="-mt-2 icon">
                      <use
                        href="../assets/img/icons.svg#icon-todo"
                        xlink:href="../assets/img/icons.svg#icon-todo"
                      />
                    </svg>
                    Servicios ofrecidos
                  </h4>
                </div>
                <div class="w-full md:w-1/3">
                  <div class="flex items-stretch mb-4 text-lg shadow-xl md:flex-row">
                    <input
                      type="text"
                      placeholder="Filtrar servicios"
                      class="text-gray-800 placeholder-gray-800 form-input w-4/5 md:mb-0 rounded-r-none h-auto"
                      v-model="searchQuery"
                    />

                    <button
                      class="justify-center font-semibold btn btn--primary w-1/5 h-auto rounded-l-none icon-text-aligner"
                      @click="searchService()"
                    >
                      <svg class="text-white icon icon--outline icon--stroke-2 icon--md">
                        <use
                          href="../assets/img/icons.svg#icon-search"
                          xlink:href="../assets/img/icons.svg#icon-search"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex mb-2 px-0 md:px-6 hidden md:flex">
                <div class="w-auto">
                  <p class="clr-blue font-semibold">
                    Por favor seleccione la casilla de cada servicio que necesita
                  </p>
                </div>
                <div class="w-12 text-right ml-auto">
                  <img src="../assets/img/icon-arrow-down.svg" class="inline-block" />
                </div>
              </div>
              <section
                class="overflow-hidden bg-white border border-b-0 border-gray-400 rounded rounded-b-none"
              >
                <div class="px-5 py-2 block md:hidden">
                  <div class="flex items-center">
                    <div class="w-auto">
                      <p class="clr-blue font-semibold">
                        Por favor seleccione la casilla de cada servicio que necesita
                      </p>
                    </div>
                    <div class="w-12 text-right ml-auto px-2">
                      <img src="../assets/img/icon-arrow-down.svg" class="inline-block" />
                    </div>
                  </div>
                </div>
                <table
                  class="w-full text-left border-collapse p-4 mt-0 service-list-table"
                  v-bind:class="{
                    OnErrorScrollHere: servicesubmited && servicedata.services.length <= 0
                  }"
                >
                  <tbody>
                    <tr
                      class="flex border-b border-gray-200 table-row"
                      v-for="(Service, index) in services.slice(0, showService)"
                      :key="index"
                    >
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div>
                            <h6 class="my-0 text-gray-900 font-semibold">
                              {{ Service.description }}
                            </h6>
                            <span
                              class="mr-4 text-black text-xl block md:hidden font-semibold"
                              v-if="Service.price"
                            >
                              Gs.{{ Service.price | thousandSeparator }}
                            </span>
                            <span
                              class="mr-4 text-black text-xl block md:hidden font-semibold"
                              v-else
                            >
                              Gs. 0
                            </span>
                          </div>
                        </div>
                      </td>

                      <td class="px-6 py-4 text-right">
                        <span
                          class="mr-4 text-black text-xl hidden md:inline-block font-semibold"
                          v-if="Service.price"
                        >
                          Gs.{{ Service.price | thousandSeparator }}
                        </span>
                        <span
                          class="mr-4 text-black text-xl hidden md:inline-block font-semibold"
                          v-else
                        >
                          Gs. 0
                        </span>
                        <input
                          type="checkbox"
                          class="form-checkbox"
                          v-model="selectedService"
                          :value="Service.id"
                          @change="addService($event, Service)"
                          v-bind:class="{
                            'bg-red-100 border-red-700 OnErrorScrollHere':
                              servicesubmited && servicedata.services.length <= 0
                          }"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="px-5">
                  <span class="error" v-if="servicesubmited && servicedata.services.length <= 0"
                    >Debe seleccionar al menos un servicio</span
                  >
                </div>
                <div class="p-5">
                  <div class="flex my-8 items-center justify-center" v-if="services.length == 0">
                    <div class="w-full md:w-1/2">
                      <p class="text-black text-center font-medium md:px-8 text-lg mb-5">
                        Su búsqueda no dio resultados por favor revise el texto de su búsqueda o
                        cambie su búsqueda
                      </p>

                      <div class="flex my-4 justify-center">
                        <button
                          class="btn btn--md font-medium bg-red-btn px-6 py-2"
                          @click="clearSearchService()"
                        >
                          Borrar búsqueda
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="flex mb-4">
                    <div class="w-auto">
                      <p class="clr-red">
                        Para quitar un servicio de la suma debe destildar la casilla.
                      </p>
                    </div>
                    <div class="w-12 text-right px-2 ml-auto">
                      <img src="../assets/img/icon-arrow-top.svg" class="inline-block" />
                    </div>
                  </div>
                  <div class="flex my-4 justify-center" v-if="services.length > showService">
                    <button
                      class="btn btn--primary py-3 w-full md:w-2/3 lg:w-1/3"
                      @click="showMoreService()"
                    >
                      <img
                        src="../assets/img/icon-flecha.svg"
                        class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-left mr-auto"
                      />
                      <span class="justify-between text-base"> Mostrar 10 servicios más</span>
                      <img
                        src="../assets/img/icon-flecha.svg"
                        class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                      />
                    </button>
                  </div>
                  <div class="flex my-4">
                    <!-- <div
                      class="p-2 w-16 h-auto rounded rounded-r-none flex items-center justify-center bg-info-icon-yellow text-center"
                    >
                      <img src="../assets/img/Icon-Vector.svg" class="inline-block" />
                    </div> -->
                    <div
                      class="w-full  rounded rounded-l-none p-2 bg-info-content-yellow text-black"
                    >
                      Los servicios con precios podrían tener variaciones. Incluyen IVA y mano de
                      obra. Materiales depende de cada trabajo en particular. <br />
                      Hay trabajos que requieren diagnostico previo para presupuestar.
                    </div>
                  </div>
                </div>
              </section>
            </section>
            <aside class="px-6 md:px-4 mb-4 lg:w-3/12 hidden lg:block">
              <div class="md:sticky md:top-0">
                <div
                  class="text-center py-8 rounded mt-8 hidden lg:block relative"
                  style="background: #e4e9fb"
                >
                  <img
                    src="../assets/img/icon-informacion.svg"
                    class="absolute"
                    style="left: 45%; top: -9px"
                  />
                  <img src="../assets/img/icon-circle-arrow.svg" class="inline-block" />
                  <p class="clr-blue px-0 md:px-20 font-semibold">
                    Agregue uno o más servicios de la lista de la izquierda.
                  </p>
                </div>
              </div>
            </aside>
          </div>
          <div class="flex flex-col justify-between mb-4 -mx-2 lg:flex-row">
            <section class="lg:w-9/12 md:pr-2 md:px-0 px-6">
              <section
                class="mb-6  bg-white border border-t-0 border-gray-400 rounded rounded-t-none"
              >
                <div class="p-5 pt-0">
                  <h5
                    class="p-4 mt-0 -mx-5 font-semibold bg-gray-100 border-t border-b border-gray-200"
                  >
                    <img src="../assets/img/icon-agenda.svg" class="inline-block mr-1" />
                    Agende una fecha y horario para el servicio
                  </h5>
                  <div class="flex mb-4 items-center justify-center">
                    <div class="w-full md:w-2/3">
                      <div class="grid grid-cols-3 gap-4 px-5 md:px-0 my-4">
                        <div class="col-span-3 md:col-span-2">
                          <VueCtkDateTimePicker
                            v-model="servicedata.date"
                            color="#3e65ff"
                            buttonColor="#3e65ff"
                            :only-date="true"
                            :no-header="true"
                            :minDate="
                              moment()
                                .add(1, 'day')
                                .format('YYYY-MM-DD')
                            "
                            locale="es"
                            format="YYYY-MM-DD"
                            formatted="dddd, D MMMM, YYYY"
                            :noKeyboard="true"
                            id="serviceregisterdate"
                            class="schduledate-wrapper  shadow-md"
                            :noLabel="true"
                            :noButtonNow="true"
                            v-bind:class="{
                              'bg-red-100 border-red-700 OnErrorScrollHere':
                                submited && !budget.date
                            }"
                          />
                          <span class="error" v-if="servicesubmited && !servicedata.date"
                            >Please select date</span
                          >
                        </div>
                        <div class="col-span-3  md:col-span-1">
                          <div class="autocomplete" v-click-outside-time="clickOutsideTime">
                            <div @click="showTimeDropDown = true">
                              <vue-timepicker
                                format="HH:mm"
                                :minute-interval="15"
                                v-model="servicedata.time"
                                class="hand-time-picker"
                                :input-class="{
                                  'rounded shadow-md mb-0 w-full field-time skip-error-style form-control': true,
                                  'bg-red-100 border-red-700 OnErrorScrollHere':
                                    (servicesubmited && !servicedata.time) || !isTimeCorrect
                                }"
                                @error="TimeError"
                                manual-input
                                hide-clear-button
                                auto-scroll
                                hide-dropdown
                                fixed-dropdown-button
                              >
                                <template v-slot:dropdownButton> </template
                              ></vue-timepicker>
                            </div>
                            <div
                              id="myInputautocomplete-list"
                              class="autocomplete-items"
                              v-show="showTimeDropDown"
                            >
                              <div
                                v-for="hour in hourList"
                                :key="'time_' + hour"
                                @click="SetTime(hour)"
                              >
                                {{ hour }}
                              </div>
                            </div>
                          </div>
                          <label class="flex items-center mb-2">
                            <span class="error" v-if="servicesubmited && !servicedata.time"
                              >Please select time</span
                            >
                            <span class="error" v-if="servicesubmited && !isTimeCorrect"
                              >Please select valid minute value.</span
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5
                    class="p-4 mt-0 -mx-5 font-semibold bg-gray-100 border-t border-b border-gray-200"
                  >
                    <img src="../assets/img/icon-info.svg" class="inline-block mr-1" />
                    Información adicional
                  </h5>

                  <div class="flex flex-wrap py-2 md:py-5">
                    <div class="w-full  px-0 md:px-5">
                      <textarea
                        v-model="servicedata.description"
                        v-bind:class="{
                          'bg-red-100 border-red-700 OnErrorScrollHere':
                            servicesubmited && !servicedata.description
                        }"
                        class="block w-full mx-auto form-control h-40 text-black"
                        placeholder="Dejar más detalles sobre el trabajo, ejemplo:
Especificar urgencia.
Quien lo va recibir en el domicilio.
Necesidad de escalera, dificultad de acceso, o la información que consideres relevante para un mejor desempeño. "
                      ></textarea>
                      <label class="flex items-center mb-2">
                        <span class="error" v-if="servicesubmited && !servicedata.description"
                          >Favor ingrese una descripción</span
                        >
                      </label>
                    </div>
                  </div>
                  <p class="mt-0 mb-2 text-black">
                    Adjunta algunas imágenes para una mejor descripción de lo requerido
                  </p>
                  <div>
                    <input
                      class="hidden w-full mt-1 mb-2 form-input"
                      type="file"
                      name="photos[]"
                      @change="photosAdd"
                      placeholder="Nombre"
                      ref="photo"
                      multiple
                    />
                  </div>
                  <div
                    class="flex flex-col md:flex-row rounded-lg justify-between py-2 md:py-4 nav-step px-4 md:px-8 cursor-pointer"
                    style="background-color: #e4e9fb"
                    @click="$refs.photo.click()"
                  >
                    <div class="md:w-1/4 text-left hidden md:block">
                      <img src="../assets/img/icon-subir-nube.svg" class="inline-block" />
                    </div>
                    <div class="w-full md:w-1/2 text-center">
                      <p class="mt-0 mb-1 text-black">
                        Toque aquí o arrastre aquí los archivos que desea subir
                      </p>
                      <p class="mb-1 text-sm text-gray-500">
                        *.pdf, *.zip, *.jpg (puedes subir hasta 3 imágenes)
                      </p>
                    </div>
                    <div class="md:w-1/4">
                      <div class="md:text-right text-center">
                        <img src="../assets/img/icon-subir-nube.svg" class="inline-block" />
                      </div>
                    </div>
                  </div>
                  <span class="error" v-if="photos && photos.length > 3"
                    >You can upload Maximum 3 images.</span
                  >

                  <div v-if="imageSizeError">
                    <span class="error" v-html="imageSizeError"></span>
                  </div>
                  <div
                    class="grid my-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                    v-if="photo && photo.length > 0"
                  >
                    <figure
                      class="relative border border-gray-200 rounded shadow"
                      v-for="(img, key) in photo"
                      :key="key"
                    >
                      <img :src="img" class="w-full" />
                      <span
                        v-show="photo"
                        @click="removePhotoPreview(key)"
                        class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                        >x</span
                      >
                    </figure>
                  </div>
                </div>
              </section>
              <!-- <section
              class="px-4 py-4 mb-4 overflow-hidden bg-white border border-gray-400 rounded"
              style="background-color: #e4e9fb"
            >
              <div class="flex flex-col md:flex-row items-center justify-center">
                <div class="w-full md:w-3/4">
                  <p class=" text-black text-lg font-semibold mb-0 text-center md:text-left">
                    ¡Listo! Ahora puede solicitar su/s servicio/s con el botón verde de la
                    <span class="hidden md:inline-block">derecha</span>
                    <span class="inline-block md:hidden">abajo</span>.
                  </p>
                </div>
                <div class="w-full md:w-1/4 text-center md:text-right px-2">
                  <img src="../assets/img/icon-arrow-right.svg" class="hidden md:inline-block" />
                  <img
                    src="../assets/img/icon-arrow-down-green.svg"
                    class="inline-block md:hidden my-2"
                  />
                </div>
              </div>
            </section> -->

              <section class="p-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded">
                <h5 class="p-4 mt-0 -mx-5 -mt-5 font-semibold bg-gray-100 border-b border-gray-200">
                  <img src="../assets/img/icon-pin.svg" class="inline-block mr-1" />
                  ¿Donde necesita que se hagan el/los servicios?
                </h5>
                <div class="flex mb-4 items-center justify-center">
                  <div class="w-full md:w-2/3">
                    <p class="text-black text-center font-medium md:px-6 text-lg mt-5">
                      Ya nos contaste en que departamento y ciudad estas;
                    </p>
                    <div class="bg-gray-100 p-3 rounded">
                      <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                        <div>
                          <label>
                            <span>Departamento</span>

                            <select
                              class="block w-full mt-1 rounded form-select"
                              v-model="servicedata.department"
                              @change="GetDepartmentLocations()"
                              v-bind:class="{
                                'bg-red-100 border-red-700 OnErrorScrollHere':
                                  servicesubmited && !servicedata.department
                              }"
                            >
                              <option disabled v-if="activeDepartments.length <= 0" :value="null">
                                Departamento no encontrado
                              </option>
                              <option disabled v-else :value="null">
                                Seleccionar Departmento
                              </option>
                              <option
                                v-for="department in activeDepartments"
                                :key="department.id"
                                :value="department.id"
                              >
                                {{ department.description }}
                              </option>
                            </select>
                            <label class="flex items-center mb-2">
                              <span class="error" v-if="servicesubmited && !servicedata.department"
                                >Por favor Ingresa tu Departamento</span
                              >
                            </label>
                          </label>
                        </div>
                        <div>
                          <label>
                            <span>Ciudad</span>
                            <select
                              class="block w-full mt-1 rounded form-select"
                              v-model="servicedata.location_id"
                              v-bind:class="{
                                'bg-red-100 border-red-700 OnErrorScrollHere':
                                  servicesubmited && !servicedata.location_id
                              }"
                            >
                              <option
                                disabled
                                v-if="activeDepartmentLocations.length <= 0"
                                :value="null"
                              >
                                Ciudad no encontrada
                              </option>
                              <option disabled v-else :value="null">
                                Seleccione Ciudad
                              </option>
                              <option
                                v-for="departmentLocation in activeDepartmentLocations"
                                :key="departmentLocation.id"
                                :value="departmentLocation.id"
                              >
                                {{ departmentLocation.description }}
                              </option>
                            </select>
                            <label class="flex items-center mb-2">
                              <span class="error" v-if="servicesubmited && !servicedata.location_id"
                                >Por favor Ingresa tu Ciudad</span
                              >
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                    <p class="text-black text-center font-medium md:px-6 text-lg mt-5">
                      Nos falta saber tu dirección para que el profesional pueda encontrar el lugar
                    </p>
                    <div class="bg-gray-100 p-3 rounded">
                      <label>
                        <span>Dirección</span>
                        <textarea
                          v-model="servicedata.direction"
                          v-bind:class="{
                            'bg-red-100 border-red-700 OnErrorScrollHere':
                              servicesubmited && !servicedata.direction
                          }"
                          class="block w-full h-32 mx-auto form-control height-textarea text-black"
                          placeholder="Ingresa la dirección de tu casa aquí y alguna referencia para mejor ubicación"
                        ></textarea>
                      </label>
                      <label class="flex items-center mb-2">
                        <span class="error" v-if="servicesubmited && !servicedata.direction"
                          >Favor ingresar una dirección</span
                        >
                      </label>
                      <label>
                        <span>Mapa</span>
                        <section class="relative my-8 border border-gray-200 rounded md:px-0">
                          <div style="width: 100%; height: 300px">
                            <l-map :zoom="zoom" :center="markerLatLng" ref="mymap">
                              <l-tile-layer :url="url"></l-tile-layer>
                              <l-circle :lat-lng="circle.center" :radius="circle.radius" />
                              <l-marker
                                :lat-lng="markerLatLng"
                                :draggable="true"
                                @update:lat-lng="locationUpdate"
                                ref="marker"
                              >
                                <l-popup>Mové este punto a tu ubicación</l-popup></l-marker
                              >
                            </l-map>
                          </div>
                        </section>
                      </label>
                      <div class="flex my-4 justify-center">
                        <button
                          class="btn btn--md btn--primary px-8 py-3"
                          :disabled="gettingLocation"
                          @click.prevent="getGeoLocation()"
                        >
                          Ubicame en el mapa
                        </button>
                      </div>
                      <div class="flex flex-col md:flex-row justify-center">
                        <div
                          class="md:w-16 w-full h-auto flex items-center justify-center text-center"
                        >
                          <img src="../assets/img/icon-atencion-blue.svg" class="inline-block" />
                        </div>
                        <div class="w-auto px-2 clr-blue md:text-left text-center">
                          Si el sistema automático no te ubica correctamente en el mapa, por favor
                          reubica el pin azúl manualmente al lugar correcto.
                        </div>
                      </div>
                    </div>
                    <div class="flex my-4 justify-center">
                      <button
                        class="btn btn--primary text-xl py-4 bg-green-btn "
                        v-if="isAuthenticated"
                        @click="validateServiceRegister"
                      >
                        Solicitar servicio/s ahora
                      </button>
                      <button
                        class="btn btn--primary text-xl py-4 bg-green-btn "
                        v-else
                        @click="ShowLoginWarning(profiledata.userId)"
                      >
                        Solicitar servicio/s ahora
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <section class="overflow-hidden bg-white border-none  rounded mb-12">
                <div class="flex flex-col md:flex-row items-center">
                  <figure class="flex-none mr-0 md:mr-2 overflow-hidden border border-gray-400">
                    <img src="../assets/img/iStock-1186956422.png" class="w-full md:w-auto" />
                  </figure>

                  <header class="flex-2 px-6 py-2 text-center md:text-left">
                    <h2 class="my-0 mb-2 text-xl font-bold">
                      ¿Necesita un trabajo de urgencia o un trabajo especifico?
                    </h2>
                    <p class="mt-0 mb-0 text-black">
                      Describa aquí lo que necesita para que el profesional pueda ponerse en
                      contacto con usted.
                    </p>
                  </header>

                  <div class="w-auto px-2 py-2 text-center md:text-left">
                    <button
                      class="btn btn--primary  text-center px-4 py-2 leading-tight font-semibold"
                      v-if="isAuthenticated"
                      @click="
                        ContactSidebarShow = true;
                        toggleHtmlClass();
                      "
                    >
                      Solicitar presupuesto a medida
                    </button>
                    <button
                      class="btn btn--primary text-center py-2 leading-tight font-semibold"
                      v-else
                      @click="ShowLoginWarning(profiledata.userId)"
                    >
                      Solicitar presupuesto a medida
                    </button>
                  </div>
                </div>
              </section>
            </section>
            <aside class="px-6 md:px-4 mb-4 lg:w-3/12">
              <div class="md:sticky md:top-0"></div>
            </aside>
          </div>
        </div>
      </div>
    </section>
    <aside
      data-pushbar-id="right"
      class="w-full shadow-2xl pushbar from_right md:w-2/5"
      v-bind:class="{ opened: ContactSidebarShow }"
    >
      <header class="px-8 py-4 bg-gray-100">
        <div class="flex items-center justify-between">
          <div>
            <h5>Contactar</h5>
          </div>
          <div>
            <span
              data-pushbar-close
              class="close push_right"
              @click="
                ContactSidebarShow = false;
                toggleHtmlClass();
              "
            ></span>
          </div>
        </div>
      </header>
      <div class="px-8 py-4">
        <form @submit.prevent="validatebudgetRegister" method="post">
          <p>
            Rellena el siguiente formulario y
            <strong class="text-sm text-brand-secondary"
              >"{{ profiledata.firstName }} {{ profiledata.lastName }}"</strong
            >
            se pondrá en contacto contigo en la brevedad posible. Todos los datos que envíes serán
            tratados de forma confidencial
          </p>

          <label>
            <span>¿Cuando quieres el servicio?</span>
            <VueCtkDateTimePicker
              v-model="budget.date"
              color="#3e65ff"
              buttonColor="#3e65ff"
              :only-date="true"
              :no-header="true"
              :minDate="moment().format('YYYY-MM-DD')"
              locale="es"
              format="YYYY-MM-DD"
              formatted="dddd, D MMMM, YYYY"
              :noKeyboard="true"
              id="servicedate"
              class="schduledate-wrapper"
              :noLabel="true"
              :noButtonNow="true"
              v-bind:class="{
                'bg-red-100 border-red-700': submited && !budget.date
              }"
            />
            <span class="error" v-if="submited && !budget.date">Please select date</span>
          </label>

          <p>
            Adjunta algunas imágenes para una mejor descripción
            <strong class="text-sm text-brand-secondary">(puedes subir hasta 3 imágenes)</strong>
          </p>

          <input
            class="block w-full mt-1 mb-2 form-input"
            type="file"
            multiple
            placeholder="Nombre"
            ref="file"
          />
          <span class="error" v-if="submited && this.$refs.file && this.$refs.file.files.length > 3"
            >You can upload Maximum 3 images.</span
          >

          <h6>Describa el servicio que necesita</h6>
          <textarea
            class="w-full h-32 mb-4 border border-gray-400 rounded"
            v-model="budget.description"
            v-bind:class="{
              'bg-red-100 border-red-700': submited && !budget.description
            }"
          ></textarea>
          <label class="flex items-center">
            <span class="error" v-if="submited && !budget.description"
              >Favor ingrese una descripción</span
            >
          </label>
          <label class="flex items-center">
            <input
              type="checkbox"
              class="form-checkbox"
              v-model="acceptPolicy"
              v-bind:class="{
                'bg-red-100 border-red-700': submited && acceptPolicy != true
              }"
            />
            <span class="ml-2 font-normal font-body">
              He leído y acepto la
              <router-link :to="{ name: 'TermsCondition' }" class="underline" target="_blank"
                >política de privacidad y condiciones</router-link
              >
            </span>
          </label>
          <label class="flex items-center">
            <span class="error" v-if="submited && acceptPolicy != true"
              >Debe aceptar las politicas de privacidad y condiciones</span
            >
          </label>
          <button class="w-full btn btn--md btn--secondary" :disabled="processingbudgetRegister">
            Pedir Presupuesto
          </button>
        </form>
      </div>
    </aside>
    <!-- modal start-->
    <div class="update-phone">
      <modal ref="updateUserPhone">
        <template v-slot:header>
          <h1></h1>
        </template>

        <template v-slot:body>
          <div class="flex flex-row justify-center">
            <div class="w-full">
              <p class="text-black mb-8 text-lg">
                Hemos detectado que aún no ha cargado su número de teléfono, para poder darle
                seguimiento y un buen soporte vamos a necesitarlo. Por favor ingrese su número de
                contacto
              </p>
              <form @submit.prevent="updateUserPhone" method="post">
                <div>
                  <label>
                    <span>Celular</span>
                    <VuePhoneNumberInput
                      v-model="telnumber"
                      color="#63b3ed"
                      class="mt-1"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': phoneSubmit && !isPhoneValid
                      }"
                      valid-color="#63b3ed"
                      error-color="#63b3ed"
                      default-country-code="PY"
                      :translations="translations"
                      :no-example="true"
                      :show-code-on-list="true"
                      @update="phoneUpdate"
                    />
                    <span class="error" v-if="phoneSubmit && !isPhoneValid">
                      Por favor ingrese su número (lo vamos a necesitar para poder darte soporte si
                      lo necesitas)
                    </span>
                  </label>
                </div>
                <button
                  class="rounded w-full btn btn--md shadow-md py-3 px-4 btn--primary bg-green-btn"
                  type="submit"
                >
                  <span class="justify-between text-base ">Enviar</span>
                </button>
              </form>
            </div>

            <hr />
          </div>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import encrypt from "@/utils/methods/encrypt";
import numberFormat from "@/utils/filters/numberFormat";
import decrypt from "@/utils/methods/decrypt";
import GetDepartments from "@/utils/methods/GetDepartments";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LCircle, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import "vue2-timepicker/dist/VueTimepicker.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
  data() {
    return {
      servicesubmited: false,
      ContactSidebarShow: false,
      processingGetProfileData: true,
      submited: false,
      processingbudgetRegister: false,
      processingserviceRequest: false,
      professionalId: null,
      profiledata: {},
      servicedata: {
        services: [],
        date: null,
        description: "",
        time: "08:00",
        direction: "",
        professional_id: null,
        user_id: null,
        department: null,
        location_id: null
      },
      budget: {
        date: this.moment().format("YYYY-MM-DD"),
        description: "",
        professional_id: null,
        user_id: null
      },
      BreadcrumbItems: [{ name: "Inicio", link: "/" }],
      acceptPolicy: false,
      budgetId: null,
      SecretKey: process.env.VUE_APP_ENCRYPT_SECRETKEY,
      department: null,
      departments: [],
      departmentLocations: [],
      location: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 10,
      geolocation: {
        latitude: -25.3211306,
        longitude: -57.5928935
      },
      markerLatLng: [-25.3211306, -57.5928935],
      circle: {
        center: latLng(-25.3211306, -57.5928935),
        radius: 4500
      },
      gettingLocation: false,
      selectedService: [],
      services: [],
      searchQuery: "",
      showService: 10,
      photos: [],
      photo: [],
      imageSizeError: "",
      TempData: {
        professionalId: null,
        profession_id: null,
        date: this.moment().format("YYYY-MM-DD"),
        description: "",
        time: "08:00",
        direction: "",
        department: null,
        location: null,
        services: null
      },
      isTimeCorrect: true,
      profession_id: null,
      showTimeDropDown: false,
      hourList: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00"
      ],
      processingUpdateUser: false,
      phoneSubmit: false,
      updateClientData: {
        userId: null,
        phoneNumber: null,
        countryCode: null,
        countryCallingCode: null
      },
      callFunction: null,
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :"
      },
      telnumber: "",
      isPhoneValid: false
    };
  },
  filters: {
    lastthree: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.slice(value.length - 3);
    },
    numberFormat,
    thousandSeparator
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetDepartments();
    this.GetProfileData();
    var self = this;
    setTimeout(function() {
      self.getSavedData();
    }, 1000);
  },
  created() {
    if (this.$route.params.pid) {
      this.professionalId = this.decrypt(this.$route.params.pid);
    }
    if (this.$route.params.proid) {
      this.profession_id = this.$route.params.proid;
    }

    if (this.searchProfession && this.searchLocation.id) {
      this.BreadcrumbItems.push({
        name: this.searchProfession,
        link:
          "/professionals/search?profession=" +
          this.searchProfession +
          "&location=" +
          this.searchLocation.id
      });
      this.BreadcrumbItems.push({
        name: this.searchLocation.name,
        link:
          "/professionals/search?profession=" +
          this.searchProfession +
          "&location=" +
          this.searchLocation.id
      });
    }
  },
  updated() {
    /*  let today_button = document.getElementsByClassName("datepicker-today")[0];
    today_button.closest("button").classList.add("datepicker-today-block");
    let today_button_sidebar = document.getElementsByClassName("datepicker-today")[1];
    today_button_sidebar.closest("button").classList.add("datepicker-today-block"); */
  },
  methods: {
    decrypt,
    GetDepartments,
    encrypt,
    GetDepartmentLocations(isSetDefault) {
      this.$store
        .dispatch("GetDepartmentLocations", {
          departmentId: this.servicedata.department
        })
        .then(response => {
          this.departmentLocations = response.data.locations;
          if (isSetDefault && this.departmentLocations.length > 0) {
            this.servicedata.location_id = this.departmentLocations[0].id;
          }
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    GetLocationDepartment(locationId) {
      this.$store
        .dispatch("GetLocationDepartment", {
          locationId: locationId
        })
        .then(response => {
          this.$store.dispatch("setShowAlert", false);
          this.servicedata.department = response.locations.department_id;
          this.GetDepartmentLocations();
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    toggleHtmlClass() {
      const el = document.documentElement;
      if (this.ContactSidebarShow) {
        el.classList.add("pushbar_locked");
      } else {
        el.classList.remove("pushbar_locked");
      }
    },
    viewFullImage(img) {
      Swal.fire({
        imageUrl: img,
        padding: "0px",
        imageAlt: "Preview Image",
        showConfirmButton: false,
        showCloseButton: true
      });
    },
    GetProfileData() {
      this.processingGetProfileData = true;
      this.$store
        .dispatch("GetProfileData", {
          professionalId: this.professionalId,
          professionId: this.profession_id
        })
        .then(response => {
          this.profiledata = response;
          /* if (this.isAuthenticated && this.user.location_id) {
            this.servicedata.location_id = this.user.location_id;
            this.GetLocationDepartment(this.servicedata.location_id);
          } else {
            this.setDefaultLocationDepartment();
          } */
          this.servicedata.location_id = this.profiledata.Location.id;
          this.GetLocationDepartment(this.servicedata.location_id);
          this.services = this.profiledata.Services;
          this.servicedata.date = this.moment()
            .add(1, "day")
            .format("YYYY-MM-DD");

          if (!this.searchProfession && !this.searchLocation.id) {
            this.BreadcrumbItems.push({
              name: this.profiledata.Profession[0].description,
              link:
                "/professionals/search?profession=" +
                this.profiledata.Profession[0].description +
                "&location=" +
                this.profiledata.Location.id
            });
            this.BreadcrumbItems.push({
              name: this.profiledata.Location.description,
              link:
                "/professionals/search?profession=" +
                this.profiledata.Profession[0].description +
                "&location=" +
                this.profiledata.Location.id
            });
          }
          this.BreadcrumbItems.push({
            name: this.profiledata.firstName + " " + this.profiledata.lastName,
            islast: true
          });
          this.processingGetProfileData = false;
        })
        .catch(() => {
          this.processingGetProfileData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    validatebudgetRegister(e) {
      e.preventDefault();
      this.submited = true;
      this.processingbudgetRegister = true;
      this.budget.professional_id = this.professionalId;
      this.budget.user_id = this.user.id;
      if (this.validatebudgetForm()) {
        if (!this.user.phoneNumber || this.user.phoneNumber == null) {
          this.callFunction = "budget";
          this.$refs.updateUserPhone.openModal();
        } else {
          this.$refs.updateUserPhone.closeModal();
          this.budgetRegister();
        }
      } else {
        this.processingbudgetRegister = false;
      }
    },
    budgetRegister() {
      var images = require.context("../assets/img/", false, /\.gif$/);
      Swal.fire({
        imageUrl: images("./loading.gif"),
        imageAlt: "Loader",
        showConfirmButton: false,
        background: "transparent",
        backdrop: `rgba(0,0,0,0.6)`,
        allowOutsideClick: false
      });
      var formdata = new FormData();
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formdata.append("photos", file);
      }
      formdata.append("date", this.budget.date);
      formdata.append("description", this.budget.description);
      formdata.append("user_id", this.budget.user_id);
      formdata.append("professional_id", this.budget.professional_id);

      this.$store
        .dispatch("budgetRegister", formdata)
        .then(() => {
          Swal.close();
          this.processingbudgetRegister = false;
          this.budget = {
            services: [],
            date: this.moment().format("YYYY-MM-DD"),
            description: "",
            professional_id: null,
            user_id: null
          };

          this.ContactSidebarShow = false;
          this.processingbudgetRegister = false;
          this.toggleHtmlClass();
          let budgetregisterMessage = `Presupuesto registrado correctamente, nos pondremos en contacto contigo pronto!`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", budgetregisterMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          Swal.close();
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          this.ContactSidebarShow = false;
          this.submited = false;
          this.toggleHtmlClass();
          this.processingbudgetRegister = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    validatebudgetForm() {
      return (
        this.budget.date != "" &&
        this.budget.description != "" &&
        this.budget.user_id != "" &&
        this.budget.professional_id != "" &&
        this.$refs.file &&
        this.$refs.file.files.length <= 3 &&
        this.acceptPolicy == true
      );
    },
    addService(e, service) {
      const i = this.servicedata.services.findIndex(_item => _item.id === service.id);

      if (e.target.checked) {
        if (i <= -1) {
          this.servicedata.services.push({
            id: service.id,
            quantity: 1
          });
        }
      } else {
        this.servicedata.services.splice(i, 1);
      }
    },
    validateServiceRegister(e) {
      e.preventDefault();
      this.servicesubmited = true;
      this.processingserviceRequest = true;
      this.servicedata.professional_id = this.professionalId;
      this.servicedata.user_id = this.user.id;

      if (this.validateserviceForm()) {
        if (!this.user.phoneNumber || this.user.phoneNumber == null) {
          this.callFunction = "service";
          this.$refs.updateUserPhone.openModal();
        } else {
          this.$refs.updateUserPhone.closeModal();
          this.serviceRegister();
        }
      } else {
        this.processingbudgetRegister = false;
        setTimeout(function() {
          var d = document.getElementsByClassName("OnErrorScrollHere")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    serviceRegister() {
      var images = require.context("../assets/img/", false, /\.gif$/);
      Swal.fire({
        imageUrl: images("./loading.gif"),
        imageAlt: "Loader",
        showConfirmButton: false,
        background: "transparent",
        backdrop: `rgba(0,0,0,0.6)`,
        allowOutsideClick: false
      });
      var formdata = new FormData();
      for (var i = 0; i < this.$refs.photo.files.length; i++) {
        let file = this.$refs.photo.files[i];
        formdata.append("photos", file);
      }
      formdata.append("date", this.servicedata.date);
      formdata.append("description", this.servicedata.description);
      formdata.append("direction", this.servicedata.direction);
      formdata.append("department", this.servicedata.department);
      formdata.append("time", this.servicedata.time);
      formdata.append("TimeZone", Intl.DateTimeFormat().resolvedOptions().timeZone);

      formdata.append("location_id", this.servicedata.location_id);
      formdata.append("latitude", this.geolocation.latitude);
      formdata.append("longitude", this.geolocation.longitude);
      formdata.append("user_id", this.servicedata.user_id);
      formdata.append("professional_id", this.servicedata.professional_id);
      formdata.append("services", JSON.stringify(this.servicedata.services));
      this.$store
        .dispatch("serviceRegister", formdata)
        .then(() => {
          Swal.close();

          this.processingserviceRequest = false;
          this.servicedata = {
            services: [],
            date: this.moment().format("YYYY-MM-DD"),
            description: "",
            professional_id: null,
            user_id: null
          };
          this.servicesubmited = false;
          Swal.fire({
            title: "Su solicitud de trabajo ha sido creada correctamente",
            icon: "success",
            padding: "1.25em 3em",
            html: `<div class="text-left mb-2">Siguientes pasos:</div>
            <ol class="text-left" style="list-style:auto;">
            <li class="mb-2">El profesional debe aceptar el trabajo, recibirá una confirmación dentro de las 24hs</li>
            <li>Ingresar a mis solicitudes para seguimiento del estado del trabajo, posterior confirmación, pago y calificación.</li>
            </ol> `,
            confirmButtonText: "Continuar"
          }).then(result => {
            if (result.value) {
              this.$router.push({ name: "ServiceRequest" });
            }
          });
        })
        .catch(() => {
          Swal.close();
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          this.processingserviceRequest = false;
          this.servicesubmited = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    serviceRequest(e) {
      e.preventDefault();
      this.servicesubmited = true;
      this.processingserviceRequest = true;
      this.servicedata.professional_id = this.professionalId;
      this.servicedata.user_id = this.user.id;
      if (this.validateserviceForm()) {
        this.$store
          .dispatch("serviceRequest", this.servicedata)
          .then(() => {
            let serviceRequestMessage = `Orden de trabajo creada correctamente. Redireccionando al panel de seguimiento...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", serviceRequestMessage);
            this.$store.dispatch("setShowAlert", true);
            this.processingserviceRequest = false;
            this.servicedata = {
              services: [],
              date: this.moment().format("YYYY-MM-DD"),
              description: "",
              professional_id: null,
              user_id: null
            };
            this.servicesubmited = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function() {
              self.$store.dispatch("setShowAlert", false);
              self.$router.push({ name: "ServiceRequest" });
            }, 2000);
          })
          .catch(() => {
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            this.processingserviceRequest = false;
            this.servicesubmited = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingserviceRequest = false;
      }
    },
    validateserviceForm() {
      return (
        this.servicedata.date != "" &&
        this.servicedata.time != "" &&
        this.isTimeCorrect == true &&
        this.servicedata.description != "" &&
        this.servicedata.department != null &&
        this.servicedata.location_id != null &&
        this.servicedata.direction != "" &&
        this.servicedata.user_id != "" &&
        this.servicedata.professional_id != "" &&
        this.servicedata.services.length > 0 &&
        this.$refs.photo &&
        this.$refs.photo.files.length <= 3
      );
    },
    mapLoad() {
      setTimeout(() => {
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
    },
    getGeoLocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = true;
        this.$refs.marker.mapObject.openPopup();
        return;
      }
      this.gettingLocation = true;
      /* eslint-disable */
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.gettingLocation = false;
          this.markerLatLng = [pos.coords.latitude, pos.coords.longitude];
          this.circle.center = latLng(pos.coords.latitude, pos.coords.longitude);
          this.errorStr = false;
        },
        err => {
          this.gettingLocation = false;
          this.errorStr = true;
          this.$refs.marker.mapObject.openPopup();
        }
      );
      /* eslint-disable */
    },
    locationUpdate(location) {
      this.geolocation = { latitude: location.lat, longitude: location.lng };
      this.circle.center = latLng(location.lat, location.lng);
    },
    searchService() {
      if (this.searchQuery) {
        this.services = this.profiledata.Services.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.description.toLowerCase().includes(v));
        });
      } else {
        this.services = this.profiledata.Services;
      }
    },
    showMoreService() {
      this.showService = this.showService + 10;
    },
    clearSearchService() {
      this.searchQuery = "";
      this.services = this.profiledata.Services;
    },
    viewTipsModal() {
      Swal.fire({
        html:
          '<div class="professional-complete-profile"><h5 class="mb-3 text-lg font-medium text-left"> Algunos consejos;</h5><ol class="advice-list text-black text-left"><li> Estimar para cuando quiere el trabajo, aclarar si es urgente, ejemplo: para plomería en caso de roturas de caño, o cerrajería.</li><li> Especificar el tipo de vivienda, ejemplo: casa, departamento, dúplex, oficina.</li><li> Para instalaciones de aires acondicionado, reparaciones, especifique la ubicación y la facilidad de acceso al mismo. Ejemplo: Si el aire acondicionado se encuentra en un segundo piso, al vacío, de acceso restringido, etc.</li></ol></div>',
        padding: "1em",
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "confirm-button-class"
        }
      });
    },
    viewOtherProfessional() {
      let html = this.$refs.otherProfession.outerHTML;

      Swal.fire({
        html: '<div class="professional-complete-profile">' + html + "</div>",
        padding: "0em",
        confirmButtonText: "Cerrar ventana",
        customClass: {
          confirmButton: "other-profession-confirm-button",
          actions: "action-class"
        }
      });
    },
    photosAdd(e) {
      var files = e.target.files || e.dataTransfer.files;
      Array.prototype.push.apply(this.photos, files);
      if (!this.photos.length) return;
      this.createPhotos(this.photos);
    },
    createPhotos(file) {
      this.photo = [];
      let imageHaveError = [];
      for (var i = 0; i < file.length; i++) {
        if (file[i].size > 1024 * 1024 * 10) {
          imageHaveError.push({ filename: file[i].name, hasError: "size" });
          this.photos.splice(i, 1);
        } else {
          if (
            file[i]["type"] == "image/png" ||
            file[i]["type"] == "image/jpeg" ||
            file[i]["type"] == "application/x-zip-compressed" ||
            file[i]["type"] == "application/pdf"
          ) {
            var reader = new FileReader();
            var vm = this;
            reader.onload = e => {
              vm.photo.push(e.target.result);
            };
            reader.readAsDataURL(file[i]);
          } else {
            imageHaveError.push({ filename: file[i].name, hasError: "type" });
            this.photos.splice(i, 1);
          }
        }
      }
      if (imageHaveError.length > 0) {
        this.imageSizeError = "";
        for (var t = 0; t < imageHaveError.length; t++) {
          if (imageHaveError[t].hasError == "type") {
            this.imageSizeError +=
              imageHaveError[t].filename + " Formato de fotografía no soportado <br>";
          } else {
            this.imageSizeError +=
              imageHaveError[t].filename +
              " supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB <br>";
          }
        }
      } else {
        this.imageSizeError = "";
      }
    },

    removePhotoPreview(key) {
      this.photo.splice(key, 1);
      this.photos.splice(key, 1);

      if (!this.photo.length) {
        this.$refs.photo.value = "";
      }
    },
    ShowLoginWarning(professionalId) {
      this.TempData.professionalId = this.encrypt(professionalId);
      this.TempData.profession_id = this.profession_id;
      this.TempData.date = this.servicedata.date;
      this.TempData.time = this.servicedata.time;
      this.TempData.description = this.servicedata.description;
      this.TempData.direction = this.servicedata.direction;
      this.TempData.department = this.servicedata.department;
      this.TempData.location = this.servicedata.location_id;
      this.TempData.services = JSON.stringify(this.servicedata.services);
      this.$store.dispatch("saveClientTempData", this.TempData);
      let warningMessage =
        "Por favor  <a href='/login'> ingrese</a> al sistema para continuar o <a href='/register-client'>regístrese</a> si todavía no tiene una cuenta creada.";
      this.$store.dispatch("setIsWarningAlert", true);
      this.$store.dispatch("setGlobalMessage", warningMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getSavedData() {
      if (this.ClientTempData && this.ClientTempData != null) {
        this.profession_id = this.ClientTempData.profession_id;
        this.servicedata.date = this.ClientTempData.date;
        this.servicedata.time = this.ClientTempData.time;
        this.servicedata.description = this.ClientTempData.description;
        this.servicedata.direction = this.ClientTempData.direction;
        this.servicedata.department = this.ClientTempData.department;
        this.GetDepartmentLocations();
        this.servicedata.location_id = this.ClientTempData.location;
        this.servicedata.services = JSON.parse(this.ClientTempData.services);
        this.servicedata.services.forEach(e => {
          this.selectedService.push(e.id);
        });
        this.$store.dispatch("removeClientTempData");
      }
    },
    TimeError(eventData) {
      if (eventData && eventData.length > 0) {
        this.isTimeCorrect = false;
      } else {
        this.isTimeCorrect = true;
      }
    },
    setDefaultLocationDepartment() {
      if (this.activeDepartments.length > 0) {
        this.servicedata.department = this.activeDepartments[0].id;
        this.GetDepartmentLocations(true);
      }
    },
    SetTime(time) {
      this.showTimeDropDown = false;
      this.servicedata.time = time;
    },
    clickOutsideTime() {
      this.showTimeDropDown = false;
    },
    updateUserPhone(e) {
      e.preventDefault();
      this.phoneSubmit = true;
      this.processingUpdateUser = true;
      this.updateClientData.userId = this.user.id;
      if (this.isPhoneValid) {
        this.$store
          .dispatch("updateClient", this.updateClientData)
          .then(() => {
            this.phoneSubmit = false;
            this.processingUpdateUser = false;
            this.$refs.updateUserPhone.closeModal();
            if (this.callFunction == "service") {
              this.serviceRegister();
            } else if (this.callFunction == "budget") {
              this.budgetRegister();
            }
          })
          .catch(() => {
            this.processingUpdateUser = false;
            let errorMessage =
              "No se ha podido actualizar su número de contacto, favor intente de nuevo";
            handToast.fire({
              icon: "error",
              title: errorMessage
            });
          });
      } else {
        this.processingRegister = false;
      }
    },
    phoneUpdate(val) {
      this.updateClientData.phoneNumber = val.nationalNumber;
      this.updateClientData.countryCode = val.countryCode;
      this.updateClientData.countryCallingCode = val.countryCallingCode;
      if (val.countryCode == "PY") {
        if (val.nationalNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    }
  },
  computed: {
    ...mapGetters([
      "error",
      "searchProfession",
      "searchLocation",
      "user",
      "isAuthenticated",
      "ClientTempData"
    ]),
    activeDepartments,
    activeDepartmentLocations,
    userage() {
      let today = new Date();
      let birthDate = new Date(this.profiledata.birthDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }
      return age;
    },
    servicewithprice() {
      if (this.profiledata.Services && this.profiledata.Services.length > 0) {
        return this.profiledata.Services.filter(service => {
          return service.price > 0;
        });
      } else {
        return [];
      }
    },
    totalServiceCost() {
      if (this.servicedata.services.length > 0) {
        var sum = 0;
        this.servicedata.services.forEach(e => {
          const i = this.servicewithprice.findIndex(_item => _item.id === e.id);
          if (i > -1) {
            var price = this.servicewithprice[i]["price"];
            sum += e.quantity * price;
          }
        });
        return sum;
      } else {
        return 0;
      }
    },
    totalService() {
      return this.servicedata.services.length;
    },
    referenceCount() {
      let count = 0;
      if (this.profiledata) {
        if (this.profiledata.laboralReferenceName1 || this.profiledata.reference1Description) {
          count++;
        }
        if (this.profiledata.laboralReferenceName2 || this.profiledata.reference2Description) {
          count++;
        }
        return count;
      } else {
        return count;
      }
    }
  },
  directives: {
    "click-outside-time": {
      bind: function(el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },
      unbind: function(el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      }
    }
  },
  components: {
    Header,
    Footer,
    Messages,
    Modal,
    Breadcrumb,
    VueCtkDateTimePicker,
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    LPopup,
    VueTimepicker,
    VuePhoneNumberInput
  }
};
</script>
<style lang="css" scoped>
@import "../assets/css/professionalprofile.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.minimum-height {
  height:200px;
}

.bg-cover{
  background-size: cover!important;
}

.bg-red-btn,
bg-red-btn:hover {
  color: #e30d17 !important;
  background-color: transparent !important;
  border: 1px solid #e30d17 !important;
}
.clr-grey-light {
  color: #999999;
}
</style>
<style lang="css">
.bg-red-100 #servicedate-input {
  background-color: #ffecef;
}
.border-red-700 #servicedate-input {
  border-color: #ca3150 !important;
}
.schduledate-wrapper .week-days {
  color: #ff783e;
}

.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day:hover
  .datepicker-day-effect,
.schduledate-wrapper .custom-button:hover .custom-button-effect {
  background-color: #f0f0f0 !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day:hover
  .datepicker-day-text,
.schduledate-wrapper .custom-button:hover .custom-button-content,
.schduledate-wrapper .custom-button .custom-button-content {
  color: black !important;
}

.schduledate-wrapper .custom-button.is-selected .custom-button-content {
  color: white !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day.selected:hover
  .datepicker-day-effect {
  background-color: #7591ff !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day.selected:hover
  .datepicker-day-text {
  color: #fff !important;
}
.schduledate-wrapper .datepicker-container .datepicker-days .datepicker-day .datepicker-today {
  background-color: transparent;
}
.schduledate-wrapper .time-picker-column-item-effect {
  background-color: #ff783e !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-today-block
  .datepicker-day-text {
  color: #ff783e;
}
.header-picker {
  padding: 10px 0px 10px 10px;
  background-color: #3e65ff;
  border-radius: 4px;
}
.header-picker span {
  color: white;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
  text-transform: capitalize;
}
.header-picker-time {
  flex: 0 0 160px;
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}

.confirm-button-class {
  background: transparent !important;
  color: #d32011 !important;
  border: 1px solid#c0c3ce !important;
  width: 100%;
}
.other-profession-confirm-button {
  background: white !important;
  color: #231f20 !important;
  border: 1px solid#c0c3ce !important;
}
.action-class {
  background: rgb(247, 250, 252);
  margin: 0;
  padding: 15px 0;
}
.modal-otherprofession {
  visibility: hidden;
  width: 0px !important;
  height: 0px;
}
.field-time {
  height: 42px;
  min-height: 42px;
}
.hand-time-picker .drop-down {
  display: block;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
.vue__time-picker-dropdown ul::-webkit-scrollbar,
.vue__time-picker .dropdown ul::-webkit-scrollbar {
  display: none;
}
.vue__time-picker,
.vue__time-picker input.display-time {
  width: 100%;
}
.vue__time-picker input.display-time:focus {
  border-color: #3e65ff;
}
.vue__time-picker .controls .dropdown-btn {
  display: none;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  max-height: 200px;
  overflow-y: auto;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
.autocomplete {
  position: relative;
  display: inline-block;
}
.update-phone .modal__dialog {
  width: 25%;
}

.update-phone .modal__footer {
  display: none;
}
.update-phone .modal__body {
  padding: 30px;
}
@media screen and (max-width: 992px) {
  .update-phone .modal__dialog {
    width: 90%;
  }
}
</style>
