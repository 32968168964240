<template>
  <div class="login-page">
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="flex py-4 md:my-1">
      <div class="container flex flex-col container-xl md:flex-row">
        <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0">
          <form
            class="p-6 bg-white border border-gray-400 rounded "
            @submit.prevent="onSubmit"
            method="post"
          >
            <div class="px-4">
              <h1 class="my-1 text-2xl text-center text-brand-primary">Crear tu cuenta de Hand</h1>
              <p class="text-xl text-black text-center leading-tight mb-6">
                Solo te tomará unos minutos y ya podrás buscar los profesionales que necesitas.
              </p>
            </div>
            <fieldset class="p-4 mb-3 border border-gray-400 rounded">
              <legend class="legend">Si prefieres puedes registrarte y ingresar con;</legend>
              <div class="grid grid-columns-2 grid-gap-4">
                <div>
                  <Facebook ref="fbComponent" />
                </div>
                <div>
                  <Google ref="googleComponent" />
                </div>
              </div>
            </fieldset>
            <fieldset class="p-4 mb-3 border border-gray-400 rounded">
              <legend class="legend">Datos personales</legend>
              <label>
                <span>Nombre</span>
                <input
                  v-model="firstName"
                  v-bind:class="{
                    'bg-red-100 border-red-700 OnErrorScrollHere': submited && !firstName
                  }"
                  class="block w-full mt-1 form-input"
                  type="text"
                  placeholder="Ingresa tu nombre completo aquí"
                />
                <span class="error" v-if="submited && !firstName"
                  >Por favor, introduzca su nombre</span
                >
              </label>
              <label>
                <span>Apellido</span>
                <input
                  v-model="lastName"
                  v-bind:class="{
                    'bg-red-100 border-red-700 OnErrorScrollHere': submited && !lastName
                  }"
                  class="block w-full mt-1 form-input"
                  type="text"
                  placeholder="Ingresa tu apellido aquí"
                />
                <span class="error" v-if="submited && !lastName"
                  >Por favor ingrese su apellido</span
                >
              </label>
              <div class="grid grid-cols-1 xl-grid-cols-2 lg:grid-gap-4">
                <div>
                  <label>
                    <span>Email</span>
                    <input
                      v-model="email"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !validateEmail()
                      }"
                      class="block w-full mt-1 form-input"
                      type="text"
                      placeholder="Ingresa tu dirección de Email"
                    />
                    <span class="error" v-if="submited && !email">Por favor ingrese su email</span>
                    <span class="error" v-if="submited && !validateEmail() && email"
                      >Ingrese un email válido</span
                    >
                  </label>
                </div>
                <div>
                  <label>
                    <span>Celular</span>

                    <VuePhoneNumberInput
                      v-model="telnumber"
                      color="#63b3ed"
                      class="mt-1"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !isPhoneValid
                      }"
                      valid-color="#63b3ed"
                      error-color="#63b3ed"
                      default-country-code="PY"
                      :translations="translations"
                      :no-example="true"
                      :show-code-on-list="true"
                      @update="phoneUpdate"
                    />

                    <span class="error" v-if="submited && !isPhoneValid">
                      Por favor ingrese su número (lo vamos a necesitar para poder darte soporte si
                      lo necesitas)
                    </span>
                  </label>
                </div>
              </div>
              <div class="grid grid-cols-1 xl-grid-cols-2 lg:grid-gap-4">
                <div>
                  <label>
                    <span>Contraseña</span>
                    <div class="pass-group">
                      <input
                        v-model="password"
                        v-bind:class="{
                          'bg-red-100 border-red-700 OnErrorScrollHere':
                            submited && !validatePassword()
                        }"
                        class="block w-full mt-1 form-input"
                        :type="passwordFieldType"
                        placeholder="Ingresa tu contraseña"
                      />
                      <div
                        class="eye-icon hidden  md:block"
                        @mousedown="showPassword('password')"
                        @mouseleave="hidePassword"
                        @mouseup="hidePassword"
                        @touchstart="showPassword('password')"
                        @touchend="hidePassword"
                        @touchcancel="hidePassword"
                      >
                        <img src="../assets/img/icon-ojo.svg" class="mb-2" />
                      </div>
                    </div>
                    <span class="error" v-if="submited && !password"
                      >Por favor ingrese una contraseña</span
                    >
                    <span class="error" v-if="submited && !validatePassword()">
                      La contraseña debe tener mínimamente 8 caracteres.
                    </span>
                  </label>
                </div>
                <div>
                  <label>
                    <span>Vuelve a ingresar tu contraseña</span>
                    <div class="pass-group">
                      <input
                        v-model="password2"
                        v-bind:class="{
                          'bg-red-100 border-red-700 OnErrorScrollHere':
                            submited && password != password2
                        }"
                        class="block w-full mt-1 form-input"
                        :type="passwordConfirmFieldType"
                        placeholder="Por favor repite tu contraseña"
                      />
                      <div
                        class="eye-icon hidden  md:block"
                        @mousedown="showPassword('passwordConfirm')"
                        @mouseleave="hidePassword"
                        @mouseup="hidePassword"
                        @touchstart="showPassword('passwordConfirm')"
                        @touchend="hidePassword"
                        @touchcancel="hidePassword"
                      >
                        <img src="../assets/img/icon-ojo.svg" class="mb-2" />
                      </div>
                    </div>
                    <span class="error" v-if="submited && password != password2"
                      >Las contraseñas no coinciden</span
                    >
                  </label>
                  <label class="flex items-center md:hidden block">
                    <input
                      type="checkbox"
                      class="form-checkbox"
                      v-model="isShowPassword"
                      @change="togglePassword"
                    />
                    <span class="ml-2 font-normal font-body">Mostrar Contraseña</span>
                  </label>
                </div>
              </div>
              <div class="grid grid-cols-1 xl-grid-cols-2 lg:grid-gap-4">
                <div>
                  <label>
                    <span>Departamento</span>
                    <select
                      class="block w-full mt-1 rounded form-select"
                      v-model="department"
                      @change="GetDepartmentLocations()"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !department
                      }"
                    >
                      <option disabled v-if="activeDepartments.length <= 0" :value="null"
                        >Departamento no encontrado</option
                      >
                      <option disabled v-else :value="null">Seleccionar Departmento</option>
                      <option
                        v-for="department in activeDepartments"
                        :key="department.id"
                        :value="department.id"
                        >{{ department.description }}</option
                      >
                    </select>
                    <span class="error" v-if="submited && !department"
                      >Por favor Ingresa tu Departamento</span
                    >
                  </label>
                </div>
                <div>
                  <label>
                    <span>Ciudad</span>
                    <select
                      class="block w-full mt-1 rounded form-select"
                      v-model="location"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': submited && !location
                      }"
                    >
                      <option disabled v-if="activeDepartmentLocations.length <= 0" :value="null"
                        >Ciudad no encontrada</option
                      >
                      <option disabled v-else :value="null">Seleccione Ciudad</option>
                      <option
                        v-for="departmentLocation in activeDepartmentLocations"
                        :key="departmentLocation.id"
                        :value="departmentLocation.id"
                        >{{ departmentLocation.description }}</option
                      >
                    </select>
                    <span class="error" v-if="submited && !location"
                      >Por favor Ingresa tu Ciudad</span
                    >
                  </label>
                </div>
              </div>
            </fieldset>
            <label class="flex items-center">
              <input
                type="checkbox"
                class="form-checkbox"
                v-model="acceptPolicy"
                value="true"
                v-bind:class="{
                  'bg-red-100 border-red-700 OnErrorScrollHere': submited && acceptPolicy != true
                }"
              />
              <span class="ml-2 font-normal font-body"
                >He leído y acepto la
                <router-link :to="{ name: 'TermsCondition' }" class="underline" target="_blank"
                  >política de privacidad y condiciones</router-link
                ></span
              >
            </label>
            <label class="flex items-center">
              <span class="error" v-if="submited && acceptPolicy != true"
                >Debe aceptar las politicas de privacidad y condiciones para registrarse</span
              >
            </label>

            <div class=" px-0 lg:px-20 mx-auto text-center">
              <button
                class="w-full btn btn--md py-4  btn--secondary rounded-full mb-4"
                type="submit"
                :disabled="processingRegister"
              >
                Registrame ahora
              </button>
              <p>
                <a
                  href="https://wa.me/+595986130808"
                  target="_blank"
                  class="btn w-full block md:inline-flex py-4 btn--contact rounded-lg md:rounded-full text-xl hover:bg-white"
                  ><img src="../assets/img/logo-whatsapp.svg" class=" w-8 mx-auto md:hidden "/>
                  <span> ¿Necesitas ayuda? <br class="block sm:hidden " />0986-130-808 </span>
                  <img src="../assets/img/logo-whatsapp.svg" class=" w-8 ml-4 hidden md:block "
                /></a>
              </p>
            </div>
          </form>
        </div>
        <div class="w-full md:w-1/2 md:pl-2 ">
          <div
            class="p-5 bg-white border border-gray-400 rounded md:py-6 md:px-12 lg:px-32 text-center"
          >
            <!-- carousel start-->
            <div class="carousel relative pb-24">
              <div class="carousel-inner overflow-hidden w-full">
                <!--Slide 1-->
                <input
                  v-model="handCarousel"
                  value="1"
                  class="carousel-open"
                  type="radio"
                  id="carousel-1"
                  name="carousel"
                  aria-hidden="true"
                  hidden=""
                  checked="checked"
                />
                <div class="carousel-item absolute opacity-0">
                  <div class="block  w-full text-white  text-center">
                    <h3 class="mb-4 md:mb-8  text-4xl">
                      Seguridad <br />
                      y confianza
                    </h3>
                    <img
                      src="../assets/img/mask-group-1.png"
                      class="inline-block mb-4 md:mb-8"
                      alt="Mask-Group"
                    />
                    <p class="text-black text-left my-6 text-xl">
                      Todos nuestros profesionales son pre-aprobados por nuestro equipo antes de ser
                      publicados. Validamos la información y hacemos una verificación de
                      antecedentes y referencias para tu mayor tranquilidad.
                    </p>
                  </div>
                </div>
                <!--Slide 2-->
                <input
                  v-model="handCarousel"
                  value="2"
                  class="carousel-open"
                  type="radio"
                  id="carousel-2"
                  name="carousel"
                  aria-hidden="true"
                  hidden=""
                />
                <div class="carousel-item absolute opacity-0">
                  <div class="block  w-full text-white  text-center">
                    <h3 class="mb-4 md:mb-8  text-4xl">
                      Rápido <br />
                      y comodo
                    </h3>
                    <img
                      src="../assets/img/mask-group-2.png"
                      class="inline-block mb-4 md:mb-8"
                      alt="Mask-Group"
                    />
                    <p class="text-black text-left my-4 text-xl">
                      En vez de perder tiempo pidiendo contactos, buscando en redes, llamando a
                      personas que no conoces, podes ingresar a Hand a ver el perfil del profesional
                      con sus calificaciones, contratar servicios, pedir presupuestos y contratarlos
                      desde cualquier dispositivo.
                    </p>
                  </div>
                </div>
                <!--Slide 3-->
                <input
                  v-model="handCarousel"
                  value="3"
                  class="carousel-open"
                  type="radio"
                  id="carousel-3"
                  name="carousel"
                  aria-hidden="true"
                  hidden=""
                />
                <div class="carousel-item absolute opacity-0">
                  <div class="block  w-full text-white  text-center">
                    <h3 class="mb-4 md:mb-8 text-4xl">
                      Transparente <br />
                      y más eficiente
                    </h3>
                    <img
                      src="../assets/img/mask-group-3.png"
                      class="inline-block mb-4 md:mb-8"
                      alt="Mask-Group"
                    />
                    <p class="text-black text-left my-6 text-xl">
                      Tenes el respaldo de toda la negociación dentro de la plataforma por medio de
                      un chat interno con el profesional para hacer seguimiento de tus pedidos con
                      la ayuda de HAND.
                    </p>
                  </div>
                </div>
                <!-- Add additional indicators for each slide-->
                <ol class="carousel-indicators">
                  <li class="inline-block mr-3">
                    <label for="carousel-1" class="carousel-bullet cursor-pointer block"></label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="carousel-2" class="carousel-bullet cursor-pointer block "></label>
                  </li>
                  <li class="inline-block mr-3">
                    <label for="carousel-3" class="carousel-bullet cursor-pointer block "></label>
                  </li>
                </ol>
              </div>
            </div>
            <!-- carousel end-->
            <img src="../assets/img/logo-hand.svg" class="inline-block my-8 md:my-16" alt="logo" />
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import validateEmail from "@/utils/methods/validateEmail";
import validatePassword from "@/utils/methods/validatePassword";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
import GetDepartments from "@/utils/methods/GetDepartments";
import GetDepartmentLocations from "@/utils/methods/GetDepartmentLocations";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      colorMessage: "#66A7E5",
      submited: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      countryCode: null,
      countryCallingCode: null,
      rememberMe: false,
      acceptPolicy: false,
      registerError: false,
      password: "",
      password2: "",
      processingRegister: false,
      handCarousel: "1",
      interval: null,
      department: null,
      departments: [],
      departmentLocations: [],
      location: null,
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :"
      },
      telnumber: "",
      isPhoneValid: false,
      passwordFieldType: "password",
      passwordConfirmFieldType: "password",
      isShowPassword: false
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.interval = setInterval(() => this.autoCarousel(), 10000);
    this.GetDepartments();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    GetDepartments,
    GetDepartmentLocations,
    validateEmail,
    validatePassword,
    autoCarousel() {
      if (this.handCarousel == 3) {
        this.handCarousel = 1;
      } else {
        this.handCarousel++;
      }
    },
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingRegister = true;
      if (this.validateForm()) {
        this.$store.dispatch("hideMessageLink");
        this.$store
          .dispatch("createClient", {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            password2: this.password2,
            phoneNumber: this.phoneNumber,
            countryCode: this.countryCode,
            countryCallingCode: this.countryCallingCode,
            location_id: this.location,
            userType: "client"
          })
          .then(() => {
            this.submited = false;
            this.processingRegister = false;
            Swal.fire({
              title: "",
              icon: "success",
              padding: "1.25em 3em",
              html: `Gracias por registrarte en Hand!.
Te enviamos un correo para validar tu usuario (esto es solo por única vez). Favor, verificá
tu buzón de entrada en caso de no encontrarlo podes ver en spam o correos no deseados`,
              confirmButtonText: "Continuar",
              allowOutsideClick: false
            }).then(result => {
              if (result.value) {
                this.$router.push({ name: "Login" });
              }
            });
          })
          .catch(() => {
            this.processingRegister = false;
            let errorMessage = this.error.response.data.msg[0];
            if (this.error.response.data.provider) {
              if (this.error.response.data.provider == "mail") {
                this.$store.dispatch("setIsShowMailLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado manualmente, por favor inicie sesión ";
              } else if (this.error.response.data.provider == "facebook") {
                this.$store.dispatch("setIsShowFacebookLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado por facebook, por favor inicie sesión ";
              } else if (this.error.response.data.provider == "google") {
                this.$store.dispatch("setIsShowGoogleLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado por google, por favor inicie sesión ";
              }
            }
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingRegister = false;
        setTimeout(function() {
          var d = document.getElementsByClassName("OnErrorScrollHere")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    validateForm() {
      return (
        this.firstName != "" &&
        this.lastName != "" &&
        this.email != "" &&
        this.validateEmail() == true &&
        this.isPhoneValid == true &&
        this.password != "" &&
        this.validatePassword() == true &&
        this.password == this.password2 &&
        this.department != null &&
        this.location != null &&
        this.acceptPolicy == true
      );
    },
    phoneUpdate(val) {
      this.phoneNumber = val.phoneNumber;
      this.countryCode = val.countryCode;
      this.countryCallingCode = val.countryCode;
      if (val.countryCode == "PY") {
        if (val.phoneNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    },
    showPassword(field) {
      if (field == "password") {
        this.passwordFieldType = "text";
      } else if (field == "passwordConfirm") {
        this.passwordConfirmFieldType = "text";
      }
    },
    hidePassword() {
      this.passwordFieldType = "password";
      this.passwordConfirmFieldType = "password";
    },
    togglePassword() {
      if (this.isShowPassword) {
        this.passwordFieldType = "text";
        this.passwordConfirmFieldType = "text";
      } else {
        this.passwordFieldType = "password";
        this.passwordConfirmFieldType = "password";
      }
    }
  },
  computed: {
    ...mapGetters(["error"]),
    activeDepartments,
    activeDepartmentLocations
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    VuePhoneNumberInput,
    Facebook,
    Google
  }
};
</script>
<style scoped>
@import "../assets/css/ingresar-registro.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>
