<template>
  <div id="login_section">
    <Header />
    <Breadcrumb />
    <Messages v-on:focusEmail="focusEmail" />
    <section class="flex py-4 md:my-1">
      <div class="container container-xl flex flex-col md:flex-row md:items-center" id="loginForm">
        <div class="w-full md:w-1/2">
          <form
            @submit.prevent="onSubmit"
            method="post"
            class="p-5 md:p-10 rounded border border-gray-400 bg-white"
          >
            <h3 class="mb-8">Iniciar Sesión</h3>
            <label>
              <span>Email:</span>
              <input
                class="form-input mt-1 block w-full"
                v-bind:class="{ 'bg-red-100 border-red-700': submited && !validateEmail() }"
                type="text"
                ref="loginEmail"
                placeholder="Ingresa un correo"
                v-model="email"
              />
              <span class="error" v-if="submited && !email"
                >Por favor ingrese su correo electrónico</span
              >
              <span class="error" v-if="submited && !validateEmail() && email"
                >Ingrese un email válido</span
              >
            </label>

            <label>
              <span>Ingresa tu contraseña:</span>
              <div class="pass-group">
                <input
                  class="form-input mt-1 block w-full"
                  v-bind:class="{ 'bg-red-100 border-red-700': submited && !password }"
                  :type="passwordFieldType"
                  v-model="password"
                  placeholder="Mínimo 8 caracteres"
                />
                <div
                  class="eye-icon hidden md:block"
                  @mousedown="showPassword"
                  @mouseleave="hidePassword"
                  @mouseup="hidePassword"
                  @touchstart="showPassword"
                  @touchend="hidePassword"
                  @touchcancel="hidePassword"
                >
                  <img src="../assets/img/icon-ojo.svg" class="mb-2" />
                </div>
              </div>

              <span class="error" v-if="submited && !password"
                >Por favor ingrese su contraseña</span
              >
            </label>
            <label class="flex items-center md:hidden block">
              <input
                type="checkbox"
                class="form-checkbox"
                v-model="isShowPassword"
                @change="togglePassword"
              />
              <span class="ml-2 font-normal font-body">Mostrar Contraseña</span>
            </label>

            <div class="recaptcha" v-if="loginattempt >= 2">
              <vue-recaptcha
                :sitekey="sitekey"
                :loadRecaptchaScript="true"
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
              ></vue-recaptcha>
              <span class="error" v-if="submited && !isverify"
                >Por favor, verifique el captcha</span
              >
            </div>
            <button
              type="submit"
              class="btn btn--md btn--secondary w-full"
              :disabled="processingLogin"
            >
              Iniciar Sesión
            </button>

            <p>
              <router-link to="/forgot" class="block text-center underline p-5"
                >He olvidado mi contraseña
              </router-link>
            </p>

            <div class="grid grid-columns-2 grid-gap-4">
              <div>
                <Facebook ref="fbComponent" />
              </div>
              <div>
                <Google ref="googleComponent" />
              </div>
            </div>
          </form>
        </div>

        <div class="w-full md:w-1/2">
          <section class="mb-5 text-center md:p-20 md:mb-0">
            <svg class="text-6xl icon">
              <use
                href="../assets/img/icons.svg#icon-id"
                xlink:href="../assets/img/icons.svg#icon-id"
              />
            </svg>
            <h4>¿Aún no estas registrado?</h4>
            <p>
              ¡Es muy fácil! Crea una cuenta y empieza a disfrutar de las ventajas que te ofrecemos
            </p>

            <router-link to="/register-client" class="btn btn--primary"
              >Crear una cuenta</router-link
            >
          </section>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import VueRecaptcha from "vue-recaptcha";
import { mapGetters } from "vuex";
import validateEmail from "@/utils/methods/validateEmail";
import scrollToSection from "@/utils/methods/scrollToSection";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
export default {
  data() {
    return {
      colorMessage: "#66A7E5",
      submited: false,
      email: "",
      password: "",
      processingLogin: false,
      loginerrormessage: "",
      isShowCaptcha: false,
      isverify: false,
      recaptchaToken: "",
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      redirectpath: "",
      passwordFieldType: "password",
      isShowPassword: false,
    };
  },
  created() {
    if (this.$route.query.redirect) {
      this.redirectpath = this.$route.query.redirect;
    }
  },
  mounted() {
    // this.$store.dispatch("setShowAlert", false);
    // this.scrollToSection("login_section");
  },
  methods: {
    validateEmail,
    scrollToSection,
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingLogin = true;

      if (this.validateForm()) {
        let email = this.email.toLowerCase();
        this.$store.dispatch("hideMessageLink");
        this.$store
          .dispatch("fetchLogin", {
            email: email,
            password: this.password,
            recaptchaToken: this.recaptchaToken,
          })
          .then(() => {
            this.processingLogin = true;
            let loginMessage = `Datos correctos! redireccionando...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", loginMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function () {
              self.$store.dispatch("setShowAlert", false);

              let isRedirectProfileId = null;
              let isProfessionId = null;
              if (self.ClientTempData && self.ClientTempData != null) {
                isRedirectProfileId = self.ClientTempData.professionalId
                  ? self.ClientTempData.professionalId
                  : null;
                isProfessionId = self.ClientTempData.profession_id
                  ? self.ClientTempData.profession_id
                  : null;
              }
              if (self.redirectpath) {
                self.$router.push(self.redirectpath);
              } else if (isRedirectProfileId != null && isProfessionId != null) {
                self.$router.push({
                  name: "ProfessionalsProfile",
                  params: { proid: isProfessionId, pid: isRedirectProfileId },
                });
              } else {
                self.$router.push({ name: "Dashboard" });
              }
            }, 2000);
          })
          .catch(() => {
            this.processingLogin = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingLogin = false;
      }
    },
    validateForm() {
      return (
        this.email != "" &&
        this.validateEmail() == true &&
        this.password != "" &&
        (this.loginattempt < 2 || this.isverify)
      );
    },
    onVerify: function (recaptchaToken) {
      this.isverify = true;
      this.recaptchaToken = recaptchaToken;
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
    },
    focusEmail: function () {
      this.scrollToSection("loginForm");
      this.$refs.loginEmail.focus();
    },
    showPassword() {
      this.isShowPassword = true;
      this.togglePassword();
    },
    hidePassword() {
      this.isShowPassword = false;
      this.togglePassword();
    },
    togglePassword() {
      if (this.isShowPassword) {
        this.passwordFieldType = "text";
      } else {
        this.passwordFieldType = "password";
      }
    },
  },
  computed: {
    ...mapGetters(["error", "loginattempt", "ClientTempData"]),
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    VueRecaptcha,
    Facebook,
    Google,
  },
};
</script>
<style scoped>
@import "../assets/css/ingresar-registro.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.recaptcha {
  margin: 10px 0;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
</style>
