<template>
  <div class="w-full">
    <section class="flex bg-white">
      <div class="container flex flex-row container-xl p-20 mt-[100px] mb-[150px] justify-center">
        <div class="bg-container pt-[60px] px-[60px] w-full lg:w-1/2">
          <div class="w-full md:pr-2 mb-4 md:mb-0">
            <fieldset>
              <h4 class="text-center">Gracias por registrarse en Hand!</h4>
              <p class="mb-6 mt-8 text-center px-4">Su solicitud ha sido enviada exitosamente. Se realizará una revisión de la misma, 
              esté atento al whatsapp de su número registrado, ya que dentro de las 72hs le llegará la aprobación y los detalles para completar el proceso.</p>
            </fieldset>

            <div class="px-0 lg:px-20 mx-auto text-center mt-12">
              <div class="w-1/2 mb-6 mx-auto">
                <router-link :to="{ name: 'Home' }" class="btn-home">
                  <rounded-button ghost>
                    Volver al inicio
                  </rounded-button>
                </router-link>
              </div>
              
              <div>
                <a
                    href="https://wa.me/+595986130808"
                    target="_blank"
                    class="inline-flex text-black"
                    >
                      <span> ¿Necesitas ayuda? </span>
                </a>
                <a
                    href="https://wa.me/+595986130808"
                    target="_blank"
                    class="inline-block text-black relative whatsapp-contact-icon"
                    >
                      <img src="@/assets/img/logo-whatsapp.svg" class="w-4 ml-4 hidden md:block"/>
                </a>
                <a
                    href="https://wa.me/+595986130808"
                    target="_blank"
                    class="inline-flex text-black ml-2 underline"
                    >
                      <span> 0986 130 808 </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { RoundedButton } from "@/components/v2/buttons"
import { mapGetters } from "vuex";
export default {
  mounted() {
    if (this.RegisterProfessionalCurrentStep && this.RegisterProfessionalCurrentStep == 4) {
      this.$store.dispatch("setShowAlert", false);
      this.$store.dispatch("setShowHeaderBackButton", true);
      this.$store.dispatch("setShowHeaderCancelButton", true);
    } else {
      if (this.RegisterProfessionalCurrentStep && this.RegisterProfessionalCurrentStep == 3) {
        this.$router.push({ name: 'RegisterProfessionalStep3' })
      } else {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch("setShowHeaderBackButton", false);
    this.$store.dispatch("setShowHeaderCancelButton", false);

    this.$store.dispatch("removeRegisterProfessionalTempData");
    this.$store.dispatch("removeRegisterProfessionalCurrentStep");
  },
  computed: {
    ...mapGetters(["RegisterProfessionalCurrentStep"]),
  },
  components: {
    RoundedButton
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
.whatsapp-contact-icon {
  top: 2px;
}
.btn-home {
  text-decoration: none;
}
.bg-container {
  background-image: url('~@/assets/img/circles-2.svg'), url('~@/assets/img/circles-1.svg');
  background-repeat: no-repeat;
  background-position: right top, left 80%;
  background-clip: padding-box;
}
</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>
