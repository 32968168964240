import Toastify from "toastify-js";
export default (data)=>{
  Toastify({
    text: "",
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style:{
      background:"#1D4CFF"
    },
    ...data
  }).showToast();
}
