<template>
  <modal :background="'red-100'">
    <div class="w-full flex flex-col items-center justify-center">
      <x-circle-icon
        :width="70"
        :height="70"
        :color="'red-700'"
      />
      <slot name="title">
        <h4>Error</h4>
      </slot>
      <slot></slot>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/v2/Modal.vue";
import {XCircleIcon} from "@/components/icons";
export default {
  components:{
    Modal,
    XCircleIcon
  }
}
</script>
