export default function() {
  this.$store
    .dispatch("GetMessagesCount", this.messageData)
    .then(response => {
      let oldmessagesCount = this.messagesCount;
      this.messagesCount = response.messages;
      if (this.messagesCount != oldmessagesCount) {
        this.getMessageData(this.messageData.id, this.messageData.type);
      }
    })
    .catch(() => {
      let errorMessage = this.error.response.data.msg;
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", errorMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
