<template>
  <div class="lg:w-1/2">
    <Show @show-service-requests="$emit('show-service-requests')" :client="client" />
  </div>
</template>
<script>
import Show from "./Show.vue";
import Edit from "./Edit.vue";
export default {
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      //editMode: false,
    };
  },
  watch: {
    /*
    client() {
      this.editMode = false;
    },

     */
  },
  components: {
    Show,
    Edit,
  },
};
</script>
<style scoped></style>
