<template>
  <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="w-full h-full flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="w-full h-full relative transform overflow-hidden rounded-lg bg-gray-600 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        >
          <div
            class="w-full h-full flex flex-col justify-between bg-gray-600 px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
          >
            <div class="w-full flex flex-col">
              <div class="w-full flex justify-end mb-4">
                <div @click="$emit('close-modal')">
                  <TimesIcon class="cursor-pointer" :color="'#fff'" />
                </div>
              </div>
              <div class="w-full flex">
                <div class="w-2/3 h-16 flex items-center bg-white">
                  <div class="flex-1 h-full flex items-center">
                    <MagnifyingGlassIcon class="mx-3" :color="'#000'" />
                    <input
                      type="text"
                      class="w-full h-full text-gray-800 placeholder-gray-800 border-none px-4 focus:outline-none"
                      v-model="query"
                    />
                  </div>
                  <select
                    class="w-1/3 h-full text-gray-800 placeholder-gray-800 border-none px-4 focus:outline-none"
                    v-model="filterBy"
                  >
                    <option disabled>Filtrar por</option>
                    <option value="names_lastnames">Nombre y Apellido</option>
                    <option value="profession">Profesión</option>
                    <option value="service">Servicio</option>
                  </select>
                </div>
                <button
                  @click="searchProfessionals"
                  class="w-1/3 justify-center h-16 font-semibold btn btn--secondary md:w-1/3 mb-4 md:mb-0 md:h-auto md:rounded-l-none"
                >
                  Buscar Profesionales
                </button>
              </div>
            </div>
            <div class="w-full flex-1 flex flex-col my-5">
              <template v-for="professional in professionals">
                <Professional
                  :onClick="onSelectProfessional"
                  :professional="professional"
                  :key="professional.id"
                  :disabled_message="disabled_message"
                />
              </template>
            </div>
            <template v-if="paginate && totalPages != 1">
              <div class="w-full bg-white">
                <Paginator
                  :currentPage="currentPage"
                  :totalPages="totalPages"
                  :onClickPage="searchProfessionals"
                  :showPrevBtn="showPrevBtn"
                  :showNextBtn="showNextBtn"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TimesIcon, MagnifyingGlassIcon } from "@/components/icons";
import Professional from "./Professional.vue";
import Paginator from "@/components/Paginator.vue";
export default {
  props: {
    listProfessionalsService: {
      type: Function,
      default: () => {},
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    disabled_message: {
      type: String,
      default: "",
    },
    onSelectProfessional: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      query: "",
      filterBy: "names_lastnames",
      professionals: [],
      currentPage: 1,
      totalPages: 0,
      showPrevBtn: false,
      showNextBtn: false,
    };
  },
  mounted() {
    this.searchProfessionals({ page: 1 });
  },
  methods: {
    searchProfessionals({ page = 1 }) {
      this.listProfessionalsService({ page, query: this.query, filterBy: this.filterBy }).then(
        ({ data }) => {
          if (this.paginate) {
            this.professionals = data.data;
            this.currentPage = data.meta.page.current_page;
            this.totalPages = data.meta.page.last_page;
            this.showPrevBtn = data.meta.page.hasPreviousPage;
            this.showNextBtn = data.meta.page.hasNextPage;
          } else {
            this.professionals = data;
          }
        },
      );
    },
  },
  components: {
    Paginator,
    TimesIcon,
    Professional,
    MagnifyingGlassIcon,
  },
};
</script>
