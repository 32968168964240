export default function(e, serviceid, professionid) {
  if (serviceid && professionid) {
    if (e.target.checked) {
      this.selectedServices.push({ service_id: serviceid, profession_id: professionid });
    } else {
      for (var i = 0; i < this.selectedServices.length; i++) {
        if (
          this.selectedServices[i].service_id == serviceid &&
          this.selectedServices[i].profession_id == professionid
        ) {
          this.selectedServices.splice(i, 1);
        }
      }
    }
  }
}
