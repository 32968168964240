<template>
    <div class="w-full flex justify-start mt-3">
      <button @click="generateExport()" class="btn btn--primary">Exportar</button>
    </div>
</template>
<script>
    import { Export } from "@/services";

    export default {
        name: "Export",
        props: {
            type: String
        },
        methods: {
            generateExport() {
                Export.generateExport(this.type).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const a = document.createElement("a");
                    a.href = url;

                    let fileName = '';

                    switch (this.type) {
                        case 'service_request':
                            fileName = 'servicios_solicitados';
                            break;
                        case 'client':
                            fileName = 'clientes';
                            break;
                        case 'professional':
                            fileName = 'profesionales';
                            break;
                    }

                    const filename = `${fileName}.xlsx`;

                    a.setAttribute('download', filename);
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            }
        }
    };
</script>