<template>
  <svg :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8146 2.17972C12.1212 1.48599 11.2974 0.936264 10.3907 0.562182C9.48404 0.1881 8.5123 -0.00294857 7.53146 3.43993e-05C3.41517 3.43993e-05 0.0642856 3.33418 0.0626115 7.43305C0.0607905 8.73822 0.40483 10.0206 1.05971 11.1496L0 15L3.95926 13.9664C5.0552 14.5601 6.28202 14.8709 7.52845 14.8704H7.53146C11.6474 14.8704 14.998 11.5359 15 7.43741C15.0025 6.45992 14.8105 5.49169 14.4354 4.58906C14.0602 3.68644 13.5092 2.86744 12.8146 2.17972ZM7.53146 13.6159H7.52878C6.41764 13.6162 5.3267 13.3188 4.36941 12.7547L4.14274 12.6208L1.7933 13.2342L2.42042 10.9544L2.27276 10.72C1.65147 9.73634 1.32229 8.59648 1.32355 7.43305C1.32355 4.02692 4.10959 1.25561 7.53381 1.25561C9.17643 1.25268 10.753 1.90233 11.9166 3.06167C13.0803 4.22102 13.7358 5.79511 13.739 7.43774C13.7377 10.8442 10.953 13.6159 7.53146 13.6159ZM10.9363 8.98896C10.7498 8.89588 9.83135 8.44656 9.66126 8.38461C9.49117 8.32267 9.36561 8.29153 9.24139 8.47769C9.11717 8.66385 8.75925 9.08037 8.65043 9.20593C8.54162 9.33149 8.4328 9.34521 8.2463 9.25213C8.05981 9.15905 7.45814 8.96318 6.7453 8.33037C6.1905 7.83785 5.81617 7.22982 5.70736 7.04399C5.59854 6.85817 5.69564 6.75738 5.78905 6.66497C5.87309 6.5816 5.97555 6.44801 6.06896 6.33953C6.16238 6.23105 6.19352 6.15337 6.25546 6.02948C6.3174 5.9056 6.2866 5.79712 6.24006 5.70437C6.19352 5.61163 5.82019 4.69723 5.66483 4.32525C5.51316 3.96297 5.35948 4.01219 5.24497 4.0065C5.13615 4.00114 5.01059 3.9998 4.88705 3.9998C4.7926 4.00226 4.69967 4.02416 4.61406 4.06414C4.52846 4.10412 4.45201 4.16132 4.3895 4.23217C4.21841 4.41833 3.73627 4.86833 3.73627 5.78172C3.73627 6.69511 4.40591 7.57903 4.49832 7.70292C4.59073 7.8268 5.81416 9.7028 7.68615 10.5074C8.03376 10.6562 8.38893 10.7868 8.75021 10.8984C9.1972 11.0397 9.604 11.02 9.92543 10.9721C10.284 10.9189 11.0303 10.5228 11.1854 10.0889C11.3404 9.65492 11.3407 9.28327 11.2942 9.20593C11.2476 9.12858 11.1231 9.08171 10.9363 8.98896Z" :fill="color"/>
  </svg>
</template>
<script>
export default{
  props:{
    width:{
      type:Number,
      default:15
    },
    height:{
      type:Number,
      default: 15
    },
    color:{
      type:String,
      default:'#3C3E47'
    }
  }
}
</script>
