<template>
  <div
    @click="() => onClick({ item: data })"
    class="w-full flex items-center my-5 item-container cursor-pointer"
  >
    <ProfilePicture class="mr-3 w-10" :url="picture_url" />
    <span class="item-label">{{ firstName }} {{ lastName }}</span>
  </div>
</template>
<script>
import { ProfilePicture } from '@/components';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    picture_url: {
      type: String,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    ProfilePicture,
  },
};
</script>
<style scoped>
.item-container {
  padding: 15px;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}

.item-container:hover {
  background-color: rgb(55, 91, 229);
  color: white;
}
</style>
