<template>
  <div class="w-full max-w-full overflow-x-auto flex">
    <div
      v-if="showPrevBtn"
      @click="() => onClickPage({ page: currentPage - 1 })"
      class="btn-control"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>
    </div>
    <template v-if="totalPages>1">
      <template v-for="page in totalPages">
        <div
          :key="page"
          @click="() => onClickPage({ page })"
          :class="`btn-page ${currentPage === page ? 'active' : ''}`"
        >
          {{ page }}
        </div>
      </template>
    </template>
    <div
      v-if="showNextBtn"
      @click="() => onClickPage({ page: currentPage + 1 })"
      class="btn-control"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    onClickPage: {
      type: Function,
      default: () => {},
    },
    showPrevBtn: {
      type: Boolean,
      default: false,
    },
    showNextBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: black;
}
.btn-control,
.btn-page {
  padding: 10px;
  cursor: pointer;
}

.btn-page.active {
  font-weight: bold;
  color: rgba(255, 120, 62);
}
</style>
