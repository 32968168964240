export default function() {
  this.$store
    .dispatch("GetDepartments")
    .then(response => {
      this.departments = response.locations;
    })
    .catch(() => {
      let errorMessage = this.error.response.data.msg;
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", errorMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
