export default function() {
  
  return this.departmentLocations.filter(departmentLocation => {
    if((this.$route.name=='RegisterProfessionalStep2' ||  this.$route.name=='RegisterClientStep2')) {
      return departmentLocation.active && departmentLocation.description!='Asunción y alrededores' && departmentLocation.description!='Todos';
    }
    else{
    return departmentLocation.active;
    }
  });
}
