import Vue from "vue";
import Vuex from "vuex";
import globalModule from "@/store/modules/global";
import client from "@/store/modules/client";
import completeProfessionalProfile from "@/store/modules/completeProfessionalProfile";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    globalModule,
    client,
    completeProfessionalProfile
  }
});

export default store;
