<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" fill="none" viewBox="0 0 24 24" stroke-width="1.5" :stroke="color">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:24
    },
    height:{
      type:Number,
      default:24
    },
    color:{
      type:String,
      default:'currentColor'
    }
  }
}
</script>
