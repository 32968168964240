<template>
  <div>
    <section class="navbar px-3">
      <div class="container container-xl flex justify-between items-center">
        <div class="flex-initial">
          <p class="mb-0 text-sm" v-if="!isAuthenticated">
            ¿Sos independiente o empresa?
            <router-link
              :to="{ name: 'ProfessionalRegister' }"
              class="inline-flex btn btn--sm btn--white mt-1 md:mt-0 md:ml-1"
              >¡Registrate aquí!</router-link
            >
          </p>
          <div class="availablity-toogle" v-if="isAuthenticated && user.userType == 'professional'">
            <toggle-button
              @change="updateProfessionalAvailability"
              :disabled="processingUpdateProfessional"
              :value="isAvailable"
              :sync="true"
              :color="{ checked: '#ff783e', unchecked: '#CCCCCC', disabled: '#E1F6FB' }"
              :switch-color="{ checked: '#E6E7EC', unchecked: '#E6E7EC' }"
              :labels="{ checked: 'Disponible', unchecked: 'No disponible' }"
              :width="115"
              :height="40"
              :font-size="11"
            />
          </div>
        </div>
        <div class="relative flex-none" style="z-index: 13">
          <nav class="w-auto">
            <ul class="flex items-center flex-wrap text-sm lg:text-base">
              <li
                class="md:inline-flex mr-4 cursor-pointer"
                v-if="isAuthenticated"
                v-click-outside="clickOutsideMessage"
                @mouseover="dropdownOpenMessage = true"
                @mouseout="
                  if (!dropdownClickMessage) {
                    dropdownOpenMessage = false;
                  }
                "
              >
                <div class="flex justify-center">
                  <div>
                    <div
                      ref="button"
                      class="w-8 h-8 flex justify-center items-center bg-white rounded-full mr-1"
                      @click="isOpenDropdown()"
                    >
                      <span
                        class="absolute top-0 flex items-center justify-center w-4 h-4 p-1 ml-2 text-xs text-white bg-red-600 rounded-full"
                        v-if="unreadMessages.length > 0"
                        >{{ unreadMessages.length }}</span
                      >
                      <svg class="icon">
                        <use
                          href="../assets/img/icons.svg#icon-email"
                          xlink:href="../assets/img/icons.svg#icon-email"
                        ></use>
                      </svg>
                    </div>

                    <div
                      v-show="dropdownOpenMessage || dropdownClickMessage"
                      class="absolute right-0 bg-white rounded-md shadow-lg overflow-hidden z-20"
                      style="width: 20rem"
                    >
                      <div v-if="unreadMessages.length > 0">
                        <a
                          v-for="(message, index) in unreadMessages"
                          :key="index"
                          href="javascript:void(0)"
                          @click="redirectMessage(message, index)"
                          class="flex hover:no-underline items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
                        >
                          <p class="text-black text-base mb-0">
                            <span v-if="message.description.length > 100">{{
                              message.description.substring(0, 100) + "..."
                            }}</span>
                            <span v-else>{{ message.description }}</span>
                          </p>
                        </a>
                      </div>
                      <div v-if="unreadMessages.length == 0">
                        <a
                          href="javascript:void(0)"
                          class="flex hover:no-underline items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
                        >
                          <p class="text-black text-base mb-0">No hay nuevos mensajes.</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                class="md:inline-flex mr-4 cursor-pointer"
                v-if="isAuthenticated"
                v-click-outside="clickOutside"
                @mouseover="dropdownOpen = true"
                @mouseout="
                  if (!dropdownClick) {
                    dropdownOpen = false;
                  }
                "
              >
                <div class="flex justify-center">
                  <div>
                    <div
                      ref="button"
                      class="w-8 h-8 flex justify-center items-center bg-white rounded-full mr-1"
                      @click="
                        dropdownOpen = !dropdownOpen;
                        dropdownClick = !dropdownClick;
                      "
                    >
                      <span
                        class="absolute top-0 flex items-center justify-center w-4 h-4 p-1 ml-2 text-xs text-white bg-red-600 rounded-full"
                        v-if="TotalNotification > 0"
                        >{{ TotalNotification }}</span
                      >
                      <svg class="icon">
                        <use
                          href="../assets/img/icons.svg#icon-bell"
                          xlink:href="../assets/img/icons.svg#icon-bell"
                        ></use>
                      </svg>
                    </div>

                    <div
                      v-show="dropdownOpen || dropdownClick"
                      class="absolute right-0 bg-white rounded-md shadow-lg overflow-hidden z-20"
                      style="width: 20rem"
                    >
                      <div v-if="TotalNotification > 0">
                        <a
                          v-for="(notification, index) in notifications"
                          :key="index"
                          href="javascript:void(0)"
                          @click="updateNotificationReadStatus(notification, index)"
                          class="flex hover:no-underline items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
                        >
                          <p class="text-black text-base mb-0">
                            <span v-if="notification.description.length > 100">{{
                              notification.description.substring(0, 100) + "..."
                            }}</span>
                            <span v-else>{{ notification.description }}</span>
                          </p>
                        </a>
                      </div>
                      <div v-if="TotalNotification == 0">
                        <a
                          href="javascript:void(0)"
                          class="flex hover:no-underline items-center px-4 py-3 border-b hover:bg-gray-100 -mx-2"
                        >
                          <p class="text-black text-base mb-0">No hay nuevas notificaciones.</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li class="md:inline-flex">
                <figure class="relative flex items-center group">
                  <div class="flex items-center justify-center mr-2 avatar" v-if="isAuthenticated">
                    <img
                      referrerPolicy="no-referrer"
                      :src="user.pictureProfileUrl"
                      v-if="user.pictureProfileUrl != null"
                      alt="Profile"
                    />
                    <img src="../assets/img/default-user-icon.jpg" v-else alt="Default Profile" />
                  </div>
                  <figcaption>
                    <a v-if="!isAuthenticated" href="/register-client" class="text-sm text-white">
                      {{ headerLoginText }}
                    </a>

                    <a
                      href="#"
                      class="flex items-center text-sm text-white"
                      v-else-if="isAuthenticated"
                    >
                      {{ user.firstName }} {{ user.lastName }}
                      <svg class="ml-2 text-white icon icon--md icon--outline icon--stroke-1">
                        <use
                          href="../assets/img/icons.svg#icon-minimal-down"
                          xlink:href="img/icons.svg#icon-minimal-down"
                        />
                      </svg>
                    </a>
                  </figcaption>
                  <ul class="dropdown group-hover:block md:mt-8" v-if="isAuthenticated">
                    <li v-if="user.userType == 'client'">
                      <router-link :to="{ name: 'ClientProfile' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-user-small"
                            xlink:href="img/icons.svg#icon-user-small"
                          />
                        </svg>
                        Mis datos
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'professional'">
                      <router-link :to="{ name: 'ProfessionalProfile' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-user-small"
                            xlink:href="img/icons.svg#icon-user-small"
                          />
                        </svg>
                        Mi Perfil/Precios
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'client'">
                      <router-link :to="{ name: 'ServiceRequest' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Mis solicitudes
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'professional'">
                      <router-link
                        :to="{ name: 'ProfessionalServiceRequest' }"
                        class="icon-text-aligner"
                      >
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Mis solicitudes
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'admin'">
                      <router-link :to="{ name: 'ActivateEmail' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Activar email
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'admin'">
                      <router-link :to="{ name: 'AdminServiceRequests' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Servicios solicitados
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'admin'">
                      <router-link :to="{ name: 'AdminBudgetRequest' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Presupuestos solicitados
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'admin'">
                      <router-link :to="{ name: 'AdminReview' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Reviews
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'admin'">
                      <router-link
                        :to="{ name: 'AdminProfessionalRequest' }"
                        class="icon-text-aligner"
                      >
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Solicitudes de profesionales
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'admin'">
                      <router-link :to="{ name: 'AdminTransferRequest' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Transferencias bancarias a confirmar
                      </router-link>
                    </li>
                    <li v-if="user.userType == 'admin'">
                      <router-link :to="{ name: 'AdminClients' }" class="icon-text-aligner">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-file-content-small"
                            xlink:href="img/icons.svg#icon-file-content-small"
                          />
                        </svg>
                        Clientes
                      </router-link>
                    </li>
                    <li>
                      <a class="icon-text-aligner" href="#" @click="logout">
                        <svg class="ml-2 icon">
                          <use
                            href="../assets/img/icons.svg#icon-leave-small"
                            xlink:href="img/icons.svg#icon-leave-small"
                          />
                        </svg>
                        Salir
                      </a>
                      <Facebook ref="fbComponent" style="display: none" />
                      <Google ref="googleComponent" style="display: none" />
                    </li>
                  </ul>
                </figure>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>

    <header class="header bg-white py-4 md:py-4" v-if="!hideHowWorks">
      <div
        class="container flex flex-col md:flex-row items-center justify-between container-xl relative"
        style="z-index: 12"
      >
        <figure class="w-full md:w-auto text-center md:text-left hidden md:block">
          <a href="/"><img src="../assets/img/logo.svg" class="inline-block" /></a>
          <span
            class="text-2xl md:text-3xl ml-2 font-bold md:inline-block hidden"
            style="color: #ff6711"
            >¡te da una mano!</span
          >
        </figure>

        <nav class="w-full md:w-auto">
          <ul
            class="flex flex-wrap items-center text-sm lg:text-base justify-center md:justify-end"
          >
            <li class="px-6 mb-4 md:mb-0">
              <router-link :to="{ name: 'Help' }" class="text-gray-900 text-xl font-bold"
                >¿Cómo funciona?</router-link
              >
            </li>
            <li v-if="!isAuthenticated && this.$route.name != 'Login'">
              <router-link
                :to="{ name: 'LoginClient' }"
                class="btn btn--secondary rounded-full uppercase text-lg px-4 md:px-8 py-1 md-py-3"
              >
                ingresa ahora
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { bus } from "../main";
import { mapGetters } from "vuex";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
import io from "socket.io-client";
import Swal from "sweetalert2";
import getMessages from "@/utils/methods/getMessages";
import getMessageRequestCount from "@/utils/methods/getMessageRequestCount";
const handToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export default {
  name: "Header",

  props: {
    hideHowWorks: Boolean,
  },
  computed: {
    ...mapGetters(["headerLoginText", "isAuthenticated", "user"]),
    unreadMessages() {
      let unreadMessage = [];
      if (this.messages && this.messages.length > 0) {
        if (this.user.userType == "client") {
          unreadMessage = this.messages.filter((message) => {
            return !message.read_by_client;
          });
        } else if (this.user.userType == "professional") {
          unreadMessage = this.messages.filter((message) => {
            return !message.read_by_professional;
          });
        }
      } else {
        return [];
      }
      return unreadMessage;
    },
  },
  data() {
    return {
      notifications: [],
      TotalNotification: 0,
      connection: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      processingNotificationRequest: false,
      updateNotificationData: {
        requestData: { read: true },
        notificationId: null,
      },
      dropdownOpen: false,
      dropdownClick: false,
      messages: [],
      dropdownOpenMessage: false,
      dropdownClickMessage: false,
      isAvailable: true,
      processingUpdateProfessional: false,
      updateProfessionalData: {
        professionalId: null,
        requestData: {
          isAvailable: "",
        },
      },
      messageRequestCount: 0,
      interval: null,
      totalReadCount: 0,
      messageTimeout: process.env.VUE_APP_MESSAGE_TIMEOUT,
    };
  },
  mounted() {
    if (this.isAuthenticated && this.user.userType != "admin") {
      this.getMessages();
      this.interval = setInterval(() => this.getMessageRequestCount(), this.messageTimeout * 1000);
      if (this.user.professionalProfile) {
        this.isAvailable = this.user.professionalProfile.isAvailable;
      }
    }
  },
  beforeDestroy() {
    if (this.isAuthenticated) {
      clearInterval(this.interval);
    }
  },
  created() {
    let socket = undefined;
    const url = this.baseUrl;
    socket = io(url);
    socket.on("connections", (data) => {
      this.connection = data;
      socket.emit("requestNotification", this.user.id);
    });
    socket.on("getNotification", (data) => {
      this.TotalNotification = data.Total;
      this.notifications = data.notifications;
      let totalNotification = parseInt(localStorage.getItem("notification")) || 0;
      if (this.TotalNotification > totalNotification) {
        bus.$emit("refreshIt", "yes");
        handToast.fire({
          icon: "success",
          title: "Tiene una nueva notificación en Hand",
        });
      }
      localStorage.setItem("notification", this.TotalNotification);
    });
  },
  methods: {
    getMessages,
    getMessageRequestCount,
    clickOutside() {
      this.dropdownOpen = false;
      this.dropdownClick = false;
    },
    clickOutsideMessage() {
      this.dropdownOpenMessage = false;
      this.dropdownClickMessage = false;
    },
    logout() {
      this.$refs.fbComponent.fbLogout();
      this.$refs.googleComponent.handleClickSignOut();
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$store.dispatch("setShowAlert", false);
          window.location.href = "/login/client";
        })
        .catch(() => {
          let errorMessage = "Something Went Wrong";
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
        });
    },
    updateNotificationReadStatus(notification, key) {
      this.processingNotificationRequest = true;
      this.updateNotificationData.notificationId = notification.id;
      this.$store
        .dispatch("updateNotificationReadStatus", this.updateNotificationData)
        .then(() => {
          this.processingNotificationRequest = false;
          this.dropdownOpen = false;
          this.dropdownClick = false;
          this.TotalNotification--;
          this.notifications.splice(key, 1);
          this.redirectNotification(notification);
        })
        .catch(() => {
          this.processingNotificationRequest = false;
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    redirectNotification(notification) {
      if (notification.eventTypeModel == "ServiceRequest") {
        if (this.user.userType == "client") {
          this.$router
            .push({
              name: "ServiceRequestSection",
              params: { id: notification.eventId },
            })
            .catch((error) => {
              if (error.name == "NavigationDuplicated") {
                this.$emit("goToServiceRequest", notification);
              } else if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        } else if (this.user.userType == "professional") {
          this.$router
            .push({
              name: "ProfessionalServiceRequestSection",
              params: { id: notification.eventId },
            })
            .catch((error) => {
              if (error.name == "NavigationDuplicated") {
                this.$emit("goToServiceRequest", notification);
              } else if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        }
      }
    },
    redirectMessage() {
      if (this.user.userType == "client") {
        this.$router
          .push({
            name: "Message",
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      } else if (this.user.userType == "professional") {
        this.$router
          .push({
            name: "ProfessionalMessage",
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      }
    },
    updateProfessionalAvailability(status) {
      this.processingUpdateProfessional = true;
      this.updateProfessionalData.professionalId = this.user.professionalProfile.id;
      this.updateProfessionalData.requestData.isAvailable = status.value;
      this.$store
        .dispatch("updateProfessionalAvailability", this.updateProfessionalData)
        .then((res) => {
          this.processingUpdateProfessional = false;
          this.isAvailable = status.value;
          handToast.fire({
            icon: "success",
            title: res.msg,
          });
        })
        .catch(() => {
          this.processingUpdateProfessional = false;
          handToast.fire({
            icon: "error",
            title: "Error ocurrido: algo salió mal, intente de nuevo",
          });
        });
    },
    isOpenDropdown() {
      if (this.unreadMessages.length > 0) {
        this.dropdownOpenMessage = !this.dropdownOpenMessage;
        this.dropdownClickMessage = !this.dropdownClickMessage;
      } else {
        if (this.user.userType == "client") {
          this.$router
            .push({
              name: "Message",
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        } else if (this.user.userType == "professional") {
          this.$router
            .push({
              name: "ProfessionalMessage",
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        }
      }
    },
  },
  directives: {
    "click-outside": {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },
      unbind: function (el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  components: {
    Facebook,
    Google,
  },
};
</script>
<style scoped>
@import "../assets/css/index.css";
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.color-white {
  color: #fff;
}
.availablity-toogle label {
  margin-bottom: 0px;
}
</style>
<style>
@media (min-width: 768px) {
  .md-py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
}
</style>
