<template>
  <img
    ref="profilePicture"
    :src="!!url ? url : '/img/default-user-icon.4aa4dc7e.jpg'"
    class="rounded-full"
    alt=""
  />
</template>
<script>
export default {
  props: {
    url: {
      type: String,
    },
  },
  mounted() {
    this.$refs['profilePicture'].setAttribute(
      'src',
      !!this.url ? this.url : '/img/default-user-icon.4aa4dc7e.jpg',
    );
  },
};
</script>
