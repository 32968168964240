<template>
  <div>
    <Header />
    <Messages />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      token: "",
      processingActivate: false
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.token = this.$route.params.tid;
    this.ActivateUser();
  },
  methods: {
    ActivateUser() {
      this.processingActivate = true;
      if (this.token) {
        this.$store
          .dispatch("ActivateAccount", {
            token: this.token
          })
          .then(() => {
            this.processingActivate = true;
            let activateMessage = `Activate Successfully. Redirecting to login...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", activateMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function() {
              self.$store.dispatch("setShowAlert", false);
              self.$router.push({ name: "Login" });
            }, 2000);
          })
          .catch(() => {
            this.processingActivate = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingActivate = false;
        this.$router.push("/login");
      }
    }
  },
  computed: {
    ...mapGetters(["error"])
  },
  components: {
    Header,
    Footer,
    Messages
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
