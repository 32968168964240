<template>
  <div class="w-full px-[53px] py-[75px] flex max-xl:flex-col-reverse min-xl:flex-row xl:justify-between">
    <div class="max-xl:hidden w-[191px]">
      <rounded-button v-if="$route.params.step>1 && $route.params.step<=3" ghost primary :onClick="previousStep">
        Volver atrás
      </rounded-button>
    </div>
    <div class="w-full xl:hidden mb-[26px]">
      <custom-link v-if="currentStep>1 && currentStep<=3" :onClick="previousStep">
        Atrás
      </custom-link>
    </div>
    <div class="max-xl:w-full w-[191px] mb-[15px] xl:mb-0">
      <rounded-button v-if="currentStep<=3" primary :onClick="nextStep">
        Siguiente
      </rounded-button>
    </div>
  </div>
</template>
<script>
import {RoundedButton} from "@/components/v2/buttons";
import CustomLink from "@/components/v2/buttons/Link.vue";
export default {
  components:{
    CustomLink,
    RoundedButton
  },
  methods:{
    previousStep()
    {
      this.$emit('previous-step')
    },
    nextStep()
    {
      this.$emit('next-step')
    }
  },
  computed:{
    currentStep()
    {
      return parseInt(this.$route.params.step)
    }
  }
}
</script>
