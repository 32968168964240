<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <ProfileSidebar />
          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
              <h4 class="mb-3 lg:mt-0 lg:mb-0">
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-todo"
                    xlink:href="img/icons.svg#icon-todo"
                  />
                </svg>
                Presupuestos solicitados
              </h4>
              <div>
                <label class="inline-flex items-center mb-0">
                  <input
                    type="radio"
                    class="form-radio"
                    name="solicitudEstado"
                    value="pending"
                    v-model="requestStatus"
                    @change="GetUserBudgetRequest()"
                  />
                  <span class="ml-2">Pendientes</span>
                </label>
                <label class="inline-flex items-center mb-0 ml-6">
                  <input
                    type="radio"
                    class="form-radio"
                    name="solicitudEstado"
                    value="complete"
                    v-model="requestStatus"
                    @change="GetUserBudgetRequest()"
                  />
                  <span class="ml-2">Concretados</span>
                </label>
              </div>
            </div>

            <div v-if="budgetRequests.length > 0">
              <div
                class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
                v-for="(budgetRequest, index) in budgetRequests"
                :key="index"
              >
                <header
                  :id="'budget_' + budgetRequest.id"
                  class="flex items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white border-b border-gray-200"
                >
                  <div class="flex flex-col lg:flex-row">
                    <strong>Estado:</strong>
                    <span
                      class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
                      v-if="budgetRequest.status == 'complete'"
                      >Concretado</span
                    >
                    <span class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary" v-else>Pendiente</span>
                  </div>
                </header>

                <div class="flex flex-col justify-between pt-4 lg:flex-row">
                  <figure class="flex items-center lg:mb-0 lg:w-2/12 lg:mr-4 lg:flex-col">
                    <img
                      :src="budgetRequest.Professional.pictureProfileUrl"
                      :alt="
                        budgetRequest.Professional.firstName +
                          ' ' +
                          budgetRequest.Professional.lastName
                      "
                      class="object-cover shadow avatar avatar--xl"
                    />
                    <figcaption
                      class="pl-2 text-2xl text-center text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
                    >
                      {{ budgetRequest.Professional.firstName }}
                      {{ budgetRequest.Professional.lastName }}
                    </figcaption>
                  </figure>

                  <hr class="my-3 -mx-5 border-gray-200 lg:hidden" />

                  <div class="flex-1">
                    <div class="flex flex-col -mx-2 lg:flex-row">
                      <div class="px-2 w-1/1 lg:w-1/3">
                        <h6 class="mb-0">Servicio solicitado:</h6>
                        <ul class="mb-3 ml-8 list-disc">
                          <li v-for="(Service, index) in budgetRequest.Services" :key="index">
                            {{ Service.description }}
                          </li>
                        </ul>
                        <h6 class="mb-0">Orden:</h6>
                        <p>{{ budgetRequest.id }}</p>
                      </div>

                      <div class="px-2 w-1/1 lg:w-1/3">
                        <h6 class="mb-0">Solicitado el:</h6>
                        <p>{{ moment(budgetRequest.createdOn).format("DD/MM/YYYY") }}</p>
                        <h6 class="mb-0">Agendado para:</h6>
                        <p>
                          {{
                            moment(budgetRequest.budgetScheduledDate).format("DD/MM/YYYY - HH:MM")
                          }}hs
                        </p>
                      </div>

                      <div class="px-2 w-1/1 lg:w-1/3">
                        <h6 class="mb-0">Precio:</h6>
                        <p>Gs.{{ totalServiceCost(budgetRequest.Services) | thousandSeparator }}</p>
                      </div>
                    </div>
                    <h6 class="mb-0">Información adicional:</h6>
                    <p>{{ budgetRequest.description }}</p>
                    <h6>Imágenes adjuntadas:</h6>
                    <div
                      class="grid mb-4 grid-columns-4 md:grid-columns-6 grid-gap-2"
                      v-if="budgetRequest.Photos && budgetRequest.Photos.length > 0"
                    >
                      <figure
                        class="p-1 border border-gray-200 rounded shadow"
                        v-for="(Photo, index) in budgetRequest.Photos"
                        :key="index"
                      >
                        <a :href="Photo.photoUrl" target="_blank"
                          ><img :src="Photo.photoUrl" class="w-full"
                        /></a>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container msg-section" v-else>
              <section
                class="flex flex-col items-center md:flex-row alert alert--success shadow alert--info"
              >
                <div class="md:pr-6 mb-2">
                  <svg class="text-6xl text-blue-800 icon md:text-5xl">
                    <use
                      href="../assets/img/icons.svg#icon-alert-circle-i"
                      xlink:href="../assets/img/icons.svg#icon-alert-circle-i"
                    />
                  </svg>
                </div>
                <div class="text-center md:text-left">
                  <p class="text-sm">No hay solicitudes de presupuestos</p>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfileSidebar from "@/components/ProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import totalServiceCost from "@/utils/methods/totalServiceCost";
import GetUserBudgetRequest from "@/utils/methods/GetUserBudgetRequest";
import scrollToSection from "@/utils/methods/scrollToSection";
export default {
  data() {
    return {
      processingBudgetRequests: false,
      requestStatus: "pending",
      budgetRequests: {}
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetUserBudgetRequest();
    if (this.$route.params.id) {
      var id = this.$route.params.id;
      var sectionId = "budget_" + id;
      this.scrollToSection(sectionId);
    }
  },
  methods: {
    GetUserBudgetRequest,
    totalServiceCost,
    scrollToSection
  },
  filters: {
    thousandSeparator
  },
  computed: {
    ...mapGetters(["error", "user"])
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfileSidebar
  }
};
</script>
<style lang="css" scoped>
@import "../assets/css/dashboard.css";
.success {
  color: #1bb01b;
}
</style>
