export default function (items) {
  if (items.length > 0) {
    var sum = 0;
    items.forEach((e) => {
      if (!e.quantity) {
        e.quantity = 1;
      }
      sum += e.quantity * e.price;
    });
    return sum;
  } else {
    return 0;
  }
}
