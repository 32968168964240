<template>
  <div>
    <v-facebook-login
      :app-id="facebook.appId"
      class="btn btn--primary w-full icon-text-aligner text-left leading-tight text-lg p-3 cursor-pointer"
      v-model="facebook.model"
      @login="fbLogin"
      @sdk-init="fbSdkInit"
      text-class="justify-between line-height-1"
    >
      <template v-slot:login>
        <template v-if="title">{{ title }}</template>
        <template v-if="!title"> Registrate con Facebook </template>
      </template>
      <template v-slot:logo>
        <img src="../assets/img/icon-facebook.svg" class="w-8 icon--align-right ml-auto fb-icon" />
      </template>
      icon-facebook
    </v-facebook-login>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import VFacebookLogin from "vue-facebook-login-component";
import { Users } from "@/services";
export default {
  name: "Facebook",
  props: {
    title: {
      type: String,
      default: "",
    },
    usertype: {
      type: String,
      default: "professional",
    },
  },
  data: () => ({
    facebook: {
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      FB: {},
      model: {},
      scope: {},
    },
    fbUser: {},
    fbAuthResponse: {}
  }),
  methods: {
    doLogin() {
      document.querySelector(".v-facebook-login").click();
    },
    fbLogin(loginResponse) {
      this.fbAuthResponse = loginResponse ? loginResponse.authResponse : {}
      this.getFbUserData();
      var self = this;
      setTimeout(function () {
        self.facebookLogin();
      }, 2000);
    },
    fbLogout() {
      if (this.facebook.scope.logout && this.facebook.model.connected) {
        this.facebook.scope.logout();
      }
    },
    fbSdkInit({ FB, scope }) {
      this.facebook.scope = scope;
      this.facebook.FB = FB;
    },
    getFbUserData() {
      this.facebook.FB.api(
        "/me",
        { fields: "id, name,email, picture.width(350).height(350),first_name,last_name" },
        (user) => {
          this.fbUser = user;
        }
      );
    },
    facebookLogin() {
      if (!this.isAuthenticated && this.idle && this.connected && this.fbUser) {
        const data = {
          oauthUid: this.fbUser.id,
          email: this.fbUser.email,
          firstName: this.fbUser.first_name,
          lastName: this.fbUser.last_name,
          pictureProfileUrl: this.avatarUrl,
          oauthProvider: "facebook",
          userType: this.usertype,
          accessToken: this.fbAuthResponse.accessToken
        };
        //Se pasa los datos a los componentes para autorrellenarlos
        this.$emit('updateData', data, this.callback);
        this.processingLogin = false;
        //this.fbLogout();
      } else {
        this.processingLogin = false;
      }
    },
    callback() {
      this.fbLogout()
    },
    ...mapMutations(["fetchLogin", "success"]),
  },
  components: {
    VFacebookLogin,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "error", "ClientTempData"]),
    idle() {
      return this.facebook.model.idle;
    },
    connected() {
      return this.facebook.model.connected;
    },
    disconnected() {
      return !this.connected;
    },
    avatarUrl() {
      if (this.fbUser && this.fbUser.picture && this.fbUser.picture.data.url) {
        return this.fbUser.picture.data.url;
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="css">
.fb-icon {
  order: 2;
}
.line-height-1 {
  line-height: 1.125;
}
.v-facebook-login:hover {
  background-color: #304fc8 !important;
}
.v-facebook-login {
  background-color: #3e65ff !important;
}
</style>
