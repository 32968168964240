<template>
  <div>
    <button
      type="button"
      class="btn btn--primary w-full icon-text-aligner text-left leading-tight text-lg p-3"
      @click="handleClickSignIn"
      
      :disabled="!isInit"
    >
      <span class="justify-between" style="line-height:1;">
        Continúa con Google</span
      >
      <img
        src="../assets/img/icon-google.svg"
        class="w-8 icon--align-right ml-auto bg-white p-2 rounded"
      />
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Google",
  data: () => ({
    isInit: false,
    Google: {},
    isSignIn: false,
    googleUser: {},
    googleAuthResponse: {}
  }),
  created() {
    let that = this;
    let checkGauthLoad = setInterval(function() {
      that.isInit = that.$gAuth.isInit;
      that.isSignIn = that.$gAuth.isAuthorized;
      if (that.isInit) clearInterval(checkGauthLoad);
    }, 1000);
  },
  methods: {
    handleClickSignIn() {
      this.$gAuth.signIn().then(GoogleUser => {
        this.googleAuthResponse =GoogleUser.getAuthResponse();
        console.log(this.googleAuthResponse.access_token);
        this.isSignIn = this.$gAuth.isAuthorized;
        this.Google = GoogleUser.getBasicProfile();
        this.googleUser.id = this.Google.getId();
        this.googleUser.email = this.Google.getEmail();
        this.googleUser.name = this.Google.getName();
        this.googleUser.firstName = this.Google.getGivenName();
        this.googleUser.lastName = this.Google.getFamilyName();
        this.googleUser.avatarUrl = this.Google.getImageUrl();
        this.googleLogin();
      });
    },
    handleClickSignOut() {
      this.$gAuth.signOut().then(() => {
        this.isSignIn = this.$gAuth.isAuthorized;
      });
    },
    googleLogin() {
      if (this.isSignIn) {
        this.$store.dispatch("hideMessageLink");
        this.$store
          .dispatch("socialLoginGoogle", {
            access_token: this.googleAuthResponse.access_token,
          })
          .then(() => {
            this.processingLogin = true;
            let loginMessage = `Datos correctos! redireccionando...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", loginMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function() {
              self.$store.dispatch("setShowAlert", false);

              let isRedirectProfileId = null;
              let isProfessionId = null;
              if (self.ClientTempData && self.ClientTempData != null) {
                isRedirectProfileId = self.ClientTempData.professionalId
                  ? self.ClientTempData.professionalId
                  : null;
                isProfessionId = self.ClientTempData.profession_id
                  ? self.ClientTempData.profession_id
                  : null;
              }
              if (self.redirectpath) {
                self.$router.push(self.redirectpath);
              } else if (isRedirectProfileId != null && isProfessionId != null) {
                self.$router.push({
                  name: "ProfessionalsProfile",
                  params: { proid: isProfessionId, pid: isRedirectProfileId }
                });
              } else {
                self.$router.push({ name: "Dashboard" });
              }
            }, 2000);
          })
          .catch(() => {
            let errorMessage = this.error.response.data.msg;
            if (this.error.response.data.provider) {
              this.handleClickSignOut();
              if (this.error.response.data.provider == "mail") {
                this.$store.dispatch("setIsShowMailLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado manualmente, por favor inicie sesión";
              } else if (this.error.response.data.provider == "facebook") {
                this.$store.dispatch("setIsShowFacebookLogin", true);
                errorMessage =
                  "Este email ya ha sido registrado por facebook, por favor inicie sesión";
              }
            }
            this.processingLogin = false;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingLogin = false;
      }
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "error", "ClientTempData"])
  }
};
</script>
