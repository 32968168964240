import Toastify from "toastify-js";

const toast = (message, type, callback, options) => {
    return Toastify({
        text: message,
        className: type ? `toast toast-${type}` : 'toast toast-success', // type: success | info | error
        selector: 'toast',
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        callback: callback,
        avatar: `/../../img/toast-${type}.svg`,
        ...options
    })
}

export default toast