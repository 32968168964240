<template>
  <div class="w-full pt-[90px]">
    <div class="w-full px-[50px] xl:pl-[53px]">
      <span class="leading-[19px] xl:text-[25px] xl:leading-[29px] font-bold text-black">1. Selecciona tus rubros</span>
      <div class="max-xl:w-full xl:w-1/2 mt-[8px]">
        <p class="max-xl:text-[14px] max-xl:leading-[16px] leading-[19px] text-dark3">
          Selecciona máximo 5 rubros a los cuáles te dediques, ten en cuenta que es la manera en la que futuros clientes te busquen.
        </p>
      </div>
      <div class="xl:w-2/6 my-[40px]">
        <rounded-button
          :disabled="!(rubros && rubros.length<5 && rubrosAvailables.length>0)"
          :onClick="newRubro"
          :color="'secondary'"
          
        >
          <div class="w-full flex justify-between pl-[30px] pr-[11px]">
            Agregar rubro
            <div class="flex">
              <div class="mr-[8px]">
                <plus-circle-icon />
              </div>
              <!--
              <div class="mr-[8px]">
                <substract-circle-icon />
              </div>
              -->
            </div>
          </div>
        </rounded-button>
      </div>
    </div>
    <div class="w-full flex flex-col px-[50px] xl:px-0">
      <template v-for="(r,i) in rubros">
        <rubro-selector
          :key="r.id"
          :index="r.id"
          :disable_delete="i==0 && rubros.length==1"
          :initial_value="r"
          @delete="()=>deleteRubro({rubroIndex:i})"
          @change="(rubro)=>onSelectRubro({rubro,rubroIndex:i})"
          :professions="professions"
          :disabled_professions="rubros.map(({id})=>id)"
        />
      </template>
    </div>
    <confirmation 
      :show="completeProfileModalDisplayed" 
      :showBack="false" 
      :title="'Bienvenido a Hand'" 
      :subtitle="'Tu perfil profesional'"
      :buttonText="'Comenzar'"
      @afterConfirmation="onModalConfirm">
        <template #modal-content>
          <div class="w-full border border-grayLight rounded-[6px] py-[16px] px-[20px] mt-[32px] flex">
            <div class="flex-grow flex flex-col ml-[16px]">
              <div class="w-full flex items-center">
                <span class="text-[18px] font-semibold text-black">Queda solo un paso</span>
              </div>
              <div class="w-full mt-[15px]">
                <p class="text-black text-[14px]">Es momento de crear tu perfil profesional, el cual verán tus clientes para contratarte. </p>
              </div>
            </div>
          </div>
        </template>
    </confirmation>
  </div>
</template>
<script>
import {RoundedButton} from "@/components/v2/buttons";
import {PlusCircleIcon,SubstractCircleIcon} from "@/components/v2/icons";
import RubroSelector from "./RubroSelector.vue";
import {AlertError} from "@/components/v2/Alerts"
import {mapMutations, mapState} from "vuex";
import Confirmation from "@/components/v2/forms/Confirmation.vue";

export default{
  components:{
    RubroSelector,
    RoundedButton,
    PlusCircleIcon,
    SubstractCircleIcon,
    AlertError,
    Confirmation
  },
  props:{
    initial_data:{
      type:Object,
      default:()=>({
        //rubros:[]
      })
    }
  },
  data()
  {
    return {
    }
  },
  beforeMount() {

  },
  methods:{
    newRubro()
    {
      const rubro = this.rubrosAvailables[0]
      this.addRubro(rubro)
    },
    onSelectRubro({rubro,rubroIndex})
    {
      this.updateRubro({rubro,rubroIndex})
    },
    onModalConfirm() {
      this.setCompleteProfileModalDisplayed()
    },
    ...mapMutations('completeProfessionalProfile',['updateRubro','deleteRubro','addRubro', 'setCompleteProfileModalDisplayed'])
  },
  computed:{
    ...mapState('completeProfessionalProfile',['rubros','professions', 'completeProfileModalDisplayed']),
    rubrosAvailables()
    {
      const ids = this.rubros.map(({id})=>id);
      return this.professions.filter(r=>!ids.includes(r.id))
    }
  }
}
</script>

