<template>
  <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between mb-2 lg:mb-4 lg:flex-row lg:items-center">
              <h4 class="mb-0 lg:mt-0">
                <svg class="-mt-2 icon">
                  <use
                    href="../../assets/img/icons.svg#icon-email"
                    xlink:href="../../assets/img/icons.svg#icon-email"
                  />
                </svg>
                Editar fotos de trabajos
              </h4>
            </div>

            <div class="mb-2 overflow-x-auto bg-white rounded shadow-md register-professional">
              <div class="flex flex-col md:flex-row md:items-center">
                <div class="w-full mb-16 md:mb-0">
                  <div class="p-5 overflow-hidden bg-white border border-gray-400">
                    <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                      <svg class="icon">
                        <use
                          href="../../assets/img/icons.svg#icon-polaroid-user"
                          xlink:href="../../assets/img/icons.svg#icon-polaroid-user"
                        />
                      </svg>
                      Fotografías
                    </h6>
                    <p>Haga click sobre la imagen para pre-visualizar</p>
                    <div
                      class="grid mb-4 grid-columns-4 md:grid-columns-4 grid-gap-2"
                      v-if="selectedPhotos && selectedPhotos.length > 0"
                    >
                      <figure
                        class="relative border border-gray-200 rounded shadow"
                        v-for="(img, key) in selectedPhotos"
                        :key="key"
                        role="button"
                      >
                        <img
                          :src="img.photoUrl"
                          class="w-full"
                          @click="viewFullImage(img.photoUrl)"
                        />
                        <span
                          v-show="photo"
                          @click="isConfirmRemovePhoto(img.id)"
                          class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                          >x</span
                        >
                      </figure>
                    </div>
                    <hr class="my-8 -mx-5 border-gray-200" />
                    <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                      <svg class="icon">
                        <use
                          href="../../assets/img/icons.svg#icon-polaroid-user"
                          xlink:href="../../assets/img/icons.svg#icon-polaroid-user"
                        />
                      </svg>
                      Agregar
                    </h6>
                    <form @submit.prevent="updateProfessionalPhotos" method="post">
                      <label>
                        <div>
                          <input
                            class="hidden w-full mt-1 mb-2 form-input"
                            type="file"
                            name="photos[]"
                            @change="photosAdd"
                            placeholder="Nombre"
                            ref="photo"
                            multiple
                          />
                        </div>
                        <div
                          v-bind:class="{
                            OnErrorScrollHere: submited && photos && photos.length == 0
                          }"
                        >
                          <button
                            type="button"
                            @click="$refs.photo.click()"
                            class="btn btn--primary"
                          >
                            Elegir archivos
                          </button>
                        </div>
                        <span class="error" v-if="submited && photos && photos.length == 0"
                          >Favor seleccione al menos una imagen</span
                        >
                        <div v-if="imageSizeError">
                          <span class="error" v-html="imageSizeError"></span>
                        </div>
                        <!-- new image end -->
                      </label>
                      <div
                        class="grid mb-4 grid-columns-3 md:grid-columns-4 grid-gap-2"
                        v-if="photo && photo.length > 0"
                      >
                        <figure
                          class="relative border border-gray-200 rounded shadow"
                          v-for="(img, key) in photo"
                          :key="key"
                        >
                          <img :src="img" class="w-full" />
                          <span
                            v-show="photo"
                            @click="removePhotoPreview(key)"
                            class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                            >x</span
                          >
                        </figure>
                      </div>

                      <div class="flex justify-end">
                        <button class="w-full btn btn--success lg:w-auto">
                          Guardar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfessionalProfileSidebar from "@/components/ProfessionalProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import scrollToSection from "@/utils/methods/scrollToSection";
import AdminProfileSidebar from "@/components/AdminProfileSidebar.vue";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      submited: false,
      processingProfessionalData: false,
      processingUpdateProfessional: false,
      ProfessionalId: null,
      professionalInfo: {},
      photos: [],
      photo: [],
      selectedPhotos: [],
      updateProfessionalData: {
        professionalId: null,
        formdata: null
      },
      imageSizeError: ""
    };
  },
  created() {
    if (this.$route.params.id) {
      this.ProfessionalId = this.$route.params.id;
    } else {
      this.ProfessionalId = this.user.id;
    }
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetProfessionalData();
  },
  beforeDestroy() {},
  methods: {
    scrollToSection,
    GetProfessionalData() {
      this.processingProfessionalData = true;
      this.$store
        .dispatch("GetProfessionalData", {
          userId: this.ProfessionalId
        })
        .then(response => {
          this.professionalInfo = response;
          this.selectedPhotos = this.professionalInfo.Photos;
          this.processingProfessionalData = false;
        })
        .catch(() => {
          this.processingProfessionalData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    updateProfessionalPhotos() {
      this.submited = true;
      this.processingUpdateProfessional = true;
      if (this.validateForm()) {
        var images = require.context("../../assets/img/", false, /\.gif$/);
        Swal.fire({
          imageUrl: images("./loading.gif"),
          imageAlt: "Loader",
          showConfirmButton: false,
          background: "transparent",
          backdrop: `rgba(0,0,0,0.6)`,
          allowOutsideClick: false
        });

        this.updateProfessionalData.userId = this.ProfessionalId;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.photo.files.length; i++) {
          let file = this.$refs.photo.files[i];
          formdata.append("files", file);
        }
        this.updateProfessionalData.formdata = formdata;
        this.$store
          .dispatch("updateProfessionalPhotos", this.updateProfessionalData)
          .then(response => {
            let UpdatePriceMessage = response.msg;
            Swal.close();
            this.submited = false;
            this.photos = [];
            this.photo = [];
            this.$refs.photo.value = "";
            this.GetProfessionalData();

            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", UpdatePriceMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.$refs.photo.value = "";
            this.processingUpdateProfessional = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch(() => {
            Swal.close();
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            this.submited = false;
            this.processingUpdateProfessional = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingUpdateProfessional = false;
        setTimeout(function() {
          var d = document.getElementsByClassName("OnErrorScrollHere")[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: "smooth" });
        }, 1000);
      }
    },
    validateForm() {
      return this.photos.length > 0;
    },
    photosAdd(e) {
      var files = e.target.files || e.dataTransfer.files;
      Array.prototype.push.apply(this.photos, files);
      if (!this.photos.length) return;
      this.createPhotos(this.photos);
    },
    createPhotos(file) {
      this.photo = [];
      let imageHaveError = [];
      for (var i = 0; i < file.length; i++) {
        if (file[i].size > 1024 * 1024 * 10) {
          imageHaveError.push({ filename: file[i].name, hasError: "size" });
          this.photos.splice(i, 1);
        } else {
          if (file[i]["type"] == "image/png" || file[i]["type"] == "image/jpeg") {
            var reader = new FileReader();
            var vm = this;
            reader.onload = e => {
              vm.photo.push(e.target.result);
            };
            reader.readAsDataURL(file[i]);
          } else {
            imageHaveError.push({ filename: file[i].name, hasError: "type" });
            this.photos.splice(i, 1);
          }
        }
      }
      if (imageHaveError.length > 0) {
        this.imageSizeError = "";
        for (var t = 0; t < imageHaveError.length; t++) {
          if (imageHaveError[t].hasError == "type") {
            this.imageSizeError +=
              imageHaveError[t].filename + " Formato de fotografía no soportado <br>";
          } else {
            this.imageSizeError +=
              imageHaveError[t].filename +
              " supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB <br>";
          }
        }
      } else {
        this.imageSizeError = "";
      }
    },

    removePhotoPreview(key) {
      this.photo.splice(key, 1);
      this.photos.splice(key, 1);

      if (!this.photo.length) {
        this.$refs.photo.value = "";
      }
    },
    viewFullImage(img) {
      Swal.fire({
        imageUrl: img,
        padding: "0px",
        imageAlt: "Preview Image",
        showConfirmButton: false,
        showCloseButton: true
      });
    },
    isConfirmRemovePhoto(photoId) {
      Swal.fire({
        title: "",
        text: "Está seguro que desea eliminar esta fotografía?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          this.deleteProfessionPhoto(photoId);
        }
      });
    },
    deleteProfessionPhoto(photoId) {
      this.$store
        .dispatch("deleteProfessionPhoto", { userId: this.ProfessionalId, photoId: photoId })
        .then(response => {
          this.GetProfessionalData();
          let removeServiceMessage = response.msg;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", removeServiceMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
        });
    }
  },

  computed: {
    ...mapGetters(["error", "user"])
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfessionalProfileSidebar,
    AdminProfileSidebar
  }
};
</script>
<style lang="css" scoped>
@import "../../assets/css/registrarme-profesional.css";

.success {
  color: #1bb01b;
}
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.swal2-image {
  margin: 0px !important;
}
</style>
<style>
.swal2-image {
  margin: 0px !important;
}
.swal2-close,
.swal2-close:hover {
  background: #0000007d !important;
}
</style>
