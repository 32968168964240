export default function() {
  this.submited = true;
  this.processingSaveMessage = true;
  if (this.validateMessageForm()) {
    var formdata = new FormData();
    formdata.append("id", this.messageData.id);
    formdata.append("type", this.messageData.type);
    formdata.append("description", this.messageData.description);
    if (this.attachFile) {
      for (var j = 0; j < this.attachFile.length; j++) {
        let file = this.attachFile[j];
        formdata.append("attachFile", file);
      }
    }
    this.$store
      .dispatch("submitMessage", formdata)
      .then(() => {
        this.messageData.description = "";
        this.removeattachFile();
        this.processingSaveMessage = false;
        this.submited = false;
        this.getMessageData();
        this.scrollToBottom();
      })
      .catch(() => {
        let errorMessage = this.error.response.data.msg;
        this.$store.dispatch("setIsSuccessAlert", false);
        this.$store.dispatch("setIsErrorAlert", true);
        this.$store.dispatch("setGlobalMessage", errorMessage);
        this.$store.dispatch("setShowAlert", true);
        this.processingSaveMessage = false;
        this.submited = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
  } else {
    this.processingSaveMessage = false;
  }
}
