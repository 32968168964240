<template>
  <svg :width="width" :height="height" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :class="`fill-${color}`" opacity="0.798386" fill-rule="evenodd" clip-rule="evenodd" d="M6.5 12.988C10.0896 12.988 13 10.0803 13 6.49399C13 2.90768 10.0896 0 6.5 0C2.91037 0 0 2.90768 0 6.49399C0 10.0803 2.91037 12.988 6.5 12.988ZM7.04147 9.741H5.95814V5.41167H7.04147V9.741ZM7.17728 3.78819C7.17728 3.41478 6.87394 3.11173 6.50019 3.11173C6.12644 3.11173 5.82311 3.41478 5.82311 3.78819C5.82311 4.16159 6.12644 4.46465 6.50019 4.46465C6.87394 4.46465 7.17728 4.16159 7.17728 3.78819Z" />
  </svg>
</template>
<script>
export default{
  props:{
    width:{
      type:Number,
      default:13
    },
    height:{
      type:Number,
      default:13
    },
    color:{
      type:String,
      default:'gray'
    }
  }
}
</script>
