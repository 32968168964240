<template>
  <div class="w-full flex items-center">
    <label :for="id" :class="[
                                `w-[18px] h-[18px] xl:w-[14px] xl:h-[14px]`,
                                `mb-0 rounded-[2px] flex items-center justify-center mr-[11px]`,
                                checked ? `bg-primary` :  `border border-gray2`
                             ]">
      <check-icon v-if="checked" :width="6" :height="4" :color="'white'" />
      <div v-if="!checked" class="w-1/2 border border-gray2"></div>
    </label>
    <input :id="id" type="checkbox" v-model="checked" style="display: none;">
    <div class="flex-1 ">
      <span class="text-black leading-[19px] ">
        <slot></slot>
      </span>
    </div>
  </div>
</template>
<script>
import {CheckIcon} from "@/components/v2/icons";

export default {
  components:{
    CheckIcon
  },
  props:{
    initial_value:{
      type:Boolean,
      default:false
    }
  },
  data()
  {
    return {
      id:'checkbox-'+Math.random(),
      checked:false
    }
  },
  mounted() {
    this.checked = this.initial_value
  },
  watch:{
    checked(checked,oldChecked)
    {
      if(checked!=oldChecked)
      {
        this.$emit('change',checked)
      }
    },
  }
}
</script>
