export default function(toDate) {
  var now = this.moment(); //now
  toDate = this.moment(toDate);
      var days = now.diff(toDate, "days");
      var hours = now.diff(toDate, "hours") % 24;
      var minutes = now.diff(toDate, "minutes") % 60;
      var duration = "";
      if (days < 0 || hours < 0 || minutes < 0) {
        duration =
          "Restante " +
          Math.abs(days) +
          " día - " +
          Math.abs(hours) +
          " hs y " +
          Math.abs(minutes) +
          " m";
      } else {
        duration = "Hace  " + days + " día/s - " + hours + " hs y " + minutes + " m";
      }
      return duration;
}
