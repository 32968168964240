<template>
  <div class="w-screen">
  <section class="w-screen flex justify-center">
    <div class="w-[627px] flex flex-col justify-center mt-6 md:mt-24 p-10 md:p-0 mb-48" id="loginForm" >
      <Messages />
      <form
        @submit.prevent="onSubmit"
        method="post"
        class=""  
      >
        <h4 class="text-[25px] mb-[45px]">Ingresa a tu portal cliente</h4>
        <fieldset class="pt-4 mb-3">
          <div class="grid md:grid-columns-2 grid-gap-4">
            <div class="md:pr-5">
              <Facebook ref="fbComponent" />
            </div>
            <div class="md:pl-5">
              <Google ref="googleComponent" />
            </div>
          </div>
        </fieldset>

        <separator class="mt-[30px] mb-[20px]" />
        <div class="grid md:grid-columns-2 grid-gap-4">
          <div class="md:pr-5">
            <form-label :title="'Teléfono'">
              <phone-input :value="phone" @input="onPhoneInput" />
            </form-label>
          </div>
          <div class="md:pl-5 mt-5 md:mt-0">
            <form-label :title="'Contraseña'">
              <password-input v-model="password" />
              <custom-link :href="'#'">
                ¿Olvidaste tu contraseña?
              </custom-link>
            </form-label>
          </div>
        </div>
        <div class="w-full flex justify-center pt-[40px]">
          <div class="w-[100%] md:w-[40%] flex flex-col items-center">
            <rounded-button :onClick="onSubmit" :disabled="processingLogin">
              Siguiente
            </rounded-button>
            <div class="w-max flex items-center max-xl:flex-col justify-between mt-[21px]">
              <span>¿Necesitas ayuda?</span>
              <div>
                <whatsapp-icon class="mx-[6px] inline-block"/>
                <custom-link :href="'#'">0981888777</custom-link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
  <Banner />
  </div>
</template>

<script>
import Banner from "@/components/v2/Banner.vue";
import VueRecaptcha from "vue-recaptcha";
import { mapGetters } from "vuex";
import scrollToSection from "@/utils/methods/scrollToSection";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
import {GoogleIcon,FacebookIcon,WhatsappIcon} from "@/components/v2/icons";
import {SocialButton,RoundedButton} from "@/components/v2/buttons";
import {PhoneInput,PasswordInput,FormLabel} from "@/components/v2/forms";
import CustomLink from "@/components/v2/Link.vue";
import Separator from "@/components/v2/Separator.vue";
import Messages from "@/components/Messages.vue";
export default {
  data() {
    return {
      colorMessage: "#66A7E5",
      submited: false,
      phone: "",
      password: "",
      processingLogin: false,
      loginerrormessage: "",
      isShowCaptcha: false,
      isverify: false,
      recaptchaToken: "",
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      redirectpath: "",
      passwordFieldType: "password",
      isShowPassword: false,
      countryCode: "PY"
    };
  },
  created() {
    if (this.$route.query.redirect) {
      this.redirectpath = this.$route.query.redirect;
    }
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    // this.scrollToSection("login_section");
  },
  methods: {
    scrollToSection,
    onSubmit(e) {
      e.preventDefault();
      this.submited = true;
      this.processingLogin = true;
      if (this.validateForm()) {
        this.$store.dispatch("hideMessageLink");
        this.$store
          .dispatch("fetchLogin", {
            phone: this.phone,
            password: this.password,
            recaptchaToken: this.recaptchaToken,
            countryCode: this.countryCode,
            userType: 'client'
          })
          .then(() => {
            this.processingLogin = true;
            let loginMessage = `Datos correctos! redireccionando...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", loginMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function () {
              self.$store.dispatch("setShowAlert", false);

              let isRedirectProfileId = null;
              let isProfessionId = null;
              if (self.ClientTempData && self.ClientTempData != null) {
                isRedirectProfileId = self.ClientTempData.professionalId
                  ? self.ClientTempData.professionalId
                  : null;
                isProfessionId = self.ClientTempData.profession_id
                  ? self.ClientTempData.profession_id
                  : null;
              }
              if (self.redirectpath) {
                self.$router.push(self.redirectpath);
              } else if (isRedirectProfileId != null && isProfessionId != null) {
                self.$router.push({
                  name: "ProfessionalsProfile",
                  params: { proid: isProfessionId, pid: isRedirectProfileId },
                });
              } else {
                self.$router.push({ name: "Dashboard" });
              }
            }, 2000);
          })
          .catch(() => {
            this.processingLogin = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            if(this.error.response.data.status && this.error.response.data.status == 'no-verified'){
              //if is not verified, take the user to validation step
              var self = this;
              setTimeout(function() {
                self.$store.dispatch("saveRegisterClientTempData", {
                phoneNumber: self.phone,
                countryCode: self.countryCode,
                }).then(() => {
                  self.$router.push({ name: 'ValidateUser' })
                })
              }, 2000);
            }
          });
      } else {
        this.processingLogin = false;
      }
    },
    onPhoneInput(phoneValue) {
      this.phone = phoneValue.phoneNumber
      this.countryCode = phoneValue.countryCode
    },
    validateForm() {
      return (
        this.phone != "" &&
        this.password != "" 
        // (this.loginattempt < 2 || this.isverify) esto se usaba con el recaptcha
      );
    },
    onVerify: function (recaptchaToken) {
      this.isverify = true;
      this.recaptchaToken = recaptchaToken;
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
    },
    focusEmail: function () {
      this.scrollToSection("loginForm");
      this.$refs.loginEmail.focus();
    },
    showPassword() {
      this.isShowPassword = true;
      this.togglePassword();
    },
    hidePassword() {
      this.isShowPassword = false;
      this.togglePassword();
    },
    togglePassword() {
      if (this.isShowPassword) {
        this.passwordFieldType = "text";
      } else {
        this.passwordFieldType = "password";
      }
    },
  },
  computed: {
    ...mapGetters(["error", "loginattempt", "ClientTempData"]),
  },
  components: {
    Separator,
    CustomLink,
    FormLabel,
    PhoneInput,
    VueRecaptcha,
    Facebook,
    Google,
    GoogleIcon,
    FacebookIcon,
    WhatsappIcon,
    SocialButton,
    PasswordInput,
    RoundedButton,
    Messages,
    Banner
  },
};
</script>

