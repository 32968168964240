<template>
  <input type="text" :placeholder="placeholder" v-model="value" class="w-full bg-white h-[37px] pl-[15px] pr-[21px] rounded-[4px] border border-grayLight"/>
</template>
<script>
export default{
  props:{
    placeholder:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      value:null
    }
  },
  watch:{
    value(value,oldValue)
    {
      if(value!=oldValue)
      {
        this.$emit('change',value)
      }
    }
  }
}
</script>
