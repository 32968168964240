<template>
  <div>
    <Header />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <SideNavigator :links="links" />
          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
              <h4 class="mb-0 lg:mt-0">
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-user-small"
                    xlink:href="../assets/img/icons.svg#icon-user-small"
                  />
                </svg>
                Mis datos
              </h4>
            </div>

            <div class="p-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded">
              <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                <svg class="icon">
                  <use
                    href="../assets/img/icons.svg#icon-polaroid-user"
                    xlink:href="../assets/img/icons.svg#icon-polaroid-user"
                  />
                </svg>
                Perfil
              </h6>
              <form @submit.prevent="updateClient" method="post">
                <div class="flex">
                  <figure
                    class="flex items-center justify-center mr-4 shadow lg:mr-10 avatar avatar--xl"
                  >
                    <img
                      :src="clientdata.pictureProfileUrl"
                      :alt="clientdata.firstName + ' ' + clientdata.lastName"
                    />
                  </figure>

                  <div class="flex-1">
                    <label>
                      <div>
                        <input
                          class="hidden w-full mt-1 mb-2 form-input"
                          type="file"
                          name="profileImage"
                          @change="updateUserAvatar"
                          ref="file"
                        />
                      </div>
                      <div class="block w-full mt-1 mb-2 form-input">
                        <button type="button" @click="$refs.file.click()" class="btn btn--primary">
                          Cambiar foto de perfil
                        </button>
                      </div>
                    </label>
                  </div>
                </div>

                <hr class="my-8 -mx-5 border-gray-200" />

                <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                  <svg class="icon">
                    <use
                      href="../assets/img/icons.svg#icon-file-content-small"
                      xlink:href="../assets/img/icons.svg#icon-file-content-small"
                    />
                  </svg>
                  Datos Personales
                </h6>

                <div class="mb-2 grid lg:grid-columns-2 grid-gap-4">
                  <div>
                    <label class="mb-2">
                      <span>Nombre:</span>
                      <input
                        class="block w-full mt-1 form-input"
                        type="text"
                        v-model="updateClientData.firstName"
                        v-bind:class="{
                          'bg-red-100 border-red-700': submited && updateClientData.firstName == ''
                        }"
                        placeholder="Ingrese su nombre"
                      />
                      <span class="error" v-if="submited && updateClientData.firstName == ''"
                        >Por favor, introduzca su nombre</span
                      >
                    </label>
                  </div>
                  <div>
                    <label class="mb-2">
                      <span>Apellido:</span>
                      <input
                        class="block w-full mt-1 form-input"
                        v-model="updateClientData.lastName"
                        v-bind:class="{
                          'bg-red-100 border-red-700': submited && updateClientData.lastName == ''
                        }"
                        type="text"
                        placeholder="Ingrese su apellido"
                      />
                      <span class="error" v-if="submited && updateClientData.lastName == ''"
                        >Por favor ingrese su apellido</span
                      >
                    </label>
                  </div>

                  <div>
                    <label class="mb-2">
                      <span>Documento:</span>
                      <input
                        class="block w-full mt-1 form-input"
                        type="text"
                        v-model="updateClientData.identificationNumber"
                        placeholder="Ingrese su número de documento"
                      />
                    </label>
                  </div>

                  <div>
                    <label class="mb-2">
                      <span>Celular:</span>
                      <VuePhoneNumberInput
                        v-model="telnumber"
                        color="#63b3ed"
                        class="mt-1"
                        v-bind:class="{
                          'bg-red-100 border-red-700 OnErrorScrollHere': submited && !isPhoneValid
                        }"
                        valid-color="#63b3ed"
                        error-color="#63b3ed"
                        :default-country-code="updateClientData.countryCode"
                        :translations="translations"
                        :no-example="true"
                        :show-code-on-list="true"
                        @update="phoneUpdate"
                      />
                      <span class="error" v-if="submited && !isPhoneValid">
                        Por favor ingrese su número
                      </span>
                    </label>
                  </div>
                </div>

                <hr class="my-8 -mx-5 border-gray-200" />
                <h6 class="mb-2 uppercase text-brand-secondary icon-text-aligner">
                  <svg class="icon">
                    <use
                      href="../assets/img/icons.svg#icon-pin"
                      xlink:href="../assets/img/icons.svg#icon-pin"
                    />
                  </svg>
                  Domicilio
                </h6>
                <div class="mb-2 grid lg:grid-columns-2 grid-gap-4">
                  <div>
                    <label class="mb-2">
                      <span>Calle 1:</span>
                      <input
                        class="block w-full mt-1 form-input"
                        v-model="updateClientData.address"
                        type="text"
                        placeholder="Ingrese una dirección"
                      />
                    </label>
                  </div>
                  <div>
                    <label class="block mb-2">
                      <span>Departamento:</span>
                      <select
                        class="block w-full mt-1 rounded form-select"
                        v-model="department"
                        @change="GetDepartmentLocations()"
                      >
                        <option disabled v-if="activeDepartments.length <= 0" :value="null"
                          >Departamento no encontrado</option
                        >
                        <option disabled v-else :value="null">Seleccionar Departmento</option>
                        <option
                          v-for="department in activeDepartments"
                          :key="department.id"
                          :value="department.id"
                          >{{ department.description }}</option
                        >
                      </select>
                    </label>
                  </div>
                  <div>
                    <label class="block mb-2">
                      <span>Ciudad:</span>
                      <select
                        class="block w-full mt-1 rounded form-select"
                        v-model="updateClientData.location_id"
                      >
                        <option disabled v-if="activeDepartmentLocations.length <= 0" :value="null"
                          >Ciudad no encontrada</option
                        >
                        <option disabled v-else :value="null">Seleccione Ciudad</option>
                        <option
                          v-for="departmentLocation in activeDepartmentLocations"
                          :key="departmentLocation.id"
                          :value="departmentLocation.id"
                          >{{ departmentLocation.description }}</option
                        >
                      </select>
                    </label>
                  </div>
                </div>

                <div class="flex justify-end">
                  <button class="w-full btn btn--success lg:w-auto">Guardar datos</button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </section>
    <Footer />
    <!-- modal start-->
    <div class="validate-phone validate-phone-modal">
      <modal ref="validatePhone">
        <template v-slot:header>
          <h5 class="w-full text-center">Validación de Teléfono</h5>
        </template>

        <template v-slot:body>
          <div class="flex flex-row justify-center">
            <div class="w-full">
              <p class="text-black mb-8 text-lg">
                Por favor inserte el código de validación que le llegará al whatsapp asociado a su teléfono. Actualizaremos su número cuando realice la validación. 
              </p>
              <form @submit.prevent="validateUserPhone" method="post">
                <div>
                  <label>
                    <input
                      class="block w-full mt-1 form-input"
                      type="text"
                      v-model="validationCode"
                    />
                    <span class="error" v-if="validatePhoneSubmit && !validationCode">
                      Por favor inserte el código de validación
                    </span>
                  </label>
                </div>
                <button class="rounded w-full btn btn--md shadow-md py-3 px-4 btn--primary bg-green-btn" type="submit">
                  <span class="justify-between text-base ">Validar código</span>
                </button>
              </form>
            </div>
            <hr />
          </div>
        </template>
        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfileSidebar from "@/components/ProfileSidebar.vue";
import { mapGetters, mapMutations } from "vuex";
import Footer from "@/components/Footer.vue";
import Swal from "sweetalert2";
import GetDepartments from "@/utils/methods/GetDepartments";
import GetDepartmentLocations from "@/utils/methods/GetDepartmentLocations";
import activeDepartments from "@/utils/computed/activeDepartments";
import activeDepartmentLocations from "@/utils/computed/activeDepartmentLocations";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import SideNavigator from "@/components/SideNavigator.vue";
import Modal from "@/components/Modal";
const handToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});
export default {
  data() {
    return {
      submited: false,
      processingGetClientData: false,
      processingUpdateUser: false,
      processingupdateAvatar: false,
      clientId: null,
      clientdata: {},
      updateClientData: {
        firstName: "",
        lastName: "",
        identificationNumber: "",
        phoneNumber: "",
        countryCode: null,
        countryCallingCode: null,
        address: "",
        location_id: null
      },
      updateAvatarData: {},
      department: null,
      departments: [],
      departmentLocations: [],
      location: null,
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :"
      },
      telnumber: "",
      isPhoneValid: false,
      links:[
        {
          route:{ name: 'ServiceRequest' },
          title: "Servicios solicitados"
        },
        {
          route:{ name: 'Message'},
          title: "Mensajes"
        },
        {
          route:{ name: 'ClientProfile' },
          title: "Mis datos"
        },
        {
          route:{ name: "Files" },
          title:"Mis archivos"
        }
      ],
      validationCode: "",
      validatePhoneSubmit: false
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetClientData();
    this.GetDepartments();
  },
  computed: {
    ...mapGetters(["error", "user", "phoneValidation"]),
    activeDepartments,
    activeDepartmentLocations
  },
  methods: {
    GetDepartments,
    GetDepartmentLocations,
    updateClient(e) {
      e.preventDefault();
      this.submited = true;
      this.processingUpdateUser = true;
      this.updateClientData.userId = this.user.id;
      if (this.validateForm()) {
        this.$store
          .dispatch("updateClient", this.updateClientData)
          .then(() => {
            this.processingUpdateUser = false;
            let registerMessage = `Datos actualizados correctamente`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", registerMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch(() => {
            this.processingUpdateUser = false;
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingRegister = false;
      }
    },
    updateUserAvatar() {
      var images = require.context("../assets/img/", false, /\.gif$/);
      Swal.fire({
        imageUrl: images("./loading.gif"),
        imageAlt: "Loader",
        showConfirmButton: false,
        background: "transparent",
        backdrop: `rgba(0,0,0,0.6)`,
        allowOutsideClick: false
      });
      this.processingupdateAvatar = true;
      this.updateAvatarData.userId = this.user.id;
      var formdata = new FormData();
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formdata.append("file", file);
      }
      this.updateAvatarData.formdata = formdata;
      this.$store
        .dispatch("updateUserAvatar", this.updateAvatarData)
        .then(response => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Imagen de perfil actualizada correctamente.",
            showConfirmButton: true
          });
          this.$refs.file.value = "";
          this.clientdata.pictureProfileUrl = response[1][0].pictureProfileUrl;
          this.processingupdateAvatar = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          Swal.close();
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          this.submited = false;
          this.processingupdateAvatar = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    validateForm() {
      return (
        this.updateClientData.firstName != "" &&
        this.updateClientData.lastName != "" &&
        this.isPhoneValid == true
      );
    },

    GetLocationDepartment() {
      this.$store
        .dispatch("GetLocationDepartment", {
          locationId: this.clientdata.location_id
        })
        .then(response => {
          this.$store.dispatch("setShowAlert", false);
          this.department = response.locations.department_id;
          this.GetDepartmentLocations();
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    GetClientData() {
      this.processingClientData = true;
      this.$store
        .dispatch("GetClientData", {
          clientId: this.user.id
        })
        .then(response => {
          this.clientdata = response.user;
          this.updateClientData.firstName = this.clientdata.firstName
            ? this.clientdata.firstName
            : "";
          this.updateClientData.lastName = this.clientdata.lastName ? this.clientdata.lastName : "";
          this.updateClientData.identificationNumber = this.clientdata.identificationNumber
            ? this.clientdata.identificationNumber
            : "";
          this.updateClientData.phoneNumber = this.clientdata.phoneNumber
            ? this.clientdata.phoneNumber
            : "";
          this.telnumber = this.updateClientData.phoneNumber;
          this.updateClientData.countryCode = this.clientdata.countryCode
            ? this.clientdata.countryCode
            : "PY";
          this.updateClientData.countryCallingCode = this.clientdata.countryCallingCode
            ? this.clientdata.countryCallingCode
            : "595";
          this.updateClientData.address = this.clientdata.address ? this.clientdata.address : "";
          if (this.clientdata.location_id && this.clientdata.location_id != null) {
            this.updateClientData.location_id = this.clientdata.location_id;
            this.GetLocationDepartment();
          } else {
            this.updateClientData.location_id = null;
          }

          this.processingClientData = false;
        })
        .catch(() => {
          this.processingClientData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    phoneUpdate(val) {
      this.updateClientData.phoneNumber = val.nationalNumber;
      this.updateClientData.countryCode = val.countryCode;
      this.updateClientData.countryCallingCode = val.countryCallingCode;
      if (val.countryCode == "PY") {
        if (val.nationalNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    },
    validateUserPhone(e) {
      e.preventDefault();
      this.validatePhoneSubmit = true;
      
      if (this.validationCode) {
        this.$store.dispatch("validatePhone", {
          code: this.validationCode,
          phoneNumber: this.updateClientData.phoneNumber,
          countryCode: this.updateClientData.countryCode
        }).then(() => {
          this.validatePhoneSubmit = false;
          this.$refs.validatePhone.closeModal();

          this.validationCode = "";
          this.updateLocalData({...this.updateClientData, phoneNumber: `+${this.updateClientData.countryCallingCode}${this.updateClientData.phoneNumber}`})

          handToast.fire({
            icon: "success",
            title: 'Gracias por validar tu teléfono'
          });
        }).catch(() => {
          this.validatePhoneSubmit = false;

          let errorMessage = "No se ha podido validar su teléfono, favor intente de nuevo";

          handToast.fire({
            icon: "error",
            title: errorMessage
          });
        });
      } else {
        //this.validatePhoneSubmit = false;
      }
    },
    ...mapMutations(["updateLocalData"])
  },
  watch: {
    phoneValidation(newValue, oldValue) {
      if (newValue) {
        this.$refs.validatePhone.openModal();
      }
    }
  },
  components: {
    SideNavigator,
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfileSidebar,
    VuePhoneNumberInput,
    Modal
  }
};
</script>
<style lang="css" scoped>
@import "../assets/css/dashboard.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
<style>
  .validate-phone .modal__dialog {
    width: 25%;
  }
  @media screen and (max-width: 992px) {
    .validate-phone .modal__dialog {
      width: 90%;
    }
  }
</style>
