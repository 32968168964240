import {
  AdminServiceRequests,
  AdminBudgetRequest,
  AdminReviews,
  AdminProfessionalsRequests,
  AdminTransferRequest,
  AdminMessageResponder,
  ActivateEmail,
  AdminClients,
  Layout,
  AdminEditProfessionalProfile,
  AdminEditProfessionalPhoto
} from "@/views/admin";
import { ifAuthenticated } from "../middlewares";

const routes = [
  {
    path: "/admin",
    component: Layout,
    children: [
      {
        name: "AdminDashboard",
        path: "/",
        redirect: "service-requests",
      },
      {
        path: "service-requests",
        name: "AdminServiceRequests",
        component: AdminServiceRequests,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Mi panel", islast: true },
          ],
        },
      },
      {
        path: "activate-email",
        name: "ActivateEmail",
        component: ActivateEmail,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Mi panel", islast: true },
          ],
        },
      },
      {
        path: "service-request/:status/:id",
        name: "AdminServiceRequestSection",
        component: AdminServiceRequests,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Mi panel", islast: true },
          ],
        },
      },
      {
        path: "transfer-request",
        name: "AdminTransferRequest",
        component: AdminTransferRequest,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Mi panel", islast: true },
          ],
        },
      },
      {
        path: "budget-request",
        name: "AdminBudgetRequest",
        component: AdminBudgetRequest,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Mi panel", islast: true },
          ],
        },
      },
      {
        path: "reviews",
        name: "AdminReviews",
        component: AdminReviews,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Review", islast: true },
          ],
        },
      },
      {
        path: "professionals-requests",
        name: "AdminProfessionalsRequests",
        component: AdminProfessionalsRequests,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Solicitudes de profesionales", islast: true },
          ],
        },
      },
      {
        path: "edit-professional-complete-profile/:id",
        name: "AdminEditProfessionalProfile",
        component: AdminEditProfessionalProfile,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [{ name: "Mi panel", islast: true }],
        },
      },
      {
        path: "edit-professional-photo/:id",
        name: "AdminEditProfessionalPhoto",
        component: AdminEditProfessionalPhoto,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [{ name: "Mi panel", islast: true }],
        },
      },
      {
        path: "message-responder/:otype/:oid/:ostatus",
        name: "AdminMessageResponder",
        component: AdminMessageResponder,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Mi panel", islast: true },
          ],
        },
      },
      {
        path: "clients",
        name: "AdminClients",
        component: AdminClients,
        beforeEnter: ifAuthenticated,
        meta: {
          permission: "admin",
          breadcrumb: [
            { name: "Inicio", link: "/" },
            { name: "Mi panel", islast: true },
          ],
        },
      },
    ],
  },
];

export default routes;
