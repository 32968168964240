<template>
  <div class="w-full flex flex-col">
    <label :class="[`title`,
                    size=='small'? `text-sm` : `text-base`
                    ]">
      {{title}}
      <slot name="tooltip"></slot>
    </label>
    <slot></slot>
  </div>
</template>
<script>
import Tooltip from "@/components/v2/Tooltip.vue";
export default {
  props:{
    title:{
      type:String,
      default:""
    },
    size:{
      type:String,
      default:'medium'
    }
  },
  components: {
    Tooltip
  },
  
}
</script>
<style scoped>
.title{
  color:#373A3C;
  line-height: 24px;
  margin-bottom: 6px;
}
</style>
