<template>
  <div>
    <Header @refreshMessage="refreshMessageEvent($event)" />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <ProfileSidebar />
          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between mb-2 lg:mb-4 lg:flex-row lg:items-center">
              <h4 class="mb-0 lg:mt-0">
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-email"
                    xlink:href="../assets/img/icons.svg#icon-email"
                  />
                </svg>
                Mensajes
              </h4>
            </div>

            <div class="mb-2 overflow-x-auto bg-white rounded shadow-md">
              <table class="w-full text-left border-collapse" v-if="messages.length > 0">
                <tbody>
                  <tr
                    class="flex flex-col lg:table-row"
                    v-for="(message, index) in messages"
                    :key="index"
                    v-bind:class="{
                      unread_message_row: !message.read_by_client
                    }"
                  >
                    <td class="px-6 py-4 border-b border-gray-200 ">
                      <small class="text-xs text-brand-secondary" v-if="!message.read_by_client"
                        >Tienes un mensaje nuevo
                      </small>
                      <h5 class="my-0 text-gray-900 whitespace-no-wrap">
                        Asunto
                      </h5>
                      <span
                        v-bind:class="{
                          unread_message: !message.read_by_client
                        }"
                      >
                        {{ message.description }}</span
                      >
                    </td>

                    <td class="px-6 py-4 border-b-4 border-gray-200 md:border-b lg-text-right">
                      <a href="#" class="px-3 py-1 text-xs text-red-600">Eliminar</a>
                      <router-link
                        :to="{
                          name: 'MessageResponderWithoutStatus',
                          params: { otype: message.type, oid: message.orderId }
                        }"
                        class="btn btn--xs btn--outline--primary"
                        >Responder</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProfileSidebar from "@/components/ProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import getMessages from "@/utils/methods/getMessages";

export default {
  data() {
    return {
      processingGetMessage: false,
      messages: {}
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.getMessages();
  },

  methods: {
    getMessages,
    refreshMessageEvent() {
      this.getMessages();
    }
  },
  computed: {
    ...mapGetters(["error", "user"])
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfileSidebar
  }
};
</script>
<style lang="css" scoped>
@import "../assets/css/dashboard.css";
.success {
  color: #1bb01b;
}
.unread_message {
  font-weight: 900;
  color: black;
}
.unread_message_row {
  background: #eee;
}
@media (min-width: 1024px) {
  .lg-text-right {
    text-align: right;
  }
}
</style>
