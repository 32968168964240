<template>
  <div class="w-full pt-[90px]">
    <div class="w-full px-[50px] xl:pl-[53px]">
      <span class="leading-[19px] xl:text-[25px] xl:leading-[29px] font-bold text-black">2. Área de cobertura</span>
      <div class="max-xl:w-full xl:w-1/2 mt-[8px]">
        <p class="max-xl:text-[14px] max-xl:leading-[16px] leading-[19px] text-dark3">
          Selecciona tu área de cobertura. Si el sistema automático no te ubica correctamente en el mapa, por favor reubica el pin azúl manualmente al lugar correcto.
        </p>
      </div>
      <div class="flex flex-col md:flex-row gap-[10px] my-[40px] items-end">
        <div class="w-full order-2 md:order-1">
          <div class="inline-block w-[180px]">
            <span class="font-medium text-black leading-[24px] mb-[6px] block">Dirección</span>
            <text-input v-model="professionalAddress" :placeholder="'Nombre de la calle'" @change="onChangeAddress" />
          </div>
          <div class="inline-block w-[124px] ml-[5px] md:ml-[10px]">
            <text-input v-model="professionalAddressNumber" :placeholder="'Número'" @change="onChangeAddressNumber" />
          </div>
        </div>
        <div class="w-full grow order-1 md:order-2 mb-[40px] md:my-0">
          <div class="w-[100%] md:w-[245px] float-none md:float-right mx-auto">
            <rounded-button :color="'secondary'" :onClick="ubicarEnMapa">
              Ubicar en el mapa
            </rounded-button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full h-[300px] px-[50px] xl:pl-[53px] flex flex-col ">
      <div class="w-full h-full border border-gray4 rounded-[5px] overflow-hidden">
        <l-map style="z-index: 19;" :zoom="10" :center="marker_position" ref="mymap">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-circle :lat-lng="marker_position" :radius="distance*1000" />
          <l-marker
            :lat-lng="marker_position"
            :draggable="true"
            @update:lat-lng="updateMarkerPosition"
            ref="marker"
          >
            <l-popup>Mové este punto a tu ubicación</l-popup>
          </l-marker>
        </l-map>
      </div>
    </div>
    <div class="w-full flex max-xl:flex-col xl:flex-row px-[50px] xl:pl-[53px] mt-[66px]">
      <div class="max-xl:w-full xl:w-max flex flex-col mb-[45px] xl:mb-0 xl:mr-[93px]">
        <span class="font-medium text-black leading-[24px] mb-[6px]">Distancia máxima para tomar trabajos</span>
        <div class="w-[250px]">
          <selector
            @selected="onChangeDistance"
            :placeholder="'km'"
            :initial_value="distance"
            :options="distances"
          />
        </div>
      </div>
    </div>
    <confirmation 
      :show="showCompleteProfileAddressModal"
      :showBack="true"
      :title="'Confirmar dirección'"
      :subtitle="'Por favor confirma tu dirección y area de cobertura para tomar los pedidos de servicios'"
      :buttonText="'Confirmar Dirección'"
      @afterConfirmation="onAddressConfirm"
      @afterClose="onAddressClose">
        <template #modal-content>
          <div class="w-full border border-green bg-green2 rounded-[6px] py-[16px] px-[20px] mt-[32px] flex">
            <div class="flex-grow flex flex-col ml-[16px]">
              <div class="w-full flex items-center">
                <span class="text-[18px] font-semibold text-black">{{ address }}</span>
              </div>
              <div class="w-full mt-[15px]">
                <p class="text-black text-[14px]">{{ distanceKm }} km a la redonda desde la dirección ingresada.</p>
              </div>
            </div>
          </div>
        </template>
    </confirmation>
  </div>

</template>
<script>
import {Selector} from "@/components/v2/forms";
import {RoundedButton} from "@/components/v2/buttons";
import {PlusCircleIcon,SubstractCircleIcon} from "@/components/v2/icons";
import { LMap, LTileLayer, LCircle, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import TextInput from "@/components/v2/forms/TextInput.vue";
import geoLocation from "@/utils/helpers/geoLocation";
import {mapMutations, mapState} from "vuex";
import Confirmation from "@/components/v2/forms/Confirmation.vue";
export default{
  components:{
    TextInput,
    RoundedButton,
    PlusCircleIcon,
    SubstractCircleIcon,
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    LPopup,
    Icon,
    Selector,
    Confirmation
  },
  data()
  {
    return {
      url:"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      marker_position: [-25.3211306, -57.5928935],
      distances:Array.from({length: 30}, (_, i) => i + 1),
      distance:1,
      professionalAddress:"",
      professionalAddressNumber:""
    }
  },
  beforeMount() {
    this.distance = this.distanceKm

    if (this.address) {
      const addressSplit = this.address.split("-")
      
      this.professionalAddress = addressSplit[0].trim()
      this.professionalAddressNumber = addressSplit[1].trim()
    }

    if (this.location) {
      this.marker_position = [this.location[0], this.location[1]] 
    }
  },
  mounted() {
    if(this.location)
    {}else{
      this.ubicarEnMapa()
    }
    
  },
  methods:{
    ubicarEnMapa()
    {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          geoLocation(this.initMarkerPosition) 
        } else if (result.state === "prompt") {
          geoLocation(this.initMarkerPosition) 
        } else if (result.state === "denied") {
          this.mensajePermiso = true;
        }
      });
      this.setLocationPermission(true)
    },
    initMarkerPosition({coords})
    {
      this.updateMarkerPosition({lat:coords.latitude,lng:coords.longitude})
    },
    updateMarkerPosition({lat,lng})
    {
      if(lat && lng) {
        this.marker_position = [lat,lng]
        this.setLocation(this.marker_position)
      }
    },
    onChangeDistance(distance)
    {
      this.distance = distance
      this.setDistanceKm(distance)
    },
    onChangeAddress(address)
    {
      this.professionalAddress = address
      this.setAddress(this.professionalAddress + ' - ' + this.professionalAddressNumber)
    },
    onChangeAddressNumber(addressNumber)
    {
      this.professionalAddressNumber = addressNumber
      this.setAddress(this.professionalAddress + ' - ' + this.professionalAddressNumber)
    },
    onAddressConfirm()
    {
      this.setShowCompleteProfileAddressModal(false)

      const { step, token} = this.$route.params
      this.$router.push({name:'ProfessionalCompleteProfile', params: { token, step: parseInt(step)+1 }})
    },
    onAddressClose()
    {
      this.setShowCompleteProfileAddressModal(false)
    },
    ...mapMutations('completeProfessionalProfile',['setDistanceKm','setLocation','setAddress', 'setLocationPermission', 'setShowCompleteProfileAddressModal'])
  },
  computed: {
    ...mapState('completeProfessionalProfile',['distanceKm', 'address', 'location', 'locationPermission', 'showCompleteProfileAddressModal']),
  }
}
</script>
<style>
.leaflet-pane {
  z-index: 1;
}
</style>
