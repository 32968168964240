<template>
  <v-select
    :disabled="selectDisabled"
    :placeholder="placeholder"
    :value="selected"
    :options="options" 
    :clearable="false" 
    :searchable="false" 
    @input="onSelectChange"
  >
    <template #open-indicator>
      <caret-icon :width="8" :height="6"/>
    </template>
  </v-select>
</template>
<script>
import { CaretIcon } from "@/components/v2/icons";
export default {
  components: {
    CaretIcon
  },
  props:{
    options:{
      type:Array,
      default:()=>[]
    },
    placeholder:{
      type:String,
      default:""
    },
    initial_value: {
      default:""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data()
  {
    return {
      selected:"",
      selectDisabled: false
    }
  },
  mounted() {
    this.selected = this.initial_value
    this.selectDisabled = this.disabled
  },
  methods: {
    onSelectChange(selected) {
      this.selected = selected
    }
  },
  watch:{
    selected(selected, oldSelected)
    {
      if(selected != oldSelected)
      {
        this.$emit('selected', selected)
      }
    }
  }
}
</script>
<style>
select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image:url('data:image/svg+xml,<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L0.535899 -6.52533e-07L7.4641 -4.68497e-08L4 6Z" fill="black"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}
.v-select .vs__dropdown-menu {
  margin-top: 5px;
  border-top-style: solid;
  border-radius: 4px;
  box-shadow: 0px 6px 10px rgba(0,0,0,0.15);
}
.v-select .vs__dropdown-menu .vs__dropdown-option--highlight, .v-select .vs__dropdown-menu .vs__dropdown-option:hover {
  background-color: #aaa;
  color: #fff;
}
.v-select.vs--open .vs__dropdown-toggle {
  border-bottom-color: rgba(60,60,60,0.26);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.v-select .vs__selected {
  position: absolute;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.v-select .vs__selected-options {
  padding: 2px 2px;
}
</style>
