<template>
  <div>
    <Header />
    <Messages />
    <Searcher :professions="activeProfessions" ref="searchForm" />
    <Professions :professions="activeProfessions" @selectProfession="selectProfession($event)" />
    <WhoWeAre />
    <Footer />
    <!-- modal start-->
    <div class="update-phone dashboard-modal">
      <modal ref="updateUserPhone">
        <template v-slot:header>
          <h1></h1>
        </template>

        <template v-slot:body>
          <div class="flex flex-row justify-center">
            <div class="w-full">
              <p class="text-black mb-8 text-lg">
                Hemos detectado que aún no ha cargado su número de teléfono, para poder darle
                seguimiento y un buen soporte vamos a necesitarlo. Por favor ingrese su número de
                contacto.
              </p>
              <form @submit.prevent="updateUserPhone" method="post">
                <div>
                  <label>
                    <span>Celular</span>
                    <VuePhoneNumberInput
                      v-model="telnumber"
                      color="#63b3ed"
                      class="mt-1"
                      v-bind:class="{
                        'bg-red-100 border-red-700 OnErrorScrollHere': phoneSubmit && !isPhoneValid
                      }"
                      valid-color="#63b3ed"
                      error-color="#63b3ed"
                      default-country-code="PY"
                      :translations="translations"
                      :no-example="true"
                      :show-code-on-list="true"
                      @update="phoneUpdate"
                    />
                    <span class="error" v-if="phoneSubmit && !isPhoneValid">
                      Por favor ingrese su número de celular
                    </span>
                  </label>
                </div>
                <button
                  class="rounded w-full btn btn--md shadow-md py-3 px-4 btn--primary bg-green-btn"
                  type="submit"
                >
                  <span class="justify-between text-base ">Enviar</span>
                </button>
              </form>
            </div>

            <hr />
          </div>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Searcher from "@/components/Searcher.vue";
import Professions from "@/components/Welcome/Professions.vue";
import WhoWeAre from "@/components/Welcome/WhoWeAre.vue";
import Footer from "@/components/Footer.vue";
import GetProfessions from "@/utils/methods/GetProfessions";
import activeProfessions from "@/utils/computed/activeProfessions";
import Messages from "@/components/Messages.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
const handToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});
export default {
  data() {
    return {
      professions: [],
      profession: "",
      processingUpdateUser: false,
      phoneSubmit: false,
      updateClientData: {
        userId: null,
        phoneNumber: null,
        countryCode: null,
        countryCallingCode: null
      },
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Elija un prefijo",
        phoneNumberLabel: "Ingresa tu número",
        example: "Ejemplo :"
      },
      telnumber: "",
      isPhoneValid: false
    };
  },
  metaInfo() {
    return {
      title: "Hand - ¡te da una mano!",
      meta: [
        {
          name: "description",
          content:
            "Hand es una plataforma para conectar a las personas que buscan servicios para el hogar, con profesionales de oficios independientes, de manera más fácil, confiable y segura."
        },
        { property: "og:title", content: "Hand - ¡te da una mano!" },
        { property: "og:site_name", content: "Hand" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" }
      ]
    };
  },
  mounted() {
    this.GetProfessions(true);

    if (this.isAuthenticated && (!this.user.phoneNumber || this.user.phoneNumber == null)) {
      this.$refs.updateUserPhone.openModal();
    }
  },
  beforeDestroy() {
    if (this.isAuthenticated) {
      this.$store.dispatch("setIsWarningAlert", false);
      this.$store.dispatch("setShowAlert", false);
      this.$store.dispatch("setIsShowCloseButton", false);
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    activeProfessions
  },
  methods: {
    GetProfessions,
    selectProfession(professionItem) {
      this.profession = professionItem.description;
      this.$refs.searchForm.changeProfession(professionItem);
    },
    updateUserPhone(e) {
      e.preventDefault();
      this.phoneSubmit = true;
      this.processingUpdateUser = true;
      this.updateClientData.userId = this.user.id;
      if (this.isPhoneValid) {
        this.$store
          .dispatch("updateClient", this.updateClientData)
          .then(() => {
            this.phoneSubmit = false;
            this.processingUpdateUser = false;
            this.$refs.updateUserPhone.closeModal();
          })
          .catch(() => {
            this.processingUpdateUser = false;
            let errorMessage =
              "No se ha podido actualizar su número de contacto, favor intente de nuevo";
            handToast.fire({
              icon: "error",
              title: errorMessage
            });
          });
      } else {
        this.processingRegister = false;
      }
    },
    phoneUpdate(val) {
      this.updateClientData.phoneNumber = val.nationalNumber;
      this.updateClientData.countryCode = val.countryCode;
      this.updateClientData.countryCallingCode = val.countryCallingCode;
      if (val.countryCode == "PY") {
        if (val.nationalNumber && val.nationalNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    }
  },
  components: {
    Header,
    Searcher,
    Professions,
    WhoWeAre,
    Footer,
    Messages,
    Modal,
    VuePhoneNumberInput
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
<style lang="css">
.dashboard-modal .modal__header {
  display: none;
}
</style>
