import {Professions} from "@/services";
import * as ls from "@/config/local-storage-help";
const $ls = ls;

const DEFAULT_RUBROS = [{year:1,services:[]}]

const state = ()=> {
  const location = $ls.getLocation()

  return {
    rubros: $ls.getRubros(),
    professions: $ls.getProfessions(),
    location: location ? [location[0], location[1]] : null,
    distanceKm: $ls.getDistanceKm(),
    address: $ls.getAddress(),
    profilePicture:null,
    files: [],
    selectedCharacteristics: [],
    locationPermission: $ls.getLocationPermission() || false,
    completeProfileModalDisplayed: true,
    showCompleteProfileAddressModal: false,
  }
}

const getters = {

}

const actions = {
  initState({commit})
  {
    Professions.list()
      .then(({data})=>{
        const professions = data.professions.map(profession => {
          profession.services.map(service => {
            service.guaranteeTime = 2
          })

          return profession
        })
        commit("setProfessions", professions)
      })
      .catch(error=>{

      })
  },
}

const mutations = {
  addRubro(state,rubro)
  {
    const newRubro = {
      ...rubro,
      services: [],
      year:1
    }

    state.rubros.push(newRubro)
    $ls.addRubro(newRubro)
  },
  updateRubro(state,{rubro,rubroIndex})
  {
    state.rubros[rubroIndex] = rubro
    $ls.updateRubro(rubro, rubroIndex)
  },
  deleteRubro(state,{rubroIndex})
  {
    state.rubros = state.rubros.filter((_,i)=>i!==rubroIndex)
    $ls.deleteRubro(rubroIndex)
  },
  setProfessions(state,professions)
  {
    state.professions = professions
    $ls.setProfessions(professions)
  },
  setLocation(state,location)
  {
    state.location = location
    $ls.setLocation(location)
  },
  setAddress(state,address)
  {
    state.address = address
    $ls.setAddress(address)
  },
  setDistanceKm(state,distance_km)
  {
    state.distanceKm = distance_km
    $ls.setDistanceKm(distance_km)
  },
  setProfilePicture(state,profilePicture)
  {
    state.profilePicture = profilePicture
  },
  setFiles(state, files)
  {
    state.files = files
  },
  setLocationPermission(state, granted)
  {
    state.locationPermission = granted
    $ls.setLocationPermission(granted)
  },
  setCompleteProfileModalDisplayed(state)
  {
    state.completeProfileModalDisplayed = false
  },
  setShowCompleteProfileAddressModal(state, show)
  {
    state.showCompleteProfileAddressModal = show
  },
  addCharacteristic(state, characteristicId)
  {
    state.selectedCharacteristics.push(characteristicId)
  },
  deleteCharacteristic(state, characteristicId)
  {
    state.selectedCharacteristics = state.selectedCharacteristics.filter(id => id !== characteristicId)
  },
  removeCompleteProfileData(state) {
    $ls.removeRubros()
    $ls.removeProfessions()
    $ls.removeDistanceKm()
    $ls.removeAddress()
    $ls.removeLocation()
    $ls.removeLocationPermission()
  }
}


export default {
  namespaced:true,
  state,
  getters,
  actions,
  mutations
};
