<template>
  <div>
    <footer class="footer" :class="[isShowFooter() ? '' : 'py-4']">
      <div class="container px-0 container-lg" v-show="isShowFooter()">
        <div class="flex flex-wrap">
          <div class="w-full px-8 mb-4 lg:w-2/12">
            <figure class="text-center  md:text-left pb-4 ">
              <a href="/"><img src="../assets/img/logo.svg" class="inline-block md:block"/></a>
            </figure>
            <hr class="block md:hidden" />
          </div>
          <div class="w-full px-8 mb-4 lg:w-3/12">
            <p class="text-black font-semibold text-center md:text-left">
              Para ver siempre todas nuestras novedades seguínos en nuestras redes sociales, donde
              también estamos para servirte
            </p>
            <nav>
              <ul class="flex">
                <li>
                  <a
                    class="flex items-center justify-center w-10 h-10 mr-2 "
                    href="https://www.facebook.com/Hand.Paraguay/"
                    target="_blank"
                  >
                    <svg class="icon icon--md">
                      <use
                        href="../assets/img/icons.svg#icon-logo-fb"
                        xlink:href="../assets/img/icons.svg#icon-logo-fb"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    class="flex items-center justify-center w-10 h-10 mr-2 "
                    href="https://twitter.com/hand_py"
                    target="_blank"
                  >
                    <svg class="icon icon--md">
                      <use
                        href="../assets/img/icons.svg#icon-logo-twitter"
                        xlink:href="../assets/img/icons.svg#icon-logo-twitter"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    class="flex items-center justify-center w-10 h-10 mr-2 "
                    href="https://www.instagram.com/hand.py/"
                    target="_blank"
                  >
                    <svg class="icon icon--md">
                      <use
                        href="../assets/img/icons.svg#icon-logo-instagram"
                        xlink:href="../assets/img/icons.svg#icon-logo-instagram"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a class="flex items-center justify-center w-10 h-10 mr-2 " href="#">
                    <svg class="icon icon--md">
                      <use
                        href="../assets/img/icons.svg#icon-logo-youtube"
                        xlink:href="../assets/img/icons.svg#icon-logo-youtube"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
            <hr class="block md:hidden" />
          </div>
          <div class="w-full px-8 mb-4 lg:w-1/3">
            <h5 class="lg:text-base text-black font-semibold">Nuestro sitio</h5>
            <ul class="grid grid-cols-1 md:grid-cols-2 md:grid-gap-2">
              <li class="mb-2 md:mb-0">
                <router-link :to="{ name: 'WhoWeAre' }" class="text-gray-800" target="_blank">
                  ¿Quienes somos?
                </router-link>
              </li>
              <li class="mb-2 md:mb-0">
                <a href="#" class="text-gray-800 ">Preguntas frecuentas</a>
              </li>
              <li class="mb-2 md:mb-0">
                <router-link :to="{ name: 'Help' }" class="text-gray-800" target="_blank">
                  ¿Como funciona?
                </router-link>
              </li>
              <li class="mb-2 md:mb-0">
                <a href="#" class="text-gray-800 "></a>
                <router-link :to="{ name: 'PrivacyPolicy' }" class="text-gray-800" target="_blank">
                  Política de privacidad
                </router-link>
              </li>
              <li class="mb-2 md:mb-0">
                <router-link :to="{ name: 'TermsCondition' }" class="text-gray-800" target="_blank">
                  Términos de uso
                </router-link>
              </li>
            </ul>
            <hr class="block md:hidden" />
          </div>

          <div class="w-full px-8 mb-4 lg:w-3/12">
            <h5 class="lg:text-base text-black font-semibold">¿Necesitas ayuda?</h5>
            <p>
              Estamos a tu disposición para aclararte cualquier duda, puedes comunicarte al:
            </p>
            <p class="text-xl font-bold icon-text-aligner">
              <a href="https://wa.me/595986130808" target="_blank">
                <img src="../assets/img/whatsapp.svg" class="icon mr-2" />
                0986-130-808
              </a>
            </p>
            <p class="text-xl font-bold icon-text-aligner">
              <svg class="icon">
                <use
                  href="../assets/img/icons.svg#icon-email"
                  xlink:href="../assets/img/icons.svg#icon-email"
                />
              </svg>
              contacto@hand.com.py
            </p>
            <p>Lunes a Viernes de 8:00hs a 18:00hs</p>
            <hr class="block md:hidden" />
          </div>
        </div>
      </div>
      <div class="container px-8 container-lg">
        <div class="flex flex-wrap">
          <div class="w-full px-2 mb-2  text-center ">
            <hr class="md:block hidden" v-show="isShowFooter()" />
            <p class="mt-2">
              (c) Hand Paraguay - Hand y la tipografía con una mano en la H son marcas registradas
              de Hand Paraguay. Todos los derechos reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String
  },
  methods: {
    isShowFooter() {
      return (
        this.$route.name !== "ServiceRequest" &&
        this.$route.name !== "ServiceRequestSection" &&
        this.$route.name !== "ClientProfile" &&
        this.$route.name !== "Message" &&
        this.$route.name !== "MessageResponderWithoutStatus" &&
        this.$route.name !== "MessageResponder" &&
        this.$route.name !== "ProfessionalServiceRequest" &&
        this.$route.name !== "ProfessionalServiceRequestSection" &&
        this.$route.name !== "ProfessionalBudgetRequest" &&
        this.$route.name !== "ProfessionalBudgetRequestSection" &&
        this.$route.name !== "ProfessionalProfile" &&
        this.$route.name !== "ProfessionalMessage" &&
        this.$route.name !== "ProfessionalMessageResponderWithoutStatus" &&
        this.$route.name !== "ProfessionalMessageResponder" &&
        this.$route.name !== "EditProfessionService" &&
        this.$route.name !== "EditProfessionPhoto"
      );
    }
  }
};
</script>
<style scoped>
@import "../assets/css/index.css";
</style>
