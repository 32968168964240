<template>
  <div class="container msg-section" v-if="showAlert">
    <section
      class="flex flex-col items-center md:flex-row alert alert--success shadow relative"
      v-bind:class="{
        'alert--success': isSuccess,
        'alert--danger': hasError,
        'alert--warning': isWarning,
        'alert--info': isInfo
      }"
    >
      <div class="md:pr-6 mb-2">
        <svg class="icon text-6xl md:text-5xl text-green-700" v-if="isSuccess">
          <use
            href="../assets/img/icons.svg#icon-check-circle"
            xlink:href="../assets/img/icons.svg#icon-check-circle"
          />
        </svg>
        <svg class="text-6xl text-red-800 icon md:text-5xl" v-if="hasError">
          <use
            href="../assets/img/icons.svg#icon-alert-circle-exc"
            xlink:href="../assets/img/icons.svg#icon-alert-circle-exc"
          />
        </svg>
        <svg class="text-6xl text-yellow-800 icon md:text-5xl" v-if="isWarning">
          <use
            href="../assets/img/icons.svg#icon-alert-circle-exc"
            xlink:href="../assets/img/icons.svg#icon-alert-circle-exc"
          />
        </svg>
        <svg class="text-6xl text-blue-800 icon md:text-5xl" v-if="isInfo">
          <use
            href="../assets/img/icons.svg#icon-alert-circle-i"
            xlink:href="../assets/img/icons.svg#icon-alert-circle-i"
          />
        </svg>
      </div>
      <div class="text-center md:text-left">
        <p class="text-sm">
          <span v-html="message"></span>
          <a
            href="javascript:void(0)"
            @click="ResendActivateEmail(ResendEmailId)"
            v-if="showResendEmail"
          >
            Reenviar correo</a
          >
          <a href="javascript:void(0)" @click="signIn('facebook')" v-if="showFacebookLogin">
            aqui</a
          >
          <a href="javascript:void(0)" @click="signIn('google')" v-if="showGoogleLogin"> aqui</a>
          <a href="javascript:void(0)" @click="signIn('mail')" v-if="showMailLogin"> aqui</a>
          <span
            @click="CloseAlert()"
            v-if="showCloseButton"
            role="button"
            class="absolute alert-close-btn"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path></svg
          ></span>
        </p>
      </div>
      <Facebook ref="fbComponent" style="display:none;" />
      <Google ref="googleComponent" style="display:none;" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
export default {
  name: "Messages",
  methods: {
    ResendActivateEmail(email) {
      this.processingActivate = true;
      if (email) {
        this.$store
          .dispatch("resendActivateEmail", {
            email: email
          })
          .then(() => {
            this.processingActivate = false;
            let ResendEmailMessage = `Resend Email Successfully`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", ResendEmailMessage);
            this.$store.dispatch("setShowAlert", true);
            this.$store.dispatch("setIsResendEmail", "");
          })
          .catch(() => {
            this.processingActivate = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingActivate = false;
      }
    },
    signIn(platform) {
      if (platform == "facebook") {
        this.$refs.fbComponent.doLogin();
      } else if (platform == "google") {
        this.$refs.googleComponent.handleClickSignIn();
      } else if (platform == "mail") {
        this.$emit("focusEmail");
        if (this.$route.name != "Login") {
          this.$router.push({ name: "LoginClient" });
        }
      }
    },
    CloseAlert() {
      this.$store.dispatch("setShowAlert", false);
    }
  },
  computed: {
    ...mapGetters([
      "message",
      "hasError",
      "isSuccess",
      "isInfo",
      "isWarning",
      "showAlert",
      "showResendEmail",
      "showFacebookLogin",
      "showGoogleLogin",
      "ResendEmailId",
      "showMailLogin",
      "showCloseButton"
    ])
  },
  components: {
    Facebook,
    Google
  }
};
</script>

<style scoped>
@import "../assets/css/perfil.css";
.error {
  color: red;
}

.success {
  color: green;
}

.message {
  padding: 20px;
}
.msg-section {
  margin: 10px auto;
}
.alert-close-btn {
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
}
</style>
