<template>
  <div class="w-full">
    <section class="flex bg-white">
      <div class="container flex flex-col container-xl md:flex-row mt-6 md:mt-24 p-10 md:p-0 mb-48">
        <div class="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0 mx-auto">
          <form
            method="post"
          >
            <fieldset>
              <h4 class="">Registrarme como profesional</h4>
              <p class="mb-6">Crea tu perfil profesional en Hand de manera simple y rápida</p>
            </fieldset>
            <fieldset class="mb-8">
              <div class="grid md:grid-columns-2 grid-gap-4">
                <div>
                  <FacebookRegister @updateData="updateValuesFromSocial"/>
                </div>
                <div>
                  <GoogleRegister  @updateData="updateValuesFromSocial"/>
                </div>
              </div>
            </fieldset>
            <separator/>
            <fieldset class="mt-6">
              <div class="grid grid-cols-1 xl-grid-cols-2 grid-gap-4">
                <div>
                  <form-label :title="'Nombre y Apellido'">
                    <input
                        v-model="name"
                        class="block w-full form-input"
                        type="text"
                        placeholder="Ingresa tu nombre y apellido"
                      />
                      <span class="error" v-if="submited && !name">Por favor ingresa tu nombre y apellido</span>
                  </form-label>
                </div>
                <div class="mt-[15px] xl:mt-[0px]">
                  <form-label :title="'Teléfono'">
                    <phone-input :value="phoneNumber" @input="onPhoneInput" />
                    <span class="error" v-if="submited && !isPhoneValid">
                      Por favor ingrese su número (lo vamos a necesitar para poder darte soporte si
                      lo necesitas)
                    </span>
                  </form-label>
                </div>
              </div>
              <div class="grid grid-cols-1 xl-grid-cols-2 grid-gap-4 mt-[15px] mb-[30px] xl:mt-[0px] xl:mb-[15px]">
                <div class="mt-4">
                  <form-label :title="'Contraseña'">
                    <template #tooltip>
                      <div class="inline-block">
                        <Tooltip message="Debe tener al menos ocho caracteres, contener caracteres especiales, minúsculas y mayúsculas, números y simbolos">
                          <img src="@/assets/v2/svg/info.svg" class="inline-block info-tooltip"/>
                        </Tooltip>
                      </div>
                    </template>
                    <password-input v-model="password" />
                    <span class="error" v-if="submited && !password">Por favor ingrese una contraseña</span>
                    <span class="error" v-if="submited && !validatePassword()">
                      La contraseña debe tener mínimamente 8 caracteres.
                    </span>
                    <label class="flex items-center">
                        <a :href="'#'" @click.prevent="suggestPassword" class="text-dark3 text-sm underline">Sugerir contraseña</a>
                    </label>
                  </form-label>
                </div>
                <div class="mt-[10px] xl:mt-[16px]">
                  <form-label :title="'Repite la contraseña'">
                    <password-input v-model="password2" />
                    <span class="error" v-if="submited && password != password2">Las contraseñas no coinciden</span>
                  </form-label>
                </div>
              </div>
            </fieldset>

            <div class="w-full flex justify-center mt-[20px] xl:mt-[0px]">
              <div class="w-[100%] xl:w-[40%] flex flex-col items-center">
                <rounded-button :disabled="processing" :onClick="onNextStep">
                  Siguiente
                </rounded-button>
                <div class="w-max flex items-center max-xl:flex-col justify-between mt-[21px]">
                  <span>¿Necesitas ayuda?</span>
                  <div>
                    <whatsapp-icon class="mx-[6px] inline-block"/>
                    <custom-link :href="'#'">0981888777</custom-link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Tooltip from "@/components/v2/Tooltip.vue";
import validatePassword from "@/utils/methods/validatePassword";
import FacebookRegister from "@/components/FacebookRegister.vue";
import GoogleRegister from "@/components/GoogleRegister.vue";
import { RoundedButton } from "@/components/v2/buttons"
import Separator from "@/components/v2/Separator.vue";
import { PhoneInput, FormLabel, PasswordInput } from "@/components/v2/forms";
import {WhatsappIcon} from "@/components/v2/icons";
import CustomLink from "@/components/v2/Link.vue"
export default {
  data() {
    return {
      submited: false,
      name: "",
      phoneNumber: "",
      countryCode: "",
      password: "",
      password2: "",
      isPhoneValid: false,
      processing: false,
      email: "",
      pictureProfileUrl: "",
      oauthUid: "",
      oauthProvider:""
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.$store.dispatch("setShowHeaderBackButton", true);
    this.$store.dispatch("setShowHeaderCancelButton", true);
    this.$store.dispatch("saveRegisterProfessionalCurrentStep", 1);
  },
  beforeDestroy() {
    this.$store.dispatch("setShowHeaderBackButton", false);
    this.$store.dispatch("setShowHeaderCancelButton", false);
  },
  methods: {
    validatePassword,
    onNextStep(e) {
      e.preventDefault();
      this.submited = true;
      this.processing = true;
      
      if (this.validateForm()) {
        this.$store.dispatch("saveRegisterProfessionalTempData", {
          firstName: this.name.split(' ')[0],
          lastName: this.name.split(' ')[1],
          phoneNumber: this.phoneNumber,
          countryCode: this.countryCode,
          password: this.password,
          email: this.email,
          pictureProfileUrl: this.pictureProfileUrl,
          oauthProvider: this.oauthProvider,
          oauthUid: this.oauthUid,
        }).then(() => {
          this.$router.push({ name: 'RegisterProfessionalStep2' })
        })
      } else {
        
      }
      this.processing = false;
    },
    validateForm() {
      return (
        this.name != "" &&
        this.isPhoneValid == true &&
        this.password != "" &&
        this.validatePassword() == true &&
        this.password == this.password2
      );
    },
    onPhoneInput(val) {
      this.phoneNumber = val.phoneNumber;
      this.countryCode = val.countryCode;
      if (val.countryCode == "PY") {
        if (val.phoneNumber.length >= 9) {
          this.isPhoneValid = true;
        } else {
          this.isPhoneValid = false;
        }
      } else {
        this.isPhoneValid = val.isValid;
      }
    },
    suggestPassword() {
      var passwordSuggested = this.phoneNumber.slice(-3) + this.name.slice(0, 3) + (Math.floor(Math.random() * (999 - 100 + 1) ) + 100)
      this.password = passwordSuggested
      this.password2 = passwordSuggested
    },
    updateValuesFromSocial(data, callback) {
      callback();
      
      this.name = data.firstName+' '+data.lastName
      this.email = data.email
      this.pictureProfileUrl = data.pictureProfileUrl
      this.oauthProvider = data.oauthProvider
      this.oauthUid = data.oauthUid
    }
  },
  components: {
    FacebookRegister,
    GoogleRegister,
    Tooltip,
    RoundedButton,
    Separator,
    PhoneInput,
    FormLabel,
    PasswordInput,
    WhatsappIcon,
    CustomLink
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.pass-group {
  position: relative;
}
.pass-group .eye-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  cursor: pointer;
}
.whatsapp-contact-icon {
  top: 2px;
}

</style>
<style lang="css">
@media (min-width: 1280px) {
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
</style>
