<template>
  <select
    class="block w-full mt-1 rounded form-select"
    v-model="experience"
    @input="$emit('input', $event.target.value)"
    @change="onSelectedEvent(experience)"
  >
    <option value="null">Años de experiencia</option>
    <option value="1">1 año</option>
    <option value="2">2 años</option>
    <option value="3">3 años</option>
    <option value="4">4 años</option>
    <option value="5">5 años</option>
    <option value="6">6 años</option>
    <option value="7">7 años</option>
    <option value="8">8 años</option>
    <option value="9">9 años</option>
    <option value="10">10 años</option>
    <option value="11">11 a 20 años</option>
    <option value="12">21 a 30 años</option>
    <option value="13"> más de 30 años</option>
  </select>
</template>

<script>
export default {
  name: "dropdownExperience",
  data() {
    return {
      experience: 1
    };
  },
  props: {
    value: String,
    onSelectedEvent: Function
  },
  watch: {
    value: function(newValue) {
      this.distance = newValue;
    }
  }
};
</script>
<style scoped>
@import "../assets/css/busqueda.css";
</style>
