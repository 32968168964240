<template>
  <div>
    <Header />
    <Breadcrumb :items="BreadcrumbItems" />
    <Messages />
    <section class="py-3">
      <div class="container container-xl">
        <div class="flex flex-col justify-between my-4 -mx-2 lg:flex-row">
          <aside class="px-2 mb-4 lg:w-3/12">
            <div class="overflow-hidden bg-white rounded shadow-lg md:sticky md:top-0">
              <img
                class="object-cover w-full h-56"
                :src="profiledata.pictureProfileUrl"
                :alt="profiledata.firstName + ' ' + profiledata.lastName"
              />
              <div class="px-6 py-4">
                <p class="mb-0 text-sm text-gray-500">Nombre:</p>
                <h5 class="mt-0 ">{{ profiledata.firstName }} {{ profiledata.lastName }}</h5>
                <div class="flex items-center mt-0 mb-2">
                  <star-rating
                    inactive-color="#dfe0e6"
                    :show-rating="false"
                    active-color="#ffd83e"
                    :increment="0.5"
                    :star-size="20"
                    :read-only="true"
                    :rating="profiledata.rate | numberFormat"
                  ></star-rating>
                  <div class="ml-1 font-semibold text-gray-900">
                    {{ profiledata.rate | numberFormat }}/5
                  </div>
                </div>
                <div class="grid grid-columns-2 grid-gap-2">
                  <div>
                    <p class="mb-0 text-sm text-gray-500">Edad:</p>
                    <h6 class="mt-0 mb-2">{{ userage }}</h6>
                  </div>
                  <div>
                    <p class="mb-0 text-sm text-gray-500">C.I:</p>
                    <h6 class="mt-0 mb-2">
                      ****.{{ profiledata.identificationNumber | lastthree }}
                    </h6>
                  </div>
                </div>
                <p class="mb-0 text-sm text-gray-500">Zona de cobertura:</p>
                <h6 class="mt-0 mb-2" v-if="profiledata.Location">
                  {{ profiledata.Location.description }}
                </h6>
              </div>
              <button
                class="w-full rounded-t-none btn btn--md btn--secondary"
                data-pushbar-target="right"
                @click="
                  ContactSidebarShow = true;
                  toggleHtmlClass();
                "
              >
                Solicitar presupuesto detallado
              </button>
            </div>
          </aside>
          <section class="px-2 lg:w-9/12 md:pr-2 md:pl-4">
            <h4 class="mt-6">
              <svg class="-mt-2 icon">
                <use
                  href="../assets/img/icons.svg#icon-file-content"
                  xlink:href="../assets/img/icons.svg#icon-file-content"
                />
              </svg>
              Resumen
            </h4>
            <article class="p-5 mb-6 bg-white border border-gray-400 rounded">
              <h6>Sobre mí</h6>
              <p>{{ profiledata.coverLetter }}</p>

              <h6>Documentaciones al día</h6>
              <ul class="grid md:grid-columns-3">
                <li
                  class="flex items-center mb-3"
                  v-for="(Document, index) in profiledata.Documents"
                  :key="index"
                >
                  <svg class="flex-none icon">
                    <use
                      href="../assets/img/icons.svg#icon-check-circle-alt"
                      xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                    />
                  </svg>
                  <span class="ml-2">{{ Document.description }}</span>
                </li>
              </ul>
            </article>

            <h4>
              <svg class="-mt-2 icon">
                <use
                  href="../assets/img/icons.svg#icon-chat-round-content"
                  xlink:href="../assets/img/icons.svg#icon-chat-round-content"
                />
              </svg>
              Testimonios
              <sup class="pb-2 text-xs">({{ profiledata.opinionsCount }})</sup>
            </h4>

            <article
              class="p-5 mb-2 bg-white border border-gray-400 rounded"
              v-for="(Opinion, index) in profiledata.Opinions"
              :key="index"
            >
              <div class="flex">
                <figure
                  class="flex-none w-6 h-6 mr-2 overflow-hidden border border-gray-400 rounded-full"
                >
                  <img
                    :src="Opinion.pictureProfileUrl"
                    :alt="Opinion.firstName + ' ' + Opinion.lastName"
                  />
                </figure>

                <div class="flex-1 pl-2">
                  <div class="flex flex-col md:flex-row">
                    <div class="mr-2">
                      <h6 class="my-0">{{ Opinion.firstName }} {{ Opinion.lastName }}</h6>
                    </div>
                    <div class="flex items-center leading-none">
                      <star-rating
                        :padding="1"
                        inactive-color="#dfe0e6"
                        :show-rating="false"
                        active-color="#ffd83e"
                        :increment="0.5"
                        :star-size="20"
                        :read-only="true"
                        :rating="Opinion.rate | numberFormat"
                      ></star-rating>

                      <div class="ml-1 font-bold text-gray-900">
                        {{ Opinion.rate | numberFormat }}/5
                      </div>
                    </div>
                    <div class="pt-1 text-xs text-gray-500 md:ml-auto">
                      <svg class="text-gray-700 icon">
                        <use
                          href="../assets/img/icons.svg#icon-calendar"
                          xlink:href="../assets/img/icons.svg#icon-calendar"
                        />
                      </svg>
                      Hace 1 semana
                    </div>
                  </div>
                  <p>{{ Opinion.description }}</p>
                </div>
              </div>
            </article>
            <section class="relative p-5 overflow-hidden bg-white border border-gray-400 rounded">
              <form @submit.prevent="serviceRequest" method="post">
                <h5
                  class="p-4 mt-0 -mx-5 -mt-5 font-semibold bg-gray-100 border-b border-gray-200 icon-text-aligner"
                >
                  <svg class="icon">
                    <use
                      href="../assets/img/icons.svg#icon-todo"
                      xlink:href="../assets/img/icons.svg#icon-todo"
                    />
                  </svg>
                  Servicio ofrecidos
                </h5>
                <ul class="mt-3 grid md:grid-columns-3 grid-gap-2">
                  <li class="mb-3" v-for="(Service, index) in profiledata.Services" :key="index">
                    <label
                      class="flex items-start mb-0 cursor-pointer p-2 border border-gray-200 rounded hover:bg-gray-100"
                      v-if="Service.price > 0"
                    >
                      <input
                        type="number"
                        value="0"
                        min="0"
                        max="5"
                        class="w-16 text-center form-input"
                        @change="addService($event, Service)"
                      />
                      <span class="ml-2 font-normal font-body">
                        {{ Service.description }}
                        <strong class="block text-lg font-black"
                          >Gs.{{ Service.price | thousandSeparator }}</strong
                        >
                      </span>
                    </label>
                    <label v-else>
                      <svg class="flex-none icon">
                        <use
                          href="../assets/img/icons.svg#icon-check-circle-alt"
                          xlink:href="../assets/img/icons.svg#icon-check-circle-alt"
                        />
                      </svg>
                      <span class="ml-2 font-normal font-body">{{ Service.description }}</span>
                    </label>
                  </li>
                </ul>
                <span class="error" v-if="servicesubmited && servicedata.services.length <= 0"
                  >Debe seleccionar al menos un servicio</span
                >
                <p class="mb-6 text-xs text-orange-500">
                  "El pago lo realizara una vez que el profesional finalice el trabajo y en
                  conformidad con el mismo"
                </p>
                <h5
                  class="p-4 mt-0 -mx-5 font-semibold bg-gray-100 border-t border-b border-gray-200 icon-text-aligner"
                >
                  <svg class="icon">
                    <use
                      href="../assets/img/icons.svg#icon-calendar"
                      xlink:href="img/icons.svg#icon-calendar"
                    />
                  </svg>
                  Agende una fecha y horario para el servicio
                </h5>
                <div class="header-picker">
                  <div class="flex justify-content-between">
                    <span class="header-picker-date dots-text flex-1">
                      <span>{{ moment(servicedata.date).format("dddd,D MMMM,YYYY") }}</span>
                    </span>
                    <div class="header-picker-time flex pl-10">
                      <span
                        class="dots-text time-number header-picker-hour flex justify-content-right"
                      >
                        <span>{{ moment(servicedata.date).format("LT") }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <VueCtkDateTimePicker
                  v-model="servicedata.date"
                  :inline="true"
                  color="#3e65ff"
                  locale="es"
                  :minDate="moment().format('YYYY-MM-DD HH:mm')"
                  format="YYYY-MM-DD HH:mm"
                  formatted="dddd,D,MMMM,YYYY"
                  :noKeyboard="true"
                  id="schduledate"
                  class="schduledate-wrapper"
                  :noLabel="true"
                  :noButtonNow="true"
                  :no-header="true"
                  v-bind:class="{
                    'bg-red-100 border-red-700': servicesubmited && !servicedata.date
                  }"
                />
                <span class="error" v-if="servicesubmited && !servicedata.date"
                  >Please select date</span
                >
                <h5
                  class="p-4 mt-0 -mx-5 font-semibold bg-gray-100 border-t border-b border-gray-200 icon-text-aligner"
                >
                  <svg class="icon">
                    <use
                      href="../assets/img/icons.svg#icon-alert-circle-i"
                      xlink:href="../assets/img/icons.svg#icon-alert-circle-i"
                    />
                  </svg>
                  Información adicional
                </h5>
                <textarea
                  rows="5"
                  class="w-full mt-3 mb-2 form-input"
                  placeholder="Agregue alguna información adicional, cuando más preciso mejor. No ingrese datos personales"
                  v-model="servicedata.description"
                  v-bind:class="{
                    'bg-red-100 border-red-700': servicesubmited && !servicedata.description
                  }"
                ></textarea>
                <label class="flex items-center mb-2">
                  <span class="error" v-if="servicesubmited && !servicedata.description"
                    >Favor ingrese una descripción</span
                  >
                </label>
                <div class="flex flex-col my-2 mb-8 md:flex-row md:justify-between md:items-center">
                  <div class="text-lg text-gray-900 font-display">
                    Servicios solicitados: {{ totalService }}
                  </div>
                  <div class="text-3xl font-bold font-display text-brand-secondary">
                    Total Gs.: {{ totalServiceCost | thousandSeparator }}
                  </div>
                </div>
                <button
                  class="absolute bottom-0 left-0 right-0 w-full rounded-none btn btn--success btn--md"
                  :disabled="processingserviceRequest || servicewithprice.length <= 0"
                >
                  Solicitar el servicio
                </button>
              </form>
            </section>
            <div class="flex mb-6">
              <p class="text-orange-500" v-if="servicewithprice.length <= 0">
                "Este profesional no cuenta con servicios con precios pre-determinados, para
                contactarlo por favor
                <a
                  href="javascript:void(0);"
                  @click="
                    ContactSidebarShow = true;
                    toggleHtmlClass();
                  "
                  >solicite</a
                >
                un presupuesto"
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>
    <aside
      data-pushbar-id="right"
      class="w-full shadow-2xl pushbar from_right md:w-2/5"
      v-bind:class="{ opened: ContactSidebarShow }"
    >
      <header class="px-8 py-4 bg-gray-100">
        <div class="flex items-center justify-between">
          <div>
            <h5>Contactar</h5>
          </div>
          <div>
            <span
              data-pushbar-close
              class="close push_right"
              @click="
                ContactSidebarShow = false;
                toggleHtmlClass();
              "
            ></span>
          </div>
        </div>
      </header>
      <div class="px-8 py-4">
        <form @submit.prevent="budgetRegister" method="post">
          <p>
            Rellena el siguiente formulario y
            <strong class="text-sm text-brand-secondary"
              >"{{ profiledata.firstName }} {{ profiledata.lastName }}"</strong
            >
            se pondrá en contacto contigo en la brevedad posible. Todos los datos que envíes serán
            tratados de forma confidencial
          </p>
          <h6 class="mb-2">Tipo de servicio</h6>
          <div class="flex">
            <div class="pr-4" v-for="(Service, index) in profiledata.Services" :key="index">
              <label class="flex items-center">
                <input
                  type="checkbox"
                  name="servicios"
                  v-model="budget.services"
                  class="form-checkbox"
                  :value="Service.id"
                  v-bind:class="{
                    'bg-red-100 border-red-700': submited && budget.services.length <= 0
                  }"
                />
                <span class="ml-2 font-normal font-body">{{ Service.description }}</span>
              </label>
            </div>
          </div>
          <label class="flex items-center">
            <span class="error" v-if="submited && budget.services.length <= 0"
              >Debe seleccionar al menos un servicio</span
            >
          </label>
          <label>
            <span>¿Cuando quieres el servicio?</span>
            <VueCtkDateTimePicker
              v-model="budget.date"
              color="#3e65ff"
              buttonColor="#3e65ff"
              :only-date="true"
              :no-header="true"
              :minDate="moment().format('YYYY-MM-DD')"
              locale="es"
              format="YYYY-MM-DD"
              formatted="dddd, D MMMM, YYYY"
              :noKeyboard="true"
              id="servicedate"
              class="schduledate-wrapper"
              :noLabel="true"
              :noButtonNow="true"
              v-bind:class="{ 'bg-red-100 border-red-700': submited && !budget.date }"
            />
            <span class="error" v-if="submited && !budget.date">Please select date</span>
          </label>

          <p>
            Adjunta algunas imágenes para una mejor descripción
            <strong class="text-sm text-brand-secondary">(puedes subir hasta 3 imágenes)</strong>
          </p>

          <input
            class="block w-full mt-1 mb-2 form-input"
            type="file"
            multiple
            placeholder="Nombre"
            ref="file"
          />
          <span class="error" v-if="submited && this.$refs.file && this.$refs.file.files.length > 3"
            >You can upload Maximum 3 images.</span
          >

          <h6>Describa el servicio que necesita</h6>
          <textarea
            class="w-full h-32 mb-4 border border-gray-400 rounded"
            v-model="budget.description"
            v-bind:class="{ 'bg-red-100 border-red-700': submited && !budget.description }"
          ></textarea>
          <label class="flex items-center">
            <span class="error" v-if="submited && !budget.description"
              >Favor ingrese una descripción</span
            >
          </label>
          <label class="flex items-center">
            <input
              type="checkbox"
              class="form-checkbox"
              v-model="acceptPolicy"
              v-bind:class="{ 'bg-red-100 border-red-700': submited && acceptPolicy != true }"
            />
            <span class="ml-2 font-normal font-body">
              He leído y acepto la
              <router-link :to="{ name: 'TermsCondition' }" class="underline" target="_blank"
                >política de privacidad y condiciones</router-link
              >
            </span>
          </label>
          <label class="flex items-center">
            <span class="error" v-if="submited && acceptPolicy != true"
              >Debe aceptar las politicas de privacidad y condiciones</span
            >
          </label>
          <button class="w-full btn btn--md btn--secondary" :disabled="processingbudgetRegister">
            Pedir Presupuesto
          </button>
        </form>
      </div>
    </aside>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import thousandSeparator from "@/utils/filters/thousandSeparator";
import numberFormat from "@/utils/filters/numberFormat";
import decrypt from "@/utils/methods/decrypt";
export default {
  data() {
    return {
      servicesubmited: false,
      ContactSidebarShow: false,
      processingGetProfileData: false,
      submited: false,
      processingbudgetRegister: false,
      processingserviceRequest: false,
      professionalId: null,
      profiledata: {},
      servicedata: {
        services: [],
        date: this.moment().format("YYYY-MM-DD HH:mm"),
        description: "",
        professional_id: null,
        user_id: null
      },
      ScheduleDate: "2020-02-03 12:30",
      budget: {
        services: [],
        date: this.moment().format("YYYY-MM-DD"),
        description: "",
        professional_id: null,
        user_id: null
      },
      BreadcrumbItems: [{ name: "Inicio", link: "/" }],
      acceptPolicy: false,
      budgetId: null,
      SecretKey: process.env.VUE_APP_ENCRYPT_SECRETKEY
    };
  },
  filters: {
    lastthree: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.slice(value.length - 3);
    },
    numberFormat,
    thousandSeparator
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetProfileData();
  },
  created() {
    if (this.$route.params.pid) {
      this.professionalId = this.decrypt(this.$route.params.pid);
    }

    if (this.searchProfession && this.searchLocation.id) {
      this.BreadcrumbItems.push({
        name: this.searchProfession,
        link:
          "/professionals/search?profession=" +
          this.searchProfession +
          "&location=" +
          this.searchLocation.id
      });
      this.BreadcrumbItems.push({
        name: this.searchLocation.name,
        link:
          "/professionals/search?profession=" +
          this.searchProfession +
          "&location=" +
          this.searchLocation.id
      });
    }
  },
  updated() {
    let today_button = document.getElementsByClassName("datepicker-today")[0];
    today_button.closest("button").classList.add("datepicker-today-block");
    let today_button_sidebar = document.getElementsByClassName("datepicker-today")[1];
    today_button_sidebar.closest("button").classList.add("datepicker-today-block");
  },
  methods: {
    decrypt,
    toggleHtmlClass() {
      const el = document.documentElement;
      if (this.ContactSidebarShow) {
        el.classList.add("pushbar_locked");
      } else {
        el.classList.remove("pushbar_locked");
      }
    },
    GetProfileData() {
      this.processingGetProfileData = true;
      this.$store
        .dispatch("GetProfileData", {
          professionalId: this.professionalId
        })
        .then(response => {
          this.profiledata = response;
          if (!this.searchProfession && !this.searchLocation.id) {
            this.BreadcrumbItems.push({
              name: this.profiledata.Profession[0].description,
              link:
                "/professionals/search?profession=" +
                this.profiledata.Profession[0].description +
                "&location=" +
                this.profiledata.Location.id
            });
            this.BreadcrumbItems.push({
              name: this.profiledata.Location.description,
              link:
                "/professionals/search?profession=" +
                this.profiledata.Profession[0].description +
                "&location=" +
                this.profiledata.Location.id
            });
          }
          this.BreadcrumbItems.push({
            name: this.profiledata.firstName + " " + this.profiledata.lastName,
            islast: true
          });
          this.processingGetProfileData = false;
        })
        .catch(() => {
          this.processingGetProfileData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    budgetRegister(e) {
      e.preventDefault();
      this.submited = true;
      this.processingbudgetRegister = true;
      this.budget.professional_id = this.professionalId;
      this.budget.user_id = this.user.id;
      if (this.validatebudgetForm()) {
        var images = require.context("../assets/img/", false, /\.gif$/);
        Swal.fire({
          imageUrl: images("./loading.gif"),
          imageAlt: "Loader",
          showConfirmButton: false,
          background: "transparent",
          backdrop: `rgba(0,0,0,0.6)`,
          allowOutsideClick: false
        });
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.file.files.length; i++) {
          let file = this.$refs.file.files[i];
          formdata.append("photos", file);
        }
        formdata.append("date", this.budget.date);
        formdata.append("description", this.budget.description);
        formdata.append("user_id", this.budget.user_id);
        formdata.append("professional_id", this.budget.professional_id);
        formdata.append("services", JSON.stringify(this.budget.services));
        this.$store
          .dispatch("budgetRegister", formdata)
          .then(() => {
            Swal.close();
            let budgetregisterMessage = `Presupuesto registrado correctamente, nos pondremos en contacto contigo pronto!`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", budgetregisterMessage);
            this.$store.dispatch("setShowAlert", true);

            this.processingbudgetRegister = false;
            this.budget = {
              services: [],
              date: this.moment().format("YYYY-MM-DD"),
              description: "",
              professional_id: null,
              user_id: null
            };
            e.target.reset();
            this.ContactSidebarShow = false;
            this.processingbudgetRegister = false;
            this.toggleHtmlClass();
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function() {
              self.$store.dispatch("setShowAlert", false);
              self.$router.push({ name: "BudgetRequest" });
            }, 2000);
          })
          .catch(() => {
            Swal.close();
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            this.ContactSidebarShow = false;
            this.submited = false;
            this.toggleHtmlClass();
            this.processingbudgetRegister = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingbudgetRegister = false;
      }
    },

    validatebudgetForm() {
      return (
        this.budget.date != "" &&
        this.budget.description != "" &&
        this.budget.user_id != "" &&
        this.budget.professional_id != "" &&
        this.budget.services.length > 0 &&
        this.$refs.file &&
        this.$refs.file.files.length <= 3 &&
        this.acceptPolicy == true
      );
    },
    addService(e, service) {
      const i = this.servicedata.services.findIndex(_item => _item.id === service.id);
      if (i > -1) {
        if (e.target.value > 0) {
          this.servicedata.services[i]["quantity"] = e.target.value;
        } else {
          this.servicedata.services.splice(i, 1);
        }
      } else {
        this.servicedata.services.push({
          id: service.id,
          quantity: e.target.value
        });
      }
    },
    serviceRequest(e) {
      e.preventDefault();
      this.servicesubmited = true;
      this.processingserviceRequest = true;
      this.servicedata.professional_id = this.professionalId;
      this.servicedata.user_id = this.user.id;
      if (this.validateserviceForm()) {
        this.$store
          .dispatch("serviceRequest", this.servicedata)
          .then(() => {
            let serviceRequestMessage = `Orden de trabajo creada correctamente. Redireccionando al panel de seguimiento...`;
            this.$store.dispatch("setIsErrorAlert", false);
            this.$store.dispatch("setIsSuccessAlert", true);
            this.$store.dispatch("setGlobalMessage", serviceRequestMessage);
            this.$store.dispatch("setShowAlert", true);
            this.processingserviceRequest = false;
            this.servicedata = {
              services: [],
              date: this.moment().format("YYYY-MM-DD"),
              description: "",
              professional_id: null,
              user_id: null
            };
            this.servicesubmited = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
            var self = this;
            setTimeout(function() {
              self.$store.dispatch("setShowAlert", false);
              self.$router.push({ name: "ServiceRequest" });
            }, 2000);
          })
          .catch(() => {
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch("setIsSuccessAlert", false);
            this.$store.dispatch("setIsErrorAlert", true);
            this.$store.dispatch("setGlobalMessage", errorMessage);
            this.$store.dispatch("setShowAlert", true);
            this.processingserviceRequest = false;
            this.servicesubmited = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } else {
        this.processingserviceRequest = false;
      }
    },
    validateserviceForm() {
      return (
        this.servicedata.date != "" &&
        this.servicedata.description != "" &&
        this.servicedata.user_id != "" &&
        this.servicedata.professional_id != "" &&
        this.servicedata.services.length > 0
      );
    }
  },
  computed: {
    ...mapGetters(["error", "searchProfession", "searchLocation", "user"]),
    userage() {
      let today = new Date();
      let birthDate = new Date(this.profiledata.birthDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }
      return age;
    },
    servicewithprice() {
      if (this.profiledata.Services && this.profiledata.Services.length > 0) {
        return this.profiledata.Services.filter(service => {
          return service.price > 0;
        });
      } else {
        return [];
      }
    },
    totalServiceCost() {
      if (this.servicedata.services.length > 0) {
        var sum = 0;
        this.servicedata.services.forEach(e => {
          const i = this.servicewithprice.findIndex(_item => _item.id === e.id);
          if (i > -1) {
            var price = this.servicewithprice[i]["price"];
            sum += e.quantity * price;
          }
        });
        return sum;
      } else {
        return 0;
      }
    },
    totalService() {
      return this.servicedata.services.length;
    }
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    VueCtkDateTimePicker
  }
};
</script>
<style lang="css" scoped>
@import "../assets/css/perfil.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
<style lang="css">
.bg-red-100 #servicedate-input {
  background-color: #ffecef;
}
.border-red-700 #servicedate-input {
  border-color: #ca3150 !important;
}
.schduledate-wrapper .week-days {
  color: #ff783e;
}

.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day:hover
  .datepicker-day-effect,
.schduledate-wrapper .custom-button:hover .custom-button-effect {
  background-color: #f0f0f0 !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day:hover
  .datepicker-day-text,
.schduledate-wrapper .custom-button:hover .custom-button-content,
.schduledate-wrapper .custom-button .custom-button-content {
  color: black !important;
}

.schduledate-wrapper .custom-button.is-selected .custom-button-content {
  color: white !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day.selected:hover
  .datepicker-day-effect {
  background-color: #7591ff !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-day.selected:hover
  .datepicker-day-text {
  color: #fff !important;
}
.schduledate-wrapper .datepicker-container .datepicker-days .datepicker-day .datepicker-today {
  background-color: transparent;
}
.schduledate-wrapper .time-picker-column-item-effect {
  background-color: #ff783e !important;
}
.schduledate-wrapper
  .datepicker-container
  .datepicker-days
  .datepicker-today-block
  .datepicker-day-text {
  color: #ff783e;
}
.header-picker {
  padding: 10px 0px 10px 10px;
  background-color: #3e65ff;
  border-radius: 4px;
}
.header-picker span {
  color: white;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 18px;
  text-transform: capitalize;
}
.header-picker-time {
  flex: 0 0 160px;
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}
</style>
