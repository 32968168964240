<template></template>

<script>
export default {
    beforeMount() {
        if (this.$route.query.url) {
            const redirectUrl = this.$route.query.url;
            window.location.href = redirectUrl;
        }
    }
}
</script>