<template>
  <svg :width="width" :height="height"  viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :class="`fill-${color}`" d="M4.85714 5.61538V12.0769C4.85714 13.3514 5.81653 14.3846 7 14.3846C8.18347 14.3846 9.14286 13.3514 9.14286 12.0769V5.38462C9.14286 2.96306 7.32002 1 5.07143 1C2.82284 1 1 2.96306 1 5.38462V12.5385C1 16.1071 3.68629 19 7 19C10.3137 19 13 16.1071 13 12.5385V5.61538"  stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:21
    },
    height:{
      type:Number,
      default:20
    },
    color:{
      type:String,
      default:'dark'
    }
  }
}
</script>
