<template>
  <div class="control-panel professional-profile">
    <Header @goToServiceRequest="goToServiceRequestEvent($event)" />
    <Breadcrumb />
    <Messages />
    <section class="py-3">
      <div class="container">
        <div class="flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row">
          <ProfileSidebar />

          <section class="px-2 lg:w-8/12">
            <div class="flex flex-col justify-between lg:flex-row lg:items-center mb-2">
              <h5 class="mb-3 lg:mt-0 lg:mb-2 text-center md:text-left">
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-todo"
                    xlink:href="../assets/img/icons.svg#icon-todo"
                  />
                </svg>
                Pedido de Servicios
              </h5>
              <div class="flex justify-center">
                <button
                  class="rounded btn--primary lg:w-auto btn btn--md px-4"
                  @click="GetUserServiceRequest"
                >
                  <span class="justify-between text-base mr-5"> Actualizar</span
                  ><img
                    src="../assets/img/icono-refrescar-blue.svg"
                    class="icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                  />
                </button>
              </div>
            </div>
            <div v-if="!paymentData.isPaymentPageShow">
              <div v-if="serviceRequests.length > 0">
                <div
                  class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
                  v-for="(serviceRequest, serviceRequestIndex) in serviceRequests"
                  :key="'paymentData_' + serviceRequestIndex"
                >
                  <header
                    :id="'service_' + serviceRequest.id"
                    @click="
                      contentVisible === serviceRequest.id
                        ? (contentVisible = false)
                        : (contentVisible = serviceRequest.id);
                      resetReviewData();
                    "
                    class="items-center cursor-pointer justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white md:border-b border-gray-200"
                  >
                    <div class="flex flex-wrap text-center md:text-left">
                      <div class="w-auto text-base text-black font-semibold">
                        <p class="mb-2 md:mb-0">
                          Estado:
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold success"
                            v-if="serviceRequest.status == 'complete'"
                            >Concretado
                            <span
                              v-if="
                                serviceRequest.paymentType == 'CASH' && serviceRequest.paid == false
                              "
                              >(pago pendiente de aprobación)</span
                            >
                            <span
                              v-if="
                                serviceRequest.paymentType == 'TRANSFER' &&
                                serviceRequest.paid == false
                              "
                              >(transferencia pendiente de aprobación)</span
                            >
                          </span>
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 text-brand-primary font-bold"
                            v-if="serviceRequest.status == 'ongoing'"
                            >En proceso</span
                          >
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold"
                            v-if="serviceRequest.status == 'pending'"
                            >Pendiente (en espera de que el profesional acepte el trabajo)</span
                          >
                          <span
                            class="-mt-1 lg:mt-0 lg:pl-2 text-danger font-bold"
                            v-if="serviceRequest.status == 'cancelled'"
                            >Cancelado</span
                          >
                        </p>
                      </div>
                      <div class="w-full md:w-auto text-center md:text-right md:ml-auto text-black">
                        <span>Orden: {{ serviceRequest.id }}</span>

                        <span class="ml-4" v-if="contentVisible === serviceRequest.id">
                          <img
                            src="../assets/img/collapse-arrow.png"
                            alt=""
                            class="w-8 rounded-full inline-block bg-gray-600"
                        /></span>
                        <span class="ml-4" v-if="contentVisible != serviceRequest.id">
                          <img
                            src="../assets/img/expand-arrow.png"
                            alt=""
                            class="rounded-full bg-gray-600 w-8 inline-block"
                        /></span>
                      </div>
                      <div class="w-full text-center text-black service-step">
                        <ul
                          class="progress-tracker progress-tracker--text progress-tracker--center mb-0"
                        >
                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ': serviceRequest.status == 'pending',
                              'is-complete':
                                serviceRequest.status == 'ongoing' ||
                                serviceRequest.status == 'complete',
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">Paso 1</h5>
                              Pendiente
                            </span>
                          </li>

                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ': serviceRequest.status == 'ongoing',
                              'is-complete': serviceRequest.status == 'complete',
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">Paso 2</h5>
                              En proceso
                            </span>
                          </li>

                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ':
                                serviceRequest.status == 'complete' &&
                                serviceRequest.paymentType != 'CASH' &&
                                serviceRequest.paymentType != 'TRANSFER',
                              'is-complete':
                                serviceRequest.status == 'complete' &&
                                (serviceRequest.paymentType == 'CASH' ||
                                  serviceRequest.paymentType == 'TRANSFER'),
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">Paso 3</h5>
                              Realizar Pago
                            </span>
                          </li>
                          <li
                            class="progress-step"
                            v-bind:class="{
                              'is-active ':
                                serviceRequest.reviewed == false &&
                                serviceRequest.status == 'complete' &&
                                (serviceRequest.paymentType == 'CASH' ||
                                  serviceRequest.paymentType == 'TRANSFER'),
                              'is-complete': serviceRequest.reviewed == true,
                            }"
                          >
                            <span class="progress-marker"></span>
                            <span class="text-base progress-text">
                              <h5 class="progress-title text-lg">paso 4</h5>
                              Agregar Reseña
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </header>
                  <div v-if="contentVisible === serviceRequest.id">
                    <div
                      v-if="
                        (serviceRequest.paid == false ||
                          serviceRequest.paymentType == 'CASH' ||
                          serviceRequest.paymentType == 'TRANSFER') &&
                        serviceRequest.reviewed == false
                      "
                    >
                      <div class="flex flex-col justify-between lg:flex-row">
                        <section class="w-full">
                          <section class="my-4 bg-white">
                            <div class="flex flex-wrap">
                              <div class="w-full px-2 md:w-2/5 hidden md:block">
                                <div class="flex items-center">
                                  <figure class="avatar w-16 h-16">
                                    <img
                                      :src="serviceRequest.User.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.User.firstName +
                                        ' ' +
                                        serviceRequest.User.lastName
                                      "
                                    />
                                  </figure>
                                  <div class="ml-3">
                                    <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                      {{ serviceRequest.User.firstName }}
                                      {{ serviceRequest.User.lastName }}
                                    </h6>
                                  </div>
                                </div>
                              </div>

                              <div class="w-full px-2 md:w-1/5 text-center hidden md:block">
                                <img
                                  src="../assets/img/icon-flecha-arrow.svg"
                                  alt=""
                                  class="inline-block"
                                />
                              </div>

                              <div class="w-full px-2 md:w-2/5">
                                <div class="flex items-center">
                                  <figure class="mr-3 avatar w-16 h-16 block md:hidden">
                                    <img
                                      :src="serviceRequest.Professional.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.Professional.firstName +
                                        ' ' +
                                        serviceRequest.Professional.lastName
                                      "
                                    />
                                  </figure>
                                  <div class="ml-0 md:ml-auto">
                                    <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                      {{ serviceRequest.Professional.firstName }}
                                      {{ serviceRequest.Professional.lastName }}
                                    </h6>
                                  </div>
                                  <figure class="ml-3 avatar w-16 h-16 md:block hidden">
                                    <img
                                      :src="serviceRequest.Professional.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.Professional.firstName +
                                        ' ' +
                                        serviceRequest.Professional.lastName
                                      "
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <hr class="mt-3 border-gray-200 block md:hidden" />
                            <hr class="mt-3 border-gray-200 hidden md:block -mx-5" />
                            <div class="-mx-5">
                              <h6 class="my-4 text-black font-semibold text-center block md:hidden">
                                Servicio/s solicitado/s:
                              </h6>
                              <table class="w-full text-left border-collapse p-4 mt-0">
                                <thead class="hidden md:table-header-group">
                                  <tr class="flex border-b border-gray-200 table-row text-black">
                                    <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                                    <th class="px-4 py-3">Precio:</th>
                                    <th class="px-4 py-3 text-right">Cantidad:</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    class="flex border-b border-gray-200 table-row"
                                    v-for="(Service, ServiceIndex) in serviceRequest.Services"
                                    :key="'service_status' + ServiceIndex"
                                  >
                                    <td class="px-2 md:px-4 py-2">
                                      <div class="flex items-center">
                                        <div>
                                          <h6 class="my-0 text-gray-900">
                                            {{ Service.description }}
                                          </h6>
                                          <span class="text-black text-xl block md:hidden">
                                            Gs.
                                            {{ Service.price | thousandSeparator }}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="px-4 py-2 hidden md:table-cell">
                                      <span class="text-black text-xl">
                                        Gs.
                                        {{ Service.price | thousandSeparator }}
                                      </span>
                                    </td>
                                    <td class="px-2 md:px-4 py-2 text-right w-1/2 md:w-auto">
                                      <button
                                        class="justify-center btn icon-text-aligner bg-white hover:bg-white px-3 h-10"
                                        @click="decrement(serviceRequestIndex, ServiceIndex)"
                                        :disabled="Service.quantity == 1"
                                        v-if="serviceRequest.status == 'pending'"
                                      >
                                        <img
                                          src="../assets/img/icon-minus.svg"
                                          class="inline-block py-2"
                                        />
                                      </button>
                                      <span class="text-black text-xl md:mx-4 mx-2">
                                        {{ Service.quantity }}
                                      </span>
                                      <button
                                        v-if="serviceRequest.status == 'pending'"
                                        class="justify-center btn icon-text-aligner bg-white hover:bg-white px-3 h-10"
                                        @click="increment(serviceRequestIndex, ServiceIndex)"
                                      >
                                        <img
                                          src="../assets/img/icon-plus.svg"
                                          class="inline-block"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                  <tr
                                    class="flex border-b border-gray-200 table-row"
                                    v-for="(
                                      extraService, extraServiceIndex
                                    ) in serviceRequest.ExtraServices"
                                    :key="'extra_service_status' + extraServiceIndex"
                                  >
                                    <td class="px-2 md:px-4 py-2">
                                      <div class="flex items-center">
                                        <div>
                                          <h6 class="my-0 text-gray-900">
                                            {{ extraService.description }}
                                          </h6>
                                          <span class="text-black text-xl block md:hidden">
                                            Gs.
                                            {{ extraService.price | thousandSeparator }}
                                          </span>
                                        </div>
                                      </div>
                                    </td>

                                    <td class="px-4 py-2 hidden md:table-cell">
                                      <span class="text-black text-xl">
                                        Gs.
                                        {{ extraService.price | thousandSeparator }}
                                      </span>
                                    </td>
                                    <td class="px-2 md:px-4 py-2 text-right"></td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr class="flex border-b border-gray-200 bg-white table-row">
                                    <td class="px-2 md:px-4 py-2">
                                      <div class="flex items-center">
                                        <div>
                                          <h6 class="my-0 text-black font-bold">Total:</h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="hidden md:table-cell px-4 py-2">
                                      <span class="text-black text-xl font-bold">
                                        Gs.
                                        {{ totalPayment(serviceRequest) | thousandSeparator }}
                                      </span>
                                    </td>
                                    <td class="px-4 py-2 text-right table-cell">
                                      <span class="text-black text-xl font-bold md:hidden">
                                        Gs.
                                        {{ totalPayment(serviceRequest) | thousandSeparator }}
                                      </span>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                            <div
                              class="flex flex-wrap md:flex-row items-center my-4"
                              v-if="serviceRequest.status == 'pending'"
                            >
                              <div class="w-full md:w-auto">
                                <p class="clr-blue text-center md:text-left">
                                  Podes seguir sumando servicios a la lista
                                </p>
                              </div>
                              <div class="w-full md:w-auto text-center md:text-right ml-auto">
                                <button
                                  class="rounded btn--primary lg:w-auto btn btn--md px-4"
                                  @click="
                                    selectServiceRequest(serviceRequestIndex, serviceRequest);
                                    $refs.modalService.openModal();
                                  "
                                >
                                  <span data-v-b0136cd2="" class="justify-between text-base mr-5">
                                    Agregar servicios</span
                                  ><img
                                    src="../assets/img/icon-circle-plus.svg"
                                    class="icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                                  />
                                </button>
                              </div>
                            </div>
                          </section>
                          <hr
                            class="my-3 -mx-5 border-gray-200"
                            v-if="
                              !(
                                serviceRequest.status == 'complete' &&
                                (serviceRequest.paymentType == 'CASH' ||
                                  serviceRequest.paymentType == 'TRANSFER')
                              )
                            "
                          />
                          <section
                            class="my-4 overflow-hidden bg-white"
                            v-if="
                              serviceRequest.status == 'pending' ||
                              serviceRequest.status == 'ongoing'
                            "
                          >
                            <h5 class="font-semibold text-base">
                              <img src="../assets/img/icon-agenda.svg" class="inline-block mr-1" />
                              Fecha y horario del servicio
                            </h5>

                            <div class="flex flex-wrap my-4" style="background-color: #e4e9fb">
                              <div
                                class="w-full px-3 md:px-6 py-3 md:w-1/2 border-b md:border-b-0 md:border-r border-gray-600"
                              >
                                <p class="mb-0">Solicitado/s el:</p>
                                <p class="text-lg mb-4 font-bold text-black capitalize">
                                  {{
                                    moment(serviceRequest.createdOn).format('dddd ,D MMMM, YYYY')
                                  }}
                                  <br />
                                  {{ moment(serviceRequest.createdOn).format('LT') }}
                                  Hs.
                                </p>
                                <button
                                  class="btn w-full bg-white hover:bg-white clr-orange-btn px-2"
                                >
                                  <span class="justify-between text-base">
                                    {{ lapsedCreatedTime(serviceRequest.createdOn) }}</span
                                  >
                                </button>
                              </div>
                              <div class="w-full px-3 md:px-6 py-3 md:w-1/2 border-gray-600">
                                <p class="mb-0">Agendado/s para:</p>
                                <p class="text-lg mb-4 font-bold text-black capitalize">
                                  {{
                                    moment(serviceRequest.serviceScheduledDate).format(
                                      'dddd ,D MMMM, YYYY',
                                    )
                                  }}
                                  <br />
                                  {{ moment(serviceRequest.serviceScheduledDate).format('LT') }}
                                  Hs.
                                </p>
                                <button
                                  class="btn w-full bg-white hover:bg-white clr-blue-btn px-2"
                                >
                                  <span class="justify-between text-base">
                                    {{ lapsedScheduledTime(serviceRequest.serviceScheduledDate) }}
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div
                              class="flex my-4"
                              v-if="
                                serviceRequest.status == 'pending' &&
                                !(
                                  serviceRequest.status == 'complete' &&
                                  (serviceRequest.paymentType == 'CASH' ||
                                    serviceRequest.paymentType == 'TRANSFER')
                                )
                              "
                            >
                              <div
                                class="p-2 w-16 h-auto flex items-center justify-center bg-info-icon-yellow text-center"
                              >
                                <img
                                  src="../assets/img/icon-agenda-black.svg"
                                  class="inline-block"
                                />
                              </div>
                              <div class="w-full p-2 bg-info-content-yellow text-black">
                                El profesional recibirá un pedido de reserva para el día y el
                                horario, en caso de que le quede bien recibirá una confimación, en
                                caso de que no pueda cumplir con el horario le envíara un día y
                                horario alternativo .
                              </div>
                            </div>
                            <div class="flex flex-col items-center lg:flex-row">
                              <div class="w-full text-center md:text-left">
                                <img
                                  src="../assets/img/map.png"
                                  alt="map"
                                  class="w-16 inline mr-2"
                                />
                                <button
                                  class="rounded btn--primary lg:w-auto btn btn--md px-3 py-3"
                                  @click="
                                    selectServiceAdress(serviceRequest);
                                    $refs.modalAdressInfo.openModal();
                                  "
                                >
                                  <svg class="icon text-white icon--align-left mr-auto">
                                    <use
                                      href="../assets/img/icons.svg#icon-pin"
                                      xlink:href="../assets/img/icons.svg#icon-pin"
                                    />
                                  </svg>
                                  <span class="justify-between text-base ml-4">
                                    Ver lugar y dirección</span
                                  >
                                </button>
                              </div>
                            </div>
                          </section>
                          <hr
                            class="my-3 -mx-5 border-gray-200"
                            v-if="serviceRequest.status != 'complete'"
                          />
                          <section
                            class="my-4 overflow-hidden bg-white"
                            v-if="serviceRequest.status != 'complete'"
                          >
                            <h5 class="font-semibold text-base mb-3">
                              <img src="../assets/img/icon-info.svg" class="inline-block mr-1" />
                              Información adicional sobre el pedido:
                            </h5>
                            <section class="flex mb-4 items-center">
                              <figure class="mr-2 bg-white border border-gray-200 avatar w-16 h-16">
                                <img
                                  :src="serviceRequest.User.pictureProfileUrl"
                                  :alt="
                                    serviceRequest.User.firstName +
                                    ' ' +
                                    serviceRequest.User.lastName
                                  "
                                />
                              </figure>
                              <div class="flex-1 bg-white border border-gray-200 rounded">
                                <header
                                  class="flex items-center justify-between font-semibold px-4 py-2"
                                  style="background-color: #f2f5ff"
                                >
                                  <div
                                    class="flex flex-col lg:flex-row text-base text-black capitalize"
                                  >
                                    <span>{{
                                      moment(serviceRequest.createdOn).format('dddd ,D MMMM, YYYY')
                                    }}</span>
                                  </div>
                                </header>
                                <p class="mb-0 p-2 text-black text-lg font-semibold">
                                  {{ serviceRequest.description }}
                                </p>
                              </div>
                            </section>

                            <div
                              v-if="serviceRequest.status != 'cancelled'"
                              class="relative flex flex-col items-center lg:flex-row"
                            >
                              <div class="w-full flex item-center text-right">
                                <router-link
                                  :to="{
                                    name: 'MessageResponder',
                                    params: {
                                      otype: 'servicerequest',
                                      oid: serviceRequest.id,
                                      ostatus: serviceRequest.status,
                                    },
                                  }"
                                  class="rounded btn--secondary lg:w-auto btn btn--md px-3 py-3"
                                >
                                  <img
                                    src="../assets/img/comment-alt-message.svg"
                                    class="icon icon--outline icon--stroke-3 icon--md icon--align-left mr-auto"
                                  />
                                  <span class="justify-between text-base ml-8">
                                    Enviar mensaje</span
                                  >
                                </router-link>
                                <div
                                  @click="cancelServiceRequest(serviceRequest)"
                                  class="rounded btn--danger--outline lg:w-auto btn btn--md px-3 py-3 ml-2"
                                >
                                  Cancelar trabajo
                                </div>
                              </div>
                            </div>
                          </section>
                          <hr
                            class="my-3 -mx-5 border-gray-200"
                            v-if="
                              serviceRequest.status != 'complete' &&
                              serviceRequest.status != 'ongoing' &&
                              serviceRequest.Photos &&
                              serviceRequest.Photos.length > 0
                            "
                          />
                          <section
                            class="my-4 overflow-hidden bg-white"
                            v-if="
                              serviceRequest.status != 'complete' &&
                              serviceRequest.status != 'ongoing' &&
                              serviceRequest.Photos &&
                              serviceRequest.Photos.length > 0
                            "
                          >
                            <p class="text-black text-center">
                              {{ serviceRequest.Photos.length }} imágenes adjuntas para una mejor
                              descripción de lo requerido
                            </p>
                            <div
                              class="grid mb-4 grid-columns md:grid-columns-3 grid-gap-1 p-2 rounded"
                              style="background-color: #dddddd"
                            >
                              <figure
                                class="p-1 border border-gray-200 rounded shadow-sm relative"
                                v-for="Photo in serviceRequest.Photos"
                                :key="'photo_' + Photo.id"
                              >
                                <a :href="Photo.photoUrl" role="button" download>
                                  <img :src="Photo.photoUrl" class="w-full" />
                                </a>
                              </figure>
                            </div>
                          </section>
                          <div class="-mx-5">
                            <div
                              class="w-full bg-brand-secondary text-center text-white btn--md rounded-none"
                              v-if="serviceRequest.status == 'pending'"
                            >
                              Estado pendiente (en espera de que el profesional acepte el trabajo)
                            </div>
                            <div
                              class="w-full bg-brand-primary text-center text-white btn--md rounded-none"
                              v-else-if="serviceRequest.status == 'ongoing'"
                            >
                              Estado trabajo en proceso
                            </div>
                            <div
                              class="w-full bg-brand-secondary text-center text-white btn--md rounded-none"
                              v-else-if="
                                serviceRequest.status == 'complete' &&
                                serviceRequest.paymentType == 'CASH'
                              "
                            >
                              <span v-if="!serviceRequest.paid"
                                >El profesional debe confirmar el pago recibido para que pueda
                                agregar la reseña y calificación del servicio.</span
                              >
                              <span v-if="serviceRequest.paid"
                                >Pago recibido por el profesional</span
                              >
                            </div>
                            <div
                              class="w-full bg-brand-secondary text-center text-white btn--md rounded-none"
                              v-bind:class="{
                                'bg-green-btn': serviceRequest.paid,
                              }"
                              v-else-if="
                                serviceRequest.status == 'complete' &&
                                serviceRequest.paymentType == 'TRANSFER'
                              "
                            >
                              <span v-if="!serviceRequest.paid"
                                >Su transferencia está pendiente de aprobación</span
                              >
                              <span v-if="serviceRequest.paid"
                                >Pago recibido por el profesional</span
                              >
                            </div>
                            <div
                              class="w-full btn--secondary text-center text-white btn--md rounded-none"
                              v-else-if="
                                serviceRequest.status == 'complete' &&
                                serviceRequest.paymentType != 'CASH' &&
                                serviceRequest.paymentType != 'TRANSFER'
                              "
                            >
                              Estado trabajo concretado
                            </div>
                          </div>
                        </section>
                      </div>

                      <footer
                        v-if="serviceRequest.status != 'cancelled'"
                        class="relative flex flex-col items-center px-5 py-4 -mx-5 text-xs bg-gray-100 border-t lg:flex-row bordera-gray-200"
                      >
                        <div class="md:mb-0 mb-4 w-full md:w-2/3">
                          <div
                            v-if="
                              serviceRequest.paymentType != 'CASH' &&
                              serviceRequest.paymentType != 'TRANSFER'
                            "
                          >
                            <span class="pr-1 text-brand-secondary">(*)</span>
                            <span
                              class="text-black text-lg font-semibold"
                              v-if="serviceRequest.status == 'complete'"
                              >Solicitar factura al profesional, con cualquier método de pago.</span
                            >
                            <span
                              class="text-black text-lg font-semibold"
                              v-if="serviceRequest.status == 'ongoing'"
                              >Concluido el trabajo se habilitará el botón de pago</span
                            >
                            <span
                              class="text-black text-lg font-semibold"
                              v-if="serviceRequest.status == 'pending'"
                              >Aceptada la solicitud le llegará una notificación confirmando el
                              trabajo</span
                            >
                          </div>
                        </div>
                        <div
                          class="flex-none w-full lg:relative md:w-1/3 text-center md:text-right"
                        >
                          <a
                            href="javascript:void(0);"
                            class="rounded lg:w-auto btn btn--md px-8 btn--disabled lg:rounded btn--disabled"
                            v-if="
                              serviceRequest.status == 'pending' ||
                              serviceRequest.status == 'ongoing'
                            "
                            >Realizar pago</a
                          >
                          <button
                            class="w-full rounded-none lg:w-auto btn btn--md lg:rounded bg-brand-secondary"
                            v-bind:class="{
                              'bg-green-btn': serviceRequest.paid,
                            }"
                            v-else-if="
                              serviceRequest.status == 'complete' &&
                              (serviceRequest.paymentType == 'CASH' ||
                                serviceRequest.paymentType == 'TRANSFER')
                            "
                            @click="requestReview(serviceRequest)"
                            :disabled="!serviceRequest.paid"
                          >
                            Agregar Reseña
                          </button>
                          <button
                            class="w-full rounded-none lg:w-auto btn btn--md lg:rounded bg-green-btn"
                            v-else-if="
                              serviceRequest.status == 'complete' &&
                              serviceRequest.paymentType != 'CASH' &&
                              serviceRequest.paymentType != 'TRANSFER'
                            "
                            @click="showPaymentPage(serviceRequest)"
                          >
                            Realizar pago
                          </button>
                        </div>
                      </footer>
                    </div>
                    <!-- paid and reiewd content start-->
                    <div
                      class="flex flex-col justify-between lg:flex-row"
                      v-else-if="serviceRequest.paid == true && serviceRequest.reviewed == true"
                    >
                      <section class="w-full">
                        <section class="my-4 bg-white">
                          <div class="">
                            <h6 class="my-4 text-black text-xl font-semibold text-center">
                              Resumen de servicios
                            </h6>
                            <div class="flex flex-wrap">
                              <div class="w-full px-2 md:w-2/5 hidden md:block">
                                <div class="flex items-center">
                                  <figure class="avatar w-16 h-16">
                                    <img
                                      :src="serviceRequest.User.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.User.firstName +
                                        ' ' +
                                        serviceRequest.User.lastName
                                      "
                                    />
                                  </figure>
                                  <div class="ml-3">
                                    <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                      {{ serviceRequest.User.firstName }}
                                      {{ serviceRequest.User.lastName }}
                                    </h6>
                                  </div>
                                </div>
                              </div>

                              <div class="w-full px-2 md:w-1/5 text-center hidden md:block">
                                <img
                                  src="../assets/img/icon-flecha-arrow.svg"
                                  alt=""
                                  class="inline-block"
                                />
                              </div>

                              <div class="w-full px-2 md:w-2/5">
                                <div class="flex items-center">
                                  <figure class="mr-3 avatar w-16 h-16 block md:hidden">
                                    <img
                                      :src="serviceRequest.Professional.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.Professional.firstName +
                                        ' ' +
                                        serviceRequest.Professional.lastName
                                      "
                                    />
                                  </figure>
                                  <div class="ml-0 md:ml-auto">
                                    <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                      {{ serviceRequest.Professional.firstName }}
                                      {{ serviceRequest.Professional.lastName }}
                                    </h6>
                                  </div>
                                  <figure class="ml-3 avatar w-16 h-16 md:block hidden">
                                    <img
                                      :src="serviceRequest.Professional.pictureProfileUrl"
                                      :alt="
                                        serviceRequest.Professional.firstName +
                                        ' ' +
                                        serviceRequest.Professional.lastName
                                      "
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>

                            <table class="w-full text-left border-collapse p-4 mt-1">
                              <thead>
                                <tr
                                  class="flex border-b border-t border-gray-200 table-row text-black"
                                >
                                  <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                                  <th class="px-4 py-3 text-right">Precio:</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="flex border-b border-gray-200 table-row"
                                  style="background-color: #fff"
                                  v-for="(Service, index) in serviceRequest.Services"
                                  :key="'service_status_paid_' + index"
                                >
                                  <td class="px-2 md:px-4 py-2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black flex">
                                          <span class="w-8 mr-2"> {{ Service.quantity }}x</span
                                          >{{ Service.description }}
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl">
                                      Gs.
                                      {{ Service.price | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  class="flex border-b border-gray-200 table-row"
                                  style="background-color: #fff"
                                  v-for="(Item, index) in serviceRequest.ExtraServices"
                                  :key="'extraservice_thankyou' + index"
                                >
                                  <td class="px-2 md:px-4 py-2 w-1/2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black">
                                          {{ Item.description }}
                                        </h6>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl">
                                      Gs. {{ Item.price | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr class="flex table-row">
                                  <td class="px-2 md:px-4 py-2">
                                    <div class="flex items-center">
                                      <div>
                                        <h6 class="my-0 text-black font-bold">Total:</h6>
                                      </div>
                                    </div>
                                  </td>

                                  <td class="px-4 py-2 text-right">
                                    <span class="text-black text-xl font-bold">
                                      Gs.
                                      {{ totalPayment(serviceRequest) | thousandSeparator }}
                                    </span>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </section>
                      </section>
                    </div>
                    <!-- paid and reviewd content end-->
                    <!-- paid but not reviewed content start-->
                    <div
                      v-else-if="serviceRequest.paid == true && serviceRequest.reviewed == false"
                    >
                      <section class="p-4">
                        <div class="flex justify-center items-center">
                          <section class="w-full md:w-1/2 md:px-5">
                            <h5 class="font-semibold text-2xl text-center">
                              Calificar profesional
                            </h5>
                            <div class="flex justify-center mt-12">
                              <article class="px-16 bg-white shadow-xl rounded-lg">
                                <div class="flex">
                                  <div class="flex-1">
                                    <div class="flex flex-col items-center text-center">
                                      <figure class="overflow-hidden avatar w-16 h-16 -mt-6">
                                        <img
                                          :src="serviceRequest.Professional.pictureProfileUrl"
                                          :alt="
                                            serviceRequest.Professional.firstName +
                                            ' ' +
                                            serviceRequest.Professional.lastName
                                          "
                                        />
                                      </figure>
                                      <div
                                        class="font-bold text-black text-2xl"
                                        style="color: #686b7b"
                                      >
                                        4/5
                                      </div>
                                      <div class="flex items-center leading-none md:flex-row mb-2">
                                        <svg class="text-yellow-500 icon">
                                          <use
                                            href="../assets/img/icons.svg#icon-star-rate"
                                            xlink:href="../assets/img/icons.svg#icon-star-rate"
                                          ></use>
                                        </svg>
                                        <svg class="text-yellow-500 icon">
                                          <use
                                            href="../assets/img/icons.svg#icon-star-rate"
                                            xlink:href="../assets/img/icons.svg#icon-star-rate"
                                          ></use>
                                        </svg>
                                        <svg class="text-yellow-500 icon">
                                          <use
                                            href="../assets/img/icons.svg#icon-star-rate"
                                            xlink:href="../assets/img/icons.svg#icon-star-rate"
                                          ></use>
                                        </svg>
                                        <svg class="text-yellow-500 icon">
                                          <use
                                            href="../assets/img/icons.svg#icon-star-rate"
                                            xlink:href="../assets/img/icons.svg#icon-star-rate"
                                          ></use>
                                        </svg>
                                        <svg class="text-yellow-500 icon">
                                          <use
                                            href="../assets/img/icons.svg#icon-star-rate"
                                            xlink:href="../assets/img/icons.svg#icon-star-rate"
                                          ></use>
                                        </svg>
                                      </div>
                                    </div>
                                    <p class="text-black mb-3 text-center">
                                      {{ serviceRequest.Professional.firstName }}
                                      {{ serviceRequest.Professional.lastName }}
                                    </p>
                                  </div>
                                </div>
                              </article>
                            </div>
                            <div class="flex justify-center items-center text-center mt-8">
                              <div class="w-full">
                                <p class="text-black text-xl text-center font-semibold mb-8">
                                  De 5 estrellas como excelente, ¿cuantas estrellas le darías al
                                  trabajo?
                                </p>

                                <div
                                  class="flex items-center justify-center leading-none md:flex-row mb-2"
                                >
                                  <span class="my-rating" @mouseleave="showCurrentRating(0)">
                                    <star-rating
                                      v-model="reviewdata.rate"
                                      inactive-color="#dfe0e6"
                                      :show-rating="false"
                                      active-color="#ffd83e"
                                      :increment="0.5"
                                      :star-size="30"
                                      @current-rating="showCurrentRating"
                                      @rating-selected="setCurrentSelectedRating"
                                    ></star-rating>
                                  </span>
                                </div>
                                <div class="flex justify-center items-center mb-4">
                                  <span class="error" v-if="submited && !reviewdata.rate > 0"
                                    >Favor agregue una reseña</span
                                  >
                                </div>
                                <div class="font-bold text-2xl" style="color: #686b7b">
                                  {{ currentRating }}/5
                                </div>
                                <p class="text-black text-center mb-8">
                                  Toca la cantidad de estrellas con la cual calificas al
                                  profesional, 5 es excelente, 1 deficiente.
                                </p>
                                <div class="md:px-8">
                                  <div class="mb-6">
                                    <label class="block">
                                      <span class="font-normal">Comentarios</span>
                                      <textarea
                                        class="block w-full h-40 mt-3 form-input text-black"
                                        placeholder="Hay algo que te gusto del trabajo, hay algo que no?, ¡contanos tu experiencia aquí!"
                                        v-model="reviewdata.description"
                                        v-bind:class="{
                                          'bg-red-100 border-red-700 OnErrorScrollHere':
                                            submited && !reviewdata.description,
                                        }"
                                      ></textarea>
                                      <span class="error" v-if="submited && !reviewdata.description"
                                        >Por favor ingrese un comentario</span
                                      >
                                    </label>
                                  </div>
                                  <button
                                    class="w-full btn btn--success py-4 mb-8"
                                    @click="validateRating(serviceRequest, false)"
                                    :disabled="processingreview"
                                  >
                                    Calificar
                                  </button>
                                  <p class="text-black text-center text-sm">
                                    *Los comentarios serán publicados si se ajustan a los términos y
                                    condiciones
                                  </p>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </section>
                    </div>
                    <!-- paid but not reviewed content End-->
                  </div>
                </div>
              </div>
              <div class="container msg-section" v-else>
                <section
                  class="flex flex-col items-center md:flex-row alert alert--success shadow alert--info"
                >
                  <div class="md:pr-6 mb-2">
                    <svg class="text-6xl text-blue-800 icon md:text-5xl">
                      <use
                        href="../assets/img/icons.svg#icon-alert-circle-i"
                        xlink:href="../assets/img/icons.svg#icon-alert-circle-i"
                      />
                    </svg>
                  </div>
                  <div class="text-center md:text-left">
                    <p class="text-sm">No hay ordenes de trabajo que mostrar.</p>
                  </div>
                </section>
              </div>
            </div>
            <!-- Payment Page Start -->
            <div v-if="paymentData.isPaymentPageShow && paymentData.serviceRequest">
              <div class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded">
                <header
                  :id="'service_' + paymentData.serviceRequest.id"
                  class="items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white md:border-b border-gray-200"
                  v-if="paymentData.isPaymentPageShow"
                >
                  <div class="flex flex-wrap text-center md:text-left">
                    <div class="w-auto text-base text-black font-semibold">
                      <p class="mb-2 md:mb-0">
                        Estado:
                        <span
                          class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary font-bold success"
                          v-if="paymentData.serviceRequest.status == 'complete'"
                          >Concretado
                        </span>
                      </p>
                    </div>
                    <div class="w-full md:w-auto text-center md:text-right md:ml-auto text-black">
                      <span>Orden: {{ paymentData.serviceRequest.id }}</span>
                    </div>
                  </div>
                </header>
                <hr class="mt-3 border-gray-200 block md:hidden" />
                <!-- step1 -->
                <div
                  class="flex flex-col justify-between lg:flex-row"
                  v-if="paymentData.isPaymentPageShow && paymentData.paymentStep == 1"
                >
                  <section class="w-full">
                    <section class="my-4 bg-white">
                      <hr
                        class="mt-3 border-gray-200 hidden md:block -mx-5"
                        v-if="paymentData.serviceRequest.status != 'complete'"
                      />
                      <div class="-mx-5">
                        <h6 class="my-4 text-black font-semibold text-center block md:hidden">
                          Servicio/s solicitado/s:
                        </h6>
                        <table class="w-full text-left border-collapse p-4 mt-0">
                          <thead class="hidden md:table-header-group">
                            <tr class="flex border-b border-gray-200 table-row text-black">
                              <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                              <th class="px-4 py-3 text-right">Precio:</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="flex border-b border-gray-200 table-row"
                              style="background-color: #f9fafc"
                              v-for="(Service, index) in paymentData.serviceRequest.Services"
                              :key="'payment_step_1_' + index"
                            >
                              <td class="px-2 md:px-4 py-2">
                                <div class="flex items-center">
                                  <div>
                                    <h6 class="my-0 text-black">
                                      {{ Service.quantity }}x &nbsp;
                                      {{ Service.description }}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-2 text-right">
                                <span class="text-black text-xl">
                                  Gs. {{ Service.price | thousandSeparator }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="w-full text-left border-collapse p-4 mt-0">
                          <tbody>
                            <tr
                              class="flex border-b border-gray-200 table-row"
                              style="background-color: #e4e9fb"
                              v-for="(Item, key) in paymentData.serviceRequest.ExtraServices"
                              :key="'ExtraServices_Step_1' + key"
                            >
                              <td class="px-2 md:px-4 py-2 w-1/2">
                                <div class="flex items-center">
                                  <div>
                                    <h6 class="my-0 text-black">
                                      {{ Item.description }}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-2 text-right">
                                <span class="text-black text-xl">
                                  Gs. {{ Item.price | thousandSeparator }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4 border-b border-gray-200"
                          style="background-color: #fff"
                        >
                          <div
                            class="px-2 md:px-4 py-2 flex items-center md:justify-start justify-center"
                          >
                            <h6 class="my-0 text-black">Ingresa tu código de descuento aquí</h6>
                          </div>
                          <div class="px-2 md:px-4 py-2 md:text-right">
                            <div class="flex md:justify-end text-lg md:flex-row">
                              <input
                                type="text"
                                placeholder="Ingresa aquí el código"
                                class="text-gray-800 placeholder-gray-800 form-input w-4/5 md:mb-0 h-auto"
                              />

                              <button
                                class="justify-center font-semibold ml-3 w-12 h-auto icon-text-aligner"
                              >
                                <img
                                  src="../assets/img/icon-flecha-right.svg"
                                  class="inline-block"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <table class="w-full text-left border-collapse p-4 mt-0">
                          <tbody></tbody>
                          <tfoot>
                            <tr
                              class="flex border-b border-gray-200 table-row"
                              style="background-color: #e4e9fb"
                            >
                              <td class="px-2 md:px-4 py-2">
                                <div class="flex items-center">
                                  <div>
                                    <h6 class="my-0 text-black">Total a pagar:</h6>
                                  </div>
                                </div>
                              </td>

                              <td class="px-4 py-2 text-right">
                                <span class="text-black text-xl">
                                  Gs.
                                  {{ totalPayment(paymentData.serviceRequest) | thousandSeparator }}
                                </span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div class="flex flex-wrap my-4">
                        <div class="w-full px-2 md:w-2/5 hidden md:block">
                          <div class="flex items-center">
                            <figure class="avatar w-16 h-16">
                              <img
                                :src="paymentData.serviceRequest.User.pictureProfileUrl"
                                :alt="
                                  paymentData.serviceRequest.User.firstName +
                                  ' ' +
                                  paymentData.serviceRequest.User.lastName
                                "
                              />
                            </figure>
                            <div class="ml-3">
                              <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                {{ paymentData.serviceRequest.User.firstName }}
                                {{ paymentData.serviceRequest.User.lastName }}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div class="w-full px-2 md:w-1/5 text-center hidden md:block">
                          <img
                            src="../assets/img/icon-flecha-arrow.svg"
                            alt=""
                            class="inline-block"
                          />
                        </div>

                        <div class="w-full px-2 md:w-2/5">
                          <div class="flex items-center">
                            <figure class="mr-3 avatar w-16 h-16 block md:hidden">
                              <img
                                :src="paymentData.serviceRequest.Professional.pictureProfileUrl"
                                :alt="
                                  paymentData.serviceRequest.Professional.firstName +
                                  ' ' +
                                  paymentData.serviceRequest.Professional.lastName
                                "
                              />
                            </figure>
                            <div class="ml-0 md:ml-auto">
                              <h6 class="my-0 text-gray-900 whitespace-no-wrap">
                                {{ paymentData.serviceRequest.Professional.firstName }}
                                {{ paymentData.serviceRequest.Professional.lastName }}
                              </h6>
                            </div>
                            <figure class="ml-3 avatar w-16 h-16 md:block hidden">
                              <img
                                :src="paymentData.serviceRequest.Professional.pictureProfileUrl"
                                :alt="
                                  paymentData.serviceRequest.Professional.firstName +
                                  ' ' +
                                  paymentData.serviceRequest.Professional.lastName
                                "
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <hr class="mt-3 border-gray-200 -mx-5" />
                      <div class="-mt-5 w-40 mx-auto bg-white">
                        <h5 class="font-semibold text-base text-center">
                          Pagar <br />
                          servicios prestados
                        </h5>
                      </div>
                      <div class="flex flex-wrap md:flex-row items-center justify-center my-8">
                        <div class="w-4/5 md:w-full items-center justify-center md:px-0 mb-8">
                          <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-2 xl:gap-4">
                            <div>
                              <p class="text-gray-900 text-center font-semibold xl-h-auto">
                                Pago online
                              </p>
                              <button
                                class="rounded btn--primary w-full btn btn--md px-5 py-4"
                                @click="createPayment(paymentData.serviceRequest.id)"
                                :disabled="processingServiceRequests"
                              >
                                <span data-v-b0136cd2="" class="justify-between text-xl">
                                  Tarjeta online</span
                                ><img
                                  src="../assets/img/icon-tarjeta.svg"
                                  class="icon--outline icon--stroke-3 icon--align-right ml-auto h-8"
                                />
                              </button>
                            </div>
                            <div>
                              <p class="text-gray-900 text-center font-semibold xl-h-auto">
                                Pago en efectivo al profesional
                              </p>
                              <button
                                class="rounded btn--primary w-full btn btn--md px-5 py-4"
                                @click="
                                  cashPayment(paymentData.serviceRequest.id, {
                                    paymentType: 'CASH',
                                  })
                                "
                                :disabled="processingServiceRequests"
                              >
                                <span data-v-b0136cd2="" class="justify-between text-xl">
                                  Efectivo</span
                                ><img
                                  src="../assets/img/icon-efectivo.svg"
                                  class="icon--outline icon--stroke-3 icon--align-right ml-auto h-8"
                                />
                              </button>
                            </div>
                            <div>
                              <p class="text-gray-900 text-center font-semibold xl-h-auto">
                                Transferencia bancaria
                              </p>
                              <button
                                class="rounded btn--primary w-full btn btn--md px-5 py-4"
                                @click="$refs.manualTransfer.openModal()"
                                :disabled="processingServiceRequests"
                              >
                                <span data-v-b0136cd2="" class="justify-between text-xl">
                                  Transferencia</span
                                ><img
                                  src="../assets/img/icon-transferencia.svg"
                                  class="icon--outline icon--stroke-3 icon--align-right ml-auto h-8"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="w-full md:px-5">
                          <div class="mr-0 text-center">
                            <img
                              class="mr-4 my-2 inline-block"
                              src="../assets/img/bancard-pago-seguro-small.svg"
                              alt="bancard"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/Visa-small.svg"
                              alt="Perfil"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/mastercard-small.svg"
                              alt="mastercard"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/maestro-small.svg"
                              alt="maestro"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/American-Express-small.svg"
                              alt="American Express"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/Diners-club-small.svg"
                              alt="Diners club"
                            /><br class="hidden md:block" />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/pago-movil-small.svg"
                              alt="pago movil"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/Transferencia-bancariaa-small.svg"
                              alt="Transferencia bancariaa-bancariaail"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/logo-tigo-money-small.svg"
                              alt="Transferencia bancariaa-bancariaail"
                            />
                            <img
                              class="mr-2 my-2 inline-block"
                              src="../assets/img/medio-billetera-personal-small.svg"
                              alt="medio billetera personal"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col md:flex-row items-center justify-center my-6">
                        <div class="w-16 h-auto flex justify-center text-center">
                          <img src="../assets/img/Icon-seguridad.svg" class="inline-block" />
                        </div>
                        <div
                          class="w-auto px-2 text-xl clr-green text-center md:text-left leading-tight"
                        >
                          Todas las transacciones <br />
                          son seguras, ¡podes confiar!
                        </div>
                      </div>
                      <div class="flex justify-center mt-12">
                        <article class="px-16 bg-white shadow-xl rounded-lg">
                          <div class="flex">
                            <div class="flex-1">
                              <div class="flex flex-col items-center text-center">
                                <figure class="overflow-hidden avatar w-16 h-16 -mt-6">
                                  <img
                                    :src="paymentData.serviceRequest.Professional.pictureProfileUrl"
                                    :alt="
                                      paymentData.serviceRequest.Professional.firstName +
                                      ' ' +
                                      paymentData.serviceRequest.Professional.lastName
                                    "
                                  />
                                </figure>
                                <div class="font-bold text-black">4/5</div>
                                <div class="flex items-center leading-none md:flex-row mb-2">
                                  <svg class="text-yellow-500 icon">
                                    <use
                                      href="../assets/img/icons.svg#icon-star-rate"
                                      xlink:href="../assets/img/icons.svg#icon-star-rate"
                                    ></use>
                                  </svg>
                                  <svg class="text-yellow-500 icon">
                                    <use
                                      href="../assets/img/icons.svg#icon-star-rate"
                                      xlink:href="../assets/img/icons.svg#icon-star-rate"
                                    ></use>
                                  </svg>
                                  <svg class="text-yellow-500 icon">
                                    <use
                                      href="../assets/img/icons.svg#icon-star-rate"
                                      xlink:href="../assets/img/icons.svg#icon-star-rate"
                                    ></use>
                                  </svg>
                                  <svg class="text-yellow-500 icon">
                                    <use
                                      href="../assets/img/icons.svg#icon-star-rate"
                                      xlink:href="../assets/img/icons.svg#icon-star-rate"
                                    ></use>
                                  </svg>
                                  <svg class="text-yellow-500 icon">
                                    <use
                                      href="../assets/img/icons.svg#icon-star-rate"
                                      xlink:href="../assets/img/icons.svg#icon-star-rate"
                                    ></use>
                                  </svg>
                                </div>
                              </div>
                              <p class="text-black mb-3">
                                {{ paymentData.serviceRequest.Professional.firstName }}
                                {{ paymentData.serviceRequest.Professional.lastName }}
                              </p>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div class="w-full mt-2">
                        <h5 class="font-semibold text-base text-center">
                          Después podrás <br />
                          calificar al profesional
                        </h5>
                      </div>
                    </section>

                    <div class="-mx-5">
                      <a href="#" class="w-full btn--pending btn btn--md rounded-none py-3"
                        >Trabajo realizado (pago pendiente)</a
                      >
                    </div>
                  </section>
                </div>
                <!-- step1 End -->
                <!-- step 3 -->
                <div v-if="paymentData.isPaymentPageShow && paymentData.paymentStep == 3">
                  <section class="p-4">
                    <div class="flex justify-center items-center">
                      <section class="w-full md:w-1/2 md:px-5">
                        <h5 class="font-semibold text-2xl text-center">Calificar profesional</h5>
                        <div class="flex justify-center mt-12">
                          <article class="px-16 bg-white shadow-xl rounded-lg">
                            <div class="flex">
                              <div class="flex-1">
                                <div class="flex flex-col items-center text-center">
                                  <figure class="overflow-hidden avatar w-16 h-16 -mt-6">
                                    <img
                                      :src="
                                        paymentData.serviceRequest.Professional.pictureProfileUrl
                                      "
                                      :alt="
                                        paymentData.serviceRequest.Professional.firstName +
                                        ' ' +
                                        paymentData.serviceRequest.Professional.lastName
                                      "
                                    />
                                  </figure>
                                  <div class="font-bold text-black text-2xl" style="color: #686b7b">
                                    4/5
                                  </div>
                                  <div class="flex items-center leading-none md:flex-row mb-2">
                                    <svg class="text-yellow-500 icon">
                                      <use
                                        href="../assets/img/icons.svg#icon-star-rate"
                                        xlink:href="../assets/img/icons.svg#icon-star-rate"
                                      ></use>
                                    </svg>
                                    <svg class="text-yellow-500 icon">
                                      <use
                                        href="../assets/img/icons.svg#icon-star-rate"
                                        xlink:href="../assets/img/icons.svg#icon-star-rate"
                                      ></use>
                                    </svg>
                                    <svg class="text-yellow-500 icon">
                                      <use
                                        href="../assets/img/icons.svg#icon-star-rate"
                                        xlink:href="../assets/img/icons.svg#icon-star-rate"
                                      ></use>
                                    </svg>
                                    <svg class="text-yellow-500 icon">
                                      <use
                                        href="../assets/img/icons.svg#icon-star-rate"
                                        xlink:href="../assets/img/icons.svg#icon-star-rate"
                                      ></use>
                                    </svg>
                                    <svg class="text-yellow-500 icon">
                                      <use
                                        href="../assets/img/icons.svg#icon-star-rate"
                                        xlink:href="../assets/img/icons.svg#icon-star-rate"
                                      ></use>
                                    </svg>
                                  </div>
                                </div>
                                <p class="text-black mb-3 text-center">
                                  {{ paymentData.serviceRequest.Professional.firstName }}
                                  {{ paymentData.serviceRequest.Professional.lastName }}
                                </p>
                              </div>
                            </div>
                          </article>
                        </div>
                        <div class="flex justify-center items-center text-center mt-8">
                          <div class="w-full">
                            <p class="text-black text-xl text-center font-semibold mb-8">
                              De 5 estrellas como excelente, ¿cuantas estrellas le darías al
                              trabajo?
                            </p>

                            <div
                              class="flex items-center justify-center leading-none md:flex-row mb-2"
                            >
                              <span class="my-rating" @mouseleave="showCurrentRating(0)">
                                <star-rating
                                  v-model="reviewdata.rate"
                                  inactive-color="#dfe0e6"
                                  :show-rating="false"
                                  active-color="#ffd83e"
                                  :increment="0.5"
                                  :star-size="30"
                                  @current-rating="showCurrentRating"
                                  @rating-selected="setCurrentSelectedRating"
                                ></star-rating>
                              </span>
                            </div>
                            <div class="flex justify-center items-center mb-4">
                              <span class="error" v-if="submited && !reviewdata.rate > 0"
                                >Favor agregue una reseña</span
                              >
                            </div>
                            <div class="font-bold text-2xl" style="color: #686b7b">
                              {{ currentRating }}/5
                            </div>
                            <p class="text-black text-center mb-8">
                              Toca la cantidad de estrellas con la cual calificas al profesional, 5
                              es excelente, 1 deficiente.
                            </p>
                            <div class="md:px-8">
                              <div class="mb-6">
                                <label class="block">
                                  <span class="font-normal">Comentarios</span>
                                  <textarea
                                    class="block w-full h-40 mt-3 form-input text-black"
                                    placeholder="Hay algo que te gusto del trabajo, hay algo que no?, ¡contanos tu experiencia aquí!"
                                    v-model="reviewdata.description"
                                    v-bind:class="{
                                      'bg-red-100 border-red-700 OnErrorScrollHere':
                                        submited && !reviewdata.description,
                                    }"
                                  ></textarea>
                                  <span class="error" v-if="submited && !reviewdata.description"
                                    >Por favor ingrese un comentario</span
                                  >
                                </label>
                              </div>
                              <button
                                class="w-full btn btn--success py-4 mb-8"
                                @click="validateRating(paymentData.serviceRequest, true)"
                                :disabled="processingreview"
                              >
                                Agregar reseña
                              </button>
                              <p class="text-black text-center">
                                *Los comentarios serán publicados si se ajustan a los términos y
                                condiciones
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </section>
                </div>
                <!-- step 3 End -->
                <!-- step 4 -->
                <div
                  class="flex flex-col justify-between lg:flex-row"
                  v-if="paymentData.isPaymentPageShow && paymentData.paymentStep == 4"
                >
                  <section class="w-full">
                    <div class="w-full text-center items-center my-4">
                      <h5 class="mb-4 text-brand-primary text-3xl">
                        ¡Muchas gracias<br />
                        por usar Hand!
                      </h5>
                      <figure
                        class="flex items-center justify-center w-48 h-48 mx-auto mb-6 border rounded-full shadow"
                      >
                        <img src="../assets/img/img-2.png" class="w-48 h-48 rounded-full" />
                      </figure>
                    </div>
                    <section class="my-4 bg-white">
                      <div class="">
                        <h6 class="my-4 text-black text-xl font-semibold text-center">
                          Resumen de servicios
                        </h6>
                        <table class="w-full text-left border-collapse p-4 mt-0">
                          <thead>
                            <tr class="flex border-b border-t border-gray-200 table-row text-black">
                              <th class="px-4 py-3">Servicio/s solicitado/s:</th>
                              <th class="px-4 py-3 text-right">Precio:</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="flex border-b border-gray-200 table-row"
                              style="background-color: #fff"
                              v-for="(Service, index) in paymentData.serviceRequest.Services"
                              :key="'payment_step_4_' + index"
                            >
                              <td class="px-2 md:px-4 py-2">
                                <div class="flex items-center">
                                  <div>
                                    <h6 class="my-0 text-black flex">
                                      <span class="w-8 mr-2"> {{ Service.quantity }}x</span
                                      >{{ Service.description }}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-2 text-right">
                                <span class="text-black text-xl">
                                  Gs. {{ Service.price | thousandSeparator }}
                                </span>
                              </td>
                            </tr>
                            <tr
                              class="flex border-b border-gray-200 table-row"
                              style="background-color: #fff"
                              v-for="(Item, index) in paymentData.serviceRequest.ExtraServices"
                              :key="'extraservice_thankyou_pay' + index"
                            >
                              <td class="px-2 md:px-4 py-2 w-1/2">
                                <div class="flex items-center">
                                  <div>
                                    <h6 class="my-0 text-black">
                                      {{ Item.description }}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="px-4 py-2 text-right">
                                <span class="text-black text-xl">
                                  Gs. {{ Item.price | thousandSeparator }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr class="flex table-row">
                              <td class="px-2 md:px-4 py-2">
                                <div class="flex items-center">
                                  <div>
                                    <h6 class="my-0 text-black font-bold">Total:</h6>
                                  </div>
                                </div>
                              </td>

                              <td class="px-4 py-2 text-right">
                                <span class="text-black text-xl font-bold">
                                  Gs.
                                  {{ totalPayment(paymentData.serviceRequest) | thousandSeparator }}
                                </span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </section>
                  </section>
                </div>
                <!-- step 4 End -->
              </div>
              <!-- thank you page footer -->
              <div
                class="container px-5 container-lg mb-4"
                v-if="paymentData.isPaymentPageShow && paymentData.paymentStep == 4"
              >
                <div class="flex justify-center items-center">
                  <div class="w-full md:w-2/5">
                    <h6 class="my-4 text-black font-semibold text-center">
                      ¿Necesitas ayuda con alguna otra tarea?
                    </h6>
                    <router-link to="/" class="w-full font-semibold btn btn--primary mb-8">
                      ver más profesiones
                    </router-link>
                    <p class="text-black font-semibold text-center mb-6">
                      Para ver siempre todas nuestras novedades seguínos en nuestras redes sociales,
                      donde también estamos para servirte
                    </p>
                    <nav>
                      <ul class="flex justify-center">
                        <li>
                          <a class="flex items-center justify-center w-10 h-10 mr-10" href="#">
                            <img
                              src="../assets/img/Icon-Facebook-footer.svg"
                              class="inline-block"
                            />
                          </a>
                        </li>
                        <li>
                          <a class="flex items-center justify-center w-10 h-10 mr-10" href="#">
                            <img src="../assets/img/Icon-Twitter.svg" class="inline-block" />
                          </a>
                        </li>
                        <li>
                          <a class="flex items-center justify-center w-10 h-10 mr-10" href="#">
                            <img src="../assets/img/Icon-Instagram.svg" class="inline-block" />
                          </a>
                        </li>
                        <li>
                          <a class="flex items-center justify-center w-10 h-10" href="#">
                            <img src="../assets/img/Icon-Youtube.svg" class="inline-block" />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <!-- thank you page footer end -->
            </div>
            <!-- Payment Page End -->
          </section>
        </div>
      </div>
    </section>
    <!-- modal start-->
    <div class="modal-adress-info">
      <modal ref="modalAdressInfo">
        <template v-slot:header>
          <h1></h1>
        </template>
        <template v-slot:body>
          <section class="p-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded">
            <h5 class="p-4 mt-0 -mx-5 -mt-5 font-semibold bg-gray-100 border-b border-gray-200">
              <img src="../assets/img/icon-pin.svg" class="inline-block mr-1" />
              Lugar a realizar el servicio
            </h5>
            <div class="flex mt-4 items-center justify-center">
              <div class="w-full">
                <div class="bg-gray-100 p-3 rounded">
                  <div class="grid grid-cols-1 lg:grid-cols-2 lg:grid-gap-4">
                    <div>
                      <label>
                        <span>Departamento</span>

                        <select
                          class="block w-full mt-1 rounded form-select"
                          v-model="selectedServiceAdress.department"
                          disabled
                        >
                          <option disabled v-if="activeDepartments.length <= 0" :value="null">
                            Departamento no encontrado
                          </option>
                          <option disabled v-else :value="null">Seleccionar Departmento</option>
                          <option
                            v-for="department in activeDepartments"
                            :key="department.id"
                            :value="department.id"
                          >
                            {{ department.description }}
                          </option>
                        </select>
                      </label>
                    </div>
                    <div>
                      <label>
                        <span>Ciudad</span>
                        <select
                          class="block w-full mt-1 rounded form-select"
                          v-model="selectedServiceAdress.location_id"
                          disabled
                        >
                          <option
                            disabled
                            v-if="activeDepartmentLocations.length <= 0"
                            :value="null"
                          >
                            Ciudad no encontrada
                          </option>
                          <option disabled v-else :value="null">Seleccione Ciudad</option>
                          <option
                            v-for="departmentLocation in activeDepartmentLocations"
                            :key="departmentLocation.id"
                            :value="departmentLocation.id"
                          >
                            {{ departmentLocation.description }}
                          </option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="bg-gray-100 p-3 rounded">
                  <label>
                    <span>Dirección</span>
                    <textarea
                      v-model="selectedServiceAdress.direction"
                      class="block w-full h-32 mx-auto form-control height-textarea"
                      placeholder="Ingresa la dirección de tu casa aquí y alguna referencia para mejor ubicación"
                      readonly
                    ></textarea>
                  </label>

                  <label>
                    <span>Mapa</span>
                    <section class="relative my-8 border border-gray-200 rounded md:px-0">
                      <div style="width: 100%; height: 300px">
                        <l-map :zoom="zoom" :center="markerLatLng" ref="mymap">
                          <l-tile-layer :url="url"></l-tile-layer>
                          <l-circle :lat-lng="circle.center" :radius="circle.radius" />
                          <l-marker :lat-lng="markerLatLng" :draggable="false" ref="marker">
                          </l-marker>
                        </l-map>
                      </div>
                    </section>
                  </label>
                </div>
              </div>
            </div>
          </section>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
    <!-- modal start-->
    <modal ref="modalService">
      <template v-slot:header>
        <h1></h1>
      </template>
      <template v-slot:body>
        <section class="w-full professional-profile">
          <div class="flex flex-col md:flex-row">
            <div class="w-full md:w-2/3">
              <h4>
                <svg class="-mt-2 icon">
                  <use
                    href="../assets/img/icons.svg#icon-todo"
                    xlink:href="img/icons.svg#icon-todo"
                  />
                </svg>
                Servicios ofrecidos
              </h4>
            </div>
            <div class="w-full md:w-1/3">
              <div class="flex items-stretch mb-4 text-lg shadow-xl md:flex-row">
                <input
                  type="text"
                  placeholder="Filtrar servicios"
                  class="text-gray-800 placeholder-gray-800 form-input w-4/5 md:mb-0 rounded-r-none h-auto"
                  v-model="searchQuery"
                />

                <button
                  class="justify-center font-semibold btn btn--primary w-1/5 h-auto rounded-l-none icon-text-aligner"
                  @click="searchService()"
                >
                  <svg class="text-white icon icon--outline icon--stroke-2 icon--md">
                    <use
                      href="../assets/img/icons.svg#icon-search"
                      xlink:href="img/icons.svg#icon-search"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="flex mb-2 px-0 md:px-6 hidden md:flex">
            <div class="w-auto">
              <p class="clr-blue">Por favor seleccione la casilla de cada servicio que necesita</p>
            </div>
            <div class="w-12 text-right ml-auto">
              <img src="../assets/img/icon-arrow-down.svg" class="inline-block" />
            </div>
          </div>
          <section class="overflow-hidden bg-white border border-gray-400 rounded rounded-b-none">
            <div class="px-5 py-2 block md:hidden">
              <div class="flex items-center">
                <div class="w-auto">
                  <p class="clr-blue">
                    Por favor seleccione la casilla de cada servicio que necesita
                  </p>
                </div>
                <div class="w-12 text-right ml-auto px-2">
                  <img src="../assets/img/icon-arrow-down.svg" class="inline-block" />
                </div>
              </div>
            </div>
            <table class="w-full text-left border-collapse p-4 mt-0">
              <tbody>
                <tr
                  class="flex border-b border-gray-200 table-row"
                  v-for="(Service, index) in filterService.slice(0, showService)"
                  :key="index"
                  style="background-color: white"
                >
                  <td class="px-6 py-4">
                    <div class="flex items-center">
                      <div>
                        <h6 class="my-0 text-gray-900">
                          {{ Service.description }}
                        </h6>
                        <span class="mr-4 text-black text-xl block md:hidden" v-if="Service.price">
                          Gs.{{ Service.price | thousandSeparator }}
                        </span>
                        <span class="mr-4 text-black text-xl block md:hidden" v-else> Gs. 0 </span>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 text-right">
                    <span
                      class="mr-4 text-black text-xl hidden md:inline-block"
                      v-if="Service.price"
                    >
                      Gs.{{ Service.price | thousandSeparator }}
                    </span>
                    <span class="mr-4 text-black text-xl hidden md:inline-block" v-else>
                      Gs. 0
                    </span>
                    <input
                      type="checkbox"
                      class="form-checkbox"
                      v-model="selectedServices"
                      :value="Service"
                      @change="addService($event, Service)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-5">
              <div class="flex my-8 items-center justify-center" v-if="filterService.length == 0">
                <div class="w-full md:w-1/2">
                  <p class="text-black text-center font-medium md:px-8 text-lg mb-5">
                    Su búsqueda no dio resultados por favor revise el texto de su búsqueda o cambie
                    su búsqueda
                  </p>

                  <div class="flex my-4 justify-center">
                    <button
                      class="btn btn--md font-medium bg-red-btn px-6 py-2"
                      @click="clearSearchService()"
                    >
                      Borrar búsqueda
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex mb-4">
                <div class="w-auto">
                  <p class="clr-red">
                    Por favor seleccione la casilla de cada servicio que necesita
                  </p>
                </div>
                <div class="w-12 text-right px-2 ml-auto">
                  <img src="../assets/img/icon-arrow-top.svg" class="inline-block" />
                </div>
              </div>

              <div class="flex flex-wrap md:flex-row items-center my-4">
                <div class="w-full md:w-auto mb-2 md:mb-0"></div>
                <div
                  class="w-full md:w-auto text-center md:text-right ml-auto"
                  v-if="services.length > showService"
                >
                  <button class="btn btn--primary py-3 w-full" @click="showMoreService()">
                    <img
                      src="../assets/img/icon-flecha.svg"
                      class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-left mr-auto"
                    />
                    <span class="justify-between text-base px-3"> Mostrar 5 servicios más</span>
                    <img
                      src="../assets/img/icon-flecha.svg"
                      class="w-5 text-white icon icon--outline icon--stroke-3 icon--md icon--align-right ml-auto"
                    />
                  </button>
                </div>
              </div>
            </div>
          </section>
        </section>
      </template>

      <template v-slot:footer> </template>
    </modal>
    <!-- modal end -->
    <!-- modal start-->
    <div class="manual-transfer">
      <modal ref="manualTransfer">
        <template v-slot:header>
          <h1></h1>
        </template>

        <template v-slot:body>
          <div class="flex flex-row">
            <div class="w-full items-center">
              <div>
                <div class="mb-4">
                  <div>
                    <p>
                      Por favor, realice la transferencia por el monto total a la siguiente cuenta
                      bancaria:<br />

                      Vision Banco<br />

                      Tipo de cuenta: Caja de ahorro<br />

                      Cta nro: 17460579<br />

                      Destinatario: Click Servicios E.A.S<br />

                      <b>RUC: 80116840-6</b>
                    </p>
                  </div>
                  <!-- upload file section-->
                  <label>
                    <!-- new image -->
                    <div>
                      <input
                        class="hidden w-full mt-1 mb-2 form-input"
                        type="file"
                        name="recieptFile"
                        @change="recieptFileAdd"
                        ref="recieptFile"
                      />
                    </div>
                    <div>
                      <button
                        type="button"
                        @click="$refs.recieptFile.click()"
                        class="btn btn--primary"
                      >
                        Adjuntar Comprobante
                      </button>
                    </div>

                    <!-- new image end -->
                  </label>
                  <span class="error" v-if="submited && recieptFile && recieptFile.length == 0"
                    >Favor seleccione al menos una imagen</span
                  >
                  <div v-if="imageSizeError">
                    <span class="error" v-html="imageSizeError"></span>
                  </div>
                  <div class="grid mb-4 grid-columns-3 grid-gap-2" v-if="recieptFilePreview">
                    <figure class="relative border border-gray-200 rounded shadow">
                      <div class="p-5" v-if="recieptFileType == 'application/pdf'">
                        {{ recieptFileName }}
                      </div>
                      <img :src="recieptFilePreview" class="w-full" v-else />
                      <span
                        v-show="recieptFilePreview"
                        @click="removeRecieptFile()"
                        class="absolute top-0 right-0 flex items-center justify-center w-6 h-6 p-1 text-lg text-white btn btn--secondary remove-btn"
                        >x</span
                      >
                    </figure>
                  </div>
                  <!-- upload file end -->
                </div>
                <div class="mb-4"></div>
              </div>
              <button
                class="rounded btn--secondary w-full btn btn--md px-4 py-4"
                @click="updatePaymentReciept()"
                :disabled="processingupdateReciept"
              >
                <span class="justify-between text-base"> Enviar</span>
              </button>
            </div>
            <hr />
          </div>
        </template>

        <template v-slot:footer> </template>
      </modal>
    </div>
    <!-- modal end -->
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Messages from '@/components/Messages.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import ProfileSidebar from '@/components/ProfileSidebar.vue';
import { mapGetters } from 'vuex';
import Footer from '@/components/Footer.vue';
import thousandSeparator from '@/utils/filters/thousandSeparator';
import scrollToSection from '@/utils/methods/scrollToSection';
import Swal from 'sweetalert2';
import Modal from '@/components/Modal';
import lapsedCreatedTime from '@/utils/methods/lapsedCreatedTime';
import lapsedScheduledTime from '@/utils/methods/lapsedScheduledTime';
import GetDepartments from '@/utils/methods/GetDepartments';
import activeDepartments from '@/utils/computed/activeDepartments';
import activeDepartmentLocations from '@/utils/computed/activeDepartmentLocations';
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LCircle, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
import { bus } from '../main';
import { ServiceRequests } from '@/services';
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const handToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
export default {
  data() {
    return {
      submited: false,
      processingServiceRequests: false,
      serviceRequests: {},
      updateServiceRequestData: {
        serviceId: null,
        requestData: {},
      },
      paymentData: {
        isPaymentPageShow: false,
        paymentStep: 1,
        serviceRequest: {},
      },
      processingreview: false,
      rating: 0.0,
      reviewdata: {
        rate: 0,
        description: '',
      },
      currentRating: 0.0,
      currentSelectedRating: 0,
      contentVisible: false,
      hash: null,
      searchQuery: '',
      filterService: [],
      showService: 10,
      services: [],
      selectedServices: [],
      selectedServiceRequestIndex: null,
      recieptFile: [],
      recieptFilePreview: null,
      recieptFileType: null,
      recieptFileName: null,
      imageSizeError: '',
      processingupdateReciept: false,
      processingProfessionalData: false,
      processingUpdateServiceRequest: false,
      updateServiceData: {
        orderId: null,
        requestData: {},
      },
      updateServiceQuantityData: {
        orderId: null,
        serviceDetailId: null,
        requestData: {
          quantity: null,
        },
      },
      textAreaStyles: {},
      isUpdateInvoice: false,
      selectedServiceAdress: {
        direction: null,
        department: null,
        location_id: null,
        latitude: -25.3211306,
        longitude: -57.5928935,
      },
      departments: [],
      departmentLocations: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 10,
      markerLatLng: [-25.3211306, -57.5928935],
      circle: {
        center: latLng(-25.3211306, -57.5928935),
        radius: 4500,
      },
    };
  },
  beforeDestroy() {
    this.$store.dispatch('setShowAlert', false);
  },
  created() {
    bus.$on('refreshIt', (data) => {
      if (data == 'yes') {
        this.GetUserServiceRequest();
      }
    });
  },
  mounted() {
    this.$store.dispatch('setShowAlert', false);
    this.GetDepartments();
    this.GetUserServiceRequest();

    if (this.$route.params.id) {
      var id = this.$route.params.id;
      var sectionId = 'service_' + id;
      this.scrollToSection(sectionId);
    }
  },
  watch: {
    '$route.params': 'goToServiceRequest',
  },
  methods: {
    cancelServiceRequest(serviceRequest) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'No podrás revertir esta acción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, cancelar',
        cancelButtonText: 'No, cancelar',
      }).then((result) => {
        if (result.value) {
          this.processingServiceRequests = true;
          ServiceRequests.cancel({ id: serviceRequest.id })
            .then((response) => {
              console.log(response);
              this.GetUserServiceRequest();
            })
            .catch((error) => {
              this.$store.dispatch("setShowAlert", true);
              console.log(error.response.data);
              let errorMessage = error.response.data.msg;
              this.$store.dispatch("setIsSuccessAlert", false);
              this.$store.dispatch("setIsErrorAlert", true);
              this.$store.dispatch("setGlobalMessage", errorMessage);
              window.scrollTo({ top: 0, behavior: "smooth" });
            })
            .finally(() => {
              this.processingServiceRequests = false;
            });
        }
      });
    },
    GetDepartments,
    GetDepartmentLocations(isSetDefault) {
      this.$store
        .dispatch('GetDepartmentLocations', {
          departmentId: this.selectedServiceAdress.department,
        })
        .then((response) => {
          this.departmentLocations = response.data.locations;
          if (isSetDefault && this.departmentLocations.length > 0) {
            this.selectedServiceAdress.location_id = this.departmentLocations[0].id;
          }
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch('setIsSuccessAlert', false);
          this.$store.dispatch('setIsErrorAlert', true);
          this.$store.dispatch('setGlobalMessage', errorMessage);
          this.$store.dispatch('setShowAlert', true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    GetLocationDepartment(locationId) {
      this.$store
        .dispatch('GetLocationDepartment', {
          locationId: locationId,
        })
        .then((response) => {
          this.$store.dispatch('setShowAlert', false);
          this.selectedServiceAdress.department = response.locations.department_id;
          this.GetDepartmentLocations();
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch('setIsSuccessAlert', false);
          this.$store.dispatch('setIsErrorAlert', true);
          this.$store.dispatch('setGlobalMessage', errorMessage);
          this.$store.dispatch('setShowAlert', true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    GetUserServiceRequest() {
      this.processingServiceRequests = true;
      this.$store
        .dispatch('GetUserServiceRequest', {
          userId: this.user.id,
        })
        .then((response) => {
          this.serviceRequests = response;
          this.processingServiceRequests = false;
          if (this.paymentStatus && this.paymentStatus.orderId) {
            var orderId = this.paymentStatus.orderId;
            const i = this.serviceRequests.findIndex((_item) => _item.id === orderId);
            if (i > -1) {
              this.showPaymentPage(this.serviceRequests[i]);
            }
            this.showServiceRequest(this.paymentStatus.status);
          }
        })
        .catch(() => {
          this.processingServiceRequests = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch('setIsSuccessAlert', false);
          this.$store.dispatch('setIsErrorAlert', true);
          this.$store.dispatch('setGlobalMessage', errorMessage);
          this.$store.dispatch('setShowAlert', true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    scrollToSection,
    resetPaymentData() {
      this.paymentData.isPaymentPageShow = false;
      this.paymentData.paymentStep = 1;
      this.paymentData.serviceRequest = {};
      this.resetReviewData();
    },
    requestReview(serviceRequest) {
      this.paymentData.paymentStep = 3;
      this.showPaymentPage(serviceRequest);
    },
    showPaymentPage(serviceRequest) {
      this.paymentData.isPaymentPageShow = true;
      this.paymentData.serviceRequest = serviceRequest;
      this.resetReviewData();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    updateServiceRequestStatus(serviceId, data) {
      this.submited = true;
      this.processingServiceRequests = true;
      if (this.validateForm()) {
        this.updateServiceRequestData.serviceId = serviceId;
        this.updateServiceRequestData.requestData = data;
        this.$store
          .dispatch('updateServiceRequestStatus', this.updateServiceRequestData)
          .then(() => {
            this.processingServiceRequests = false;
            this.submited = false;
            this.paymentData.paymentStep = 3;
            let registerMessage = `Payment is submit successfully.`;
            this.$store.dispatch('setIsErrorAlert', false);
            this.$store.dispatch('setIsSuccessAlert', true);
            this.$store.dispatch('setGlobalMessage', registerMessage);
            this.$store.dispatch('setShowAlert', true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
          .catch(() => {
            this.processingServiceRequests = false;
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch('setIsSuccessAlert', false);
            this.$store.dispatch('setIsErrorAlert', true);
            this.$store.dispatch('setGlobalMessage', errorMessage);
            this.$store.dispatch('setShowAlert', true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      } else {
        this.processingServiceRequests = false;
        setTimeout(function () {
          var d = document.getElementsByClassName('OnErrorScrollHere')[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: 'smooth' });
        }, 1000);
      }
    },
    cashPayment(serviceId, data) {
      this.submited = true;
      this.processingServiceRequests = true;
      if (this.validateForm()) {
        this.updateServiceRequestData.serviceId = serviceId;
        this.updateServiceRequestData.requestData = data;
        this.$store
          .dispatch('updateServiceRequestStatus', this.updateServiceRequestData)
          .then(() => {
            this.processingServiceRequests = false;
            this.submited = false;
            this.resetPaymentData();
            this.GetUserServiceRequest();
            let registerMessage = `La solicitud de pago en efectivo se envió correctamente.`;
            this.$store.dispatch('setIsErrorAlert', false);
            this.$store.dispatch('setIsSuccessAlert', true);
            this.$store.dispatch('setGlobalMessage', registerMessage);
            this.$store.dispatch('setShowAlert', true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
          .catch(() => {
            this.processingServiceRequests = false;
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch('setIsSuccessAlert', false);
            this.$store.dispatch('setIsErrorAlert', true);
            this.$store.dispatch('setGlobalMessage', errorMessage);
            this.$store.dispatch('setShowAlert', true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      } else {
        this.processingServiceRequests = false;
        setTimeout(function () {
          var d = document.getElementsByClassName('OnErrorScrollHere')[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: 'smooth' });
        }, 1000);
      }
    },
    createPayment(orderId) {
      this.submited = true;
      this.processingServiceRequests = true;
      if (this.validateForm()) {
        this.$store
          .dispatch('createPayment', {
            orderId: orderId,
          })
          .then(() => {
            this.processingServiceRequests = false;
            let registerMessage = `Redireccionando a pasarela de pagos.....`;
            this.$store.dispatch('setIsErrorAlert', false);
            this.$store.dispatch('setIsSuccessAlert', true);
            this.$store.dispatch('setGlobalMessage', registerMessage);
            this.$store.dispatch('setShowAlert', true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            var self = this;
            setTimeout(function () {
              window.location.href = self.paymentHash.url;
            }, 2000);
          })
          .catch(() => {
            this.processingServiceRequests = false;
            let errorMessage = this.error.response.data.msg;
            this.$store.dispatch('setIsSuccessAlert', false);
            this.$store.dispatch('setIsErrorAlert', true);
            this.$store.dispatch('setGlobalMessage', errorMessage);
            this.$store.dispatch('setShowAlert', true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      } else {
        this.processingServiceRequests = false;
        setTimeout(function () {
          var d = document.getElementsByClassName('OnErrorScrollHere')[0];
          var topPos = d.offsetTop - 25;
          d.focus();
          window.scrollTo({ top: topPos, behavior: 'smooth' });
        }, 1000);
      }
    },
    showServiceRequest(status) {
      if (status) {
        this.submited = false;
        this.paymentData.paymentStep = 3;
      } else {
        Swal.fire({
          title: '',
          icon: 'error',
          html:
            'No se pudo procesar el pago a la orden ' +
            this.paymentHash.orderId +
            ', por favor intente nuevamente, si no lo consigue comuníquese con nosotros al mail ' +
            ' <a href="mailto:contacto@hand.com.py">contacto@hand.com.py</a> ',
        });
      }
      this.$store.dispatch('removePaymentStatus');
    },
    validateForm() {
      if (this.paymentData.paymentStep == 1) {
        return true;
      }
    },
    setRating: function (rating) {
      this.rating = rating;
    },
    showCurrentRating: function (rating) {
      this.currentRating = rating === 0 ? this.currentSelectedRating : rating;
    },
    setCurrentSelectedRating: function (rating) {
      this.currentSelectedRating = rating;
    },
    validateRating(requestData, isMoveNextStep) {
      this.submited = true;
      this.processingreview = true;
      this.reviewdata.professional_id = requestData.Professional.id;
      this.reviewdata.service_id = requestData.id;
      this.reviewdata.user_id = this.user.id;
      if (this.reviewdata.rate > 0 && this.reviewdata.description != '') {
        if (this.reviewdata.rate <= 1) {
          Swal.fire({
            title: '',
            text: 'Has seleccionado el puntaje más bajo, por favor asegurese que seleccionó correctamente, ya que el mismo va a incidir negativamente en el perfil del profesional. Desea continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
          }).then((result) => {
            if (result.value) {
              this.submitProfessionalReview(isMoveNextStep);
            } else {
              this.processingreview = false;
              this.submited = false;
            }
          });
        } else {
          this.submitProfessionalReview(isMoveNextStep);
        }
      } else {
        this.processingreview = false;
      }
    },
    submitProfessionalReview(isMoveNextStep) {
      this.$store
        .dispatch('submitProfessionalReview', this.reviewdata)
        .then(() => {
          if (isMoveNextStep) {
            this.paymentData.paymentStep = 4;
          } else {
            this.GetUserServiceRequest();
          }

          let reviewsubmitMessage = `Reseña enviada correctamente`;
          this.$store.dispatch('setIsErrorAlert', false);
          this.$store.dispatch('setIsSuccessAlert', true);
          this.$store.dispatch('setGlobalMessage', reviewsubmitMessage);
          this.$store.dispatch('setShowAlert', true);
          this.resetReviewData();
          this.processingreview = false;
          this.submited = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch('setIsSuccessAlert', false);
          this.$store.dispatch('setIsErrorAlert', true);
          this.$store.dispatch('setGlobalMessage', errorMessage);
          this.$store.dispatch('setShowAlert', true);
          this.processingreview = false;
          this.submited = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    resetReviewData() {
      this.currentRating = 0.0;
      this.currentSelectedRating = 0;
      this.rating = 0.0;
      this.reviewdata = {
        rate: 0,
        description: '',
      };
    },
    totalPayment(requestData) {
      if (requestData.Services && requestData.Services.length > 0) {
        var sum = 0;
        requestData.Services.forEach((e) => {
          sum += e.quantity * e.price;
        });
        if (requestData.ExtraServices && requestData.ExtraServices.length > 0) {
          requestData.ExtraServices.forEach((e) => {
            sum += 1 * e.price;
          });
        }
        return sum;
      } else {
        return 0;
      }
    },
    increment(serviceRequestIndex, ServiceIndex) {
      var orderID = this.serviceRequests[serviceRequestIndex].id;
      var serviceDetailId =
        this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].order_detail_id;
      var qty = this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity;
      this.updateServiceQuantity(orderID, serviceDetailId, qty + 1);
    },
    decrement(serviceRequestIndex, ServiceIndex) {
      if (this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity === 1) {
        this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity = 1;
      } else {
        var orderID = this.serviceRequests[serviceRequestIndex].id;
        var serviceDetailId =
          this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].order_detail_id;
        var qty = this.serviceRequests[serviceRequestIndex].Services[ServiceIndex].quantity;
        this.updateServiceQuantity(orderID, serviceDetailId, qty - 1);
      }
    },
    searchService() {
      if (this.searchQuery) {
        this.filterService = this.services.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every((v) => item.description.toLowerCase().includes(v));
        });
      } else {
        this.filterService = this.services;
      }
    },
    showMoreService() {
      this.showService = this.showService + 5;
    },
    clearSearchService() {
      this.searchQuery = '';
      this.filterService = this.services;
    },
    addService(e, service) {
      const f = this.serviceRequests[this.selectedServiceRequestIndex].Services.findIndex(
        (_item) => _item.id === service.id,
      );
      var orderID = this.serviceRequests[this.selectedServiceRequestIndex].id;
      var services = this.serviceRequests[this.selectedServiceRequestIndex].Services;
      var updatedServices = [];
      if (e.target.checked) {
        if (f <= -1) {
          for (var p = 0; p < services.length; p++) {
            updatedServices.push({
              id: services[p].id,
              quantity: services[p].quantity,
            });
          }
          updatedServices.push({
            id: service.id,
            quantity: 1,
          });
          this.updateServiceRequest(orderID, updatedServices);
        }
      } else {
        services.splice(f, 1);
        for (var u = 0; u < services.length; u++) {
          updatedServices.push({
            id: services[u].id,
            quantity: services[u].quantity,
          });
        }
        this.updateServiceRequest(orderID, updatedServices);
      }
    },
    selectServiceRequest(serviceRequestIndex, ServiceRequest) {
      this.selectedServiceRequestIndex = serviceRequestIndex;
      this.GetProfessionalData(ServiceRequest);
    },
    recieptFileAdd(e) {
      this.recieptFile = e.target.files || e.dataTransfer.files;
      if (!this.recieptFile) return;
      let imageHaveError = [];
      if (this.recieptFile[0].size > 1024 * 1024 * 10) {
        imageHaveError.push({
          filename: this.recieptFile[0].name,
          hasError: 'size',
        });
        this.recieptFile = [];
      } else {
        if (
          this.recieptFile[0]['type'] == 'image/png' ||
          this.recieptFile[0]['type'] == 'image/jpeg' ||
          this.recieptFile[0]['type'] == 'application/pdf'
        ) {
          this.recieptFileType = this.recieptFile[0]['type'];
          this.recieptFileName = this.recieptFile[0]['name'];
          var reader = new FileReader();
          var vm = this;
          reader.onload = (e) => {
            vm.recieptFilePreview = e.target.result;
          };
          reader.readAsDataURL(this.recieptFile[0]);
        } else {
          imageHaveError.push({
            filename: this.recieptFile[0].name,
            hasError: 'type',
          });
          this.recieptFile = [];
        }
      }
      if (imageHaveError.length > 0) {
        this.imageSizeError = '';
        for (var t = 0; t < imageHaveError.length; t++) {
          if (imageHaveError[t].hasError == 'type') {
            this.imageSizeError +=
              imageHaveError[t].filename + ' Formato de fotografía no soportado <br>';
          } else {
            this.imageSizeError +=
              imageHaveError[t].filename +
              ' supera el tamaño permitido. Por favor seleccione un archivo de tamaño menor a 10MB <br>';
          }
        }
      } else {
        this.imageSizeError = '';
      }
    },
    removeRecieptFile() {
      this.recieptFile = null;
      this.recieptFilePreview = null;
      if (!this.recieptFile) {
        this.$refs.recieptFile.value = '';
      }
    },
    updatePaymentReciept() {
      this.submited = true;
      this.processingupdateReciept = true;
      if (this.$refs.recieptFile && this.$refs.recieptFile.files.length > 0) {
        var images = require.context('../assets/img/', false, /\.gif$/);
        Swal.fire({
          imageUrl: images('./loading.gif'),
          imageAlt: 'Loader',
          showConfirmButton: false,
          background: 'transparent',
          backdrop: `rgba(0,0,0,0.6)`,
          allowOutsideClick: false,
        });
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.recieptFile.files.length; i++) {
          let file = this.$refs.recieptFile.files[i];
          formdata.append('files', file);
        }
        formdata.append('orderId', this.paymentData.serviceRequest.id);

        this.$store
          .dispatch('updatePaymentReciept', formdata)
          .then(() => {
            Swal.close();
            this.$refs.manualTransfer.closeModal();
            this.resetPaymentData();
            this.GetUserServiceRequest();
            this.$refs.recieptFile.value = '';
            this.processingupdateReciept = false;
            Swal.fire({
              title: '',
              icon: 'success',
              html: `Su comprobante ha sido adjuntado con éxito, ni bien se procese la transacción, el profesional será notificado, usted podrá continuar con el flujo y agregar una reseña por el servicio recibido`,
              confirmButtonText: 'Continuar',
            });
          })
          .catch(() => {
            Swal.close();
            let errorMessage = this.error.response.data.msg[0];
            this.$store.dispatch('setIsSuccessAlert', false);
            this.$store.dispatch('setIsErrorAlert', true);
            this.$store.dispatch('setGlobalMessage', errorMessage);
            this.$store.dispatch('setShowAlert', true);
            this.submited = false;
            this.processingupdateReciept = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      } else {
        this.processingupdateReciept = false;
      }
    },
    GetProfessionalData(ServiceRequest) {
      this.processingProfessionalData = true;
      this.$store
        .dispatch('GetProfileData', {
          professionalId: ServiceRequest.Professional.user_id,
          professionId: ServiceRequest.Profession[0].id,
        })
        .then((response) => {
          this.services = response.Services;
          this.filterService = this.services;

          this.selectedServices = [];
          ServiceRequest.Services.forEach((e) => {
            const i = this.services.findIndex((_item) => _item.id === e.id);
            if (i > -1) {
              this.selectedServices.push(this.services[i]);
            }
          });
          this.processingProfessionalData = false;
        })
        .catch(() => {
          this.processingProfessionalData = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch('setIsSuccessAlert', false);
          this.$store.dispatch('setIsErrorAlert', true);
          this.$store.dispatch('setGlobalMessage', errorMessage);
          this.$store.dispatch('setShowAlert', true);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    lapsedCreatedTime,
    lapsedScheduledTime,
    updateServiceRequest(orderID, services) {
      this.processingUpdateServiceRequest = true;
      this.updateServiceData.orderId = orderID;
      this.updateServiceData.requestData.services = services;
      this.$store
        .dispatch('updateServiceRequest', this.updateServiceData)
        .then(() => {
          this.processingUpdateServiceRequest = false;
          this.GetUserServiceRequest();
          handToast.fire({
            icon: 'success',
            title: 'servicios actualizados con éxito',
          });
        })
        .catch(() => {
          this.processingUpdateServiceRequest = false;

          handToast.fire({
            icon: 'error',
            title: 'Error ocurrido: algo salió mal, intente de nuevo',
          });
        });
    },
    updateServiceQuantity(orderId, serviceDetailId, qty) {
      this.processingUpdateServiceRequest = true;
      this.updateServiceQuantityData.orderId = orderId;
      this.updateServiceQuantityData.serviceDetailId = serviceDetailId;
      this.updateServiceQuantityData.requestData.quantity = qty;

      this.$store
        .dispatch('updateServiceQuantity', this.updateServiceQuantityData)
        .then(() => {
          this.processingUpdateServiceRequest = false;
          this.GetUserServiceRequest();
          handToast.fire({
            icon: 'success',
            title: 'Cantidad de servicio actualizada correctamente',
          });
        })
        .catch(() => {
          this.processingUpdateServiceRequest = false;
          handToast.fire({
            icon: 'error',
            title: 'Error ocurrido: algo salió mal, intente de nuevo',
          });
        });
    },
    goToServiceRequestEvent(notification) {
      if (notification.eventId) {
        this.resetPaymentData();
        var id = notification.eventId;
        var sectionId = 'service_' + id;
        this.scrollToSection(sectionId);
      }
    },
    goToServiceRequest() {
      if (this.$route.params.id) {
        this.resetPaymentData();
        var id = this.$route.params.id;
        var sectionId = 'service_' + id;
        this.scrollToSection(sectionId);
      }
    },
    selectServiceAdress(ServiceRequest) {
      this.selectedServiceAdress.location_id = ServiceRequest.location_id;
      this.selectedServiceAdress.longitude = ServiceRequest.longitude;
      this.selectedServiceAdress.latitude = ServiceRequest.latitude;
      this.selectedServiceAdress.direction = ServiceRequest.direction;
      if (ServiceRequest.latitude && ServiceRequest.longitude) {
        this.circle.center = latLng(ServiceRequest.latitude, ServiceRequest.longitude);
        this.markerLatLng = [ServiceRequest.latitude, ServiceRequest.longitude];
      }
      this.GetLocationDepartment(this.selectedServiceAdress.location_id);
    },
    refreshServiceRequestEvent() {
      this.GetUserServiceRequest();
    },
  },
  filters: {
    thousandSeparator,
    twoDigit: function (value) {
      if (!value) return '';
      return ('0' + value).slice(-2);
    },
  },
  computed: {
    ...mapGetters(['error', 'user', 'paymentHash', 'paymentStatus']),
    activeDepartments,
    activeDepartmentLocations,
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    ProfileSidebar,
    Modal,
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
  },
};
</script>
<style lang="css" scoped>
@import '../assets/css/control-panel.css';

.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.success {
  color: #1bb01b;
}
.capitalize {
  text-transform: capitalize;
}
</style>
<style lang="css">
.manual-transfer .modal__dialog {
  width: 30%;
}
.modal-adress-info .modal__dialog {
  width: 50%;
}

.manual-transfer .modal__footer,
.modal-adress-info .modal__footer {
  display: none;
}
.manual-transfer .modal__body {
  padding: 0px 30px 30px;
}
@media screen and (max-width: 992px) {
  .manual-transfer .modal__dialog,
  .modal-adress-info .modal__dialog {
    width: 90%;
  }
}
@media (min-width: 1024px) {
  .xl-h-auto {
    height: 3rem !important;
  }
}
@media (min-width: 1280px) {
  .xl-h-auto {
    height: auto !important;
  }
}
.service-step .progress-step.is-active .progress-marker:before {
  background-color: red;
}
.service-step .progress-step.is-complete .progress-marker:before {
  background-color: green;
}

.service-step .progress-step.is-complete .progress-marker:after {
  background-color: green;
}
.progress-marker:before {
  z-index: 2 !important;
}
.progress-marker:after {
  z-index: 1 !important;
}
</style>
