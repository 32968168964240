<template>
  <div class="w-full pt-[90px]">
    <div class="w-full px-[50px] xl:pl-[53px]">
      <span class="leading-[19px] xl:text-[25px] xl:leading-[29px] font-bold text-black">3. Descripción profesional</span>
      <div class="max-xl:w-full xl:w-1/2 mt-[8px]">
        <p class="max-xl:text-[14px] max-xl:leading-[16px] leading-[19px] text-dark3">
          Selecciona aquellas caracteristicas con las que te sientas idenficado como profesional, serán las que los clientes tengan en cuenta a la hora de contratar un servicio.
        </p>
      </div>
    </div>
    <div class="w-full flex flex-col px-[50px] xl:px-0 xl:pl-[53px] mt-[70px]">
      <div class="flex items-center mb-[20px]">
        <user-icon />
        <span class="font-semibold xl:text-[20px] xl:leading-[23px] text-black ml-[13px]">Características profesionales</span>
      </div>
      <div class="max-xl:w-full xl:w-2/3 grid max-xl:grid-cols-2 xl:grid-cols-3 max-sm:grid-cols-1 gap-x-[40px] xl:gap-x-[23px] gap-y-[20px]">
        <template v-for="option in characteristics">
          <checkbox 
            :key="option.id"
            @change="(checked) => onCharacteristicClicked(checked, option.id)"
          >
            {{option.description}}
          </checkbox>
        </template>
      </div>
    </div>
    <div class="max-xl:w-full xl:w-1/2 px-[50px] mt-[70px]">
      <div class="flex items-center mb-[20px]">
        <camera-icon />
        <span class="font-semibold xl:text-[20px] xl:leading-[23px] text-black mx-[13px]">Foto de perfil</span>
        <Tooltip message="Se recomienda una foto formato cuadrado, plano cercano, con buena luz. La foto de perfil deberá ser nítida.">
          <img src="@/assets/v2/svg/info.svg" class="inline-block w-[15px]"/>
        </Tooltip>
      </div>
      <file-input :accept="'.jpg, .png'" @selected="onSelectProfilePicture" @deleted="onDeletedProfilePicture" :color="'primary'">
        <template #placeholder>
          <span class="text-primary">Cargar foto de <span class="font-bold">PERFIL</span></span>
        </template>
        <template #trigger-icon>
          <cloud-upload-icon :width="23" :height="19" :color="'white'"/>
        </template>
        <template #modal-content>
          <span class="max-xl:text-[18px] xl:text-[25px] leading-[29px] text-black font-bold">Foto de perfil</span>
          <span class="max-xl:text-[14px] xl:text-[16px] leading-[24px] text-dark">SubTitle</span>
          <div class="w-full flex max-xl:flex-col xl:flex-row items-center justify-center border border-grayLight rounded-[6px] max-xl:py-[23px] max-xl:px-[16px] xl:p-[16px] mt-[32px]">
            <img src="@/assets/v2/img/img-profile-2.png" class="max-xl:w-[100px] max-xl:h-[100px] xl:w-[116px] xl:h-[116px] rounded-full" />
            <div class="flex-grow flex flex-col ml-[16px]">
              <div class="w-full flex items-center mt-[24px]">
                <check-circle-icon />
                <span class="max-xl:text-[14px] xl:text-[16px] leading-[18px] font-semibold text-black ml-[10px]">Referencia correcta de foto</span>
              </div>
              <div class="w-full mt-[17px]">
                <p class="text-gray leading-[18px]">
                  Esta es una referencia correcta de cómo debería verse la foto que cargues de tu <span class="font-bold">FOTO DE PERFIL</span>.
                </p>
              </div>
            </div>
          </div>
        </template>
      </file-input>
      <span class="text-red-500 mt-[5px] block" v-if="!profilePictureCheck">
        El archivo que intenta agregar supera el tamaño permitido. Por favor seleccione una foto de tamaño menor a 1MB
      </span>
    </div>
    <div class="max-xl:w-full xl:w-1/2 px-[50px] mt-[70px]">
      <div class="flex items-center mb-[20px]">
        <attach-icon :color="'white'" />
        <span class="font-semibold xl:text-[20px] xl:leading-[23px] text-black mx-[13px]">Certificación (opcional)</span>
        <Tooltip message="Si te haz certificado en algún oficio, adjuntar foto del titulo original y/o matricula.">
          <img src="@/assets/v2/svg/info.svg" class="inline-block w-[15px]"/>
        </Tooltip>
      </div>
      <file-input @selected="onSelectFiles" @deleted="onDeletedFiles" :color="'primary'" :multiple="true">
        <template #placeholder>
          <span class="text-primary">Cargar <span class="font-bold">DOCUMENTACIÓN</span></span>
        </template>
        <template #trigger-icon>
          <cloud-upload-icon :width="23" :height="19" :color="'white'"/>
        </template>
        <template #modal-content>
          <span class="max-xl:text-[18px] xl:text-[25px] leading-[29px] text-black font-bold">Certificaciones</span>
          <span class="max-xl:text-[14px] xl:text-[16px] leading-[24px] text-dark">Opcional</span>
          <div class="w-full flex max-xl:flex-col xl:flex-row items-center justify-center border border-grayLight rounded-[6px] max-xl:py-[23px] max-xl:px-[16px] xl:p-[16px] mt-[32px]">
            <div class="flex-grow flex flex-col ml-[16px]">
              <div class="w-full flex items-center">
                <check-circle-icon />
                <span class="max-xl:text-[14px] xl:text-[16px] leading-[18px] font-semibold text-black ml-[10px]">Certificación</span>
              </div>
            </div>
          </div>
        </template>
      </file-input>
      <span class="text-red-500 mt-[5px] block" v-if="!filesCheck">
        Sólo puedes subir hasta 3 archivos
      </span>
    </div>
  </div>
</template>
<script>
import {RoundedButton} from "@/components/v2/buttons";
import {PlusCircleIcon,SubstractCircleIcon,UserIcon,SettingsIcon,CloudUploadIcon,CheckCircleIcon,AlertCircleIcon,AttachIcon} from "@/components/v2/icons";
import Checkbox from "@/components/v2/forms/Checkbox.vue";
import {FileInput} from "@/components/v2/forms";
import CameraIcon from "@/components/v2/icons/CameraIcon.vue";
import Tooltip from "@/components/v2/Tooltip.vue";
import {mapMutations, mapState} from "vuex";
import { Professionals } from "@/services";

export default{
  components:{
    Tooltip,
    CameraIcon,
    SettingsIcon,
    Checkbox,
    RoundedButton,
    PlusCircleIcon,
    SubstractCircleIcon,
    AlertCircleIcon,
    UserIcon,
    FileInput,
    CloudUploadIcon,
    CheckCircleIcon,
    AttachIcon
  },
  data() {
    return {
      characteristics: [],
      profilePictureCheck: true,
      filesCheck: true
    }
  },
  beforeMount() {
    Professionals.getCharacteristics().then(({data}) => {
      this.characteristics = data.characteristics
    }).catch(error => { console.log(error) })
  },
  methods:{
    ...mapState('completeProfessionalProfile', ["profilePicture"]),
    ...mapMutations("completeProfessionalProfile",["setProfilePicture", "addCharacteristic", "deleteCharacteristic", 'setFiles']),
    onCharacteristicClicked(checked, characteristicId) {
      if (checked) {
        this.addCharacteristic(characteristicId)
      } else {
        this.deleteCharacteristic(characteristicId)
      }
    },
    onSelectProfilePicture(picture)
    {
      if (this.checkProfilePicture(picture)) {
        this.setProfilePicture(picture)
        this.profilePictureCheck = true
      } else {
        this.setProfilePicture(null)
        this.profilePictureCheck = false
      }
    },
    onDeletedProfilePicture(picture)
    {
      this.setProfilePicture(null)
    },
    onSelectFiles(files)
    {
      this.setFiles(files)

      if (this.checkFiles(files)) {
        this.filesCheck = true
      } else {
        this.filesCheck = false
      }
    },
    onDeletedFiles(files)
    {
      this.setFiles([])
      this.filesCheck = true
    },
    checkProfilePicture(picture) {
      const limitSize = 1024 * 1024 * 1

      if (picture && picture.size < limitSize) {
        return true
      }

      return false
    },
    checkFiles(files) {
      if (files.length <= 3) {
        return true
      }

      return false
    },
  }
}
</script>
