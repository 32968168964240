<template>
  <div
    @click="$emit('show-details')"
    class="w-full flex items-center bg-white my-5 p-5 cursor-pointer"
  >
    <div class="w-1/2 flex">
      <div class="w-1/2 flex flex-col justify-center items-center mr-5">
        <span class="font-semibold">Cliente</span>
        <img :src="client.user.pictureProfileUrl" class="w-12 w-12 rounded-full my-3" alt="" />
        <span>{{ client.user.firstName + " " + client.user.lastName }}</span>
      </div>
      <div class="w-1/2 flex flex-col justify-center items-center">
        <span class="font-semibold">Profesional</span>
        <img
          :src="professional.user.pictureProfileUrl"
          class="w-12 w-12 rounded-full my-3"
          alt=""
        />
        <span>{{ professional.user.firstName + " " + professional.user.lastName }}</span>
      </div>
    </div>
    <div class="w-1/2 h-full flex flex-col items-center justify-between">
      <div class="flex mb-3">
        <div class="flex mr-3">
          <calendar-days-icon color="#686b79" /><span class="font-bold">{{ date }}</span>
        </div>
        <div class="flex">
          <clock-icon color="#686b79" /><span class="font-bold">{{
            serviceRequest.serviceHour
          }}</span>
        </div>
      </div>
      <div class="flex">
        <span v-if="serviceRequest.status !== 'complete'" class="text-lg font-bold">₲ {{ serviceRequest.serviceFee | thousandSeparator }}</span>
        <span v-if="serviceRequest.status === 'complete'" class="text-lg font-bold">₲ {{ serviceRequest.service_finished_fee | thousandSeparator }}</span>
        <span
          :class="[
            'font-bold text-lg ml-8',
            { 'text-brand-secondary success': serviceRequest.status == 'complete' },
            { 'text-brand-primary': serviceRequest.status == 'ongoing' },
            { 'text-brand-secondary': serviceRequest.status == 'pending' },
          ]"
        >
          {{ statusTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import thousandSeparator from "@/utils/filters/thousandSeparator";
import scrollToSection from "@/utils/methods/scrollToSection";
import statusTranslations from "@/utils/helpers/ServiceRequest/statusTranslations";
import { ProfilePicture } from "@/components";
import dayjs from "dayjs";
import { CalendarDaysIcon, ClockIcon } from "@/components/icons";
export default {
  props: {
    serviceRequest: {
      type: Object,
      default: () => ({}),
    },
    onSelectProfessional: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      processingServiceRequests: false,
      requestStatus: "pending",
    };
  },
  methods: {
    totalServiceCost: (items) => {
      if (items.length) {
        var sum = 0;
        items.forEach((e) => {
          if (!e.quantity) {
            e.quantity = 1;
          }
          sum += e.quantity * e.service.price;
        });
        return sum;
      }
      return 0;
    },
    scrollToSection,
    totalComision(requestData) {
      var comisionGravado = 0;
      if (requestData.Professional && requestData.Professional.comisionGravado) {
        comisionGravado = requestData.Professional.comisionGravado;
      }
      if (
        this.totalPayment(requestData) &&
        this.totalPayment(requestData) > 0 &&
        comisionGravado > 0
      ) {
        return Math.round(this.totalPayment(requestData) / comisionGravado);
      }
      return 0;
    },
  },
  filters: {
    thousandSeparator,
  },
  computed: {
    statusTitle() {
      return statusTranslations[this.serviceRequest.status];
    },
    client() {
      return this.serviceRequest.client_profile;
    },
    professional() {
      return this.serviceRequest.professional_profile;
    },
    date() {
      return dayjs(this.serviceRequest.date).format("DD/MM/YYYY");
    },
    totalPayment() {
      if (this.serviceRequest.details && this.serviceRequest.details.length > 0) {
        var sum = 0;
        this.serviceRequest.details.forEach((e) => {
          sum += e.quantity * e.service.price;
        });
        if (this.serviceRequest.extra_services && this.serviceRequest.extra_services.length > 0) {
          this.serviceRequest.extra_services.forEach((e) => {
            sum += 1 * e.price;
          });
        }
        return sum;
      }
      return 0;
    },
  },
  components: {
    ProfilePicture,
    CalendarDaysIcon,
    ClockIcon,
  },
};
</script>
<style lang="css" scoped>
.success {
  color: #1bb01b;
}
</style>
