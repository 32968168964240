<template>
  <div class="w-screen overflow-x-hidden">
    <div class="w-full h-full flex">
      <div class="w-0 xl:w-1/3">
        <side-navigator
          @go-to-step="goToStep"
        />
      </div>
      <div class="w-full xl:w-2/3 xl:pr-[200px] flex flex-col xl:border-l xl:border-l-gray2">
        <div class="w-full">
          <component
            :is="'step-'+$route.params.step"
          />
        </div>
        <bottom-navigator
          @next-step="nextStep"
          @previous-step="previousStep"
        />
      </div>
    </div>
    <template v-if="loading">
      <loading-screen />
    </template>

  </div>
</template>
<script>
import SideNavigator from "./SideNavigator.vue";
import BottomNavigator from "./BottomNavigator.vue";
import Step1 from "./Step1/Step1.vue"
import Step2 from "./Step2/Step2.vue"
import Step3 from "./Step3/Step3.vue"
import Step4 from "./Step4.vue"
import {checkProfessionalToken,ifNotAuthenticated} from "@/router/middlewares";
import {mapActions, mapState, mapGetters, mapMutations} from "vuex";
import LoadingScreen from "@/views/v2/professional/complete-profile/LoadingScreen.vue";

export default {
  components:{
    LoadingScreen,
    BottomNavigator,
    SideNavigator,
    Step1,
    Step2,
    Step3,
    Step4
  },
  data()
  {
    return {
      loading:false
    }
  },
  beforeRouteEnter(to,from,next){
    ifNotAuthenticated(to,from,next)
    checkProfessionalToken(to,from,next)
  },
  mounted() {
    if (!this.professions.length) {
      this.initState() 
    }
  },
  computed:{
    ...mapState('completeProfessionalProfile',['rubros',"location","address","distanceKm","profilePicture", "professions", "selectedCharacteristics", "files"]),
    ...mapGetters(["error"]),
  },
  methods:{
    nextStep()
    {
      const {step,token} = this.$route.params
      const nextStep = parseInt(step)+1
      if(this.checkStep({step}))
      {
        if(step==3)
        {
          this.loading=true
          const {token} = this.$route.params

          let updateData = {};
          let formData = new FormData();

          formData.append("coverLetter", "Presentación");
          formData.append("latitude", this.location[0]);
          formData.append("longitude", this.location[1]);
          formData.append("distance", this.distanceKm);
          formData.append("profileImage", this.profilePicture);

          if (this.files.length) {
            for (var i = 0; i < this.files.length; i++) {
              let file = this.files[i];
              formData.append("files", file);
            }
          }

          let professions = []
          let services = []
          let characteristics = this.selectedCharacteristics.map(id => ({ characteristic_id: id }))

          this.rubros.map((rubro) => {
            professions.push({
              profession_id: rubro.id,
              experience: rubro.year
            });

            rubro.services.map(service => {
              services.push({
                service_id: service.id,
                service_price: service.price,
                service_guaranteeTime: service.guaranteeTime,
                extraService1: "",
                extraService2: "",
                extraService3: ""
              });
            })
          })

          formData.append("professions", JSON.stringify(professions));
          formData.append("services", JSON.stringify(services));
          formData.append("characteristics", JSON.stringify(characteristics));

          updateData.token = token
          updateData.formdata = formData

          this.$store.dispatch("completeProfessionalProfile", updateData).then(() => {
            this.removeCompleteProfileData()
            this.$router.push({ name: 'ProfessionalCompleteProfile', params: { token, step: nextStep } })
          })
          .catch(() => {
            let errorMessage = this.error.response.data.msg[0];

            this.$store.dispatch("setShowAlert", true);
            this.$store.dispatch("setIsErrorAlert", true)
            this.$store.dispatch("setGlobalMessage", errorMessage)
          }).finally(() => {
            this.loading = false
          });

        }else{
          if (step == 2) {
            this.setShowCompleteProfileAddressModal(true)
          } else {
            this.$router.push({name:'ProfessionalCompleteProfile',params:{token,step:nextStep}})
          }
        }

        this.$emit("selected",this.itemSelected)

      }
    },
    previousStep()
    {
      const {step,token} = this.$route.params
      this.$router.push({name:'ProfessionalCompleteProfile',params:{token,step:parseInt(step)-1}})
    },
    goToStep({step})
    {
      const {token} = this.$route.params
      this.$router.push({name:'ProfessionalCompleteProfile',params:{token,step:parseInt(step)}})
    },
    checkStep({step})
    {
      const stepIsComplete = this["step"+step+"IsComplete"]()
      if(!stepIsComplete)
      {
        this.$store.dispatch("setShowAlert", true);
        this.$store.dispatch("setIsErrorAlert", true)
        this.$store.dispatch("setGlobalMessage", "Completa este paso antes de continuar")
      }
      return stepIsComplete
    },
    step1IsComplete()
    {
      return this.rubros && this.rubros.filter(rubro=>{
        return rubro.year>=1 && rubro.services.length>0 && rubro.id
      }).length===this.rubros.length
    },
    step2IsComplete()
    {
      return this.location[0] && this.location[1] && !!this.address && this.distanceKm>=1
    },
    step3IsComplete()
    {
      return !!this.profilePicture && this.selectedCharacteristics.length && this.files.length <= 3
    },
    ...mapActions('completeProfessionalProfile',['initState']),
    ...mapMutations("completeProfessionalProfile",["removeCompleteProfileData", "setShowCompleteProfileAddressModal"])
  }
}
</script>
