<template>
  <div class="flex flex-col justify-between mb-4">
    <h4 class="mb-3 lg:mt-0 lg:mb-0">
      <svg class="-mt-2 icon">
        <use
          href="../../../../assets/img/icons.svg#icon-todo"
          xlink:href="../../../../assets/img/icons.svg#icon-todo"
        />
      </svg>
      Pedido de Servicios
    </h4>
    <div class="w-full flex items-center justify-between mt-4">
      <label class="flex items-center mb-0 mr-6">
        <input type="radio" class="form-radio" value="all" v-model="status" />
        <span class="ml-2">Todos</span>
      </label>
      <label class="flex items-center mb-0 mr-6">
        <input type="radio" class="form-radio" value="pending" v-model="status" />
        <span class="ml-2">Pendientes</span>
      </label>
      <label class="flex items-center mb-0 mr-6">
        <input type="radio" class="form-radio" value="ongoing" v-model="status" />
        <span class="ml-2">En Curso</span>
      </label>
      <label class="flex items-center mb-0 mr-6">
        <input type="radio" class="form-radio" value="complete" v-model="status" />
        <span class="ml-2">Concretados</span>
      </label>
      <label class="flex items-center mb-0 mr-6">
        <input type="radio" class="form-radio" value="cancelled" v-model="status" />
        <span class="ml-2">Cancelados</span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    onChangeStatus: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      status: 'pending',
    };
  },
  watch: {
    status() {
      this.onChangeStatus({ status: this.status });
    },
  },
};
</script>
