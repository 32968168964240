<template>
  <div>
    <header class="header bg-white py-4 md:py-4">
      <div
        class="container flex flex-col md:flex-row items-center justify-between container-xl relative h-[60px]"
        style="z-index: 12"
      >
        <figure class="w-full md:w-auto pl-7 pt-3 md:p-0 pr-7">
          <router-link :to="{ name: 'Home' }">
            <div class="inline-block">
              <Logo/>
            </div>
          </router-link>
          <router-link v-if="this.$route.meta.headerLeftSection" :to="{ name: this.$route.meta.headerLeftSection.pathName }" class="inline-block ml-8 text-black relative bottom-2 mt-4 float-right">
            {{ this.$route.meta.headerLeftSection.title }}
          </router-link>
          <router-link v-else :to="{ name: 'RegisterProfessional' }" class="inline-block ml-8 text-black relative bottom-2 mt-4 float-right">
            Registrate como profesional
          </router-link>
        </figure>

        <nav class="md:w-96  invisible md:visible">
          <ul
            class="flex flex-wrap text-sm lg:text-base justify-center"
          >
            <li class="mb-4 md:mb-0">
              <div>
                <rounded-button v-if="showHeaderBackButton" ghost :color="'dark'" :onClick="onBackClick">
                  <div class="flex items-center justify-between px-[20px]">
                    <arrow-left-icon :color="'dark'"/>
                    <span class="pl-5 font-medium">Volver atrás</span>
                  </div>
                </rounded-button>
              </div>
            </li>
            <li class=" invisible md:visible">
              <router-link v-if="showHeaderCancelButton"
                :to="{ name: 'Home' }"
                class="btn rounded-full text-lg px-4 h-[45px] md:px-8 py-1 md-py-3 bg-white text-black border-2 border-white"
              >
                Cancelar
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import Facebook from "@/components/Facebook.vue";
import Google from "@/components/Google.vue";
import io from "socket.io-client";
import Swal from "sweetalert2";
import getMessages from "@/utils/methods/getMessages";
import getMessageRequestCount from "@/utils/methods/getMessageRequestCount";
import { RoundedButton } from "@/components/v2/buttons"
import { ArrowLeftIcon } from "@/components/v2/icons"
import Logo from "./Logo.vue"
const handToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export default {
  name: "Header",
  computed: {
    ...mapGetters(["headerLoginText", "isAuthenticated", "user", "showHeaderBackButton", "showHeaderCancelButton", "RegisterClientCurrentStep", , "RegisterProfessionalCurrentStep"]),
    unreadMessages() {
      let unreadMessage = [];
      if (this.messages && this.messages.length > 0) {
        if (this.user.userType == "client") {
          unreadMessage = this.messages.filter((message) => {
            return !message.read_by_client;
          });
        } else if (this.user.userType == "professional") {
          unreadMessage = this.messages.filter((message) => {
            return !message.read_by_professional;
          });
        }
      } else {
        return [];
      }
      return unreadMessage;
    },
  },
  data() {
    return {
      notifications: [],
      TotalNotification: 0,
      connection: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      processingNotificationRequest: false,
      updateNotificationData: {
        requestData: { read: true },
        notificationId: null,
      },
      dropdownOpen: false,
      dropdownClick: false,
      messages: [],
      dropdownOpenMessage: false,
      dropdownClickMessage: false,
      isAvailable: true,
      processingUpdateProfessional: false,
      updateProfessionalData: {
        professionalId: null,
        requestData: {
          isAvailable: "",
        },
      },
      messageRequestCount: 0,
      interval: null,
      totalReadCount: 0,
      messageTimeout: process.env.VUE_APP_MESSAGE_TIMEOUT,
    };
  },
  mounted() {
    if (this.isAuthenticated && this.user.userType != "admin") {
      this.getMessages();
      this.interval = setInterval(() => this.getMessageRequestCount(), this.messageTimeout * 1000);
      if (this.user.professionalProfile) {
        this.isAvailable = this.user.professionalProfile.isAvailable;
      }
    }
  },
  beforeDestroy() {
    if (this.isAuthenticated) {
      clearInterval(this.interval);
    }
  },
  created() {
    let socket = undefined;
    const url = this.baseUrl;
    socket = io(url);
    socket.on("connections", (data) => {
      this.connection = data;
      socket.emit("requestNotification", this.user.id);
    });
    socket.on("getNotification", (data) => {
      this.TotalNotification = data.Total;
      this.notifications = data.notifications;
      let totalNotification = parseInt(localStorage.getItem("notification")) || 0;
      if (this.TotalNotification > totalNotification) {
        bus.$emit("refreshIt", "yes");
        handToast.fire({
          icon: "success",
          title: "Tiene una nueva notificación en Hand",
        });
      }
      localStorage.setItem("notification", this.TotalNotification);
    });
  },
  methods: {
    getMessages,
    getMessageRequestCount,
    clickOutside() {
      this.dropdownOpen = false;
      this.dropdownClick = false;
    },
    clickOutsideMessage() {
      this.dropdownOpenMessage = false;
      this.dropdownClickMessage = false;
    },
    logout() {
      this.$refs.fbComponent.fbLogout();
      this.$refs.googleComponent.handleClickSignOut();
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$store.dispatch("setShowAlert", false);
          window.location.href = "/login";
        })
        .catch(() => {
          let errorMessage = "Something Went Wrong";
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
        });
    },
    updateNotificationReadStatus(notification, key) {
      this.processingNotificationRequest = true;
      this.updateNotificationData.notificationId = notification.id;
      this.$store
        .dispatch("updateNotificationReadStatus", this.updateNotificationData)
        .then(() => {
          this.processingNotificationRequest = false;
          this.dropdownOpen = false;
          this.dropdownClick = false;
          this.TotalNotification--;
          this.notifications.splice(key, 1);
          this.redirectNotification(notification);
        })
        .catch(() => {
          this.processingNotificationRequest = false;
          let errorMessage = this.error.response.data.msg[0];
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    redirectNotification(notification) {
      if (notification.eventTypeModel == "ServiceRequest") {
        if (this.user.userType == "client") {
          this.$router
            .push({
              name: "ServiceRequestSection",
              params: { id: notification.eventId },
            })
            .catch((error) => {
              if (error.name == "NavigationDuplicated") {
                this.$emit("goToServiceRequest", notification);
              } else if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        } else if (this.user.userType == "professional") {
          this.$router
            .push({
              name: "ProfessionalServiceRequestSection",
              params: { id: notification.eventId },
            })
            .catch((error) => {
              if (error.name == "NavigationDuplicated") {
                this.$emit("goToServiceRequest", notification);
              } else if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        }
      }
    },
    redirectMessage() {
      if (this.user.userType == "client") {
        this.$router
          .push({
            name: "Message",
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      } else if (this.user.userType == "professional") {
        this.$router
          .push({
            name: "ProfessionalMessage",
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      }
    },
    updateProfessionalAvailability(status) {
      this.processingUpdateProfessional = true;
      this.updateProfessionalData.professionalId = this.user.professionalProfile.id;
      this.updateProfessionalData.requestData.isAvailable = status.value;
      this.$store
        .dispatch("updateProfessionalAvailability", this.updateProfessionalData)
        .then((res) => {
          this.processingUpdateProfessional = false;
          this.isAvailable = status.value;
          handToast.fire({
            icon: "success",
            title: res.msg,
          });
        })
        .catch(() => {
          this.processingUpdateProfessional = false;
          handToast.fire({
            icon: "error",
            title: "Error ocurrido: algo salió mal, intente de nuevo",
          });
        });
    },
    isOpenDropdown() {
      if (this.unreadMessages.length > 0) {
        this.dropdownOpenMessage = !this.dropdownOpenMessage;
        this.dropdownClickMessage = !this.dropdownClickMessage;
      } else {
        if (this.user.userType == "client") {
          this.$router
            .push({
              name: "Message",
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        } else if (this.user.userType == "professional") {
          this.$router
            .push({
              name: "ProfessionalMessage",
            })
            .catch((error) => {
              if (error.name != "NavigationDuplicated") {
                throw error;
              }
            });
        }
      }
    },
    onBackClick() {
      if (this.RegisterClientCurrentStep) {
        if (this.RegisterClientCurrentStep == 1) {
          this.$router.push({ name: 'Home' })
          this.$store.dispatch("removeRegisterClientCurrentStep");
        } else {
          this.$router.push({ name: 'RegisterClientStep1' })
        }
      }

      if (this.RegisterProfessionalCurrentStep) {
        if (this.RegisterProfessionalCurrentStep == 1) {
          this.$router.push({ name: 'Home' })
        } else {
          const toStep = this.RegisterProfessionalCurrentStep - 1

          if (toStep == 3) {
            this.$router.push({ name: 'Home' })
          } else {
            this.$router.push({ name: `RegisterProfessionalStep${toStep}` })
          }
        }
      }
    }
  },
  directives: {
    "click-outside": {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener("click", handler);
      },
      unbind: function (el) {
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  components: {
    Logo,
    Facebook,
    Google,
    RoundedButton,
    ArrowLeftIcon
  },
};
</script>
<style scoped>
.availablity-toogle label {
  margin-bottom: 0px;
}
</style>
