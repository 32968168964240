export default function(e) {
  this.attachFile = e.target.files || e.dataTransfer.files;
  if (!this.attachFile) return;
  var reader = new FileReader();
  var vm = this;
  reader.onload = e => {
    vm.attachFilePreview = e.target.result;
  };
  reader.readAsDataURL(this.attachFile[0]);
}
