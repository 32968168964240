<template>
  <svg :width="width" :height="height" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :class="`stroke-${color}`" d="M1.30078 6.63129L5.22071 10.2817L13.9245 1.10741" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:15
    },
    height:{
      type:Number,
      default:12
    },
    color:{
      type:String,
      default:'white'
    }
  }
}
</script>
