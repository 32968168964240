<template>
  <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-20 overflow-y-auto">
      <div
        class="h-screen flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="w-full h-4/5 relative transform overflow-hidden rounded-lg bg-gray-200 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        >
          <div class="w-full h-full flex flex-col bg-gray-200">
            <!--  -->
            <div class="sm:flex sm:items-start bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="w-full flex justify-end">
                <div @click="$emit('hide')" class="cursor-pointer">
                  <times-icon />
                </div>
              </div>
              <div v-if="!show_filters" class="w-full flex items-center justify-between">
                <h2 class="flex items-center">
                  <clock-rotate-left-icon class="w-10 mr-3" :color="'#3E65FF'" /> Historial de
                  trabajos contratados
                </h2>
                <div @click="show_filters = true">
                  <magnifying-glass-icon class="w-8 h-8 mr-10 cursor-pointer" :color="'#000'" />
                </div>
              </div>
            </div>
            <div class="w-full mt-4 px-6">
              <SearchFilters v-if="show_filters" @filters-updated="listServiceRequests" />
            </div>
            <div
              class="flex-1 flex flex-col px-6 pt-5 pb-6 sm:p-6 sm:pb-4"
              style="overflow-y: scroll"
            >
              <template v-for="service_request in service_requests">
                <ServiceRequest :service_request="service_request" :key="service_request.id" />
              </template>
              <template v-if="!service_requests.length && !error">
                <EmptyFeedback />
              </template>
              <template v-if="error">
                <ErrorFeedback />
              </template>
            </div>
            <div class="sm:flex sm:items-start bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
              <Paginator
                :totalPages="totalPages"
                :currentPage="currentPage"
                :onClickPage="listServiceRequests"
                :showNextBtn="showNextBtn"
                :showPrevBtn="showPrevBtn"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ServiceRequests } from "@/services";
import { TimesIcon, ClockRotateLeftIcon } from "@/components/icons";
import { Paginator } from "@/components";
import ServiceRequest from "./ServiceRequest.vue";
import { MagnifyingGlassIcon } from "@/components/icons";
import SearchFilters from "@/views/admin/ServiceRequests/SearchFilters.vue";
import statusTranslations from "@/utils/helpers/ServiceRequest/statusTranslations";
import dayjs from "dayjs";
import EmptyFeedback from "../../ServiceRequests/EmptyFeedback.vue";
import ErrorFeedback from "@/views/admin/ServiceRequests/ErrorFeedback.vue";

export default {
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      service_requests: [],
      totalPages: 1,
      currentPage: 1,
      showNextBtn: false,
      showPrevBtn: false,
      show_filters: false,
      isLoading: false,
      error: null,
    };
  },
  mounted() {
    this.listServiceRequests({ page: this.currentPage });
  },
  methods: {
    listServiceRequests({
      page = 1,
      query = "",
      states = Object.keys(statusTranslations),
      date = dayjs().format("YYYY-MM-DD"),
    }) {
      this.isLoading = true;
      ServiceRequests.getByClient({
        client_profile_id: this.client.client_profile.id,
        page,
        query,
        states,
        date,
      })
        .then(({ data }) => {
          this.error = null;
          this.service_requests = data.service_requests.data;
          this.currentPage = parseInt(data.service_requests.meta.page.current_page);
          this.totalPages = parseInt(data.service_requests.meta.page.last_page);
          this.showNextBtn = data.service_requests.meta.page.hasNextPage;
          this.showPrevBtn = data.service_requests.meta.page.hasPreviousPage;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    ErrorFeedback,
    EmptyFeedback,
    SearchFilters,
    MagnifyingGlassIcon,
    ClockRotateLeftIcon,
    TimesIcon,
    ServiceRequest,
    Paginator,
  },
};
</script>
