<template>
  <div>
    <Messages />
  </div>
</template>

<script>
import Messages from "@/components/Messages.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      processingPayment: false
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    if (this.$route.params.hash) {
      const hash = this.$route.params.hash;
      this.getPaymentStatus(hash);
    }
  },
  methods: {
    getPaymentStatus(hash) {
      this.processingPayment = true;
      this.$store
        .dispatch("getPaymentStatus", {
          hash: hash
        })
        .then(() => {
          this.processingPayment = false;
          this.$router.push({ name: "ServiceRequest" });
        })
        .catch(() => {
          this.processingPayment = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  },
  computed: {
    ...mapGetters(["error", "paymentHash"])
  },
  components: {
    Messages
  }
};
</script>
<style scoped>
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
