 <template>
  <div class="container">
    <nav class="my-2" aria-label="Breadcrumb">
      <ol class="inline-flex p-0 text-sm list-none">
        <li
          v-for="(breadcrumb, idx) in breadcrumbList"
          :key="idx"
          v-bind:class="{'page-item':true, 'text-gray-500':(!breadcrumb.link)}"
        >
        <router-link :to="breadcrumb.link" v-if="!!breadcrumb.link">{{ breadcrumb.name }}</router-link>
          <span v-else>{{ breadcrumb.name }}</span>
          <svg class="mt-1 icon" v-if="!breadcrumb.islast">
            <use
              href="../assets/img/icons.svg#icon-right"
              xlink:href="../assets/img/icons.svg#icon-right"
            />
          </svg>
        </li>
      </ol>
    </nav>
  </div>
</template>
<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: []
    };
  },
  props: {
    items: Array
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if(this.items) {
          if(this.items.length>0) {
            this.breadcrumbList = this.items;
          }
      }
      
     
    }
  }
};
</script>
<style scoped>
@import "../assets/css/index.css";
</style>