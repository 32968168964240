var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{on:{"refreshMessage":function($event){return _vm.refreshMessageEvent($event)}}}),_c('Breadcrumb'),_c('Messages'),_c('section',{staticClass:"py-3"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-col-reverse justify-between -mx-2 lg:my-4 lg:flex-row"},[_c('ProfessionalProfileSidebar'),_c('section',{staticClass:"px-2 lg:w-8/12"},[_c('div',{staticClass:"flex flex-col justify-between mb-2 lg:mb-4 lg:flex-row lg:items-center"},[_c('h4',{staticClass:"mb-0 lg:mt-0"},[_c('svg',{staticClass:"-mt-2 icon"},[_c('use',{attrs:{"href":require("../../assets/img/icons.svg") + "#icon-email","xlink:href":require("../../assets/img/icons.svg") + "#icon-email"}})]),_vm._v(" Mensajes ")])]),_c('div',{staticClass:"mb-2 overflow-x-auto bg-white rounded shadow-md"},[_c('table',{staticClass:"w-full text-left border-collapse"},[_c('tbody',_vm._l((_vm.messages),function(message,index){return _c('tr',{key:index,staticClass:"flex flex-col lg:table-row",class:{
                    unread_message_row: !message.read_by_professional
                  }},[_c('td',{staticClass:"px-6 py-4 border-b border-gray-200"},[(!message.read_by_professional)?_c('small',{staticClass:"text-xs text-brand-secondary"},[_vm._v("Tienes un mensaje nuevo ")]):_vm._e(),_c('h5',{staticClass:"my-0 text-gray-900 whitespace-no-wrap"},[_vm._v(" Creado por ")]),_c('div',{staticClass:"flex items-center"},[(message.client)?_c('figure',{staticClass:"border border-gray-200 avatar"},[_c('img',{attrs:{"src":message.client.pictureProfileUrl,"alt":message.client.firstName + ' ' + message.client.lastName}})]):_vm._e(),_c('div',{staticClass:"ml-3"},[_c('small',{staticClass:"text-xs text-gray-400"},[_vm._v(_vm._s(_vm.moment(message.createdAt).format("DD/MM/YY HH:MM"))+"hs")]),(message.client)?_c('h6',{staticClass:"my-0 text-gray-900 whitespace-no-wrap"},[_vm._v(" "+_vm._s(message.client.firstName)+" "+_vm._s(message.client.lastName)+" ")]):_vm._e()])])]),_c('td',{staticClass:"px-6 py-4 border-b border-gray-200"},[_c('h5',{staticClass:"my-0 text-gray-900 whitespace-no-wrap"},[_vm._v(" Asunto ")]),_c('span',{class:{
                        unread_message: !message.read_by_professional
                      }},[_vm._v(" "+_vm._s(message.description))])]),_c('td',{staticClass:"px-6 py-4 border-b-4 border-gray-200 md:border-b"},[_c('a',{staticClass:"px-3 py-1 text-xs text-red-600",attrs:{"href":"#"}},[_vm._v("Eliminar")]),_c('router-link',{staticClass:"btn btn--xs btn--outline--primary",attrs:{"to":{
                        name: 'ProfessionalMessageResponderWithoutStatus',
                        params: { otype: message.type, oid: message.orderId }
                      }}},[_vm._v("Responder")])],1)])}),0)])])])],1)])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }