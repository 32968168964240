<template>
  <div class="w-full h-16 flex">
    <div class="w-1/2 h-full flex mr-2">
      <select
        class="w-full h-full text-gray-800 placeholder-gray-800 border-none px-4 focus:outline-none"
        id="month"
        v-model="month"
      >
        <option disabled>Mes</option>
        <template v-for="(month, i) in months">
          <option :key="i" :value="i + 1">{{ month }}</option>
        </template>
      </select>
    </div>
    <div class="w-1/2 h-full flex">
      <select
        class="w-full h-full text-gray-800 placeholder-gray-800 border-none px-4 focus:outline-none"
        id="year"
        v-model="year"
      >
        <option disabled>Año</option>
        <template v-for="(year, i) in years">
          <option :key="i" :value="year">{{ year }}</option>
        </template>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Marzo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      years: [2023],
      month: 1,
      year: 2023,
    };
  },
};
</script>
