<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle :class="`fill-${color}`" :cx="width/2" :cy="height/2" :r="width/2" />
    <path d="M5.2347 8.26521L7.11116 10.0126L11.2777 5.62094" stroke="white" stroke-linecap="round"/>
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:16
    },
    height:{
      type:Number,
      default:16
    },
    color:{
      type:String,
      default:'[#04B00B]'
    }
  }
}
</script>
