import {HTTP} from "@/config/http-constants";

export default {
  upload(data)
  {
    return HTTP.post('public/file',data,{
      headers:{
        "Content-Type": "multipart/form-data",
        'Accept':'application/json'
      }
    })
  }
}
