<template>
  <section class="px-2 lg:w-1/2">
    <div class="mb-8">
      <Export :type="'client'"/>
    </div>
    <div class="flex flex-col justify-between mb-4 lg:flex-row lg:items-center">
      <h4 class="mb-3 lg:mt-0 lg:mb-0">
        <svg class="-mt-2 icon">
          <use
            href="../../../../assets/img/icons.svg#icon-todo"
            xlink:href="../../../../assets/img/icons.svg#icon-todo"
          />
        </svg>
        Listado de clientes
      </h4>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="w-full">
        <div class="flex items-stretch mb-4 text-lg shadow-xl md:flex-row">
          <input
            type="text"
            placeholder="Filtrar clientes"
            v-model="search"
            class="text-gray-800 placeholder-gray-800 form-input w-4/5 md:mb-0 rounded-r-none h-auto"
          />

          <button
            class="justify-center font-semibold btn btn--primary w-1/5 h-auto rounded-l-none icon-text-aligner"
            @click="() => listClients({ page: 1, search: search })"
          >
            <svg class="text-white icon icon--outline icon--stroke-2 icon--md">
              <use
                href="../../../../assets/img/icons.svg#icon-search"
                xlink:href="img/icons.svg#icon-search"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-center mt-3">
      <i class="font-medium text-xl">{{totalItems}} resultados en total</i>
    </div>
    <template v-for="client in clients">
      <Item
        :key="client.id"
        :data="client"
        :firstName="client.firstName"
        :lastName="client.lastName"
        :picture_url="client.pictureProfileUrl"
        :onClick="onItemClicked"
      />
    </template>
    <Paginator
      :totalPages="totalPages"
      :currentPage="currentPage"
      :onClickPage="listClients"
      :showNextBtn="showNextBtn"
      :showPrevBtn="showPrevBtn"
    />
  </section>
</template>

<script>
import { Clients } from "@/services";
import Item from "./Item.vue";
import { Paginator } from "@/components";
import Export from "../../Export.vue";
export default {
  props: {
    onItemClicked: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      clients: [],
      totalPages: 1,
      currentPage: 1,
      showNextBtn: false,
      showPrevBtn: false,
      search: "",
      totalItems:0
    };
  },
  mounted() {
    this.listClients({ page: this.currentPage, search: this.search });
  },
  methods: {
    listClients({ page = 1, search = "" }) {
      if (search == "") search = this.search;
      console.log(search);
      Clients.all({ page, search }).then(this.handleSuccessClientList).catch(console.log);
    },
    handleSuccessClientList({ data }) {
      this.clients = data.data;
      this.currentPage = parseInt(data.meta.page.current_page);
      this.totalPages = parseInt(data.meta.page.last_page);
      this.showNextBtn = data.meta.page.hasNextPage;
      this.showPrevBtn = data.meta.page.hasPreviousPage;
      this.totalItems = data.meta.page.total
    },
  },
  filters: {},
  computed: {},
  components: {
    Item,
    Paginator,
    Export
  },
};
</script>
<style scoped></style>
