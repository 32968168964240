<template>
  <div>
    <Header />
    <Breadcrumb :items="BreadcrumbItems" />
    <Messages />
    <section class="flex py-4">
      <div class="container flex items-center container-xl">
        <div class="w-full xl:w-12/12">
          <h2 class="w-5/5 lg:w-4/5">
            Profesionales
            <span class="text-brand-secondary">{{ searchprofession }}</span>
            en {{ searchlocation }}
          </h2>
          <div
            class="flex flex-col items-stretch mb-2 text-lg shadow-xl md:flex-row md:h-16 auto-profession"
          >
            <div
              class="h-16 text-gray-800 placeholder-gray-800  md:w-2/3 mb-4 md:mb-0 md:rounded-r-none md:h-auto"
              :key="componentKey"
            >
              <div class="relative">
                <autocomplete
                  ref="profession"
                  placeholder="¿Qué necesitas? Ej: Electricista"
                  :source="professions"
                  v-model="profession"
                  :initialValue="profession"
                  :initialDisplay="profession"
                  results-display="description"
                  resultsValue="description"
                  inputClass="h-16 md:h-12  text-gray-800 placeholder-gray-800  border-none px-4  profession-value"
                  id="buscador_field_search"
                  @selected="setProfession"
                >
                  <template #noResults>
                    Profesión inexistente
                  </template>
                </autocomplete>
              </div>
            </div>
            <select
              v-model="location"
              class="h-16 text-gray-800 rounded form-select md:w-1/3 mb-4 md:mb-0 border-none  md:h-auto md:rounded-none"
            >
              <option selected disabled>¿Dónde?</option>
              <option
                v-for="location in activeLocations"
                :key="location.id"
                :value="{ id: location.id, name: location.description }"
                >{{ location.description }}</option
              >
            </select>
            <button
              @click="SearchProfessionals()"
              :disabled="processingSearch"
              class="justify-center h-16 font-semibold btn btn--secondary md:w-1/3 mb-4 md:mb-0 md:h-auto md:rounded-l-none icon-text-aligner"
            >
              <svg class="text-white icon icon--outline icon--stroke-2 icon--md">
                <use
                  href="../assets/img/icons.svg#icon-search"
                  xlink:href="img/icons.svg#icon-search"
                />
              </svg>
              Buscar Profesionales
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="py-3">
      <div v-if="processingSearch">
        <div class="container container-xl">
          <img src="../assets/img/loading-gif.gif" class="w-full inline-block" />
        </div>
      </div>
      <div v-else>
        <div class="container container-xl" v-if="professionals.length > 0">
          <h5>
            Mostrando
            <span class="text-brand-secondary"
              >{{ professionals.length }} {{ searchprofession }}</span
            >
            profesionales que trabajan en zona {{ searchlocation }}
          </h5>

          <div class="flex flex-col my-4 -mx-2 lg:flex-row">
            <section class="px-2 w-full">
              <article
                class="p-5 mb-4 bg-white border border-gray-400 rounded"
                v-for="professional in professionals"
                :key="professional.id"
              >
                <div class="flex flex-wrap justify-center">
                  <figure
                    class="flex-none w-10 h-10 mr-2 overflow-hidden border border-gray-400 rounded-full"
                  >
                    <img
                      :src="professional.pictureProfileUrl"
                      :alt="professional.firstName + ' ' + professional.lastName"
                    />
                  </figure>
                  <header class="flex-1 pr-2">
                    <h5 class="my-0 professional-profile">
                      {{ professional.firstName }}
                      <span>{{ professional.lastName }}</span>
                      <span
                        v-if="professional.isAvailable"
                        class="inline-flex items-center justify-center px-3 py-3 ml-2 text-base font-bold leading-none text-white bg-green-btn rounded-full"
                        >Disponible</span
                      >
                      <span
                        v-else
                        class="inline-flex items-center justify-center px-3 py-3 ml-2 text-base font-bold leading-none text-white bg-red-600 rounded-full"
                        >No Disponible</span
                      >
                    </h5>
                    <p class="mb-0 text-xs">
                      Trabaja en zona
                      <a href="#">{{ professional.Location.description }}</a>
                    </p>
                    <div class="flex items-center">
                      <star-rating
                        inactive-color="#dfe0e6"
                        :show-rating="false"
                        active-color="#ffd83e"
                        :increment="0.5"
                        :star-size="20"
                        :read-only="true"
                        :rating="professional.rate | numberFormat"
                      ></star-rating>
                      <div class="ml-1 font-semibold text-gray-900">{{ professional.rate }}/5</div>
                    </div>
                    <p class="mb-0 text-xs text-gray-500">
                      {{ professional.opinionsCount }} opiniones
                    </p>
                  </header>

                  <div class="w-auto md:self-center mt-4 md:mt-0">
                    <button
                      @click="goToProfile(profession_id, professional)"
                      class="btn btn--primary"
                    >
                      Ver perfil
                    </button>
                  </div>
                </div>
                <p class="pl-12">{{ professional.information }}</p>

                <div
                  class="pt-5 pl-16 pr-5 -ml-5 -mr-5 border-t border-gray-300"
                  v-if="professional.opinionsCount > 0"
                >
                  <h6>
                    <svg class="mb-1 icon text-brand-secondary">
                      <use
                        href="../assets/img/icons.svg#icon-chat-round-content"
                        xlink:href="../assets/img/icons.svg#icon-chat-round-content"
                      />
                    </svg>
                    <span>{{ professional.opinionsCount }}</span> Opiniones de clientes
                  </h6>
                  <div>
                    <div
                      v-for="(Opinion, index) in professional.Opinions.slice(
                        0,
                        showOpinion[professional.userId]
                      )"
                      :key="index"
                    >
                      <div class="flex items-center pl-6 mt-2 mb-1">
                        <figure
                          class="flex-none w-6 h-6 mr-2 overflow-hidden border border-gray-400 rounded-full"
                        >
                          <img
                            :src="Opinion.pictureProfileUrl"
                            :alt="Opinion.firstName + ' ' + Opinion.lastName"
                          />
                        </figure>
                        <div class="pr-2">
                          <h6 class="my-0">{{ Opinion.firstName }} {{ Opinion.lastName }}</h6>
                        </div>
                        <div class="leading-none">
                          <star-rating
                            inactive-color="#dfe0e6"
                            :show-rating="false"
                            active-color="#ffd83e"
                            :increment="0.5"
                            :star-size="20"
                            :read-only="true"
                            :rating="Opinion.rate | numberFormat"
                          ></star-rating>
                        </div>
                      </div>
                      <p class="pl-6">{{ Opinion.description }}</p>
                    </div>
                  </div>
                  <a
                    href="javascript:void(0)"
                    @click="showMoreOpinion(professional.userId, professional.opinionsCount)"
                    v-if="professional.opinionsCount > showOpinion[professional.userId]"
                    >Ver todos los comentarios</a
                  >
                </div>
              </article>
            </section>
          </div>
        </div>
        <div class="container msg-section" v-else>
          <section
            class="flex flex-col items-center md:flex-row alert alert--success shadow alert--info"
          >
            <div class="md:pr-6 mb-2">
              <svg class="text-6xl text-blue-800 icon md:text-5xl">
                <use
                  href="../assets/img/icons.svg#icon-alert-circle-i"
                  xlink:href="../assets/img/icons.svg#icon-alert-circle-i"
                />
              </svg>
            </div>
            <div class="text-center md:text-left">
              <p class="text-sm">
                Lo sentimos, no encontramos ningún profesional con esas características en la zona
                seleccionada.
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters } from "vuex";
import numberFormat from "@/utils/filters/numberFormat";
import encrypt from "@/utils/methods/encrypt";
import GetLocations from "@/utils/methods/GetLocations";
import setDefaultLocation from "@/utils/methods/setDefaultLocation";
import activeLocations from "@/utils/computed/activeLocations";
import GetProfessions from "@/utils/methods/GetProfessions";
import Autocomplete from "vuejs-auto-complete";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      processingSearch: true,
      profession: "electricista",
      location: { id: null, name: "" },
      searchprofession: "electricista",
      searchlocation: "",
      professionals: [],
      locations: [],
      BreadcrumbItems: [{ name: "Inicio", link: "/" }],
      SecretKey: process.env.VUE_APP_ENCRYPT_SECRETKEY,
      professions: [],
      services: [],
      service: [],
      selectedService: [],
      advanceProfession: "",
      isShowSearch: false,
      isMultiOpen: false,
      componentKey: 0,
      issetvalue: false,
      showOpinion: {},
      profession_id: null
    };
  },
  created() {
    if (this.$route.query.services) {
      let serviceID = this.$route.query.services.replace("[", "");
      serviceID = serviceID.replace("]", "");
      this.selectedService = serviceID.split(",");
    }
    if (this.$route.query.profession && this.$route.query.location) {
      this.profession = this.$route.query.profession;
      this.location.id = this.$route.query.location;
      this.BreadcrumbItems.push({
        name: this.profession,
        link:
          "/professionals/search?profession=" + this.profession + "&location=" + this.location.id
      });
    }
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.GetLocations();
    this.GetProfessions(true);
  },
  filters: {
    encrypt: function(value) {
      if (!value) return "";
      value = value.toString();
      var lastname_array = new Array();
      lastname_array = value.split(" ");
      var encrypt_value = "";
      for (var i = 0; i < lastname_array.length; i++) {
        encrypt_value +=
          lastname_array[i].charAt(0) + lastname_array[i].slice(1).replace(/\S/gi, "x") + " ";
      }
      return encrypt_value;
    },
    numberFormat
  },
  methods: {
    encrypt,
    GetLocations,
    setDefaultLocation,
    GetProfessions,
    SearchProfessionals() {
      this.isShowSearch = false;
      this.processingSearch = true;
      let serviceIds = [];
      for (var t = 0; t < this.service.length; t++) {
        serviceIds[t] = this.service[t].id;
      }
      this.$store
        .dispatch("SearchProfessionals", {
          profession: this.profession,
          location: this.location.id,
          locationname: this.location.name,
          services: serviceIds
        })
        .then(response => {
          this.professionals = response;
          if (this.professionals.length > 0) {
            this.professionals.forEach(e => {
              this.$set(this.showOpinion, e.userId, 2);
            });
          }
          this.searchprofession = this.profession;
          if (this.location.name != "") {
            this.searchlocation = this.location.name;
          }
          this.profession_id = this.advanceProfession.id;
          this.processingSearch = false;
        })
        .catch(() => {
          this.processingSearch = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    GetServiceByType() {
      this.service = [];
      this.profession = this.advanceProfession.description;
      this.componentKey += 1;
      this.$store
        .dispatch("getServiceByType", {
          type: this.advanceProfession.type
        })
        .then(response => {
          this.services = response.services;
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    toggleAdvanceSearch() {
      this.isShowSearch = !this.isShowSearch;
    },
    setProfession(profession) {
      this.advanceProfession = profession.selectedObject;
      this.GetServiceByType();
    },
    setProfessionalValue() {
      const i = this.professions.findIndex(_item => _item.description === this.profession);
      if (i > -1) {
        this.advanceProfession = this.professions[i];
        this.profession_id = this.professions[i].id;
      }
    },
    setServiceValue() {
      this.selectedService.forEach(e => {
        const j = this.services.findIndex(_item => _item.id == e);
        if (j > -1) {
          this.service.push(this.services[j]);
        }
      });
      this.issetvalue = true;
    },
    showMoreOpinion(key, totalOpinion) {
      this.showOpinion[key] = totalOpinion;
    },
    goToProfile(profession_id, professional) {
      if (!localStorage.getItem("sp-popup") && !this.isAuthenticated) {
        var self = this;
        setTimeout(function() {
          Swal.fire({
            title: "Nuevo en HAND?",
            icon: "",
            html: `Para iniciar una contratación debe <a href='/register-client'>registrarse</a>, si ya tiene una cuenta  <a href='/login'>ingresar</a>`,
            confirmButtonText: "Continuar",
            backdrop: false
          }).then(result => {
            if (result.value) {
              self.$router.push({
                name: "ProfessionalsProfile",
                params: { proid: profession_id, pid: self.encrypt(professional.userId) }
              });
            }
          });
        }, 2000);
        localStorage.setItem("sp-popup", "viewed");
      } else {
        this.$router.push({
          name: "ProfessionalsProfile",
          params: { proid: profession_id, pid: this.encrypt(professional.userId) }
        });
      }
    }
  },
  watch: {
    professions() {
      if (!this.issetvalue) {
        this.setProfessionalValue();
        this.GetServiceByType();
      }
    },
    services() {
      if (!this.issetvalue) {
        this.setServiceValue();
        this.SearchProfessionals();
      }
    }
  },
  computed: {
    ...mapGetters(["error", "isAuthenticated"]),
    activeLocations
  },
  components: {
    Header,
    Footer,
    Messages,
    Breadcrumb,
    Autocomplete
  }
};
</script>

<style scoped>
@import "../assets/css/busqueda.css";
.error {
  color: #e54d42;
  border-left-color: #b82e24;
}
</style>
<style>
.auto-profession .autocomplete__box {
  border: none !important;
  border-radius: 0px !important;
}
.card-profesion {
  z-index: 5;
}

#buscador_field_search {
  background-color: #fff;
  border-color: #c0c3ce;
  border-right: none;
  border-radius: 0.25rem;

  padding: 0rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  #buscador_field_search {
    padding: 0.5rem 0.75rem;
    border-right: 1px solid #c0c3ce;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
