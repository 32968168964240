<template>
  <svg :width="width" :height="height"  viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :class="`fill-${color}`" d="M5.8 3.88V4.63C6.06675 4.63 6.31343 4.48832 6.44783 4.2579L5.8 3.88ZM7.48 1V0.25C7.21325 0.25 6.96657 0.391683 6.83216 0.622097L7.48 1ZM13.72 1L14.3678 0.622097C14.2334 0.391684 13.9867 0.25 13.72 0.25V1ZM15.4 3.88L14.7522 4.2579C14.8866 4.48832 15.1332 4.63 15.4 4.63V3.88ZM1.75 16.12V6.28H0.25V16.12H1.75ZM3.4 4.63H5.8V3.13H3.4V4.63ZM6.44783 4.2579L8.12783 1.3779L6.83216 0.622097L5.15216 3.5021L6.44783 4.2579ZM7.48 1.75H13.72V0.25H7.48V1.75ZM13.0722 1.3779L14.7522 4.2579L16.0478 3.5021L14.3678 0.622097L13.0722 1.3779ZM15.4 4.63H17.8V3.13H15.4V4.63ZM19.45 6.28V16.12H20.95V6.28H19.45ZM19.45 16.12C19.45 17.0313 18.7113 17.77 17.8 17.77V19.27C19.5397 19.27 20.95 17.8597 20.95 16.12H19.45ZM17.8 4.63C18.7113 4.63 19.45 5.36873 19.45 6.28H20.95C20.95 4.5403 19.5397 3.13 17.8 3.13V4.63ZM1.75 6.28C1.75 5.36873 2.48873 4.63 3.4 4.63V3.13C1.6603 3.13 0.25 4.5403 0.25 6.28H1.75ZM3.4 17.77C2.48873 17.77 1.75 17.0313 1.75 16.12H0.25C0.25 17.8597 1.6603 19.27 3.4 19.27V17.77ZM13.45 10.6C13.45 12.174 12.174 13.45 10.6 13.45V14.95C13.0024 14.95 14.95 13.0024 14.95 10.6H13.45ZM10.6 13.45C9.02599 13.45 7.75 12.174 7.75 10.6H6.25C6.25 13.0024 8.19756 14.95 10.6 14.95V13.45ZM7.75 10.6C7.75 9.02599 9.02599 7.75 10.6 7.75V6.25C8.19756 6.25 6.25 8.19756 6.25 10.6H7.75ZM10.6 7.75C12.174 7.75 13.45 9.02599 13.45 10.6H14.95C14.95 8.19756 13.0024 6.25 10.6 6.25V7.75ZM17.8 17.77H3.4V19.27H17.8V17.77Z" />
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:21
    },
    height:{
      type:Number,
      default:20
    },
    color:{
      type:String,
      default:'dark'
    }
  }
}
</script>
