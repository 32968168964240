export default function(type) {
  this.$store
    .dispatch("getServiceByType", {
      type: type
    })
    .then(response => {
      this.services = [].concat(this.services, response.services);
    })
    .catch(() => {
      this.processingGetProfessionalData = false;
      let errorMessage = this.error.response.data.msg;
      this.$store.dispatch("setIsSuccessAlert", false);
      this.$store.dispatch("setIsErrorAlert", true);
      this.$store.dispatch("setGlobalMessage", errorMessage);
      this.$store.dispatch("setShowAlert", true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
