var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"px-2 mb-4 lg:w-1/3"},[_c('nav',{staticClass:"w-full lg:-mt-4"},[_c('ul',[_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('service-requests') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4',
          ],attrs:{"to":{ name: 'AdminServiceRequests' }}},[_c('span',[_vm._v("Servicios solicitados")]),_c('svg',{class:[
              _vm.currentPage.includes('service-requests') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg',
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('budget-request') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4',
          ],attrs:{"to":{ name: 'AdminBudgetRequest' }}},[_c('span',[_vm._v("Presupuestos solicitados")]),_c('svg',{class:[
              _vm.currentPage.includes('budget-request') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg',
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('review') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4',
          ],attrs:{"to":{ name: 'AdminReview' }}},[_c('span',[_vm._v("Reviews")]),_c('svg',{class:[
              _vm.currentPage.includes('review') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg',
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('professional-request') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4',
          ],attrs:{"to":{ name: 'AdminProfessionalRequest' }}},[_c('span',[_vm._v("Solicitudes de profesionales")]),_c('svg',{class:[
              _vm.currentPage.includes('professional-request')
                ? 'text-brand-secondary'
                : 'text-black',
              'icon icon--lg',
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('transfer-request') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4',
          ],attrs:{"to":{ name: 'AdminTransferRequest' }}},[_c('span',[_vm._v("Transferencias bancarias a confirmar")]),_c('svg',{class:[
              _vm.currentPage.includes('transfer-request') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg',
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1),_c('li',[_c('router-link',{class:[
            _vm.currentPage.includes('clients') ? 'text-brand-secondary' : 'text-black',
            'flex items-center justify-between py-4',
          ],attrs:{"to":{ name: 'AdminClients' }}},[_c('span',[_vm._v("Clientes")]),_c('svg',{class:[
              _vm.currentPage.includes('clients') ? 'text-brand-secondary' : 'text-black',
              'icon icon--lg',
            ]},[_c('use',{attrs:{"href":require("../assets/img/icons.svg") + "#icon-right","xlink:href":require("../assets/img/icons.svg") + "#icon-file-content"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }