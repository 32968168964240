<template>
  <svg :width="width" :height="height" :class="`stroke-${color}`" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.83333 12.9474L1 6.95295M1 6.95295L6.83333 0.958496M1 6.95295H15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props:{
    width:{
      type:Number,
      default:16
    },
    height:{
      type:Number,
      default:14
    },
    color:{
      type:String,
      default:'#000'
    }
  }
}
</script>
