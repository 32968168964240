import { HTTP } from "@/config/http-constants";
export default {
  all({ page = 1, search = "" }) {
    return HTTP.get(`/api/clients?page=${page}&search=${search}`);
  },
  update(id, data) {
    return HTTP.patch(`/api/clients/${id}`, data);
  },
  search(data) {
    return HTTP.get(`/api/clients?search${id}`, data);
  },
};
